import React from 'react';
import { useParams } from 'react-router-dom';
import { PreContainer } from '../../../components/code/PreContainer';
import { useAppSelector } from '../../../redux/hooks';
import { categorySelectors } from '../../../redux/slice/categories';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { AdminCategoryActionMenu } from './AdminCategoryActionMenu';

interface MatchParams {
  categoryId: string;
}
export const AdminCategory: React.FC = () => {
  const { categoryId } = useParams<keyof MatchParams>();
  const category = useAppSelector(state =>
    categorySelectors.selectById(state, categoryId!)
  );

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs currentPageName={category?.name} />
      {category && <AdminCategoryActionMenu category={category} />}
      <h2>Kategori JSON-data</h2>
      <PreContainer obj={category} />
    </React.Fragment>
  );
};
