import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setSideMenuOpen } from '../../redux/slice/ui';
import './HamburgerMenu.scss';

export const HamburgerMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(state => state.ui.sideMenuOpen);
  return (
    <span
      className='hamburger'
      onClick={() => {
        dispatch(setSideMenuOpen(!isOpen));
      }}
    >
      ☰
    </span>
  );
};
