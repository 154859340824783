// source: data.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.PointerCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.Source', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string old_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.prototype.getOldId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.prototype.setOldId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.prototype.getNewId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectIdRequest.prototype.setNewId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    oldOperationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    newOperationId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldOperationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewOperationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOldOperationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNewOperationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string old_operation_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.prototype.getOldOperationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.prototype.setOldOperationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string new_operation_id = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.prototype.getNewOperationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.RenameSubjectOperationIdRequest.prototype.setNewOperationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampRangeFilter: (f = msg.getTimestampRangeFilter()) && shared_pb.TimestampInterval.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.TimestampInterval;
      reader.readMessage(value,shared_pb.TimestampInterval.deserializeBinaryFromReader);
      msg.setTimestampRangeFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampRangeFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.TimestampInterval.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.TimestampInterval timestamp_range_filter = 1;
 * @return {?proto.oslonokkelen.proto.shared.TimestampInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.prototype.getTimestampRangeFilter = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.TimestampInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.TimestampInterval, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.TimestampInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.prototype.setTimestampRangeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.prototype.clearTimestampRangeFilter = function() {
  return this.setTimestampRangeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogRequest.prototype.hasTimestampRangeFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    citykeyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitykeyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCitykeyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string citykey_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.prototype.getCitykeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchEntityAuditLogRequest.prototype.setCitykeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    actor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    resourceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventType: jspb.Message.getFieldWithDefault(msg, 3, ""),
    summary: jspb.Message.getFieldWithDefault(msg, 4, ""),
    details: jspb.Message.getFieldWithDefault(msg, 5, ""),
    timestamp: (f = msg.getTimestamp()) && shared_pb.Timestamp.toObject(includeInstance, f),
    auditLogSource: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry;
  return proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setResourceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetails(value);
      break;
    case 6:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 7:
      var value = /** @type {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.Source} */ (reader.readEnum());
      msg.setAuditLogSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResourceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDetails();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAuditLogSource();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.Source = {
  BACKEND: 0,
  AUTH: 1
};

/**
 * optional string actor = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.getActor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.setActor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string resource_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.getResourceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.setResourceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string event_type = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.getEventType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.setEventType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string summary = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string details = 5;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.getDetails = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.setDetails = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional oslonokkelen.proto.shared.Timestamp timestamp = 6;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.getTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 6));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Source audit_log_source = 7;
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.Source}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.getAuditLogSource = function() {
  return /** @type {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.Source} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.Source} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry.prototype.setAuditLogSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * repeated AuditLogEntry entries = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.AuditLogEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.FetchAuditLogResponse.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.PointerCase = {
  POINTER_NOT_SET: 0,
  FNR: 1,
  PROFILE_ID: 2
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.PointerCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.getPointerCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.PointerCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fnr: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profileId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFnr(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string fnr = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.getFnr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.setFnr = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.clearFnr = function() {
  return jspb.Message.setOneofField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.hasFnr = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string profile_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.getProfileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.setProfileId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.clearProfileId = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.hasProfileId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string reason = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fodselsnummer: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFodselsnummer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFodselsnummer();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fodselsnummer = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.prototype.getFodselsnummer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupRequest.prototype.setFodselsnummer = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    preferredEncoding: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (reader.readEnum());
      msg.setPreferredEncoding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreferredEncoding();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.Text.Type preferred_encoding = 2;
 * @return {!proto.oslonokkelen.proto.shared.Text.Type}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.prototype.getPreferredEncoding = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Text.Type} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataRequest.prototype.setPreferredEncoding = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 version = 1;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataRequest.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 1, 0),
    updatedXml: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedXml(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUpdatedXml();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 expected_version = 1;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string updated_xml = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.prototype.getUpdatedXml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateXmlRequest.prototype.setUpdatedXml = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    wiki: (f = msg.getWiki()) && shared_pb.Text.toObject(includeInstance, f),
    citykeyId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.Text;
      reader.readMessage(value,shared_pb.Text.deserializeBinaryFromReader);
      msg.setWiki(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitykeyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWiki();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.Text.serializeBinaryToWriter
    );
  }
  f = message.getCitykeyId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.Text wiki = 1;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.prototype.getWiki = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, shared_pb.Text, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.prototype.setWiki = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.prototype.clearWiki = function() {
  return this.setWiki(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.prototype.hasWiki = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string citykey_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.prototype.getCitykeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.UpdateKeypediaRequest.prototype.setCitykeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.repeatedFields_ = [4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.toObject(includeInstance, f),
    fodselsnummer: jspb.Message.getFieldWithDefault(msg, 3, ""),
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.toObject, includeInstance),
    phoneNumbersList: jspb.Message.toObjectList(msg.getPhoneNumbersList(),
    proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.toObject, includeInstance),
    emailsList: jspb.Message.toObjectList(msg.getEmailsList(),
    proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.toObject, includeInstance),
    approvedTermsList: jspb.Message.toObjectList(msg.getApprovedTermsList(),
    proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.toObject, includeInstance),
    keychainsList: jspb.Message.toObjectList(msg.getKeychainsList(),
    proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.toObject, includeInstance),
    version: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileId(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFodselsnummer(value);
      break;
    case 4:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 5:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.deserializeBinaryFromReader);
      msg.addPhoneNumbers(value);
      break;
    case 6:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.deserializeBinaryFromReader);
      msg.addEmails(value);
      break;
    case 7:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.deserializeBinaryFromReader);
      msg.addApprovedTerms(value);
      break;
    case 8:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.deserializeBinaryFromReader);
      msg.addKeychains(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfileId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.serializeBinaryToWriter
    );
  }
  f = message.getFodselsnummer();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.serializeBinaryToWriter
    );
  }
  f = message.getEmailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.serializeBinaryToWriter
    );
  }
  f = message.getApprovedTermsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.serializeBinaryToWriter
    );
  }
  f = message.getKeychainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain;
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.toObject = function(includeInstance, msg) {
  var f, obj = {
    first: jspb.Message.getFieldWithDefault(msg, 1, ""),
    last: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name;
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirst(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirst();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLast();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string first = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.prototype.getFirst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.prototype.setFirst = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.prototype.getLast = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name.prototype.setLast = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone;
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string country_code = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email;
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    os: jspb.Message.getFieldWithDefault(msg, 3, ""),
    model: jspb.Message.getFieldWithDefault(msg, 4, ""),
    kid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device;
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOs(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setKid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOs();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getKid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string public_key = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.getPublicKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.setPublicKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string nickname = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string os = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.getOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.setOs = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string model = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string kid = 5;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.getKid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device.prototype.setKid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm;
  return proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.getProfileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.setProfileId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Name name = 2;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.getName = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name, 2));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Name|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string fodselsnummer = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.getFodselsnummer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.setFodselsnummer = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Device devices = 4;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device, 4));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Device, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * repeated Phone phone_numbers = 5;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.getPhoneNumbersList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone, 5));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.setPhoneNumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.addPhoneNumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Phone, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.clearPhoneNumbersList = function() {
  return this.setPhoneNumbersList([]);
};


/**
 * repeated Email emails = 6;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.getEmailsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email, 6));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.setEmailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.addEmails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Email, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.clearEmailsList = function() {
  return this.setEmailsList([]);
};


/**
 * repeated ApprovedTerm approved_terms = 7;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.getApprovedTermsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm, 7));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.setApprovedTermsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.addApprovedTerms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.ApprovedTerm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.clearApprovedTermsList = function() {
  return this.setApprovedTermsList([]);
};


/**
 * repeated Keychain keychains = 8;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.getKeychainsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain, 8));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.setKeychainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.addKeychains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.Keychain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.clearKeychainsList = function() {
  return this.setKeychainsList([]);
};


/**
 * optional int32 version = 20;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.AdminUserProfileLookupResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    zip: msg.getZip_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setZip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZip_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes zip = 1;
 * @return {!(string|Uint8Array)}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.prototype.getZip = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes zip = 1;
 * This is a type-conversion wrapper around `getZip()`
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.prototype.getZip_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getZip()));
};


/**
 * optional bytes zip = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getZip()`
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.prototype.getZip_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getZip()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.GdprProfileLookupResponse.prototype.setZip = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    subjectsList: jspb.Message.toObjectList(msg.getSubjectsList(),
    proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.toObject, includeInstance),
    categoriesList: jspb.Message.toObjectList(msg.getCategoriesList(),
    shared_pb.Category.toObject, includeInstance),
    user: (f = msg.getUser()) && proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.toObject(includeInstance, f),
    backendVersion: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.deserializeBinaryFromReader);
      msg.addSubjects(value);
      break;
    case 3:
      var value = new shared_pb.Category;
      reader.readMessage(value,shared_pb.Category.deserializeBinaryFromReader);
      msg.addCategories(value);
      break;
    case 6:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackendVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSubjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.serializeBinaryToWriter
    );
  }
  f = message.getCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      shared_pb.Category.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.serializeBinaryToWriter
    );
  }
  f = message.getBackendVersion();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser;
  return proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string roles = 2;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.repeatedFields_ = [6,7,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adminRole: jspb.Message.getFieldWithDefault(msg, 4, ""),
    coordinates: (f = msg.getCoordinates()) && shared_pb.Coordinates.toObject(includeInstance, f),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    shared_pb.Image.toObject, includeInstance),
    operationsList: jspb.Message.toObjectList(msg.getOperationsList(),
    shared_pb.Operation.toObject, includeInstance),
    anomalyMessage: (f = msg.getAnomalyMessage()) && shared_pb.AnomalyMessage.toObject(includeInstance, f),
    visibleForEveryone: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    dynamicTagsList: jspb.Message.toObjectList(msg.getDynamicTagsList(),
    shared_pb.DynamicTag.toObject, includeInstance),
    wiki: (f = msg.getWiki()) && shared_pb.Keypedia.toObject(includeInstance, f),
    inProduction: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    about: (f = msg.getAbout()) && shared_pb.Text.toObject(includeInstance, f),
    citykeyId: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject;
  return proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminRole(value);
      break;
    case 5:
      var value = new shared_pb.Coordinates;
      reader.readMessage(value,shared_pb.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 6:
      var value = new shared_pb.Image;
      reader.readMessage(value,shared_pb.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 7:
      var value = new shared_pb.Operation;
      reader.readMessage(value,shared_pb.Operation.deserializeBinaryFromReader);
      msg.addOperations(value);
      break;
    case 9:
      var value = new shared_pb.AnomalyMessage;
      reader.readMessage(value,shared_pb.AnomalyMessage.deserializeBinaryFromReader);
      msg.setAnomalyMessage(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleForEveryone(value);
      break;
    case 11:
      var value = new shared_pb.DynamicTag;
      reader.readMessage(value,shared_pb.DynamicTag.deserializeBinaryFromReader);
      msg.addDynamicTags(value);
      break;
    case 12:
      var value = new shared_pb.Keypedia;
      reader.readMessage(value,shared_pb.Keypedia.deserializeBinaryFromReader);
      msg.setWiki(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInProduction(value);
      break;
    case 14:
      var value = new shared_pb.Text;
      reader.readMessage(value,shared_pb.Text.deserializeBinaryFromReader);
      msg.setAbout(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitykeyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdminRole();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      shared_pb.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      shared_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      shared_pb.Operation.serializeBinaryToWriter
    );
  }
  f = message.getAnomalyMessage();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      shared_pb.AnomalyMessage.serializeBinaryToWriter
    );
  }
  f = message.getVisibleForEveryone();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getDynamicTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      shared_pb.DynamicTag.serializeBinaryToWriter
    );
  }
  f = message.getWiki();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      shared_pb.Keypedia.serializeBinaryToWriter
    );
  }
  f = message.getInProduction();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getAbout();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      shared_pb.Text.serializeBinaryToWriter
    );
  }
  f = message.getCitykeyId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_id = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string admin_role = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional oslonokkelen.proto.shared.Coordinates coordinates = 5;
 * @return {?proto.oslonokkelen.proto.shared.Coordinates}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getCoordinates = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Coordinates} */ (
    jspb.Message.getWrapperField(this, shared_pb.Coordinates, 5));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Coordinates|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated oslonokkelen.proto.shared.Image images = 6;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Image>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.Image, 6));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Image>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Image}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.oslonokkelen.proto.shared.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * repeated oslonokkelen.proto.shared.Operation operations = 7;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Operation>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getOperationsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Operation>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.Operation, 7));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Operation>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Operation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Operation}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.addOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.oslonokkelen.proto.shared.Operation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.clearOperationsList = function() {
  return this.setOperationsList([]);
};


/**
 * optional oslonokkelen.proto.shared.AnomalyMessage anomaly_message = 9;
 * @return {?proto.oslonokkelen.proto.shared.AnomalyMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getAnomalyMessage = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.AnomalyMessage} */ (
    jspb.Message.getWrapperField(this, shared_pb.AnomalyMessage, 9));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.AnomalyMessage|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setAnomalyMessage = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.clearAnomalyMessage = function() {
  return this.setAnomalyMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.hasAnomalyMessage = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool visible_for_everyone = 10;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getVisibleForEveryone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setVisibleForEveryone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * repeated oslonokkelen.proto.shared.DynamicTag dynamic_tags = 11;
 * @return {!Array<!proto.oslonokkelen.proto.shared.DynamicTag>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getDynamicTagsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.DynamicTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.DynamicTag, 11));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.DynamicTag>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setDynamicTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.DynamicTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.DynamicTag}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.addDynamicTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.oslonokkelen.proto.shared.DynamicTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.clearDynamicTagsList = function() {
  return this.setDynamicTagsList([]);
};


/**
 * optional oslonokkelen.proto.shared.Keypedia wiki = 12;
 * @return {?proto.oslonokkelen.proto.shared.Keypedia}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getWiki = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Keypedia} */ (
    jspb.Message.getWrapperField(this, shared_pb.Keypedia, 12));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Keypedia|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setWiki = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.clearWiki = function() {
  return this.setWiki(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.hasWiki = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool in_production = 13;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getInProduction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setInProduction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional oslonokkelen.proto.shared.Text about = 14;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getAbout = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, shared_pb.Text, 14));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setAbout = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.clearAbout = function() {
  return this.setAbout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.hasAbout = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string citykey_id = 19;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.getCitykeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject.prototype.setCitykeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 version = 1;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Subject subjects = 2;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.getSubjectsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject, 2));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.setSubjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.addSubjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.Subject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.clearSubjectsList = function() {
  return this.setSubjectsList([]);
};


/**
 * repeated oslonokkelen.proto.shared.Category categories = 3;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Category>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.getCategoriesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Category>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.Category, 3));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Category>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.setCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Category=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Category}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.addCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.oslonokkelen.proto.shared.Category, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.clearCategoriesList = function() {
  return this.setCategoriesList([]);
};


/**
 * optional AdminUser user = 6;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.getUser = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser, 6));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.AdminUser|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string backend_version = 7;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.getBackendVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.DataResponse.prototype.setBackendVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, 0),
    xml: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setXml(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getXml();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 version = 1;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string xml = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.prototype.getXml = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.XmlDataResponse.prototype.setXml = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationStatusList: jspb.Message.toObjectList(msg.getOperationStatusList(),
    proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.toObject, includeInstance),
    doorLocksList: jspb.Message.toObjectList(msg.getDoorLocksList(),
    proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.toObject, includeInstance),
    adapterStatsList: jspb.Message.toObjectList(msg.getAdapterStatsList(),
    proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.deserializeBinaryFromReader);
      msg.addOperationStatus(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.deserializeBinaryFromReader);
      msg.addDoorLocks(value);
      break;
    case 3:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.deserializeBinaryFromReader);
      msg.addAdapterStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.serializeBinaryToWriter
    );
  }
  f = message.getDoorLocksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.serializeBinaryToWriter
    );
  }
  f = message.getAdapterStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    healthy: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    debugMessage: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus;
  return proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHealthy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebugMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHealthy();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDebugMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operation_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.prototype.getOperationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.prototype.setOperationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool healthy = 3;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.prototype.getHealthy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.prototype.setHealthy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string debug_message = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.prototype.getDebugMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus.prototype.setDebugMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operationId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adapterId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    thingId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    locked: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    lockedUpdatedAt: (f = msg.getLockedUpdatedAt()) && shared_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState;
  return proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThingId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLocked(value);
      break;
    case 6:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLockedUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdapterId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThingId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLocked();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLockedUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operation_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.getOperationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.setOperationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adapter_id = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.getAdapterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.setAdapterId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string thing_id = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.getThingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.setThingId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool locked = 5;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.getLocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.setLocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional oslonokkelen.proto.shared.Timestamp locked_updated_at = 6;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.getLockedUpdatedAt = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 6));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.setLockedUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.clearLockedUpdatedAt = function() {
  return this.setLockedUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState.prototype.hasLockedUpdatedAt = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.toObject = function(includeInstance, msg) {
  var f, obj = {
    adapterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connectedToBackend: jspb.Message.getFieldWithDefault(msg, 2, ""),
    connectedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastPingAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pingLatencyMillis: jspb.Message.getFieldWithDefault(msg, 5, 0),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    statusIsWarning: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState;
  return proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectedToBackend(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConnectedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastPingAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPingLatencyMillis(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatusIsWarning(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdapterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnectedToBackend();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConnectedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLastPingAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getPingLatencyMillis();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatusIsWarning();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string adapter_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.getAdapterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.setAdapterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string connected_to_backend = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.getConnectedToBackend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.setConnectedToBackend = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 connected_at = 3;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.getConnectedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.setConnectedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 last_ping_at = 4;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.getLastPingAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.setLastPingAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 ping_latency_millis = 5;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.getPingLatencyMillis = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.setPingLatencyMillis = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string status_message = 6;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool status_is_warning = 7;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.getStatusIsWarning = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState.prototype.setStatusIsWarning = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated OperationStatus operation_status = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.getOperationStatusList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.setOperationStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.addOperationStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.OperationStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.clearOperationStatusList = function() {
  return this.setOperationStatusList([]);
};


/**
 * repeated DoorLockState door_locks = 2;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.getDoorLocksList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState, 2));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.setDoorLocksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.addDoorLocks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.DoorLockState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.clearDoorLocksList = function() {
  return this.setDoorLocksList([]);
};


/**
 * repeated AdapterState adapter_stats = 3;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState>}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.getAdapterStatsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState, 3));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.setAdapterStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState}
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.addAdapterStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.AdapterState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.data.StatusDataResponse.prototype.clearAdapterStatsList = function() {
  return this.setAdapterStatsList([]);
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.data);
