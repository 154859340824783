import { zodResolver } from '@hookform/resolvers/zod';
import { formatDistance } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../components/Button/Button';
import { CreateSection } from '../../components/CreateSection/CreateSection';
import { EndDateTimePicker } from '../../components/DatePickers/EndDateTimePicker';
import { StartDateTimePicker } from '../../components/DatePickers/StartDateTimePicker';
import { KeySelect } from '../../components/Forms/KeySelect';
import {
  FetchOperationLogAudits,
  fetchOperationLogAudits
} from '../../redux/actions/operations';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { subjectSelectors } from '../../redux/slice/subjects';

interface Option {
  labelText: string;
  description: string;
  value: string;
}

export const AuditForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const subjects = useAppSelector(subjectSelectors.selectAll);

  const subjectOptions: Option[] = useMemo(() => {
    return subjects.map(subject => {
      return {
        value: subject.id,
        labelText: subject.name,
        description: subject.id
      };
    });
  }, [subjects.length]);

  const [operationOptions, setOperationOptions] = useState<Option[]>([]);

  const methods = useForm<FetchOperationLogAudits>({
    resolver: zodResolver(FetchOperationLogAudits),
    defaultValues: {
      fromDate: new Date(),
      untilDate: new Date()
    }
  });

  const { watch, setValue, handleSubmit } = methods;
  const [fromDate, untilDate, selectedSubject, selectedOperation] = watch([
    'fromDate',
    'untilDate',
    'selectedSubject',
    'selectedOperation'
  ]);

  const varighet = formatDistance(fromDate, untilDate);

  useEffect(() => {
    const subject = subjects.find(
      subject => subject.id === selectedSubject?.value
    );
    setValue('selectedOperation', { value: '', label: 'Velg en operasjon' });
    if (subject) {
      setOperationOptions(
        subject.operationsList.map(operation => {
          return {
            value: operation.id,
            labelText: operation.name,
            description: operation.id
          };
        })
      );
    }
  }, [selectedSubject]);

  const submit = (data: FetchOperationLogAudits) =>
    dispatch(fetchOperationLogAudits(data));

  return (
    <CreateSection aria-label='Ny søkslogg'>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <KeySelect
            name='selectedSubject'
            label='Sted'
            description='Velg stedet du vil se loggen for'
            elementProps={{
              options: subjectOptions,
              placeholder: 'Velg et sted'
            }}
          />
          {selectedSubject && (
            <KeySelect
              name='selectedOperation'
              label='Operasjon'
              description='Velg døren du vil se loggen for'
              elementProps={{
                options: operationOptions,
                placeholder: 'Velg en operasjon'
              }}
            />
          )}
          {selectedOperation?.value && (
            <React.Fragment>
              <StartDateTimePicker
                name='fromDate'
                startDate={methods.getValues('fromDate')}
                endDate={methods.getValues('untilDate')}
                inline
              />{' '}
              –{' '}
              <EndDateTimePicker
                name='untilDate'
                startDate={methods.getValues('fromDate')}
                endDate={new Date()}
                inline
              />
              <p>
                Du søker innenfor en varighet på: <b>{varighet}</b>
              </p>
              <br />
              <Button label='Se besøkslogger' type='submit' />
            </React.Fragment>
          )}
        </form>
      </FormProvider>
    </CreateSection>
  );
};
