import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { KeychainFactoryDetails } from '../../redux/slice/keychainFactoryDetails';
import { KeychainFactorySummary } from '../../redux/slice/keychainFactorySummaries';
import { KeychainFactoryBreadcrumbs } from './KeychainFactoryBreadcrumbs';

interface Props {
  keychainFactory: KeychainFactoryDetails | KeychainFactorySummary;
}
type ContextType = {
  keychainFactory: KeychainFactoryDetails;
};

export const KeychainDetailsWrapper: React.FC<Props> = ({
  keychainFactory
}) => {
  if (keychainFactory.isDetails) {
    return <Outlet context={{ keychainFactory } satisfies ContextType} />;
  } else {
    return (
      <section>
        <KeychainFactoryBreadcrumbs keychainFactory={keychainFactory} />
        <LoadingSpinner />
      </section>
    );
  }
};

export const useKeychainFactoryDetails = () => {
  return useOutletContext<ContextType>();
};
