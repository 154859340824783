import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  Breadcrumbs,
  generateKeychainBreadcrumbs
} from '../../components/Navigation/breadcrumbs';
import { SubjectsContainer } from '../../components/SubjectsContainer/SubjectsContainer';
import { listKeychainFactoryConfigurations } from '../../redux/actions/keychainFactorySummaries';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectKeychainFactories } from '../../redux/slice/keychainFactorySummaries';
import { clearSelectedAdminTool } from '../../redux/slice/ui';
import { filterKeychainFactories } from '../../utils/keychainFactory/filterBySearch';
import { urlToDomainMap } from '../../utils/keychainFactory/filterByType';
import { getKeychainType } from '../../utils/storage/webStorage';
import { KeychainFactoryCardsContainer } from './keychainFactoryCards/KeychainFactoryCardsContainer';
import { TopInfo } from './keychainFactoryCards/TopInfo/TopInfo';
import { FilterButtons } from './keychainFactoryCards/filterButtonGroup/FilterButtons';

export const Home = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const keychainFactories = useAppSelector(selectKeychainFactories.selectAll);
  const dispatch = useAppDispatch();

  const keychainType =
    urlToDomainMap[useParams().keychainType || getKeychainType()];

  const setSearch = (search: string) => {
    const searchParams = search ? { filter: search } : undefined;
    setSearchParams(searchParams, { replace: true });
  };
  const search = searchParams.get('filter') || '';
  const filteredKeychainFactories = filterKeychainFactories(
    keychainFactories,
    search,
    keychainType
  );

  useEffect(() => {
    dispatch(clearSelectedAdminTool());
    dispatch(listKeychainFactoryConfigurations());
  }, [dispatch]);

  return (
    <section className='home-container'>
      <SubjectsContainer>
        <Breadcrumbs breadcrumbs={generateKeychainBreadcrumbs(keychainType)} />
        <TopInfo
          numOfKeychainFactories={filteredKeychainFactories.length}
          total={keychainFactories.length}
          search={search}
          setSearch={setSearch}
        />
        <FilterButtons
          keychainType={keychainType}
          keychainFactories={keychainFactories}
          searchParams={searchParams}
        />

        <KeychainFactoryCardsContainer
          keychainFactories={filteredKeychainFactories}
        />
      </SubjectsContainer>
    </section>
  );
};
