import React from 'react';
import { ClockIcon } from '../../../assets/ClockIcon';
import { OperationInfoCard } from '../../operation/operationCards/OperationInfoCard';
import './OpeningHoursCard.scss';

export const OpeningHoursCard = () => {
  const text = '';
  return (
    <OperationInfoCard
      icon={<ClockIcon />}
      header='Åpningstider'
      link={`åpningstider/lag-ny`}
      text={text}
    />
  );
};
