import React from 'react';
import { ActionMenu } from '../../../../components/ActionMenu/ActionMenu';
import { ActionMenuContainer } from '../../../../components/ActionMenu/ActionMenuContainer';
import { useDialogBox } from '../../../../components/DialogBox/useDialogBox';
import { Keychain } from '../../../../redux/slice/keychains';
import { KeychainDialogs } from './KeychainDialogs';
import { generateActionMenuItems } from './keychainDataGenerator';

type Props = {
  keychain: Keychain;
  keychainFactoryUri: string;
};

export const KeychainDetailsMenu: React.FC<Props> = ({
  keychain,
  keychainFactoryUri
}) => {
  const [DeleteDialog, openDelete, closeDelete] = useDialogBox();
  const [EditDialog, openEdit, closeEdit] = useDialogBox();
  const [EmailDialog, openEmail, closeEmail] = useDialogBox();
  const [RolesDialog, openRoles, closeRoles] = useDialogBox();
  const actions = generateActionMenuItems(
    keychain,
    openEdit,
    openDelete,
    openEmail,
    openRoles
  );

  return (
    <ActionMenuContainer>
      <div className='user-info-text'>Brukerinformasjon</div>
      <ActionMenu actions={actions} />
      <KeychainDialogs
        keychain={keychain}
        keychainFactoryUri={keychainFactoryUri}
        DeleteDialog={DeleteDialog}
        closeDelete={closeDelete}
        EditDialog={EditDialog}
        closeEdit={closeEdit}
        EmailDialog={EmailDialog}
        closeEmail={closeEmail}
        RolesDialog={RolesDialog}
        closeRoles={closeRoles}
      />
    </ActionMenuContainer>
  );
};
