import { formatISO } from 'date-fns';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { EndDatePicker } from '../../../../components/DatePickers/EndDatePicker';
import { DialogBoxType } from '../../../../components/DialogBox/useDialogBox';
import { extendAllKeychainsExpireDateRequest } from '../../../../redux/actions/keychains';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';

const date = new Date();

interface Props {
  DialogBox: DialogBoxType;
  keychainFactoryUri: string;
  closeModal: () => void;
}

export const EditAllApprovedKeychains: React.FC<Props> = ({
  closeModal,
  DialogBox,
  keychainFactoryUri
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<{ endDate: Date }>({
    values: {
      endDate: date
    }
  });
  const { endDate } = methods.watch();
  const onUpdate = useMemo(() => {
    return () =>
      dispatchAndHandleResult(
        dispatch,
        extendAllKeychainsExpireDateRequest,
        {
          newExpireDate: formatISO(new Date(endDate), {
            representation: 'date'
          }),
          keychainFactoryUri: keychainFactoryUri
        },
        closeModal
      );
  }, [closeModal, dispatch, endDate, keychainFactoryUri]);

  return (
    <DialogBox
      buttonLabel='Oppdater'
      onSubmit={onUpdate}
      methods={methods}
      header='Endre tilgang for alle brukere'
    >
      <div>
        Tilgangen for <b>samtlige brukere</b> endres
      </div>
      <div className='grid-container'>
        <EndDatePicker
          name='endDate'
          startDate={new Date()}
          endDate={endDate}
          position='right'
        />
      </div>
    </DialogBox>
  );
};
