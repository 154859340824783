import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { EndDateTimePicker } from '../../../components/DatePickers/EndDateTimePicker';
import { StartDateTimePicker } from '../../../components/DatePickers/StartDateTimePicker';
import { DialogFormBox } from '../../../components/DialogBox/DialogFormBox';
import { KeySelect } from '../../../components/Forms/KeySelect';
import { KeyText } from '../../../components/Forms/KeyText';
import {
  CreateGroupLink,
  createGroupLink
} from '../../../redux/actions/groupLinks';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { KeychainFactoryDetails } from '../../../redux/slice/keychainFactoryDetails';
import { FullOperationId } from '../../../redux/types/protoTypes';

type Props = {
  closeModal: () => void;
  isModalOpen: boolean;
  keychainFactory: KeychainFactoryDetails;
};

type Option = {
  label: string;
  value: FullOperationId;
};
export const NewGroupLink: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  keychainFactory
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<CreateGroupLink>({
    resolver: zodResolver(CreateGroupLink),
    defaultValues: {
      fromDate: new Date(),
      untilDate: new Date(),
      keychainFactoryUri: keychainFactory.keychainFactoryUri,
      fullOperationId: {
        value: {
          operationId: '',
          subjectId: ''
        },
        label: 'Velg en operasjon'
      }
    }
  });
  const { reset, watch } = methods;
  const [fromDate, untilDate] = watch(['fromDate', 'untilDate']);
  const options: Option[] = (
    keychainFactory.policiesList.createGroupLink?.eligibleOperationIdsList || []
  ).map(operation => {
    return {
      value: operation,
      label: operation.subjectId + ' / ' + operation.operationId
    };
  });

  return (
    <DialogFormBox
      methods={methods}
      buttonLabel='Lag spredning'
      onSubmit={data =>
        dispatchAndHandleResult(dispatch, createGroupLink, data, () => {
          closeModal();
          reset();
        })
      }
      header='Spre nøkkel'
      closeModal={() => {
        closeModal();
        reset();
      }}
      isModalOpen={isModalOpen}
    >
      <KeyText name='title' elementProps={{}} label='Tittel' />
      <KeyText name='publicDescription' elementProps={{}} label='Beskrivelse' />
      <KeySelect
        label='Operasjon'
        name='fullOperationId'
        elementProps={{
          options: options,
          placeholder: 'Velg en operasjon'
        }}
      />
      <StartDateTimePicker
        name='fromDate'
        startDate={fromDate}
        endDate={untilDate}
        inline
        position='top-end'
      />{' '}
      -{' '}
      <EndDateTimePicker
        name='untilDate'
        startDate={fromDate}
        endDate={untilDate}
        inline
      />
    </DialogFormBox>
  );
};
