import React from 'react';
import { Button, ButtonProps } from '../Button/Button';
import './ToggleButton.scss';

interface ToggleGroupProps {
  buttons: ToggleButtonProps[];
  selectedValue: string;
}

const ToggleGroup: React.FC<ToggleGroupProps> = ({
  buttons,
  selectedValue
}) => {
  const buttonsElements = buttons.map(button => {
    return (
      <ToggleButton
        key={button.value}
        {...button}
        selected={button.value === selectedValue}
      ></ToggleButton>
    );
  });
  return <div className='button-toggle'>{buttonsElements}</div>;
};

export interface ToggleButtonProps extends ButtonProps {
  selected?: boolean;
  value: string;
}

const ToggleButton: React.FC<ToggleButtonProps> = props => {
  return (
    <Button
      {...props}
      type={props.selected ? 'selected' : 'unselected'}
    ></Button>
  );
};

export { ToggleGroup };
