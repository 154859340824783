import React from 'react';
import { CaretSvg } from '../../assets/CaretSvg';
import { ActionMenu } from '../ActionMenu/ActionMenu';
import { ActionMenuItemProps } from '../ActionMenu/ActionMenuItem';
import './TextToggle.scss';

interface TextToggleProps {
  text: string;
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
  actions?: ActionMenuItemProps[];
}

export const TextToggle: React.FC<TextToggleProps> = ({
  text,
  toggle,
  setToggle,
  actions = []
}) => (
  <div className='keystudio-text-toggle'>
    <h3 onClick={() => setToggle(!toggle)}>
      <div className={toggle ? 'up' : 'down'}>
        <CaretSvg />
      </div>
      {text}
    </h3>
    {actions.length > 0 && (
      <ActionMenu actions={actions} placement='bottom-start' />
    )}
  </div>
);
