import React from 'react';
import { CommonHourComponent, CommonHourProps } from './CommonHourComponent';

interface Props extends CommonHourProps {
  day: string;
}
export const OpeningHourComponent: React.FC<Props> = props => {
  return (
    <li className='day-line'>
      <div className='day'>{props.day.toLowerCase()}</div>
      <CommonHourComponent {...props} />
    </li>
  );
};
