import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminRoleCard } from '../../pages/keychainFactory/AdminRoleCard/AdminRoleCard';
import { SubjectBreadcrumbs } from '../../pages/places/SubjectBreadcrumbs';
import { useSubject } from '../../pages/places/SubjectWrapper';
import { updateKeypedia } from '../../redux/actions/appData';
import { dispatchAndHandleResult } from '../../redux/actions/util';
import { useAppDispatch } from '../../redux/hooks';
import { historyGoBack } from '../../utils/history';
import { PageInfoText } from '../PageInfoText/PageInfoText';
import { KeypediaEditor } from './KeypediaEditor';

export const EditKeypediaSubject: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { subject } = useSubject();

  const handleSaveClick = async (updatedText: string) =>
    dispatchAndHandleResult(
      dispatch,
      updateKeypedia,
      {
        citykeyId: subject.citykeyId,
        content: updatedText
      },
      () => historyGoBack(navigate)
    );

  return (
    <div>
      <SubjectBreadcrumbs subject={subject} name='Interninfo og -notater' />
      <AdminRoleCard adminRole={subject.adminRole} type='stedet' />
      <PageInfoText type='medium'>
        Her kan man skrive hva som helst om stedet. For eksempel beskrivelse av
        oppkoblingen, instruksjoner ved nedetid, eller andre ting som kan være
        relevant for admin-brukere. Informasjonen her er ikke tilgjengelig i
        appen.
      </PageInfoText>
      <KeypediaEditor saveCallback={handleSaveClick} wiki={subject.wiki} />
    </div>
  );
};
