import React from 'react';
import {
  actionsHome,
  adminAdapterHome,
  adminCategoryHome,
  adminKeychainHome,
  adminSubjectHome,
  adminAdmissionCardsHome,
  godModeHome,
  pvdHome,
  downloadsHome,
  thirdPartySystemHome,
  adminOidcHome,
  adminPublicKeychainHome,
  adminBucketHome,
  faqHome
} from '../../components/Navigation/controlpanelNavPaths';
import { ControlPanelCard } from '../../components/cards/SuperAdminCard/ControlPanelCard';
import { keycloak } from '../../keycloak/keycloak-config';
import { useAppSelector } from '../../redux/hooks';
import { CopyWrapper } from '../../utils/id/CopyWrapper';
import './Controlpanel.scss';
import { ControlpanelBreadcrumbs } from './ControlpanelBreadcrumbs';

export const Controlpanel: React.FC = () => {
  const backendVersion = useAppSelector(state => state.appData.backendVersion);
  return (
    <section className='controlpanel'>
      <ControlpanelBreadcrumbs />
      <div className='controlpanel-cards'>
        <ControlPanelCard {...actionsHome} />
        <ControlPanelCard {...adminAdapterHome} />
        <ControlPanelCard {...adminAdmissionCardsHome} />
        <ControlPanelCard {...adminCategoryHome} />
        <ControlPanelCard {...faqHome} />
        <ControlPanelCard {...adminKeychainHome} />
        <ControlPanelCard {...pvdHome} />
        <ControlPanelCard {...adminPublicKeychainHome} />
        <ControlPanelCard {...adminSubjectHome} />
        <ControlPanelCard {...thirdPartySystemHome} />
        <ControlPanelCard {...adminBucketHome} />
        <ControlPanelCard {...adminOidcHome} />
        <ControlPanelCard {...downloadsHome} />
        <ControlPanelCard {...godModeHome} />
      </div>
      <footer>
        <p>
          <strong>Keystudio build: </strong>
          <a
            href={
              'https://github.com/oslokommune/oslonokkelen-admin/commit/' +
              process.env.REACT_APP_BUILD_VERSION
            }
          >
            {process.env.REACT_APP_BUILD_VERSION} [{process.env.REACT_APP_ENV}]
          </a>
        </p>

        <p>
          <strong>Keystudio build date: </strong>
          {process.env.REACT_APP_BUILD_DATE}
        </p>

        <p>
          <strong>Backend build: </strong>
          <i>{backendVersion}</i>
        </p>
        <p>
          <strong>Access token: </strong>
          <CopyWrapper copyValue={keycloak.token}>
            Klikk for å kopiere
          </CopyWrapper>
        </p>
      </footer>
    </section>
  );
};
