import React from 'react';

export const Bucket = () => (
  <svg
    width='136'
    height='136'
    viewBox='0 0 136 136'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M68 136C105.555 136 136 105.555 136 68C136 30.4446 105.555 0 68 0C30.4446 0 0 30.4446 0 68C0 105.555 30.4446 136 68 136Z'
      fill='#F9B3AB'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M69.4698 32C55.2365 32 43.7917 44.3243 43.5721 60.5767H40.893C40.8651 60.5754 40.8372 60.5754 40.8093 60.5767C40.5873 60.5977 40.3811 60.7009 40.2313 60.8662C40.0816 61.0314 39.999 61.2467 40 61.4698V68.614C40 68.8508 40.0941 69.0779 40.2616 69.2454C40.4291 69.4129 40.6562 69.507 40.893 69.507H43.7116L51.1349 103.274C51.1734 103.476 51.2806 103.659 51.4382 103.791C51.5958 103.923 51.7943 103.997 52 104H87.7209C87.9266 103.997 88.125 103.923 88.2827 103.791C88.4403 103.659 88.5475 103.476 88.586 103.274L95.2279 69.507H98.0465C98.2834 69.507 98.5105 69.4129 98.678 69.2454C98.8454 69.0779 98.9395 68.8508 98.9395 68.614V61.4698C98.9395 61.2329 98.8454 61.0058 98.678 60.8383C98.5105 60.6709 98.2834 60.5768 98.0465 60.5767H95.3675C95.1478 44.3243 83.703 32 69.4698 32ZM69.4698 33.786C82.6668 33.786 93.3643 45.1581 93.5814 60.5767H45.3581C45.5752 45.1581 56.2728 33.786 69.4698 33.786ZM45.5862 69.507H45.4977L46.4869 73.9834L45.5862 69.507Z'
      fill='#2A2859'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65.9758 56.4244C65.6539 56.4244 65.3359 56.4415 65.0228 56.4748C63.7491 55.7739 62.2858 55.3752 60.7293 55.3752C57.4702 55.3752 54.6193 57.1234 53.0632 59.7331C52.1542 58.9775 50.986 58.5232 49.7116 58.5232C46.8141 58.5232 44.4651 60.8721 44.4651 63.7697C44.4651 65.3947 45.2039 66.8472 46.364 67.8096C45.8162 69.1532 45.5144 70.6233 45.5144 72.1639C45.5144 75.5913 47.0082 78.6697 49.3803 80.7837C48.9218 81.6571 48.6623 82.6514 48.6623 83.7063C48.6623 86.3722 50.3192 88.651 52.6593 89.5693C52.1264 90.273 51.8103 91.15 51.8103 92.1008C51.8103 94.4188 53.6894 96.298 56.0075 96.298C58.3255 96.298 60.2047 94.4188 60.2047 92.1008C60.2047 90.7742 59.5893 89.5914 58.6284 88.8223C59.9515 87.8713 60.8906 86.4199 61.1687 84.745C65.3598 84.5417 68.7916 81.4455 69.51 77.4104H66.5004V60.6216H96.9305C95.9733 59.3474 94.4494 58.5232 92.733 58.5232C92.5712 58.5232 92.411 58.5305 92.2529 58.5449C90.9914 57.8616 89.5466 57.4736 88.0112 57.4736C85.5084 57.4736 83.2463 58.5045 81.6265 60.1648C80.1704 59.131 78.3906 58.5232 76.4688 58.5232C75.0908 58.5232 73.7857 58.8357 72.6206 59.3938C70.9875 57.5712 68.6156 56.4244 65.9758 56.4244Z'
      fill='#F9DDAB'
    />
    <ellipse
      cx='90.1098'
      cy='57.9983'
      rx='1.57395'
      ry='1.57395'
      fill='#F9DDAB'
    />
    <ellipse
      cx='67.5498'
      cy='65.8681'
      rx='6.29581'
      ry='6.29581'
      fill='#F9DDAB'
    />
    <ellipse
      cx='67.0251'
      cy='74.7873'
      rx='3.67256'
      ry='3.67256'
      fill='#F9DDAB'
    />
    <ellipse
      cx='85.3879'
      cy='57.4736'
      rx='3.14791'
      ry='3.14791'
      fill='#F9DDAB'
    />
    <path
      d='M53 66.5C51.5 72 55.2 77.3 62 76.5'
      stroke='#F9C66B'
      strokeWidth='0.5'
    />
    <path
      d='M64 63C64.643 61.3632 62.915 59.7859 60 60.0239'
      stroke='#F9C66B'
      strokeWidth='0.5'
    />
  </svg>
);
