import { FormattedOpeningHours } from './formatOpeningHours';

export const sortDays = (a: { day: DayEng }, b: { day: DayEng }) => {
  const aIndex = daysEng.indexOf(a.day);
  const bIndex = daysEng.indexOf(b.day);
  return aIndex < bIndex ? -1 : 1;
};

export const sortOpeningHours = (
  a: FormattedOpeningHours,
  b: FormattedOpeningHours
) => {
  const aType = a.type;
  const bType = b.type;

  if (aType === 'public' || bType === 'public') {
    return 1;
  } else {
    return aType < bType ? -1 : 1;
  }
};

export const daysEng = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
] as const;

export const daysNor = [
  'MANDAG',
  'TIRSDAG',
  'ONSDAG',
  'TORSDAG',
  'FREDAG',
  'LØRDAG',
  'SØNDAG'
] as const;

type DayEng = (typeof daysEng)[number];
export type DayNor = (typeof daysNor)[number];
export const getDayNor = (day: DayEng) => daysNor[daysEng.indexOf(day)];

export const getDayEng = (day: DayNor) => daysEng[daysNor.indexOf(day)];
