import { z } from 'zod';
import {
  CreateProximityVerificationDeviceRequest,
  ListProximityVerificationDevicesRequest,
  ProximityVerificationDevice
} from '../../proto/pvd_pb';
import {
  getGrpcMetadata,
  getPvdClient,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import { PvdDetails } from '../slice/pvd';
import { id, zeroOrMore } from '../types/zodSchemas';

export const CreatePvdDetails = z.object({
  id: id,
  timeDriftSeconds: zeroOrMore, //z.number().min(0, 'Tidsavvik må være 0 eller høyere'),
  timeStepSeconds: z.preprocess(
    val => Number(val),
    z.number().min(10, 'Regenereringssyklus må være 10 sekunder eller høyere')
  ),
  discrepancyTolerance: zeroOrMore
});
export type CreatePvdDetails = z.infer<typeof CreatePvdDetails>;

export const createProximityVerificationDevice = createAppAsyncThunk<
  PvdDetails,
  CreatePvdDetails
>(
  'pvd/createProximityVerificationDevice',
  async (
    { id, timeDriftSeconds, timeStepSeconds, discrepancyTolerance },
    thunkAPI
  ) => {
    try {
      const device = new ProximityVerificationDevice()
        .setId(id)
        .setTimeDriftSeconds(timeDriftSeconds)
        .setTimeStepSeconds(timeStepSeconds)
        .setDiscrepancyTolerance(discrepancyTolerance);
      const capabilities = [ProximityVerificationDevice.Capability.QR];
      device.setCapabilitiesList(capabilities);

      const request = new CreateProximityVerificationDeviceRequest();
      request.setDevice(device);
      await getPvdClient().createProximityVerificationDevice(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(listProximityVerificationDevice());
      return convertToPvdDetails(device.toObject());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const listProximityVerificationDevice = createAppAsyncThunk<
  PvdDetails[]
>('pvd/listProximityVerificationDevice', async (_, thunkAPI) => {
  try {
    const request = new ListProximityVerificationDevicesRequest();
    const response = (
      await getPvdClient().listProximityVerificationDevices(
        request,
        await getGrpcMetadata()
      )
    ).toObject();
    return response.devicesList.map(convertToPvdDetails);
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

const convertToPvdDetails = (
  pvdObject: ProximityVerificationDevice.AsObject
): PvdDetails => {
  return {
    id: pvdObject.id,
    secret: pvdObject.secret,
    timeDriftSeconds: pvdObject.timeDriftSeconds,
    timeStepSeconds: pvdObject.timeStepSeconds,
    discrepancyTolerance: pvdObject.discrepancyTolerance,
    capabilitiesList: pvdObject.capabilitiesList,
    lastModifiedTimestamp: pvdObject.lastModified?.epochMillis || 0
  };
};
