import React from 'react';

export const ShareIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='53'
    height='23'
    viewBox='0 0 53 23'
    fill='none'
  >
    <path
      d='M17.9206 11.2049C17.9045 9.88939 17.5002 8.60789 16.7585 7.52127C16.0167 6.43465 14.9705 5.59134 13.7512 5.09718C12.5319 4.60301 11.1938 4.48001 9.90478 4.74362C8.61581 5.00722 7.4334 5.64568 6.50598 6.57886C5.57856 7.51205 4.94743 8.69838 4.69182 9.98897C4.4362 11.2795 4.5675 12.6169 5.06921 13.8331C5.57091 15.0494 6.42068 16.0903 7.51186 16.8253C8.60305 17.5604 9.88705 17.9567 11.2027 17.9646C12.9894 17.9574 14.7003 17.2416 15.9598 15.9743C17.2193 14.707 17.9245 12.9917 17.9206 11.2049ZM21.9818 13.4609C21.4334 16.2408 19.8325 18.7014 17.5133 20.3292C15.1942 21.9569 12.3359 22.6261 9.5352 22.1969C6.63624 21.7049 4.04929 20.0869 2.33821 17.6957C0.627132 15.3044 -0.0693461 12.3337 0.400589 9.43104C0.762866 6.85601 2.0302 4.49412 3.97554 2.76854C5.92088 1.04296 8.4171 0.0664396 11.0169 0.0139503C13.6168 -0.038539 16.1504 0.836428 18.1638 2.48209C20.1772 4.12775 21.5388 6.43656 22.0048 8.99486L52.9543 9.17017L52.9293 13.6363L48.5467 13.6112L48.4987 22.5455L44.1161 22.5204L44.1411 18.0502H44.1244C44.1244 18.0502 44.1244 18.0397 44.1244 18.0356C44.135 17.7438 44.0868 17.4528 43.9828 17.18C43.8788 16.9072 43.7211 16.658 43.519 16.4473C43.3169 16.2365 43.0746 16.0685 42.8064 15.9532C42.5381 15.8379 42.2495 15.7776 41.9575 15.7759C41.6655 15.7742 41.3762 15.8312 41.1067 15.9434C40.8371 16.0557 40.5928 16.2209 40.3883 16.4293C40.1838 16.6377 40.0233 16.885 39.9162 17.1567C39.8091 17.4283 39.7576 17.7186 39.7648 18.0105C39.7648 18.0105 39.7648 18.0105 39.7648 18.0251H39.7523L39.7272 22.4954L35.3446 22.4703L35.3926 13.5361L21.9818 13.4609Z'
      fill='#2A2859'
    />
  </svg>
);
