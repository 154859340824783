import React from 'react';

export const AccessControl = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='136'
    height='136'
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12161'
      data-name='Group 12161'
      transform='translate(-328 -232)'
    >
      <circle
        id='Ellipse_691'
        data-name='Ellipse 691'
        cx='68'
        cy='68'
        r='68'
        transform='translate(328 232)'
        fill='#f9b3ab'
      />
      <g
        id='Group_12122'
        data-name='Group 12122'
        transform='translate(-209 -429)'
      >
        <g
          id='Rectangle_1801'
          data-name='Rectangle 1801'
          transform='translate(566 711)'
          fill='none'
          stroke='#292858'
          strokeWidth='3'
        >
          <rect width='79' height='37' stroke='none' />
          <rect x='1.5' y='1.5' width='76' height='34' fill='none' />
        </g>
        <path
          id='Path_4580'
          data-name='Path 4580'
          d='M-10028.461-643.689l5.238-1.97,8.742,3.126'
          transform='translate(10606.094 1367.654)'
          fill='none'
          stroke='#292858'
          strokeWidth='3'
        />
        <path
          id='Path_4582'
          data-name='Path 4582'
          d='M-10019.057-643.872l-3.524-1.786-5.88,2.835'
          transform='translate(10652.034 1376.492)'
          fill='none'
          stroke='#292858'
          strokeWidth='3'
        />
        <path
          id='Path_4583'
          data-name='Path 4583'
          d='M-10019.057-643.872l-3.524-1.786-5.88,2.835'
          transform='translate(10619.145 1379.724)'
          fill='none'
          stroke='#292858'
          strokeWidth='3'
        />
      </g>
    </g>
  </svg>
);
