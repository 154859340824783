import React, { useEffect } from 'react';
import { Table } from '../../components/Table/Table';
import { HeadingProps } from '../../components/Table/TableHeader';
import { sortData } from '../../components/Table/sortUtil';
import { useSort } from '../../components/Table/useSort';
import { AuditLog } from '../../redux/slice/operations';
import {
  formatDateFromEpochMillis,
  formatInterval
} from '../../utils/timeConverter';

type Props = {
  log: AuditLog[];
};
export const AuditTable: React.FC<Props> = ({ log }) => {
  const [sorted, setSorted] = React.useState(log);
  const [sortKey, sortReversed, setSortKey] = useSort<AuditLog>();
  const headings: HeadingProps<AuditLog>[] = [
    { element: 'Søkt utført av', sortKey: 'email' },
    { element: 'Tidspunkt for søk', sortKey: 'timestamp' },
    { element: 'Operasjon', sortKey: 'operationId' },
    { element: 'Søkeintervall' },
    { element: 'Antall treff', sortKey: 'entriesFound' }
  ];

  useEffect(() => {
    setSorted(sortData(log, sortKey, sortReversed));
  }, [log, sortReversed, sortKey]);

  const rows = sorted.map(entry => {
    return {
      cells: [
        entry.email,
        formatDateFromEpochMillis(entry.timestamp),
        `${entry.subjectId}/${entry.operationId}`,
        formatInterval(
          entry.from.date,
          entry.until.date,
          entry.from.time,
          entry.until.time
        ),
        entry.entriesFound
      ]
    };
  });

  return (
    <section className='audit-table'>
      <h3>Logg over utførte søk</h3>
      <Table
        headings={headings}
        rows={rows}
        sortKey={sortKey}
        setSortKey={setSortKey}
        sortReversed={sortReversed}
      />
    </section>
  );
};
