import { setDefaultOptions } from 'date-fns';
import { nb } from 'date-fns/locale';
import React from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { createRoot } from 'react-dom/client';
import { AppNotLoggedIn } from './AppNotLoggedIn';
import { AppWithStore } from './AppWithStore';
import { initOptions, keycloak } from './keycloak/keycloak-config';
import { store } from './redux';
import { getAppData } from './redux/actions/appData';
import { cacheCheck } from './utils/cacheCheck/cacheCheck';
import { sendLoginStatistics } from './utils/requests/statisticsClient';
import {
  deleteLoginTimestamp,
  getLoginMethod,
  getTimeSinceLoginStarted,
  getUseSafetycloak,
  setLoginTimestampIfNotSet
} from './utils/storage/webStorage';

registerLocale('nb', nb);
setDefaultLocale('nb');
setDefaultOptions({ locale: nb });

const assumedSafetycloak = getUseSafetycloak() === 'yes';

// When something fails here the user is redirected back to keycloak login.
// Upon redirect back to this app this function will run again.
// The user will not get through this without a valid keycloak object in redux-store.
const initKeycloak = async () => {
  return new Promise((resolve, reject) => {
    setLoginTimestampIfNotSet();
    keycloak
      .init(initOptions)
      .then(() => {
        if (!keycloak.authenticated) {
          console.warn('still not logged in after intitializing keycloak');
          keycloak.login();
        } else {
          const timelapsed = getTimeSinceLoginStarted();
          sendLoginStatistics(
            timelapsed,
            window.navigator.userAgent,
            keycloak.realm || 'unknown'
          );
          resolve(null);
        }
      })
      .catch(() => {
        console.error("Couldn't initialize Keycloak.");
        keycloak.logout();
        reject();
      })
      .finally(() => {
        deleteLoginTimestamp();
      });
  });
};

const waitForKeycloak = async () => {
  cacheCheck();
  if (getLoginMethod() === 'ask') {
    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(<AppNotLoggedIn loginAndRunApp={loginAndRunApp} />);
  } else {
    await loginAndRunApp();
  }
};

const loginAndRunApp = async () => {
  if (assumedSafetycloak === (getUseSafetycloak() === 'yes')) {
    await initKeycloak();
    runApp();
  } else {
    location.reload();
  }
};

const runApp = () => {
  store.dispatch(getAppData());
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<AppWithStore store={store} />);
};

waitForKeycloak();
