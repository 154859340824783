import React from 'react';

export const EditSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='48'
    height='48'
    viewBox='0 0 48 48'
  >
    <title>Rediger</title>
    <g id='Group_1529' data-name='Group 1529' transform='translate(-1016 -248)'>
      <circle
        id='Ellipse_154'
        data-name='Ellipse 154'
        cx='24'
        cy='24'
        r='24'
        transform='translate(1016 248)'
        fill='#f8f0dd'
      />
      <path
        id='Path_2939'
        data-name='Path 2939'
        d='M22.614,4.691,18.535.611,15.563,3.581h0L7.107,12.037h0l-.471.471L5.22,17.991,10.7,16.576l.842-.842,8.1-8.074h0l.627-.627.4-.394h0Zm-4.08-2.037L20.569,4.69l-.927.927L17.608,3.581Zm-8.772.424V1.632H.016v20.22H23.129l0-8.739H21.679l0,7.293H1.461V3.078Zm-.611,8.959,2.024,2.023,7.445-7.422L16.585,4.6ZM8.129,13.059l.784.783,1.239,1.239-.193.193-2.727.7.7-2.727Z'
        transform='translate(1028.428 260.769)'
        fill='#2a2859'
        fillRule='evenodd'
      />
    </g>
  </svg>
);
