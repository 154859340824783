import React from 'react';
import './Toggle.scss';

interface ToggleProps {
  id: string;
  name: string;
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  color?: string;
}

export const Toggle = ({
  id,
  name,
  setSelectedIds,
  selectedIds,
  color
}: ToggleProps) => {
  const handleClick = () => {
    selectedIds.includes(id)
      ? setSelectedIds(selectedIds.filter(selected => selected !== id))
      : setSelectedIds([...selectedIds, id]);
  };

  const selected = selectedIds.includes(id);

  const className = selected ? 'toggle selected' : 'toggle';

  return (
    <li
      className={className}
      style={color ? { borderBottomColor: `${color}` } : {}}
    >
      <label className='osg-txt7'>
        <input
          type='checkbox'
          checked={selected}
          onChange={handleClick}
          className='ods-form-check-input'
        />
        {name}
      </label>
    </li>
  );
};
