import React from 'react';
import { KeyText } from '../../../../components/Forms/KeyText';

type Props = {
  name: string;
};
export const BucketName: React.FC<Props> = ({ name }) => {
  return (
    <KeyText
      data-testid='name-input'
      name={name}
      label='Navn på operasjonsspannet'
      description='Navnet på operasjonsspannet som vises på statistikksiden.'
      elementProps={{}}
    />
  );
};
