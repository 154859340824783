import React from 'react';
import { Button } from '../../../../components/Button/Button';
import { OpeningHours } from '../../../../redux/types/common';
import { combineDateAndClosedRules } from '../../../../utils/keychainFactory/openingHours';
import { formatDayDate } from '../../../../utils/timeConverter';
import { OverridingRule } from './overridingRule.tsx/OverridingRule';

interface Props {
  openingHours: OpeningHours;
  setOpeningHours: (openingHours: OpeningHours) => void;
}

export const OverridingHoursList: React.FC<Props> = ({
  openingHours,
  setOpeningHours
}) => {
  const overridingRules = combineDateAndClosedRules(openingHours);
  overridingRules.sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  const createNewOverridingRule = () => {
    const todaysDate = formatDayDate(new Date());
    const newOverridingRules = [...openingHours.dateOverrideRulesList];

    newOverridingRules.push({
      date: todaysDate,
      timeInterval: [{ from: '07:00', until: '20:00' }]
    });

    setOpeningHours({
      ...openingHours,
      dateOverrideRulesList: newOverridingRules
    });
  };

  return (
    <React.Fragment>
      <ul className='opening-hours'>
        {overridingRules.length ? (
          <React.Fragment>
            {overridingRules.map((overridingRule, index) => {
              return (
                <OverridingRule
                  key={overridingRule.date + index}
                  overridingRule={overridingRule}
                  openingHours={openingHours}
                  setOpeningHours={setOpeningHours}
                />
              );
            })}
          </React.Fragment>
        ) : (
          <p className='light'>
            Denne åpningstiden har ingen overstyrende åpningstider.
          </p>
        )}
      </ul>

      <Button
        label='Ny overstyrende åpningstid'
        onClick={createNewOverridingRule}
      />
    </React.Fragment>
  );
};
