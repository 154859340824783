import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteAndConfirmMenu } from '../../../components/DeleteAndConfirm/DeleteAndConfirm';
import { PreContainer } from '../../../components/code/PreContainer';
import { deleteSubject } from '../../../redux/actions/subjects';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { subjectSelectors } from '../../../redux/slice/subjects';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { OperationsTable } from '../Operations/OperationsTable';
import { AdminRole } from './AdminRole';
import { ProductionStatus } from './ProductionStatus';

interface MatchParams {
  subjectId: string;
}

export const AdminSubject: React.FC = () => {
  const navigate = useNavigate();
  const { subjectId } = useParams<keyof MatchParams>();
  const subject = useAppSelector(state =>
    subjectSelectors.selectById(state, subjectId!)
  );
  const dispatch = useAppDispatch();
  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs
        currentPageName={subject?.name || 'Ukjent sted'}
      />
      {subject ? (
        <React.Fragment>
          <DeleteAndConfirmMenu
            label='Slett sted'
            message='Er du sikker på at du vil slette dette stedet?'
            deleteAction={() =>
              dispatchAndHandleResult(dispatch, deleteSubject, subject.id, () =>
                navigate('..')
              )
            }
          />
          <AdminRole subjectId={subject.id} roleId={subject.adminRole} />
          <ProductionStatus
            subjectId={subject.id}
            inProductionInitial={subject.inProduction}
          />
          <OperationsTable operations={subject.operationsList} />
          <h2>Stedets JSON-data</h2>
          <PreContainer obj={subject} />
        </React.Fragment>
      ) : (
        <div>Finner ikke stedet med ID: {subjectId}</div>
      )}
    </React.Fragment>
  );
};
