import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdminOperation } from '../pages/controlpanel/Operations/AdminOperation';
import { CreateOperation } from '../pages/controlpanel/Operations/CreateOperation';
import { AdminSubject } from '../pages/controlpanel/Subjects/AdminSubject';
import { AdminSubjectsPage } from '../pages/controlpanel/Subjects/AdminSubjectsPage';
import { CreatePlace } from '../pages/controlpanel/Subjects/CreatePlace';
import { listActionChains, listAdapters } from '../redux/actions/adapters';
import { listCategories } from '../redux/actions/categories';
import { listProximityVerificationDevice } from '../redux/actions/pvd';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const AdminSubjectsRouter = () => {
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedAdminTool !== 'subject') {
      dispatch(setSelectedAdminTool('subject'));
      dispatch(listProximityVerificationDevice());
      dispatch(listAdapters());
      dispatch(listCategories());
      dispatch(listActionChains());
    }
  }, [selectedAdminTool, dispatch]);
  return (
    <section>
      <Routes>
        <Route path='lag-ny' element={<CreatePlace />} />
        <Route path=':subjectId' element={<AdminSubject />} />
        <Route path=':subjectId/lag-ny' element={<CreateOperation />} />
        <Route path=':subjectId/:operationId' element={<AdminOperation />} />
        <Route path='/' element={<AdminSubjectsPage />} />
      </Routes>
    </section>
  );
};
