import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogFormBox } from '../../../components/DialogBox/DialogFormBox';
import { KeyTextarea } from '../../../components/Forms/KeyTextarea';
import {
  UpdateCategoryDescription,
  updateCategoryDescription
} from '../../../redux/actions/categories';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { Category } from '../../../redux/types/protoTypes';

type Props = {
  category: Category;
  closeModal: () => void;
  isModalOpen: boolean;
};
export const EditCategoryDescriptionModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  category
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<UpdateCategoryDescription>({
    resolver: zodResolver(UpdateCategoryDescription),
    values: category.description && {
      description: category.description.content,
      id: category.id
    }
  });
  return (
    <DialogFormBox
      methods={methods}
      onSubmit={data =>
        dispatchAndHandleResult(
          dispatch,
          updateCategoryDescription,
          data,
          closeModal
        )
      }
      buttonLabel='Bekreft'
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      header='Endre kategoribeskrivelse!'
    >
      <KeyTextarea
        name='description'
        label='Beskrivelse'
        description='Skriv en beskrivelse av kategorien.'
        elementProps={{
          placeholder: 'Skriv en beskrivelse'
        }}
      />
    </DialogFormBox>
  );
};
