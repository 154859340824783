import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useForm } from 'react-hook-form';
import { EditableValue } from '../../../components/EditableValue/EditableValue';
import { KeySelect } from '../../../components/Forms/KeySelect';
import { SetPvdId, setPvdId } from '../../../redux/actions/operations';
import { showErrorPopup } from '../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Operation } from '../../../redux/slice/operations';
import { PvdDetails, pvdSelectors } from '../../../redux/slice/pvd';

export interface SelectOption {
  label: string;
  value: string;
}
type Props = {
  subjectId: string;
  operation: Operation;
};

export const Pvd: React.FC<Props> = ({ subjectId, operation }) => {
  const dispatch = useAppDispatch();
  const pvdList: PvdDetails[] = useAppSelector(pvdSelectors.selectAll);
  const pvdId = operation.proximityDeviceId;
  const methods = useForm<SetPvdId>({
    resolver: zodResolver(SetPvdId),
    defaultValues: {
      subjectId,
      operationId: operation.id,
      deviceOption: pvdId
        ? {
            label: pvdId,
            value: pvdId
          }
        : {
            label: 'Ingen nærhetsenhet valgt',
            value: ''
          }
    }
  });

  const handleSaveClick = async (data: SetPvdId) => {
    if (data.deviceOption.label) {
      dispatch(setPvdId(data));
    } else {
      showErrorPopup(dispatch, undefined, 'Velg en nærhetsenhet fra listen');
    }
  };

  const options: SelectOption[] = pvdList.map(pvd => {
    return { label: pvd.id, value: pvd.id };
  });
  if (pvdId) {
    options.push({
      label: 'Fjern tilknyttet enhet',
      value: ''
    });
  }
  return (
    <EditableValue
      label='Nærhetsenhet'
      value={operation.proximityDeviceId || 'Ingen nærhetsenhet tilknyttet'}
      mainButtonClick={handleSaveClick}
      methods={methods}
    >
      <KeySelect
        label='Nærhetsenhet'
        description='Velg nærhetsenhet'
        name='deviceOption'
        elementProps={{
          options: options,
          placeholder: 'Velg nærhetsenhet',
          isMulti: false
        }}
      />
    </EditableValue>
  );
};
