import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ActionMenuItemProps } from '../ActionMenu/ActionMenuItem';
import { RowActionMenu } from './RowActionMenu';
import './Table.scss';

export type TableRowProps = {
  row: RowProps;
  rowIndex: number;
  hasRowActions: boolean;
};

export type RowProps = {
  cells: ReactNode[];
  link?: string;
  className?: string;
  actions?: ActionMenuItemProps[];
};

export const TableRow: React.FC<TableRowProps> = ({
  row,
  rowIndex,
  hasRowActions
}) => {
  return (
    <tr
      key={row.link || rowIndex}
      className={classNames('keystudio-table-tr', row.className)}
    >
      {row.cells.map((cell, cellIndex) => (
        <td key={`${rowIndex}-${cellIndex}`} className='keystudio-table-td'>
          {row.link ? (
            <Link to={row.link} className='keystudio-table-cell-content'>
              {cell}
            </Link>
          ) : (
            <div className='keystudio-table-cell-content'>{cell}</div>
          )}
        </td>
      ))}
      {hasRowActions && (
        <td key={`${rowIndex}-actions`} className='keystudio-table-td'>
          {row.actions ? <RowActionMenu rowActions={row.actions} /> : null}
        </td>
      )}
    </tr>
  );
};
