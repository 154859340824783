import React from 'react';

export const EditPencilIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12.593'
    height='12.593'
    viewBox='0 0 12.593 12.593'
  >
    <g id='noun-edit-1372806' transform='translate(-6.4 -6.397)'>
      <g id='Group' transform='translate(6.4 6.397)'>
        <path
          id='Compound_Path'
          data-name='Compound Path'
          d='M16.372,6.566a.578.578,0,0,0-.817,0L7.34,14.782a.578.578,0,0,0-.153.274l-.771,3.222a.578.578,0,0,0,.7.7l3.222-.77a.578.578,0,0,0,.274-.153l8.215-8.217a.578.578,0,0,0,0-.817ZM8.6,15.157l5.31-5.31,1.635,1.635-5.31,5.31Zm-.516,1.118,1.031,1.031-1.356.325Zm8.277-5.6L14.718,9.03l1.238-1.238L17.6,9.427Z'
          transform='translate(-6.4 -6.397)'
          fill='#292858'
        />
      </g>
    </g>
  </svg>
);
