import React from 'react';

export const OperationLight: React.FC = () => (
  <svg
    width='40'
    height='44'
    viewBox='0 0 25 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Group 12645'>
      <g id='Vector'>
        <path
          d='M12.75 4.5C12.5588 4.5 12.3675 4.5075 12.1763 4.51875C7.64629 4.8 3.97879 8.52375 3.76129 13.0613C3.59254 16.575 5.44504 19.6687 8.25004 21.2962V24.0037C8.25004 24.4163 8.58754 24.7537 9.00004 24.7537H16.5C16.9125 24.7537 17.25 24.4163 17.25 24.0037V21.2962C19.9388 19.74 21.75 16.8337 21.75 13.5037C21.75 8.535 17.7188 4.50375 12.75 4.50375V4.5ZM16.5 19.995C16.035 20.2612 15.75 20.7563 15.75 21.2925V23.25H9.75004V21.2925C9.75004 20.7563 9.46504 20.2612 9.00004 19.995C6.55879 18.5812 5.12254 15.9525 5.25754 13.1325C5.43754 9.375 8.51628 6.25125 12.2663 6.015C12.4275 6.00375 12.5888 6 12.75 6C16.8863 6 20.25 9.36375 20.25 13.5C20.25 16.1663 18.8138 18.6562 16.5 19.995Z'
          fill='#2A2859'
        />
        <path
          d='M15.75 25.5H9.75C9.3375 25.5 9 25.8375 9 26.25C9 26.6625 9.3375 27 9.75 27H15.75C16.1625 27 16.5 26.6625 16.5 26.25C16.5 25.8375 16.1625 25.5 15.75 25.5Z'
          fill='#2A2859'
        />
        <path
          d='M12.75 3C13.1625 3 13.5 2.6625 13.5 2.25V0.75C13.5 0.3375 13.1625 0 12.75 0C12.3375 0 12 0.3375 12 0.75V2.25C12 2.6625 12.3375 3 12.75 3Z'
          fill='#2A2859'
        />
        <path
          d='M5.325 5.77873C5.51625 5.77873 5.7075 5.70373 5.85375 5.55748C6.14625 5.26498 6.14625 4.78873 5.85375 4.49623L4.7925 3.43498C4.5 3.14248 4.02375 3.14248 3.73125 3.43498C3.43875 3.72748 3.43875 4.20373 3.73125 4.49623L4.7925 5.55748C4.93875 5.70373 5.13 5.77873 5.32125 5.77873H5.325Z'
          fill='#2A2859'
        />
        <path
          d='M2.25 11.625H0.75C0.3375 11.625 0 11.9625 0 12.375C0 12.7875 0.3375 13.125 0.75 13.125H2.25C2.6625 13.125 3 12.7875 3 12.375C3 11.9625 2.6625 11.625 2.25 11.625Z'
          fill='#2A2859'
        />
        <path
          d='M24.75 11.625H23.25C22.8375 11.625 22.5 11.9625 22.5 12.375C22.5 12.7875 22.8375 13.125 23.25 13.125H24.75C25.1625 13.125 25.5 12.7875 25.5 12.375C25.5 11.9625 25.1625 11.625 24.75 11.625Z'
          fill='#2A2859'
        />
        <path
          d='M20.7037 3.43874L19.6425 4.49999C19.35 4.79249 19.35 5.26874 19.6425 5.56124C19.7887 5.70749 19.98 5.78249 20.1712 5.78249C20.3625 5.78249 20.5537 5.70749 20.7 5.56124L21.7612 4.49999C22.0537 4.20749 22.0537 3.73124 21.7612 3.43874C21.4687 3.14624 20.9925 3.14624 20.7 3.43874H20.7037Z'
          fill='#F9C66B'
        />
      </g>
    </g>
  </svg>
);
