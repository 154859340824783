import React from 'react';
import { useFormContext } from 'react-hook-form';
import { KeyComponentProps, KeyWrapper } from './KeyWrapper';

type Option = {
  label: string;
  value: string;
};

export interface KeyRadioGroupType extends KeyComponentProps {
  options: Option[];
  elementProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}

export const KeyRadioGroup: React.FC<KeyRadioGroupType> = ({
  name,
  options,
  ...props
}) => {
  const { register } = useFormContext();
  const { elementProps, ...wrapperProps } = props;
  return (
    <KeyWrapper name={name} {...wrapperProps}>
      {options.map((option, index) => (
        <div key={option.value} className='keystudio-form-element--radio'>
          <input
            {...register(name)}
            type='radio'
            id={`${name}-${option.value}`}
            value={option.value}
            {...elementProps}
            data-testid={props['data-testid']}
          />
          <label key={index} htmlFor={`${name}-${option.value}`}>
            {option.label}
          </label>
        </div>
      ))}
    </KeyWrapper>
  );
};
