import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../../components/Navigation/breadcrumbs';
import { admissionCardsHome } from '../../../../components/Navigation/navPaths';
import { useAuditLog } from '../../../../utils/auditLogs/useAuditLog';
import { AuditLogsTable } from '../../../controlpanel/LogsAndData/pages/AuitLogTable';
import { useAdmissionCard } from '../AdmissionCardWrapper';

export const AuditPage: React.FC = () => {
  const { admissionCard } = useAdmissionCard();
  const auditLogs = useAuditLog(admissionCard.citykeyId);
  return (
    <Fragment>
      <Breadcrumbs
        breadcrumbs={[
          admissionCardsHome,
          {
            name: admissionCard.publicName || 'Ukjent',
            path: '..'
          },
          { name: 'Endringslogg' }
        ]}
      />
      <AuditLogsTable auditLogs={auditLogs} />
    </Fragment>
  );
};
