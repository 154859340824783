import React from 'react';

export const RedirectIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='87.661'
    height='80.174'
    viewBox='0 0 87.661 80.174'
  >
    <g id='noun-redirect-72681' transform='translate(152.001 287.578)'>
      <path
        id='Path_11378'
        data-name='Path 11378'
        d='M86.324,44.252,72.932,34.1a3.374,3.374,0,0,0-4.083,0L55.458,44.252a3.38,3.38,0,0,0,4.09,5.383L67.506,43.6V63.909a10.148,10.148,0,1,1-20.3-.114V17.455a16.913,16.913,0,1,0-33.826.112v19.2L5.422,30.725A3.38,3.38,0,0,0,1.336,36.11L14.724,46.258a3.375,3.375,0,0,0,4.086,0L32.2,36.112a3.38,3.38,0,1,0-4.086-5.385L20.15,36.759v-19.2a10.148,10.148,0,1,1,20.3-.112V63.8a16.913,16.913,0,1,0,33.826.111v-20.3l7.963,6.031a3.382,3.382,0,1,0,4.09-5.388Z'
        transform='translate(-152 -288.174)'
      />
    </g>
  </svg>
);
