import React from 'react';

export const HelpSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
  >
    <g id='Group_1522' data-name='Group 1522' transform='translate(-1 -1)'>
      <path
        id='Path_2924'
        data-name='Path 2924'
        d='M9.958,4.96a2.922,2.922,0,0,1,.642,5.778h-.036v1.008h-1.2V9.61h.6A1.728,1.728,0,1,0,8.3,7.45v.1L7.1,7.318A2.928,2.928,0,0,1,9.958,4.96ZM10,2.2a7.8,7.8,0,1,0,5.515,2.285A7.8,7.8,0,0,0,10,2.2ZM10,1A9,9,0,1,1,3.636,3.636,9,9,0,0,1,10,1Z'
      />
      <path
        id='Path_2925'
        data-name='Path 2925'
        d='M16.32,21.711a1.055,1.055,0,1,1-.079-.406A1.055,1.055,0,0,1,16.32,21.711Z'
        transform='translate(-5.307 -7.894)'
        fillRule='evenodd'
      />
    </g>
  </svg>
);
