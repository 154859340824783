import { useEffect } from 'react';
import { fetchKeychainFactoryDetails } from '../../redux/actions/keychainFactoryDetails';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  ManualGrantKeychainFactoryDetails,
  KeychainFactoryDetails,
  OidcKeychainFactoryDetails,
  PublicKeychainFactoryDetails,
  PushKeychainFactoryDetails,
  selectKeychainFactoryDetails,
  UnknownKeychainFactoryDetails
} from '../../redux/slice/keychainFactoryDetails';
import {
  ManualGrantKeychainFactorySummary,
  KeychainFactorySummary,
  OidcKeychainFactorySummary,
  PublicKeychainFactorySummary,
  PushKeychainFactorySummary,
  selectKeychainFactories,
  UnknownKeychainFactorySummary
} from '../../redux/slice/keychainFactorySummaries';
import { FactoryType } from '../../redux/types/common';
import { getFullkeychainFactoryUri } from './getKeychainFactoryAssets';

type KeychainFactoryDetailsType<T extends FactoryType> = T extends 'public'
  ? PublicKeychainFactoryDetails
  : T extends 'manual-grant'
    ? ManualGrantKeychainFactoryDetails
    : T extends 'push'
      ? PushKeychainFactoryDetails
      : T extends 'oidc'
        ? OidcKeychainFactoryDetails
        : T extends 'unknown'
          ? UnknownKeychainFactoryDetails
          : KeychainFactoryDetails;

const useKeychainFactory = <T extends FactoryType>(
  id: string,
  keychainType: T
): KeychainFactoryDetailsType<T> | undefined => {
  const dispatch = useAppDispatch();
  const keychainFactoryUri = getFullkeychainFactoryUri(keychainType, id);
  const keychainFactory = useAppSelector(state =>
    selectKeychainFactoryDetails.selectById(state, keychainFactoryUri)
  );
  useEffect(() => {
    dispatch(fetchKeychainFactoryDetails(keychainFactoryUri));
  }, [keychainFactoryUri]);
  return keychainFactory as KeychainFactoryDetailsType<T>;
};

type KeychainFactorySummaryType<T extends FactoryType> = T extends 'public'
  ? PublicKeychainFactorySummary
  : T extends 'manual-grant'
    ? ManualGrantKeychainFactorySummary
    : T extends 'push'
      ? PushKeychainFactorySummary
      : T extends 'oidc'
        ? OidcKeychainFactorySummary
        : T extends 'unknown'
          ? UnknownKeychainFactorySummary
          : KeychainFactorySummary;

export const useKeychainFactorySummaryOrDetails = <T extends FactoryType>(
  id: string,
  keychainType: T
) => {
  const keychainFactoryUri = getFullkeychainFactoryUri(keychainType, id);
  const summary = useAppSelector(state =>
    selectKeychainFactories.selectById(state, keychainFactoryUri)
  ) as KeychainFactorySummaryType<T>;
  const details = useKeychainFactory(id, keychainType);
  return details || summary;
};
