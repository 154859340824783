import {
  DataRequest,
  UpdateKeypediaRequest,
  UpdateXmlRequest,
  XmlDataRequest
} from '../../proto/data_pb';
import { Text } from '../../proto/shared_pb';
import {
  getDataClient,
  getGrpcMetadata,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import { setPopup } from '../slice/popup';
import { createText } from './util';

interface XmlData {
  version: number;
  xml: string;
}
export const getAppData = createAppAsyncThunk(
  'appData/getAppData',
  async (_, thunkAPI) => {
    try {
      return (
        await getDataClient().fetchData(
          new DataRequest().setPreferredEncoding(Text.Type.HTML),
          await getGrpcMetadata()
        )
      ).toObject();
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const getXmlData = createAppAsyncThunk<XmlData, void>(
  'appData/getXmlData',
  async (_, thunkAPI) => {
    try {
      return (
        await getDataClient().fetchXmlData(
          new XmlDataRequest().setVersion(-1),
          await getGrpcMetadata()
        )
      ).toObject();
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const updateXmlData = createAppAsyncThunk<XmlData, XmlData>(
  'appData/updateXmlData',
  async ({ version, xml }, thunkAPI) => {
    try {
      await getDataClient().updateXml(
        new UpdateXmlRequest().setUpdatedXml(xml).setExpectedVersion(version),
        await getGrpcMetadata()
      );

      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(setPopup({ error: false, message: 'Lagret' }));
      return { version: version + 1, xml: xml };
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

type KeypediaData = {
  citykeyId: string;
  content: string;
};
export const updateKeypedia = createAppAsyncThunk<void, KeypediaData>(
  'appData/updateKeypediaData',
  async ({ citykeyId, content }, thunkAPI) => {
    try {
      await getDataClient().updateKeypedia(
        new UpdateKeypediaRequest()
          .setCitykeyId(citykeyId)
          .setWiki(createText(content)),
        await getGrpcMetadata()
      );

      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Notatet ble lagret' })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);
