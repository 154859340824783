import { createSlice } from '@reduxjs/toolkit';
import { getAppData } from '../actions/appData';

export type User = {
  username: string;
  email: string;
  rolesList: string[];
};

export const initialState: User = {
  username: '',
  email: '',
  rolesList: []
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAppData.fulfilled, (state, { payload }) => {
      return payload.user;
    });
  }
});

export default userSlice.reducer;
