import React from 'react';
import { useAppSelector } from '../../../redux/hooks';
import './ActionChain.scss';
import { ActionChainTable } from './ActionChainTable';

export const ActionChainPage: React.FC = () => {
  const actionChains = useAppSelector(state => state.adapters.actionChains);

  return (
    <div>
      <ActionChainTable actionChains={actionChains} />
    </div>
  );
};
