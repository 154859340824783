import { createSlice } from '@reduxjs/toolkit';
import { fetchFlightRecording } from '../actions/flightrecordings';

export type FlightRecordingState = {
  metadata: {
    summary: string;
    os: string;
    buildNumber: number;
    receivedEpochSeconds: number;
  };
  json: object;
  isLoading: boolean;
  hasData: boolean;
};

export const initialState: FlightRecordingState = {
  metadata: {
    summary: '',
    os: '',
    buildNumber: 0,
    receivedEpochSeconds: 0
  },
  json: {},
  isLoading: false,
  hasData: false
};

const flightrecordingSlice = createSlice({
  name: 'flightrecordings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchFlightRecording.fulfilled, (state, { payload }) => {
        const { json, ...metadata } = payload;
        return {
          metadata,
          json: JSON.parse(json),
          isLoading: false,
          hasData: true
        };
      })
      .addCase(fetchFlightRecording.rejected, () => initialState)
      .addCase(fetchFlightRecording.pending, state => {
        state.isLoading = true;
        state.hasData = false;
      });
  }
});

export default flightrecordingSlice.reducer;
