import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UiState {
  selectedAdminTool: string | null;
  sideMenuOpen: boolean;
  hoursType: string;
  traceId: string;
}

export const initialState: UiState = {
  selectedAdminTool: null,
  sideMenuOpen: false,
  hoursType: '',
  traceId: ''
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSelectedAdminTool: (state, action: PayloadAction<string>) => {
      state.selectedAdminTool = action.payload;
    },
    clearSelectedAdminTool: state => {
      state.selectedAdminTool = null;
    },
    setSideMenuOpen: (state, action: PayloadAction<boolean>) => {
      state.sideMenuOpen = action.payload;
    },
    setHoursType: (state, action: PayloadAction<string>) => {
      state.hoursType = action.payload;
    },
    setTraceId: (state, action: PayloadAction<string>) => {
      state.traceId = action.payload;
    }
  }
});

export const {
  setSelectedAdminTool,
  clearSelectedAdminTool,
  setSideMenuOpen,
  setHoursType,
  setTraceId
} = uiSlice.actions;
export default uiSlice.reducer;
