import React, { useState } from 'react';
import Arrow from '../../../../../../assets/down.png';
import { Subject } from '../../../../../../redux/slice/subjects';
import { OperationList } from './operationList/OperationList';

interface Props {
  subject: Subject;
}

export const SubjectListItem: React.FC<Props> = ({ subject }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <li>
      <div onClick={() => setShowMore(!showMore)}>
        <h3>{subject.name}</h3>
        <img src={Arrow} alt='arrow' className={showMore ? 'up' : 'down'} />
      </div>
      {showMore && (
        <OperationList
          subjectId={subject.id}
          operations={subject.operationsList}
        />
      )}
    </li>
  );
};
