import React from 'react';
import { Term } from '../../../../redux/slice/terms';
import { TypeCard } from './TypeCard';

interface Props {
  term: Term;
}

export const TypeContainer: React.FC<Props> = ({ term }) => {
  return <>{<TypeCard type={term.type} />}</>;
};
