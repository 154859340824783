import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Confirm } from '../../../../components/Confirm';
import { useAdmissionCard } from '../AdmissionCardWrapper';
import { EditAdmissionCardBreadcrumbs } from './EditAdmissionCardBreadcrumbs';
import { EditAdmissionCardSubjects } from './EditAdmissionCardSubjects';
import { EditAdmissionCardTerms } from './EditAdmissionCardTerms';

interface MatchParams {
  editId: string;
}

export const AdmissionCardEdit: React.FC = () => {
  const [showNavigateConfirm, setShowNaviateConfirm] = useState(false);
  const navigate = useNavigate();
  const { editId } = useParams<keyof MatchParams>();
  const { admissionCard } = useAdmissionCard();
  return (
    <section className='admissioncard-edit'>
      <Confirm
        title='Forlate siden'
        message='Du har ulagrede endringer, er du sikker på at du vil forlate siden?'
        isModalOpen={showNavigateConfirm}
        closeModal={() => setShowNaviateConfirm(false)}
        doSomething={() => {
          navigate(-1);
        }}
      />
      <EditAdmissionCardBreadcrumbs
        editId={editId}
        admissionCard={admissionCard}
      />
      {editId === 'vilkaar' && (
        <EditAdmissionCardTerms admissionCard={admissionCard} />
      )}
      {editId === 'steder' && (
        <EditAdmissionCardSubjects admissionCard={admissionCard} />
      )}
    </section>
  );
};
