import React from 'react';
import { KeypediaSvg } from '../../assets/KeypediaSvg';
import { SubjectActionCard } from '../../pages/places/SubjectCardsContainer/SubjectCard/SubjectActionCard';
import './Keypedia.scss';

export const KeypediaSubjectCard: React.FC = () => {
  return (
    <SubjectActionCard
      link='wiki'
      header='Interninfo og -notater'
      icon={<KeypediaSvg />}
      classes='keypedia-card'
    />
  );
};
