import React from 'react';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { CreatePublicKeychainFactory } from './CreatePublicKeychainFactory';

export const CreatePublicKeychainFactoryPage = () => {
  return (
    <section>
      <ControlpanelBreadcrumbs currentPageName='Lag ny tilgangsliste' />
      <CreatePublicKeychainFactory />
    </section>
  );
};
