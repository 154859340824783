import React from 'react';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { useAppSelector } from '../../../redux/hooks';
import {
  OidcKeychainFactorySummary,
  selectKeychainFactories
} from '../../../redux/slice/keychainFactorySummaries';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';
import { getIdFromkeychainFactoryUri } from '../../../utils/keychainFactory/getKeychainFactoryAssets';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const AdminOidcPage: React.FC = () => {
  const keychains = useAppSelector(selectKeychainFactories.selectAll);
  const oidcChains = keychains.filter(
    (keychain): keychain is OidcKeychainFactorySummary =>
      keychain.type === 'oidc'
  );

  const tableHeadings = ['Navn', 'Rolle', 'ID', 'Tilkoblede steder'];
  const data = oidcChains.map(chain => {
    return {
      cells: [
        chain.name,
        <CopyWrapper key={chain.ownerRole} copyValue={chain.ownerRole} />,
        <CopyWrapper
          key={chain.keychainFactoryUri}
          copyValue={chain.keychainFactoryUri}
        />,
        chain.linkedSubjects.length
      ],
      link: getIdFromkeychainFactoryUri(chain.keychainFactoryUri)
    };
  });

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs />
      <TableWithButtons
        ting='OIDC tilgangsliste'
        buttons={[
          {
            label: 'Lag ny web nøkkel',
            type: 'primary',
            accessKey: 'n',
            linkTo: 'lag-ny'
          }
        ]}
        table={{
          headings: tableHeadings,
          rows: data
        }}
      />
    </React.Fragment>
  );
};
