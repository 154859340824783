import React from 'react';

export const OperationUnknown: React.FC = () => (
  <svg
    width='45'
    height='45'
    viewBox='0 0 30 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Vector'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0452 9.58933L12.7365 10.3942L10.4653 13.4123L12.7365 16.3298L12.0452 17.2353L9.77413 14.2171L7.40427 17.2353L6.71306 16.3298L9.08292 13.4123L6.71306 10.3942L7.40427 9.58933L9.77413 12.5069L12.0452 9.58933Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.7958 9.58933L24.487 10.3942L22.2159 13.4123L24.487 16.3298L23.7958 17.2353L21.5247 14.2171L19.2536 17.2353L18.5624 16.3298L20.8335 13.4123L18.5624 10.3942L19.2536 9.58933L21.5247 12.5069L23.7958 9.58933Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.98418 24.4788C6.71306 23.9758 -11.1596 5.46454 15.4025 1.03793C22.0184 0.333693 42.1622 9.48873 22.8084 24.177L22.7096 27.8994L20.3398 27.7988V25.7867L18.6611 25.6861V28H16.4887V25.7867H15.0076L15.1063 27.8994H12.9339L12.8352 25.6861H11.2553L11.1565 28H8.98418V24.4788Z'
        stroke='#2A2859'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.0452 9.58933L12.7365 10.3942L10.4653 13.4123L12.7365 16.3298L12.0452 17.2353L9.77413 14.2171L7.40427 17.2353L6.71306 16.3298L9.08292 13.4123L6.71306 10.3942L7.40427 9.58933L9.77413 12.5069L12.0452 9.58933Z'
        stroke='#2A2859'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.7958 9.58933L24.487 10.3942L22.2159 13.4123L24.487 16.3298L23.7958 17.2353L21.5247 14.2171L19.2536 17.2353L18.5624 16.3298L20.8335 13.4123L18.5624 10.3942L19.2536 9.58933L21.5247 12.5069L23.7958 9.58933Z'
        stroke='#2A2859'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);
