import React from 'react';

type Props = {
  size?: number;
};
export const ManualGrantChains: React.FC<Props> = ({ size = 136 }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12200'
      data-name='Group 12200'
      transform='translate(-1255 -475)'
    >
      <g
        id='Group_12192'
        data-name='Group 12192'
        transform='translate(-231 256)'
      >
        <g
          id='Group_12177'
          data-name='Group 12177'
          transform='translate(1158 -13)'
        >
          <circle
            id='Ellipse_691'
            data-name='Ellipse 691'
            cx='68'
            cy='68'
            r='68'
            transform='translate(328 232)'
            fill='#f9b3ab'
          />
        </g>
      </g>
      <g
        id='Group_12198'
        data-name='Group 12198'
        transform='translate(198.05 -186.03)'
      >
        <g id='Group_12199' data-name='Group 12199'>
          <g
            id='noun-work-for-food-97466'
            transform='translate(1083.512 717.834)'
          >
            <path
              id='Path_11309'
              data-name='Path 11309'
              d='M195.446,393.6a3.758,3.758,0,0,1-.064,1.814,3.582,3.582,0,0,1-5.977,1.6l-7.083-6.91-1.131,1.271,7.1,6.915a5.444,5.444,0,0,0,7.346.141,7.269,7.269,0,0,0,1.415-2.979h15.358a4.18,4.18,0,0,1,4,3.881,3.732,3.732,0,0,1-4,3.939H186.625a6.439,6.439,0,0,1-3.882-1.5l-15.9-11.991-17.759-.074V376.726h26.9a7.768,7.768,0,0,1,3.208.8,13.728,13.728,0,0,1,2.275,1.733l12.967,12.589a3.623,3.623,0,0,1,1.01,1.749Z'
              transform='translate(-149.088 -376.726)'
              fill='#292858'
            />
          </g>
          <path
            id='Path_4368'
            data-name='Path 4368'
            d='M126.466,8.658a5.163,5.163,0,1,0-5.191,5.223,5.211,5.211,0,0,0,5.191-5.223M129.6,10.4a8.492,8.492,0,0,1-9.618,6.751,8.61,8.61,0,0,1-7.058-9.864,8.456,8.456,0,0,1,16.7-.337l23.915.135-.019,3.451-3.392-.019-.038,6.9L146.7,17.4l.019-3.454H146.7s0-.008,0-.012a1.685,1.685,0,1,0-3.369-.019s0,.008,0,.012h-.01l-.019,3.454-3.392-.019.038-6.9Z'
            transform='translate(1012.851 713.685)'
            fill='#292858'
          />
        </g>
      </g>
    </g>
  </svg>
);
