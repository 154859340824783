import React from 'react';
import { Term } from '../../../redux/slice/terms';
import { OwnerAdminContainter } from './admin/OwnerAdminContainer';
import { InfoContainer } from './info/InfoContainer';
import { TypeContainer } from './type/TypeContainer';

interface Props {
  term: Term;
}

export const Cards: React.FC<Props> = ({ term }) => (
  <div className='cards'>
    <InfoContainer term={term} />
    <TypeContainer term={term} />
    <OwnerAdminContainter term={term} />
  </div>
);
