import React from 'react';
import { Link } from 'react-router-dom';
import './OperationInfoCard.scss';

type SubjectActionCardProps = {
  icon: React.ReactElement;
  header: string;
  text: string;
  link: string;
  linkTarget?: string;
  classes?: string;
};

export const OperationInfoCard: React.FC<SubjectActionCardProps> = ({
  icon,
  header,
  text,
  link,
  linkTarget,
  classes = ''
}) => {
  classes += ' operation-info-card';
  return (
    <Link to={link} target={linkTarget}>
      <div className={classes}>
        <p className='title'>{header}</p>
        <div className='img-container'>{icon}</div>
        <div className='text'>{text}</div>
      </div>
    </Link>
  );
};
