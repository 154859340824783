import { z } from 'zod';
import {
  AddGroupRequest,
  AddQuestionRequest,
  BroadcastFaq,
  DeleteGroupRequest,
  DeleteQuestionRequest,
  FetchFAQRequest,
  UpdateGroupTitleRequest,
  UpdateQuestionRequest
} from '../../proto/broadcast_pb';
import {
  getBroadcastClient,
  getGrpcMetadata,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createUrlSafeId } from '../../utils/urlSafeId';
import { createAppAsyncThunk } from '../hooks';
import { noValidationString, stringNonEmpty, title } from '../types/zodSchemas';

export type FAQGroup = {
  id: string;
  title: string;
  questions: FAQQuestion[];
};

export const FAQQuestion = z.object({
  id: noValidationString,
  question: stringNonEmpty,
  answer: stringNonEmpty
});
export type FAQQuestion = z.infer<typeof FAQQuestion>;

export const Group = z.object({
  groupId: noValidationString,
  title: title
});
export type Group = z.infer<typeof Group>;

export const QuestionPayload = z.object({
  groupId: stringNonEmpty,
  question: FAQQuestion
});
export type QuestionPayload = z.infer<typeof QuestionPayload>;

export const fetchFAQ = createAppAsyncThunk<FAQGroup[]>(
  'broadcast/fetchFAQ',
  async (_, thunkAPI) => {
    try {
      const response = await getBroadcastClient().fetchFAQ(
        new FetchFAQRequest(),
        await getGrpcMetadata()
      );
      return faqToModel(response.toObject().faq);
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const addGroup = createAppAsyncThunk<
  { groupId: string; title: string },
  Group
>('broadcast/addGroup', async ({ title }, thunkAPI) => {
  try {
    const request = new AddGroupRequest()
      .setTitle(title)
      .setGroupId(createUrlSafeId(title));
    await getBroadcastClient().addGroup(request, await getGrpcMetadata());
    return request.toObject();
  } catch (error) {
    return handleGrpcError(error, thunkAPI);
  }
});

export const deleteGroup = createAppAsyncThunk<string, string>(
  'broadcast/deleteGroup',
  async (id: string, thunkAPI) => {
    try {
      await getBroadcastClient().deleteGroup(
        new DeleteGroupRequest().setGroupId(id),
        await getGrpcMetadata()
      );
      return id;
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const updateGroupTitle = createAppAsyncThunk<Group, Group>(
  'broadcast/updateGroupTitle',
  async ({ groupId, title }, thunkAPI) => {
    try {
      await getBroadcastClient().updateGroupTitle(
        new UpdateGroupTitleRequest().setGroupId(groupId).setTitle(title),
        await getGrpcMetadata()
      );
      return { groupId, title };
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const addQuestion = createAppAsyncThunk<
  QuestionPayload,
  QuestionPayload
>('broadcast/addQuestion', async ({ groupId, question }, thunkAPI) => {
  try {
    await getBroadcastClient().addQuestion(
      new AddQuestionRequest().setQuestion(
        new BroadcastFaq.Group.Question()
          .setTitle(question.question)
          .setBody(question.answer)
          .setGroupId(groupId)
          .setId(createUrlSafeId(question.question))
      ),
      await getGrpcMetadata()
    );
    thunkAPI.dispatch(fetchFAQ());
    return { groupId, question };
  } catch (error) {
    return handleGrpcError(error, thunkAPI);
  }
});

export const updateQuestion = createAppAsyncThunk<
  QuestionPayload,
  QuestionPayload
>('broadcast/updateQuestion', async ({ groupId, question }, thunkAPI) => {
  try {
    await getBroadcastClient().updateQuestion(
      new UpdateQuestionRequest().setQuestion(
        new BroadcastFaq.Group.Question()
          .setGroupId(groupId)
          .setId(question.id)
          .setTitle(question.question)
          .setBody(question.answer)
      ),
      await getGrpcMetadata()
    );
    return { groupId, question };
  } catch (error) {
    return handleGrpcError(error, thunkAPI);
  }
});

type DeleteQuestionPayload = {
  groupId: string;
  questionId: string;
};
export const deleteQuestion = createAppAsyncThunk<
  DeleteQuestionPayload,
  DeleteQuestionPayload
>('broadcast/deleteQuestion', async ({ groupId, questionId }, thunkAPI) => {
  try {
    await getBroadcastClient().deleteQuestion(
      new DeleteQuestionRequest().setQuestionId(questionId).setGroupId(groupId),
      await getGrpcMetadata()
    );
    return { groupId, questionId };
  } catch (error) {
    return handleGrpcError(error, thunkAPI);
  }
});

const faqToModel = (
  faq: BroadcastFaq.AsObject = { groupsList: [] }
): FAQGroup[] => {
  return faq.groupsList.map(group => ({
    id: group.id,
    title: group.title,
    questions: group.questionsList.map(question => ({
      id: question.id,
      question: question.title,
      answer: question.body
    }))
  }));
};
