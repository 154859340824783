import { AdminUserProfileLookupRequest } from '../../../../proto/data_pb';
import { PhoneNumber } from '../../../../proto/shared_pb';
import {
  DeleteProfileDataRequest,
  FindNameFromContactInfoRequest
} from '../../../../proto/utils_pb';
import { store } from '../../../../redux';
import { showErrorPopup } from '../../../../redux/actions/util';
import { setPopup } from '../../../../redux/slice/popup';
import {
  getDataClient,
  getGrpcMetadata,
  getUtilsClient
} from '../../../../utils/requests/grpcRequest';

export const profileLookup = async (
  profileId: string,
  fnr: string,
  phonenumber: string,
  reason: string
) => {
  try {
    if (phonenumber) {
      const searchObject = new FindNameFromContactInfoRequest().setPhoneNumber(
        new PhoneNumber().setCountryCode('47').setNumber(phonenumber)
      );
      const response = await getUtilsClient().findNameFromContactInfo(
        searchObject,
        await getGrpcMetadata()
      );
      profileId = response.getProfileId();
    }
    const request = new AdminUserProfileLookupRequest();
    if (profileId) {
      request.setProfileId(profileId);
    }
    if (fnr) {
      request.setFnr(fnr);
    }
    request.setReason(reason);
    return (
      await getDataClient().adminUserProfileLookup(
        request,
        await getGrpcMetadata()
      )
    ).toObject();
  } catch (error) {
    showErrorPopup(store.dispatch, error);
  }
};
export const deleteProfile = async (
  profileId: string,
  fnr: string,
  reason: string,
  slice: 'profile' | 'device'
) => {
  try {
    const request = new DeleteProfileDataRequest();
    if (profileId) {
      request.setProfileid(profileId);
    }
    if (fnr) {
      request.setFodselsnummer(fnr);
    }
    request.setReason(reason);
    if (slice === 'device') {
      await getUtilsClient().deleteDevicesForProfile(
        request,
        await getGrpcMetadata()
      );
      store.dispatch(
        setPopup({ error: false, message: 'Alle mobilenheter ble logget ut' })
      );
    } else {
      await getUtilsClient().deleteProfile(request, await getGrpcMetadata());
      store.dispatch(
        setPopup({ error: false, message: 'Brukerprofilen ble slettet' })
      );
    }
  } catch (error) {
    showErrorPopup(store.dispatch, error);
  }
};
