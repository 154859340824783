import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { AdminRoleSelector } from '../../../components/Forms/AdminRoleSelector';
import { KeyId } from '../../../components/Forms/KeyId';
import { KeyText } from '../../../components/Forms/KeyText';
import { KeyTextarea } from '../../../components/Forms/KeyTextarea';
import {
  CreateOidcKeychainFactory,
  createOidcKeychainFactory
} from '../../../redux/actions/keychainFactoryDetails';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { historyGoBack } from '../../../utils/history';

export const CreateNewOidcKeychain = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm<CreateOidcKeychainFactory>({
    resolver: zodResolver(CreateOidcKeychainFactory),
    defaultValues: {
      lifespan: 60
    }
  });
  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(data =>
    dispatchAndHandleResult(dispatch, createOidcKeychainFactory, data, () => {
      historyGoBack(navigate);
    })
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <KeyText
          data-testid='name-input'
          name='publicName'
          label='Navn på Web-login'
          description='Navnet på tilgangslisten som vises i listen over tilgangslister.'
          elementProps={{
            placeholder: 'Skriv inn navn på tilgangslisten'
          }}
        />
        <KeyId
          name='id'
          otherField='publicName'
          description='Unik ID til web-login-knippet'
          label='ID'
          elementProps={{
            placeholder: 'Skriv inn ønsket ID til oidc'
          }}
        />

        <AdminRoleSelector
          description='Adminbrukere må ha denne rollen for å administrere tilgangslisten.'
          name='adminRole'
        />

        <KeyText
          name='lifespan'
          label='Levetid'
          description='Hvor mange minutter en sesjon skal leve.'
          elementProps={{
            type: 'number'
          }}
        />

        <KeyTextarea
          name='allowedOrigins'
          label='Origin URLer'
          description='URL(er) som OIDC tillater at innloggingen kommer fra. En URL på hver linje.'
          elementProps={{
            rows: 5
          }}
        />

        <KeyTextarea
          name='redirectUrl'
          label='Redirect URLer'
          description='URL(er) som OIDC tillater redirect til etter innlogging. En URL på hver linje.'
          elementProps={{
            rows: 5
          }}
        />

        <Button label='Lag ny web login' type='submit' accessKey='n' />
      </form>
    </FormProvider>
  );
};
