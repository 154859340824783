import {
  getGrpcMetadata,
  getKeychainFactoryClient,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import {ListKeychainFactoryConfigurationsRequest} from "../../proto/keychain-factory_pb";

export const listKeychainFactoryConfigurations = createAppAsyncThunk(
  'keychainFactories/listKeychainFactoryConfigurations',
  async (_, thunkAPI) => {
    try {
      const response =
        await getKeychainFactoryClient().listKeychainFactoryConfigurations(
          new ListKeychainFactoryConfigurationsRequest(),
          await getGrpcMetadata()
        );
      return response.toObject().keychainFactoriesList;
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);
