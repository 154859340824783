import React, { PropsWithChildren } from 'react';
import { useAppSelector } from '../../redux/hooks';

export const RenderIfSuperAdmin: React.FC<PropsWithChildren> = ({
  children
}) => {
  if (!isSuperAdmin()) {
    return null;
  }
  return children;
};

export const isSuperAdmin = (): boolean => {
  const user = useAppSelector(state => state.user);
  return user.rolesList.includes('admin_super');
};
