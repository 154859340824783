import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import { KeyText } from '../../../../components/Forms/KeyText';
import {
  SessionLifeSpan,
  setSessionLifespan
} from '../../../../redux/actions/keychainFactoryDetails';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { historyGoBack } from '../../../../utils/history';
import { useKeychainFactoryDetails } from '../../KeychainDetailsWrapper';
import { KeychainFactoryBreadcrumbs } from '../../KeychainFactoryBreadcrumbs';

export const OidcLifespan: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { keychainFactory } = useKeychainFactoryDetails();
  if (keychainFactory.type !== 'oidc') {
    return <p>Støtter ikke tilgangslistetype {keychainFactory.type}</p>;
  }
  const methods = useForm<SessionLifeSpan>({
    resolver: zodResolver(SessionLifeSpan),
    defaultValues: keychainFactory
  });
  const onSave = (data: SessionLifeSpan) =>
    dispatchAndHandleResult(dispatch, setSessionLifespan, data, goBack);

  const goBack = () => {
    historyGoBack(navigate);
  };

  return (
    <React.Fragment>
      <KeychainFactoryBreadcrumbs
        keychainFactory={keychainFactory}
        name='Konfigurer levetid'
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSave)}>
          <KeyText
            label='Levetid'
            name='sessionLifespanMinutes'
            description='Hvor mange minutter en sesjon skal leve.'
            elementProps={{
              type: 'number'
            }}
          />
          <div className='button-row'>
            <Button label='Avbryt' type='secondary' onClick={goBack} />
            <Button label='Lagre' type='submit' />
          </div>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
