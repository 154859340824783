import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogFormBox } from '../../../../components/DialogBox/DialogFormBox';
import {
  UpdateOperationBucketName,
  updateOperationBucketName
} from '../../../../redux/actions/buckets';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { Bucket } from '../../../../redux/slice/buckets';
import { BucketName } from './BucketName';

type Props = {
  bucket: Bucket;
  closeModal: () => void;
  isModalOpen: boolean;
};
export const EditNameModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  bucket
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<UpdateOperationBucketName>({
    resolver: zodResolver(UpdateOperationBucketName),
    defaultValues: bucket
  });

  return (
    <DialogFormBox
      methods={methods}
      buttonLabel='Bekreft'
      onSubmit={data =>
        dispatchAndHandleResult(
          dispatch,
          updateOperationBucketName,
          data,
          closeModal
        )
      }
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      header='Endre navn'
    >
      <BucketName name='name' />
    </DialogFormBox>
  );
};
