/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.term
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: term.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as term_pb from './term_pb'; // proto import: "term.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class TermServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListTerms = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.term.TermService/ListTerms',
    grpcWeb.MethodType.UNARY,
    term_pb.ListTermsRequest,
    term_pb.ListTermsResponse,
    (request: term_pb.ListTermsRequest) => {
      return request.serializeBinary();
    },
    term_pb.ListTermsResponse.deserializeBinary
  );

  listTerms(
    request: term_pb.ListTermsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<term_pb.ListTermsResponse>;

  listTerms(
    request: term_pb.ListTermsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: term_pb.ListTermsResponse) => void): grpcWeb.ClientReadableStream<term_pb.ListTermsResponse>;

  listTerms(
    request: term_pb.ListTermsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: term_pb.ListTermsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.term.TermService/ListTerms',
        request,
        metadata || {},
        this.methodDescriptorListTerms,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.term.TermService/ListTerms',
    request,
    metadata || {},
    this.methodDescriptorListTerms);
  }

  methodDescriptorCreateTerm = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.term.TermService/CreateTerm',
    grpcWeb.MethodType.UNARY,
    term_pb.CreateTermRequest,
    shared_pb.EmptyResponse,
    (request: term_pb.CreateTermRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createTerm(
    request: term_pb.CreateTermRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createTerm(
    request: term_pb.CreateTermRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createTerm(
    request: term_pb.CreateTermRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.term.TermService/CreateTerm',
        request,
        metadata || {},
        this.methodDescriptorCreateTerm,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.term.TermService/CreateTerm',
    request,
    metadata || {},
    this.methodDescriptorCreateTerm);
  }

  methodDescriptorReviseTerm = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.term.TermService/ReviseTerm',
    grpcWeb.MethodType.UNARY,
    term_pb.ReviseTermRequest,
    shared_pb.EmptyResponse,
    (request: term_pb.ReviseTermRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  reviseTerm(
    request: term_pb.ReviseTermRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  reviseTerm(
    request: term_pb.ReviseTermRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  reviseTerm(
    request: term_pb.ReviseTermRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.term.TermService/ReviseTerm',
        request,
        metadata || {},
        this.methodDescriptorReviseTerm,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.term.TermService/ReviseTerm',
    request,
    metadata || {},
    this.methodDescriptorReviseTerm);
  }

  methodDescriptorArchiveTerm = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.term.TermService/ArchiveTerm',
    grpcWeb.MethodType.UNARY,
    term_pb.ArchiveTermRequest,
    shared_pb.EmptyResponse,
    (request: term_pb.ArchiveTermRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  archiveTerm(
    request: term_pb.ArchiveTermRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  archiveTerm(
    request: term_pb.ArchiveTermRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  archiveTerm(
    request: term_pb.ArchiveTermRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.term.TermService/ArchiveTerm',
        request,
        metadata || {},
        this.methodDescriptorArchiveTerm,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.term.TermService/ArchiveTerm',
    request,
    metadata || {},
    this.methodDescriptorArchiveTerm);
  }

  methodDescriptorUnarchiveTerm = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.term.TermService/UnarchiveTerm',
    grpcWeb.MethodType.UNARY,
    term_pb.UnarchiveTermRequest,
    shared_pb.EmptyResponse,
    (request: term_pb.UnarchiveTermRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  unarchiveTerm(
    request: term_pb.UnarchiveTermRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  unarchiveTerm(
    request: term_pb.UnarchiveTermRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  unarchiveTerm(
    request: term_pb.UnarchiveTermRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.term.TermService/UnarchiveTerm',
        request,
        metadata || {},
        this.methodDescriptorUnarchiveTerm,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.term.TermService/UnarchiveTerm',
    request,
    metadata || {},
    this.methodDescriptorUnarchiveTerm);
  }

  methodDescriptorUpdateTermOwner = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.term.TermService/UpdateTermOwner',
    grpcWeb.MethodType.UNARY,
    term_pb.UpdateTermOwnerRequest,
    shared_pb.EmptyResponse,
    (request: term_pb.UpdateTermOwnerRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateTermOwner(
    request: term_pb.UpdateTermOwnerRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateTermOwner(
    request: term_pb.UpdateTermOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateTermOwner(
    request: term_pb.UpdateTermOwnerRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.term.TermService/UpdateTermOwner',
        request,
        metadata || {},
        this.methodDescriptorUpdateTermOwner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.term.TermService/UpdateTermOwner',
    request,
    metadata || {},
    this.methodDescriptorUpdateTermOwner);
  }

}

