/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.utils
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: utils.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as utils_pb from './utils_pb'; // proto import: "utils.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class UtilsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorTranslateOpeningHourSpec = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/TranslateOpeningHourSpec',
    grpcWeb.MethodType.UNARY,
    utils_pb.TranslateOpeningHourSpecRequest,
    utils_pb.TranslateOpeningHourSpecResponse,
    (request: utils_pb.TranslateOpeningHourSpecRequest) => {
      return request.serializeBinary();
    },
    utils_pb.TranslateOpeningHourSpecResponse.deserializeBinary
  );

  translateOpeningHourSpec(
    request: utils_pb.TranslateOpeningHourSpecRequest,
    metadata?: grpcWeb.Metadata | null): Promise<utils_pb.TranslateOpeningHourSpecResponse>;

  translateOpeningHourSpec(
    request: utils_pb.TranslateOpeningHourSpecRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: utils_pb.TranslateOpeningHourSpecResponse) => void): grpcWeb.ClientReadableStream<utils_pb.TranslateOpeningHourSpecResponse>;

  translateOpeningHourSpec(
    request: utils_pb.TranslateOpeningHourSpecRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: utils_pb.TranslateOpeningHourSpecResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/TranslateOpeningHourSpec',
        request,
        metadata || {},
        this.methodDescriptorTranslateOpeningHourSpec,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/TranslateOpeningHourSpec',
    request,
    metadata || {},
    this.methodDescriptorTranslateOpeningHourSpec);
  }

  methodDescriptorFindNameFromContactInfo = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/FindNameFromContactInfo',
    grpcWeb.MethodType.UNARY,
    utils_pb.FindNameFromContactInfoRequest,
    utils_pb.FindNameFromContactInfoResponse,
    (request: utils_pb.FindNameFromContactInfoRequest) => {
      return request.serializeBinary();
    },
    utils_pb.FindNameFromContactInfoResponse.deserializeBinary
  );

  findNameFromContactInfo(
    request: utils_pb.FindNameFromContactInfoRequest,
    metadata?: grpcWeb.Metadata | null): Promise<utils_pb.FindNameFromContactInfoResponse>;

  findNameFromContactInfo(
    request: utils_pb.FindNameFromContactInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: utils_pb.FindNameFromContactInfoResponse) => void): grpcWeb.ClientReadableStream<utils_pb.FindNameFromContactInfoResponse>;

  findNameFromContactInfo(
    request: utils_pb.FindNameFromContactInfoRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: utils_pb.FindNameFromContactInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/FindNameFromContactInfo',
        request,
        metadata || {},
        this.methodDescriptorFindNameFromContactInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/FindNameFromContactInfo',
    request,
    metadata || {},
    this.methodDescriptorFindNameFromContactInfo);
  }

  methodDescriptorGenerateImagePresignedUrl = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/GenerateImagePresignedUrl',
    grpcWeb.MethodType.UNARY,
    utils_pb.ImagePresignedUrlRequest,
    utils_pb.ImagePresignedUrlResponse,
    (request: utils_pb.ImagePresignedUrlRequest) => {
      return request.serializeBinary();
    },
    utils_pb.ImagePresignedUrlResponse.deserializeBinary
  );

  generateImagePresignedUrl(
    request: utils_pb.ImagePresignedUrlRequest,
    metadata?: grpcWeb.Metadata | null): Promise<utils_pb.ImagePresignedUrlResponse>;

  generateImagePresignedUrl(
    request: utils_pb.ImagePresignedUrlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: utils_pb.ImagePresignedUrlResponse) => void): grpcWeb.ClientReadableStream<utils_pb.ImagePresignedUrlResponse>;

  generateImagePresignedUrl(
    request: utils_pb.ImagePresignedUrlRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: utils_pb.ImagePresignedUrlResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/GenerateImagePresignedUrl',
        request,
        metadata || {},
        this.methodDescriptorGenerateImagePresignedUrl,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/GenerateImagePresignedUrl',
    request,
    metadata || {},
    this.methodDescriptorGenerateImagePresignedUrl);
  }

  methodDescriptorDeleteProfile = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/DeleteProfile',
    grpcWeb.MethodType.UNARY,
    utils_pb.DeleteProfileDataRequest,
    shared_pb.EmptyResponse,
    (request: utils_pb.DeleteProfileDataRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteProfile(
    request: utils_pb.DeleteProfileDataRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteProfile(
    request: utils_pb.DeleteProfileDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteProfile(
    request: utils_pb.DeleteProfileDataRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/DeleteProfile',
        request,
        metadata || {},
        this.methodDescriptorDeleteProfile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/DeleteProfile',
    request,
    metadata || {},
    this.methodDescriptorDeleteProfile);
  }

  methodDescriptorDeleteDevicesForProfile = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/DeleteDevicesForProfile',
    grpcWeb.MethodType.UNARY,
    utils_pb.DeleteProfileDataRequest,
    shared_pb.EmptyResponse,
    (request: utils_pb.DeleteProfileDataRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteDevicesForProfile(
    request: utils_pb.DeleteProfileDataRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteDevicesForProfile(
    request: utils_pb.DeleteProfileDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteDevicesForProfile(
    request: utils_pb.DeleteProfileDataRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/DeleteDevicesForProfile',
        request,
        metadata || {},
        this.methodDescriptorDeleteDevicesForProfile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.utils.UtilsService/DeleteDevicesForProfile',
    request,
    metadata || {},
    this.methodDescriptorDeleteDevicesForProfile);
  }

}

