import React from 'react';

export const KeypediaSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='79.45'
    height='79.449'
    viewBox='0 0 79.45 79.449'
  >
    <g
      id='Group_12315'
      data-name='Group 12315'
      transform='translate(-1326 -248.551)'
    >
      <g id='noun-notes-5671224' transform='translate(1220.993 213.557)'>
        <path
          id='Path_11293'
          data-name='Path 11293'
          d='M265.447,129.46l11.007,11.007a2.837,2.837,0,0,0,4.012-4.013L269.46,125.447a2.838,2.838,0,0,0-4.013,4.013Z'
          transform='translate(-133.73 -75.091)'
          fill='#292858'
          fillRule='evenodd'
        />
        <path
          id='Path_11294'
          data-name='Path 11294'
          d='M134.8,239.273l-22.7-13.1a2.84,2.84,0,0,1-1.037-3.877l14.743-25.536a2.838,2.838,0,1,0-4.916-2.838l-14.743,25.536a8.513,8.513,0,0,0,3.117,11.627l22.7,13.1a2.837,2.837,0,1,0,2.837-4.914Z'
          transform='translate(0 -131.967)'
          fill='#292858'
          fillRule='evenodd'
        />
        <path
          id='Path_11295'
          data-name='Path 11295'
          d='M178.32,38.316a11.351,11.351,0,1,0,16.053,0A11.356,11.356,0,0,0,178.32,38.316Zm4.015,4.015a5.673,5.673,0,1,1,0,8.023A5.675,5.675,0,0,1,182.335,42.331Z'
          transform='translate(-58.642)'
          fill='#292858'
          fillRule='evenodd'
        />
        <path
          id='Path_11296'
          data-name='Path 11296'
          d='M320.506,247.241l14.744-8.512a2.838,2.838,0,0,0-2.837-4.915l-14.744,8.512a2.838,2.838,0,0,0,2.837,4.915Z'
          transform='translate(-176.991 -166.265)'
          fill='#292858'
          fillRule='evenodd'
        />
        <path
          id='Path_11297'
          data-name='Path 11297'
          d='M355.506,307.862l14.744-8.512a2.837,2.837,0,1,0-2.837-4.914l-14.744,8.512a2.837,2.837,0,1,0,2.837,4.914Z'
          transform='translate(-206.316 -217.057)'
          fill='#292858'
          fillRule='evenodd'
        />
        <path
          id='Path_11298'
          data-name='Path 11298'
          d='M390.506,383.155l9.829-5.675a2.837,2.837,0,1,0-2.837-4.914l-9.829,5.675a2.837,2.837,0,1,0,2.837,4.914Z'
          transform='translate(-235.641 -282.519)'
          fill='#292858'
          fillRule='evenodd'
        />
        <path
          id='Path_11299'
          data-name='Path 11299'
          d='M240.983,164.627l-3.294,1.9a8.512,8.512,0,0,0-3.117,11.627L251.6,207.643a8.512,8.512,0,0,0,11.628,3.114L287.8,196.572a8.517,8.517,0,0,0,3.116-11.63l-17.025-29.489c-2.309-4-7.458-5.478-12.163-2.762a2.838,2.838,0,1,0,2.837,4.915c1.682-.972,3.583-.746,4.409.686L286,187.78a2.836,2.836,0,0,1-1.038,3.874s-24.574,14.188-24.574,14.188a2.835,2.835,0,0,1-3.874-1.038l-17.025-29.489a2.835,2.835,0,0,1,1.038-3.874l3.294-1.9a2.838,2.838,0,0,0-2.838-4.915Z'
          transform='translate(-107.601 -97.456)'
          fill='#292858'
          fillRule='evenodd'
        />
      </g>
    </g>
  </svg>
);
