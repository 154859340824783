import React from 'react';
import { ActionMenuItemProps } from '../../../components/ActionMenu/ActionMenuItem';
import { ConfirmDialogDelete } from '../../../components/DeleteAndConfirm/DeleteAndConfirm';
import { deleteQuestion, FAQQuestion } from '../../../redux/actions/broadcast';
import { useAppDispatch } from '../../../redux/hooks';
import { HelpSection } from '../../help/HelpSection/HelpSection';
import { QuestionDialog } from './QuestionDialog';

type Props = {
  groupId: string;
  questions: FAQQuestion[];
};

export const FaqQuestions: React.FC<Props> = ({ groupId, questions }) => {
  const dispatch = useAppDispatch();
  const [isQuestionModalOpen, setIsQuestionModalOpen] = React.useState(false);
  const [isDeleteQuestionModalOpen, setIsDeleteQuestionModalOpen] =
    React.useState(false);
  const [selectedQuestion, setSelectedQuestion] = React.useState<FAQQuestion>();
  const questionActions = (question: FAQQuestion): ActionMenuItemProps[] => [
    {
      type: 'edit',
      description: 'Rediger',
      onClick: () => {
        setSelectedQuestion(question);
        setIsQuestionModalOpen(true);
      }
    },
    {
      type: 'delete',
      description: 'Slett',
      onClick: () => {
        setSelectedQuestion(question);
        setIsDeleteQuestionModalOpen(true);
      }
    }
  ];

  return (
    <React.Fragment>
      {questions.map(question => (
        <HelpSection
          key={question.id}
          title={question.question}
          actions={questionActions(question)}
        >
          <p>{question.answer}</p>
        </HelpSection>
      ))}
      <QuestionDialog
        isModalOpen={isQuestionModalOpen}
        closeModal={() => {
          setIsQuestionModalOpen(false);
        }}
        question={selectedQuestion}
        groupId={groupId}
      />
      <ConfirmDialogDelete
        label='Slett spørsmål'
        message='Er du sikker på at du vil slette dette spørsmålet?'
        deleteAction={() => {
          if (!selectedQuestion) return;
          dispatch(
            deleteQuestion({
              groupId: groupId,
              questionId: selectedQuestion.id
            })
          );
        }}
        isModalOpen={isDeleteQuestionModalOpen}
        setIsModalOpen={setIsDeleteQuestionModalOpen}
      />
    </React.Fragment>
  );
};
