import React from 'react';
import { useLocation } from 'react-router-dom';
import { Operation } from '../../../redux/slice/operations';
import { Subject } from '../../../redux/slice/subjects';
import {
  Breadcrumbs,
  generateSubjectBreadcrumbs
} from '../../Navigation/breadcrumbs';
import { subjectHome } from '../../Navigation/navPaths';

interface Props {
  editId: string;
  subject: Subject;
  operation: Operation;
}

const getName = (editId: string) => {
  switch (editId) {
    case 'about':
      return 'Offentlig beskrivelse';
    case 'gps-radius':
      return 'GPS-radius';
    case 'åpningstider':
      return 'Åpningstider';
    case 'vilkaar':
      return 'Vilkår';
    case 'agelimit':
      return 'Aldersgrense';
    case 'wiki':
      return 'Interninfo og -notater';
    default:
      return editId;
  }
};

export const EditOperationBreadcrumbs: React.FC<Props> = ({
  editId,
  subject,
  operation
}) => {
  const pathParams = useLocation().pathname.split('/');

  const breadcrumbs = [
    ...generateSubjectBreadcrumbs(subject),
    {
      name: operation.name,
      path: `${subjectHome.path}/${pathParams[2]}/${pathParams[3]}`
    },
    {
      name: getName(editId)
    }
  ];

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
