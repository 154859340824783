import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Select, {
  components,
  GroupBase,
  MultiValueProps,
  Props,
  SingleValueProps
} from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import classNames from 'classnames';
import { KeyComponentProps, KeyWrapper } from './KeyWrapper';

export type LabelOption = {
  label: string | React.ReactElement;
};

export type LabelTextOption = {
  labelText: string;
  description?: string;
  selectedDisplayLabel?: string;
};

export type DefaultOption = LabelOption | LabelTextOption;

export interface KeySelectType<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> extends KeyComponentProps {
  elementProps: Props<Option, IsMulti, Group>;
}

export interface KeyComboboxType<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> extends KeyComponentProps {
  elementProps: CreatableProps<Option, IsMulti, Group>;
}

export const KeySelect = <
  Option extends DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  name,
  elementProps,
  ...wrapperProps
}: KeySelectType<Option, IsMulti, Group>) => {
  const { control } = useFormContext();
  return (
    <KeyWrapper name={name} {...wrapperProps}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            inputId={name}
            {...getSelectProps(elementProps)}
            unstyled
            filterOption={(option, inputValue) => {
              const textContent = extractTextFromElement(
                option.label
              ).toLowerCase();
              return textContent.includes(inputValue.toLowerCase());
            }}
            {...field}
          />
        )}
      />
    </KeyWrapper>
  );
};

export const KeyCombobox = <
  Option extends DefaultOption,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  name,
  elementProps,
  ...wrapperProps
}: KeyComboboxType<Option, IsMulti, Group>) => {
  const { control } = useFormContext();
  return (
    <KeyWrapper name={name} {...wrapperProps}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <CreatableSelect
            inputId={name}
            {...getSelectProps(elementProps)}
            unstyled
            {...field}
          />
        )}
      />
    </KeyWrapper>
  );
};

export const getSelectProps = <
  Option extends DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  elementProps: Props<Option, IsMulti, Group>
) => {
  const classNamesProp: typeof elementProps.classNames = {
    control: state => {
      return classNames('keystudio-form-element--select', {
        'keystudio-form-element--select--focus': state.isFocused
      });
    },
    menu: () => 'keystudio-form-element--menu',
    menuList: () => 'keystudio-form-element--menuList',
    multiValue: () => 'keystudio-form-element--multiValue',
    multiValueRemove: () => 'keystudio-form-element--multiValueRemove',
    noOptionsMessage: () => 'keystudio-form-element--noOptionsMessage',
    groupHeading: () => 'keystudio-form-element--groupHeading',
    option: state => {
      return classNames('keystudio-form-element--option', {
        'keystudio-form-element--option--selected': state.isSelected,
        'keystudio-form-element--option--focused': state.isFocused
      });
    }
  };
  const components = elementProps.components || {};
  components.SingleValue = CustomSingleValue;
  components.MultiValue = CustomMultiValue;

  elementProps.options = elementProps.options?.map(option => {
    if ('options' in option) {
      return {
        ...option,
        options: option.options.map(option => ({
          ...option,
          label: generateLabel(option)
        }))
      };
    }
    return {
      ...option,
      label: generateLabel(option)
    };
  });

  return {
    classNames: classNamesProp,
    noOptionsMessage: () => 'Ingen tilgjengelige valg',
    components,
    ...elementProps
  };
};

export const extractTextFromElement = (
  element: React.ReactElement | string
): string => {
  if (typeof element === 'string') return element;
  return React.Children.toArray(element.props.children)
    .map(child =>
      typeof child === 'string'
        ? child
        : React.isValidElement(child)
          ? extractTextFromElement(child)
          : ''
    )
    .join('');
};

export const generateLabel = (
  option: DefaultOption
): React.ReactElement | string => {
  if ('labelText' in option) {
    if (option.description) {
      return (
        <React.Fragment>
          {option.labelText}
          <div className='keystudio-form-element--option-description'>
            {option.description}
          </div>
        </React.Fragment>
      );
    }
    return option.labelText;
  }
  return option.label;
};

const CustomSingleValue = <
  Option extends DefaultOption,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SingleValueProps<Option, IsMulti, Group>
) => {
  const label = getSelectedDisplayLabel(props.data);
  return <components.SingleValue {...props}>{label}</components.SingleValue>;
};

const CustomMultiValue = <
  Option extends DefaultOption,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: MultiValueProps<Option, IsMulti, Group>
) => {
  const label = getSelectedDisplayLabel(props.data);
  return <components.MultiValue {...props}>{label}</components.MultiValue>;
};

const getSelectedDisplayLabel = (option: DefaultOption) => {
  if ('selectedDisplayLabel' in option) {
    return option.selectedDisplayLabel;
  } else if ('labelText' in option) {
    return option.labelText;
  }
  return option.label;
};
