import React from 'react';
import { KeyText } from '../../../components/Forms/KeyText';

interface Props {
  title: string;
  helperText: string;
  placeholder: string;
  name: string;
}

export const NormalInput: React.FC<Props> = ({
  title,
  helperText,
  placeholder,
  name
}) => {
  return (
    <KeyText
      name={name}
      description={helperText}
      label={title}
      elementProps={{
        placeholder: placeholder
      }}
    />
  );
};
