import React, { Fragment } from 'react';
import { useAuditLog } from '../../../utils/auditLogs/useAuditLog';
import { AuditLogsTable } from '../../controlpanel/LogsAndData/pages/AuitLogTable';
import { SubjectBreadcrumbs } from '../SubjectBreadcrumbs';
import { useSubject } from '../SubjectWrapper';

export const AuditPage: React.FC = () => {
  const { subject } = useSubject();
  const auditLogs = useAuditLog(subject?.citykeyId);
  return (
    <Fragment>
      <SubjectBreadcrumbs subject={subject} name='Endringslogger' />
      <AuditLogsTable auditLogs={auditLogs} />
    </Fragment>
  );
};
