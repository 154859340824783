import { z } from 'zod';
import { InviteUserToManualGrantKeychainFactoryRequest } from '../../proto/keychain-factory_pb';
import {
  OidcReplaceRolesForUserRequest,
  OidcSetUserEmailRequest,
  OidcTerminateRootSessionRequest,
  PushOidcKeychainRequest
} from '../../proto/keychain-oidc_pb';
import {
  ExtendKeychainExpireDateRequest,
  RevokeKeychainRequest,
  ListKeychainsRequest,
  ListKeychainsResponse,
  DescribeKeychainRequest,
  DescribeKeychainResponse,
  ExtendAllKeychainsExpireDateRequest
} from '../../proto/keychain_pb';
import {
  LocalDate,
  LocalDateInterval,
  PhoneNumber
} from '../../proto/shared_pb';
import {
  getGrpcMetadata,
  getKeychainClient,
  getKeychainFactoryClient,
  getOidcKeychainClient,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import { Role } from '../slice/keychains';
import { setPopup } from '../slice/popup';
import { email } from '../types/zodSchemas';
import { createDateTime } from './util';

export const fetchListKeychains = createAppAsyncThunk<
  {
    uri: string;
    keychainsList: ListKeychainsResponse.AsObject['keychainsList'];
  },
  string
>('keychains/fetchListKeychains', async (keychainFactoryUri, thunkAPI) => {
  try {
    const request = new ListKeychainsRequest();
    request.setKeychainFactoryUri(keychainFactoryUri);
    const response = await getKeychainClient().listKeychains(
      request,
      await getGrpcMetadata()
    );
    const unclaimed = response.toObject().unclaimedKeychainsList;
    if (unclaimed.length > 0) {
      console.log('unclaimed keychains');
      console.table(unclaimed);
    }
    return {
      uri: keychainFactoryUri,
      keychainsList: response.toObject().keychainsList
    };
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const getKeychainDetails = createAppAsyncThunk<
  DescribeKeychainResponse.AsObject,
  string
>('keychains/getKeychainDetails', async (keychainId, thunkAPI) => {
  try {
    const request = new DescribeKeychainRequest();
    request.setKeychainUri(keychainId);
    const response = await getKeychainClient().describeKeychain(
      request,
      await getGrpcMetadata()
    );
    return response.toObject();
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

interface ExtendKeychainData {
  keychainId: string;
  newExpireDate: string;
  keychainFactoryUri: string;
  shareable: boolean;
}
export const extendKeychainExpireDateRequest = createAppAsyncThunk<
  ExtendKeychainData,
  ExtendKeychainData
>(
  'keychains/extendKeychainExpireDateRequest',
  async (
    { keychainId, newExpireDate, keychainFactoryUri, shareable },
    thunkAPI
  ) => {
    try {
      const request = new ExtendKeychainExpireDateRequest()
        .setKeychainId(keychainId)
        .setShareable(shareable)
        .setNewExpireDate(new LocalDate().setDateString(newExpireDate));
      await getKeychainClient().extendKeychainExpireDate(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Tilgangen ble oppdatert' })
      );
      return { keychainId, newExpireDate, keychainFactoryUri, shareable };
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

interface ExtendAllKeychainsData {
  newExpireDate: string;
  keychainFactoryUri: string;
}
export const extendAllKeychainsExpireDateRequest = createAppAsyncThunk<
  ExtendAllKeychainsData,
  ExtendAllKeychainsData
>(
  'keychains/extendAllKeychainsExpireDateRequest',
  async ({ newExpireDate, keychainFactoryUri }, thunkAPI) => {
    try {
      const request = new ExtendAllKeychainsExpireDateRequest()
        .setKeychainFactoryUri(keychainFactoryUri)
        .setNewExpireDate(new LocalDate().setDateString(newExpireDate));
      await getKeychainClient().extendAllKeychainsExpireDate(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Tilgangen ble oppdatert' })
      );
      thunkAPI.dispatch(fetchListKeychains(keychainFactoryUri));

      return { newExpireDate, keychainFactoryUri };
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

interface DeleteKeychainData {
  keychainId: string;
  keychainFactoryUri: string;
  expiredInsteadOfDelete: boolean;
}
export const deleteKeychainExpireDateRequest = createAppAsyncThunk<
  Omit<DeleteKeychainData, 'callback'>,
  DeleteKeychainData
>(
  'keychains/deleteKeychainExpireDateRequest',
  async (
    { keychainId, keychainFactoryUri, expiredInsteadOfDelete },
    thunkAPI
  ) => {
    try {
      const request = new RevokeKeychainRequest()
        .setKeychainId(keychainId)
        .setExpiredInsteadOfDelete(expiredInsteadOfDelete);
      await getKeychainClient().revokeKeychain(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Tilgangen ble slettet' })
      );
      return { keychainId, keychainFactoryUri, expiredInsteadOfDelete };
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

interface GrantAccessCommon {
  keychainFactoryUri: string;
  phoneNumber: string;
  countryCode: string;
  endDate: string;
  shareable: boolean;
}
interface GrantAccessData extends GrantAccessCommon {
  startDate: string;
}
export const grantAccess = createAppAsyncThunk<void, GrantAccessData>(
  'keychains/grantAccess',
  async (
    {
      phoneNumber,
      countryCode,
      keychainFactoryUri,
      startDate,
      endDate,
      shareable
    },
    thunkAPI
  ) => {
    try {
      const phoneNumberProto = new PhoneNumber()
        .setCountryCode(countryCode)
        .setNumber(phoneNumber);
      const request = new InviteUserToManualGrantKeychainFactoryRequest()
        .setApprovedFromUntil(
          new LocalDateInterval()
            .setFrom(new LocalDate().setDateString(startDate))
            .setUntil(new LocalDate().setDateString(endDate))
        )
        .setKeychainFactoryUri(keychainFactoryUri)
        .setPhoneNumber(phoneNumberProto)
        .setShareable(shareable);
      await getKeychainFactoryClient().inviteUserToManualGrantKeychainFactory(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Brukeren har fått tilgang'
        })
      );
      thunkAPI.dispatch(fetchListKeychains(keychainFactoryUri));
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);
interface GrantOidcAccessData extends GrantAccessCommon {
  roles: string[];
  email: string;
}
export const grantOidcAccess = createAppAsyncThunk<void, GrantOidcAccessData>(
  'keychains/grantOidcAccess',
  async (
    { keychainFactoryUri, roles, phoneNumber, countryCode, email, endDate },
    thunkAPI
  ) => {
    try {
      const phoneNumberProto = new PhoneNumber()
        .setCountryCode(countryCode)
        .setNumber(phoneNumber);
      const request = new PushOidcKeychainRequest()
        .setKeychainFactoryUri(keychainFactoryUri)
        .setPhoneNumber(phoneNumberProto)
        .setEmailAddress(email)
        .setRoleIdsList(roles);
      request.setGrantedUntil(createDateTime({ date: endDate, time: '23:59' }));
      await getOidcKeychainClient().pushOidcKeychain(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Tilgang er gitt!'
        })
      );
      thunkAPI.dispatch(fetchListKeychains(keychainFactoryUri));
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

type ReplaceRolesProps = {
  keychainUri: string;
  roles: Role[];
};

export const replaceRoles = createAppAsyncThunk<
  ReplaceRolesProps,
  ReplaceRolesProps
>('keychains/replaceRoles', async ({ roles, keychainUri }, thunkAPI) => {
  try {
    const request = new OidcReplaceRolesForUserRequest()
      .setKeychainUri(keychainUri)
      .setRoleIdsList(roles.map(role => role.id));
    await getOidcKeychainClient().oidcReplaceRolesForUser(
      request,
      await getGrpcMetadata()
    );
    thunkAPI.dispatch(
      setPopup({
        error: false,
        message: 'Roller er oppdatert!'
      })
    );
    return { roles, keychainUri };
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const SetEmailProps = z.object({
  keychainUri: z.string(),
  email: email
});

export type SetEmailProps = z.infer<typeof SetEmailProps>;

export const setUserEmail = createAppAsyncThunk<SetEmailProps, SetEmailProps>(
  'keychains/setUserEmail',
  async ({ email, keychainUri }, thunkAPI) => {
    try {
      const request = new OidcSetUserEmailRequest()
        .setKeychainUri(keychainUri)
        .setEmailAddress(email);
      await getOidcKeychainClient().oidcSetUserEmail(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'E-post er oppdatert!'
        })
      );
      return { email, keychainUri };
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const terminateSession = createAppAsyncThunk<
  void,
  { factoryUri: string; sessionId: string; keychainId: string }
>(
  'keychains/terminateSession',
  async ({ factoryUri, sessionId, keychainId }, thunkAPI) => {
    try {
      const request = new OidcTerminateRootSessionRequest()
        .setFactoryUri(factoryUri)
        .setRootSessionId(sessionId);
      await getOidcKeychainClient().oidcTerminateRootSession(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Session is terminated!'
        })
      );
      thunkAPI.dispatch(getKeychainDetails(keychainId));
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);
