import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { CreateSection } from '../../../components/CreateSection/CreateSection';
import { KeyText } from '../../../components/Forms/KeyText';
import { KeyTextarea } from '../../../components/Forms/KeyTextarea';
import {
  createCategory,
  CreateCategoryForm
} from '../../../redux/actions/categories';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const CreateCategory = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<CreateCategoryForm>({
    resolver: zodResolver(CreateCategoryForm)
  });

  const { handleSubmit } = methods;

  const handleSaveClick = (data: CreateCategoryForm) =>
    dispatchAndHandleResult(dispatch, createCategory, data, () => {
      navigate('..', { relative: 'path' });
    });

  return (
    <CreateSection>
      <ControlpanelBreadcrumbs currentPageName='Lag ny kategori' />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <KeyText
            name='name'
            description='Skriv inn kategoriens navn. Et eksempel på dette er Bibliotek.'
            label='Navn'
            elementProps={{
              placeholder: 'Skriv inn et navn'
            }}
          />
          <KeyTextarea
            name='about'
            label='Beskrivelse'
            description='Skriv en beskrivelse av kategorien.'
            elementProps={{
              placeholder: 'Skriv en beskrivelse'
            }}
          />

          <Button label='Lagre' type='submit' />
        </form>
      </FormProvider>
    </CreateSection>
  );
};
