import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdminCategoriesPage } from '../pages/controlpanel/Category/AdminCategoriesPage';
import { AdminCategory } from '../pages/controlpanel/Category/AdminCategory';
import { CreateCategory } from '../pages/controlpanel/Category/CreateCategory';
import { listCategories } from '../redux/actions/categories';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const AdminCategoryRouter = () => {
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedAdminTool !== 'category') {
      dispatch(setSelectedAdminTool('category'));
      dispatch(listCategories());
    }
  }, [selectedAdminTool, dispatch]);
  return (
    <section>
      <Routes>
        <Route path='lag-ny' element={<CreateCategory />} />
        <Route path=':categoryId' element={<AdminCategory />} />
        <Route path='/' element={<AdminCategoriesPage />} />
      </Routes>
    </section>
  );
};
