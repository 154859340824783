import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { EditOpeningHoursContainer } from '../../../components/EditOpeningHoursContainer/EditOpeningHoursContainer';
import { updateKeychainFactoryOpeningHoursRequest } from '../../../redux/actions/keychainFactoryDetails';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { historyGoBack } from '../../../utils/history';
import { useKeychainFactoryDetails } from '../KeychainDetailsWrapper';
import { KeychainFactoryBreadcrumbs } from '../KeychainFactoryBreadcrumbs';
import { OpeningType } from './openingType/OpeningType';
import { ManualGrantKeychainFactoryOverridingHours } from './overridingRules/ManualGrantKeychainFactoryOverridingHours';
import { ManualGrantKeychainFactoryNormalHours } from './simpleDayRules/ManualGrantKeychainFactoryNormalHours';

export const OpeningHoursPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { keychainFactory } = useKeychainFactoryDetails();
  const initialHours =
    'openingHours' in keychainFactory
      ? keychainFactory.openingHours
      : undefined;

  if (!initialHours) {
    throw new Error('Invalid keychainFactory type');
  }
  const [openingHours, setOpeningHours] = useState(initialHours);

  return (
    <EditOpeningHoursContainer>
      <KeychainFactoryBreadcrumbs
        keychainFactory={keychainFactory}
        name='Rediger åpningstider'
      />
      <div className='card'>
        <h3>Åpningstider</h3>
        <p>
          Her kan du redigere åpningstidene til <b>{keychainFactory.name}</b>.
        </p>
        <OpeningType
          openingHours={openingHours}
          setOpeningHours={setOpeningHours}
        />

        <div className='opening-hour-container'>
          <div className='opening-hour'>
            <ManualGrantKeychainFactoryNormalHours
              openingHours={openingHours}
              setOpeningHours={setOpeningHours}
            />
            <ManualGrantKeychainFactoryOverridingHours
              openingHours={openingHours}
              setOpeningHours={setOpeningHours}
            />
          </div>
        </div>
        <div className='button-row'>
          <Link to='..'>
            <Button label='Avbryt' type='secondary' />
          </Link>

          <Button
            label='Lagre'
            type='primary'
            onClick={() =>
              dispatchAndHandleResult(
                dispatch,
                updateKeychainFactoryOpeningHoursRequest,
                {
                  keychainFactoryUri: keychainFactory.keychainFactoryUri,
                  openingHours
                },
                () => {
                  historyGoBack(navigate);
                }
              )
            }
          />
        </div>
      </div>
    </EditOpeningHoursContainer>
  );
};
