import { z } from 'zod';
import { OperationId } from '../../proto/shared_pb';
import {
  CreateOperationBucketRequest,
  DeleteOperationBucketRequest,
  FetchOperationBucketsRequest,
  OperationBucket,
  UpdateOperationBucketNameRequest,
  UpdateOperationBucketOperationsRequest
} from '../../proto/statistics_pb';
import {
  getGrpcMetadata,
  getStatisticsServiceClient,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import { BucketOperation } from '../slice/buckets';
import { setPopup } from '../slice/popup';
import { id, name } from '../types/zodSchemas';

type OperationBucketProps = {
  id: string;
  name: string;
  operationsList: BucketOperation[];
};

export const createOperationBucket = createAppAsyncThunk<
  void,
  OperationBucketProps
>(
  'buckets/createOperationBucket',
  async ({ id, name, operationsList }, thunkAPI) => {
    try {
      await getStatisticsServiceClient().createOperationBucket(
        new CreateOperationBucketRequest().setOperationBucket(
          new OperationBucket()
            .setId(id)
            .setName(name)
            .setOperationIdsList(getOperationIdsList(operationsList))
        ),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Operasjonsspannet er opprettet' })
      );
      thunkAPI.dispatch(fetchOperationBuckets());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const UpdateOperationBucketName = z.object({
  id: id,
  name: name
});
export type UpdateOperationBucketName = z.infer<
  typeof UpdateOperationBucketName
>;
export const updateOperationBucketName = createAppAsyncThunk<
  void,
  UpdateOperationBucketName
>('buckets/updateOperationBucketName', async ({ id, name }, thunkAPI) => {
  try {
    await getStatisticsServiceClient().updateOperationBucketName(
      new UpdateOperationBucketNameRequest().setId(id).setName(name),
      await getGrpcMetadata()
    );
    thunkAPI.dispatch(fetchOperationBuckets());
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const fetchOperationBuckets = createAppAsyncThunk(
  'buckets/fetchOperationBuckets',
  async (_, thunkAPI) => {
    try {
      const response = await getStatisticsServiceClient().fetchOperationBuckets(
        new FetchOperationBucketsRequest(),
        await getGrpcMetadata()
      );
      return response.toObject().operationBucketsList;
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const updateOperationBucketOperations = createAppAsyncThunk<
  void,
  Pick<OperationBucketProps, 'id' | 'operationsList'>
>(
  'buckets/updateOperationBucketOperations',
  async ({ id, operationsList }, thunkAPI) => {
    try {
      await getStatisticsServiceClient().updateOperationBucketOperations(
        new UpdateOperationBucketOperationsRequest()
          .setId(id)
          .setOperationIdsList(getOperationIdsList(operationsList)),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Operasjonsspannet er lagret' })
      );
      thunkAPI.dispatch(fetchOperationBuckets());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const deleteOperationBucket = createAppAsyncThunk<
  Pick<OperationBucketProps, 'id'>,
  Pick<OperationBucketProps, 'id'>
>('buckets/deleteOperationBucket', async ({ id }, thunkAPI) => {
  try {
    await getStatisticsServiceClient().deleteOperationBucket(
      new DeleteOperationBucketRequest().setId(id),
      await getGrpcMetadata()
    );
    thunkAPI.dispatch(
      setPopup({ error: false, message: 'Operasjonsspannet er slettet' })
    );
    thunkAPI.dispatch(fetchOperationBuckets());
    return { id };
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const getAllOperationIds = createAppAsyncThunk(
  'buckets/getAllOperationIds',
  async (_, thunkAPI) => {
    try {
      const response = await getStatisticsServiceClient().getAllOperationIds(
        new FetchOperationBucketsRequest(),
        await getGrpcMetadata()
      );
      return response.toObject().operationIdsList;
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

const getOperationIdsList = (operationsList: BucketOperation[]) =>
  operationsList.map(operation => {
    switch (operation.operationType) {
      case 'subjectOperation':
        return new OperationId().setSubjectOperation(
          new OperationId.SubjectOperation()
            .setOperationId(operation.operationId)
            .setSubjectId(operation.subjectId)
        );
      case 'admissionCard':
        return new OperationId().setAdmissionCard(
          new OperationId.AdmissionCard().setType(operation.type)
        );
      case 'oidc':
        return new OperationId().setOidc(
          new OperationId.Oidc()
            .setRealm(operation.realm)
            .setMethod(operation.method)
        );
    }
  });
