import {
  DateOverrideRule,
  OpeningHours,
  SimpleDayRule
} from '../../redux/types/common';
import { daysEng } from '../operation/openingHours/openingHoursFunctions';
import { formatDayDate } from '../timeConverter';

export interface TimeInterval {
  from: string;
  until: string | undefined;
}

const defaultListOfOpeningHours = [
  'Mandag: Stengt',
  'Tirsdag: Stengt',
  'Onsdag: Stengt',
  'Torsdag: Stengt',
  'Fredag: Stengt',
  'Lørdag: Stengt',
  'Søndag: Stengt'
];

export const getDayFromRule = (rule: string) =>
  rule.substr(0, rule.indexOf(':'));

export const getRuleIndexFromDay = (day: string, openingHours: string[]) => {
  for (let i = 0; i < openingHours.length; i++) {
    if (openingHours[i].includes(day)) {
      return i;
    }
  }
};

export const formatManualGrantKeychainFactoryOpeningHours = (
  openingHours: OpeningHours
) => {
  const { spec } = openingHours;
  const openingHoursFromSpec = spec.split('\n');
  const listOfOpeningHours = [...defaultListOfOpeningHours];

  for (let i = 0; i < openingHoursFromSpec.length; i++) {
    const day = getDayFromRule(openingHoursFromSpec[i]);
    const indexToUpdate = getRuleIndexFromDay(day, listOfOpeningHours);
    if (indexToUpdate !== undefined && openingHoursFromSpec[i] !== '') {
      listOfOpeningHours[indexToUpdate] = openingHoursFromSpec[i];
    }
  }

  return listOfOpeningHours;
};

export const getDayAndTimeFromRule = (rule: string) => {
  const splitRule = rule.split(': ');

  return {
    day: splitRule[0],
    time: splitRule[1]
  };
};

export const formatSimpleDayRules = (simpleDayRule: SimpleDayRule[]) => {
  const formattedSimpleDayRules: SimpleDayRule[] = [];

  for (let i = 0; i < daysEng.length; i++) {
    const rule = {
      day: daysEng[i],
      timeInterval: [{ from: '00:00', until: '00:00' }]
    };

    for (let j = 0; j < simpleDayRule.length; j++) {
      if (simpleDayRule[j].day === daysEng[i]) {
        rule.timeInterval = simpleDayRule[j].timeInterval;
      }
    }

    formattedSimpleDayRules[i] = rule;
  }

  return formattedSimpleDayRules;
};

export const checkIfDayIsClosed = (timeInterval: TimeInterval) => {
  if ((timeInterval.from && timeInterval.until) === '00:00') {
    return true;
  } else {
    return false;
  }
};

export const combineDateAndClosedRules = (openingHours: OpeningHours) => {
  const { closedDateRulesList, dateOverrideRulesList } = openingHours;
  const overridingRules: DateOverrideRule[] = [...dateOverrideRulesList];

  for (let i = 0; i < closedDateRulesList.length; i++) {
    overridingRules.push({
      date: closedDateRulesList[i].date,
      timeInterval: [{ from: '00:00', until: '00:00' }]
    });
  }

  return overridingRules;
};

export const updateOverridingRuleDate = (
  openingHours: OpeningHours,
  type: string,
  date: string,
  newDate: Date
) => {
  const updatedRules =
    type === 'dateOverrideRules'
      ? [...openingHours.dateOverrideRulesList]
      : [...openingHours.closedDateRulesList];

  const ruleIndex = updatedRules.findIndex(
    (rule: { date: string }) => rule.date === date
  );
  updatedRules[ruleIndex].date = formatDayDate(newDate);

  return {
    ...openingHours,
    [type]: updatedRules
  };
};
