/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.logs
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: operation-log.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as operation$log_pb from './operation-log_pb'; // proto import: "operation-log.proto"


export class OperationLogServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGenerateStatistics = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/GenerateStatistics',
    grpcWeb.MethodType.UNARY,
    operation$log_pb.StatisticsRequest,
    operation$log_pb.StatisticsResponse,
    (request: operation$log_pb.StatisticsRequest) => {
      return request.serializeBinary();
    },
    operation$log_pb.StatisticsResponse.deserializeBinary
  );

  generateStatistics(
    request: operation$log_pb.StatisticsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<operation$log_pb.StatisticsResponse>;

  generateStatistics(
    request: operation$log_pb.StatisticsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: operation$log_pb.StatisticsResponse) => void): grpcWeb.ClientReadableStream<operation$log_pb.StatisticsResponse>;

  generateStatistics(
    request: operation$log_pb.StatisticsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: operation$log_pb.StatisticsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/GenerateStatistics',
        request,
        metadata || {},
        this.methodDescriptorGenerateStatistics,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/GenerateStatistics',
    request,
    metadata || {},
    this.methodDescriptorGenerateStatistics);
  }

  methodDescriptorGenerateBulkStatistics = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/GenerateBulkStatistics',
    grpcWeb.MethodType.SERVER_STREAMING,
    operation$log_pb.BulkStatisticsRequest,
    operation$log_pb.BulkStatisticsResponse,
    (request: operation$log_pb.BulkStatisticsRequest) => {
      return request.serializeBinary();
    },
    operation$log_pb.BulkStatisticsResponse.deserializeBinary
  );

  generateBulkStatistics(
    request: operation$log_pb.BulkStatisticsRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<operation$log_pb.BulkStatisticsResponse> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/GenerateBulkStatistics',
      request,
      metadata || {},
      this.methodDescriptorGenerateBulkStatistics);
  }

  methodDescriptorFetchOperationLogAudits = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/FetchOperationLogAudits',
    grpcWeb.MethodType.UNARY,
    operation$log_pb.OperationLogAuditsRequest,
    operation$log_pb.OperationLogAuditsResponse,
    (request: operation$log_pb.OperationLogAuditsRequest) => {
      return request.serializeBinary();
    },
    operation$log_pb.OperationLogAuditsResponse.deserializeBinary
  );

  fetchOperationLogAudits(
    request: operation$log_pb.OperationLogAuditsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<operation$log_pb.OperationLogAuditsResponse>;

  fetchOperationLogAudits(
    request: operation$log_pb.OperationLogAuditsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: operation$log_pb.OperationLogAuditsResponse) => void): grpcWeb.ClientReadableStream<operation$log_pb.OperationLogAuditsResponse>;

  fetchOperationLogAudits(
    request: operation$log_pb.OperationLogAuditsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: operation$log_pb.OperationLogAuditsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/FetchOperationLogAudits',
        request,
        metadata || {},
        this.methodDescriptorFetchOperationLogAudits,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/FetchOperationLogAudits',
    request,
    metadata || {},
    this.methodDescriptorFetchOperationLogAudits);
  }

  methodDescriptorFetchDecryptedOperationLogData = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/FetchDecryptedOperationLogData',
    grpcWeb.MethodType.UNARY,
    operation$log_pb.DecryptedOperationLogDataRequest,
    operation$log_pb.DecryptedOperationLogDataResponse,
    (request: operation$log_pb.DecryptedOperationLogDataRequest) => {
      return request.serializeBinary();
    },
    operation$log_pb.DecryptedOperationLogDataResponse.deserializeBinary
  );

  fetchDecryptedOperationLogData(
    request: operation$log_pb.DecryptedOperationLogDataRequest,
    metadata?: grpcWeb.Metadata | null): Promise<operation$log_pb.DecryptedOperationLogDataResponse>;

  fetchDecryptedOperationLogData(
    request: operation$log_pb.DecryptedOperationLogDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: operation$log_pb.DecryptedOperationLogDataResponse) => void): grpcWeb.ClientReadableStream<operation$log_pb.DecryptedOperationLogDataResponse>;

  fetchDecryptedOperationLogData(
    request: operation$log_pb.DecryptedOperationLogDataRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: operation$log_pb.DecryptedOperationLogDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/FetchDecryptedOperationLogData',
        request,
        metadata || {},
        this.methodDescriptorFetchDecryptedOperationLogData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.logs.OperationLogService/FetchDecryptedOperationLogData',
    request,
    metadata || {},
    this.methodDescriptorFetchDecryptedOperationLogData);
  }

}

