import React from 'react';
import { Button } from '../../../components/Button/Button';
import { thirdPartySystemHome } from '../../../components/Navigation/controlpanelNavPaths';
import { ConfirmCard } from '../../../components/cards/ConfirmCard/ConfirmCard';
import { useAppDispatch } from '../../../redux/hooks';
import { setNewApiKey } from '../../../redux/slice/thirdPartySystems';

interface Props {
  apiKey: string;
  id: string;
  setPopup: (showPopup: boolean) => void;
}

export const KeyPopup: React.FC<Props> = ({ apiKey, id, setPopup }) => {
  const dispatch = useAppDispatch();
  const onclick = () => {
    dispatch(setNewApiKey(''));
    setPopup(false);
  };
  return (
    <ConfirmCard>
      <div className='confirm-box'>
        <div className='top'>
          <h2>API Nøkkel</h2>
          <p>Ta godt vare på API-nøkkelen!</p>
          <input
            className='important'
            type='text'
            disabled={true}
            value={apiKey}
          />
        </div>
        <div className='buttons'>
          <Button
            label='Ferdig'
            type='destructive'
            onClick={onclick}
            linkTo={`${thirdPartySystemHome.path}/${id}`}
          />
        </div>
      </div>
    </ConfirmCard>
  );
};
