import React from 'react';

export const WarningCircleInactive = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
  >
    <g
      id='Group_1795'
      data-name='Group 1795'
      transform='translate(0.099 0.099)'
    >
      <circle
        id='Ellipse_522'
        data-name='Ellipse 522'
        cx='7.5'
        cy='7.5'
        r='7.5'
        transform='translate(0.401 0.401)'
        fill='#fff'
        stroke='#2a2859'
        strokeWidth='1'
      />
      <path
        id='Path_3073'
        data-name='Path 3073'
        d='M14.593,7.562l.188,5.634h1.033L16,7.562Z'
        transform='translate(-7.484 -3.974)'
        fill='#2a2859'
      />
      <path
        id='Path_3074'
        data-name='Path 3074'
        d='M14.124,21.627a.939.939,0,1,0,.939-.939A.924.924,0,0,0,14.124,21.627Z'
        transform='translate(-7.25 -10.526)'
        fill='#2a2859'
      />
    </g>
  </svg>
);
