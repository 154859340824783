// source: shared.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.oslonokkelen.proto.shared.Action', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Action.ActionHealthApi', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Action.ResponseRecord', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.ActionDefinition', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.AnomalyMessage', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Category', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Coordinates', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.DynamicTag', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.DynamicTag.Type', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.EmptyResponse', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.ErrorResponse', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.FullActionId', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.FullOperationId', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.HealthColor', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.HealthSummary', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Image', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.KeychainFactoryHeader', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.KeychainFactoryType', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Keypedia', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.LocalDate', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.LocalDateInterval', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.LocalDateTime', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.LocalDateTimeInterval', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.LocalTime', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.LocalTimeInterval', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OpeningHours', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Operation', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationEntity', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationEntity.Door', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationEntity.EntityCase', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationEntity.Light', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationId', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationId.AdmissionCard', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationId.IdCase', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationId.Oidc', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationId.Oidc.Method', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationId.SubjectOperation', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.OperationType', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.PersonName', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.PhoneNumber', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Problem', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Problem.HowBad', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Result', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Text', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Text.Type', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Thing', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Thing.LastSeen', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Thing.Network', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.Timestamp', null, global);
goog.exportSymbol('proto.oslonokkelen.proto.shared.TimestampInterval', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.FullOperationId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.FullOperationId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.FullOperationId.displayName = 'proto.oslonokkelen.proto.shared.FullOperationId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OperationEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.oslonokkelen.proto.shared.OperationEntity.oneofGroups_);
};
goog.inherits(proto.oslonokkelen.proto.shared.OperationEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OperationEntity.displayName = 'proto.oslonokkelen.proto.shared.OperationEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OperationEntity.Door = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.OperationEntity.Door, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OperationEntity.Door.displayName = 'proto.oslonokkelen.proto.shared.OperationEntity.Door';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OperationEntity.Light = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.OperationEntity.Light, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OperationEntity.Light.displayName = 'proto.oslonokkelen.proto.shared.OperationEntity.Light';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OperationId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.oslonokkelen.proto.shared.OperationId.oneofGroups_);
};
goog.inherits(proto.oslonokkelen.proto.shared.OperationId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OperationId.displayName = 'proto.oslonokkelen.proto.shared.OperationId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.OperationId.SubjectOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.displayName = 'proto.oslonokkelen.proto.shared.OperationId.SubjectOperation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OperationId.AdmissionCard = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.OperationId.AdmissionCard, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.displayName = 'proto.oslonokkelen.proto.shared.OperationId.AdmissionCard';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.OperationId.Oidc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OperationId.Oidc.displayName = 'proto.oslonokkelen.proto.shared.OperationId.Oidc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Text = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Text, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Text.displayName = 'proto.oslonokkelen.proto.shared.Text';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Image.displayName = 'proto.oslonokkelen.proto.shared.Image';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.EmptyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.EmptyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.EmptyResponse.displayName = 'proto.oslonokkelen.proto.shared.EmptyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.PhoneNumber = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.PhoneNumber, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.PhoneNumber.displayName = 'proto.oslonokkelen.proto.shared.PhoneNumber';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.ErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.ErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.ErrorResponse.displayName = 'proto.oslonokkelen.proto.shared.ErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.FullActionId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.FullActionId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.FullActionId.displayName = 'proto.oslonokkelen.proto.shared.FullActionId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.HealthSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.HealthSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.HealthSummary.displayName = 'proto.oslonokkelen.proto.shared.HealthSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Coordinates = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Coordinates, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Coordinates.displayName = 'proto.oslonokkelen.proto.shared.Coordinates';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Timestamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Timestamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Timestamp.displayName = 'proto.oslonokkelen.proto.shared.Timestamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.TimestampInterval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.TimestampInterval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.TimestampInterval.displayName = 'proto.oslonokkelen.proto.shared.TimestampInterval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.LocalDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.LocalDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.LocalDate.displayName = 'proto.oslonokkelen.proto.shared.LocalDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.LocalTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.LocalTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.LocalTime.displayName = 'proto.oslonokkelen.proto.shared.LocalTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.LocalDateTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.LocalDateTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.LocalDateTime.displayName = 'proto.oslonokkelen.proto.shared.LocalDateTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.LocalDateInterval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.LocalDateInterval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.LocalDateInterval.displayName = 'proto.oslonokkelen.proto.shared.LocalDateInterval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.LocalTimeInterval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.LocalTimeInterval.displayName = 'proto.oslonokkelen.proto.shared.LocalTimeInterval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.LocalDateTimeInterval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.LocalDateTimeInterval.displayName = 'proto.oslonokkelen.proto.shared.LocalDateTimeInterval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OpeningHours = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oslonokkelen.proto.shared.OpeningHours.repeatedFields_, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.OpeningHours, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OpeningHours.displayName = 'proto.oslonokkelen.proto.shared.OpeningHours';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.repeatedFields_, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.displayName = 'proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.repeatedFields_, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.displayName = 'proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.displayName = 'proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.PersonName = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.PersonName, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.PersonName.displayName = 'proto.oslonokkelen.proto.shared.PersonName';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.ActionDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.ActionDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.ActionDefinition.displayName = 'proto.oslonokkelen.proto.shared.ActionDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Problem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Problem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Problem.displayName = 'proto.oslonokkelen.proto.shared.Problem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Operation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oslonokkelen.proto.shared.Operation.repeatedFields_, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Operation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Operation.displayName = 'proto.oslonokkelen.proto.shared.Operation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.KeychainFactoryHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.KeychainFactoryHeader.displayName = 'proto.oslonokkelen.proto.shared.KeychainFactoryHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Category = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oslonokkelen.proto.shared.Category.repeatedFields_, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Category, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Category.displayName = 'proto.oslonokkelen.proto.shared.Category';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Thing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oslonokkelen.proto.shared.Thing.repeatedFields_, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Thing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Thing.displayName = 'proto.oslonokkelen.proto.shared.Thing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Thing.LastSeen, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Thing.LastSeen.displayName = 'proto.oslonokkelen.proto.shared.Thing.LastSeen';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Thing.Network = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Thing.Network, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Thing.Network.displayName = 'proto.oslonokkelen.proto.shared.Thing.Network';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Action = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.oslonokkelen.proto.shared.Action.repeatedFields_, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Action, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Action.displayName = 'proto.oslonokkelen.proto.shared.Action';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Action.ActionHealthApi, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Action.ActionHealthApi.displayName = 'proto.oslonokkelen.proto.shared.Action.ActionHealthApi';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Action.ResponseRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Action.ResponseRecord.displayName = 'proto.oslonokkelen.proto.shared.Action.ResponseRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.displayName = 'proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.DynamicTag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.DynamicTag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.DynamicTag.displayName = 'proto.oslonokkelen.proto.shared.DynamicTag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.Keypedia = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.Keypedia, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.Keypedia.displayName = 'proto.oslonokkelen.proto.shared.Keypedia';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.oslonokkelen.proto.shared.AnomalyMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.oslonokkelen.proto.shared.AnomalyMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.oslonokkelen.proto.shared.AnomalyMessage.displayName = 'proto.oslonokkelen.proto.shared.AnomalyMessage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.FullOperationId.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.FullOperationId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.FullOperationId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.FullOperationId.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.oslonokkelen.proto.shared.FullOperationId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.FullOperationId;
  return proto.oslonokkelen.proto.shared.FullOperationId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.FullOperationId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.oslonokkelen.proto.shared.FullOperationId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.FullOperationId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.FullOperationId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.FullOperationId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.FullOperationId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.FullOperationId.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.FullOperationId} returns this
 */
proto.oslonokkelen.proto.shared.FullOperationId.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operation_id = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.FullOperationId.prototype.getOperationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.FullOperationId} returns this
 */
proto.oslonokkelen.proto.shared.FullOperationId.prototype.setOperationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.oslonokkelen.proto.shared.OperationEntity.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.oslonokkelen.proto.shared.OperationEntity.EntityCase = {
  ENTITY_NOT_SET: 0,
  DOOR: 1,
  LIGHT: 2
};

/**
 * @return {proto.oslonokkelen.proto.shared.OperationEntity.EntityCase}
 */
proto.oslonokkelen.proto.shared.OperationEntity.prototype.getEntityCase = function() {
  return /** @type {proto.oslonokkelen.proto.shared.OperationEntity.EntityCase} */(jspb.Message.computeOneofCase(this, proto.oslonokkelen.proto.shared.OperationEntity.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OperationEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OperationEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OperationEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    door: (f = msg.getDoor()) && proto.oslonokkelen.proto.shared.OperationEntity.Door.toObject(includeInstance, f),
    light: (f = msg.getLight()) && proto.oslonokkelen.proto.shared.OperationEntity.Light.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OperationEntity}
 */
proto.oslonokkelen.proto.shared.OperationEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OperationEntity;
  return proto.oslonokkelen.proto.shared.OperationEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OperationEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OperationEntity}
 */
proto.oslonokkelen.proto.shared.OperationEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.OperationEntity.Door;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.OperationEntity.Door.deserializeBinaryFromReader);
      msg.setDoor(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.OperationEntity.Light;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.OperationEntity.Light.deserializeBinaryFromReader);
      msg.setLight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OperationEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OperationEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OperationEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoor();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.OperationEntity.Door.serializeBinaryToWriter
    );
  }
  f = message.getLight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.OperationEntity.Light.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OperationEntity.Door.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OperationEntity.Door.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OperationEntity.Door} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationEntity.Door.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OperationEntity.Door}
 */
proto.oslonokkelen.proto.shared.OperationEntity.Door.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OperationEntity.Door;
  return proto.oslonokkelen.proto.shared.OperationEntity.Door.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OperationEntity.Door} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OperationEntity.Door}
 */
proto.oslonokkelen.proto.shared.OperationEntity.Door.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OperationEntity.Door.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OperationEntity.Door.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OperationEntity.Door} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationEntity.Door.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OperationEntity.Light.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OperationEntity.Light.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OperationEntity.Light} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationEntity.Light.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OperationEntity.Light}
 */
proto.oslonokkelen.proto.shared.OperationEntity.Light.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OperationEntity.Light;
  return proto.oslonokkelen.proto.shared.OperationEntity.Light.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OperationEntity.Light} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OperationEntity.Light}
 */
proto.oslonokkelen.proto.shared.OperationEntity.Light.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OperationEntity.Light.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OperationEntity.Light.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OperationEntity.Light} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationEntity.Light.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional Door door = 1;
 * @return {?proto.oslonokkelen.proto.shared.OperationEntity.Door}
 */
proto.oslonokkelen.proto.shared.OperationEntity.prototype.getDoor = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OperationEntity.Door} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.OperationEntity.Door, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OperationEntity.Door|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.OperationEntity} returns this
*/
proto.oslonokkelen.proto.shared.OperationEntity.prototype.setDoor = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.oslonokkelen.proto.shared.OperationEntity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.OperationEntity} returns this
 */
proto.oslonokkelen.proto.shared.OperationEntity.prototype.clearDoor = function() {
  return this.setDoor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.OperationEntity.prototype.hasDoor = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Light light = 2;
 * @return {?proto.oslonokkelen.proto.shared.OperationEntity.Light}
 */
proto.oslonokkelen.proto.shared.OperationEntity.prototype.getLight = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OperationEntity.Light} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.OperationEntity.Light, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OperationEntity.Light|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.OperationEntity} returns this
*/
proto.oslonokkelen.proto.shared.OperationEntity.prototype.setLight = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.oslonokkelen.proto.shared.OperationEntity.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.OperationEntity} returns this
 */
proto.oslonokkelen.proto.shared.OperationEntity.prototype.clearLight = function() {
  return this.setLight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.OperationEntity.prototype.hasLight = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.oslonokkelen.proto.shared.OperationId.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.oslonokkelen.proto.shared.OperationId.IdCase = {
  ID_NOT_SET: 0,
  SUBJECT_OPERATION: 1,
  ADMISSION_CARD: 2,
  OIDC: 3
};

/**
 * @return {proto.oslonokkelen.proto.shared.OperationId.IdCase}
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.getIdCase = function() {
  return /** @type {proto.oslonokkelen.proto.shared.OperationId.IdCase} */(jspb.Message.computeOneofCase(this, proto.oslonokkelen.proto.shared.OperationId.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OperationId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OperationId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationId.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectOperation: (f = msg.getSubjectOperation()) && proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.toObject(includeInstance, f),
    admissionCard: (f = msg.getAdmissionCard()) && proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.toObject(includeInstance, f),
    oidc: (f = msg.getOidc()) && proto.oslonokkelen.proto.shared.OperationId.Oidc.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OperationId}
 */
proto.oslonokkelen.proto.shared.OperationId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OperationId;
  return proto.oslonokkelen.proto.shared.OperationId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OperationId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OperationId}
 */
proto.oslonokkelen.proto.shared.OperationId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.OperationId.SubjectOperation;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.deserializeBinaryFromReader);
      msg.setSubjectOperation(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.OperationId.AdmissionCard;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.deserializeBinaryFromReader);
      msg.setAdmissionCard(value);
      break;
    case 3:
      var value = new proto.oslonokkelen.proto.shared.OperationId.Oidc;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.OperationId.Oidc.deserializeBinaryFromReader);
      msg.setOidc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OperationId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OperationId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectOperation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.serializeBinaryToWriter
    );
  }
  f = message.getAdmissionCard();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.serializeBinaryToWriter
    );
  }
  f = message.getOidc();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.oslonokkelen.proto.shared.OperationId.Oidc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OperationId.SubjectOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OperationId.SubjectOperation}
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OperationId.SubjectOperation;
  return proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OperationId.SubjectOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OperationId.SubjectOperation}
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OperationId.SubjectOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.OperationId.SubjectOperation} returns this
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operation_id = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.prototype.getOperationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.OperationId.SubjectOperation} returns this
 */
proto.oslonokkelen.proto.shared.OperationId.SubjectOperation.prototype.setOperationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OperationId.AdmissionCard} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OperationId.AdmissionCard}
 */
proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OperationId.AdmissionCard;
  return proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OperationId.AdmissionCard} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OperationId.AdmissionCard}
 */
proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OperationId.AdmissionCard} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.OperationId.AdmissionCard} returns this
 */
proto.oslonokkelen.proto.shared.OperationId.AdmissionCard.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OperationId.Oidc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OperationId.Oidc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.toObject = function(includeInstance, msg) {
  var f, obj = {
    realm: jspb.Message.getFieldWithDefault(msg, 1, ""),
    method: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OperationId.Oidc}
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OperationId.Oidc;
  return proto.oslonokkelen.proto.shared.OperationId.Oidc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OperationId.Oidc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OperationId.Oidc}
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRealm(value);
      break;
    case 2:
      var value = /** @type {!proto.oslonokkelen.proto.shared.OperationId.Oidc.Method} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OperationId.Oidc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OperationId.Oidc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRealm();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.Method = {
  APPROVE: 0,
  REJECT: 1
};

/**
 * optional string realm = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.prototype.getRealm = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.OperationId.Oidc} returns this
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.prototype.setRealm = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Method method = 2;
 * @return {!proto.oslonokkelen.proto.shared.OperationId.Oidc.Method}
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.prototype.getMethod = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.OperationId.Oidc.Method} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.OperationId.Oidc.Method} value
 * @return {!proto.oslonokkelen.proto.shared.OperationId.Oidc} returns this
 */
proto.oslonokkelen.proto.shared.OperationId.Oidc.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional SubjectOperation subject_operation = 1;
 * @return {?proto.oslonokkelen.proto.shared.OperationId.SubjectOperation}
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.getSubjectOperation = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OperationId.SubjectOperation} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.OperationId.SubjectOperation, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OperationId.SubjectOperation|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.OperationId} returns this
*/
proto.oslonokkelen.proto.shared.OperationId.prototype.setSubjectOperation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.oslonokkelen.proto.shared.OperationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.OperationId} returns this
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.clearSubjectOperation = function() {
  return this.setSubjectOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.hasSubjectOperation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AdmissionCard admission_card = 2;
 * @return {?proto.oslonokkelen.proto.shared.OperationId.AdmissionCard}
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.getAdmissionCard = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OperationId.AdmissionCard} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.OperationId.AdmissionCard, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OperationId.AdmissionCard|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.OperationId} returns this
*/
proto.oslonokkelen.proto.shared.OperationId.prototype.setAdmissionCard = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.oslonokkelen.proto.shared.OperationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.OperationId} returns this
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.clearAdmissionCard = function() {
  return this.setAdmissionCard(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.hasAdmissionCard = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Oidc oidc = 3;
 * @return {?proto.oslonokkelen.proto.shared.OperationId.Oidc}
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.getOidc = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OperationId.Oidc} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.OperationId.Oidc, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OperationId.Oidc|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.OperationId} returns this
*/
proto.oslonokkelen.proto.shared.OperationId.prototype.setOidc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.oslonokkelen.proto.shared.OperationId.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.OperationId} returns this
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.clearOidc = function() {
  return this.setOidc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.OperationId.prototype.hasOidc = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Text.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Text.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Text} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Text.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Text}
 */
proto.oslonokkelen.proto.shared.Text.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Text;
  return proto.oslonokkelen.proto.shared.Text.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Text} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Text}
 */
proto.oslonokkelen.proto.shared.Text.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 2:
      var value = /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Text.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Text.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Text} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Text.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.oslonokkelen.proto.shared.Text.Type = {
  PLAIN: 0,
  MARKDOWN: 1,
  HTML: 2
};

/**
 * optional string content = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Text.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Text} returns this
 */
proto.oslonokkelen.proto.shared.Text.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Type type = 2;
 * @return {!proto.oslonokkelen.proto.shared.Text.Type}
 */
proto.oslonokkelen.proto.shared.Text.prototype.getType = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Text.Type} value
 * @return {!proto.oslonokkelen.proto.shared.Text} returns this
 */
proto.oslonokkelen.proto.shared.Text.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    uri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    altText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Image}
 */
proto.oslonokkelen.proto.shared.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Image;
  return proto.oslonokkelen.proto.shared.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Image}
 */
proto.oslonokkelen.proto.shared.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAltText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAltText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uri = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Image.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Image} returns this
 */
proto.oslonokkelen.proto.shared.Image.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alt_text = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Image.prototype.getAltText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Image} returns this
 */
proto.oslonokkelen.proto.shared.Image.prototype.setAltText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.EmptyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.EmptyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.EmptyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.EmptyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.EmptyResponse}
 */
proto.oslonokkelen.proto.shared.EmptyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.EmptyResponse;
  return proto.oslonokkelen.proto.shared.EmptyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.EmptyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.EmptyResponse}
 */
proto.oslonokkelen.proto.shared.EmptyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.EmptyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.EmptyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.EmptyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.EmptyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.PhoneNumber.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.PhoneNumber.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.PhoneNumber} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.PhoneNumber.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    number: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.PhoneNumber}
 */
proto.oslonokkelen.proto.shared.PhoneNumber.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.PhoneNumber;
  return proto.oslonokkelen.proto.shared.PhoneNumber.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.PhoneNumber} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.PhoneNumber}
 */
proto.oslonokkelen.proto.shared.PhoneNumber.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.PhoneNumber.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.PhoneNumber.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.PhoneNumber} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.PhoneNumber.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string country_code = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.PhoneNumber.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.PhoneNumber} returns this
 */
proto.oslonokkelen.proto.shared.PhoneNumber.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string number = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.PhoneNumber.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.PhoneNumber} returns this
 */
proto.oslonokkelen.proto.shared.PhoneNumber.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.ErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.ErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.ErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.ErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    humanReadableMessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    traceid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    methodName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.ErrorResponse}
 */
proto.oslonokkelen.proto.shared.ErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.ErrorResponse;
  return proto.oslonokkelen.proto.shared.ErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.ErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.ErrorResponse}
 */
proto.oslonokkelen.proto.shared.ErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHumanReadableMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraceid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMethodName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.ErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.ErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.ErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.ErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHumanReadableMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTraceid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMethodName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string human_readable_message = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.ErrorResponse.prototype.getHumanReadableMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.ErrorResponse} returns this
 */
proto.oslonokkelen.proto.shared.ErrorResponse.prototype.setHumanReadableMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string traceId = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.ErrorResponse.prototype.getTraceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.ErrorResponse} returns this
 */
proto.oslonokkelen.proto.shared.ErrorResponse.prototype.setTraceid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string method_name = 3;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.ErrorResponse.prototype.getMethodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.ErrorResponse} returns this
 */
proto.oslonokkelen.proto.shared.ErrorResponse.prototype.setMethodName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.FullActionId.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.FullActionId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.FullActionId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.FullActionId.toObject = function(includeInstance, msg) {
  var f, obj = {
    adapterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    thingId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actionId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.FullActionId}
 */
proto.oslonokkelen.proto.shared.FullActionId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.FullActionId;
  return proto.oslonokkelen.proto.shared.FullActionId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.FullActionId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.FullActionId}
 */
proto.oslonokkelen.proto.shared.FullActionId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThingId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.FullActionId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.FullActionId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.FullActionId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.FullActionId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdapterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThingId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string adapter_id = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.FullActionId.prototype.getAdapterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.FullActionId} returns this
 */
proto.oslonokkelen.proto.shared.FullActionId.prototype.setAdapterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string thing_id = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.FullActionId.prototype.getThingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.FullActionId} returns this
 */
proto.oslonokkelen.proto.shared.FullActionId.prototype.setThingId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string action_id = 3;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.FullActionId.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.FullActionId} returns this
 */
proto.oslonokkelen.proto.shared.FullActionId.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.HealthSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.HealthSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.HealthSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.HealthSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    color: jspb.Message.getFieldWithDefault(msg, 1, 0),
    summary: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.HealthSummary}
 */
proto.oslonokkelen.proto.shared.HealthSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.HealthSummary;
  return proto.oslonokkelen.proto.shared.HealthSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.HealthSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.HealthSummary}
 */
proto.oslonokkelen.proto.shared.HealthSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.oslonokkelen.proto.shared.HealthColor} */ (reader.readEnum());
      msg.setColor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.HealthSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.HealthSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.HealthSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.HealthSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColor();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional HealthColor color = 1;
 * @return {!proto.oslonokkelen.proto.shared.HealthColor}
 */
proto.oslonokkelen.proto.shared.HealthSummary.prototype.getColor = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.HealthColor} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.HealthColor} value
 * @return {!proto.oslonokkelen.proto.shared.HealthSummary} returns this
 */
proto.oslonokkelen.proto.shared.HealthSummary.prototype.setColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string summary = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.HealthSummary.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.HealthSummary} returns this
 */
proto.oslonokkelen.proto.shared.HealthSummary.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Coordinates.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Coordinates.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Coordinates} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Coordinates.toObject = function(includeInstance, msg) {
  var f, obj = {
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lon: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Coordinates}
 */
proto.oslonokkelen.proto.shared.Coordinates.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Coordinates;
  return proto.oslonokkelen.proto.shared.Coordinates.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Coordinates} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Coordinates}
 */
proto.oslonokkelen.proto.shared.Coordinates.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Coordinates.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Coordinates.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Coordinates} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Coordinates.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double lat = 1;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Coordinates.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Coordinates} returns this
 */
proto.oslonokkelen.proto.shared.Coordinates.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lon = 2;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Coordinates.prototype.getLon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Coordinates} returns this
 */
proto.oslonokkelen.proto.shared.Coordinates.prototype.setLon = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Timestamp.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Timestamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Timestamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Timestamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    epochMillis: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.oslonokkelen.proto.shared.Timestamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Timestamp;
  return proto.oslonokkelen.proto.shared.Timestamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Timestamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.oslonokkelen.proto.shared.Timestamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEpochMillis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Timestamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Timestamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Timestamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Timestamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEpochMillis();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 epoch_millis = 1;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Timestamp.prototype.getEpochMillis = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Timestamp} returns this
 */
proto.oslonokkelen.proto.shared.Timestamp.prototype.setEpochMillis = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.TimestampInterval.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.TimestampInterval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.TimestampInterval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.TimestampInterval.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && proto.oslonokkelen.proto.shared.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && proto.oslonokkelen.proto.shared.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.TimestampInterval}
 */
proto.oslonokkelen.proto.shared.TimestampInterval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.TimestampInterval;
  return proto.oslonokkelen.proto.shared.TimestampInterval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.TimestampInterval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.TimestampInterval}
 */
proto.oslonokkelen.proto.shared.TimestampInterval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.Timestamp;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.Timestamp;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.TimestampInterval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.TimestampInterval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.TimestampInterval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.TimestampInterval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional Timestamp from = 1;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.oslonokkelen.proto.shared.TimestampInterval.prototype.getFrom = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Timestamp, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.TimestampInterval} returns this
*/
proto.oslonokkelen.proto.shared.TimestampInterval.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.TimestampInterval} returns this
 */
proto.oslonokkelen.proto.shared.TimestampInterval.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.TimestampInterval.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Timestamp to = 2;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.oslonokkelen.proto.shared.TimestampInterval.prototype.getTo = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Timestamp, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.TimestampInterval} returns this
*/
proto.oslonokkelen.proto.shared.TimestampInterval.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.TimestampInterval} returns this
 */
proto.oslonokkelen.proto.shared.TimestampInterval.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.TimestampInterval.prototype.hasTo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.LocalDate.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.LocalDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.LocalDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateString: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.LocalDate}
 */
proto.oslonokkelen.proto.shared.LocalDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.LocalDate;
  return proto.oslonokkelen.proto.shared.LocalDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.LocalDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.LocalDate}
 */
proto.oslonokkelen.proto.shared.LocalDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.LocalDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.LocalDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.LocalDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateString();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string date_string = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.LocalDate.prototype.getDateString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.LocalDate} returns this
 */
proto.oslonokkelen.proto.shared.LocalDate.prototype.setDateString = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.LocalTime.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.LocalTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.LocalTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeString: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.LocalTime}
 */
proto.oslonokkelen.proto.shared.LocalTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.LocalTime;
  return proto.oslonokkelen.proto.shared.LocalTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.LocalTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.LocalTime}
 */
proto.oslonokkelen.proto.shared.LocalTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.LocalTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.LocalTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.LocalTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeString();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string time_string = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.LocalTime.prototype.getTimeString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.LocalTime} returns this
 */
proto.oslonokkelen.proto.shared.LocalTime.prototype.setTimeString = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.LocalDateTime.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.LocalDateTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.LocalDateTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalDateTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && proto.oslonokkelen.proto.shared.LocalDate.toObject(includeInstance, f),
    time: (f = msg.getTime()) && proto.oslonokkelen.proto.shared.LocalTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTime}
 */
proto.oslonokkelen.proto.shared.LocalDateTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.LocalDateTime;
  return proto.oslonokkelen.proto.shared.LocalDateTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.LocalDateTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTime}
 */
proto.oslonokkelen.proto.shared.LocalDateTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.LocalDate;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalDate.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.LocalTime;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalTime.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.LocalDateTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.LocalDateTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.LocalDateTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalDateTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.LocalTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional LocalDate date = 1;
 * @return {?proto.oslonokkelen.proto.shared.LocalDate}
 */
proto.oslonokkelen.proto.shared.LocalDateTime.prototype.getDate = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDate} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.LocalDate, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDate|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTime} returns this
*/
proto.oslonokkelen.proto.shared.LocalDateTime.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTime} returns this
 */
proto.oslonokkelen.proto.shared.LocalDateTime.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.LocalDateTime.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LocalTime time = 2;
 * @return {?proto.oslonokkelen.proto.shared.LocalTime}
 */
proto.oslonokkelen.proto.shared.LocalDateTime.prototype.getTime = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalTime} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.LocalTime, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalTime|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTime} returns this
*/
proto.oslonokkelen.proto.shared.LocalDateTime.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTime} returns this
 */
proto.oslonokkelen.proto.shared.LocalDateTime.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.LocalDateTime.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.LocalDateInterval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.LocalDateInterval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && proto.oslonokkelen.proto.shared.LocalDate.toObject(includeInstance, f),
    until: (f = msg.getUntil()) && proto.oslonokkelen.proto.shared.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateInterval}
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.LocalDateInterval;
  return proto.oslonokkelen.proto.shared.LocalDateInterval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.LocalDateInterval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateInterval}
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.LocalDate;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalDate.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.LocalDate;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalDate.deserializeBinaryFromReader);
      msg.setUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.LocalDateInterval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.LocalDateInterval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getUntil();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional LocalDate from = 1;
 * @return {?proto.oslonokkelen.proto.shared.LocalDate}
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.prototype.getFrom = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDate} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.LocalDate, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDate|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.LocalDateInterval} returns this
*/
proto.oslonokkelen.proto.shared.LocalDateInterval.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateInterval} returns this
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LocalDate until = 2;
 * @return {?proto.oslonokkelen.proto.shared.LocalDate}
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.prototype.getUntil = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDate} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.LocalDate, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDate|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.LocalDateInterval} returns this
*/
proto.oslonokkelen.proto.shared.LocalDateInterval.prototype.setUntil = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateInterval} returns this
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.prototype.clearUntil = function() {
  return this.setUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.LocalDateInterval.prototype.hasUntil = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.LocalTimeInterval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.LocalTimeInterval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && proto.oslonokkelen.proto.shared.LocalTime.toObject(includeInstance, f),
    until: (f = msg.getUntil()) && proto.oslonokkelen.proto.shared.LocalTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.LocalTimeInterval}
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.LocalTimeInterval;
  return proto.oslonokkelen.proto.shared.LocalTimeInterval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.LocalTimeInterval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.LocalTimeInterval}
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.LocalTime;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalTime.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.LocalTime;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalTime.deserializeBinaryFromReader);
      msg.setUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.LocalTimeInterval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.LocalTimeInterval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.LocalTime.serializeBinaryToWriter
    );
  }
  f = message.getUntil();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.LocalTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional LocalTime from = 1;
 * @return {?proto.oslonokkelen.proto.shared.LocalTime}
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.prototype.getFrom = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalTime} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.LocalTime, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalTime|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.LocalTimeInterval} returns this
*/
proto.oslonokkelen.proto.shared.LocalTimeInterval.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.LocalTimeInterval} returns this
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LocalTime until = 2;
 * @return {?proto.oslonokkelen.proto.shared.LocalTime}
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.prototype.getUntil = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalTime} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.LocalTime, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalTime|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.LocalTimeInterval} returns this
*/
proto.oslonokkelen.proto.shared.LocalTimeInterval.prototype.setUntil = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.LocalTimeInterval} returns this
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.prototype.clearUntil = function() {
  return this.setUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.LocalTimeInterval.prototype.hasUntil = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.LocalDateTimeInterval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.LocalDateTimeInterval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: (f = msg.getFrom()) && proto.oslonokkelen.proto.shared.LocalDateTime.toObject(includeInstance, f),
    until: (f = msg.getUntil()) && proto.oslonokkelen.proto.shared.LocalDateTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.LocalDateTimeInterval;
  return proto.oslonokkelen.proto.shared.LocalDateTimeInterval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.LocalDateTimeInterval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.LocalDateTime;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalDateTime.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.LocalDateTime;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalDateTime.deserializeBinaryFromReader);
      msg.setUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.LocalDateTimeInterval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.LocalDateTimeInterval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.LocalDateTime.serializeBinaryToWriter
    );
  }
  f = message.getUntil();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.LocalDateTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional LocalDateTime from = 1;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTime}
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.prototype.getFrom = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTime} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.LocalDateTime, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTime|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTimeInterval} returns this
*/
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTimeInterval} returns this
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LocalDateTime until = 2;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTime}
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.prototype.getUntil = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTime} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.LocalDateTime, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTime|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTimeInterval} returns this
*/
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.prototype.setUntil = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.LocalDateTimeInterval} returns this
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.prototype.clearUntil = function() {
  return this.setUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.LocalDateTimeInterval.prototype.hasUntil = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oslonokkelen.proto.shared.OpeningHours.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OpeningHours.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OpeningHours.toObject = function(includeInstance, msg) {
  var f, obj = {
    simpleDayRulesList: jspb.Message.toObjectList(msg.getSimpleDayRulesList(),
    proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.toObject, includeInstance),
    dateOverrideRulesList: jspb.Message.toObjectList(msg.getDateOverrideRulesList(),
    proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.toObject, includeInstance),
    closedDateRulesList: jspb.Message.toObjectList(msg.getClosedDateRulesList(),
    proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.toObject, includeInstance),
    alwaysOpen: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    spec: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours}
 */
proto.oslonokkelen.proto.shared.OpeningHours.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OpeningHours;
  return proto.oslonokkelen.proto.shared.OpeningHours.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours}
 */
proto.oslonokkelen.proto.shared.OpeningHours.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.deserializeBinaryFromReader);
      msg.addSimpleDayRules(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.deserializeBinaryFromReader);
      msg.addDateOverrideRules(value);
      break;
    case 3:
      var value = new proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.deserializeBinaryFromReader);
      msg.addClosedDateRules(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlwaysOpen(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OpeningHours.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OpeningHours.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSimpleDayRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.serializeBinaryToWriter
    );
  }
  f = message.getDateOverrideRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.serializeBinaryToWriter
    );
  }
  f = message.getClosedDateRulesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.serializeBinaryToWriter
    );
  }
  f = message.getAlwaysOpen();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSpec();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    day: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeIntervalsList: jspb.Message.toObjectList(msg.getTimeIntervalsList(),
    proto.oslonokkelen.proto.shared.LocalTimeInterval.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule}
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule;
  return proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule}
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDay(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.LocalTimeInterval;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalTimeInterval.deserializeBinaryFromReader);
      msg.addTimeIntervals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDay();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeIntervalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.LocalTimeInterval.serializeBinaryToWriter
    );
  }
};


/**
 * optional string day = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.prototype.getDay = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule} returns this
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.prototype.setDay = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated LocalTimeInterval time_intervals = 2;
 * @return {!Array<!proto.oslonokkelen.proto.shared.LocalTimeInterval>}
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.prototype.getTimeIntervalsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.LocalTimeInterval>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.LocalTimeInterval, 2));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.LocalTimeInterval>} value
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule} returns this
*/
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.prototype.setTimeIntervalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.LocalTimeInterval=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.LocalTimeInterval}
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.prototype.addTimeIntervals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.oslonokkelen.proto.shared.LocalTimeInterval, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule} returns this
 */
proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule.prototype.clearTimeIntervalsList = function() {
  return this.setTimeIntervalsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && proto.oslonokkelen.proto.shared.LocalDate.toObject(includeInstance, f),
    timeIntervalsList: jspb.Message.toObjectList(msg.getTimeIntervalsList(),
    proto.oslonokkelen.proto.shared.LocalTimeInterval.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule}
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule;
  return proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule}
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.LocalDate;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalDate.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.LocalTimeInterval;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalTimeInterval.deserializeBinaryFromReader);
      msg.addTimeIntervals(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getTimeIntervalsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.LocalTimeInterval.serializeBinaryToWriter
    );
  }
};


/**
 * optional LocalDate date = 1;
 * @return {?proto.oslonokkelen.proto.shared.LocalDate}
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.prototype.getDate = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDate} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.LocalDate, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDate|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule} returns this
*/
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule} returns this
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated LocalTimeInterval time_intervals = 2;
 * @return {!Array<!proto.oslonokkelen.proto.shared.LocalTimeInterval>}
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.prototype.getTimeIntervalsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.LocalTimeInterval>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.LocalTimeInterval, 2));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.LocalTimeInterval>} value
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule} returns this
*/
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.prototype.setTimeIntervalsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.LocalTimeInterval=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.LocalTimeInterval}
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.prototype.addTimeIntervals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.oslonokkelen.proto.shared.LocalTimeInterval, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule} returns this
 */
proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule.prototype.clearTimeIntervalsList = function() {
  return this.setTimeIntervalsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && proto.oslonokkelen.proto.shared.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule}
 */
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule;
  return proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule}
 */
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.LocalDate;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.LocalDate.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional LocalDate date = 1;
 * @return {?proto.oslonokkelen.proto.shared.LocalDate}
 */
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.prototype.getDate = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDate} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.LocalDate, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDate|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule} returns this
*/
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule} returns this
 */
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SimpleDayRule simple_day_rules = 1;
 * @return {!Array<!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule>}
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.getSimpleDayRulesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule, 1));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule>} value
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours} returns this
*/
proto.oslonokkelen.proto.shared.OpeningHours.prototype.setSimpleDayRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule}
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.addSimpleDayRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.oslonokkelen.proto.shared.OpeningHours.SimpleDayRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours} returns this
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.clearSimpleDayRulesList = function() {
  return this.setSimpleDayRulesList([]);
};


/**
 * repeated DateOverrideRule date_override_rules = 2;
 * @return {!Array<!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule>}
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.getDateOverrideRulesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule, 2));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule>} value
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours} returns this
*/
proto.oslonokkelen.proto.shared.OpeningHours.prototype.setDateOverrideRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule}
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.addDateOverrideRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.oslonokkelen.proto.shared.OpeningHours.DateOverrideRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours} returns this
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.clearDateOverrideRulesList = function() {
  return this.setDateOverrideRulesList([]);
};


/**
 * repeated ClosedDateRule closed_date_rules = 3;
 * @return {!Array<!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule>}
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.getClosedDateRulesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule, 3));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule>} value
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours} returns this
*/
proto.oslonokkelen.proto.shared.OpeningHours.prototype.setClosedDateRulesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule}
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.addClosedDateRules = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.oslonokkelen.proto.shared.OpeningHours.ClosedDateRule, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours} returns this
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.clearClosedDateRulesList = function() {
  return this.setClosedDateRulesList([]);
};


/**
 * optional bool always_open = 4;
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.getAlwaysOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours} returns this
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.setAlwaysOpen = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string spec = 5;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.getSpec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.OpeningHours} returns this
 */
proto.oslonokkelen.proto.shared.OpeningHours.prototype.setSpec = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.PersonName.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.PersonName.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.PersonName} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.PersonName.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.PersonName}
 */
proto.oslonokkelen.proto.shared.PersonName.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.PersonName;
  return proto.oslonokkelen.proto.shared.PersonName.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.PersonName} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.PersonName}
 */
proto.oslonokkelen.proto.shared.PersonName.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.PersonName.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.PersonName.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.PersonName} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.PersonName.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.PersonName.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.PersonName} returns this
 */
proto.oslonokkelen.proto.shared.PersonName.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.PersonName.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.PersonName} returns this
 */
proto.oslonokkelen.proto.shared.PersonName.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.ActionDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.ActionDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.ActionDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.ActionDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullActionId: (f = msg.getFullActionId()) && proto.oslonokkelen.proto.shared.FullActionId.toObject(includeInstance, f),
    sendFodselsnummer: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.ActionDefinition}
 */
proto.oslonokkelen.proto.shared.ActionDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.ActionDefinition;
  return proto.oslonokkelen.proto.shared.ActionDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.ActionDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.ActionDefinition}
 */
proto.oslonokkelen.proto.shared.ActionDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionUri(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.FullActionId;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.FullActionId.deserializeBinaryFromReader);
      msg.setFullActionId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendFodselsnummer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.ActionDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.ActionDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.ActionDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.ActionDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullActionId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.FullActionId.serializeBinaryToWriter
    );
  }
  f = message.getSendFodselsnummer();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string action_uri = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.ActionDefinition.prototype.getActionUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.ActionDefinition} returns this
 */
proto.oslonokkelen.proto.shared.ActionDefinition.prototype.setActionUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FullActionId full_action_id = 2;
 * @return {?proto.oslonokkelen.proto.shared.FullActionId}
 */
proto.oslonokkelen.proto.shared.ActionDefinition.prototype.getFullActionId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullActionId} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.FullActionId, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullActionId|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.ActionDefinition} returns this
*/
proto.oslonokkelen.proto.shared.ActionDefinition.prototype.setFullActionId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.ActionDefinition} returns this
 */
proto.oslonokkelen.proto.shared.ActionDefinition.prototype.clearFullActionId = function() {
  return this.setFullActionId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.ActionDefinition.prototype.hasFullActionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool send_fodselsnummer = 3;
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.ActionDefinition.prototype.getSendFodselsnummer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.oslonokkelen.proto.shared.ActionDefinition} returns this
 */
proto.oslonokkelen.proto.shared.ActionDefinition.prototype.setSendFodselsnummer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Problem.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Problem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Problem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Problem.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    howBad: jspb.Message.getFieldWithDefault(msg, 2, 0),
    text: (f = msg.getText()) && proto.oslonokkelen.proto.shared.Text.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Problem}
 */
proto.oslonokkelen.proto.shared.Problem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Problem;
  return proto.oslonokkelen.proto.shared.Problem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Problem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Problem}
 */
proto.oslonokkelen.proto.shared.Problem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {!proto.oslonokkelen.proto.shared.Problem.HowBad} */ (reader.readEnum());
      msg.setHowBad(value);
      break;
    case 3:
      var value = new proto.oslonokkelen.proto.shared.Text;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Text.deserializeBinaryFromReader);
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Problem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Problem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Problem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Problem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHowBad();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getText();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.oslonokkelen.proto.shared.Text.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.oslonokkelen.proto.shared.Problem.HowBad = {
  NOTICE: 0,
  WARNING: 1,
  ERROR: 2
};

/**
 * optional string title = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Problem.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Problem} returns this
 */
proto.oslonokkelen.proto.shared.Problem.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional HowBad how_bad = 2;
 * @return {!proto.oslonokkelen.proto.shared.Problem.HowBad}
 */
proto.oslonokkelen.proto.shared.Problem.prototype.getHowBad = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.Problem.HowBad} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Problem.HowBad} value
 * @return {!proto.oslonokkelen.proto.shared.Problem} returns this
 */
proto.oslonokkelen.proto.shared.Problem.prototype.setHowBad = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Text text = 3;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.oslonokkelen.proto.shared.Problem.prototype.getText = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Text, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Problem} returns this
*/
proto.oslonokkelen.proto.shared.Problem.prototype.setText = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Problem} returns this
 */
proto.oslonokkelen.proto.shared.Problem.prototype.clearText = function() {
  return this.setText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Problem.prototype.hasText = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oslonokkelen.proto.shared.Operation.repeatedFields_ = [7,13,17,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Operation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Operation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Operation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    operationType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    about: (f = msg.getAbout()) && proto.oslonokkelen.proto.shared.Text.toObject(includeInstance, f),
    closed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    termIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    mustBeWithinMetersToExecute: jspb.Message.getFieldWithDefault(msg, 8, 0),
    actionChainId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    ageLimit: jspb.Message.getFieldWithDefault(msg, 10, 0),
    visibleForEveryone: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    proximityDeviceId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    problemsList: jspb.Message.toObjectList(msg.getProblemsList(),
    proto.oslonokkelen.proto.shared.Problem.toObject, includeInstance),
    wiki: (f = msg.getWiki()) && proto.oslonokkelen.proto.shared.Keypedia.toObject(includeInstance, f),
    entity: (f = msg.getEntity()) && proto.oslonokkelen.proto.shared.OperationEntity.toObject(includeInstance, f),
    keychainFactoriesGrantingAccessList: jspb.Message.toObjectList(msg.getKeychainFactoriesGrantingAccessList(),
    proto.oslonokkelen.proto.shared.KeychainFactoryHeader.toObject, includeInstance),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.oslonokkelen.proto.shared.Image.toObject, includeInstance),
    citykeyId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    biometricsRequired: jspb.Message.getBooleanFieldWithDefault(msg, 20, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Operation}
 */
proto.oslonokkelen.proto.shared.Operation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Operation;
  return proto.oslonokkelen.proto.shared.Operation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Operation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Operation}
 */
proto.oslonokkelen.proto.shared.Operation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.oslonokkelen.proto.shared.OperationType} */ (reader.readEnum());
      msg.setOperationType(value);
      break;
    case 4:
      var value = new proto.oslonokkelen.proto.shared.Text;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Text.deserializeBinaryFromReader);
      msg.setAbout(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClosed(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTermIds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMustBeWithinMetersToExecute(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionChainId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAgeLimit(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVisibleForEveryone(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setProximityDeviceId(value);
      break;
    case 13:
      var value = new proto.oslonokkelen.proto.shared.Problem;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Problem.deserializeBinaryFromReader);
      msg.addProblems(value);
      break;
    case 14:
      var value = new proto.oslonokkelen.proto.shared.Keypedia;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Keypedia.deserializeBinaryFromReader);
      msg.setWiki(value);
      break;
    case 15:
      var value = new proto.oslonokkelen.proto.shared.OperationEntity;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.OperationEntity.deserializeBinaryFromReader);
      msg.setEntity(value);
      break;
    case 17:
      var value = new proto.oslonokkelen.proto.shared.KeychainFactoryHeader;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.KeychainFactoryHeader.deserializeBinaryFromReader);
      msg.addKeychainFactoriesGrantingAccess(value);
      break;
    case 18:
      var value = new proto.oslonokkelen.proto.shared.Image;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitykeyId(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBiometricsRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Operation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Operation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Operation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOperationType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAbout();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.oslonokkelen.proto.shared.Text.serializeBinaryToWriter
    );
  }
  f = message.getClosed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getTermIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getMustBeWithinMetersToExecute();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getActionChainId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAgeLimit();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getVisibleForEveryone();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getProximityDeviceId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getProblemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.oslonokkelen.proto.shared.Problem.serializeBinaryToWriter
    );
  }
  f = message.getWiki();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.oslonokkelen.proto.shared.Keypedia.serializeBinaryToWriter
    );
  }
  f = message.getEntity();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.oslonokkelen.proto.shared.OperationEntity.serializeBinaryToWriter
    );
  }
  f = message.getKeychainFactoriesGrantingAccessList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.oslonokkelen.proto.shared.KeychainFactoryHeader.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.oslonokkelen.proto.shared.Image.serializeBinaryToWriter
    );
  }
  f = message.getCitykeyId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getBiometricsRequired();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OperationType operation_type = 3;
 * @return {!proto.oslonokkelen.proto.shared.OperationType}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getOperationType = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.OperationType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.OperationType} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setOperationType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional Text about = 4;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getAbout = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Text, 4));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
*/
proto.oslonokkelen.proto.shared.Operation.prototype.setAbout = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.clearAbout = function() {
  return this.setAbout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.hasAbout = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool closed = 6;
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated string term_ids = 7;
 * @return {!Array<string>}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getTermIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setTermIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.addTermIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.clearTermIdsList = function() {
  return this.setTermIdsList([]);
};


/**
 * optional int32 must_be_within_meters_to_execute = 8;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getMustBeWithinMetersToExecute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setMustBeWithinMetersToExecute = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string action_chain_id = 16;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getActionChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setActionChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int32 age_limit = 10;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getAgeLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setAgeLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool visible_for_everyone = 11;
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getVisibleForEveryone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setVisibleForEveryone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string proximity_device_id = 12;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getProximityDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setProximityDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated Problem problems = 13;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Problem>}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getProblemsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Problem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.Problem, 13));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Problem>} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
*/
proto.oslonokkelen.proto.shared.Operation.prototype.setProblemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Problem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Problem}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.addProblems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.oslonokkelen.proto.shared.Problem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.clearProblemsList = function() {
  return this.setProblemsList([]);
};


/**
 * optional Keypedia wiki = 14;
 * @return {?proto.oslonokkelen.proto.shared.Keypedia}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getWiki = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Keypedia} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Keypedia, 14));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Keypedia|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
*/
proto.oslonokkelen.proto.shared.Operation.prototype.setWiki = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.clearWiki = function() {
  return this.setWiki(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.hasWiki = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional OperationEntity entity = 15;
 * @return {?proto.oslonokkelen.proto.shared.OperationEntity}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getEntity = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OperationEntity} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.OperationEntity, 15));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OperationEntity|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
*/
proto.oslonokkelen.proto.shared.Operation.prototype.setEntity = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.clearEntity = function() {
  return this.setEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.hasEntity = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated KeychainFactoryHeader keychain_factories_granting_access = 17;
 * @return {!Array<!proto.oslonokkelen.proto.shared.KeychainFactoryHeader>}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getKeychainFactoriesGrantingAccessList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.KeychainFactoryHeader>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.KeychainFactoryHeader, 17));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.KeychainFactoryHeader>} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
*/
proto.oslonokkelen.proto.shared.Operation.prototype.setKeychainFactoriesGrantingAccessList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.addKeychainFactoriesGrantingAccess = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.oslonokkelen.proto.shared.KeychainFactoryHeader, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.clearKeychainFactoriesGrantingAccessList = function() {
  return this.setKeychainFactoriesGrantingAccessList([]);
};


/**
 * repeated Image images = 18;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Image>}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.Image, 18));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Image>} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
*/
proto.oslonokkelen.proto.shared.Operation.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Image}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.oslonokkelen.proto.shared.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string citykey_id = 19;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getCitykeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setCitykeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool biometrics_required = 20;
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Operation.prototype.getBiometricsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.oslonokkelen.proto.shared.Operation} returns this
 */
proto.oslonokkelen.proto.shared.Operation.prototype.setBiometricsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.KeychainFactoryHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    image: (f = msg.getImage()) && proto.oslonokkelen.proto.shared.Image.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader}
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.KeychainFactoryHeader;
  return proto.oslonokkelen.proto.shared.KeychainFactoryHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader}
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.oslonokkelen.proto.shared.Image;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Image.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 4:
      var value = /** @type {!proto.oslonokkelen.proto.shared.KeychainFactoryType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.KeychainFactoryHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.oslonokkelen.proto.shared.Image.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader} returns this
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader} returns this
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Image image = 3;
 * @return {?proto.oslonokkelen.proto.shared.Image}
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.getImage = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Image} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Image, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Image|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader} returns this
*/
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader} returns this
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.hasImage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional KeychainFactoryType type = 4;
 * @return {!proto.oslonokkelen.proto.shared.KeychainFactoryType}
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.getType = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.KeychainFactoryType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.KeychainFactoryType} value
 * @return {!proto.oslonokkelen.proto.shared.KeychainFactoryHeader} returns this
 */
proto.oslonokkelen.proto.shared.KeychainFactoryHeader.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oslonokkelen.proto.shared.Category.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Category.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Category.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Category} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Category.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: (f = msg.getDescription()) && proto.oslonokkelen.proto.shared.Text.toObject(includeInstance, f),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    proto.oslonokkelen.proto.shared.Image.toObject, includeInstance),
    citykeyId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adminRole: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Category}
 */
proto.oslonokkelen.proto.shared.Category.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Category;
  return proto.oslonokkelen.proto.shared.Category.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Category} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Category}
 */
proto.oslonokkelen.proto.shared.Category.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.oslonokkelen.proto.shared.Text;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Text.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.oslonokkelen.proto.shared.Image;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitykeyId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Category.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Category.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Category} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Category.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.oslonokkelen.proto.shared.Text.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.oslonokkelen.proto.shared.Image.serializeBinaryToWriter
    );
  }
  f = message.getCitykeyId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdminRole();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Category.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Category} returns this
 */
proto.oslonokkelen.proto.shared.Category.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Category.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Category} returns this
 */
proto.oslonokkelen.proto.shared.Category.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Text description = 3;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.oslonokkelen.proto.shared.Category.prototype.getDescription = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Text, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Category} returns this
*/
proto.oslonokkelen.proto.shared.Category.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Category} returns this
 */
proto.oslonokkelen.proto.shared.Category.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Category.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Image images = 4;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Image>}
 */
proto.oslonokkelen.proto.shared.Category.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.Image, 4));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Image>} value
 * @return {!proto.oslonokkelen.proto.shared.Category} returns this
*/
proto.oslonokkelen.proto.shared.Category.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Image}
 */
proto.oslonokkelen.proto.shared.Category.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.oslonokkelen.proto.shared.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.Category} returns this
 */
proto.oslonokkelen.proto.shared.Category.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string citykey_id = 5;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Category.prototype.getCitykeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Category} returns this
 */
proto.oslonokkelen.proto.shared.Category.prototype.setCitykeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string admin_role = 6;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Category.prototype.getAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Category} returns this
 */
proto.oslonokkelen.proto.shared.Category.prototype.setAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oslonokkelen.proto.shared.Thing.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Thing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Thing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Thing.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    thingUri: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ownerAdminRole: jspb.Message.getFieldWithDefault(msg, 5, ""),
    link: jspb.Message.getFieldWithDefault(msg, 6, ""),
    manufacturer: jspb.Message.getFieldWithDefault(msg, 7, ""),
    deviceType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    firmwareVersion: jspb.Message.getFieldWithDefault(msg, 9, ""),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    proto.oslonokkelen.proto.shared.Action.toObject, includeInstance),
    lastSeen: (f = msg.getLastSeen()) && proto.oslonokkelen.proto.shared.Thing.LastSeen.toObject(includeInstance, f),
    network: (f = msg.getNetwork()) && proto.oslonokkelen.proto.shared.Thing.Network.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Thing}
 */
proto.oslonokkelen.proto.shared.Thing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Thing;
  return proto.oslonokkelen.proto.shared.Thing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Thing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Thing}
 */
proto.oslonokkelen.proto.shared.Thing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setThingUri(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerAdminRole(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setManufacturer(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareVersion(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.Action;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Action.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 10:
      var value = new proto.oslonokkelen.proto.shared.Thing.LastSeen;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Thing.LastSeen.deserializeBinaryFromReader);
      msg.setLastSeen(value);
      break;
    case 11:
      var value = new proto.oslonokkelen.proto.shared.Thing.Network;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Thing.Network.deserializeBinaryFromReader);
      msg.setNetwork(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Thing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Thing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Thing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThingUri();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOwnerAdminRole();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getManufacturer();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDeviceType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFirmwareVersion();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.Action.serializeBinaryToWriter
    );
  }
  f = message.getLastSeen();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.oslonokkelen.proto.shared.Thing.LastSeen.serializeBinaryToWriter
    );
  }
  f = message.getNetwork();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.oslonokkelen.proto.shared.Thing.Network.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Thing.LastSeen.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Thing.LastSeen} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestampEpochSeconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    explanation: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Thing.LastSeen}
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Thing.LastSeen;
  return proto.oslonokkelen.proto.shared.Thing.LastSeen.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Thing.LastSeen} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Thing.LastSeen}
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampEpochSeconds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExplanation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Thing.LastSeen.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Thing.LastSeen} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestampEpochSeconds();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getExplanation();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 timestamp_epoch_seconds = 1;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen.prototype.getTimestampEpochSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Thing.LastSeen} returns this
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen.prototype.setTimestampEpochSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string explanation = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen.prototype.getExplanation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing.LastSeen} returns this
 */
proto.oslonokkelen.proto.shared.Thing.LastSeen.prototype.setExplanation = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Thing.Network.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Thing.Network} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Thing.Network.toObject = function(includeInstance, msg) {
  var f, obj = {
    macAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ipAddress: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rssi: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastConnectedAtEpochSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    lastDisconnectedAtEpochSeconds: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Thing.Network}
 */
proto.oslonokkelen.proto.shared.Thing.Network.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Thing.Network;
  return proto.oslonokkelen.proto.shared.Thing.Network.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Thing.Network} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Thing.Network}
 */
proto.oslonokkelen.proto.shared.Thing.Network.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacAddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIpAddress(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRssi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastConnectedAtEpochSeconds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastDisconnectedAtEpochSeconds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Thing.Network.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Thing.Network} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Thing.Network.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMacAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIpAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRssi();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLastConnectedAtEpochSeconds();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getLastDisconnectedAtEpochSeconds();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional string mac_address = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.getMacAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing.Network} returns this
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.setMacAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ip_address = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.getIpAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing.Network} returns this
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.setIpAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 rssi = 3;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.getRssi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Thing.Network} returns this
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.setRssi = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 last_connected_at_epoch_seconds = 4;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.getLastConnectedAtEpochSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Thing.Network} returns this
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.setLastConnectedAtEpochSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 last_disconnected_at_epoch_seconds = 5;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.getLastDisconnectedAtEpochSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Thing.Network} returns this
 */
proto.oslonokkelen.proto.shared.Thing.Network.prototype.setLastDisconnectedAtEpochSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string thing_uri = 3;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getThingUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.setThingUri = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string owner_admin_role = 5;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getOwnerAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.setOwnerAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string link = 6;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string manufacturer = 7;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getManufacturer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.setManufacturer = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string device_type = 8;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getDeviceType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string firmware_version = 9;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getFirmwareVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.setFirmwareVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated Action actions = 2;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Action>}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Action>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.Action, 2));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Action>} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
*/
proto.oslonokkelen.proto.shared.Thing.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Action=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Action}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.oslonokkelen.proto.shared.Action, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * optional LastSeen last_seen = 10;
 * @return {?proto.oslonokkelen.proto.shared.Thing.LastSeen}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getLastSeen = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Thing.LastSeen} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Thing.LastSeen, 10));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Thing.LastSeen|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
*/
proto.oslonokkelen.proto.shared.Thing.prototype.setLastSeen = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.clearLastSeen = function() {
  return this.setLastSeen(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.hasLastSeen = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Network network = 11;
 * @return {?proto.oslonokkelen.proto.shared.Thing.Network}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.getNetwork = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Thing.Network} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Thing.Network, 11));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Thing.Network|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
*/
proto.oslonokkelen.proto.shared.Thing.prototype.setNetwork = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Thing} returns this
 */
proto.oslonokkelen.proto.shared.Thing.prototype.clearNetwork = function() {
  return this.setNetwork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Thing.prototype.hasNetwork = function() {
  return jspb.Message.getField(this, 11) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.oslonokkelen.proto.shared.Action.repeatedFields_ = [9,13,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Action.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Action.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Action} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Action.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    healthMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actionUri: jspb.Message.getFieldWithDefault(msg, 4, ""),
    healthStatus: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    responseHealth: (f = msg.getResponseHealth()) && proto.oslonokkelen.proto.shared.HealthSummary.toObject(includeInstance, f),
    manifestHealth: (f = msg.getManifestHealth()) && proto.oslonokkelen.proto.shared.HealthSummary.toObject(includeInstance, f),
    usedByOperationsList: jspb.Message.toObjectList(msg.getUsedByOperationsList(),
    proto.oslonokkelen.proto.shared.FullOperationId.toObject, includeInstance),
    healthApi: (f = msg.getHealthApi()) && proto.oslonokkelen.proto.shared.Action.ActionHealthApi.toObject(includeInstance, f),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.oslonokkelen.proto.shared.Action.ResponseRecord.toObject, includeInstance),
    responseTimeSummary: (f = msg.getResponseTimeSummary()) && proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.toObject(includeInstance, f),
    partOfActionChainsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    betterUptimeMonitorId: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Action}
 */
proto.oslonokkelen.proto.shared.Action.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Action;
  return proto.oslonokkelen.proto.shared.Action.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Action} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Action}
 */
proto.oslonokkelen.proto.shared.Action.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHealthMessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionUri(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHealthStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.oslonokkelen.proto.shared.HealthSummary;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.HealthSummary.deserializeBinaryFromReader);
      msg.setResponseHealth(value);
      break;
    case 7:
      var value = new proto.oslonokkelen.proto.shared.HealthSummary;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.HealthSummary.deserializeBinaryFromReader);
      msg.setManifestHealth(value);
      break;
    case 9:
      var value = new proto.oslonokkelen.proto.shared.FullOperationId;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.FullOperationId.deserializeBinaryFromReader);
      msg.addUsedByOperations(value);
      break;
    case 12:
      var value = new proto.oslonokkelen.proto.shared.Action.ActionHealthApi;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Action.ActionHealthApi.deserializeBinaryFromReader);
      msg.setHealthApi(value);
      break;
    case 13:
      var value = new proto.oslonokkelen.proto.shared.Action.ResponseRecord;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Action.ResponseRecord.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    case 14:
      var value = new proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.deserializeBinaryFromReader);
      msg.setResponseTimeSummary(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.addPartOfActionChains(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetterUptimeMonitorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Action.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Action.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Action} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Action.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHealthMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHealthStatus();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getResponseHealth();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.oslonokkelen.proto.shared.HealthSummary.serializeBinaryToWriter
    );
  }
  f = message.getManifestHealth();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.oslonokkelen.proto.shared.HealthSummary.serializeBinaryToWriter
    );
  }
  f = message.getUsedByOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.oslonokkelen.proto.shared.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getHealthApi();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.oslonokkelen.proto.shared.Action.ActionHealthApi.serializeBinaryToWriter
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.oslonokkelen.proto.shared.Action.ResponseRecord.serializeBinaryToWriter
    );
  }
  f = message.getResponseTimeSummary();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.serializeBinaryToWriter
    );
  }
  f = message.getPartOfActionChainsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      15,
      f
    );
  }
  f = message.getBetterUptimeMonitorId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Action.ActionHealthApi.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Action.ActionHealthApi} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi.toObject = function(includeInstance, msg) {
  var f, obj = {
    apiKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pollUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Action.ActionHealthApi}
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Action.ActionHealthApi;
  return proto.oslonokkelen.proto.shared.Action.ActionHealthApi.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Action.ActionHealthApi} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Action.ActionHealthApi}
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Action.ActionHealthApi.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Action.ActionHealthApi} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPollUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string api_key = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Action.ActionHealthApi} returns this
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi.prototype.setApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string poll_uri = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi.prototype.getPollUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Action.ActionHealthApi} returns this
 */
proto.oslonokkelen.proto.shared.Action.ActionHealthApi.prototype.setPollUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Action.ResponseRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Action.ResponseRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timestamp: (f = msg.getTimestamp()) && proto.oslonokkelen.proto.shared.Timestamp.toObject(includeInstance, f),
    traceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    code: jspb.Message.getFieldWithDefault(msg, 4, ""),
    responseTimeMs: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseRecord}
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Action.ResponseRecord;
  return proto.oslonokkelen.proto.shared.Action.ResponseRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Action.ResponseRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseRecord}
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.oslonokkelen.proto.shared.Result} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.Timestamp;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTraceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResponseTimeMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Action.ResponseRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Action.ResponseRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTraceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResponseTimeMs();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional Result result = 1;
 * @return {!proto.oslonokkelen.proto.shared.Result}
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.getResult = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.Result} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Result} value
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseRecord} returns this
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional Timestamp timestamp = 2;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.getTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Timestamp, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseRecord} returns this
*/
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseRecord} returns this
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string trace_id = 3;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.getTraceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseRecord} returns this
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.setTraceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string code = 4;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseRecord} returns this
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 response_time_ms = 5;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.getResponseTimeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseRecord} returns this
 */
proto.oslonokkelen.proto.shared.Action.ResponseRecord.prototype.setResponseTimeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.toObject = function(includeInstance, msg) {
  var f, obj = {
    minMs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    maxMs: jspb.Message.getFieldWithDefault(msg, 2, 0),
    avgMs: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries}
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries;
  return proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries}
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinMs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxMs(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvgMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinMs();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMaxMs();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAvgMs();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 min_ms = 1;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.prototype.getMinMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries} returns this
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.prototype.setMinMs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 max_ms = 2;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.prototype.getMaxMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries} returns this
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.prototype.setMaxMs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 avg_ms = 3;
 * @return {number}
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.prototype.getAvgMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries} returns this
 */
proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries.prototype.setAvgMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string health_message = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getHealthMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.setHealthMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string action_uri = 4;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getActionUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.setActionUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool health_status = 3;
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getHealthStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.setHealthStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional HealthSummary response_health = 6;
 * @return {?proto.oslonokkelen.proto.shared.HealthSummary}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getResponseHealth = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.HealthSummary} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.HealthSummary, 6));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.HealthSummary|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
*/
proto.oslonokkelen.proto.shared.Action.prototype.setResponseHealth = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.clearResponseHealth = function() {
  return this.setResponseHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Action.prototype.hasResponseHealth = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional HealthSummary manifest_health = 7;
 * @return {?proto.oslonokkelen.proto.shared.HealthSummary}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getManifestHealth = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.HealthSummary} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.HealthSummary, 7));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.HealthSummary|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
*/
proto.oslonokkelen.proto.shared.Action.prototype.setManifestHealth = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.clearManifestHealth = function() {
  return this.setManifestHealth(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Action.prototype.hasManifestHealth = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated FullOperationId used_by_operations = 9;
 * @return {!Array<!proto.oslonokkelen.proto.shared.FullOperationId>}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getUsedByOperationsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.FullOperationId>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.FullOperationId, 9));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.FullOperationId>} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
*/
proto.oslonokkelen.proto.shared.Action.prototype.setUsedByOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.FullOperationId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.oslonokkelen.proto.shared.Action.prototype.addUsedByOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.oslonokkelen.proto.shared.FullOperationId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.clearUsedByOperationsList = function() {
  return this.setUsedByOperationsList([]);
};


/**
 * optional ActionHealthApi health_api = 12;
 * @return {?proto.oslonokkelen.proto.shared.Action.ActionHealthApi}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getHealthApi = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Action.ActionHealthApi} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Action.ActionHealthApi, 12));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Action.ActionHealthApi|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
*/
proto.oslonokkelen.proto.shared.Action.prototype.setHealthApi = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.clearHealthApi = function() {
  return this.setHealthApi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Action.prototype.hasHealthApi = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * repeated ResponseRecord records = 13;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Action.ResponseRecord>}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Action.ResponseRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.oslonokkelen.proto.shared.Action.ResponseRecord, 13));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Action.ResponseRecord>} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
*/
proto.oslonokkelen.proto.shared.Action.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Action.ResponseRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Action.ResponseRecord}
 */
proto.oslonokkelen.proto.shared.Action.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.oslonokkelen.proto.shared.Action.ResponseRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * optional ResponseTimeSummaries response_time_summary = 14;
 * @return {?proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getResponseTimeSummary = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries, 14));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Action.ResponseTimeSummaries|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
*/
proto.oslonokkelen.proto.shared.Action.prototype.setResponseTimeSummary = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.clearResponseTimeSummary = function() {
  return this.setResponseTimeSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Action.prototype.hasResponseTimeSummary = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * repeated string part_of_action_chains = 15;
 * @return {!Array<string>}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getPartOfActionChainsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.setPartOfActionChainsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.addPartOfActionChains = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.clearPartOfActionChainsList = function() {
  return this.setPartOfActionChainsList([]);
};


/**
 * optional string better_uptime_monitor_id = 16;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Action.prototype.getBetterUptimeMonitorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Action} returns this
 */
proto.oslonokkelen.proto.shared.Action.prototype.setBetterUptimeMonitorId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.DynamicTag.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.DynamicTag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.DynamicTag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.DynamicTag.toObject = function(includeInstance, msg) {
  var f, obj = {
    typeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: (f = msg.getText()) && proto.oslonokkelen.proto.shared.Text.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.DynamicTag}
 */
proto.oslonokkelen.proto.shared.DynamicTag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.DynamicTag;
  return proto.oslonokkelen.proto.shared.DynamicTag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.DynamicTag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.DynamicTag}
 */
proto.oslonokkelen.proto.shared.DynamicTag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.oslonokkelen.proto.shared.DynamicTag.Type} */ (reader.readEnum());
      msg.setTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.oslonokkelen.proto.shared.Text;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Text.deserializeBinaryFromReader);
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.DynamicTag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.DynamicTag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.DynamicTag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.DynamicTag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTypeId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.oslonokkelen.proto.shared.Text.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.oslonokkelen.proto.shared.DynamicTag.Type = {
  USED_TODAY: 0,
  VIRGIN: 1,
  TROUBLE: 2,
  NOT_USED_LAST_7_DAYS: 3,
  NOT_USED_LAST_30_DAYS: 4,
  KEYLESS_KEYCHAIN: 5,
  VISIBLE_FOR_EVERYONE: 6,
  PRODUCTION: 7,
  BETA: 8,
  ANOMALY_MESSAGE: 9
};

/**
 * optional Type type_id = 1;
 * @return {!proto.oslonokkelen.proto.shared.DynamicTag.Type}
 */
proto.oslonokkelen.proto.shared.DynamicTag.prototype.getTypeId = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.DynamicTag.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.DynamicTag.Type} value
 * @return {!proto.oslonokkelen.proto.shared.DynamicTag} returns this
 */
proto.oslonokkelen.proto.shared.DynamicTag.prototype.setTypeId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.DynamicTag.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.DynamicTag} returns this
 */
proto.oslonokkelen.proto.shared.DynamicTag.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Text text = 3;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.oslonokkelen.proto.shared.DynamicTag.prototype.getText = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Text, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.DynamicTag} returns this
*/
proto.oslonokkelen.proto.shared.DynamicTag.prototype.setText = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.DynamicTag} returns this
 */
proto.oslonokkelen.proto.shared.DynamicTag.prototype.clearText = function() {
  return this.setText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.DynamicTag.prototype.hasText = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.Keypedia.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.Keypedia.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.Keypedia} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Keypedia.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: (f = msg.getText()) && proto.oslonokkelen.proto.shared.Text.toObject(includeInstance, f),
    lastUpdated: (f = msg.getLastUpdated()) && proto.oslonokkelen.proto.shared.Timestamp.toObject(includeInstance, f),
    lastUpdatedBy: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.Keypedia}
 */
proto.oslonokkelen.proto.shared.Keypedia.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.Keypedia;
  return proto.oslonokkelen.proto.shared.Keypedia.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.Keypedia} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.Keypedia}
 */
proto.oslonokkelen.proto.shared.Keypedia.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.Text;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Text.deserializeBinaryFromReader);
      msg.setText(value);
      break;
    case 2:
      var value = new proto.oslonokkelen.proto.shared.Timestamp;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdated(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastUpdatedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.Keypedia.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.Keypedia.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.Keypedia} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.Keypedia.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.Text.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdated();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.oslonokkelen.proto.shared.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional Text text = 1;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.oslonokkelen.proto.shared.Keypedia.prototype.getText = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Text, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Keypedia} returns this
*/
proto.oslonokkelen.proto.shared.Keypedia.prototype.setText = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Keypedia} returns this
 */
proto.oslonokkelen.proto.shared.Keypedia.prototype.clearText = function() {
  return this.setText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Keypedia.prototype.hasText = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Timestamp last_updated = 2;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.oslonokkelen.proto.shared.Keypedia.prototype.getLastUpdated = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Timestamp, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.Keypedia} returns this
*/
proto.oslonokkelen.proto.shared.Keypedia.prototype.setLastUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.Keypedia} returns this
 */
proto.oslonokkelen.proto.shared.Keypedia.prototype.clearLastUpdated = function() {
  return this.setLastUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.Keypedia.prototype.hasLastUpdated = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string last_updated_by = 3;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.Keypedia.prototype.getLastUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.Keypedia} returns this
 */
proto.oslonokkelen.proto.shared.Keypedia.prototype.setLastUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.oslonokkelen.proto.shared.AnomalyMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.oslonokkelen.proto.shared.AnomalyMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: (f = msg.getText()) && proto.oslonokkelen.proto.shared.Text.toObject(includeInstance, f),
    author: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timestamp: (f = msg.getTimestamp()) && proto.oslonokkelen.proto.shared.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.oslonokkelen.proto.shared.AnomalyMessage}
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.oslonokkelen.proto.shared.AnomalyMessage;
  return proto.oslonokkelen.proto.shared.AnomalyMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.oslonokkelen.proto.shared.AnomalyMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.oslonokkelen.proto.shared.AnomalyMessage}
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.oslonokkelen.proto.shared.Text;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Text.deserializeBinaryFromReader);
      msg.setText(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthor(value);
      break;
    case 3:
      var value = new proto.oslonokkelen.proto.shared.Timestamp;
      reader.readMessage(value,proto.oslonokkelen.proto.shared.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.oslonokkelen.proto.shared.AnomalyMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.oslonokkelen.proto.shared.AnomalyMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.oslonokkelen.proto.shared.Text.serializeBinaryToWriter
    );
  }
  f = message.getAuthor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.oslonokkelen.proto.shared.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional Text text = 1;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.getText = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Text, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.AnomalyMessage} returns this
*/
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.setText = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.AnomalyMessage} returns this
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.clearText = function() {
  return this.setText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.hasText = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string author = 2;
 * @return {string}
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.getAuthor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.oslonokkelen.proto.shared.AnomalyMessage} returns this
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.setAuthor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Timestamp timestamp = 3;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.getTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, proto.oslonokkelen.proto.shared.Timestamp, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.oslonokkelen.proto.shared.AnomalyMessage} returns this
*/
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.oslonokkelen.proto.shared.AnomalyMessage} returns this
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.oslonokkelen.proto.shared.AnomalyMessage.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.oslonokkelen.proto.shared.HealthColor = {
  RED: 0,
  GREEN: 1,
  YELLOW: 2
};

/**
 * @enum {number}
 */
proto.oslonokkelen.proto.shared.Result = {
  ERROR: 0,
  DENIED: 1,
  SUCCESS: 2
};

/**
 * @enum {number}
 */
proto.oslonokkelen.proto.shared.OperationType = {
  EXECUTE: 0,
  CODE_QR: 1,
  CODE_RAW: 2,
  CODE_BAR_39: 3
};

/**
 * @enum {number}
 */
proto.oslonokkelen.proto.shared.KeychainFactoryType = {
  PUBLIC: 0,
  OIDC: 1,
  PUSH: 2,
  MANUAL_GRANT: 3
};

goog.object.extend(exports, proto.oslonokkelen.proto.shared);
