import { useEffect } from 'react';
import { isSuperAdmin } from '../../components/util/RenderIfSuperAdmin';
import { fetchKeychainFactoryDetails } from '../../redux/actions/keychainFactoryDetails';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { rolesSelectors } from '../../redux/slice/roles';
import { getFullkeychainFactoryUri } from '../keychainFactory/getKeychainFactoryAssets';

export const useAdminRoles = () => {
  const roles = useAppSelector(state => rolesSelectors.selectAll(state));
  const superAdmin = isSuperAdmin();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const factoryUri = getFullkeychainFactoryUri('oidc', 'keystudio');
    if (!(roles.length && roles[0].uri.includes(factoryUri)) && superAdmin) {
      dispatch(fetchKeychainFactoryDetails(factoryUri));
    }
  }, [roles, superAdmin]);
  return roles;
};

export const useAdminRole = (id: string) => {
  const allRoles = useAdminRoles();
  return allRoles.find(role => role.id === id);
};
