import React from 'react';

export const GodMode = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='136'
    height='136'
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12162'
      data-name='Group 12162'
      transform='translate(-560 -232)'
    >
      <circle
        id='Ellipse_692'
        data-name='Ellipse 692'
        cx='68'
        cy='68'
        r='68'
        transform='translate(560 232)'
        fill='#f9b3ab'
      />
      <g id='noun-god-3965047' transform='translate(588 259)'>
        <path
          id='Path_3021'
          data-name='Path 3021'
          d='M267.218,64.5l7.919,13.142a33.242,33.242,0,0,1,6.74,2.19L269.24,58.772a2.414,2.414,0,0,0-4.044,0L252.56,79.748a33.239,33.239,0,0,1,6.74-2.19Z'
          transform='translate(-227.224 -57.677)'
          fill='#292858'
        />
        <path
          id='Path_3022'
          data-name='Path 3022'
          d='M163.812,385.076l-10.025-16.6a31.691,31.691,0,0,1-3.369,3.369l7.329,12.047H90.521L97.85,371.85c-1.348-1.263-2.443-2.443-3.369-3.369l-10.025,16.6a2.358,2.358,0,0,0,0,2.359,2.269,2.269,0,0,0,2.022,1.179h75.4a2.269,2.269,0,0,0,2.022-1.179,2.6,2.6,0,0,0-.084-2.359Z'
          transform='translate(-84.139 -321.725)'
          fill='#292858'
        />
        <path
          id='Path_3023'
          data-name='Path 3023'
          d='M187.885,245.991c15.669,0,25.273-10.867,27.884-14.237a3.92,3.92,0,0,0,0-4.8c-2.611-3.285-12.215-14.152-27.884-14.152S162.612,223.666,160,227.036a3.92,3.92,0,0,0,0,4.8C162.612,235.123,172.216,245.991,187.885,245.991Zm14.321-25.1a34.25,34.25,0,0,1,9.856,8.509,34.3,34.3,0,0,1-9.856,8.424,16.136,16.136,0,0,0,2.359-8.508,18.051,18.051,0,0,0-2.359-8.425ZM187.885,217.1a12.3,12.3,0,1,1-12.3,12.3A12.314,12.314,0,0,1,187.885,217.1Zm-14.321,3.791a16.135,16.135,0,0,0-2.359,8.508,16.354,16.354,0,0,0,2.359,8.508,35.588,35.588,0,0,1-9.856-8.508,34.248,34.248,0,0,1,9.856-8.509Z'
          transform='translate(-147.891 -189.464)'
          fill='#292858'
        />
        <path
          id='Path_3024'
          data-name='Path 3024'
          d='M302.424,284.358a8.34,8.34,0,1,0,0-16.68,8.479,8.479,0,0,0-3.285.674,3.465,3.465,0,0,1,2.443,3.369,3.541,3.541,0,0,1-6.908,1.1,8.272,8.272,0,0,0,7.75,11.542Z'
          transform='translate(-262.43 -236.087)'
          fill='#292858'
        />
      </g>
    </g>
  </svg>
);
