import React from 'react';
import { Problem } from '../../../../proto/shared_pb';
import { Category } from '../../../../redux/types/protoTypes';
import { getSubjectImage } from '../../../../utils/operation/operation';
import {
  getCategoryNameFromId,
  SubjectsGroupedByCategories
} from '../../../../utils/subjects/subjects';
import { SubjectCard } from './SubjectCard';
import './SubjectCardGroup.scss';

import HowBad = Problem.HowBad;

interface SubjectCardGroupProps {
  category: SubjectsGroupedByCategories;
  categories: Category[];
}

export const SubjectCardGroup: React.FC<SubjectCardGroupProps> = ({
  category,
  categories
}) => {
  if (category.subjects.length !== 0) {
    return (
      <div
        key={category.id}
        role={'group'}
        aria-labelledby={`${category.id}Header`}
        className='subject-card-group'
      >
        <h4
          id={`${category.id}Header`}
          className='category capitalized dotted subject-name'
        >
          {getCategoryNameFromId(category.id, categories).name}
        </h4>
        <div className='cards' role={'presentation'}>
          {category.subjects.map((subject, index) => {
            const subjectImage = getSubjectImage(subject.id, category.subjects);

            const hasSeriousProblem = subject.operationsList.some(operation => {
              return operation.problemsList.some(problem => {
                return (
                  problem.howBad === HowBad.WARNING ||
                  problem.howBad === HowBad.ERROR
                );
              });
            });

            const anomaly = !!subject.anomalyMessage.timestampMillis;

            return (
              <SubjectCard
                key={subject.id}
                subject={subject}
                img={subjectImage ? subjectImage : undefined}
                index={category.subjects.length - index}
                hasSeriousProblem={hasSeriousProblem}
                anomaly={anomaly}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
