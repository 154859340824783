import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Subject } from '../../redux/slice/subjects';
import { FactoryType } from '../../redux/types/common';
import { HamburgerMenu } from './HamburgerMenu';
import './breadcrumbs.scss';
import {
  manualGrantKeychainHome,
  keychainHome,
  oidcKeychainHome,
  publicKeychainHome,
  pushKeychainHome,
  subjectHome
} from './navPaths';

export interface Breadcrumb {
  name: string;
  path?: string;
}

export const generateKeychainBreadcrumbs = (
  type: FactoryType
): Breadcrumb[] => {
  const crumbs = [keychainHome];
  switch (type) {
    case 'manual-grant':
      crumbs.push(manualGrantKeychainHome);
      break;
    case 'push':
      crumbs.push(pushKeychainHome);
      break;
    case 'oidc':
      crumbs.push(oidcKeychainHome);
      break;
    case 'public':
      crumbs.push(publicKeychainHome);
      break;
  }
  return crumbs;
};

export const generateSubjectBreadcrumbs = (
  subject: Pick<Subject, 'name' | 'id'>
): Breadcrumb[] => {
  return [
    subjectHome,
    { name: subject.name, path: `${subjectHome.path}/${subject.id}` }
  ];
};

interface Props {
  breadcrumbs: Breadcrumb[];
  children?: React.ReactNode;
}

export const Breadcrumbs: React.FC<Props> = ({ breadcrumbs, children }) => {
  useEffect(() => {
    document.title = breadcrumbs
      .map(({ name }) => name)
      .reverse()
      .join(' – ');
  }, [breadcrumbs]);
  const crumbs = breadcrumbs.map(({ path = '', name }, index) => {
    return (
      <nav key={path + name} aria-label='Brødsmule'>
        {index > 0 && ' / '}
        {
          <NavLink to={path} end relative='path'>
            {({ isActive }) => (isActive ? <b>{name}</b> : <span>{name}</span>)}
          </NavLink>
        }
      </nav>
    );
  });
  return (
    <div className='breadcrumbs'>
      <HamburgerMenu />
      {crumbs}
      {children}
    </div>
  );
};
