import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DialogFormBox } from '../../../../components/DialogBox/DialogFormBox';
import {
  AdminRoleSelector,
  createRoleOption,
  emptyOption
} from '../../../../components/Forms/AdminRoleSelector';
import { KeyText } from '../../../../components/Forms/KeyText';
import {
  UpdateTermOwner,
  updateTermOwner
} from '../../../../redux/actions/terms';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { Term } from '../../../../redux/slice/terms';
import { useAdminRole } from '../../../../utils/roles/useAdminRole';

type Props = {
  term: Term;
  edit: boolean;
  setEdit: (edit: boolean) => void;
};
export const EditOwnerAdmin: React.FC<Props> = ({ term, edit, setEdit }) => {
  const dispatch = useAppDispatch();
  const role = useAdminRole(term.adminRole);
  const methods = useForm<UpdateTermOwner>({
    resolver: zodResolver(UpdateTermOwner),
    defaultValues: {
      ...term,
      adminRole: emptyOption(term.adminRole)
    }
  });
  useEffect(() => {
    if (role) {
      methods.setValue('adminRole', createRoleOption(role));
    }
  }, [role]);
  return (
    <DialogFormBox
      methods={methods}
      onSubmit={data =>
        dispatchAndHandleResult(dispatch, updateTermOwner, data, () =>
          setEdit(false)
        )
      }
      isModalOpen={edit}
      closeModal={() => {
        methods.setValue('ownerName', term.ownerName);
        if (role) {
          methods.setValue('adminRole', createRoleOption(role));
        }
        setEdit(false);
      }}
      header='Endre eier og admin'
    >
      <KeyText
        name='ownerName'
        label='Eier'
        description='Hvem har laget dette brukervilkåret? F.eks. ren eller deichman'
        elementProps={{}}
      />
      <AdminRoleSelector
        description='Adminbrukere må ha denne rollen for å administrere brukervilkåret.'
        name='adminRole'
      />
    </DialogFormBox>
  );
};
