import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { AdminRoleSelector } from '../../../components/Forms/AdminRoleSelector';
import { KeyId } from '../../../components/Forms/KeyId';
import { KeyText } from '../../../components/Forms/KeyText';
import {
  createPublicKeychainFactory,
  CreatePublicKeychainFactoryForm
} from '../../../redux/actions/keychainFactoryDetails';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { historyGoBack } from '../../../utils/history';

export const CreatePublicKeychainFactory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm<CreatePublicKeychainFactoryForm>({
    resolver: zodResolver(CreatePublicKeychainFactoryForm)
  });
  const { handleSubmit, reset } = methods;

  const handleCreateClick = async (data: CreatePublicKeychainFactoryForm) =>
    dispatchAndHandleResult(dispatch, createPublicKeychainFactory, data, () => {
      reset();
      historyGoBack(navigate);
    });

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleCreateClick)}>
        <KeyText
          data-testid='name-input'
          name='publicName'
          label='Navn på tilgangslisten'
          description='Navnet blir synlig i Oslonøkkelen-appen for personer på
          tilgangslisten.'
          elementProps={{
            placeholder: 'Skriv inn navn på tilgangslisten'
          }}
        />
        <KeyId
          name='id'
          otherField='publicName'
          description='Unik ID til web-login-knippet'
          label='ID'
          elementProps={{
            placeholder: 'Skriv inn ønsket ID til oidc'
          }}
        />

        <AdminRoleSelector
          description='Adminbrukere må ha denne rollen for å administrere tilgangslisten.'
          name='adminRole'
        />

        <Button label='Lag ny tilgangsliste' type='submit' accessKey='n' />
      </form>
    </FormProvider>
  );
};
