import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAdapterDetails } from '../../../redux/actions/adapters';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { ActionTable } from './ActionTable';

interface MatchParams {
  adapterId: string;
}

export const AdapterDetails: React.FC = () => {
  const { adapterId } = useParams<keyof MatchParams>();
  const adapter = useAppSelector(state => state.adapters.adapterDetails);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (adapterId && adapterId !== adapter.description.id) {
      dispatch(getAdapterDetails(adapterId));
    }
  }, [adapter.description.id, adapterId, dispatch]);

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs currentPageName={adapterId} />
      <ActionTable adapterId={adapter.description.id} things={adapter.thingsList} />
    </React.Fragment>
  );
};
