import React from 'react';
import { AboutCard } from '../../components/About/AboutCard';
import { AnomalyCard } from '../../components/Anomaly/AnomalyCard';
import { KeypediaSubjectCard } from '../../components/Keypedia/KeypediaSubjectCard';
import { PageInfoText } from '../../components/PageInfoText/PageInfoText';
import { AuditCard } from '../../components/cards/AuditCard/AuditCard';
import { LinkToControlpanelCard } from '../../components/cards/ControlpanelCards/LinkToControlpanelCard';
import { StatsCard } from '../../components/cards/StatsCard/StatsCard';
import { ImageCard } from '../../components/image/ImageCard';
import { OperationCards } from '../../components/operation/operationCards/OperationCards';
import { Tag } from './DynamicTag/DynamicTag';
import { LocationCard } from './LocationCard';
import { SubjectMenu } from './Menu/SubjectMenu';
import './Subject.scss';
import { SubjectBreadcrumbs } from './SubjectBreadcrumbs';
import { useSubject } from './SubjectWrapper';

export const Subject: React.FC = () => {
  const { subject } = useSubject();
  const fullOperationIds = subject.operationsList.map(operation => {
    return { subjectId: subject.id, operationId: operation.id };
  });

  return (
    <section className='subject'>
      <SubjectBreadcrumbs subject={subject} />
      <span className='visibility-status'>
        {subject.visibleForEveryone && '(Synlig for alle)'}
        <span className='tooltiptext'>
          Fordi en eller flere av operasjonene til stedet har offentlige
          åpningstider er stedet synlig for alle brukere av appen.
        </span>
      </span>
      <div className='actionmenu-ribbon'>
        <PageInfoText type='medium'>
          <br />
          Her kan du sette opp stedet ditt. De fleste innstillinger her vil
          synes på stedet i appen.
        </PageInfoText>
        <SubjectMenu subject={subject} />
      </div>
      <div className='cards'>
        <ImageCard images={subject.imagesList} subjectId={subject.id} />
        <AboutCard about={subject.about} />
        <AnomalyCard anomaly={subject.anomalyMessage} />
        <LocationCard />
        <KeypediaSubjectCard />
        <StatsCard type='subject' id={subject.id} />
        <AuditCard type='subject' citykeyId={subject.citykeyId} />
        <LinkToControlpanelCard type='subject' />
      </div>
      <div className='dynamic-tags'>
        Status Tag
        {subject.dynamicTagsList.map(tag => (
          <Tag key={tag.typeId} tag={tag} />
        ))}
      </div>
      <hr />
      <OperationCards fullOperationIds={fullOperationIds} />
    </section>
  );
};
