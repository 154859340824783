import React from 'react';

export const TimeIcon = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Tidsicon'>
      <g id='Vector'>
        <path d='M16 7V16L23 15.4047V18H14V7H16Z' fill='#2A2859' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1 16C1 7.71625 7.71625 1 16 1C24.2837 1 31 7.71625 31 16C31 24.285 24.2837 31 16 31C7.71625 31 1 24.285 1 16ZM3.14875 16C3.14875 23.0963 8.9025 28.8512 16 28.8512C23.0975 28.8512 28.8512 23.0963 28.8512 16C28.8512 8.9025 23.0975 3.14875 16 3.14875C8.9025 3.14875 3.14875 8.9025 3.14875 16Z'
          fill='#2A2859'
        />
      </g>
    </g>
  </svg>
);
