import React from 'react';

export const AccessControllIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='98.015'
    height='52.595'
    viewBox='0 0 98.015 52.595'
  >
    <g id='noun-access-1197046' transform='translate(-108.206 -151.36)'>
      <path
        id='Path_2993'
        data-name='Path 2993'
        d='M191.323,196.592a1.117,1.117,0,0,0-1.117,1.117v3.684H170.468a1.128,1.128,0,0,0-.77.308l-11.017,10.5-17.9.327h0c-2.983.059-5.18,2.941-5.18,6.252,0,.015,0,.029,0,.044l-19.813-6.079h0a5.159,5.159,0,0,0-6.408,3.671,4.82,4.82,0,0,0,.606,3.835,7,7,0,0,0,2.872,2.467h0l15.024,7.312h0c4.869,2.521,13.785,8.741,23.764,8.741,9.534,0,12.515-1.927,20.693-5.053h0c10.047-3.833,12.9-5.217,18.079-5.952l.122,5.714a1.117,1.117,0,0,0,1.117,1.093H204.1a1.117,1.117,0,0,0,1.117-1.117V197.707a1.117,1.117,0,0,0-1.117-1.117H191.321Zm1.117,2.233h10.55v33.514H192.763l-.322-15.051a1.116,1.116,0,0,0-1.14-1.1,1.15,1.15,0,0,0-.785.347,1.132,1.132,0,0,0-.308.8l.175,8.181c-5.593.762-8.653,2.233-18.827,6.114h0c-8.3,3.171-10.654,4.905-19.9,4.905-9.041,0-17.562-5.812-22.741-8.494-.009,0-.016-.011-.025-.012l-15.045-7.322a4.894,4.894,0,0,1-1.971-1.658,2.431,2.431,0,0,1-.335-2.065,2.929,2.929,0,0,1,3.595-2.112l20.911,6.416a5.227,5.227,0,0,0,4.759,3.726h21.357a1.148,1.148,0,0,0,.8-.322,1.117,1.117,0,0,0-.8-1.912H140.8c-1.477,0-2.969-1.682-2.969-4.008s1.486-3.988,2.99-4.019l18.332-.335a1.105,1.105,0,0,0,.75-.308l11.008-10.487h19.292v5.158a1.117,1.117,0,1,0,2.233,0v-9.959Z'
        transform='translate(0 -35.813)'
        stroke='#121212'
        strokeWidth='2'
      />
      <path
        id='Path_2994'
        data-name='Path 2994'
        d='M164.179,151.86a7.619,7.619,0,1,0,7.223,10.047h29.908a1.117,1.117,0,0,0,.808-1.888l-2.543-2.666h0l-2.543-2.677a1.117,1.117,0,0,0-1.62,0l-2.546,2.68-1.726,1.818-1.734-1.818h0l-2.543-2.677a1.119,1.119,0,0,0-1.251-.257,1.115,1.115,0,0,0-.369.257l-2.544,2.677h0l-1.736,1.82-1.736-1.82h0l-2.543-2.677a1.119,1.119,0,0,0-1.251-.257,1.115,1.115,0,0,0-.369.257l-2.546,2.68-.815.859a7.645,7.645,0,0,0-7.518-6.353Zm0,2.233a5.387,5.387,0,1,1-5.392,5.381A5.373,5.373,0,0,1,164.179,154.093Zm11.689,2.972,1.736,1.828h0l.745.781h-4.962l.744-.783Zm10.181,0,1.736,1.828h0l.745.781h-4.965l.745-.781h0Zm10.17,0,1.736,1.828h0l.745.781H193.74l.744-.783Z'
        transform='translate(-37.897)'
        stroke='#121212'
        strokeWidth='1'
      />
    </g>
  </svg>
);
