import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Building,
  Door,
  ManualGrantChains,
  PublicChains,
  ThreeDots,
  Web
} from '../../../assets/CircledIcons';
import { SubjectActionCard } from '../../../pages/places/SubjectCardsContainer/SubjectCard/SubjectActionCard';
import { thirdPartySystemHome } from '../../Navigation/controlpanelNavPaths';
import { controlPanelHome, keychainHome } from '../../Navigation/navPaths';
import { OperationInfoCard } from '../../operation/operationCards/OperationInfoCard';
import { RenderIfSuperAdmin } from '../../util/RenderIfSuperAdmin';
import { NarrowCard } from '../NarrowCard/NarrowCard';

interface Props {
  type: 'subject' | 'operation' | 'oidc' | 'manual-grant' | 'push' | 'public';
  extra?: string;
}

export const getLinkToControlPanel = (
  pathname: string,
  type: Props['type']
) => {
  let trimmedPath = decodeURI(pathname).replace(keychainHome.path, '');
  if (type === 'push') {
    trimmedPath = trimmedPath.substring(
      trimmedPath.lastIndexOf('/'),
      trimmedPath.lastIndexOf('_')
    );
    return thirdPartySystemHome.path + trimmedPath;
  }
  return controlPanelHome.path + trimmedPath;
};
export const LinkToControlpanelCard: React.FC<Props> = ({ type, extra }) => {
  const { pathname } = useLocation();
  const link = getLinkToControlPanel(pathname, type);
  let card = <React.Fragment />;
  switch (type) {
    case 'subject':
      card = (
        <SubjectActionCard
          link={link}
          header='Kontrollpanel'
          icon={<Building />}
        ></SubjectActionCard>
      );
      break;
    case 'operation':
      card = (
        <OperationInfoCard
          link={link}
          header='Kontrollpanel'
          icon={<Door />}
          text='Snarvei rett til Kontrollpanel'
        />
      );
      break;
    case 'oidc':
      card = (
        <NarrowCard
          link={link}
          header='Kontrollpanel'
          icon={<Web size={100} />}
        ></NarrowCard>
      );
      break;
    case 'manual-grant':
      card = (
        <NarrowCard
          link={link}
          header='Kontrollpanel'
          icon={<ManualGrantChains size={100} />}
        ></NarrowCard>
      );
      break;
    case 'public':
      card = (
        <NarrowCard
          link={link}
          header='Kontrollpanel'
          icon={<PublicChains size={100} />}
        ></NarrowCard>
      );
      break;
    case 'push':
      card = (
        <NarrowCard
          link={link}
          header='Kontrollpanel'
          icon={<ThreeDots size={100} />}
        >
          {extra}
        </NarrowCard>
      );
      break;
  }
  return <RenderIfSuperAdmin>{card}</RenderIfSuperAdmin>;
};
