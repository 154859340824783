import React from 'react';

export const Empty: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='40'
    viewBox='0 0 37 40'
  >
    <g
      id='Group_12329'
      data-name='Group 12329'
      transform='translate(-64 -1129)'
    >
      <g
        id='Ellipse_773'
        data-name='Ellipse 773'
        transform='translate(64 1129)'
        fill='#fff'
        stroke='#292858'
        strokeWidth='1'
      ></g>
    </g>
  </svg>
);
