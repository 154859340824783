import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { name } from '../../redux/types/zodSchemas';
import { DialogFormBox } from '../DialogBox/DialogFormBox';
import { KeyText } from '../Forms/KeyText';

type Props = {
  closeModal: () => void;
  isModalOpen: boolean;
  initialName: string;
  storeAction: (newName: string) => void;
  formLabel?: string;
  description?: string;
};
export const RenameDialog: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  initialName,
  storeAction,
  formLabel = 'Navn',
  description
}) => {
  const methods = useForm({
    defaultValues: { name: initialName },
    resolver: zodResolver(z.object({ name: name }))
  });
  return (
    <DialogFormBox
      buttonLabel='Gi nytt navn'
      methods={methods}
      onSubmit={data => {
        storeAction(data.name);
        closeModal();
      }}
      header='Endre navn'
      closeModal={closeModal}
      isModalOpen={isModalOpen}
    >
      <KeyText
        name='name'
        elementProps={{}}
        label={formLabel}
        description={description}
      />
    </DialogFormBox>
  );
};
