import React, { useEffect } from 'react';
import { HeadingProps } from '../../../components/Table/TableHeader';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { sortData } from '../../../components/Table/sortUtil';
import { useSort } from '../../../components/Table/useSort';
import { ActionChain } from '../../../redux/slice/adapters';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import './ActionChain.scss';

interface Props {
  actionChains: ActionChain[];
}
export const ActionChainTable: React.FC<Props> = ({ actionChains }) => {
  const [sortKey, sortReversed, setSortKey] = useSort<ActionChain>();
  const [sortedActionChain, setSortedActionChain] = React.useState<
    ActionChain[]
  >([]);
  const tableHeadings = [
    { element: 'ID', sortKey: 'id' },
    { element: 'Navn', sortKey: 'name' },
    { element: 'Antall actions', sortKey: 'actionsList' },
    { element: 'Antall operasjoner', sortKey: 'usedByOperationsList' }
  ] satisfies HeadingProps<ActionChain>[];

  useEffect(() => {
    setSortedActionChain(sortData(actionChains, sortKey, sortReversed));
  }, [sortKey, sortReversed, actionChains]);

  const rows = sortedActionChain.map(actionChain => {
    return {
      cells: [
        <CopyWrapper key={actionChain.id} copyValue={actionChain.id} />,
        actionChain.name,
        actionChain.actionsList.length,
        actionChain.usedByOperationsList.length
      ],
      link: actionChain.id
    };
  });

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs />
      <TableWithButtons
        ting='actionchain'
        buttons={[
          {
            label: 'Lag ny ActionChain',
            type: 'primary',
            accessKey: 'n',
            linkTo: 'create-new'
          }
        ]}
        table={{
          headings: tableHeadings,
          rows,
          sortKey,
          setSortKey,
          sortReversed
        }}
      />
    </React.Fragment>
  );
};
