import React from 'react';

export const ManualGrantKeychain = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='48'
    height='48'
    viewBox='0 0 48 48'
  >
    <g id='Group_1927' data-name='Group 1927' transform='translate(-480 -440)'>
      <g id='Group_1925' data-name='Group 1925' transform='translate(480 440)'>
        <g id='Group_1845' data-name='Group 1845' transform='translate(0 0)'>
          <g id='Group_1843' data-name='Group 1843' transform='translate(0)'>
            <circle
              id='Ellipse_535'
              data-name='Ellipse 535'
              cx='24'
              cy='24'
              r='24'
              fill='#f9c66b'
            />
          </g>
        </g>
      </g>
      <g
        id='Group_1926'
        data-name='Group 1926'
        transform='translate(486.261 458.783)'
      >
        <path
          id='Path_4368'
          data-name='Path 4368'
          d='M121.651,5.6a3.341,3.341,0,1,0-3.359,3.38,3.372,3.372,0,0,0,3.359-3.38m2.03,1.128a5.5,5.5,0,0,1-6.224,4.368,5.572,5.572,0,0,1-4.567-6.383,5.472,5.472,0,0,1,10.8-.218l15.475.087-.012,2.233-2.195-.012-.024,4.467-2.195-.012.012-2.235h-.008s0-.005,0-.008a1.09,1.09,0,1,0-2.18-.012s0,.005,0,.008h-.007l-.012,2.235-2.195-.012.024-4.467Z'
          transform='translate(-103.69 0)'
        />
        <line
          id='Line_1'
          data-name='Line 1'
          x1='4.756'
          transform='translate(2.914 1.24)'
        />
        <path
          id='Line_2'
          data-name='Line 2'
          d='M-5.354-9.219H-10.11a.89.89,0,0,1-.89-.89.89.89,0,0,1,.89-.89h4.756a.89.89,0,0,1,.89.89A.89.89,0,0,1-5.354-9.219Z'
          transform='translate(13.024 11.349)'
        />
        <line
          id='Line_3'
          data-name='Line 3'
          x1='4.756'
          transform='translate(0.89 5.636)'
        />
        <path
          id='Line_4'
          data-name='Line 4'
          d='M-5.354-9.219H-10.11a.89.89,0,0,1-.89-.89.89.89,0,0,1,.89-.89h4.756a.89.89,0,0,1,.89.89A.89.89,0,0,1-5.354-9.219Z'
          transform='translate(11 15.746)'
        />
        <line
          id='Line_5'
          data-name='Line 5'
          x1='4.756'
          transform='translate(2.914 10.033)'
        />
        <path
          id='Line_6'
          data-name='Line 6'
          d='M-5.354-9.219H-10.11a.89.89,0,0,1-.89-.89.89.89,0,0,1,.89-.89h4.756a.89.89,0,0,1,.89.89A.89.89,0,0,1-5.354-9.219Z'
          transform='translate(13.024 20.143)'
        />
      </g>
    </g>
  </svg>
);
