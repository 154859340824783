// source: operation-log.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.IdCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.oneofGroups_ = [[5,6,7]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.IdCase = {
  ID_NOT_SET: 0,
  OPERATION_ID: 5,
  KEYCHAIN_FACTORY_URI: 6,
  ADMISSION_CARD_TYPE: 7
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.IdCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.getIdCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.IdCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    interval: (f = msg.getInterval()) && shared_pb.LocalDateTimeInterval.toObject(includeInstance, f),
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 6, ""),
    admissionCardType: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.LocalDateTimeInterval;
      reader.readMessage(value,shared_pb.LocalDateTimeInterval.deserializeBinaryFromReader);
      msg.setInterval(value);
      break;
    case 5:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdmissionCardType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInterval();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.LocalDateTimeInterval.serializeBinaryToWriter
    );
  }
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.LocalDateTimeInterval interval = 1;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.getInterval = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTimeInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateTimeInterval, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.setInterval = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.clearInterval = function() {
  return this.setInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 5;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 5));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string keychain_factory_uri = 6;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.clearKeychainFactoryUri = function() {
  return jspb.Message.setOneofField(this, 6, proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.hasKeychainFactoryUri = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string admission_card_type = 7;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.getAdmissionCardType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.setAdmissionCardType = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.clearAdmissionCardType = function() {
  return jspb.Message.setOneofField(this, 7, proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsRequest.prototype.hasAdmissionCardType = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, ""),
    until: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUntil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUntil();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string from = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.prototype.getFrom = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.prototype.setFrom = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string until = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.prototype.getUntil = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsRequest.prototype.setUntil = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    interval: (f = msg.getInterval()) && shared_pb.LocalDateTimeInterval.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = new shared_pb.LocalDateTimeInterval;
      reader.readMessage(value,shared_pb.LocalDateTimeInterval.deserializeBinaryFromReader);
      msg.setInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getInterval();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.LocalDateTimeInterval.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional oslonokkelen.proto.shared.LocalDateTimeInterval interval = 2;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.prototype.getInterval = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTimeInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateTimeInterval, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.prototype.setInterval = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.prototype.clearInterval = function() {
  return this.setInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataRequest.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dayStatisticsList: jspb.Message.toObjectList(msg.getDayStatisticsList(),
    proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.deserializeBinaryFromReader);
      msg.addDayStatistics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDayStatisticsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: (f = msg.getDate()) && shared_pb.LocalDate.toObject(includeInstance, f),
    successCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    errorCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deniedCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    uniqueUserCount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.LocalDate;
      reader.readMessage(value,shared_pb.LocalDate.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSuccessCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setErrorCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeniedCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUniqueUserCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getSuccessCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getErrorCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeniedCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getUniqueUserCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.LocalDate date = 1;
 * @return {?proto.oslonokkelen.proto.shared.LocalDate}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.getDate = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDate} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDate, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDate|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.hasDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 success_count = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.getSuccessCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.setSuccessCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 error_count = 3;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.getErrorCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.setErrorCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 denied_count = 4;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.getDeniedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.setDeniedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 unique_user_count = 5;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.getUniqueUserCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics.prototype.setUniqueUserCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated DayStatistics day_statistics = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics>}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.prototype.getDayStatisticsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.prototype.setDayStatisticsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.prototype.addDayStatistics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.DayStatistics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.StatisticsResponse.prototype.clearDayStatisticsList = function() {
  return this.setDayStatisticsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    zip: msg.getZip_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setZip(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getZip_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes zip = 1;
 * @return {!(string|Uint8Array)}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.prototype.getZip = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes zip = 1;
 * This is a type-conversion wrapper around `getZip()`
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.prototype.getZip_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getZip()));
};


/**
 * optional bytes zip = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getZip()`
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.prototype.getZip_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getZip()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.BulkStatisticsResponse.prototype.setZip = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditsList: jspb.Message.toObjectList(msg.getAuditsList(),
    proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.deserializeBinaryFromReader);
      msg.addAudits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: (f = msg.getTimestamp()) && shared_pb.Timestamp.toObject(includeInstance, f),
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    interval: (f = msg.getInterval()) && shared_pb.LocalDateTimeInterval.toObject(includeInstance, f),
    entriesFound: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 3:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 4:
      var value = new shared_pb.LocalDateTimeInterval;
      reader.readMessage(value,shared_pb.LocalDateTimeInterval.deserializeBinaryFromReader);
      msg.setInterval(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEntriesFound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getInterval();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      shared_pb.LocalDateTimeInterval.serializeBinaryToWriter
    );
  }
  f = message.getEntriesFound();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.Timestamp timestamp = 2;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.getTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 3;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional oslonokkelen.proto.shared.LocalDateTimeInterval interval = 4;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.getInterval = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTimeInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateTimeInterval, 4));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.setInterval = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.clearInterval = function() {
  return this.setInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 entries_found = 5;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.getEntriesFound = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit.prototype.setEntriesFound = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Audit audits = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit>}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.prototype.getAuditsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.prototype.setAuditsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.prototype.addAudits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.Audit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.OperationLogAuditsResponse.prototype.clearAuditsList = function() {
  return this.setAuditsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && shared_pb.Timestamp.toObject(includeInstance, f),
    fnr: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    error: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry;
  return proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFnr(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFnr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getError();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.Timestamp timestamp = 1;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.getTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fnr = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.getFnr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.setFnr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool error = 4;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.getError = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry.prototype.setError = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated Entry entries = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry>}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.Entry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.logs.DecryptedOperationLogDataResponse.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.logs);
