import React, { useCallback } from 'react';
import { ActionMenu } from '../../../components/ActionMenu/ActionMenu';
import { ActionMenuItemProps } from '../../../components/ActionMenu/ActionMenuItem';
import { RenameDialog } from '../../../components/Dialog/RenameDialog';
import { updateSubjectName } from '../../../redux/actions/subjects';
import { useAppDispatch } from '../../../redux/hooks';
import { Subject } from '../../../redux/slice/subjects';

type Props = {
  subject: Subject;
};
export const SubjectMenu: React.FC<Props> = ({ subject }) => {
  const [isRenameModalOpen, setIsRenameModalOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  const renameAction = useCallback(
    (name: string) => {
      dispatch(updateSubjectName({ subjectId: subject.id, name }));
    },
    [subject.id]
  );

  const actions: ActionMenuItemProps[] = [
    {
      type: 'edit',
      description: 'Endre navn på sted',
      onClick: () => setIsRenameModalOpen(true)
    }
  ];

  return (
    <div className='actionmenu-container'>
      <ActionMenu actions={actions} />
      <RenameDialog
        isModalOpen={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
        initialName={subject.name}
        storeAction={renameAction}
        formLabel='Nytt navn på stedet'
      />
    </div>
  );
};
