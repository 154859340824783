import React from 'react';
import { useLocation } from 'react-router-dom';
import { PvdSvg } from '../../../assets/PvdSvg';
import { getLinkToControlPanel } from '../../cards/ControlpanelCards/LinkToControlpanelCard';
import { OperationInfoCard } from '../operationCards/OperationInfoCard';

interface Props {
  proximityDeviceId: string;
}

export const PvdCard: React.FC<Props> = ({ proximityDeviceId }) => {
  const { pathname } = useLocation();
  const link = getLinkToControlPanel(pathname, 'subject');
  return (
    <OperationInfoCard
      icon={<PvdSvg />}
      header='Nærhetsenhet'
      link={link}
      text={'ID: ' + proximityDeviceId}
    />
  );
};
