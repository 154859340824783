import axios from 'axios';
import { UpdateKeychainFactoryImageRequest } from '../../proto/keychain-factory_pb';
import { UpdateOperationImageRequest } from '../../proto/operation_pb';
import { UpdateSubjectImageRequest } from '../../proto/subjects_pb';
import {
  ImagePresignedUrlRequest,
  ImagePresignedUrlResponse
} from '../../proto/utils_pb';
import {
  getGrpcMetadata,
  getKeychainFactoryClient,
  getOperationsClient,
  getSubjectsClient,
  getUtilsClient,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import { setPopup } from '../slice/popup';
import { getAppData } from './appData';
import { createFullOperationId } from './util';

type RequestSubjectImagePresignedUrlProps = {
  subjectId: string;
};

type RequestOperationImagePresignedUrlProps = {
  subjectId: string;
  operationId: string;
};

type RequestKeychainFactoryImagePresignedUrlProps = {
  keychainFactoryUri: string;
};

export const requestImagePresignedUrl = createAppAsyncThunk<
  ImagePresignedUrlResponse.AsObject,
  | RequestSubjectImagePresignedUrlProps
  | RequestOperationImagePresignedUrlProps
  | RequestKeychainFactoryImagePresignedUrlProps
>('images/requestImagePresignedUrl', async ({ ...ids }, thunkAPI) => {
  try {
    const request = new ImagePresignedUrlRequest();
    if ('operationId' in ids) {
      request.setOperation(
        new ImagePresignedUrlRequest.OperationImage().setOperationId(
          createFullOperationId({ ...ids })
        )
      );
    } else if ('subjectId' in ids) {
      request.setSubject(
        new ImagePresignedUrlRequest.SubjectImage().setSubjectId(ids.subjectId)
      );
    } else if ('keychainFactoryUri' in ids) {
      request.setKeychainFactory(
        new ImagePresignedUrlRequest.KeychainFactoryImage().setKeychainFactoryUri(
          ids.keychainFactoryUri
        )
      );
    }
    return (
      await getUtilsClient().generateImagePresignedUrl(
        request,
        await getGrpcMetadata()
      )
    ).toObject();
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

interface UploadImageProps {
  presignedUrl: ImagePresignedUrlResponse.AsObject;
  image: File;
}
export const uploadImage = createAppAsyncThunk<number | void, UploadImageProps>(
  'images/uploadImage',
  async ({ presignedUrl: presignedUrl, image }, thunkAPI) => {
    try {
      const response = await axios({
        url: presignedUrl.presignedUri,
        headers: {
          'content-type': presignedUrl.contentType,
          'cache-control': presignedUrl.cacheControl
        },
        method: 'put',
        data: image
      });
      return response.status;
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

interface UpdateImagePropsCommon {
  uri: string;
  remove: boolean;
}
interface UpdateSubjectImageProps extends UpdateImagePropsCommon {
  subjectId: string;
}
interface UpdateOperationImageProps extends UpdateImagePropsCommon {
  subjectId: string;
  operationId: string;
}
interface UpdateKeychainFactoryImageProps extends UpdateImagePropsCommon {
  keychainFactoryUri: string;
}

type UpdateImageProps =
  | UpdateSubjectImageProps
  | UpdateOperationImageProps
  | UpdateKeychainFactoryImageProps;

export const updateImage = createAppAsyncThunk<void, UpdateImageProps>(
  'images/updateImage',
  async ({ uri, remove, ...ids }, thunkAPI) => {
    try {
      if ('operationId' in ids) {
        const request = new UpdateOperationImageRequest()
          .setOperationId(createFullOperationId(ids))
          .setUri(remove ? '' : uri);
        await getOperationsClient().updateOperationImage(
          request,
          await getGrpcMetadata()
        );
      } else if ('subjectId' in ids) {
        const request = new UpdateSubjectImageRequest()
          .setSubjectId(ids.subjectId)
          .setUri(remove ? '' : uri);
        await getSubjectsClient().updateSubjectImage(
          request,
          await getGrpcMetadata()
        );
      } else if ('keychainFactoryUri' in ids) {
        const request = new UpdateKeychainFactoryImageRequest()
          .setKeychainFactoryUri(ids.keychainFactoryUri)
          .setImageUri(remove ? '' : uri);
        await getKeychainFactoryClient().updateKeychainFactoryImage(
          request,
          await getGrpcMetadata()
        );
      }
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Bildet er oppdatert'
        })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);
