import { createSlice } from '@reduxjs/toolkit';
import { getAppData, getXmlData, updateXmlData } from '../actions/appData';

export interface AppDataState {
  getXmlDataLoading: boolean;
  updateXmlDataLoading: boolean;
  xmlData: { version: number; xml: string } | null;
  backendVersion: string;
}

export const initialState: AppDataState = {
  getXmlDataLoading: false,
  updateXmlDataLoading: false,
  xmlData: null,
  backendVersion: ''
};

const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateXmlData.pending, state => {
        state.updateXmlDataLoading = true;
      })
      .addCase(updateXmlData.fulfilled, (state, { payload }) => {
        state.updateXmlDataLoading = false;
        state.xmlData = payload;
      })
      .addCase(updateXmlData.rejected, state => {
        state.updateXmlDataLoading = false;
      })
      .addCase(getXmlData.pending, state => {
        state.getXmlDataLoading = true;
      })
      .addCase(getXmlData.fulfilled, (state, { payload }) => {
        state.getXmlDataLoading = false;
        state.xmlData = payload;
      })
      .addCase(getXmlData.rejected, state => {
        state.getXmlDataLoading = false;
      })
      .addCase(getAppData.fulfilled, (state, { payload }) => {
        state.backendVersion = payload.backendVersion;
      });
  }
});
export default appDataSlice.reducer;
