import React from 'react';
import { ClockIcon } from '../../../assets/ClockIcon';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { AuditCard } from '../../../components/cards/AuditCard/AuditCard';
import { LinkToControlpanelCard } from '../../../components/cards/ControlpanelCards/LinkToControlpanelCard';
import { NarrowCard } from '../../../components/cards/NarrowCard/NarrowCard';
import { ImageCard } from '../../../components/image/ImageCard';
import { PublicKeychainFactoryDetails } from '../../../redux/slice/keychainFactoryDetails';
import { PublicKeychainFactorySummary } from '../../../redux/slice/keychainFactorySummaries';
import { KeychainFactoryBreadcrumbs } from '../KeychainFactoryBreadcrumbs';
import { KeychainFactoryContainer } from '../KeychainFactoryContainer/KeychainFactoryContainer';
import { Locks } from '../locks/Locks';

interface Props {
  keychainFactory: PublicKeychainFactoryDetails | PublicKeychainFactorySummary;
}

export const PublicKeychainFactoryPage: React.FC<Props> = ({
  keychainFactory
}) => {
  return (
    <KeychainFactoryContainer>
      <KeychainFactoryBreadcrumbs keychainFactory={keychainFactory} />
      <div className='cards'>
        <ImageCard
          images={[{ uri: keychainFactory.image }]}
          keychainFactoryUri={keychainFactory.keychainFactoryUri}
        />
      </div>
      <div className='cards'>
        <NarrowCard
          link='åpningstider'
          header='Åpningstider'
          icon={<ClockIcon />}
        >
          {keychainFactory.isDetails && (
            <h4>
              {keychainFactory.openingHours.alwaysOpen
                ? 'Alltid åpent'
                : 'Tilpasset åpningstid'}
            </h4>
          )}
        </NarrowCard>
        <AuditCard
          type={keychainFactory.type}
          citykeyId={keychainFactory.keychainFactoryUri}
        />
        <LinkToControlpanelCard type={keychainFactory.type} />
      </div>
      <br />
      {keychainFactory.isDetails ? (
        <Locks operations={keychainFactory.grantedOperationsList} />
      ) : (
        <LoadingSpinner />
      )}
    </KeychainFactoryContainer>
  );
};
