import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ToggleGroup } from '../../../components/ToggleGroup/ToggleButton';
import { isSuperAdmin } from '../../../components/util/RenderIfSuperAdmin';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { AuditLogs } from './pages/AuditLogs';
import { FlightRecordings } from './pages/FlightRecordings';
import { OperationLogs } from './pages/OperationLogs';
import { ProfileLookup } from './pages/ProfileLookup';

export const LogsAndData: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const flightRecordingId = searchParams.get('flightRecordingId') || '';
  const [selectedTab, setSelectedTab] = useState(
    flightRecordingId ? 'flightrecordings' : 'auditlogs'
  );
  const toggleGroupData = [
    {
      label: 'Audit Logs',
      value: 'auditlogs',
      onClick: () => setSelectedTab('auditlogs')
    },
    {
      label: 'Flight Recordings',
      value: 'flightrecordings',
      onClick: () => setSelectedTab('flightrecordings')
    },
    {
      label: 'Operasjonslogger',
      value: 'operationlogs',
      onClick: () => setSelectedTab('operationlogs')
    },
    {
      label: 'Profilhåndtering',
      value: 'profile-lookup',
      onClick: () => setSelectedTab('profile-lookup')
    }
  ];

  if (isSuperAdmin()) {
    return (
      <section className='superadmin' aria-label='LogsAndData'>
        <ControlpanelBreadcrumbs />
        <ToggleGroup buttons={toggleGroupData} selectedValue={selectedTab} />
        <br />
        <br />
        {selectedTab === 'auditlogs' && <AuditLogs />}
        {selectedTab === 'flightrecordings' && (
          <FlightRecordings
            setSearchParams={setSearchParams}
            id={flightRecordingId}
          />
        )}
        {selectedTab === 'operationlogs' && <OperationLogs />}
        {selectedTab === 'profile-lookup' && <ProfileLookup />}
      </section>
    );
  } else {
    return (
      <section className='superadmin'>
        <h1>Du har ikke tilgang til superadmin</h1>
      </section>
    );
  }
};
