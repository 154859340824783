import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { KeySelect } from '../../../components/Forms/KeySelect';
import { useAppSelector } from '../../../redux/hooks';

type Option = {
  labelText: string;
  value: string;
  description: string;
};

type Props = {
  actionChainId?: string;
  name?: string;
};
export const ActionChainSelector: React.FC<Props> = ({
  actionChainId,
  name = 'actionChain'
}) => {
  const actionChains = useAppSelector(state => state.adapters.actionChains);

  const [actionChainOptions, setActionChainOptions] = useState<Option[]>([]);

  const { setValue } = useFormContext();

  useEffect(() => {
    setActionChainOptions(
      actionChains.map(actionChain => {
        return {
          value: actionChain.id,
          description: actionChain.id,
          labelText: actionChain.name
        };
      })
    );
  }, [actionChains]);

  useEffect(() => {
    if (actionChainId) {
      const foundActionChain = actionChainOptions.find(
        option => option.value === actionChainId
      );
      if (foundActionChain) {
        setValue(name, foundActionChain);
      }
    }
  }, [actionChainId, actionChainOptions]);
  return (
    <KeySelect
      label='ActionChain'
      name={name}
      description='Velg handlingskjeden som operasjonen skal være knyttet til.'
      elementProps={{
        options: actionChainOptions,
        placeholder: 'Velg en ActionChain'
      }}
    />
  );
};
