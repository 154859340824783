import React from 'react';
import { antall } from '../../utils/language';
import { Button, ButtonProps } from '../Button/Button';
import { Table, TableProps } from './Table';
import './TableWithButtons.scss';

interface Props<T> {
  buttons?: ButtonProps[];
  table: TableProps<T>;
  ting?: string;
  appendText?: string;
}

export const TableWithButtons = <T,>({
  buttons = [],
  table,
  ting = 'rad',
  appendText = ''
}: Props<T>) => {
  return (
    <div className='grouped-button-table'>
      <span className='count'>
        {antall(table.rows?.length, ting)} {appendText}
      </span>
      <div className='buttons'>
        {buttons.map(value => (
          <Button key={value.label} {...value} />
        ))}
      </div>
      <Table {...table} />
    </div>
  );
};
