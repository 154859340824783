import React from 'react';
import { OperationInfo } from '../../../../../redux/slice/keychainFactoryDetails';
import { LocksInKeychainFactoryList } from './list/LocksInKeychainFactoryList';

interface Props {
  keychainFactoryUri: string;
  operations: OperationInfo[];
}

export const LocksInKeychainFactory: React.FC<Props> = ({
  keychainFactoryUri,
  operations
}) => (
  <section className='current-locks'>
    <h2>Låser på tilgangslisten</h2>
    <p>Brukerene på tilgangslisten får adgang til disse låsene:</p>
    <LocksInKeychainFactoryList
      keychainFactoryUri={keychainFactoryUri}
      operations={operations}
    />
  </section>
);
