import React from 'react';
import { OverridingHourComponent } from '../../../../../components/OpeningHours/OverridingHour';
import {
  DateOverrideRule,
  OpeningHours
} from '../../../../../redux/types/common';
import {
  checkIfDayIsClosed,
  updateOverridingRuleDate
} from '../../../../../utils/keychainFactory/openingHours';

interface Props {
  overridingRule: DateOverrideRule;
  openingHours: OpeningHours;
  setOpeningHours: (openingHours: OpeningHours) => void;
}

export const OverridingRule: React.FC<Props> = ({
  overridingRule,
  openingHours,
  setOpeningHours
}) => {
  const { date } = overridingRule;
  const [timeInterval] = overridingRule.timeInterval;
  const dayIsClosed = checkIfDayIsClosed(timeInterval);

  const changeOverridingRuleDate = (newDate: Date) => {
    if (dayIsClosed) {
      const updatedOpeningHours = updateOverridingRuleDate(
        openingHours,
        'closedDateRules',
        date,
        newDate
      );
      setOpeningHours(updatedOpeningHours);
    } else {
      const updatedOpeningHours = updateOverridingRuleDate(
        openingHours,
        'dateOverrideRules',
        date,
        newDate
      );
      setOpeningHours(updatedOpeningHours);
    }
  };

  const updateTime = (newTime: string, type: 'from' | 'until') => {
    const ruleIndex = openingHours.dateOverrideRulesList.findIndex(
      rule => JSON.stringify(rule) === JSON.stringify(overridingRule)
    );
    const updatedRules = [...openingHours.dateOverrideRulesList];
    if (type === 'from') {
      updatedRules[ruleIndex].timeInterval[0].from = newTime;
    } else {
      updatedRules[ruleIndex].timeInterval[0].until = newTime;
    }

    setOpeningHours({
      ...openingHours,
      dateOverrideRulesList: updatedRules
    });
  };

  const updateDateClosed = (dayIsOpen: boolean) => {
    let updatedClosedDateRules: { date: string }[] = [];
    let updatedDateOverrideRules: DateOverrideRule[] = [];

    if (dayIsOpen) {
      updatedClosedDateRules = [...openingHours.closedDateRulesList].filter(
        rule => rule.date !== date
      );
      updatedDateOverrideRules = [
        ...openingHours.dateOverrideRulesList,
        { date: date, timeInterval: [{ from: '07:00', until: '20:00' }] }
      ];
    } else {
      updatedDateOverrideRules = [...openingHours.dateOverrideRulesList].filter(
        rule => rule.date !== date
      );
      updatedClosedDateRules = [...openingHours.closedDateRulesList, { date }];
    }

    setOpeningHours({
      ...openingHours,
      closedDateRulesList: updatedClosedDateRules,
      dateOverrideRulesList: updatedDateOverrideRules
    });
  };

  const removeRule = () => {
    let updatedClosedDateRules: { date: string }[] = [];
    let updatedDateOverrideRules: DateOverrideRule[] = [];

    if (dayIsClosed) {
      updatedClosedDateRules = [...openingHours.closedDateRulesList].filter(
        rule => rule.date !== date
      );
      updatedDateOverrideRules = [...openingHours.dateOverrideRulesList];
    } else {
      updatedDateOverrideRules = [...openingHours.dateOverrideRulesList].filter(
        rule => rule.date !== date
      );
      updatedClosedDateRules = [...openingHours.closedDateRulesList];
    }

    setOpeningHours({
      ...openingHours,
      closedDateRulesList: updatedClosedDateRules,
      dateOverrideRulesList: updatedDateOverrideRules
    });
  };

  return (
    <OverridingHourComponent
      selectedDate={new Date(date)}
      onDateChange={changeOverridingRuleDate}
      dayIsClosed={dayIsClosed}
      updateDayIsClosed={updateDateClosed}
      fromTime={timeInterval.from}
      untilTime={timeInterval.until}
      updateTime={updateTime}
      removeRule={removeRule}
    />
  );
};
