/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.data
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: data.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as data_pb from './data_pb'; // proto import: "data.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class DataServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAdminUserProfileLookup = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.data.DataService/AdminUserProfileLookup',
    grpcWeb.MethodType.UNARY,
    data_pb.AdminUserProfileLookupRequest,
    data_pb.AdminUserProfileLookupResponse,
    (request: data_pb.AdminUserProfileLookupRequest) => {
      return request.serializeBinary();
    },
    data_pb.AdminUserProfileLookupResponse.deserializeBinary
  );

  adminUserProfileLookup(
    request: data_pb.AdminUserProfileLookupRequest,
    metadata?: grpcWeb.Metadata | null): Promise<data_pb.AdminUserProfileLookupResponse>;

  adminUserProfileLookup(
    request: data_pb.AdminUserProfileLookupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: data_pb.AdminUserProfileLookupResponse) => void): grpcWeb.ClientReadableStream<data_pb.AdminUserProfileLookupResponse>;

  adminUserProfileLookup(
    request: data_pb.AdminUserProfileLookupRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: data_pb.AdminUserProfileLookupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.data.DataService/AdminUserProfileLookup',
        request,
        metadata || {},
        this.methodDescriptorAdminUserProfileLookup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.data.DataService/AdminUserProfileLookup',
    request,
    metadata || {},
    this.methodDescriptorAdminUserProfileLookup);
  }

  methodDescriptorGdprProfileLookup = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.data.DataService/GdprProfileLookup',
    grpcWeb.MethodType.UNARY,
    data_pb.GdprProfileLookupRequest,
    data_pb.GdprProfileLookupResponse,
    (request: data_pb.GdprProfileLookupRequest) => {
      return request.serializeBinary();
    },
    data_pb.GdprProfileLookupResponse.deserializeBinary
  );

  gdprProfileLookup(
    request: data_pb.GdprProfileLookupRequest,
    metadata?: grpcWeb.Metadata | null): Promise<data_pb.GdprProfileLookupResponse>;

  gdprProfileLookup(
    request: data_pb.GdprProfileLookupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: data_pb.GdprProfileLookupResponse) => void): grpcWeb.ClientReadableStream<data_pb.GdprProfileLookupResponse>;

  gdprProfileLookup(
    request: data_pb.GdprProfileLookupRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: data_pb.GdprProfileLookupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.data.DataService/GdprProfileLookup',
        request,
        metadata || {},
        this.methodDescriptorGdprProfileLookup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.data.DataService/GdprProfileLookup',
    request,
    metadata || {},
    this.methodDescriptorGdprProfileLookup);
  }

  methodDescriptorFetchData = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchData',
    grpcWeb.MethodType.UNARY,
    data_pb.DataRequest,
    data_pb.DataResponse,
    (request: data_pb.DataRequest) => {
      return request.serializeBinary();
    },
    data_pb.DataResponse.deserializeBinary
  );

  fetchData(
    request: data_pb.DataRequest,
    metadata?: grpcWeb.Metadata | null): Promise<data_pb.DataResponse>;

  fetchData(
    request: data_pb.DataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: data_pb.DataResponse) => void): grpcWeb.ClientReadableStream<data_pb.DataResponse>;

  fetchData(
    request: data_pb.DataRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: data_pb.DataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchData',
        request,
        metadata || {},
        this.methodDescriptorFetchData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchData',
    request,
    metadata || {},
    this.methodDescriptorFetchData);
  }

  methodDescriptorFetchXmlData = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchXmlData',
    grpcWeb.MethodType.UNARY,
    data_pb.XmlDataRequest,
    data_pb.XmlDataResponse,
    (request: data_pb.XmlDataRequest) => {
      return request.serializeBinary();
    },
    data_pb.XmlDataResponse.deserializeBinary
  );

  fetchXmlData(
    request: data_pb.XmlDataRequest,
    metadata?: grpcWeb.Metadata | null): Promise<data_pb.XmlDataResponse>;

  fetchXmlData(
    request: data_pb.XmlDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: data_pb.XmlDataResponse) => void): grpcWeb.ClientReadableStream<data_pb.XmlDataResponse>;

  fetchXmlData(
    request: data_pb.XmlDataRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: data_pb.XmlDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchXmlData',
        request,
        metadata || {},
        this.methodDescriptorFetchXmlData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchXmlData',
    request,
    metadata || {},
    this.methodDescriptorFetchXmlData);
  }

  methodDescriptorUpdateXml = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.data.DataService/UpdateXml',
    grpcWeb.MethodType.UNARY,
    data_pb.UpdateXmlRequest,
    shared_pb.EmptyResponse,
    (request: data_pb.UpdateXmlRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateXml(
    request: data_pb.UpdateXmlRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateXml(
    request: data_pb.UpdateXmlRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateXml(
    request: data_pb.UpdateXmlRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.data.DataService/UpdateXml',
        request,
        metadata || {},
        this.methodDescriptorUpdateXml,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.data.DataService/UpdateXml',
    request,
    metadata || {},
    this.methodDescriptorUpdateXml);
  }

  methodDescriptorUpdateKeypedia = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.data.DataService/UpdateKeypedia',
    grpcWeb.MethodType.UNARY,
    data_pb.UpdateKeypediaRequest,
    shared_pb.EmptyResponse,
    (request: data_pb.UpdateKeypediaRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateKeypedia(
    request: data_pb.UpdateKeypediaRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateKeypedia(
    request: data_pb.UpdateKeypediaRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateKeypedia(
    request: data_pb.UpdateKeypediaRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.data.DataService/UpdateKeypedia',
        request,
        metadata || {},
        this.methodDescriptorUpdateKeypedia,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.data.DataService/UpdateKeypedia',
    request,
    metadata || {},
    this.methodDescriptorUpdateKeypedia);
  }

  methodDescriptorFetchAuditLog = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchAuditLog',
    grpcWeb.MethodType.UNARY,
    data_pb.FetchAuditLogRequest,
    data_pb.FetchAuditLogResponse,
    (request: data_pb.FetchAuditLogRequest) => {
      return request.serializeBinary();
    },
    data_pb.FetchAuditLogResponse.deserializeBinary
  );

  fetchAuditLog(
    request: data_pb.FetchAuditLogRequest,
    metadata?: grpcWeb.Metadata | null): Promise<data_pb.FetchAuditLogResponse>;

  fetchAuditLog(
    request: data_pb.FetchAuditLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: data_pb.FetchAuditLogResponse) => void): grpcWeb.ClientReadableStream<data_pb.FetchAuditLogResponse>;

  fetchAuditLog(
    request: data_pb.FetchAuditLogRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: data_pb.FetchAuditLogResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchAuditLog',
        request,
        metadata || {},
        this.methodDescriptorFetchAuditLog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchAuditLog',
    request,
    metadata || {},
    this.methodDescriptorFetchAuditLog);
  }

  methodDescriptorFetchEntityAuditLog = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchEntityAuditLog',
    grpcWeb.MethodType.UNARY,
    data_pb.FetchEntityAuditLogRequest,
    data_pb.FetchAuditLogResponse,
    (request: data_pb.FetchEntityAuditLogRequest) => {
      return request.serializeBinary();
    },
    data_pb.FetchAuditLogResponse.deserializeBinary
  );

  fetchEntityAuditLog(
    request: data_pb.FetchEntityAuditLogRequest,
    metadata?: grpcWeb.Metadata | null): Promise<data_pb.FetchAuditLogResponse>;

  fetchEntityAuditLog(
    request: data_pb.FetchEntityAuditLogRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: data_pb.FetchAuditLogResponse) => void): grpcWeb.ClientReadableStream<data_pb.FetchAuditLogResponse>;

  fetchEntityAuditLog(
    request: data_pb.FetchEntityAuditLogRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: data_pb.FetchAuditLogResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchEntityAuditLog',
        request,
        metadata || {},
        this.methodDescriptorFetchEntityAuditLog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.data.DataService/FetchEntityAuditLog',
    request,
    metadata || {},
    this.methodDescriptorFetchEntityAuditLog);
  }

  methodDescriptorRenameSubjectId = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.data.DataService/RenameSubjectId',
    grpcWeb.MethodType.UNARY,
    data_pb.RenameSubjectIdRequest,
    shared_pb.EmptyResponse,
    (request: data_pb.RenameSubjectIdRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  renameSubjectId(
    request: data_pb.RenameSubjectIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  renameSubjectId(
    request: data_pb.RenameSubjectIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  renameSubjectId(
    request: data_pb.RenameSubjectIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.data.DataService/RenameSubjectId',
        request,
        metadata || {},
        this.methodDescriptorRenameSubjectId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.data.DataService/RenameSubjectId',
    request,
    metadata || {},
    this.methodDescriptorRenameSubjectId);
  }

  methodDescriptorRenameSubjectOperationId = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.data.DataService/RenameSubjectOperationId',
    grpcWeb.MethodType.UNARY,
    data_pb.RenameSubjectOperationIdRequest,
    shared_pb.EmptyResponse,
    (request: data_pb.RenameSubjectOperationIdRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  renameSubjectOperationId(
    request: data_pb.RenameSubjectOperationIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  renameSubjectOperationId(
    request: data_pb.RenameSubjectOperationIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  renameSubjectOperationId(
    request: data_pb.RenameSubjectOperationIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.data.DataService/RenameSubjectOperationId',
        request,
        metadata || {},
        this.methodDescriptorRenameSubjectOperationId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.data.DataService/RenameSubjectOperationId',
    request,
    metadata || {},
    this.methodDescriptorRenameSubjectOperationId);
  }

}

