import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../../../../components/Button/Button';
import { KeyCheckboxGroup } from '../../../../components/Forms/KeyCheckboxGroup';
import { KeySelect } from '../../../../components/Forms/KeySelect';
import { useAppSelector } from '../../../../redux/hooks';
import { ActionItem, ActionSummary } from '../../../../redux/slice/adapters';

interface Props {
  setActionList: (a: ActionItem[]) => void;
  actionList: ActionItem[];
}

interface Option {
  labelText: string;
  value: ActionSummary;
  description: string;
}

export const AddNewActionToActionChain: React.FC<Props> = ({
  setActionList,
  actionList
}) => {
  const actionsList = useAppSelector(state => state.adapters.actionsList) || [];
  const options: Option[] = actionsList.map(action => ({
    description: `${action.thingDescription} – ${action.actionDescription}`,
    value: action,
    labelText: `Adapter: ${action.adapterId}, Thing: ${action.thingId}, Action: ${action.actionId}`
  }));

  const { getValues } = useFormContext();

  const addNewAction = () => {
    const selectedAction = getValues('temp-form-value');
    const { sendFnr } = getValues('temp-checkbox-value');
    if (selectedAction) {
      setActionList([
        ...actionList,
        convertToAction(selectedAction.value, sendFnr)
      ]);
    }
  };

  return (
    <fieldset className={'box add-new-box'}>
      <legend>Legg til ny action:</legend>
      <KeySelect
        label='Velg ny action'
        name='temp-form-value'
        elementProps={{
          className: 'new-action-select',
          options: options,
          isMulti: false,
          placeholder: 'Tilgjengelige actions'
        }}
      />
      <KeyCheckboxGroup
        name='temp-checkbox-value'
        options={[
          {
            label: 'Send fødselsnummer',
            value: 'sendFnr'
          }
        ]}
      />
      <Button
        type='primary'
        label='Legg til action'
        onClick={addNewAction}
      ></Button>
    </fieldset>
  );
};

const convertToAction = (
  action: ActionSummary,
  sendFodselsnummer: boolean
): ActionItem => {
  return {
    adapterId: action.adapterId,
    thingId: action.thingId,
    actionId: action.actionId,
    actionUri: action.uri,
    sendFodselsnummer: sendFodselsnummer
  };
};
