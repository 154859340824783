import { formatDistanceToNow } from 'date-fns';
import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import { useDialogBox } from '../../../../components/DialogBox/useDialogBox';
import { GrafanaDashboardLink } from '../../../../components/Grafana/GrafanaDashboardLink';
import { KeyValueFields } from '../../../../components/KeyValue/KeyValueFields';
import { actionsHome } from '../../../../components/Navigation/controlpanelNavPaths';
import { HealthColor } from '../../../../proto/shared_pb';
import { getAdapterDetails } from '../../../../redux/actions/adapters';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  getGrafanaActionUrl,
  getGrafanaAdapterUrl
} from '../../../../utils/grafana';
import { CopyWrapper } from '../../../../utils/id/CopyWrapper';
import { storForbokstav } from '../../../../utils/language';
import { ControlpanelAdapterBreadcrumbs } from '../../ControlpanelBreadcrumbs';
import './ActionDetails.scss';
import { ActionLog } from './ActionLog';
import { ActionOperationsList } from './ActionOperationsList';
import { ResponseTimeCrumb } from './ResponseTimeCrumb';
import { ResponseTimeGraph } from './ResponseTimeGraph';
import { CreateMonitor } from './createMonitor';

interface MatchParams {
  adapterId: string;
  thingId: string;
  actionId: string;
}

export const ActionDetails: React.FC = () => {
  const { adapterId, thingId, actionId } = useParams<keyof MatchParams>();
  const adapter = useAppSelector(state => state.adapters.adapterDetails);
  const dispatch = useAppDispatch();

  const [CreateMonitorDialog, openCreateMonitor, closeCreateMonitor] =
    useDialogBox();

  useEffect(() => {
    if (adapterId) {
      dispatch(getAdapterDetails(adapterId));
    }
  }, [adapter.description.id, adapterId, dispatch]);
  const thing = adapter.thingsList.find(thing => {
    return thing.id === thingId;
  });
  const action = thing?.actionsList.find(action => {
    return action.id === actionId;
  });
  const hasBetteruptimeMonitor = !!action?.betterUptimeMonitorId;

  return (
    <section className='action-details-page'>
      <ControlpanelAdapterBreadcrumbs
        adapterName={adapterId!}
        currentPageName='Action-detaljer'
      />
      {!action || !thing ? (
        <div>Finner ikke action...</div>
      ) : (
        <Fragment>
          <h2>ID-er</h2>
          <p className='help'>
            De tre første id-ene her kommer fra manifestet til adapteret. Det
            som heter action-uri nedenfor er en kombiansjon av disse tre som lar
            oss identifisere en action knyttet til en ting fra et gitt adapter.
          </p>
          <KeyValueFields className='action-details'>
            <dt>Adapter</dt>
            <dd>
              <CopyWrapper copyValue={adapterId} />
              &nbsp;
              <GrafanaDashboardLink
                title='Grafana dashboard for adapter'
                href={getGrafanaAdapterUrl(adapterId!)}
              />
            </dd>
            <dt>Thing</dt>
            <dd>
              <CopyWrapper copyValue={thingId} />
            </dd>
            <dt>Action</dt>
            <dd>
              <CopyWrapper copyValue={actionId} />
              &nbsp;
              <GrafanaDashboardLink
                title='Grafana dashboard for action'
                href={getGrafanaActionUrl(adapterId!, thingId!, actionId!)}
              />
            </dd>

            <dt>Action URI</dt>
            <dd>
              <CopyWrapper copyValue={action.actionUri} />
            </dd>
          </KeyValueFields>

          <h2>Beskrivelse fra manifest</h2>
          <p className='help'>
            Disse kommer fra adapteret. Her er det veldig stor forskjell på
            hvordan de brukes og hvor nyttige de er. For å unngå
            feilkonfigurering er det veldig nyttig for oss om disse sier veldig
            klart hvilken dør som går opp når de utføres.
          </p>
          <KeyValueFields className='action-details'>
            <dt>Thing</dt>
            <dd>{thing.description}</dd>

            <dt>Action</dt>
            <dd>{action.description}</dd>

            {thing.link != '' && (
              <Fragment>
                <dt>Ekstern link</dt>
                <dd>
                  <a href={thing.link}>{thing.link}</a>
                </dd>
              </Fragment>
            )}
          </KeyValueFields>

          {(thing.manufacturer != '' || thing.deviceType != '') && (
            <Fragment>
              <h2>Hardware</h2>
              <p className='help'>
                Noen adapter støtter rapportering av produsent og type utstyr.
              </p>
              <KeyValueFields className='action-details'>
                <dt>Produsent</dt>
                <dd>{thing.manufacturer}</dd>
                <dt>Type</dt>
                <dd>{thing.deviceType}</dd>
                <dt>Firmware</dt>
                <dd>{thing.firmwareVersion}</dd>
              </KeyValueFields>
            </Fragment>
          )}

          {thing.lastSeen && (
            <Fragment>
              <h2>Sist sett</h2>
              <p className='help'>
                Noen adapter rapporterer når de sist hadde kontakt med tingen.
              </p>
              <KeyValueFields className='action-details'>
                <dt>Sist sett</dt>
                <dd>
                  {storForbokstav(
                    formatDistanceToNow(
                      thing.lastSeen.timestampEpochSeconds * 1000,
                      { addSuffix: true }
                    )
                  )}
                </dd>

                <dt>Melding</dt>
                <dd>{thing.lastSeen.explanation}</dd>
              </KeyValueFields>
            </Fragment>
          )}

          {thing.network && (
            <Fragment>
              <h2>Nettverk</h2>
              <p className='help'>
                Noen adapter rapporterer informasjon om nettverksforbindelsen
                til dørene.
              </p>
              <KeyValueFields className='action-details'>
                {thing.network.ipAddress != '' && (
                  <Fragment>
                    <dt>IP</dt>
                    <dd>
                      <CopyWrapper copyValue={thing.network.ipAddress} />
                      &nbsp;
                      <a
                        href={'https://ip-api.com/#' + thing.network.ipAddress}
                      >
                        Geo lookup
                      </a>
                    </dd>
                  </Fragment>
                )}

                {thing.network.macAddress != '' && (
                  <Fragment>
                    <dt>MAC</dt>
                    <dd>
                      <CopyWrapper copyValue={thing.network.macAddress} />
                    </dd>
                  </Fragment>
                )}

                {thing.network.rssi > 0 && (
                  <Fragment>
                    <dt>Rssi</dt>
                    <dd>{thing.network.rssi}</dd>
                  </Fragment>
                )}

                {thing.network.lastConnectedAtEpochSeconds > 0 && (
                  <Fragment>
                    <dt>Last connected</dt>
                    <dd>
                      {storForbokstav(
                        formatDistanceToNow(
                          thing.network.lastConnectedAtEpochSeconds * 1000,
                          { addSuffix: true }
                        )
                      )}
                    </dd>
                  </Fragment>
                )}

                {thing.network.lastConnectedAtEpochSeconds > 0 && (
                  <Fragment>
                    <dt>Last disconnected</dt>
                    <dd>
                      {storForbokstav(
                        formatDistanceToNow(
                          thing.network.lastDisconnectedAtEpochSeconds * 1000,
                          { addSuffix: true }
                        )
                      )}
                    </dd>
                  </Fragment>
                )}
              </KeyValueFields>
            </Fragment>
          )}

          <h2>Status</h2>
          <p className='help'>
            Det vi kaller egenerklæring under er adapterets egen formening om
            helsetilstanden til døra. Ikke alle adapter støtter dette og selv
            for de som støtter det vil det være mange tilfeller hvor adapteret
            tror at ting fungerer når det ikke gjør det og omvendt.
          </p>
          <p className='help'>
            Fordi vi ikke alltid kan stole på adapteret sier om helsetilstanden
            til forskjellige dører utleder vi også en status basert på faktisk
            bruk. Dersom vi får flere feil på rad vil denne bli rød. Dette er en
            mye bedre indikator på feil, men har ulempen ved å være en “trailing
            indicator” som ikke slår ut før noen faktisk står utenfor og ikke
            kommer seg inn.
          </p>
          <KeyValueFields className='action-details'>
            <dt>Egenerklæring</dt>
            <dd>
              <span className={getStatusColor(action.manifestHealth?.color)}>
                {action.manifestHealth?.summary}
              </span>
            </dd>

            <dt>Basert på faktisk bruk</dt>
            <dd>
              <span className={getStatusColor(action.responseHealth?.color)}>
                {action.responseHealth?.summary}
              </span>
            </dd>
          </KeyValueFields>

          {action.responseTimeSummary && (
            <Fragment>
              <h2>Responstider</h2>
              <p className='help'>
                Måler tiden det tar å utøfre en action fra backend → adapter →
                dør og tilbake.
              </p>
              <div className='action-details'>
                <div className='response-times'>
                  <ResponseTimeCrumb
                    label='Min'
                    responseTime={action.responseTimeSummary?.minMs}
                  ></ResponseTimeCrumb>
                  <ResponseTimeCrumb
                    label='Avg'
                    responseTime={action.responseTimeSummary?.avgMs}
                  ></ResponseTimeCrumb>
                  <ResponseTimeCrumb
                    label='Max'
                    responseTime={action.responseTimeSummary?.maxMs}
                  ></ResponseTimeCrumb>
                </div>

                <ResponseTimeGraph
                  width={400}
                  minMs={action.responseTimeSummary?.minMs || 0}
                  avgMs={action.responseTimeSummary?.avgMs || 0}
                  maxMs={action.responseTimeSummary?.maxMs || 0}
                />
              </div>
            </Fragment>
          )}

          <h2>Overvåking</h2>
          <p className='help'>
            Vi bruker{' '}
            <a href='https://uptime.betterstack.com/team/43532/monitors'>
              betteruptime.com
            </a>{' '}
            til å overvåke dører. Enn så lenge er det en manuell prosess å
            konfigurere dette. Her kan du generere en semi-hemmelig link. Denne
            kan legges inn i betteruptime som vil kalle denne f.eks. femte
            minutt og varsle dersom noe er galt.
          </p>
          <KeyValueFields className='action-details'>
            <dt>Monitor-link</dt>
            <dd>
              {hasBetteruptimeMonitor ? (
                <Link
                  to={
                    'https://uptime.betterstack.com/team/43532/monitors/' +
                    action.betterUptimeMonitorId
                  }
                >
                  Betteruptime
                </Link>
              ) : (
                <Button
                  type='add'
                  label='Opprett monitor'
                  onClick={openCreateMonitor}
                />
              )}
            </dd>
          </KeyValueFields>

          <CreateMonitor
            DialogBox={CreateMonitorDialog}
            actionUri={action.actionUri}
            adapterId={adapter.description.id}
            closeModal={closeCreateMonitor}
          />

          <h2>Del av actionchains</h2>
          <p className='help'>
            En action kan inngå i flere forskjellige action chains som igjen kan
            være knyttet til flere operasjoner på steder.
          </p>
          <div className='action-details'>
            {action.partOfActionChainsList.length > 0 && (
              <Fragment>
                <ul>
                  {action.partOfActionChainsList.map(actionChain => {
                    return (
                      <li key={actionChain}>
                        <Link to={actionsHome.path + '/' + actionChain}>
                          {actionChain} 🔗
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </Fragment>
            )}

            {action.partOfActionChainsList.length == 0 && (
              <p>Inngår ikke i noen action chains.</p>
            )}
          </div>

          {action.usedByOperationsList.length > 0 && (
            <ActionOperationsList
              usedByOperationsList={action.usedByOperationsList}
            />
          )}

          {action.recordsList && action.recordsList.length > 0 && (
            <ActionLog recordsList={action.recordsList} />
          )}
        </Fragment>
      )}
    </section>
  );
};

export const formatTimeInMs = (timeInMs: number | undefined) => {
  if (timeInMs === undefined) {
    return 'Ukjent';
  }
  return timeInMs + ' ms';
};
const getStatusColor = (colorNumber?: HealthColor) => {
  if (colorNumber === HealthColor.RED) {
    return 'red';
  }
  if (colorNumber === HealthColor.YELLOW) {
    return 'yellow';
  }
  if (colorNumber === HealthColor.GREEN) {
    return 'green';
  }
  return '';
};
