import React from 'react';
import { ToggleGroup } from '../../../../components/ToggleGroup/ToggleButton';
import { OpeningHours } from '../../../../redux/types/common';
import './OpeningType.scss';

interface Props {
  openingHours: OpeningHours;
  setOpeningHours: (openingHours: OpeningHours) => void;
}

export const OpeningType: React.FC<Props> = ({
  openingHours,
  setOpeningHours
}) => {
  const toggleGroupData = [
    {
      label: 'Alltid åpent',
      value: 'true',
      onClick: () => {
        setOpeningHours({
          ...openingHours,
          alwaysOpen: true
        });
      }
    },
    {
      label: 'Tidsstyrt åpningstid',
      value: 'false',
      onClick: () => {
        setOpeningHours({
          ...openingHours,
          alwaysOpen: false
        });
      }
    }
  ];
  return (
    <div className='openingType'>
      Hvilken type åpningstid?{' '}
      <ToggleGroup
        buttons={toggleGroupData}
        selectedValue={openingHours.alwaysOpen + ''}
      />
    </div>
  );
};
