import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { CreateSection } from '../../../components/CreateSection/CreateSection';
import { AdminRoleSelector } from '../../../components/Forms/AdminRoleSelector';
import { KeyId } from '../../../components/Forms/KeyId';
import { KeySelect } from '../../../components/Forms/KeySelect';
import { KeyText } from '../../../components/Forms/KeyText';
import {
  createSubject,
  CreateSubjectSchema
} from '../../../redux/actions/subjects';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { categorySelectors } from '../../../redux/slice/categories';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

interface SelectOption {
  value: string;
  labelText: string;
  description?: string;
}

export const CreatePlace = () => {
  const navigate = useNavigate();
  const suggestedCategories = useAppSelector(categorySelectors.selectAll);
  const dispatch = useAppDispatch();
  const categoryOptions: SelectOption[] = suggestedCategories.map(category => {
    return {
      value: category.id,
      labelText: category.name,
      description: category.description?.content
    };
  });
  const methods = useForm<CreateSubjectSchema>({
    resolver: zodResolver(CreateSubjectSchema)
  });
  const { handleSubmit } = methods;
  const handleSaveClick = (data: CreateSubjectSchema) =>
    dispatchAndHandleResult(dispatch, createSubject, data, () => {
      navigate('..');
    });

  return (
    <CreateSection aria-label='Nytt sted'>
      <ControlpanelBreadcrumbs currentPageName='Lag nytt sted' />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <KeyText
            name='name'
            label='Navn'
            description='Skriv inn stedets egenavn. Et eksempel på dette er SØRENGA
          (minigjenbruksstasjon).'
            elementProps={{
              placeholder: 'Skriv inn navn på sted'
            }}
          />
          <KeyId
            name='id'
            description='Huske å følge navnekonvensjonen til kategorien'
            important={true}
            label='Unik ID'
            elementProps={{}}
          />
          <KeySelect
            name='category'
            label='Kategori'
            description='Kategorien bestemmer hvor stedet havner i appen. Et eksempel på dette
          er AVFALL OG OMBRUK.'
            elementProps={{
              options: categoryOptions,
              placeholder: 'Velg en kategori'
            }}
          />
          <AdminRoleSelector
            description='Roller definerer hvem som kan se og redigere dette stedet i adminpanelet.'
            name='adminRole'
          />
          <Button label='Lag sted' type='submit' />
        </form>
      </FormProvider>
    </CreateSection>
  );
};
