import React from 'react';
import { OperationLinks } from '../../../components/operation/operationCards/OperationLinks/OperationLinks';
import { OperationInfo } from '../../../redux/slice/keychainFactoryDetails';
import { AddLockCard } from './lockCards/AddLockCard';
import { LockCard } from './lockCards/LockCard';

interface Props {
  operations: OperationInfo[];
  readonly?: boolean;
}

export const Locks: React.FC<Props> = ({ operations, readonly = false }) => {
  return (
    <React.Fragment>
      <h4 className='locks-text'>Denne tilgangslisten gir adgang til:</h4>
      <OperationLinks>
        {operations.map(operation => (
          <LockCard key={operation.operationId} operation={operation} />
        ))}
      </OperationLinks>
      {!readonly && <AddLockCard />}
    </React.Fragment>
  );
};
