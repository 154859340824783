import React from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { useAppSelector } from '../../../redux/hooks';
import { AdmissionCard } from '../../../redux/slice/admissionCards';
import './AdmissionCardDetails.scss';

interface MatchParams {
  cardId: string;
}
type ContextType = {
  admissionCard: AdmissionCard;
};

export const AdmissionCardWrapper: React.FC = () => {
  const { cardId } = useParams<keyof MatchParams>();
  const admissionCards: AdmissionCard[] = useAppSelector(
    state => state.admissionCards.cards
  );

  const admissionCard = admissionCards.find(
    admissionCard => admissionCard.id === cardId
  );

  if (admissionCards.length === 0) {
    return <LoadingSpinner />;
  }

  if (!admissionCard) {
    return <p>Adgangskort ikke funnet</p>;
  }

  return <Outlet context={{ admissionCard } satisfies ContextType} />;
};
export const useAdmissionCard = () => {
  return useOutletContext<ContextType>();
};
