import React from 'react';
import { Link } from 'react-router-dom';
import { OperationDoor } from '../../../../assets/OperationDoor';
import { OperationLight } from '../../../../assets/OperationLight';
import { OperationUnknown } from '../../../../assets/OperationUnknown';
import { subjectHome } from '../../../../components/Navigation/navPaths';
import { OperationStatus } from '../../../../components/OperationStatus/OperationStatus';
import { OperationEntity } from '../../../../proto/shared_pb';
import { OperationInfo } from '../../../../redux/slice/keychainFactoryDetails';
import './LockCard.scss';

import EntityCase = OperationEntity.EntityCase;

interface Props {
  operation: OperationInfo;
}

export const LockCard: React.FC<Props> = ({ operation }) => {
  const heading = operation.name ? (
    <h4>
      <b>Navn:</b>
      <br /> {operation.name}
    </h4>
  ) : (
    <h4>
      <b>ID:</b> <br />
      {operation.operationId}
    </h4>
  );

  return (
    <Link
      to={`${subjectHome.path}/${operation.subjectId}/${operation.operationId}`}
    >
      <div className='lock-card'>
        <div className='lock-card-icon'>
          <div className='door-image'>
            {operation.type === EntityCase.DOOR ? (
              <OperationDoor />
            ) : operation.type === EntityCase.LIGHT ? (
              <OperationLight />
            ) : (
              <OperationUnknown />
            )}
          </div>
        </div>
        <div className='lock-card-content'>
          {heading}
          <OperationStatus
            type='dot'
            subjectId={operation.subjectId}
            operationId={operation.operationId}
          />
        </div>
      </div>
    </Link>
  );
};
