export const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const numA = Number(versionsA.shift());

    const numB = Number(versionsB.shift());
    if (numA === numB) continue;
    return numA > numB || isNaN(numB);
  }
  return false;
};
