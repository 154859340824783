import React, { useState } from 'react';
import { Term } from '../../../../redux/slice/terms';
import { EditInfoCard } from './EditInfoCard';
import { InfoCard } from './InfoCard';

interface Props {
  term: Term;
}

export const InfoContainer: React.FC<Props> = ({ term }) => {
  const [edit, setEdit] = useState(false);

  return (
    <>
      {edit ? (
        <EditInfoCard term={term} setEdit={setEdit} />
      ) : (
        <InfoCard
          information={term.currentText ? term.currentText.content : undefined}
          setEdit={setEdit}
        />
      )}
    </>
  );
};
