import React from 'react';

export const Super = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='136'
    height='136'
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12166'
      data-name='Group 12166'
      transform='translate(-1256 -231)'
    >
      <path
        id='Path_11280'
        data-name='Path 11280'
        d='M68,0A68,68,0,1,1,0,68,68,68,0,0,1,68,0Z'
        transform='translate(1256 231)'
        fill='#f9ddab'
      />
      <g
        id='Group_1698'
        data-name='Group 1698'
        transform='translate(515.133 -197)'
      >
        <g
          id='Group_1697'
          data-name='Group 1697'
          transform='translate(772.867 460)'
        >
          <g
            id='Ellipse_590'
            data-name='Ellipse 590'
            fill='none'
            stroke='#292858'
            strokeWidth='3'
          >
            <circle cx='36' cy='36' r='36' stroke='none' />
            <circle cx='36' cy='36' r='34.5' fill='none' />
          </g>
        </g>
        <path
          id='S'
          d='M1.18,1.52c0,4.48,3,7.64,8.24,8.36l5.76.8c2.28.32,3.12,1.44,3.12,3.08,0,2.28-2.32,3.68-5.52,3.68A8.472,8.472,0,0,1,5.5,13.36L.46,17.08c2.88,4.2,7.32,5.96,12.32,5.96,7.56,0,12.08-4.08,12.08-9.44,0-5-3.76-8.12-9.44-8.84L10.94,4.2C8.54,3.88,7.5,2.72,7.5,1.12,7.5-.84,9.42-2,12.46-2a8.434,8.434,0,0,1,6.88,3.36l4.84-3.52C21.3-6.2,17.1-7.52,12.5-7.52,6.34-7.52,1.18-3.96,1.18,1.52Z'
          transform='translate(796.208 488.24)'
          fill='#292858'
        />
      </g>
    </g>
  </svg>
);
