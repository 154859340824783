import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useJsApiLoader';

export const getEnv = () => {
  const host = window.location.host;
  if (host.includes('citykey-testing')) {
    return 'test';
  } else if (host.includes('beta-')) {
    return 'beta';
  } else if (process.env.REACT_APP_ENV === 'prod') {
    return 'prod';
  } else if (host.includes('citykey-admin')) {
    return 'local-dev-env';
  } else if (host.includes('admin') || host.includes('keystudio')) {
    return 'prod';
  } else if (host.includes('.local')) {
    return 'local-mdns';
  }
  return 'local';
};
export type Env = ReturnType<typeof getEnv>;

const getGrpcWebUrl = () => {
  const env = getEnv();

  if (env === 'prod' || env === 'beta') {
    return 'https://grpc-web.prod.citykey.oslo.systems';
  } else if (env === 'local-mdns') {
    const { hostname } = document.location;
    if (document.URL.substring(0, 5) === 'http:') {
      return `http://${hostname}:10180`;
    } else {
      return `https://${hostname}:10181`;
    }
  } else {
    if (document.URL.substring(0, 5) === 'http:') {
      return 'http://grpc-proxy:10180';
    } else {
      return 'https://grpc-proxy:10181';
    }
  }
};

const getGrafanaUrl = () => {
  const env = getEnv();

  if (env === 'prod' || env === 'beta') {
    return 'https://grafana.oslonøkkelen.no';
  } else {
    return 'http://grafana:10110';
  }
};

const getApiUrl = () => {
  const env = getEnv();

  if (env === 'prod' || env === 'beta') {
    return 'https://api.prod.citykey.oslo.systems';
  } else {
    return 'http://citykey-backend:10000';
  }
};

export const googleMapOptions: UseLoadScriptOptions = {
  googleMapsApiKey: 'AIzaSyBKBZgE2rh6lC9Sp5GuUMFM9OuAEUDYIkU',
  libraries: ['places'],
  nonce: myNonce
};

export const osloBounds = {
  south: 59.80297,
  west: 10.59975,
  north: 60.00903,
  east: 10.96676
};

export const GRPC_WEB_URL = getGrpcWebUrl();
export const GRAFANA_URL = getGrafanaUrl();
export const API_URL = getApiUrl();
export const ENV = getEnv();
export const HOSTNAME = document.location.hostname;
export const REDUX_LOG_ENABLED = false;
