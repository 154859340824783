import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearPopup } from '../../redux/slice/popup';
import './Popup.scss';

export const Popup = () => {
  const popup = useAppSelector(state => state.popup.popup);
  const [countdown, setCountdown] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (popup && !popup.error && !countdown) {
      setCountdown(true);
      setTimeout(() => {
        dispatch(clearPopup());
        setCountdown(false);
      }, 5000);
    }
  }, [popup, countdown, dispatch]);

  if (popup && !popup.error) {
    return (
      <div className='popup-container info'>
        <div className='column'>
          <span>{popup.message}</span>
        </div>
        <button
          className='cross-container'
          onClick={() => dispatch(clearPopup())}
        >
          <div className='line' style={{ transform: 'rotate(45deg)' }} />
          <div className='line' style={{ transform: 'rotate(135deg)' }} />
        </button>
      </div>
    );
  } else {
    return null;
  }
};
