import React from 'react';
import { Breadcrumbs } from '../../../../components/Navigation/breadcrumbs';
import { termsHome } from '../../../../components/Navigation/navPaths';
import { Term } from '../../../../redux/slice/terms';

interface Props {
  term: Term;
}

export const TermBreadcrumbs: React.FC<Props> = ({ term }) => {
  return (
    <Breadcrumbs
      breadcrumbs={[
        termsHome,
        {
          name: term.title
        }
      ]}
    />
  );
};
