import React from 'react';

export const LoginMethodApp = () => (
  <svg
    width='26'
    height='30'
    viewBox='0 0 11 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Isolation_Mode' clipPath='url(#clip0_3332_10085)'>
      <path
        id='Vector'
        d='M8.84781 8.88909C8.84781 7.02242 7.34733 5.5078 5.50069 5.5078C3.65288 5.5078 2.15356 7.02359 2.15356 8.88909C2.15356 10.7558 3.65404 12.2704 5.50069 12.2704C7.34849 12.2704 8.84781 10.7546 8.84781 8.88909ZM6.60057 21.1113H11.0013V23.3335H8.80034V23.3406C8.80034 23.3406 8.79571 23.3406 8.79223 23.3406C8.18788 23.3406 7.69582 23.8353 7.69582 24.4446C7.69582 25.054 8.18672 25.5487 8.79223 25.5487C8.79455 25.5487 8.79687 25.5487 8.80034 25.5487V25.5569H11.0013V27.7791H6.60057V30.0014H4.4008V14.3335C2.15125 13.8692 0.409958 12.0353 0.0626265 9.72184C-0.393536 6.68792 1.67193 3.85634 4.67519 3.39552C7.67846 2.93587 10.4814 5.02125 10.9376 8.05517C11.3787 10.9955 9.45332 13.7464 6.60057 14.3324V21.1113Z'
        fill='#292858'
      />
      <path
        id='Vector_2'
        d='M4.65291 1.1577V0.0407398C5.21674 -0.014231 5.78405 -0.014231 6.34673 0.0407398V1.1577C6.55744 1.18109 6.767 1.21384 6.9754 1.25477L7.18611 0.164716C7.74185 0.274658 8.286 0.438401 8.81047 0.653605L8.39715 1.68284C8.62638 1.77641 8.85099 1.88168 9.07097 1.99863L9.58039 1.01384C10.0829 1.27934 10.5575 1.59279 10.9998 1.95068L9.62554 3.68518C7.21506 1.73548 3.78805 1.73548 1.37757 3.68518L0.000976562 1.94951C0.443246 1.59162 0.917933 1.27817 1.41925 1.01384L1.92983 1.99863C2.14865 1.88285 2.37441 1.77758 2.60365 1.68284L2.19033 0.653605C2.7148 0.438401 3.25895 0.274658 3.81468 0.164716L4.0254 1.25477C4.23379 1.21384 4.44335 1.18109 4.65407 1.1577H4.65291Z'
        fill='#292858'
      />
    </g>
    <defs>
      <clipPath id='clip0_3332_10085'>
        <rect width='11' height='30' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
