import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Button } from '../../../components/Button/Button';
import { DeleteAndConfirm } from '../../../components/DeleteAndConfirm/DeleteAndConfirm';
import {
  extractTextFromElement,
  getSelectProps
} from '../../../components/Forms/KeySelect';
import * as shared_pb from '../../../proto/shared_pb';
import { ManagedAsset } from '../../../proto/third-party-systems_pb';
import {
  addOrUpdateManagedAsset,
  removeManagedAsset
} from '../../../redux/actions/thirdPartySystems';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { subjectSelectors } from '../../../redux/slice/subjects';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';
import { createUrlSafeId } from '../../../utils/urlSafeId';
import './AssetsTable.scss';

interface Props {
  managedassetsList: ManagedAsset.AsObject[];
  id: string;
  version: number;
}

interface OptionFormat {
  label: string;
  value: shared_pb.FullOperationId.AsObject;
}

interface DataFormat {
  assetId: string;
  readonly: boolean;
  operationList: OptionFormat[];
  isDirty: boolean;
}
export const AssetsTable: React.FC<Props> = ({
  managedassetsList,
  id,
  version
}) => {
  const subjects = useAppSelector(subjectSelectors.selectAll);
  const dispatch = useAppDispatch();

  const options: OptionFormat[] = useMemo(() => {
    return subjects.flatMap(subject => {
      return subject.operationsList.map(operation => {
        return {
          label: subject.name + ': ' + operation.name,
          value: { operationId: operation.id, subjectId: subject.id }
        };
      });
    });
  }, [subjects]);

  const [data, setData] = React.useState<DataFormat[]>([]);

  useEffect(() => {
    setData(mapToData(managedassetsList, options, data));
  }, [managedassetsList.length, options.length]);

  const addRow = () => {
    setData([
      ...data,
      { assetId: '', operationList: [], readonly: false, isDirty: true }
    ]);
  };

  const deleteRow = (index: number) => {
    const asset = data[index];
    if (!asset.readonly) {
      setData(data.filter((_, i) => i !== index));
    } else {
      dispatch(removeManagedAsset({ version, id, assetId: asset.assetId }));
    }
  };

  const editRowText = (value: string, index: number) => {
    const list = [...data];
    if (!list[index].readonly) {
      list[index].assetId = createUrlSafeId(value, 20);
      setData(list);
    }
  };

  const editRowOperation = (
    values: OptionFormat[],
    index: number,
    isDirty = true
  ) => {
    const list = [...data];
    list[index].operationList = values;
    list[index].isDirty = isDirty;
    setData(list);
  };

  //TODO check for duplicate IDs
  const saveRow = (row: DataFormat, index: number) =>
    dispatchAndHandleResult(
      dispatch,
      addOrUpdateManagedAsset,
      {
        version,
        id,
        updatedManagedAsset: mapToManagedAsset(row)
      },
      () => {
        editRowOperation(row.operationList, index, false);
      }
    );

  if (options.length === 0) {
    return <div>Laster...</div>;
  }

  return (
    <div className='assets'>
      <h2>Ressurser</h2>
      <div className='assets-table'>
        <div className='header-id'>Ekstern ID</div>
        <div className='header-operations'>Gir tilgang til:</div>
        {data.map((row, index) => {
          const assetIdField = row.readonly ? (
            <CopyWrapper copyValue={row.assetId} />
          ) : (
            <div className='form-element inline'>
              <input
                type='text'
                data-testid='asset-id'
                {...{
                  value: row.assetId,
                  onChange: event => {
                    editRowText(event.currentTarget.value, index);
                  }
                }}
              />
            </div>
            // <FormElement
            //   type='text'
            //   data-testid='asset-id'
            //   inline
            //   elementProps={{
            //     value: row.assetId,
            //     onChange: event => {
            //       editRowText(event.currentTarget.value, index);
            //     }
            //   }}
            // />
          );
          //TODO reintrodue FormElement (KeySelect) and remove Select
          return (
            <React.Fragment key={row.readonly ? row.assetId : index}>
              <div className='action-buttons'>
                <DeleteAndConfirm
                  label='Fjern'
                  buttonType='remove'
                  size='small'
                  message={`Er du sikker på at du vil fjerne ${row.assetId}?`}
                  deleteAction={() => {
                    deleteRow(index);
                  }}
                />
              </div>
              <div className='asset-id'>{assetIdField}</div>
              <div className='asset-operations'>
                <div className='form-element inline'>
                  <Select
                    id={id}
                    {...getSelectProps({
                      options: options,
                      isMulti: true,
                      defaultValue: row.operationList,
                      placeholder: 'Velg operasjoner',
                      onChange: values => {
                        editRowOperation([...values], index);
                      }
                    })}
                    unstyled
                    filterOption={(option, inputValue) => {
                      const textContent = extractTextFromElement(
                        option.label
                      ).toLowerCase();
                      return textContent.includes(inputValue.toLowerCase());
                    }}
                  />
                </div>
                {/*<FormElement*/}
                {/*  type='select'*/}
                {/*  // name='operations'*/}
                {/*  inline*/}
                {/*  elementProps={{*/}
                {/*    options: options,*/}
                {/*    isMulti: true,*/}
                {/*    defaultValue: row.operationList,*/}
                {/*    placeholder: 'Velg operasjoner',*/}
                {/*    onChange: values => {*/}
                {/*      editRowOperation([...values], index);*/}
                {/*    }*/}
                {/*  }}*/}
                {/*/>*/}
              </div>
              {row.isDirty && (
                <Button
                  className='asset-save'
                  label='Lagre'
                  type='primary'
                  onClick={() => saveRow(row, index)}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
      <Button label='Legg til' type='add' size='small' onClick={addRow} />
    </div>
  );
};

const mapToData = (
  managedassetsList: Props['managedassetsList'],
  options: OptionFormat[],
  existingData: DataFormat[]
): DataFormat[] => {
  if (options.length === 0) {
    return [];
  }
  const unsorted = managedassetsList.map(managedAsset => {
    const selectedOptions = managedAsset.assetsList
      .map(asset => {
        return options.find(
          option =>
            option.value.operationId === asset.operationId?.operationId &&
            option.value.subjectId === asset.operationId?.subjectId
        );
      })
      .filter(option => option !== undefined) as OptionFormat[];

    const isDirty =
      existingData.find(data => data.assetId === managedAsset.id)?.isDirty ||
      false;

    return {
      assetId: managedAsset.id,
      operationList: selectedOptions,
      isDirty: isDirty,
      readonly: true
    };
  });
  return unsorted.sort((a, b) => {
    if (a.assetId < b.assetId) {
      return -1;
    } else if (a.assetId > b.assetId) {
      return 1;
    }
    return 0;
  });
};

const mapToManagedAsset = (data: DataFormat): ManagedAsset.AsObject => {
  return {
    id: data.assetId,
    assetsList: data.operationList.map(operation => {
      return {
        operationId: {
          operationId: operation.value.operationId,
          subjectId: operation.value.subjectId
        }
      };
    })
  };
};
