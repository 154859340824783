import React from 'react';
import { KeySelect } from '../../../components/Forms/KeySelect';
import { Term } from '../../../proto/term_pb';

import Type = Term.Type;

interface Props {
  selectedType: Type;
  setSelectedType: (selectedType: Type) => void;
}

export interface SelectOption {
  label: string;
  value: Term.Type;
}

export const TypeSelector: React.FC<Props> = ({
  selectedType,
  setSelectedType
}) => {
  const options: SelectOption[] = [
    {
      value: Type.HJEMMEL,
      label: 'Hjemmel'
    },
    {
      value: Type.VILKAAR,
      label: 'Vilkår'
    },
    {
      value: Type.SAMTYKKE,
      label: 'Samtykke'
    }
  ];

  return (
    <KeySelect
      name='type'
      label='Type'
      elementProps={{
        options: options,
        isMulti: false,
        placeholder: 'Velg type',
        value: options.find(option => option.value === selectedType),
        onChange: option => {
          option && setSelectedType(option.value);
        }
      }}
    />
  );
};
