import React from 'react';
import { BookedKeychain } from '../../assets/BookedKeychain';
import { ManualGrantKeychain } from '../../assets/ManualGrantKeychain';
import { OIDCKeychain } from '../../assets/OIDCKeychain';
import { PublicKeychain } from '../../assets/PublicKeychain';
import { UnknownSvg } from '../../assets/UnknownSvg';
import {
  manualGrantKeychainHome,
  keychainHome,
  oidcKeychainHome,
  pushKeychainHome,
  publicKeychainHome
} from '../../components/Navigation/navPaths';
import { KeychainCommon } from '../../redux/slice/operations';
import { FactoryType } from '../../redux/types/common';

export const getKeychainFactoryLink = (factory: KeychainCommon) => {
  const factoryType = getTypeFromkeychainFactoryUri(factory.keychainFactoryUri);
  const factoryId = getIdFromkeychainFactoryUri(factory.keychainFactoryUri);

  const parsedId = parsekeychainFactoryUri(factory.keychainFactoryUri);
  switch (factory.type) {
    case 'manual-grant':
      return `${manualGrantKeychainHome.path}/${factoryId}`;
    case 'push':
      return `${pushKeychainHome.path}/${parsedId.systemId}_${parsedId.id}`;
    case 'oidc': {
      return `${oidcKeychainHome.path}/${factoryId}`;
    }
    default:
      if (!factoryId || !factoryType) {
        console.error('Found no link to keychainFactory', factory);
        return '';
      }
      return `${keychainHome.path}/${factoryType}/${factoryId}`;
  }
};

export const getKeychainFactoryIcon = (factory: KeychainCommon) => {
  switch (factory.type) {
    case 'manual-grant':
      return (
        <span className='center-image'>
          <ManualGrantKeychain />
        </span>
      );
    case 'push':
      return (
        <span className='center-image'>
          <BookedKeychain />
        </span>
      );
    case 'public':
      return (
        <span className='center-image'>
          <PublicKeychain />
        </span>
      );
    case 'oidc':
      return (
        <span className='center-image'>
          <OIDCKeychain />
        </span>
      );
    default:
      return (
        <span className='center-image'>
          <UnknownSvg />
        </span>
      );
  }
};

export const parsekeychainFactoryUri = (keychainFactoryUri: string) => {
  const match = keychainFactoryUri.match(
    /citykey:\/\/factories\/([^/]+)\/([^/]+)\/?(.*)/
  );
  if (match) {
    const [, type, systemId, id] = match;
    return { type, id: id ? id : systemId, systemId: id ? systemId : '' };
  }
  return { type: '', id: '', systemId: '' };
};

export const getIdFromkeychainFactoryUri = (keychainFactoryUri: string) => {
  const match = keychainFactoryUri.match(/citykey:\/\/factories\/[^/]+\/(.+)/);
  return match ? match[1] : '';
};
export const getTypeFromkeychainFactoryUri = (keychainFactoryUri: string) => {
  const match = keychainFactoryUri.match(/citykey:\/\/factories\/([^/]+)\/.+/);
  return match ? match[1] : '';
};

export const getShortkeychainFactoryId = (keychainFactoryUri: string) => {
  return keychainFactoryUri.split('citykey://factories/')[1];
};

export const getFullkeychainFactoryUri = (type: FactoryType, id: string) => {
  switch (type) {
    case 'public':
      return `citykey://factories/public/${id}`;
    case 'manual-grant':
      return `citykey://factories/manual-code/${id}`;
    case 'push':
      return `citykey://factories/push/${id}`;
    case 'oidc':
      return `citykey://factories/oidc/${id}`;
    default:
      console.error('Unknown keychainfactory type', type);
      return `citykey://factories/${type}/${id}`;
  }
};

export const getFactoryTypeFromPathType = (pathType = ''): FactoryType => {
  const factories: { path: string; type: FactoryType }[] = [
    { path: pushKeychainHome.path, type: 'push' },
    { path: manualGrantKeychainHome.path, type: 'manual-grant' },
    { path: oidcKeychainHome.path, type: 'oidc' },
    { path: publicKeychainHome.path, type: 'public' }
  ];

  const foundFactory = factories.find(factory =>
    factory.path.endsWith(pathType)
  );

  return foundFactory ? foundFactory.type : 'unknown';
};
