import React, { PropsWithChildren } from 'react';
import './KeyValueFields.scss';

type Props = {
  className?: string;
};
export const KeyValueFields: React.FC<PropsWithChildren<Props>> = ({
  children,
  className = ''
}) => {
  return <dl className={`key-value-fields ${className}`}>{children}</dl>;
};
