import { StatusCode } from 'grpc-web';
import { PhoneNumber } from '../../proto/shared_pb';
import {
  FindNameFromContactInfoRequest,
  FindNameFromContactInfoResponse
} from '../../proto/utils_pb';
import {
  getGrpcMetadata,
  getUtilsClient,
  handleGrpcError,
  isGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';

export const findProfileFromPhoneNumber = createAppAsyncThunk<
  FindNameFromContactInfoResponse.AsObject,
  string
>('access/findProfileFromPhoneNumber', async (phonenumber, thunkAPI) => {
  try {
    const searchObject = new FindNameFromContactInfoRequest().setPhoneNumber(
      new PhoneNumber().setCountryCode('47').setNumber(phonenumber)
    );
    const response = await getUtilsClient().findNameFromContactInfo(
      searchObject,
      await getGrpcMetadata()
    );
    return response.toObject();
  } catch (e) {
    if (isGrpcError(e) && e.code === StatusCode.NOT_FOUND) {
      return thunkAPI.rejectWithValue('IKKE FUNNET');
    } else {
      return handleGrpcError(e, thunkAPI);
    }
  }
});
