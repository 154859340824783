import React from 'react';
import { OpeningHours, SimpleDayRule } from '../../../../../redux/types/common';
import { EditSimpleDayRule } from './editSimpleDayRule/EditSimpleDayRule';

interface Props {
  formattedSimpleDayRules: SimpleDayRule[];
  openingHours: OpeningHours;
  setOpeningHours: (openingHours: OpeningHours) => void;
}

export const SimpleDayRuleList: React.FC<Props> = ({
  formattedSimpleDayRules,
  openingHours,
  setOpeningHours
}) => (
  <ul className='opening-hours'>
    {formattedSimpleDayRules.map(simpleDayRule => (
      <EditSimpleDayRule
        key={simpleDayRule.day}
        openingHours={openingHours}
        simpleDayRule={simpleDayRule}
        setOpeningHours={setOpeningHours}
      />
    ))}
  </ul>
);
