import React from 'react';

export const StatsSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='23.667'
    height='24'
    viewBox='0 0 23.667 24'
  >
    <g
      id='Group_12273'
      data-name='Group 12273'
      transform='translate(0 0)'
      opacity='0.48'
    >
      <g id='Group_12266' data-name='Group 12266' transform='translate(0 0)'>
        <path
          id='noun-statistics-4638766'
          d='M106.426,35V59h23.667V57.018H108.664V35Zm14.3,8.024V55.213h2.219V43.028Zm-9.773,4.02v8.135h2.2V47.048Zm4.992-1.719v9.883h2.26V45.33Z'
          transform='translate(-106.426 -35.004)'
          fillRule='evenodd'
        />
        <path
          id='Path_3037'
          data-name='Path 3037'
          d='M-.266.3H2.08V16.074H-.266Z'
          transform='translate(19.25 4.251)'
        />
      </g>
    </g>
  </svg>
);
