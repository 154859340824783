import React from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { useAppSelector } from '../../redux/hooks';
import { Subject, subjectSelectors } from '../../redux/slice/subjects';
import './Subject.scss';
import { SubjectBreadcrumbs } from './SubjectBreadcrumbs';

interface MatchParams {
  subjectId: string;
}
type ContextType = {
  subject: Subject;
};

export const SubjectWrapper: React.FC = () => {
  const { subjectId = '' } = useParams<keyof MatchParams>();
  const subject = useAppSelector(state =>
    subjectSelectors.selectById(state, subjectId)
  );
  const isLoading = useAppSelector(state => state.subjects.isLoading);

  if (subject) {
    return <Outlet context={{ subject } satisfies ContextType} />;
  } else {
    const dummySubject = {
      id: subjectId,
      name: subjectId
    };
    return (
      <section>
        <SubjectBreadcrumbs subject={dummySubject} />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <p>Finner ikke stedet med ID: {subjectId}</p>
        )}
      </section>
    );
  }
};

export const useSubject = () => {
  return useOutletContext<ContextType>();
};
