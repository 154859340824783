import { ImagePresignedUrlResponse } from '../../../proto/utils_pb';
import { store } from '../../../redux';
import {
  requestImagePresignedUrl,
  updateImage,
  uploadImage
} from '../../../redux/actions/images';

interface SaveProps {
  error: string;
  image: File | null;
  setLoading: (loading: boolean) => void;
  closeModal: () => void;
}

interface SubjectProps extends SaveProps {
  subjectId: string;
}

interface OperationProps extends SubjectProps {
  operationId: string;
}

interface KeychainFactoryProps extends SaveProps {
  keychainFactoryUri: string;
}

type Props = SubjectProps | OperationProps | KeychainFactoryProps;

export const handleImageSave = async ({
  error,
  image,
  setLoading,
  closeModal,
  ...ids
}: Props) => {
  if (error === '') {
    try {
      const presignedUrl: ImagePresignedUrlResponse.AsObject = await store
        .dispatch(requestImagePresignedUrl({ ...ids }))
        .unwrap();
      if (image) {
        setLoading(true);

        const response = await store
          .dispatch(uploadImage({ presignedUrl, image }))
          .unwrap();
        if (response === 200) {
          await store.dispatch(
            updateImage({
              ...ids,
              uri: presignedUrl.publicUri,
              remove: false
            })
          );
        }
        setLoading(false);
      } else {
        await store.dispatch(
          updateImage({
            ...ids,
            uri: presignedUrl.publicUri,
            remove: true
          })
        );
      }
      closeModal();
    } catch (error) {
      setLoading(false);
      closeModal();
    }
  }
};
