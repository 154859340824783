import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setSideMenuOpen } from '../../redux/slice/ui';
import { User } from '../../redux/slice/user';
import { Navbar } from '../Navigation/Navbar';
import { Logo } from './Logo';
import { LogoutSection } from './LogoutSection';
import { ProfileInformation } from './ProfileInformation';
import './SideMenu.scss';

interface Props {
  user: User;
}
export const SideMenu: React.FC<Props> = ({ user }) => {
  const isOpen = useAppSelector(state => state.ui.sideMenuOpen);
  const dispatch = useAppDispatch();
  const location = useLocation();
  React.useEffect(() => {
    dispatch(setSideMenuOpen(false));
  }, [location.pathname]);
  return (
    <aside className={classNames('side-menu', { 'menu-open': isOpen })}>
      <Logo user={user} />
      <ProfileInformation user={user} />
      <Navbar />
      <LogoutSection />
      <div className='side-menu-line' />
    </aside>
  );
};
