import React from 'react';
import { useParams } from 'react-router-dom';
import { PreContainer } from '../../../components/code/PreContainer';
import { FactoryType } from '../../../redux/types/common';
import { useKeychainFactorySummaryOrDetails } from '../../../utils/keychainFactory/useKeychainFactory';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { AdminRole } from './AdminRole';
import { KeychainActionMenu } from './KeychainActionMenu';

interface MatchParams {
  keychainId: string;
}
export const AdminKeychain: React.FC<{ type: FactoryType }> = ({ type }) => {
  const { keychainId = '' } = useParams<keyof MatchParams>();
  const keychain = useKeychainFactorySummaryOrDetails(keychainId, type);

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs currentPageName={keychainId} />
      {keychain && (
        <div className='actionmenu-ribbon'>
          <AdminRole
            roleId={keychain.ownerRole}
            keychainFactoryUri={keychain.keychainFactoryUri}
          />
          {keychain.isDetails && (
            <KeychainActionMenu keychainFactory={keychain} />
          )}
        </div>
      )}
      <h2>KeychainFactory JSON-data </h2>
      <PreContainer obj={keychain} />
    </React.Fragment>
  );
};
