import React from 'react';

export const WarningCircle = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='52'
    height='52'
    viewBox='0 0 35 35'
  >
    <g
      id='Group_1798'
      data-name='Group 1798'
      transform='translate(-0.401 -0.401)'
    >
      <circle
        id='Ellipse_522'
        data-name='Ellipse 522'
        cx='17.5'
        cy='17.5'
        r='17.5'
        transform='translate(0.401 0.401)'
        fill='#ff8274'
      />
      <path
        id='Path_3073'
        data-name='Path 3073'
        d='M14.593,7.562l.443,13.28h2.435l.442-13.28Z'
        transform='translate(1.619 0.35)'
        fill='#2a2859'
      />
      <path
        id='Path_3074'
        data-name='Path 3074'
        d='M14.124,22.9a2.213,2.213,0,1,0,2.213-2.213A2.177,2.177,0,0,0,14.124,22.9Z'
        transform='translate(1.534 2.719)'
        fill='#2a2859'
      />
    </g>
  </svg>
);
