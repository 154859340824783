import React from 'react';
import { QrSvg } from '../../../assets/QrSvg';
import { API_URL } from '../../../constants';
import { OperationInfoCard } from '../operationCards/OperationInfoCard';

interface Props {
  subjectId: string;
  operationId: string;
}

export const QRCard: React.FC<Props> = ({ subjectId, operationId }) => {
  return (
    <OperationInfoCard
      icon={<QrSvg />}
      header='Utskrivbar QR-kode'
      text='Klikk for å få dørens QR-kode'
      link={`${API_URL}/web/operation-link/${subjectId}/${operationId}`}
      linkTarget='_blank'
    />
  );
};
