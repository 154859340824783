import React from 'react';

export const PvdSvg = () => (
  <svg
    width='99'
    height='62'
    viewBox='0 0 99 62'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='icon n&#195;&#166;r'>
      <path
        id='Vector'
        d='M60.4942 23.66C67.0142 23.66 72.3242 18.35 72.3242 11.83C72.3242 5.31 67.0242 0 60.4942 0C53.9642 0 48.6642 5.31 48.6642 11.83C48.6642 18.35 53.9642 23.66 60.4942 23.66ZM60.4942 2.27C65.7642 2.27 70.0542 6.56 70.0542 11.83C70.0542 17.1 65.7642 21.39 60.4942 21.39C55.2242 21.39 50.9342 17.1 50.9342 11.83C50.9342 6.56 55.2242 2.27 60.4942 2.27Z'
        fill='#2A2859'
      />
      <path
        id='Vector_2'
        d='M97.2442 51.48L88.9542 36.08C88.3642 34.99 87.3442 34.17 86.1442 33.83L69.4042 29.08L42.5542 21.75L32.7842 18.67C32.1842 18.48 31.6842 18.06 31.3942 17.5L24.8842 4.76C24.0242 3.08 22.5742 1.84 20.7742 1.26C18.9842 0.679997 17.0742 0.829997 15.3942 1.69L15.1842 1.8C11.7242 3.57 10.3442 7.82 12.1342 11.31L22.0042 29.39C22.5942 30.47 23.6142 31.29 24.8042 31.62L37.5642 35.23L29.9842 57.2C29.7842 57.79 30.0942 58.44 30.6842 58.64C31.2742 58.85 31.9242 58.53 32.1242 57.94L40.0942 34.84C40.1942 34.55 40.1742 34.22 40.0242 33.95C39.8742 33.68 39.6242 33.47 39.3242 33.38L25.4042 29.44C24.8042 29.27 24.2842 28.86 23.9842 28.31L14.1242 10.25C12.9242 7.9 13.8542 5.02 16.2042 3.82L16.4142 3.71C17.5542 3.13 18.8442 3.03 20.0642 3.42C21.2742 3.81 22.2642 4.66 22.8542 5.79L29.3642 18.53C29.9242 19.62 30.9142 20.46 32.0842 20.83L41.8942 23.92L68.7842 31.26L85.5142 36C86.1242 36.17 86.6442 36.59 86.9442 37.15L95.2342 52.56C95.8442 53.68 95.9742 54.97 95.6142 56.2C95.2542 57.43 94.4342 58.43 93.3142 59.04L93.1142 59.15C91.9942 59.76 90.6942 59.89 89.4742 59.53C88.2542 59.17 87.2442 58.35 86.6342 57.22L80.8042 46.45C80.2142 45.37 79.2442 44.57 78.0542 44.22L66.2442 40.71C65.9542 40.62 65.6342 40.66 65.3742 40.81C65.1042 40.96 64.9142 41.21 64.8342 41.5L60.3542 57.93C60.1842 58.53 60.5442 59.16 61.1442 59.32C61.7442 59.48 62.3742 59.13 62.5342 58.53L66.7142 43.22L77.4142 46.4C78.0142 46.58 78.5142 46.98 78.8142 47.53L84.6442 58.3C85.5442 59.96 87.0242 61.16 88.8342 61.7C89.4942 61.9 90.1742 62 90.8542 62C92.0142 62 93.1542 61.71 94.2042 61.15L94.4042 61.04C96.0642 60.14 97.2642 58.65 97.8042 56.85C98.3442 55.05 98.1442 53.14 97.2442 51.49V51.48Z'
        fill='#2A2859'
      />
      <path
        id='Vector_3'
        d='M29.9342 41.1C29.3542 40.16 28.4342 39.51 27.3542 39.26C26.2742 39.01 25.1642 39.19 24.2342 39.78L22.1842 41.06C21.6242 40.29 20.8142 39.73 19.8742 39.47C18.8342 39.18 17.7842 39.3 16.9742 39.81L12.0842 42.86L9.24424 41.5C9.16424 41.46 9.08424 41.46 9.00424 41.44C8.93424 41.39 8.88424 41.33 8.80424 41.29L6.61424 40.24C5.50424 39.71 4.24424 39.64 3.08423 40.05C1.92423 40.46 0.984236 41.3 0.454236 42.41C-0.0757642 43.52 -0.145765 44.78 0.264235 45.94C0.674235 47.1 1.51424 48.04 2.62424 48.57L4.39424 49.42C3.33424 53.5 5.27424 57.9 9.21424 59.79C10.4642 60.39 11.7842 60.67 13.0942 60.67C16.4542 60.67 19.6742 58.78 21.2242 55.56L23.9742 49.82C23.9742 49.82 23.9942 49.74 24.0042 49.7L28.6342 46.82C29.5742 46.24 30.2242 45.32 30.4742 44.24C30.7242 43.16 30.5442 42.05 29.9542 41.12L29.9342 41.1ZM2.38424 45.17C2.17424 44.58 2.21424 43.94 2.48424 43.37C2.75424 42.8 3.23424 42.38 3.82424 42.17C4.41424 41.96 5.05424 42 5.62424 42.27L7.24423 43.04L5.21424 47.28L3.59424 46.5C3.02424 46.23 2.60424 45.75 2.39424 45.16L2.38424 45.17ZM19.1542 54.57C17.5442 57.92 13.5142 59.33 10.1742 57.73C6.82424 56.13 5.41424 52.1 7.01424 48.75L9.27424 44.03L11.4242 45.06C11.4742 45.1 11.5242 45.14 11.5942 45.18L21.2642 50.18L19.1542 54.58V54.57ZM28.2442 43.72C28.1342 44.21 27.8342 44.62 27.4142 44.88L22.5842 47.89L14.5042 44.02L18.1742 41.73C18.4342 41.57 18.8542 41.54 19.2642 41.65C19.7542 41.79 20.1742 42.1 20.4342 42.52C20.5142 42.64 20.5742 42.79 20.6342 42.99C20.7342 43.33 20.9842 43.61 21.3142 43.73C21.6442 43.86 22.0142 43.82 22.3142 43.63L25.4242 41.69C25.8542 41.42 26.3542 41.34 26.8342 41.46C27.3242 41.57 27.7342 41.87 28.0042 42.29C28.2642 42.71 28.3542 43.21 28.2342 43.7L28.2442 43.72Z'
        fill='#2A2859'
      />
      <path
        id='Vector_4'
        d='M47.4742 40.6C49.5942 40.6 51.3142 38.88 51.3142 36.76C51.3142 34.64 49.5942 32.92 47.4742 32.92C45.3542 32.92 43.6342 34.64 43.6342 36.76C43.6342 38.88 45.3542 40.6 47.4742 40.6ZM47.4742 35.19C48.3442 35.19 49.0442 35.89 49.0442 36.76C49.0442 37.63 48.3442 38.33 47.4742 38.33C46.6042 38.33 45.9042 37.63 45.9042 36.76C45.9042 35.89 46.6042 35.19 47.4742 35.19Z'
        fill='#2A2859'
      />
      <path
        id='Vector_5'
        d='M55.5942 40.39C53.1742 39.97 50.8542 41.35 49.9842 43.54C48.7242 43.57 47.4742 43.92 46.4142 44.67C45.0742 45.62 44.1742 47.04 43.9042 48.66C43.3342 52.01 45.5842 55.2 48.9342 55.77C49.2842 55.83 49.6342 55.86 49.9842 55.86C51.2442 55.86 52.4742 55.47 53.5342 54.73C54.8742 53.78 55.7742 52.36 56.0542 50.74C56.0742 50.61 56.0942 50.47 56.1042 50.33C57.9842 49.82 59.4342 48.26 59.7742 46.28C60.2542 43.51 58.3742 40.86 55.6042 40.39H55.5942ZM57.5342 45.9C57.3142 47.2 56.2342 48.16 54.9142 48.25C54.6142 48.27 54.3342 48.41 54.1342 48.63C53.9342 48.86 53.8342 49.15 53.8542 49.45C53.8742 49.79 53.8642 50.09 53.8242 50.36C53.6442 51.39 53.0842 52.28 52.2342 52.88C51.3842 53.48 50.3442 53.72 49.3242 53.54C47.2042 53.18 45.7842 51.16 46.1442 49.04C46.3242 48.01 46.8842 47.12 47.7342 46.52C48.4042 46.05 49.1742 45.8 49.9742 45.8C50.1942 45.8 50.4142 45.82 50.6442 45.86C50.9442 45.91 51.2442 45.84 51.4942 45.67C51.7442 45.5 51.9042 45.23 51.9542 44.94C52.0842 44.19 52.4942 43.54 53.1142 43.1C53.7342 42.66 54.4842 42.49 55.2342 42.62C55.9842 42.75 56.6342 43.16 57.0742 43.78C57.5142 44.4 57.6842 45.15 57.5542 45.9H57.5342Z'
        fill='#2A2859'
      />
      <path
        id='Vector_6'
        d='M5.06419 33.79C5.06419 33.79 5.15419 33.79 5.20419 33.79C5.82419 33.71 6.26419 33.15 6.19419 32.53C4.97419 22.49 10.4942 18.93 10.7542 18.77C11.2842 18.45 11.4642 17.75 11.1442 17.22C10.8242 16.68 10.1242 16.51 9.58419 16.83C9.29419 17 2.52419 21.2 3.94419 32.8C4.01419 33.38 4.50419 33.8 5.06419 33.8V33.79Z'
        fill='#2A2859'
      />
      <path
        id='Vector_7'
        d='M12.4341 21.42C11.9241 21.06 11.2141 21.19 10.8541 21.7C10.6841 21.94 6.85414 27.59 10.7441 36.69C10.9241 37.12 11.3441 37.38 11.7841 37.38C11.9341 37.38 12.0841 37.35 12.2241 37.29C12.8041 37.04 13.0641 36.38 12.8241 35.8C9.46414 27.93 12.5741 23.2 12.7141 23C13.0641 22.49 12.9441 21.78 12.4241 21.43L12.4341 21.42Z'
        fill='#2A2859'
      />
    </g>
  </svg>
);
