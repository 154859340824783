import React from 'react';
import { useFormContext } from 'react-hook-form';
import { KeyComponentProps, KeyWrapper } from './KeyWrapper';

interface Option {
  label: string;
  value: string;
  'data-testid'?: string;
}

export interface KeyCheckboxGroupProps extends KeyComponentProps {
  options: Option[];
}

export const KeyCheckboxGroup: React.FC<KeyCheckboxGroupProps> = ({
  name,
  options,
  ...props
}) => {
  const { register } = useFormContext();
  return (
    <KeyWrapper name={name} {...props}>
      {options.map(option => (
        <div key={option.value} className='checkbox-option'>
          <input
            {...register(`${name}.${option.value}`)}
            type='checkbox'
            id={`${name}-${option.value}`}
            value={option.value}
            data-testid={option['data-testid']}
          />
          <label htmlFor={`${name}-${option.value}`}>{option.label}</label>
        </div>
      ))}
    </KeyWrapper>
  );
};
