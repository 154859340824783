import React, { Fragment } from 'react';
import { KeychainCommon } from '../../../redux/slice/operations';
import { useAuditLog } from '../../../utils/auditLogs/useAuditLog';
import { AuditLogsTable } from '../../controlpanel/LogsAndData/pages/AuitLogTable';
import { KeychainFactoryBreadcrumbs } from '../KeychainFactoryBreadcrumbs';

type Props = {
  keychainFactory: KeychainCommon;
};
export const AuditPage: React.FC<Props> = ({ keychainFactory }) => {
  const auditLogs = useAuditLog(keychainFactory.keychainFactoryUri);
  return (
    <Fragment>
      <KeychainFactoryBreadcrumbs
        keychainFactory={keychainFactory}
        name='Endringslogg'
      />
      <AuditLogsTable auditLogs={auditLogs} />
    </Fragment>
  );
};
