import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import { KeyId } from '../../../../components/Forms/KeyId';
import { KeyText } from '../../../../components/Forms/KeyText';
import { actionsHome } from '../../../../components/Navigation/controlpanelNavPaths';
import {
  CreateActionChain,
  createActionChain
} from '../../../../redux/actions/adapters';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { ControlpanelBreadcrumbs } from '../../ControlpanelBreadcrumbs';
import '../ActionChain.scss';
import { ActionList } from './ActionList';
import { AddNewActionToActionChain } from './AddNewActionToActionChain';

export const NewActionChain: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<CreateActionChain>({
    resolver: zodResolver(CreateActionChain),
    defaultValues: {
      name: '',
      id: '',
      actionList: []
    }
  });
  const { setValue, watch, handleSubmit } = methods;
  const [actionList] = watch(['actionList']);

  const onCreate = (data: CreateActionChain) =>
    dispatchAndHandleResult(dispatch, createActionChain, data, goBack);

  const onCancel = () => {
    goBack();
  };

  const goBack = () => {
    navigate(actionsHome.path);
  };

  return (
    <section className='actionchain-details'>
      <ControlpanelBreadcrumbs currentPageName='Ny ActionChain' />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onCreate)}>
          <div>
            <KeyText label='Navn' name='name' elementProps={{}} />
            <KeyId
              label='ChainID'
              name='id'
              otherField='name'
              elementProps={{}}
            />
            <ActionList
              actionList={actionList}
              setActionList={newList => setValue('actionList', newList)}
            />
            <AddNewActionToActionChain
              actionList={actionList}
              setActionList={newList => setValue('actionList', newList)}
            />
          </div>
          <div className='button-row'>
            <Button label='Avbryt' type='secondary' onClick={onCancel} />
            <Button label='Opprett' type='submit' />
          </div>
        </form>
      </FormProvider>
    </section>
  );
};
