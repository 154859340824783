import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Breadcrumbs } from '../../components/Navigation/breadcrumbs';
import { subjectHome } from '../../components/Navigation/navPaths';
import { SubjectsContainer } from '../../components/SubjectsContainer/SubjectsContainer';
import { useAppSelector } from '../../redux/hooks';
import { subjectSelectors } from '../../redux/slice/subjects';
import {
  getCategoriesWithSubjects,
  getFilteredSubjects
} from '../../utils/subjects/subjects';
import { SubjectCardsContainer } from './SubjectCardsContainer/SubjectCardsContainer';
import { FilterSearch } from './TopInfo/FilterSearch';
import { TopInfo } from './TopInfo/TopInfo';

export const Subjects = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const subjects = useAppSelector(subjectSelectors.selectAll);
  const filter = searchParams.get('filter') || '';
  const selectedCategories = searchParams.getAll('category');
  const selectedTags = searchParams.getAll('tag');
  const filteredSubects = getFilteredSubjects(
    subjects,
    selectedCategories,
    selectedTags,
    filter
  );
  const categoriesWithSubjects = getCategoriesWithSubjects(filteredSubects);
  return (
    <SubjectsContainer>
      <Breadcrumbs breadcrumbs={[subjectHome]} />
      <TopInfo
        numOfSubjects={filteredSubects.length}
        totalNumber={subjects.length}
      />
      <FilterSearch
        subjects={subjects}
        selectedCategories={selectedCategories}
        selectedTags={selectedTags}
        filter={filter}
        setParams={setSearchParams}
      />
      <SubjectCardsContainer categoriesWithSubjects={categoriesWithSubjects} />
    </SubjectsContainer>
  );
};
