import React from 'react';

export const Tear: React.FC = () => (
  <svg
    width='35'
    height='40'
    viewBox='0 0 44 50'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Group 12643'>
      <path
        id='Vector'
        d='M23.5 39C30.3929 39 36 33.4209 36 26.5625C36 19.0999 28.2857 11.3887 24.1429 7.26652C23.7857 6.91116 23.2143 6.91116 22.8929 7.26652C18.7143 11.3887 11 19.0999 11 26.5625C11 33.4209 16.6071 39 23.5 39ZM23.5 9.14992C27.5714 13.201 34.2143 20.166 34.2143 26.5625C34.2143 32.4259 29.3929 37.2232 23.5 37.2232C17.6071 37.2232 12.7857 32.4259 12.7857 26.5625C12.7857 20.166 19.4286 13.2365 23.5 9.14992Z'
        fill='#33394E'
      />
      <path
        id='Vector_2'
        d='M23.5 36C28.192 36 32 31.8084 32 26.6437C32 22.7515 29.416 17.8488 24.112 11.7111L23.5 11L22.888 11.7111C17.584 17.8488 15 22.7515 15 26.6437C15 31.8084 18.808 36 23.5 36ZM18.298 21.1796C18.536 20.7305 19.046 20.5808 19.454 20.8428C19.862 21.1048 19.998 21.6662 19.76 22.1153C18.876 23.8743 18.4 25.4087 18.4 26.6437C18.4 28.1407 18.944 29.5629 19.896 30.6108C20.236 30.985 20.236 31.5838 19.896 31.9207C19.726 32.1078 19.522 32.1826 19.284 32.1826C19.046 32.1826 18.842 32.1078 18.672 31.9207C17.414 30.5359 16.7 28.6272 16.7 26.6437C16.7 25.0719 17.244 23.238 18.298 21.1796Z'
        fill='#2A2859'
      />
    </g>
  </svg>
);
