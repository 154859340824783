import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUrlSafeId } from '../../utils/urlSafeId';
import { KeyInput } from './KeyInput';
import { KeyTextType } from './KeyText';

export interface KeyIdType extends KeyTextType {
  maxCharacters?: number;
  otherField?: string;
}

export const KeyId: React.FC<KeyIdType> = ({
  maxCharacters,
  otherField,
  ...props
}) => {
  const { watch, setValue } = useFormContext();
  const [currentId, otherValue] = watch([props.name, otherField || '']);
  const updateId = (rawName: string) => {
    if (rawName) {
      const safeId = createUrlSafeId(rawName, maxCharacters);
      if (safeId !== currentId) {
        setValue(props.name, safeId);
      }
    }
  };

  useEffect(() => {
    updateId(currentId);
  }, [currentId]);
  useEffect(() => {
    updateId(otherValue);
  }, [otherValue]);

  return <KeyInput {...props} type='text' />;
};
