import React from 'react';
import { ActionMenu } from '../../../components/ActionMenu/ActionMenu';
import { ActionMenuItemProps } from '../../../components/ActionMenu/ActionMenuItem';
import { GroupDialog } from './GroupDialog';

export const AdminFAQMenu: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const action: ActionMenuItemProps = {
    type: 'add',
    description: 'Lag ny spørsmålsgruppe',
    onClick: () => {
      setIsModalOpen(true);
    }
  };

  return (
    <div className='actionmenu-container'>
      <ActionMenu actions={[action]} />
      <GroupDialog
        isModalOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};
