import React from 'react';
import 'react-redux';
import { termsHome } from '../../components/Navigation/navPaths';
import { LinkCard } from '../../components/cards/LinkCard/LinkCard';
import { useAppSelector } from '../../redux/hooks';
import { termsSelectors } from '../../redux/slice/terms';

const getShortText = (text: string) => {
  if (text.length > 100) {
    return text.substr(0, 110) + '…';
  }
  return text;
};

const getTextFromHtml = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

export const TermsList = () => {
  const terms = useAppSelector(termsSelectors.selectAll);
  return (
    <div className='cards'>
      {terms.map(term => {
        const text = term.currentText
          ? getTextFromHtml(term.currentText.content)
          : '';
        return (
          <LinkCard
            key={term.id}
            title={term.title}
            linkUri={`${termsHome.path}/${term.id}`}
            content={<p>{getShortText(text)}</p>}
            size='small'
          />
        );
      })}
    </div>
  );
};
