import React from 'react';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { useAppSelector } from '../../../redux/hooks';
import { categorySelectors } from '../../../redux/slice/categories';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const AdminCategoriesPage: React.FC = () => {
  const categories = useAppSelector(categorySelectors.selectAll);
  const tableHeadings = ['Navn', 'ID'];
  const data = categories.map(category => {
    return {
      cells: [category.name, category.id],
      link: category.id
    };
  });

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs />
      <TableWithButtons
        ting='kategori'
        buttons={[
          {
            label: 'Lag ny kategori',
            type: 'primary',
            accessKey: 'n',
            linkTo: 'lag-ny'
          }
        ]}
        table={{
          headings: tableHeadings,
          rows: data
        }}
      />
    </React.Fragment>
  );
};
