import React, { PropsWithChildren, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { DialogFormBox, DialogBoxProps } from './DialogFormBox';

export const useDialogBox = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const Dialog = <T extends FieldValues>({
    children,
    ...props
  }: Omit<
    PropsWithChildren<DialogBoxProps<T>>,
    'closeModal' | 'isModalOpen'
  >) => {
    return (
      <DialogFormBox
        closeModal={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
        {...props}
      >
        {children}
      </DialogFormBox>
    );
  };

  return [
    Dialog,
    () => {
      setIsModalOpen(true);
    },
    () => {
      setIsModalOpen(false);
    }
  ] as const;
};

export type DialogBoxType = ReturnType<typeof useDialogBox>[0];
