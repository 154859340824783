import React from 'react';

export const TrashCan: React.FC = () => (
  <svg
    id='Group_12244'
    data-name='Group 12244'
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='17.5'
    viewBox='0 0 14 17.5'
  >
    <path
      id='Path_11373'
      data-name='Path 11373'
      d='M6,11l.665,8.644h1.33L7.33,11Z'
      transform='translate(-2.57 -5.27)'
      fill='#2a2859'
    />
    <path
      id='Path_11374'
      data-name='Path 11374'
      d='M11,19.644V11h1.33v8.644Z'
      transform='translate(-4.665 -5.27)'
      fill='#2a2859'
    />
    <path
      id='Path_11375'
      data-name='Path 11375'
      d='M15.665,11,15,19.644h1.33L16.995,11Z'
      transform='translate(-6.425 -5.27)'
      fill='#2a2859'
    />
    <path
      id='Path_11376'
      data-name='Path 11376'
      d='M10.477,0H3.5V1.75H0V4.667H.608L1.8,17.5H12.2L13.392,4.667H14V1.75H10.477ZM9.31,1.75H4.667V1.167H9.31ZM2.865,16.333,1.78,4.667H12.22L11.135,16.333ZM1.167,2.917V3.5H12.833V2.917Z'
      fill='#2a2859'
      fillRule='evenodd'
    />
  </svg>
);
