import React, { PropsWithChildren } from 'react';
import { WarningCircle } from '../../assets/WarningCircle';
import './Alert.scss';

type Props = {
  severity?: 'error' | 'warning' | 'info' | 'success';
};
export const Alert: React.FC<PropsWithChildren<Props>> = ({
  children,
  severity = 'error'
}) => {
  return (
    <div className={'keystudio-alert ' + severity}>
      <WarningCircle />
      <div>{children}</div>
    </div>
  );
};
