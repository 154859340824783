import React from 'react';
import { useNavigate } from 'react-router-dom';
import { updateOperationTerms } from '../../../../redux/actions/operations';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { historyGoBack } from '../../../../utils/history';
import { Button } from '../../../Button/Button';

interface Props {
  subjectId: string;
  operationId: string;
  selectedTermIds: string[];
}

export const SaveButton: React.FC<Props> = ({
  subjectId,
  operationId,
  selectedTermIds
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSaveClick = () =>
    dispatchAndHandleResult(
      dispatch,
      updateOperationTerms,
      {
        subjectId,
        operationId,
        termIds: selectedTermIds
      },
      () => {
        historyGoBack(navigate);
      }
    );

  return <Button label='Lagre' type='primary' onClick={handleSaveClick} />;
};
