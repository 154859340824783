import React from 'react';
import cn from 'classnames';
import DOMPurify from 'dompurify';
import { Anomaly } from '../../../assets/tags/Anomaly';
import { Beta } from '../../../assets/tags/Beta';
import { Empty } from '../../../assets/tags/Empty';
import { Keyless } from '../../../assets/tags/Keyless';
import { Last7Days } from '../../../assets/tags/Last7Days';
import { Last30Days } from '../../../assets/tags/Last30Days';
import { Prod } from '../../../assets/tags/Prod';
import { Tear } from '../../../assets/tags/Tear';
import { Trouble } from '../../../assets/tags/Trouble';
import { UsedToday } from '../../../assets/tags/UsedToday';
import { Visible } from '../../../assets/tags/Visible';
import { DynamicTag } from '../../../proto/shared_pb';

type Props = {
  tag: DynamicTag.AsObject;
  activeTag?: DynamicTag.Type | undefined;
  setActiveTag?: (tag: DynamicTag.Type | undefined) => void;
};
export const Tag: React.FC<Props> = ({
  tag,
  setActiveTag = () => {},
  activeTag
}) => {
  return (
    <div
      className={cn('tag', { trouble: tag.typeId === DynamicTag.Type.TROUBLE })}
      onMouseEnter={() => setActiveTag(tag.typeId)}
      onMouseLeave={() => setActiveTag(undefined)}
    >
      <span className='icon'>{getIconForTag(tag.typeId)}</span>
      <span
        className={cn('tag-description', {
          show: activeTag == tag.typeId
        })}
      >
        {tag.title}
        {tag.text ? (
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(tag.text.content)
            }}
          ></span>
        ) : null}
      </span>
    </div>
  );
};

const getIconForTag = (tag: DynamicTag.Type) => {
  switch (tag) {
    case DynamicTag.Type.PRODUCTION:
      return <Prod />;
    case DynamicTag.Type.BETA:
      return <Beta />;
    case DynamicTag.Type.USED_TODAY:
      return <UsedToday />;
    case DynamicTag.Type.VIRGIN:
      return <Tear />;
    case DynamicTag.Type.TROUBLE:
      return <Trouble />;
    case DynamicTag.Type.NOT_USED_LAST_7_DAYS:
      return <Last7Days />;
    case DynamicTag.Type.NOT_USED_LAST_30_DAYS:
      return <Last30Days />;
    case DynamicTag.Type.KEYLESS_KEYCHAIN:
      return <Keyless />;
    case DynamicTag.Type.VISIBLE_FOR_EVERYONE:
      return <Visible />;
    case DynamicTag.Type.ANOMALY_MESSAGE:
      return <Anomaly />;
    default:
      return <Empty />;
  }
};
