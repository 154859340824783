import React from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { generateClasses, KeyComponentProps, KeyWrapper } from './KeyWrapper';

export interface KeyDateTimeType extends KeyComponentProps {
  type: 'date' | 'time' | 'datetime';
  elementProps: Omit<
    React.ComponentProps<typeof DatePicker>,
    'selected' | 'onChange' | 'selectsRange' | 'selectsMultiple'
  >;
}
export const KeyDateTime: React.FC<KeyDateTimeType> = ({ name, ...props }) => {
  const { elementProps, ...wrapperProps } = props;
  if (props.type === 'time') {
    elementProps.showTimeSelectOnly = true;
    elementProps.dateFormat = 'HH:mm';
  }
  if (['time', 'datetime'].includes(props.type)) {
    elementProps.showTimeSelect = true;
    elementProps.timeCaption = 'Tid';
  }
  const { inputClassNames } = generateClasses(props);

  const { control } = useFormContext();
  return (
    <KeyWrapper name={name} {...wrapperProps}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <DatePicker
            id={name}
            {...elementProps}
            selected={field.value}
            className={inputClassNames}
            {...field}
          />
        )}
      />
    </KeyWrapper>
  );
};
