import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearPopup } from '../../redux/slice/popup';
import { setTraceId } from '../../redux/slice/ui';
import { getGrafanaTraceUrl } from '../../utils/grafana';
import { Button } from '../Button/Button';
import { isSuperAdmin } from '../util/RenderIfSuperAdmin';
import './Popup.scss';

export const ErrorPopup = () => {
  const popup = useAppSelector(state => state.popup.popup);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const cancelClick = () => {
    dispatch(clearPopup());
    dispatch(setTraceId(''));
  };
  const traceId = popup?.traceId || '';
  const methodName = popup?.methodName;
  const feedbackClick = () => {
    dispatch(clearPopup());
    if (traceId) {
      dispatch(setTraceId(traceId));
    }
    navigate('/hjelp');
  };

  if (popup && popup.error) {
    const buttons = (
      <div className='button-panel'>
        <Button
          label='Send tilbakemelding'
          type='primary'
          onClick={feedbackClick}
        />
        <Button label='Avbryt' type='secondary' onClick={cancelClick} />
      </div>
    );

    return (
      <div className='popup-container error'>
        <div className='column'>
          <h3>{popup.message}</h3>
          <span>Gi oss gjerne tilbakemelding på hva som gikk galt.</span>
          {isSuperAdmin() && (
            <React.Fragment>
              {traceId && (
                <a href={getGrafanaTraceUrl(traceId)} target='grafana'>
                  Link til grafana: {traceId}
                </a>
              )}
              {methodName && <span> Method name: {methodName}</span>}
            </React.Fragment>
          )}
          {buttons}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
