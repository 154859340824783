import React from 'react';
import { Button } from '../../../../components/Button/Button';
import { KeyStudioCard } from '../../../../components/cards/KeyStudioCard/KeyStudioCard';
import { Term } from '../../../../redux/slice/terms';
import { useAdminRole } from '../../../../utils/roles/useAdminRole';

type Props = {
  term: Term;
  setEdit: (edit: boolean) => void;
};
export const OwnerAdminCard: React.FC<Props> = ({ term, setEdit }) => {
  const role = useAdminRole(term.adminRole);
  return (
    <KeyStudioCard>
      <h3>Eier</h3>
      <p>{term.ownerName}</p>
      <br />
      <br />
      <h3>AdminRolle</h3>
      <p>{role?.name || term.adminRole}</p>
      <br />
      <Button label='Rediger' onClick={() => setEdit(true)} />
    </KeyStudioCard>
  );
};
