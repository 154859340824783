import React from 'react';

export const FabrikkSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='76.387'
    viewBox='0 0 40 76.387'
  >
    <g id='Group_1553' data-name='Group 1553' transform='translate(0 397.679)'>
      <path
        id='Path_2942'
        data-name='Path 2942'
        d='M63.228-261.218v-1.862a14.844,14.844,0,0,1,2.852,0v1.862q.532.059,1.058.162l.355-1.817a14.811,14.811,0,0,1,2.734.814l-.7,1.716q.579.235,1.134.525l.858-1.64a14.831,14.831,0,0,1,2.39,1.561L71.6-257.008a11.109,11.109,0,0,0-13.886,0L55.4-259.9a14.817,14.817,0,0,1,2.388-1.56l.859,1.64q.554-.29,1.134-.525l-.7-1.716a14.816,14.816,0,0,1,2.734-.814l.355,1.817Q62.7-261.159,63.228-261.218Z'
        transform='translate(-44.655 -108.665)'
        fill='#292858'
      />
      <path
        id='Path_2943'
        data-name='Path 2943'
        d='M66.495-204.291H73.9v3.7H70.2v.011h-.013a1.843,1.843,0,0,0-1.845,1.839,1.843,1.843,0,0,0,1.845,1.839H70.2v.014H73.9v3.7H66.495v3.7h-3.7v-26.108a9.258,9.258,0,0,1-7.3-7.682,9.258,9.258,0,0,1,7.764-10.542A9.258,9.258,0,0,1,73.8-226.047a9.258,9.258,0,0,1-7.3,10.46Zm3.784-20.367a5.638,5.638,0,0,0-5.635-5.635,5.638,5.638,0,0,0-5.635,5.635,5.638,5.638,0,0,0,5.635,5.636A5.638,5.638,0,0,0,70.279-224.658Z'
        transform='translate(-44.643 -131.814)'
        fill='#292858'
      />
      <path
        id='Path_2944'
        data-name='Path 2944'
        d='M25.489-358.873l-.1.029a20.192,20.192,0,0,1-5.385.727A19.9,19.9,0,0,1,0-377.9a19.9,19.9,0,0,1,20-19.781A19.9,19.9,0,0,1,40-377.9a19.769,19.769,0,0,1-11.046,17.69l-.055.028-.132-.386-.171-.4-.187-.421-.2-.378-.292-.491-.264-.388-.19-.263-.148-.185-.081-.087A16.461,16.461,0,0,0,36.38-377.9,16.4,16.4,0,0,0,20-394.293,16.4,16.4,0,0,0,3.62-377.9,16.4,16.4,0,0,0,20-361.5a16.413,16.413,0,0,0,3.38-.35l.017,0,.47.411.409.407.336.418.259.389.371.692Z'
        transform='translate(0 0)'
        fill='#292858'
        fillRule='evenodd'
      />
    </g>
  </svg>
);
