import React from 'react';
import { KeyInput } from './KeyInput';
import { KeyComponentProps } from './KeyWrapper';

export interface KeyColorType extends KeyComponentProps {
  elementProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}
export const KeyColor: React.FC<KeyColorType> = props => {
  return <KeyInput {...props} type='color' />;
};
