import React from 'react';

export const Adapters = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='136'
    height='136'
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12197'
      data-name='Group 12197'
      transform='translate(-1021 -475)'
    >
      <g
        id='Group_12191'
        data-name='Group 12191'
        transform='translate(-465 256)'
      >
        <g
          id='Group_12177'
          data-name='Group 12177'
          transform='translate(1158 -13)'
        >
          <circle
            id='Ellipse_691'
            data-name='Ellipse 691'
            cx='68'
            cy='68'
            r='68'
            transform='translate(328 232)'
            fill='#f9b3ab'
          />
        </g>
      </g>
      <g id='Group_12196' data-name='Group 12196'>
        <g
          id='Ellipse_708'
          data-name='Ellipse 708'
          transform='matrix(0.996, -0.087, 0.087, 0.996, 1073.25, 502.819)'
          fill='#f9b3ab'
          stroke='#292858'
          strokeWidth='3'
        >
          <circle cx='9' cy='9' r='9' stroke='none' />
          <circle cx='9' cy='9' r='7.5' fill='none' />
        </g>
        <g
          id='Ellipse_704'
          data-name='Ellipse 704'
          transform='translate(1067 513)'
          fill='#f9b3ab'
          stroke='#292858'
          strokeWidth='3'
        >
          <circle cx='9' cy='9' r='9' stroke='none' />
          <circle cx='9' cy='9' r='7.5' fill='none' />
        </g>
        <g
          id='Ellipse_705'
          data-name='Ellipse 705'
          transform='translate(1094 510)'
          fill='#f9b3ab'
          stroke='#292858'
          strokeWidth='3'
        >
          <circle cx='9' cy='9' r='9' stroke='none' />
          <circle cx='9' cy='9' r='7.5' fill='none' />
        </g>
        <g
          id='Ellipse_706'
          data-name='Ellipse 706'
          transform='translate(1080 506)'
          fill='#f9b3ab'
          stroke='#292858'
          strokeWidth='3'
        >
          <circle cx='9' cy='9' r='9' stroke='none' />
          <circle cx='9' cy='9' r='7.5' fill='none' />
        </g>
        <g
          id='Ellipse_707'
          data-name='Ellipse 707'
          transform='translate(1086 514)'
          fill='#f9b3ab'
          stroke='#292858'
          strokeWidth='3'
        >
          <circle cx='9' cy='9' r='9' stroke='none' />
          <circle cx='9' cy='9' r='7.5' fill='none' />
        </g>
        <g
          id='Rectangle_1916'
          data-name='Rectangle 1916'
          transform='translate(1082 567)'
          fill='none'
          stroke='#292858'
          strokeWidth='3'
        >
          <rect width='15' height='15' stroke='none' />
          <rect x='1.5' y='1.5' width='12' height='12' fill='none' />
        </g>
        <path
          id='Path_11307'
          data-name='Path 11307'
          d='M1826.607-4478l20.4,23.585v12.383h10.562v-12.383L1877.717-4478h-51.11Z'
          transform='translate(-763.162 5000.031)'
          fill='#f9b3ab'
          stroke='#292858'
          strokeLinecap='round'
          strokeWidth='3'
        />
      </g>
    </g>
  </svg>
);
