import React, { Fragment } from 'react';
import { CloneIcon } from '../../assets/CloneIcon';
import { EditPencilIcon } from '../../assets/EditPencilIcon';
import { EmailIcon } from '../../assets/EmailIcon';
import { ShareIcon } from '../../assets/ShareIcon';
import { TimeIcon } from '../../assets/TimeIcon';
import { TrashCan } from '../../assets/TrashCan';

export type ActionTypes =
  | 'delete'
  | 'edit'
  | 'add'
  | 'clone'
  | 'share'
  | 'email'
  | 'time';

export type ActionMenuItemProps = {
  type: ActionTypes;
  description?: string;
  onClick: () => void;
};

export const ActionMenuItem: React.FC<ActionMenuItemProps> = ({
  onClick,
  description,
  type
}) => {
  return (
    <div className='actionmenu-item' onClick={onClick}>
      <Fragment>
        <div className='icon'>{getIconFromRole(type)}</div>
        <div className='description'>{description || descMap.get(type)}</div>
      </Fragment>
    </div>
  );
};

const getIconFromRole = (role: ActionTypes) => {
  if (role === 'delete') {
    return <TrashCan />;
  } else if (role === 'edit') {
    return <EditPencilIcon />;
  } else if (role === 'clone' || role === 'add') {
    return <CloneIcon />;
  } else if (role === 'email') {
    return <EmailIcon />;
  } else if (role === 'time') {
    return <TimeIcon />;
  } else if (role === 'share') {
    return <ShareIcon />;
  }
  return <Fragment>?</Fragment>;
};
const descMap = new Map<ActionTypes, string>([
  ['delete', 'Slett'],
  ['edit', 'Rediger'],
  ['share', 'Del'],
  ['clone', 'Lag kopi']
]);
