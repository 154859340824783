import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmDialogDelete } from '../../../components/DeleteAndConfirm/DeleteAndConfirm';
import { deleteKeychainFactory } from '../../../redux/actions/keychainFactoryDetails';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { KeychainCommon } from '../../../redux/slice/operations';

type Props = {
  closeModal: () => void;
  isModalOpen: boolean;
  keychainFactory: KeychainCommon;
};
export const DeleteKeychain: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  keychainFactory
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const deleteAction = () =>
    dispatchAndHandleResult(
      dispatch,
      deleteKeychainFactory,
      {
        keychainFactoryUri: keychainFactory.keychainFactoryUri
      },
      () => {
        closeModal();
        navigate('..', { relative: 'path' });
      }
    );
  return (
    <ConfirmDialogDelete
      label='Slett tilgangslisten'
      message='Er du sikker på at du vil slette denne tilgangslisten? Alle som har tilgang til den vil miste tilgangen sin. Dette kan ikke angres.'
      deleteAction={deleteAction}
      isModalOpen={isModalOpen}
      setIsModalOpen={closeModal}
    />
  );
};
