/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.broadcast
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: broadcast.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as broadcast_pb from './broadcast_pb'; // proto import: "broadcast.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class BroadcastServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorFetchFAQ = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/FetchFAQ',
    grpcWeb.MethodType.UNARY,
    broadcast_pb.FetchFAQRequest,
    broadcast_pb.FAQResponse,
    (request: broadcast_pb.FetchFAQRequest) => {
      return request.serializeBinary();
    },
    broadcast_pb.FAQResponse.deserializeBinary
  );

  fetchFAQ(
    request: broadcast_pb.FetchFAQRequest,
    metadata?: grpcWeb.Metadata | null): Promise<broadcast_pb.FAQResponse>;

  fetchFAQ(
    request: broadcast_pb.FetchFAQRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: broadcast_pb.FAQResponse) => void): grpcWeb.ClientReadableStream<broadcast_pb.FAQResponse>;

  fetchFAQ(
    request: broadcast_pb.FetchFAQRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: broadcast_pb.FAQResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/FetchFAQ',
        request,
        metadata || {},
        this.methodDescriptorFetchFAQ,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/FetchFAQ',
    request,
    metadata || {},
    this.methodDescriptorFetchFAQ);
  }

  methodDescriptorAddGroup = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/AddGroup',
    grpcWeb.MethodType.UNARY,
    broadcast_pb.AddGroupRequest,
    shared_pb.EmptyResponse,
    (request: broadcast_pb.AddGroupRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  addGroup(
    request: broadcast_pb.AddGroupRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  addGroup(
    request: broadcast_pb.AddGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  addGroup(
    request: broadcast_pb.AddGroupRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/AddGroup',
        request,
        metadata || {},
        this.methodDescriptorAddGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/AddGroup',
    request,
    metadata || {},
    this.methodDescriptorAddGroup);
  }

  methodDescriptorDeleteGroup = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/DeleteGroup',
    grpcWeb.MethodType.UNARY,
    broadcast_pb.DeleteGroupRequest,
    shared_pb.EmptyResponse,
    (request: broadcast_pb.DeleteGroupRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteGroup(
    request: broadcast_pb.DeleteGroupRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteGroup(
    request: broadcast_pb.DeleteGroupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteGroup(
    request: broadcast_pb.DeleteGroupRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/DeleteGroup',
        request,
        metadata || {},
        this.methodDescriptorDeleteGroup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/DeleteGroup',
    request,
    metadata || {},
    this.methodDescriptorDeleteGroup);
  }

  methodDescriptorUpdateGroupTitle = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/UpdateGroupTitle',
    grpcWeb.MethodType.UNARY,
    broadcast_pb.UpdateGroupTitleRequest,
    shared_pb.EmptyResponse,
    (request: broadcast_pb.UpdateGroupTitleRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateGroupTitle(
    request: broadcast_pb.UpdateGroupTitleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateGroupTitle(
    request: broadcast_pb.UpdateGroupTitleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateGroupTitle(
    request: broadcast_pb.UpdateGroupTitleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/UpdateGroupTitle',
        request,
        metadata || {},
        this.methodDescriptorUpdateGroupTitle,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/UpdateGroupTitle',
    request,
    metadata || {},
    this.methodDescriptorUpdateGroupTitle);
  }

  methodDescriptorAddQuestion = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/AddQuestion',
    grpcWeb.MethodType.UNARY,
    broadcast_pb.AddQuestionRequest,
    shared_pb.EmptyResponse,
    (request: broadcast_pb.AddQuestionRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  addQuestion(
    request: broadcast_pb.AddQuestionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  addQuestion(
    request: broadcast_pb.AddQuestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  addQuestion(
    request: broadcast_pb.AddQuestionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/AddQuestion',
        request,
        metadata || {},
        this.methodDescriptorAddQuestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/AddQuestion',
    request,
    metadata || {},
    this.methodDescriptorAddQuestion);
  }

  methodDescriptorDeleteQuestion = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/DeleteQuestion',
    grpcWeb.MethodType.UNARY,
    broadcast_pb.DeleteQuestionRequest,
    shared_pb.EmptyResponse,
    (request: broadcast_pb.DeleteQuestionRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteQuestion(
    request: broadcast_pb.DeleteQuestionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteQuestion(
    request: broadcast_pb.DeleteQuestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteQuestion(
    request: broadcast_pb.DeleteQuestionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/DeleteQuestion',
        request,
        metadata || {},
        this.methodDescriptorDeleteQuestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/DeleteQuestion',
    request,
    metadata || {},
    this.methodDescriptorDeleteQuestion);
  }

  methodDescriptorUpdateQuestion = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/UpdateQuestion',
    grpcWeb.MethodType.UNARY,
    broadcast_pb.UpdateQuestionRequest,
    shared_pb.EmptyResponse,
    (request: broadcast_pb.UpdateQuestionRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateQuestion(
    request: broadcast_pb.UpdateQuestionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateQuestion(
    request: broadcast_pb.UpdateQuestionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateQuestion(
    request: broadcast_pb.UpdateQuestionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/UpdateQuestion',
        request,
        metadata || {},
        this.methodDescriptorUpdateQuestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/UpdateQuestion',
    request,
    metadata || {},
    this.methodDescriptorUpdateQuestion);
  }

  methodDescriptorFetchSupportedClients = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/FetchSupportedClients',
    grpcWeb.MethodType.UNARY,
    broadcast_pb.FetchSupportedClientsRequest,
    broadcast_pb.SupportedClientsResponse,
    (request: broadcast_pb.FetchSupportedClientsRequest) => {
      return request.serializeBinary();
    },
    broadcast_pb.SupportedClientsResponse.deserializeBinary
  );

  fetchSupportedClients(
    request: broadcast_pb.FetchSupportedClientsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<broadcast_pb.SupportedClientsResponse>;

  fetchSupportedClients(
    request: broadcast_pb.FetchSupportedClientsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: broadcast_pb.SupportedClientsResponse) => void): grpcWeb.ClientReadableStream<broadcast_pb.SupportedClientsResponse>;

  fetchSupportedClients(
    request: broadcast_pb.FetchSupportedClientsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: broadcast_pb.SupportedClientsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/FetchSupportedClients',
        request,
        metadata || {},
        this.methodDescriptorFetchSupportedClients,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/FetchSupportedClients',
    request,
    metadata || {},
    this.methodDescriptorFetchSupportedClients);
  }

  methodDescriptorUpdateSupportedClients = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/UpdateSupportedClients',
    grpcWeb.MethodType.UNARY,
    broadcast_pb.UpdateSupportedClientsRequest,
    shared_pb.EmptyResponse,
    (request: broadcast_pb.UpdateSupportedClientsRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateSupportedClients(
    request: broadcast_pb.UpdateSupportedClientsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateSupportedClients(
    request: broadcast_pb.UpdateSupportedClientsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateSupportedClients(
    request: broadcast_pb.UpdateSupportedClientsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/UpdateSupportedClients',
        request,
        metadata || {},
        this.methodDescriptorUpdateSupportedClients,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastService/UpdateSupportedClients',
    request,
    metadata || {},
    this.methodDescriptorUpdateSupportedClients);
  }

}

