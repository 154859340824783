import React from 'react';
import classNames from 'classnames';
import './StatusDot.scss';

interface Props {
  color: string;
}
export const StatusDot: React.FC<Props> = ({ color }) => {
  const classes = classNames('status-dot', color);
  return <span role='img' className={classes} />;
};
