import React from 'react';
import { DialogFormBox } from './DialogBox/DialogFormBox';

interface Props {
  title: string;
  message: string;
  doSomething: () => void;
  closeModal: () => void;
  isModalOpen: boolean;
}

export const Confirm: React.FC<Props> = ({
  title,
  message,
  doSomething,
  closeModal,
  isModalOpen
}) => {
  return (
    <DialogFormBox
      buttonLabel='Bekreft'
      buttonType='destructive'
      onSubmit={doSomething}
      header={title}
      closeModal={closeModal}
      isModalOpen={isModalOpen}
    >
      <p>{message}</p>
    </DialogFormBox>
  );
};
