import React from 'react';
import { Button } from '../../components/Button/Button';
import { Breadcrumbs } from '../../components/Navigation/breadcrumbs';
import { termsHome } from '../../components/Navigation/navPaths';
import { PageInfoText } from '../../components/PageInfoText/PageInfoText';
import './Terms.scss';
import { TermsList } from './TermsList';

export const Terms = () => (
  <section className='terms'>
    <Breadcrumbs breadcrumbs={[termsHome]} />
    <PageInfoText type='small'>
      Vilkår er betingelser innbyggeren må godta i appen for å bruke
      Oslonøkkelen. Hvis dere gjør endringer, vil innbyggeren få beskjed i appen
      om å godta vilkårene deres på nytt. For å få et «tomt» vilkår ta kontakt
      med oss
    </PageInfoText>
    <Button
      label='Lag nytt vilkår'
      type='primary'
      accessKey='n'
      linkTo='lag-ny'
    ></Button>
    <TermsList />
  </section>
);
