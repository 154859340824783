import { flip, shift } from '@floating-ui/react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { KeyDateTime } from '../Forms/KeyDateTime';
import { DatePickerProps } from './DatePickerProps';
import './DatePickerStyle.scss';

export const StartDateTimePicker: React.FC<DatePickerProps> = ({
  name,
  endDate,
  startDate,
  minDate,
  disabled = false,
  inline = false,
  position
}) => (
  <div className={`datepicker ${inline ? 'datepicker--inline' : ''}`}>
    <KeyDateTime
      type='datetime'
      name={name}
      label='Fra'
      elementProps={{
        selectsStart: true,
        popperPlacement: position,
        popperModifiers: [shift(), flip()],
        startDate: startDate,
        endDate: endDate,
        minDate: minDate,
        dateFormat: 'dd.MM.yyyy  HH:mm',
        showTimeSelect: true,
        showDisabledMonthNavigation: true,
        disabled: disabled
      }}
    />
  </div>
);
