import { utils, writeFile } from 'xlsx';
import { store } from '../../../redux';
import { setPopup } from '../../../redux/slice/popup';
import { GraphData } from './statsTypes';

const padTo2Digits = (num: number) => num.toString().padStart(2, '0');

const getDateFromTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);

  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear()
  ].join('-');
};

const formatStatId = (id: string) => {
  const stringParts = id.split('/');
  return stringParts[stringParts.length - 1].substring(0, 30);
};

export const exportStats = (graphData: GraphData[]) => {
  if (graphData.length !== 0) {
    const wb = utils.book_new();

    graphData.forEach(stat => {
      const data = stat.data.map(dataPoint => {
        return {
          dato: getDateFromTimestamp(dataPoint.timestamp * 1000),
          besok: dataPoint.visits
        };
      });

      const ws = utils.json_to_sheet(data);

      utils.book_append_sheet(wb, ws, formatStatId(stat.id));
    });

    writeFile(wb, 'oslonokkelen-eksport.xlsx');
  } else {
    store.dispatch(
      setPopup({
        error: true,
        message: 'Ingen data å eksporere'
      })
    );
  }
};
