import { EditorState, convertToRaw, ContentState } from 'draft-js';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import draftToHtml from 'draftjs-to-html';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { advancedToolbar } from '../../../components/Editor/TextEditor';

interface Props {
  text: string;
  setText: (text: string) => void;
}

export const TextInput: React.FC<Props> = ({ text, setText }) => {
  const getEditorState = () => {
    let editorState = null;
    if (text) {
      const processedHTML = DraftPasteProcessor.processHTML(text);
      const contentState = ContentState.createFromBlockArray(processedHTML);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }
    return editorState;
  };
  const [editorState, setEditorState] = useState(getEditorState());

  const getEditorText = () =>
    draftToHtml(convertToRaw(editorState.getCurrentContent()));

  return (
    <div className='form-element'>
      <label className='form-element--label'>Selve teksten</label>
      <Editor
        toolbarClassName='editor-toolbar'
        editorClassName='wysiwyg-editor'
        editorState={editorState}
        toolbar={advancedToolbar}
        onEditorStateChange={editorState => {
          setEditorState(editorState);
          const updatedText = getEditorText();
          setText(updatedText);
        }}
      />
    </div>
  );
};
