import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import './assets/OsloSansOffice-Regular.ttf';
import {
  admissionCardsHome,
  auditHome,
  controlPanelHome,
  helpHome,
  keychainHome,
  statsHome,
  subjectHome,
  termsHome
} from './components/Navigation/navPaths';
import { ErrorPopup } from './components/Popups/ErrorPopup';
import { Popup } from './components/Popups/Popup';
import { RefreshNotification } from './components/Popups/RefreshNotification';
import { ScrollToTop } from './components/ScrollToTop';
import { SideMenu } from './components/SideMenu/SideMenu';
import { AuditPage } from './pages/audit/AuditPage';
import { HelpPage } from './pages/help/Help';
import { Home } from './pages/home/Home';
import { Buttons } from './pages/showcase/Buttons';
import { FormComponents } from './pages/showcase/FormComponents';
import { ImageComponents } from './pages/showcase/ImageComponents';
import { Showcase } from './pages/showcase/Showcase';
import { StatsPage } from './pages/stats/StatsPage';
import { useAppSelector } from './redux/hooks';
import { AdmissionCardRouter } from './routers/AdmissionCardRouter';
import { ControlPanelRouter } from './routers/ControlPanelRouter';
import { KeychainFactoryRouter } from './routers/KeychainFactoryRouter';
import { SubjectsRouter } from './routers/SubjectsRouter';
import { TermsRouter } from './routers/TermsRouter';
import './sass/main.scss';

export const App = () => {
  const user = useAppSelector(state => state.user);
  return (
    <Router>
      <ScrollToTop />
      <RefreshNotification />
      <Popup />
      <ErrorPopup />
      <div className='App'>
        <SideMenu user={user} />
        <div className='content-root'>
          {user.username === 'unauthenticated' ? (
            <div className='wrong-login'>
              Du må logge inn med admin brukernavn og passord, ikke ID Porten.
            </div>
          ) : (
            <Routes>
              <Route path={`${keychainHome.path}/*`} element={<Home />} />
              <Route
                path={keychainHome.path + '/:keychainType'}
                element={<Home />}
              />
              <Route
                path={`${keychainHome.path}/:keychainType/:id/*`}
                element={<KeychainFactoryRouter />}
              />
              <Route
                path={`${subjectHome.path}/*`}
                element={<SubjectsRouter />}
              />
              <Route path={`${termsHome.path}/*`} element={<TermsRouter />} />
              <Route
                path={`${admissionCardsHome.path}/*`}
                element={<AdmissionCardRouter />}
              />
              <Route path={statsHome.path} element={<StatsPage />} />
              <Route path={auditHome.path} element={<AuditPage />} />
              <Route path={helpHome.path} element={<HelpPage />} />
              <Route
                path={`${controlPanelHome.path}/*`}
                element={
                  user.rolesList.includes('admin_super') ? (
                    <ControlPanelRouter />
                  ) : (
                    <Home />
                  )
                }
              />
              <Route path='/showcase' element={<Showcase />} />
              <Route path='/showcase/form' element={<FormComponents />} />
              <Route path='/showcase/buttons' element={<Buttons />} />
              <Route path='/showcase/images' element={<ImageComponents />} />
              <Route
                path='/'
                element={<Navigate to={keychainHome.path} replace={true} />}
              />
            </Routes>
          )}
        </div>
      </div>
    </Router>
  );
};
