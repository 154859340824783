import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { formatTime, parseTime } from '../../utils/timeConverter';
import { TimePicker } from '../DatePickers/TimePicker';
import { Slider } from '../Slider/Slider';

export interface CommonHourProps {
  dayIsClosed: boolean;
  updateDayIsClosed: (isOpen: boolean) => void;
  fromTime: string;
  untilTime: string;
  updateTime: (value: string, type: 'from' | 'until') => void;
}
export const CommonHourComponent: React.FC<CommonHourProps> = ({
  dayIsClosed,
  updateDayIsClosed,
  fromTime,
  untilTime,
  updateTime
}) => {
  const methods = useForm({
    defaultValues: {
      dayIsClosed,
      fromTime: parseTime(fromTime),
      untilTime: parseTime(untilTime)
    }
  });
  const fromWatch = methods.watch('fromTime');
  const untilWatch = methods.watch('untilTime');
  useEffect(() => {
    if (dayIsClosed) return;
    if (fromTime !== formatTime(fromWatch)) {
      updateTime(formatTime(fromWatch), 'from');
    }
    if (untilTime !== formatTime(untilWatch)) {
      updateTime(formatTime(untilWatch), 'until');
    }
  }, [fromWatch, untilWatch]);
  useEffect(() => {
    methods.setValue('dayIsClosed', dayIsClosed);
    methods.setValue('fromTime', parseTime(fromTime));
    methods.setValue('untilTime', parseTime(untilTime));
  }, [dayIsClosed]);

  return (
    <FormProvider {...methods}>
      <div className='switch-container'>
        <Slider checked={!dayIsClosed} setChecked={updateDayIsClosed} />
      </div>
      <span className='status'>{dayIsClosed ? <>Stengt</> : <>Åpent</>}</span>
      <div className='time'>
        {!dayIsClosed && (
          <React.Fragment>
            <TimePicker inline name='fromTime' />
            <span className='dash'>til</span>
            <TimePicker inline name='untilTime' />
          </React.Fragment>
        )}
      </div>
    </FormProvider>
  );
};
