import { xml } from '@codemirror/lang-xml';
import { EditorView } from '@codemirror/view';
import { githubDark } from '@uiw/codemirror-theme-github';
import CodeMirror from '@uiw/react-codemirror';
import React, { useState, useEffect } from 'react';
import { Button } from '../../../components/Button/Button';
import { Confirm } from '../../../components/Confirm';
import { isSuperAdmin } from '../../../components/util/RenderIfSuperAdmin';
import { getXmlData, updateXmlData } from '../../../redux/actions/appData';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  clearSelectedAdminTool,
  setSelectedAdminTool
} from '../../../redux/slice/ui';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import './Godmode.scss';

export const Godmode = () => {
  const updateXmlDataLoading = useAppSelector(
    state => state.appData.updateXmlDataLoading
  );
  const getXmlDataLoading = useAppSelector(
    state => state.appData.getXmlDataLoading
  );
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  const dispatch = useAppDispatch();
  const [hasChanged, setHasChanged] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [xmlData, setXmlData] = useState({
    version: 0,
    xml: ''
  });
  const superAdmin = isSuperAdmin();
  useEffect(() => {
    if (
      selectedAdminTool !== 'godmode' ||
      (xmlData.xml === '' && !getXmlDataLoading)
    ) {
      dispatch(setSelectedAdminTool('godmode'));
      if (superAdmin) {
        dispatch(getXmlData())
          .unwrap()
          .then(data => {
            setXmlData(data);
          });
      }
    }
  }, [selectedAdminTool, xmlData, getXmlDataLoading, dispatch, superAdmin]);

  const cancelConfirm = () => {
    setShowConfirm(false);
  };

  const doSomething = () => {
    if (xmlData.xml !== '' && xmlData.version !== 0) {
      dispatch(updateXmlData(xmlData));
      setXmlData({
        ...xmlData,
        version: xmlData.version + 1
      });
      setHasChanged(false);
      setShowConfirm(false);
    }
  };

  if (superAdmin) {
    return (
      <section className='godmode' aria-labelledby={'godmodePageTitle'}>
        <ControlpanelBreadcrumbs />
        <Confirm
          title='Lagre'
          message='Er du sikker på at du vil lagre? Endringer kan ta ned tjenesten.'
          doSomething={doSomething}
          isModalOpen={showConfirm}
          closeModal={cancelConfirm}
        />
        {!updateXmlDataLoading && (
          <div className='header'>
            <h1 id={'godmodePageTitle'}>GODMODE</h1>
            {hasChanged && (
              <div className='buttons'>
                <Button
                  label='Avbryt'
                  type='secondary'
                  linkTo='/'
                  onClick={() => clearSelectedAdminTool()}
                />
                <Button
                  label='Lagre'
                  type='primary'
                  onClick={() => {
                    setShowConfirm(true);
                  }}
                />
              </div>
            )}
          </div>
        )}
        {xmlData.xml ? (
          <CodeMirror
            className='CodeMirror'
            value={xmlData.xml}
            extensions={[xml(), EditorView.cspNonce.of(myNonce)]}
            theme={githubDark}
            onChange={data => {
              if (!hasChanged) setHasChanged(true);
              setXmlData({ ...xmlData, xml: data });
            }}
          />
        ) : (
          <div className='lds-ring'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </section>
    );
  } else {
    return (
      <section className='godmode'>
        <h1>Du har ikke tilgang til godmode</h1>
      </section>
    );
  }
};
