import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { CaretSvg } from '../../assets/CaretSvg';
import { DialogFormBox } from '../DialogBox/DialogFormBox';
import './EditableValue.scss';

type Props<T extends FieldValues> = {
  methods: ReturnType<typeof useForm<T>>;
  label: string;
  value: ReactElement | string;
  mainButtonClick: (data: T) => void;
  link?: string;
};
export const EditableValue = <T extends FieldValues>({
  label,
  value,
  mainButtonClick,
  link,
  methods,
  children
}: PropsWithChildren<Props<T>>) => {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <dl className='editable-value'>
      <dt>{label}</dt>
      <dd>
        {link ? (
          <Link to={link}>
            <span>{value}</span>
          </Link>
        ) : (
          <span>{value}</span>
        )}
        <span
          className='edit-icon'
          onClick={() => {
            setShowPopup(true);
          }}
        >
          <CaretSvg />
        </span>
        <DialogFormBox
          methods={methods}
          onSubmit={data => {
            mainButtonClick(data);
            setShowPopup(false);
          }}
          isModalOpen={showPopup}
          closeModal={() => setShowPopup(false)}
          header={'Endre ' + label.toLowerCase()}
        >
          {children}
        </DialogFormBox>
      </dd>
    </dl>
  );
};
