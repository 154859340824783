import {
  OpeningHours,
  SimpleDayRule,
  TimeIntervalsList
} from '../../../../../../redux/types/common';

export const updateDayIsClosed = (
  dayIsClosed: boolean,
  openingHours: OpeningHours,
  simpleDayRule: SimpleDayRule,
  timeInterval: TimeIntervalsList,
  setOpeningHours: (openingHours: OpeningHours) => void
) => {
  const updatedSimpleDayRules = [...openingHours.simpleDayRulesList];

  if (dayIsClosed) {
    const indexToRemove = updatedSimpleDayRules.findIndex(
      rule => rule.day === simpleDayRule.day
    );
    if (indexToRemove !== -1) {
      updatedSimpleDayRules.splice(indexToRemove, 1);
    }
  } else {
    if (
      updatedSimpleDayRules.findIndex(
        rule => rule.day === simpleDayRule.day
      ) === -1
    ) {
      updatedSimpleDayRules.push({
        ...simpleDayRule,
        timeInterval: [{ from: '07:00', until: '20:00' }]
      });
    }
  }

  setOpeningHours({
    ...openingHours,
    simpleDayRulesList: updatedSimpleDayRules
  });
};

export const updateDayTimeInterval = (
  newTimeInterval: TimeIntervalsList,
  openingHours: OpeningHours,
  simpleDayRule: SimpleDayRule,
  timeInterval: TimeIntervalsList,
  setOpeningHours: (openingHours: OpeningHours) => void
) => {
  const updatedSimpleDayRules: SimpleDayRule[] = JSON.parse(
    JSON.stringify(openingHours.simpleDayRulesList)
  );

  const indexToUpdate = updatedSimpleDayRules.findIndex(
    rule => rule.day === simpleDayRule.day
  );

  updatedSimpleDayRules[indexToUpdate].timeInterval = [newTimeInterval];

  setOpeningHours({
    ...openingHours,
    simpleDayRulesList: updatedSimpleDayRules
  });
};
