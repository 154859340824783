import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateNewPvd } from '../pages/controlpanel/pvd/CreateNewPvd';
import { PvdList } from '../pages/controlpanel/pvd/PvdList';
import { listProximityVerificationDevice } from '../redux/actions/pvd';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const PvdRouter = () => {
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedAdminTool !== 'pvd') {
      dispatch(setSelectedAdminTool('pvd'));
      dispatch(listProximityVerificationDevice());
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <section>
      <Routes>
        <Route path='lag-ny' element={<CreateNewPvd />} />
        <Route path='' element={<PvdList />} />
      </Routes>
    </section>
  );
};
