import parse from 'html-react-parser';
import React from 'react';
import { Button } from '../../../../components/Button/Button';
import { KeyStudioCard } from '../../../../components/cards/KeyStudioCard/KeyStudioCard';

interface Props {
  information: string | undefined;
  setEdit: (edit: boolean) => void;
}

export const InfoCard: React.FC<Props> = ({ information, setEdit }) => (
  <KeyStudioCard>
    <h3 className='title'>Tekst</h3>
    <div className='text-container'>
      {information !== undefined && parse(information)}
    </div>
    <Button label='Rediger' onClick={() => setEdit(true)} />
  </KeyStudioCard>
);
