/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.admissioncard
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: admissioncards.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as admissioncards_pb from './admissioncards_pb'; // proto import: "admissioncards.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class AdmissionCardServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateAdmissionCardType = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/CreateAdmissionCardType',
    grpcWeb.MethodType.UNARY,
    admissioncards_pb.CreateAdmissionCardTypeRequest,
    shared_pb.EmptyResponse,
    (request: admissioncards_pb.CreateAdmissionCardTypeRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createAdmissionCardType(
    request: admissioncards_pb.CreateAdmissionCardTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createAdmissionCardType(
    request: admissioncards_pb.CreateAdmissionCardTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createAdmissionCardType(
    request: admissioncards_pb.CreateAdmissionCardTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/CreateAdmissionCardType',
        request,
        metadata || {},
        this.methodDescriptorCreateAdmissionCardType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/CreateAdmissionCardType',
    request,
    metadata || {},
    this.methodDescriptorCreateAdmissionCardType);
  }

  methodDescriptorUpdateAdmissionCardTypeDescription = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateAdmissionCardTypeDescription',
    grpcWeb.MethodType.UNARY,
    admissioncards_pb.UpdateAdmissionCardTypeDescriptionRequest,
    shared_pb.EmptyResponse,
    (request: admissioncards_pb.UpdateAdmissionCardTypeDescriptionRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateAdmissionCardTypeDescription(
    request: admissioncards_pb.UpdateAdmissionCardTypeDescriptionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateAdmissionCardTypeDescription(
    request: admissioncards_pb.UpdateAdmissionCardTypeDescriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateAdmissionCardTypeDescription(
    request: admissioncards_pb.UpdateAdmissionCardTypeDescriptionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateAdmissionCardTypeDescription',
        request,
        metadata || {},
        this.methodDescriptorUpdateAdmissionCardTypeDescription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateAdmissionCardTypeDescription',
    request,
    metadata || {},
    this.methodDescriptorUpdateAdmissionCardTypeDescription);
  }

  methodDescriptorListAdmissionCardTypes = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/ListAdmissionCardTypes',
    grpcWeb.MethodType.UNARY,
    admissioncards_pb.ListAdmissionCardTypesRequest,
    admissioncards_pb.ListAdmissionCardTypesResponse,
    (request: admissioncards_pb.ListAdmissionCardTypesRequest) => {
      return request.serializeBinary();
    },
    admissioncards_pb.ListAdmissionCardTypesResponse.deserializeBinary
  );

  listAdmissionCardTypes(
    request: admissioncards_pb.ListAdmissionCardTypesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<admissioncards_pb.ListAdmissionCardTypesResponse>;

  listAdmissionCardTypes(
    request: admissioncards_pb.ListAdmissionCardTypesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: admissioncards_pb.ListAdmissionCardTypesResponse) => void): grpcWeb.ClientReadableStream<admissioncards_pb.ListAdmissionCardTypesResponse>;

  listAdmissionCardTypes(
    request: admissioncards_pb.ListAdmissionCardTypesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: admissioncards_pb.ListAdmissionCardTypesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/ListAdmissionCardTypes',
        request,
        metadata || {},
        this.methodDescriptorListAdmissionCardTypes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/ListAdmissionCardTypes',
    request,
    metadata || {},
    this.methodDescriptorListAdmissionCardTypes);
  }

  methodDescriptorDescribeAdmissionCardType = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/DescribeAdmissionCardType',
    grpcWeb.MethodType.UNARY,
    admissioncards_pb.DescribeAdmissionCardTypeRequest,
    admissioncards_pb.DescribeAdmissionCardResponse,
    (request: admissioncards_pb.DescribeAdmissionCardTypeRequest) => {
      return request.serializeBinary();
    },
    admissioncards_pb.DescribeAdmissionCardResponse.deserializeBinary
  );

  describeAdmissionCardType(
    request: admissioncards_pb.DescribeAdmissionCardTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<admissioncards_pb.DescribeAdmissionCardResponse>;

  describeAdmissionCardType(
    request: admissioncards_pb.DescribeAdmissionCardTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: admissioncards_pb.DescribeAdmissionCardResponse) => void): grpcWeb.ClientReadableStream<admissioncards_pb.DescribeAdmissionCardResponse>;

  describeAdmissionCardType(
    request: admissioncards_pb.DescribeAdmissionCardTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: admissioncards_pb.DescribeAdmissionCardResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/DescribeAdmissionCardType',
        request,
        metadata || {},
        this.methodDescriptorDescribeAdmissionCardType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/DescribeAdmissionCardType',
    request,
    metadata || {},
    this.methodDescriptorDescribeAdmissionCardType);
  }

  methodDescriptorToggleAdmissionCardPublishedStatus = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/ToggleAdmissionCardPublishedStatus',
    grpcWeb.MethodType.UNARY,
    admissioncards_pb.ToggleAdmissionCardPublishedStatusRequest,
    shared_pb.EmptyResponse,
    (request: admissioncards_pb.ToggleAdmissionCardPublishedStatusRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  toggleAdmissionCardPublishedStatus(
    request: admissioncards_pb.ToggleAdmissionCardPublishedStatusRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  toggleAdmissionCardPublishedStatus(
    request: admissioncards_pb.ToggleAdmissionCardPublishedStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  toggleAdmissionCardPublishedStatus(
    request: admissioncards_pb.ToggleAdmissionCardPublishedStatusRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/ToggleAdmissionCardPublishedStatus',
        request,
        metadata || {},
        this.methodDescriptorToggleAdmissionCardPublishedStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/ToggleAdmissionCardPublishedStatus',
    request,
    metadata || {},
    this.methodDescriptorToggleAdmissionCardPublishedStatus);
  }

  methodDescriptorUpdateRelevantSubjects = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateRelevantSubjects',
    grpcWeb.MethodType.UNARY,
    admissioncards_pb.UpdateRelevantSubjectsRequest,
    shared_pb.EmptyResponse,
    (request: admissioncards_pb.UpdateRelevantSubjectsRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateRelevantSubjects(
    request: admissioncards_pb.UpdateRelevantSubjectsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateRelevantSubjects(
    request: admissioncards_pb.UpdateRelevantSubjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateRelevantSubjects(
    request: admissioncards_pb.UpdateRelevantSubjectsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateRelevantSubjects',
        request,
        metadata || {},
        this.methodDescriptorUpdateRelevantSubjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateRelevantSubjects',
    request,
    metadata || {},
    this.methodDescriptorUpdateRelevantSubjects);
  }

  methodDescriptorUpdateRequiredTerms = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateRequiredTerms',
    grpcWeb.MethodType.UNARY,
    admissioncards_pb.UpdateRequiredTermsRequest,
    shared_pb.EmptyResponse,
    (request: admissioncards_pb.UpdateRequiredTermsRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateRequiredTerms(
    request: admissioncards_pb.UpdateRequiredTermsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateRequiredTerms(
    request: admissioncards_pb.UpdateRequiredTermsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateRequiredTerms(
    request: admissioncards_pb.UpdateRequiredTermsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateRequiredTerms',
        request,
        metadata || {},
        this.methodDescriptorUpdateRequiredTerms,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateRequiredTerms',
    request,
    metadata || {},
    this.methodDescriptorUpdateRequiredTerms);
  }

  methodDescriptorUpdatePresentation = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdatePresentation',
    grpcWeb.MethodType.UNARY,
    admissioncards_pb.UpdatePresentationRequest,
    shared_pb.EmptyResponse,
    (request: admissioncards_pb.UpdatePresentationRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updatePresentation(
    request: admissioncards_pb.UpdatePresentationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updatePresentation(
    request: admissioncards_pb.UpdatePresentationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updatePresentation(
    request: admissioncards_pb.UpdatePresentationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdatePresentation',
        request,
        metadata || {},
        this.methodDescriptorUpdatePresentation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdatePresentation',
    request,
    metadata || {},
    this.methodDescriptorUpdatePresentation);
  }

  methodDescriptorUpdateIcon = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateIcon',
    grpcWeb.MethodType.UNARY,
    admissioncards_pb.UpdateIconRequest,
    shared_pb.EmptyResponse,
    (request: admissioncards_pb.UpdateIconRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateIcon(
    request: admissioncards_pb.UpdateIconRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateIcon(
    request: admissioncards_pb.UpdateIconRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateIcon(
    request: admissioncards_pb.UpdateIconRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateIcon',
        request,
        metadata || {},
        this.methodDescriptorUpdateIcon,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateIcon',
    request,
    metadata || {},
    this.methodDescriptorUpdateIcon);
  }

  methodDescriptorUpdateBackgroundColor = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateBackgroundColor',
    grpcWeb.MethodType.UNARY,
    admissioncards_pb.UpdateBackgroundColorRequest,
    shared_pb.EmptyResponse,
    (request: admissioncards_pb.UpdateBackgroundColorRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateBackgroundColor(
    request: admissioncards_pb.UpdateBackgroundColorRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateBackgroundColor(
    request: admissioncards_pb.UpdateBackgroundColorRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateBackgroundColor(
    request: admissioncards_pb.UpdateBackgroundColorRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateBackgroundColor',
        request,
        metadata || {},
        this.methodDescriptorUpdateBackgroundColor,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardService/UpdateBackgroundColor',
    request,
    metadata || {},
    this.methodDescriptorUpdateBackgroundColor);
  }

}

