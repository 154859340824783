import { useEffect } from 'react';
import { AuditLog, fetchEntityAuditLog } from '../../redux/actions/auditLogs';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { auditLogSelectors } from '../../redux/slice/auditLogs';

export const useAuditLog = (citykeyId: string): AuditLog[] => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.auditLogs);
  useEffect(() => {
    if (!isLoading) {
      dispatch(fetchEntityAuditLog({ citykeyId }));
    }
  }, []);
  return useAppSelector(auditLogSelectors.selectAll).filter(
    value => value.resourceId === citykeyId
  );
};
