import React from 'react';
import { PageInfoText } from '../../../components/PageInfoText/PageInfoText';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { useAppSelector } from '../../../redux/hooks';
import { thirdPartySystemSelectors } from '../../../redux/slice/thirdPartySystems';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';

export const TPSList = () => {
  const thirdPartySystems = useAppSelector(thirdPartySystemSelectors.selectAll);

  if (thirdPartySystems.length > 0) {
    const tableHeadings = ['ID', 'Navn', 'Informasjon', 'Adminrolle'];
    const data = thirdPartySystems.map(tps => {
      return {
        cells: [
          <CopyWrapper key={tps.id} copyValue={tps.id} />,
          tps.name,
          tps.information,
          <CopyWrapper key={tps.adminRole} copyValue={tps.adminRole} />
        ],
        link: tps.id
      };
    });

    return (
      <React.Fragment>
        <TableWithButtons
          ting='tredjepartssystem'
          buttons={[
            {
              label: 'Lag nytt tredjepartssystem',
              type: 'primary',
              accessKey: 'n',
              linkTo: 'lag-ny'
            }
          ]}
          table={{ headings: tableHeadings, rows: data }}
        />
      </React.Fragment>
    );
  } else {
    return (
      <PageInfoText>
        Du har ikke satt opp noen tredjepartssystemer.
      </PageInfoText>
    );
  }
};
