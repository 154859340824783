import React from 'react';

export const ClockIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='80'
    height='80'
    viewBox='0 0 80 80'
  >
    <path
      id='Path_2922'
      data-name='Path 2922'
      d='M6.73,41A34.27,34.27,0,1,0,41,6.73,34.272,34.272,0,0,0,6.73,41ZM1,41A40,40,0,1,1,41,81,40,40,0,0,1,1,41ZM41,17V41l18.667-1.587v6.921h-24V17Z'
      transform='translate(-1 -1)'
      fill='#2c2c2c'
    />
  </svg>
);
