/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.operation
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: operation.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as operation_pb from './operation_pb'; // proto import: "operation.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class OperationServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorUpdateAgeLimit = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateAgeLimit',
    grpcWeb.MethodType.UNARY,
    operation_pb.UpdateAgeLimitMessage,
    shared_pb.EmptyResponse,
    (request: operation_pb.UpdateAgeLimitMessage) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateAgeLimit(
    request: operation_pb.UpdateAgeLimitMessage,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateAgeLimit(
    request: operation_pb.UpdateAgeLimitMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateAgeLimit(
    request: operation_pb.UpdateAgeLimitMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateAgeLimit',
        request,
        metadata || {},
        this.methodDescriptorUpdateAgeLimit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateAgeLimit',
    request,
    metadata || {},
    this.methodDescriptorUpdateAgeLimit);
  }

  methodDescriptorUpdateEntityType = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateEntityType',
    grpcWeb.MethodType.UNARY,
    operation_pb.UpdateEntityTypeRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.UpdateEntityTypeRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateEntityType(
    request: operation_pb.UpdateEntityTypeRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateEntityType(
    request: operation_pb.UpdateEntityTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateEntityType(
    request: operation_pb.UpdateEntityTypeRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateEntityType',
        request,
        metadata || {},
        this.methodDescriptorUpdateEntityType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateEntityType',
    request,
    metadata || {},
    this.methodDescriptorUpdateEntityType);
  }

  methodDescriptorClearAgeLimit = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/ClearAgeLimit',
    grpcWeb.MethodType.UNARY,
    operation_pb.ClearAgeLimitMessage,
    shared_pb.EmptyResponse,
    (request: operation_pb.ClearAgeLimitMessage) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  clearAgeLimit(
    request: operation_pb.ClearAgeLimitMessage,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  clearAgeLimit(
    request: operation_pb.ClearAgeLimitMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  clearAgeLimit(
    request: operation_pb.ClearAgeLimitMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/ClearAgeLimit',
        request,
        metadata || {},
        this.methodDescriptorClearAgeLimit,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/ClearAgeLimit',
    request,
    metadata || {},
    this.methodDescriptorClearAgeLimit);
  }

  methodDescriptorCreateOperation = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/CreateOperation',
    grpcWeb.MethodType.UNARY,
    operation_pb.CreateOperationRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.CreateOperationRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createOperation(
    request: operation_pb.CreateOperationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createOperation(
    request: operation_pb.CreateOperationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createOperation(
    request: operation_pb.CreateOperationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/CreateOperation',
        request,
        metadata || {},
        this.methodDescriptorCreateOperation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/CreateOperation',
    request,
    metadata || {},
    this.methodDescriptorCreateOperation);
  }

  methodDescriptorUpdateOperationName = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationName',
    grpcWeb.MethodType.UNARY,
    operation_pb.UpdateOperationNameRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.UpdateOperationNameRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateOperationName(
    request: operation_pb.UpdateOperationNameRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateOperationName(
    request: operation_pb.UpdateOperationNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateOperationName(
    request: operation_pb.UpdateOperationNameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationName',
        request,
        metadata || {},
        this.methodDescriptorUpdateOperationName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationName',
    request,
    metadata || {},
    this.methodDescriptorUpdateOperationName);
  }

  methodDescriptorUpdateOperationAbout = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationAbout',
    grpcWeb.MethodType.UNARY,
    operation_pb.UpdateOperationAboutRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.UpdateOperationAboutRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateOperationAbout(
    request: operation_pb.UpdateOperationAboutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateOperationAbout(
    request: operation_pb.UpdateOperationAboutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateOperationAbout(
    request: operation_pb.UpdateOperationAboutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationAbout',
        request,
        metadata || {},
        this.methodDescriptorUpdateOperationAbout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationAbout',
    request,
    metadata || {},
    this.methodDescriptorUpdateOperationAbout);
  }

  methodDescriptorUpdateOperationTerms = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationTerms',
    grpcWeb.MethodType.UNARY,
    operation_pb.UpdateOperationTermsRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.UpdateOperationTermsRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateOperationTerms(
    request: operation_pb.UpdateOperationTermsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateOperationTerms(
    request: operation_pb.UpdateOperationTermsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateOperationTerms(
    request: operation_pb.UpdateOperationTermsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationTerms',
        request,
        metadata || {},
        this.methodDescriptorUpdateOperationTerms,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationTerms',
    request,
    metadata || {},
    this.methodDescriptorUpdateOperationTerms);
  }

  methodDescriptorUpdateOperationExecuteMeters = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationExecuteMeters',
    grpcWeb.MethodType.UNARY,
    operation_pb.UpdateOperationExecuteMetersRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.UpdateOperationExecuteMetersRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateOperationExecuteMeters(
    request: operation_pb.UpdateOperationExecuteMetersRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateOperationExecuteMeters(
    request: operation_pb.UpdateOperationExecuteMetersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateOperationExecuteMeters(
    request: operation_pb.UpdateOperationExecuteMetersRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationExecuteMeters',
        request,
        metadata || {},
        this.methodDescriptorUpdateOperationExecuteMeters,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationExecuteMeters',
    request,
    metadata || {},
    this.methodDescriptorUpdateOperationExecuteMeters);
  }

  methodDescriptorUpdateOperationActionChain = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationActionChain',
    grpcWeb.MethodType.UNARY,
    operation_pb.UpdateOperationActionChainRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.UpdateOperationActionChainRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateOperationActionChain(
    request: operation_pb.UpdateOperationActionChainRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateOperationActionChain(
    request: operation_pb.UpdateOperationActionChainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateOperationActionChain(
    request: operation_pb.UpdateOperationActionChainRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationActionChain',
        request,
        metadata || {},
        this.methodDescriptorUpdateOperationActionChain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationActionChain',
    request,
    metadata || {},
    this.methodDescriptorUpdateOperationActionChain);
  }

  methodDescriptorUpdateProximityVerificationDeviceId = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateProximityVerificationDeviceId',
    grpcWeb.MethodType.UNARY,
    operation_pb.UpdateProximityVerificationDeviceIdRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.UpdateProximityVerificationDeviceIdRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateProximityVerificationDeviceId(
    request: operation_pb.UpdateProximityVerificationDeviceIdRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateProximityVerificationDeviceId(
    request: operation_pb.UpdateProximityVerificationDeviceIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateProximityVerificationDeviceId(
    request: operation_pb.UpdateProximityVerificationDeviceIdRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateProximityVerificationDeviceId',
        request,
        metadata || {},
        this.methodDescriptorUpdateProximityVerificationDeviceId,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateProximityVerificationDeviceId',
    request,
    metadata || {},
    this.methodDescriptorUpdateProximityVerificationDeviceId);
  }

  methodDescriptorUpdateOperationImage = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationImage',
    grpcWeb.MethodType.UNARY,
    operation_pb.UpdateOperationImageRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.UpdateOperationImageRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateOperationImage(
    request: operation_pb.UpdateOperationImageRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateOperationImage(
    request: operation_pb.UpdateOperationImageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateOperationImage(
    request: operation_pb.UpdateOperationImageRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationImage',
        request,
        metadata || {},
        this.methodDescriptorUpdateOperationImage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationImage',
    request,
    metadata || {},
    this.methodDescriptorUpdateOperationImage);
  }

  methodDescriptorUpdateOperationBiometricsRequirement = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationBiometricsRequirement',
    grpcWeb.MethodType.UNARY,
    operation_pb.UpdateOperationBiometricsRequirementRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.UpdateOperationBiometricsRequirementRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateOperationBiometricsRequirement(
    request: operation_pb.UpdateOperationBiometricsRequirementRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateOperationBiometricsRequirement(
    request: operation_pb.UpdateOperationBiometricsRequirementRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateOperationBiometricsRequirement(
    request: operation_pb.UpdateOperationBiometricsRequirementRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationBiometricsRequirement',
        request,
        metadata || {},
        this.methodDescriptorUpdateOperationBiometricsRequirement,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/UpdateOperationBiometricsRequirement',
    request,
    metadata || {},
    this.methodDescriptorUpdateOperationBiometricsRequirement);
  }

  methodDescriptorDeleteOperation = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/DeleteOperation',
    grpcWeb.MethodType.UNARY,
    operation_pb.DeleteOperationRequest,
    shared_pb.EmptyResponse,
    (request: operation_pb.DeleteOperationRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteOperation(
    request: operation_pb.DeleteOperationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteOperation(
    request: operation_pb.DeleteOperationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteOperation(
    request: operation_pb.DeleteOperationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/DeleteOperation',
        request,
        metadata || {},
        this.methodDescriptorDeleteOperation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.operation.OperationService/DeleteOperation',
    request,
    metadata || {},
    this.methodDescriptorDeleteOperation);
  }

}

