import React from 'react';

export const CaretSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='8.727'
    height='16'
    viewBox='0 0 8.727 16'
  >
    <path
      id='Path_3032'
      data-name='Path 3032'
      d='M6.846,8,0,15.034.941,16,8.727,8,.941,0,0,.966Z'
      fill='#2a2859'
      fillRule='evenodd'
    />
  </svg>
);
