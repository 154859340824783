import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { z } from 'zod';
import { HealthColor, Result } from '../../proto/shared_pb';
import { renameActionChain } from '../actions/adapters';

export type ActionItem = {
  adapterId: string;
  thingId: string;
  actionId: string;
  actionUri: string;
  sendFodselsnummer: boolean;
};

export const fullOperationId = z.object({
  subjectId: z.string(),
  operationId: z.string()
});
export type FullOpertaionId = z.infer<typeof fullOperationId>;

export interface FullActionId {
  adapterId: string;
  thingId: string;
  actionId: string;
}

export interface ActionChain {
  id: string;
  name: string;
  version: number;
  uri?: string;
  actionsList: ActionItem[];
  usedByOperationsList: FullOpertaionId[];
}

interface Action {
  id: string;
  healthStatus: boolean;
  healthMessage: string;
  actionUri: string;
  description: string;
  partOfActionChainsList: string[];
  responseHealth?: HealthStatus;
  manifestHealth?: HealthStatus;
  usedByOperationsList: FullOpertaionId[];
  healthApi?: {
    apiKey: string;
    pollUri: string;
  };
  betterUptimeMonitorId? : string;
  recordsList: RecordsList[];
  responseTimeSummary?: { minMs: number; maxMs: number; avgMs: number };
}

export interface Thing {
  id: string;
  thingUri: string;
  description: string;
  ownerAdminRole: string;
  manufacturer: string;
  deviceType: string;
  firmwareVersion: string;
  link: string;
  actionsList: Action[];
  lastSeen?: ThingLastSeen;
  network?: Network;
}

export interface ThingLastSeen {
  explanation: string;
  timestampEpochSeconds: number;
}

export interface Network {
  ipAddress: string;
  macAddress: string;
  rssi: number;
  lastConnectedAtEpochSeconds: number;
  lastDisconnectedAtEpochSeconds: number;
}

export interface AdapterDescription {
  id: string;
  uri: string;
  version: number;
  adapterScrapeSummary?: {
    lastScrapeTimestamp: number;
    lastSuccessfulScrapeTimestamp: number;
    lastScrapeDurationMs: number;
    lastScrapeTraceId: string;
  };
  sandboxed: boolean;
  selfTestUri: string;
  scrapeUri: string;
}
export interface ActionSummary {
  uri: string;
  adapterId: string;
  thingId: string;
  actionId: string;
  thingDescription: string;
  actionDescription: string;
}

export interface AdapterDetails {
  description: AdapterDescription;
  thingsList: Thing[];
}

export interface HealthStatus {
  color: HealthColor;
  summary: string;
}

export interface RecordsList {
  result: Result;
  timestamp: number;
  traceId: string;
  code: string;
  responseTimeMs: number;
}

export interface AdapterState {
  adapterDetails: AdapterDetails;
  descriptions: AdapterDescription[];
  actionChains: ActionChain[];
  actionsList: ActionSummary[];
}

export const initialState: AdapterState = {
  adapterDetails: {
    thingsList: [],
    description: { id: '' } as AdapterDescription
  },
  descriptions: [],
  actionChains: [],
  actionsList: []
};

const adaptersSlice = createSlice({
  name: 'adapters',
  initialState,
  reducers: {
    setAdapterDescriptions: (
      state,
      action: PayloadAction<AdapterDescription[]>
    ) => {
      state.descriptions = action.payload;
    },
    setAdapterDetails: (state, action: PayloadAction<AdapterDetails>) => {
      state.adapterDetails = action.payload;
    },
    setActionChains: (state, action: PayloadAction<ActionChain[]>) => {
      state.actionChains = action.payload;
    },
    setActionsList: (state, action: PayloadAction<ActionSummary[]>) => {
      state.actionsList = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(renameActionChain.fulfilled, (state, { payload }) => {
      state.actionChains = state.actionChains.map(chain => {
        if (chain.id === payload.id) {
          return { ...chain, name: payload.name };
        }
        return chain;
      });
    });
  }
});

export const {
  setAdapterDescriptions,
  setAdapterDetails,
  setActionChains,
  setActionsList
} = adaptersSlice.actions;
export default adaptersSlice.reducer;
