import React from 'react';

export const DeactivateIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='14'
    viewBox='0 0 14 14'
  >
    <g
      id='Group_12255'
      data-name='Group 12255'
      transform='translate(-776 -1668)'
    >
      <g
        id='Ellipse_753'
        data-name='Ellipse 753'
        transform='translate(776 1668)'
        fill='none'
        stroke='#292858'
        strokeWidth='1'
      >
        <circle cx='7' cy='7' r='7' stroke='none' />
        <circle cx='7' cy='7' r='6.5' fill='none' />
      </g>
      <g
        id='Group_12254'
        data-name='Group 12254'
        transform='translate(778.416 1672.212)'
      >
        <line
          id='Line_123'
          data-name='Line 123'
          y1='4.437'
          transform='translate(0 0) rotate(-45)'
          fill='none'
          stroke='#292858'
          strokeWidth='1'
        />
        <line
          id='Line_125'
          data-name='Line 125'
          y1='4.437'
          transform='translate(5.842 0) rotate(-45)'
          fill='none'
          stroke='#292858'
          strokeWidth='1'
        />
        <line
          id='Line_124'
          data-name='Line 124'
          x1='4.437'
          transform='translate(0 3.138) rotate(-45)'
          fill='none'
          stroke='#292858'
          strokeWidth='1'
        />
        <line
          id='Line_126'
          data-name='Line 126'
          x1='4.437'
          transform='translate(5.842 3.138) rotate(-45)'
          fill='none'
          stroke='#292858'
          strokeWidth='1'
        />
      </g>
      <line
        id='Line_127'
        data-name='Line 127'
        x2='6.043'
        transform='translate(779.885 1677.927)'
        fill='none'
        stroke='#292858'
        strokeWidth='1'
      />
    </g>
  </svg>
);
