import React from 'react';

export const BookedKeychain = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='46'
    height='46'
    viewBox='0 0 46 46'
  >
    <g id='Group_1982' data-name='Group 1982' transform='translate(-608 -688)'>
      <circle
        id='Ellipse_603'
        data-name='Ellipse 603'
        cx='23'
        cy='23'
        r='23'
        transform='translate(608 688)'
        fill='#f9c66b'
      />
      <g
        id='Group_1979'
        data-name='Group 1979'
        transform='translate(4100.543 473.769)'
      >
        <g
          id='Rectangle_1597'
          data-name='Rectangle 1597'
          transform='translate(-3482.543 226.231)'
          fill='none'
          stroke='#292858'
          strokeWidth='2'
        >
          <rect width='25' height='6' stroke='none' />
          <rect x='1' y='1' width='23' height='4' fill='none' />
        </g>
        <g
          id='Rectangle_1598'
          data-name='Rectangle 1598'
          transform='translate(-3482.543 226.231)'
          fill='none'
          stroke='#292858'
          strokeWidth='2'
        >
          <rect width='25' height='20' stroke='none' />
          <rect x='1' y='1' width='23' height='18' fill='none' />
        </g>
        <g
          id='Rectangle_1599'
          data-name='Rectangle 1599'
          transform='translate(-3477.543 224.231)'
          fill='none'
          stroke='#292858'
          strokeWidth='1'
        >
          <rect width='2' height='3' stroke='none' />
          <rect x='0.5' y='0.5' width='1' height='2' fill='none' />
        </g>
        <g
          id='Rectangle_1600'
          data-name='Rectangle 1600'
          transform='translate(-3464.543 224.231)'
          fill='none'
          stroke='#292858'
          strokeWidth='1'
        >
          <rect width='2' height='3' stroke='none' />
          <rect x='0.5' y='0.5' width='1' height='2' fill='none' />
        </g>
        <path
          id='Path_4481'
          data-name='Path 4481'
          d='M-3542.185,253h10.329'
          transform='translate(64.642 -17.769)'
          fill='none'
          stroke='#292858'
          strokeWidth='2'
        />
        <path
          id='Path_4482'
          data-name='Path 4482'
          d='M-3542.185,253h15.694'
          transform='translate(64.642 -13.769)'
          fill='none'
          stroke='#292858'
          strokeWidth='2'
        />
        <circle
          id='Ellipse_602'
          data-name='Ellipse 602'
          cx='7'
          cy='7'
          r='7'
          transform='translate(-3468.849 236.115)'
          fill='#43f8b6'
        />
        <path
          id='Path_4484'
          data-name='Path 4484'
          d='M-3510.242,236.824l3.021,4.231,8.3-8.3'
          transform='translate(45.686 3.361)'
          fill='none'
          stroke='#292858'
          strokeWidth='3'
        />
      </g>
    </g>
  </svg>
);
