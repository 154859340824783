import React from 'react';

export const Door = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='137'
    height='137'
    viewBox='0 0 137 137'
  >
    <g
      id='Group_12158'
      data-name='Group 12158'
      transform='translate(-570 -625)'
    >
      <circle
        id='Ellipse_699'
        data-name='Ellipse 699'
        cx='68.5'
        cy='68.5'
        r='68.5'
        transform='translate(570 625)'
        fill='#deeef7'
      />
      <g id='noun-door-4667251' transform='translate(610.629 659.214)'>
        <path
          id='Path_3014'
          data-name='Path 3014'
          d='M198.33,36.6a1.6,1.6,0,0,1,1.6-1.6H238.39a1.6,1.6,0,0,1,1.6,1.6V100.7a1.6,1.6,0,0,1-1.6,1.6H199.933a1.6,1.6,0,0,1-1.6-1.6Zm3.205,1.6V99.1h35.252V38.2Z'
          transform='translate(-190.319 -35)'
          fillRule='evenodd'
        />
        <path
          id='Path_3015'
          data-name='Path 3015'
          d='M148.013,36.6a1.6,1.6,0,0,1,1.6-1.6h38.457a1.6,1.6,0,0,1,1.6,1.6V99.1h8.012v3.2h-9.614a1.6,1.6,0,0,1-1.6-1.6V38.2H151.219V100.7a1.6,1.6,0,0,1-1.6,1.6H140V99.1h8.012Z'
          transform='translate(-140.002 -35)'
          fillRule='evenodd'
        />
        <path
          id='Path_3016'
          data-name='Path 3016'
          d='M408.33,242.944V233.33h3.205v9.614Z'
          transform='translate(-371.476 -206.09)'
          fillRule='evenodd'
        />
      </g>
    </g>
  </svg>
);
