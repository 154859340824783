import { useJsApiLoader } from '@react-google-maps/api';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { googleMapOptions } from '../../constants';
import { updateSubjectCoordinates } from '../../redux/actions/subjects';
import { dispatchAndHandleResult } from '../../redux/actions/util';
import { useAppDispatch } from '../../redux/hooks';
import { Subject } from '../../redux/slice/subjects';
import './Location.scss';
import { MapView } from './MapView';
import { SubjectBreadcrumbs } from './SubjectBreadcrumbs';
import { useSubject } from './SubjectWrapper';

export interface Coordinates {
  lat: number;
  lng: number;
}

const defaultCoordinates: Coordinates = {
  lat: 59.91329,
  lng: 10.74567
};
export const Location: React.FC = () => {
  const navigate = useNavigate();
  const { subject } = useSubject();
  const dispatch = useAppDispatch();

  const [markerCoordinates, setMarkerCoordinates] =
    useState<Coordinates>(defaultCoordinates);
  const [mapCenterCoordinates, setMapCenterCoordinates] =
    useState<Coordinates>(defaultCoordinates);
  const [radii, setRadii] = useState<[number, number]>([0, 0]);
  const { isLoaded, loadError } = useJsApiLoader(googleMapOptions);
  if (loadError) {
    console.error(loadError);
  }

  const [justLoaded, setJustLoaded] = useState(true);

  useEffect(() => {
    if (justLoaded && isLoaded) {
      if (subject) {
        resetCoordinates(subject);
        const operations = subject.operationsList;

        if (operations.length) {
          const radiuses = operations.map(
            operation => operation.mustBeWithinMetersToExecute
          );

          const highest = radiuses.reduce((prevHigh, cur) => {
            return cur > prevHigh ? cur : prevHigh;
          });

          const lowest = radiuses.reduce((prev, cur) => {
            return cur < prev && cur > 0 ? cur : prev;
          });
          setRadii([lowest, highest]);
        }
        setJustLoaded(false);
      }
    }
  }, [justLoaded, isLoaded, subject]);

  const resetCoordinates = (subject: Subject) => {
    const subjectCoordinates = {
      lat: subject?.coordinates?.lat || defaultCoordinates.lat,
      lng: subject?.coordinates?.lon || defaultCoordinates.lng
    };
    setMapCenterCoordinates(subjectCoordinates);
    setMarkerCoordinates(subjectCoordinates);
  };
  const navigateToSubject = () => {
    navigate('..', { relative: 'path' });
  };

  const handleSaveClick = () =>
    dispatchAndHandleResult(
      dispatch,
      updateSubjectCoordinates,
      {
        subjectId: subject!.id,
        coordinates: markerCoordinates
      },
      () => navigateToSubject()
    );

  return (
    <div className='location'>
      <SubjectBreadcrumbs
        subject={subject}
        name='GPS-posisjonen til stedet i appen'
      />
      {isLoaded ? (
        <React.Fragment>
          <MapView
            mapCenterCoordinates={mapCenterCoordinates}
            markerCoordinates={markerCoordinates}
            setMarkerCoordinates={setMarkerCoordinates}
            radii={radii}
          />
          <div className='button-row'>
            <Button
              label='Avbryt'
              type='secondary'
              onClick={navigateToSubject}
            />
            <Button label='Lagre' type='primary' onClick={handleSaveClick} />
          </div>
        </React.Fragment>
      ) : loadError ? (
        <div>Feil under lasting av kart: {loadError.message}</div>
      ) : (
        <div>Last inn kart...</div>
      )}
    </div>
  );
};
