import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { DynamicTag } from '../../proto/shared_pb';
import { getAppData } from '../actions/appData';
import { convertToSubject } from '../actions/subjects';
import { RootState } from '../index';
import { ImageData, Keypedia } from '../types/common';
import { Operation } from './operations';

export interface Subject {
  citykeyId: string;
  id: string;
  name: string;
  categoryId: string;
  adminRole: string;
  coordinates?: Coordinates;
  imagesList: Array<ImageData>;
  operationsList: Array<Operation>;
  anomalyMessage: AnomalyMessage;
  visibleForEveryone: boolean;
  dynamicTagsList: Array<DynamicTag.AsObject>;
  wiki: Keypedia;
  about: string;
  inProduction: boolean;
}

export interface AnomalyMessage {
  htmlContent: string;
  author: string;
  timestampMillis: number;
}
interface Coordinates {
  lat: number;
  lon: number;
}

const subjectAdapter = createEntityAdapter<Subject>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const subjectSlice = createSlice({
  name: 'subjects',
  initialState: subjectAdapter.getInitialState({ isLoading: false }),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAppData.fulfilled, (state, { payload }) => {
        subjectAdapter.setAll(
          state,
          payload.subjectsList.map(subject => convertToSubject(subject))
        );
        state.isLoading = false;
      })
      .addCase(getAppData.pending, state => {
        state.isLoading = true;
      })
      .addCase(getAppData.rejected, state => {
        state.isLoading = false;
      });
  }
});

export const initialState = subjectSlice.getInitialState();
export type SubjectsState = typeof initialState;
export default subjectSlice.reducer;

export const subjectSelectors = subjectAdapter.getSelectors<RootState>(
  state => state.subjects
);
