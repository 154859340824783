import React from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../redux/slice/user';
import { isSuperAdmin } from '../util/RenderIfSuperAdmin';
import './Logo.scss';

const getIconPath = (user: string) => {
  switch (user) {
    case 'isak.solhe':
      return '/Group 12287.png';
    case 'kim.betti@':
      return '/Group 12289.png';
    case 'anders.ekr':
    case 'anders@gma':
      return '/Group 12325.png';
    case 'armin.bard':
    case 'armin.baar':
      return '/Group 12286.png';
    case 'lily.santo':
      return '/Group 12285.png';
    case 'Mr.mamen@o':
      return '/mamen-studio.png';
    case 'hakon.gims':
      return '/Group 12288.png';
    default:
      return `/icon192${process.env.REACT_APP_ENV}.png`;
  }
};
interface Props {
  user: User;
}
export const Logo: React.FC<Props> = ({ user }) => (
  <header className='logo'>
    <Link to='/'>
      <img
        src={getIconPath(user.username.slice(0, 10))}
        alt='KeyStudio'
        width={35}
      />
      {isSuperAdmin() ? <h1>MamenStudio</h1> : <h1>KeyStudio</h1>}
    </Link>
  </header>
);
