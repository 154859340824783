import {
  fetchSubjectVisitations,
  fetchKeychainFactoryVisitations,
  fetchAdmissionCardUsages
} from '../../../redux/actions/visitations';
import { Subject } from '../../../redux/slice/subjects';
import {
  AdmissionCardUsages,
  GraphData,
  GraphDataPoint,
  KeychainFactoryVisitations,
  SubjectVisitations,
  Visitation
} from './statsTypes';

export const getNumOfOpenings = (graphData: GraphData[]) => {
  let openings = 0;
  for (const graph of graphData) {
    for (const data of graph.data) {
      openings += data.visits;
    }
  }
  return openings;
};

const getRandomHexColor = () =>
  '#' + Math.floor(Math.random() * 16777215).toString(16);

const getRandomGraphColor = (graphData: GraphData[]) => {
  const colors = [
    '#292757',
    '#6ce8fe',
    '#b1f3fd',
    '#f6ecda',
    '#014843',
    '#42f7b5',
    '#c5f5c6',
    '#cfbdac',
    '#f8c46a'
  ];

  const randomColor = colors.find(color => {
    return !graphData.find(data => data.color === color);
  });

  return randomColor ? randomColor : getRandomHexColor();
};

const comparator = (a: GraphDataPoint, b: GraphDataPoint) => {
  if (a.timestamp < b.timestamp) return -1;
  if (a.timestamp < b.timestamp) return 1;
  return 0;
};

const existsInDataForGraph = (
  dataForGraph: GraphData[],
  id: string,
  dateIntervalDays: number
) => {
  const dataPoint = dataForGraph.find(data => data.id === id);
  return !!(dataPoint && dataPoint.data.length === dateIntervalDays);
};

export const getNewStatsToFetch = (
  dataForGraph: GraphData[],
  selectedSubjects: string[],
  selectedKeychainFactories: string[],
  selectedAdmissioncards: string[],
  dateInterval: { startDate: Date; endDate: Date }
) => {
  const dateIntervalLength =
    dateInterval.endDate.getTime() - dateInterval.startDate.getTime();
  const dateIntervalDays =
    Math.ceil(dateIntervalLength / (1000 * 3600 * 24)) + 1;

  const subjectStatsToFetch: string[] = selectedSubjects.filter(subject => {
    return !existsInDataForGraph(dataForGraph, subject, dateIntervalDays);
  });

  const keychainFactoryStatsToFetch: string[] =
    selectedKeychainFactories.filter(factory => {
      return !existsInDataForGraph(dataForGraph, factory, dateIntervalDays);
    });

  const admissionCardStatsToFetch: string[] = selectedAdmissioncards.filter(
    card => {
      return !existsInDataForGraph(dataForGraph, card, dateIntervalDays);
    }
  );

  return {
    subjectStatsToFetch,
    keychainFactoryStatsToFetch,
    admissionCardStatsToFetch
  };
};

export const fetchNewStats = async (
  newStatsToFetch: {
    subjectStatsToFetch: string[];
    keychainFactoryStatsToFetch: string[];
    admissionCardStatsToFetch: string[];
  },
  subjects: Subject[],
  dateInterval: { startDate: Date; endDate: Date },
  setLoading: (value: React.SetStateAction<boolean>) => void
) => {
  const newSubjectStats: SubjectVisitations[] = [];
  const newKeychainFactoryStats: KeychainFactoryVisitations[] = [];
  const newAdmissionCardStats: AdmissionCardUsages[] = [];

  for (const subjectId of newStatsToFetch.subjectStatsToFetch) {
    const subject = subjects.find(subject => subject.id === subjectId);
    if (!subject) {
      console.warn("Subject doesn't exist", subjectId);
      continue;
    }
    setLoading(true);
    const subjectStats = await fetchSubjectVisitations(dateInterval, subject);

    setLoading(false);

    newSubjectStats.push({ subjectId, operations: subjectStats });
  }

  for (let i = 0; i < newStatsToFetch.keychainFactoryStatsToFetch.length; i++) {
    const keychainFactoryUri = newStatsToFetch.keychainFactoryStatsToFetch[i];

    setLoading(true);

    const keychainFactoryStats = await fetchKeychainFactoryVisitations(
      dateInterval,
      keychainFactoryUri
    );

    setLoading(false);

    newKeychainFactoryStats.push({
      keychainFactoryUri,
      visits: keychainFactoryStats ? keychainFactoryStats : []
    });
  }

  for (let i = 0; i < newStatsToFetch.admissionCardStatsToFetch.length; i++) {
    const admissionCardId = newStatsToFetch.admissionCardStatsToFetch[i];
    setLoading(true);
    const admissionCardStats = await fetchAdmissionCardUsages(
      dateInterval,
      admissionCardId
    );
    setLoading(false);

    newAdmissionCardStats.push({
      admissionCardId,
      visits: admissionCardStats ? admissionCardStats : []
    });
  }

  return { newSubjectStats, newKeychainFactoryStats, newAdmissionCardStats };
};

/**
 * Returns index of graphData with given subjectId if it exists,
 * otherwise returns -1
 */
const getExistingIndex = (graphData: GraphData[], subjectId: string) => {
  for (let i = 0; i < graphData.length; i++) {
    if (graphData[i].id === subjectId) {
      return i;
    }
  }
  return -1;
};

const addDaysFromVisitation = (days: Visitation[], day: Visitation[]) => {
  for (let i = 0; i < day.length; i++) {
    const { date, successCount } = day[i];
    if (days.some(day => day.date === date)) {
      const index = days.findIndex(day => day.date === date);
      days[index].successCount += successCount;
    } else {
      days.push({ date: date, successCount: successCount });
    }
  }
};

const addGraphDataPointsFromDays = (
  days: Visitation[],
  graphData: GraphData[],
  color: string,
  id: string
) => {
  const graphDataPoints: GraphDataPoint[] = [];

  for (let i = 0; i < days.length; i++) {
    const timestamp = new Date(days[i].date).getTime() / 1000;
    graphDataPoints.push({ timestamp, visits: days[i].successCount });
  }

  graphDataPoints.sort(comparator);

  const existingIndex = getExistingIndex(graphData, id);

  if (existingIndex !== -1) {
    graphData[existingIndex].data = graphDataPoints;
  } else {
    graphData.push({ id, data: graphDataPoints, color });
  }
};

export const createGraphData = (
  dataForGraph: GraphData[],
  selectedSubjects: string[],
  selectedKeychainFactories: string[],
  selectedAdmissioncards: string[],
  newStats: {
    newSubjectStats: SubjectVisitations[];
    newKeychainFactoryStats: KeychainFactoryVisitations[];
    newAdmissionCardStats: AdmissionCardUsages[];
  }
) => {
  const { newSubjectStats, newKeychainFactoryStats, newAdmissionCardStats } =
    newStats;
  const graphData: GraphData[] = dataForGraph.filter(
    data =>
      selectedSubjects.some(subject => subject === data.id) ||
      selectedKeychainFactories.some(
        keychainFactory => keychainFactory === data.id
      ) ||
      selectedAdmissioncards.some(admissionCard => admissionCard === data.id)
  );

  for (let i = 0; i < newSubjectStats.length; i++) {
    const { subjectId, operations } = newSubjectStats[i];
    const days: Visitation[] = [];
    const color = getRandomGraphColor(graphData);

    for (let k = 0; k < operations.length; k++) {
      addDaysFromVisitation(days, newSubjectStats[i].operations[k].day);
    }

    addGraphDataPointsFromDays(days, graphData, color, subjectId);
  }

  for (let i = 0; i < newKeychainFactoryStats.length; i++) {
    const { keychainFactoryUri } = newKeychainFactoryStats[i];
    const days: Visitation[] = [];
    const color = getRandomGraphColor(graphData);

    addDaysFromVisitation(days, newKeychainFactoryStats[i].visits);

    addGraphDataPointsFromDays(days, graphData, color, keychainFactoryUri);
  }

  for (let i = 0; i < newAdmissionCardStats.length; i++) {
    const { admissionCardId } = newAdmissionCardStats[i];
    const days: Visitation[] = [];
    const color = getRandomGraphColor(graphData);

    addDaysFromVisitation(days, newAdmissionCardStats[i].visits);

    addGraphDataPointsFromDays(days, graphData, color, admissionCardId);
  }

  return graphData;
};
