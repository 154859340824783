import axios from 'axios';
import packageJson from '../../../package.json';
import { refreshCacheAndReload } from './refreshCache';
import { semverGreaterThan } from './semver';

export const cacheCheck = () => {
  axios.get('/meta.json').then(response => {
    const currentVersion = response.data.version;
    const latestVersion = packageJson.version;
    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

    if (shouldForceRefresh) {
      console.log(
        `We have a new version - ${latestVersion}. Should force refresh`
      );
      refreshCacheAndReload();
    }
  });
};
