import React, { Fragment } from 'react';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { useAppSelector } from '../../../redux/hooks';
import { bucketSelectors } from '../../../redux/slice/buckets';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const OperationBuckets: React.FC = () => {
  const buckets = useAppSelector(bucketSelectors.selectAll);
  const tableHeadings = ['Navn', 'ID', 'Antall operasjoner'];
  const data = buckets.map(bucket => {
    return {
      cells: [
        bucket.name,
        <CopyWrapper copyValue={bucket.id} key={bucket.id} />,
        bucket.operationIdsList.length
      ],
      link: bucket.id
    };
  });
  return (
    <Fragment>
      <ControlpanelBreadcrumbs />
      <TableWithButtons
        ting='spann'
        buttons={[
          {
            label: 'Lag nytt spann',
            type: 'primary',
            accessKey: 'n',
            linkTo: 'lag-ny'
          }
        ]}
        table={{ headings: tableHeadings, rows: data }}
      />
    </Fragment>
  );
};
