import React, { useState } from 'react';
import { RenderIfSuperAdmin } from '../../../../components/util/RenderIfSuperAdmin';
import { Term } from '../../../../redux/slice/terms';
import { EditOwnerAdmin } from './EditOwnerAdmin';
import { OwnerAdminCard } from './OwnerAdminCard';

type Props = {
  term: Term;
};
export const OwnerAdminContainter: React.FC<Props> = ({ term }) => {
  const [edit, setEdit] = useState(false);
  return (
    <RenderIfSuperAdmin>
      <OwnerAdminCard term={term} setEdit={setEdit} />
      <EditOwnerAdmin term={term} edit={edit} setEdit={setEdit} />
    </RenderIfSuperAdmin>
  );
};
