import React from 'react';
import { Button } from '../../components/Button/Button';
import { Breadcrumbs } from '../../components/Navigation/breadcrumbs';
import { showcaseBreadcrumb } from './Showcase';

export const Buttons: React.FC = () => {
  return (
    <div>
      <Breadcrumbs
        breadcrumbs={showcaseBreadcrumb.concat({ name: 'Buttons' })}
      />
      <Button type='primary' label='Primary' />
      <Button type='secondary' label='Secondary' />
      <Button type='destructive' label='Destructive' />
      <Button type='edit' label='Edit' />
      <Button type='selected' label='Selected' />
      <Button type='unselected' label='Unselected' />
      <Button type='disabled' label='Disabled' />
      <Button type='add' label='Add' />
      <Button type='remove' label='Remove' />
    </div>
  );
};
