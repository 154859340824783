import React, { HTMLInputTypeAttribute } from 'react';
import { useFormContext } from 'react-hook-form';
import { generateClasses, KeyComponentProps, KeyWrapper } from './KeyWrapper';

export interface KeyInputType extends KeyComponentProps {
  type: HTMLInputTypeAttribute;
  elementProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}

export const KeyInput: React.FC<KeyInputType> = ({ name, ...props }) => {
  const { inputClassNames } = generateClasses(props);
  const { register } = useFormContext();
  const { elementProps, ...wrapperProps } = props;
  return (
    <KeyWrapper name={name} {...wrapperProps}>
      <input
        {...register(name)}
        id={name}
        type={props.type}
        {...elementProps}
        className={inputClassNames}
        data-testid={props['data-testid']}
      />
    </KeyWrapper>
  );
};
