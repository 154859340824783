import React from 'react';
import { LinkCard } from '../../../../../components/cards/LinkCard/LinkCard';
import { KeychainFactorySummary } from '../../../../../redux/slice/keychainFactorySummaries';
import {
  getKeychainFactoryIcon,
  getKeychainFactoryLink
} from '../../../../../utils/keychainFactory/getKeychainFactoryAssets';
import './CardGroup.scss';

interface CardGroupProps {
  subjectName: string;
  keychainFactories: KeychainFactorySummary[];
}

export const CardGroup: React.FC<CardGroupProps> = ({
  subjectName,
  keychainFactories
}) => {
  return (
    <li className='card-group'>
      <h4 className='subject-name'>{subjectName}</h4>

      <ul className='card-list'>
        {keychainFactories.map(factory => {
          let image = factory.image;
          if (factory.type === 'oidc' && !image) {
            image = '/opengraph.png';
          }
          return (
            <LinkCard
              key={factory.keychainFactoryUri}
              title={factory.name}
              img={image}
              content={<p></p>}
              linkUri={getKeychainFactoryLink(factory)}
              icon={getKeychainFactoryIcon(factory)}
              size='small-wide'
            />
          );
        })}
      </ul>
    </li>
  );
};
