import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  UpdateOperationExecuteMeters,
  updateOperationExecuteMeters
} from '../../../redux/actions/operations';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { Operation } from '../../../redux/slice/operations';
import { historyGoBack } from '../../../utils/history';
import { Button } from '../../Button/Button';
import { KeyText } from '../../Forms/KeyText';
import { PageInfoText } from '../../PageInfoText/PageInfoText';

interface Props {
  subjectId: string;
  operation: Operation;
  setShowNavigateConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditMetersToExecute: React.FC<Props> = ({
  subjectId,
  operation,
  setShowNavigateConfirm
}) => {
  const dispatch = useAppDispatch();

  const methods = useForm<UpdateOperationExecuteMeters>({
    resolver: zodResolver(UpdateOperationExecuteMeters),
    defaultValues: {
      subjectId,
      operationId: operation.id,
      mustBeWithinMetersToExecute: operation.mustBeWithinMetersToExecute
    }
  });
  const navigate = useNavigate();

  const handleSaveClick = (data: UpdateOperationExecuteMeters) =>
    dispatchAndHandleResult(
      dispatch,
      updateOperationExecuteMeters,
      data,
      () => {
        historyGoBack(navigate);
      }
    );

  const handleCancelClick = () => {
    if (
      Number(methods.getValues('mustBeWithinMetersToExecute')) !==
      Number(operation.mustBeWithinMetersToExecute)
    ) {
      setShowNavigateConfirm(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <React.Fragment>
      <h3>GPS-Radius</h3>
      <PageInfoText type='medium'>
        <p>
          Sporingsradius for GPS fra telefonen målt i Meter. Lokasjon fra
          mobiltelefon er ofte unøyaktig og derfor er anbefalt verdi på anbefalt
          verdi 150 meter. Verdien kan settes lavere om det trengs høyere
          sikkerhet men vær da oppmerksom på at brukere kan stå i fare for å
          måtte vente lenge på nøyaktig posisjon fra telefonen.
        </p>
        <p>
          Om det er behov for åpning av dører fra innsiden av store bygg bør
          verdien settes høyere. Dette er fordi posisjonering fra mobiltelefon
          ofte er unøyaktig innendørs og kan ha stor feilmargin.
        </p>
        <p>
          Ønsker du å forandre på denne verdien bør det testes grundig slik at
          vi unngår misfornøyde brukere.{' '}
        </p>
      </PageInfoText>
      <div className='meters-to-execute'>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSaveClick)}>
            <KeyText
              name='mustBeWithinMetersToExecute'
              label='GPS-radius i meter'
              elementProps={{
                type: 'number'
              }}
            />
            <div className='button-row'>
              <Button
                onClick={handleCancelClick}
                label='Avbryt'
                type='secondary'
              />
              <Button label='Lagre' type='submit' />
            </div>
          </form>
        </FormProvider>
      </div>
    </React.Fragment>
  );
};
