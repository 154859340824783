import React from 'react';

export const DotsIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='3'
    height='17'
    viewBox='0 0 3 17'
  >
    <g
      id='Group_12256'
      data-name='Group 12256'
      transform='translate(-102.554 -4)'
    >
      <g
        id='Group_12237'
        data-name='Group 12237'
        transform='translate(103.001 4)'
      >
        <circle
          id='Ellipse_748'
          data-name='Ellipse 748'
          cx='1.5'
          cy='1.5'
          r='1.5'
          transform='translate(-0.447)'
          fill='#292858'
        />
        <circle
          id='Ellipse_749'
          data-name='Ellipse 749'
          cx='1.5'
          cy='1.5'
          r='1.5'
          transform='translate(-0.447 7)'
          fill='#292858'
        />
        <circle
          id='Ellipse_750'
          data-name='Ellipse 750'
          cx='1.5'
          cy='1.5'
          r='1.5'
          transform='translate(-0.447 14)'
          fill='#292858'
        />
      </g>
    </g>
  </svg>
);
