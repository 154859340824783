// source: keychain-oidc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
var keychain$shared_pb = require('./keychain-shared_pb.js');
goog.object.extend(proto, keychain$shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.prototype.getKeychainUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.prototype.setKeychainUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string role_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.prototype.getRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcAddRoleToUserRequest.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoleIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.prototype.getKeychainUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.prototype.setKeychainUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string role_ids = 2;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.prototype.getRoleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.prototype.setRoleIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.prototype.addRoleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcReplaceRolesForUserRequest.prototype.clearRoleIdsList = function() {
  return this.setRoleIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string role_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.prototype.getRoleUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleRequest.prototype.setRoleUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    factoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rootSessionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRootSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRootSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.prototype.getFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.prototype.setFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string root_session_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.prototype.getRootSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcTerminateRootSessionRequest.prototype.setRootSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    factoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionLifespanMinutes: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryUri(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSessionLifespanMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionLifespanMinutes();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.prototype.getFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.prototype.setFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 session_lifespan_minutes = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.prototype.getSessionLifespanMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetSessionLifespanRequest.prototype.setSessionLifespanMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string role_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.prototype.getRoleUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.prototype.setRoleUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateRoleDescriptionRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    factoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    biometricsRequired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFactoryUri(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBiometricsRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBiometricsRequired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.prototype.getFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.prototype.setFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool biometrics_required = 2;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.prototype.getBiometricsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcUpdateBiometricsRequiredRequest.prototype.setBiometricsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fullName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFullName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFullName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.prototype.getKeychainUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.prototype.setKeychainUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string full_name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.prototype.getFullName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry.prototype.setFullName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Entry entries = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.Entry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcListUsersWithRoleResponse.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.prototype.getKeychainUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.prototype.setKeychainUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string role_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.prototype.getRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcDeleteRoleFromUserRequest.prototype.setRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.prototype.getKeychainUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.prototype.setKeychainUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email_address = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcSetUserEmailRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    redirectUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRedirectUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string redirect_uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.prototype.getRedirectUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.AddAllowedOidcRedirectUriRequest.prototype.setRedirectUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    redirectUriList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRedirectUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRedirectUriList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string redirect_uri = 2;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.prototype.getRedirectUriList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.prototype.setRedirectUriList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.prototype.addRedirectUri = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOidcRedirectUrisRequest.prototype.clearRedirectUriList = function() {
  return this.setRedirectUriList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    allowedOriginsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedOrigins(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAllowedOriginsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string allowed_origins = 2;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.prototype.getAllowedOriginsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.prototype.setAllowedOriginsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.prototype.addAllowedOrigins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.ReplaceAllowedOriginsRequest.prototype.clearAllowedOriginsList = function() {
  return this.setAllowedOriginsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    redirectUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRedirectUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string redirect_uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.prototype.getRedirectUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteAllowedOidcRedirectUriRequest.prototype.setRedirectUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    definition: (f = msg.getDefinition()) && keychain$shared_pb.OidcRoleDefinition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = new keychain$shared_pb.OidcRoleDefinition;
      reader.readMessage(value,keychain$shared_pb.OidcRoleDefinition.deserializeBinaryFromReader);
      msg.setDefinition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDefinition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      keychain$shared_pb.OidcRoleDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.keychain.OidcRoleDefinition definition = 2;
 * @return {?proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.prototype.getDefinition = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition} */ (
    jspb.Message.getWrapperField(this, keychain$shared_pb.OidcRoleDefinition, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.prototype.setDefinition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.prototype.clearDefinition = function() {
  return this.setDefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcRoleRequest.prototype.hasDefinition = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.DeleteOidcRoleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.repeatedFields_ = [3,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    redirectUrisList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    adminRole: jspb.Message.getFieldWithDefault(msg, 4, ""),
    roleDefinitionsList: jspb.Message.toObjectList(msg.getRoleDefinitionsList(),
    keychain$shared_pb.OidcRoleDefinition.toObject, includeInstance),
    allowedOriginsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    sessionLifespanMinutes: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRedirectUris(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminRole(value);
      break;
    case 5:
      var value = new keychain$shared_pb.OidcRoleDefinition;
      reader.readMessage(value,keychain$shared_pb.OidcRoleDefinition.deserializeBinaryFromReader);
      msg.addRoleDefinitions(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedOrigins(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSessionLifespanMinutes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRedirectUrisList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getAdminRole();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRoleDefinitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      keychain$shared_pb.OidcRoleDefinition.serializeBinaryToWriter
    );
  }
  f = message.getAllowedOriginsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getSessionLifespanMinutes();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string redirect_uris = 3;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.getRedirectUrisList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.setRedirectUrisList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.addRedirectUris = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.clearRedirectUrisList = function() {
  return this.setRedirectUrisList([]);
};


/**
 * optional string admin_role = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.getAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.setAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated oslonokkelen.proto.shared.keychain.OidcRoleDefinition role_definitions = 5;
 * @return {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.getRoleDefinitionsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, keychain$shared_pb.OidcRoleDefinition, 5));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.setRoleDefinitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.addRoleDefinitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.clearRoleDefinitionsList = function() {
  return this.setRoleDefinitionsList([]);
};


/**
 * repeated string allowed_origins = 6;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.getAllowedOriginsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.setAllowedOriginsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.addAllowedOrigins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.clearAllowedOriginsList = function() {
  return this.setAllowedOriginsList([]);
};


/**
 * optional int32 session_lifespan_minutes = 7;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.getSessionLifespanMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.CreateOidcKeychainFactoryRequest.prototype.setSessionLifespanMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: (f = msg.getPhoneNumber()) && shared_pb.PhoneNumber.toObject(includeInstance, f),
    roleIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    grantedUntil: (f = msg.getGrantedUntil()) && shared_pb.LocalDateTime.toObject(includeInstance, f),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = new shared_pb.PhoneNumber;
      reader.readMessage(value,shared_pb.PhoneNumber.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoleIds(value);
      break;
    case 4:
      var value = new shared_pb.LocalDateTime;
      reader.readMessage(value,shared_pb.LocalDateTime.deserializeBinaryFromReader);
      msg.setGrantedUntil(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.PhoneNumber.serializeBinaryToWriter
    );
  }
  f = message.getRoleIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getGrantedUntil();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      shared_pb.LocalDateTime.serializeBinaryToWriter
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.PhoneNumber phone_number = 2;
 * @return {?proto.oslonokkelen.proto.shared.PhoneNumber}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.getPhoneNumber = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.PhoneNumber} */ (
    jspb.Message.getWrapperField(this, shared_pb.PhoneNumber, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.PhoneNumber|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string role_ids = 3;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.getRoleIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.setRoleIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.addRoleIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.clearRoleIdsList = function() {
  return this.setRoleIdsList([]);
};


/**
 * optional oslonokkelen.proto.shared.LocalDateTime granted_until = 4;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTime}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.getGrantedUntil = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTime} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateTime, 4));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTime|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.setGrantedUntil = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.clearGrantedUntil = function() {
  return this.setGrantedUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.hasGrantedUntil = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string email_address = 5;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc.PushOidcKeychainRequest.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.keychain.oidc);
