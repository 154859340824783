import React from 'react';

export const Keyless: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='40'
    viewBox='0 0 37 40'
  >
    <g
      id='Group_12333'
      data-name='Group 12333'
      transform='translate(-64 -1360)'
    >
      <g
        id='Ellipse_775'
        data-name='Ellipse 775'
        transform='translate(64 1360)'
        fill='#fff'
        stroke='#292858'
        strokeWidth='1'
      ></g>
      <path
        id='Subtraction_4'
        data-name='Subtraction 4'
        d='M2864.351,13248h0c-.334,0-.625-.576-1.028-1.376-.2-.391-.421-.835-.689-1.282a.943.943,0,0,0-.786-.554c-1.135,0-2.483,2.679-2.5,2.706l0-.026c-.077-1.058-.734-10.464,0-15a8.723,8.723,0,0,1,2.19-4.421,5.9,5.9,0,0,1,4.256-2.052,5.393,5.393,0,0,1,2.7.765,9.83,9.83,0,0,1,2.784,2.552,22.677,22.677,0,0,1,3.385,6.53,39.528,39.528,0,0,1,1.79,7.982c.265,2.165.285,3.65.285,3.665h0a8.577,8.577,0,0,1-3.253-2.543c-.265-.371-.509-.552-.745-.552-.48,0-.835.741-1.211,1.525l0,0a8.015,8.015,0,0,1-.6,1.108.906.906,0,0,1-.761.415c-.812,0-1.933-1.006-2.791-2.5-.4-.691-.624-.961-.815-.961-.258,0-.442.5-.777,1.4v.006c-.144.39-.308.831-.518,1.343C2864.91,13247.6,2864.62,13248,2864.351,13248Zm-1.035-17.08a1.223,1.223,0,1,0,1.405,1.21A1.32,1.32,0,0,0,2863.315,13230.919Zm5.06-.483a1.223,1.223,0,1,0,1.407,1.208A1.319,1.319,0,0,0,2868.375,13230.436Z'
        transform='translate(-2783.006 -11857.998)'
        fill='#fad0cb'
        stroke='#292858'
        strokeWidth='1'
      />
    </g>
  </svg>
);
