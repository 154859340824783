import React from 'react';
import { Button } from '../../../../../../components/Button/Button';
import { removeOperationLock } from '../../../../../../redux/actions/keychainFactoryDetails';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { OperationInfo } from '../../../../../../redux/slice/keychainFactoryDetails';

interface Props {
  keychainFactoryUri: string;
  operation: OperationInfo;
}

export const LockListItem: React.FC<Props> = ({
  operation,
  keychainFactoryUri
}) => {
  const dispatch = useAppDispatch();

  const handleButtonClick = () => {
    dispatch(
      removeOperationLock({
        keychainFactoryUri,
        subjectId: operation.subjectId,
        operationId: operation.operationId
      })
    );
  };

  return (
    <li className='locks-in-keychainFactory'>
      <h3>{operation.name}</h3>
      {operation.subjectId ? (
        <p>{`${operation.subjectId} / ${operation.operationId}`}</p>
      ) : (
        <p>Denne låsen har ingen operationBridge</p>
      )}
      <Button label='Fjern' type='destructive' onClick={handleButtonClick} />
    </li>
  );
};
