import React from 'react';
import { AccessControllIcon2 } from '../../../assets/AccessControlIcon2';
import { LifespanSvg } from '../../../assets/LifespanSvg';
import { RedirectIcon } from '../../../assets/RedirectIcon';
import { RoleIcon } from '../../../assets/RoleIcon';
import { AuditCard } from '../../../components/cards/AuditCard/AuditCard';
import { LinkToControlpanelCard } from '../../../components/cards/ControlpanelCards/LinkToControlpanelCard';
import { NarrowCard } from '../../../components/cards/NarrowCard/NarrowCard';
import { StatsCard } from '../../../components/cards/StatsCard/StatsCard';
import { ImageCard } from '../../../components/image/ImageCard';
import { OidcKeychainFactoryDetails } from '../../../redux/slice/keychainFactoryDetails';
import { OidcKeychainFactorySummary } from '../../../redux/slice/keychainFactorySummaries';
import { AdminRoleCard } from '../AdminRoleCard/AdminRoleCard';
import { KeychainFactoryBreadcrumbs } from '../KeychainFactoryBreadcrumbs';
import { KeychainFactoryContainer } from '../KeychainFactoryContainer/KeychainFactoryContainer';

interface Props {
  keychainFactory: OidcKeychainFactoryDetails | OidcKeychainFactorySummary;
}

export const OidcKeychainFactoryPage: React.FC<Props> = ({
  keychainFactory
}) => {
  return (
    <KeychainFactoryContainer>
      <KeychainFactoryBreadcrumbs keychainFactory={keychainFactory} />
      <div className='cards'>
        <ImageCard
          images={[{ uri: keychainFactory.image }]}
          keychainFactoryUri={keychainFactory.keychainFactoryUri}
        />
      </div>
      <div className='cards'>
        <NarrowCard
          link='tilgangskontroll'
          header='Tilgangsoversikt'
          icon={<AccessControllIcon2 />}
        >
          {keychainFactory.isDetails && (
            <h4 className='number-of-requests-text'>
              Antall brukere: {keychainFactory.approvedKeychains}
            </h4>
          )}
        </NarrowCard>
        <NarrowCard link='roller' header='Roller' icon={<RoleIcon />}>
          {keychainFactory.isDetails && (
            <h4 className='number-of-requests-text'>
              Antall roller: {keychainFactory.roleDefinitionsList.length}
            </h4>
          )}
        </NarrowCard>
        <NarrowCard link='redirects' header='Redirects' icon={<RedirectIcon />}>
          {keychainFactory.isDetails && (
            <h4 className='number-of-requests-text'>
              Antall redirects: {keychainFactory.redirectUrisList.length}
            </h4>
          )}
        </NarrowCard>
        <NarrowCard
          link='origins'
          header='Allowed origins'
          icon={<RedirectIcon />}
        >
          {keychainFactory.isDetails && (
            <h4 className='number-of-requests-text'>
              Allowed origins: {keychainFactory.allowedOriginsList.length}
            </h4>
          )}
        </NarrowCard>
        <NarrowCard link='lifespan' header='Levetid' icon={<LifespanSvg />}>
          {keychainFactory.isDetails && (
            <h4 className='number-of-requests-text'>
              Levetid: {keychainFactory.sessionLifespanMinutes} minutter
            </h4>
          )}
        </NarrowCard>
        <StatsCard type='oidc' id={keychainFactory.keychainFactoryUri} />
        <AuditCard
          type={keychainFactory.type}
          citykeyId={keychainFactory.keychainFactoryUri}
        />
        <LinkToControlpanelCard type='oidc' />
        <AdminRoleCard adminRole={keychainFactory.ownerRole} />
      </div>
    </KeychainFactoryContainer>
  );
};
