import React from 'react';
import { Outlet, useOutletContext, useParams } from 'react-router-dom';
import { useSubject } from '../../pages/places/SubjectWrapper';
import { Operation } from '../../redux/slice/operations';
import { Subject } from '../../redux/slice/subjects';

interface MatchParams {
  operationId: string;
}
type ContextType = {
  subject: Subject;
  operation: Operation;
};

export const OperationWrapper: React.FC = () => {
  const { operationId } = useParams<keyof MatchParams>();
  const { subject } = useSubject();
  const operation = subject.operationsList.find(
    operation => operation.id === operationId
  );

  if (operation) {
    return <Outlet context={{ operation, subject } satisfies ContextType} />;
  } else {
    return <p>Finner ikke låsen {operationId}</p>;
  }
};

export const useOperation = () => {
  return useOutletContext<ContextType>();
};
