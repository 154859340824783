import React from 'react';

export const PushKeychain = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='71'
    height='46'
    viewBox='0 0 71 46'
  >
    <g id='Group_1984' data-name='Group 1984' transform='translate(-369 -562)'>
      <g
        id='Group_1965'
        data-name='Group 1965'
        transform='translate(-3429 3164)'
      >
        <g
          id='Group_1922'
          data-name='Group 1922'
          transform='translate(3520 -2816)'
        >
          <g
            id='Group_1845'
            data-name='Group 1845'
            transform='translate(277 36)'
          >
            <g
              id='Group_1843'
              data-name='Group 1843'
              transform='translate(26 178)'
            >
              <g
                id='Ellipse_535'
                data-name='Ellipse 535'
                fill='#f8f0dd'
                stroke='#2c2c2c'
                strokeWidth='2'
              >
                <circle cx='23' cy='23' r='23' stroke='none' />
                <circle cx='23' cy='23' r='22' fill='none' />
              </g>
            </g>
          </g>
        </g>
        <g
          id='Group_1923'
          data-name='Group 1923'
          transform='translate(3798 -2587.13)'
        >
          <path
            id='Path_4368'
            data-name='Path 4368'
            d='M122.424,6.093a3.633,3.633,0,1,0-3.653,3.675,3.667,3.667,0,0,0,3.653-3.675m2.208,1.227a5.976,5.976,0,0,1-6.768,4.751A6.059,6.059,0,0,1,112.9,5.129a5.951,5.951,0,0,1,11.749-.237l16.83.095-.013,2.429L139.074,7.4l-.027,4.858-2.387-.013.013-2.43h-.009s0-.006,0-.008a1.186,1.186,0,1,0-2.371-.013s0,.006,0,.008h-.007l-.013,2.43-2.387-.013.026-4.858Z'
            transform='translate(-102.891 0)'
          />
          <line
            id='Line_1'
            data-name='Line 1'
            x1='5.172'
            transform='translate(3.169 1.348)'
          />
          <path
            id='Line_2'
            data-name='Line 2'
            d='M-4.86-9.063h-5.172A.968.968,0,0,1-11-10.032.968.968,0,0,1-10.032-11H-4.86a.968.968,0,0,1,.968.968A.968.968,0,0,1-4.86-9.063Z'
            transform='translate(13.201 11.38)'
          />
          <line
            id='Line_3'
            data-name='Line 3'
            x1='5.172'
            transform='translate(0.968 6.13)'
          />
          <path
            id='Line_4'
            data-name='Line 4'
            d='M-4.86-9.063h-5.172A.968.968,0,0,1-11-10.032.968.968,0,0,1-10.032-11H-4.86a.968.968,0,0,1,.968.968A.968.968,0,0,1-4.86-9.063Z'
            transform='translate(11 16.161)'
          />
          <line
            id='Line_5'
            data-name='Line 5'
            x1='5.172'
            transform='translate(3.169 10.911)'
          />
          <path
            id='Line_6'
            data-name='Line 6'
            d='M-4.86-9.063h-5.172A.968.968,0,0,1-11-10.032.968.968,0,0,1-10.032-11H-4.86a.968.968,0,0,1,.968.968A.968.968,0,0,1-4.86-9.063Z'
            transform='translate(13.201 20.943)'
          />
        </g>
      </g>
      <g
        id='Union_31'
        data-name='Union 31'
        transform='translate(410 573)'
        fill='#201e1e'
      >
        <path
          d='M 9.792545318603516 21.00048446655273 L 4.253216743469238 21.00048446655273 L 4.788219928741455 13.13820457458496 L 4.828619956970215 12.54455375671387 L 4.3261399269104 12.22585391998291 C 2.869440078735352 11.30191421508789 1.999760031700134 9.722164154052734 1.999760031700134 8.000003814697266 C 1.999760031700134 5.24299430847168 4.243080139160156 3.000004053115845 7.000490188598633 3.000004053115845 C 9.757360458374023 3.000004053115845 12.00024032592773 5.24299430847168 12.00024032592773 8.000003814697266 C 12.00024032592773 9.716994285583496 11.1342601776123 11.29432392120361 9.68373966217041 12.21936416625977 L 9.181059837341309 12.53994369506836 L 9.224040031433105 13.13459396362305 L 9.792545318603516 21.00048446655273 Z'
          stroke='none'
        />
        <path
          d='M 8.717660903930664 20.00048446655273 L 8.140689849853516 12.01738452911377 L 9.146039962768555 11.37622451782227 C 10.30708026885986 10.63579368591309 11.00024032592773 9.373654365539551 11.00024032592773 8.000003814697266 C 11.00024032592773 5.794394016265869 9.205960273742676 4.000004291534424 7.000490188598633 4.000004291534424 C 4.794479846954346 4.000004291534424 2.999759912490845 5.794394016265869 2.999759912490845 8.000003814697266 C 2.999759912490845 9.377814292907715 3.695830106735229 10.64188385009766 4.86175012588501 11.38138389587402 L 5.866710186004639 12.01879405975342 L 5.323583126068115 20.00048446655273 L 8.717660903930664 20.00048446655273 M 10.86742973327637 22.00048446655273 L 3.182859897613525 22.00048446655273 L 3.79052996635437 13.07031440734863 C 2.113039970397949 12.00634384155273 0.9997599720954895 10.13330459594727 0.9997599720954895 8.000003814697266 C 0.9997599720954895 4.686034202575684 3.686280012130737 2.000004053115845 7.000490188598633 2.000004053115845 C 10.31371974945068 2.000004053115845 13.00024032592773 4.686034202575684 13.00024032592773 8.000003814697266 C 13.00024032592773 10.12793445587158 11.89208984375 11.99707412719727 10.22144031524658 13.06250381469727 L 10.86742973327637 22.00048446655273 Z'
          stroke='none'
          fill='#201e1e'
        />
      </g>
    </g>
  </svg>
);
