import React from 'react';
import { daysNor } from '../../../../../utils/operation/openingHours/openingHoursFunctions';

export const AlwaysOpenDaysList = () => (
  <ul className='opening-hours'>
    {daysNor.map(day => (
      <li className='day-line' key={day}>
        <div className='day'>{day.toLowerCase()}</div>
        <div className='switch-container'></div>
        <span className='status'>Åpent</span>
      </li>
    ))}
  </ul>
);
