import React from 'react';
import './TopInfoStat.scss';

interface TopInfoStatProps {
  label: string;
  number: number;
  total?: number;
}

export const TopInfoStat: React.FC<TopInfoStatProps> = ({
  label,
  number,
  total = 0
}) => (
  <div className='top-info-stat' data-testid='top-info-stat'>
    <p className='label'>{label}</p>
    <p className='number'>{number}</p>
    {total > number && <p className='total'> (av totalt {total})</p>}
  </div>
);
