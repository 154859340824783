import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdmissionCardsTable } from '../pages/controlpanel/admissionCard/AdmissionCardsTable';
import { CreateAdmissionCard } from '../pages/controlpanel/admissionCard/CreateAdmissionCard';
import { listActionChains } from '../redux/actions/adapters';
import { listAdmissionCardTypes } from '../redux/actions/admissionCards';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const AdminAdmissionCardRouter = () => {
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedAdminTool !== 'admissioncard-admin') {
      dispatch(setSelectedAdminTool('admissioncard-admin'));
      dispatch(listActionChains());
      dispatch(listAdmissionCardTypes());
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <section>
      <Routes>
        <Route path='lag-ny' element={<CreateAdmissionCard />} />
        <Route path='/' element={<AdmissionCardsTable />} />
      </Routes>
    </section>
  );
};
