import React from 'react';

export const UsedToday: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='40'
    viewBox='0 0 37 40'
  >
    <g
      id='Group_12328'
      data-name='Group 12328'
      transform='translate(-64 -1072)'
    >
      <g
        id='Ellipse_774'
        data-name='Ellipse 774'
        transform='translate(64 1072)'
        fill='#fff'
        stroke='#292858'
        strokeWidth='1'
      ></g>
      <g id='noun-star-6080785' transform='translate(63.934 1082.134)'>
        <g
          id='Group_12327'
          data-name='Group 12327'
          transform='translate(8.066 -2.566)'
        >
          <path
            id='Path_11410'
            data-name='Path 11410'
            d='M23.42,18.672l2.518,6.164a2.449,2.449,0,0,0,2.131,1.547l6.639.49a.385.385,0,0,1,.209.688l-5.071,4.29a2.443,2.443,0,0,0-.814,2.506l1.585,6.466a.384.384,0,0,1-.584.413l-5.652-3.5a2.451,2.451,0,0,0-2.634,0l-5.66,3.506a.384.384,0,0,1-.528-.125.379.379,0,0,1-.046-.292L17.1,34.358a2.443,2.443,0,0,0-.814-2.506l-5.084-4.3a.386.386,0,0,1-.045-.542.381.381,0,0,1,.267-.135l6.639-.491a2.449,2.449,0,0,0,2.131-1.547l2.518-6.164a.383.383,0,0,1,.71,0Z'
            transform='translate(-11.066 -18.434)'
            fill='#f9c66b'
            stroke='#292858'
            strokeWidth='1'
          />
        </g>
      </g>
    </g>
  </svg>
);
