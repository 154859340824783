import React from 'react';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { KeychainCommon } from '../../redux/slice/operations';
import { FactoryType } from '../../redux/types/common';
import { KeychainFactoryBreadcrumbs } from './KeychainFactoryBreadcrumbs';

type Props = {
  type: FactoryType;
  isLoading: boolean;
  id: string;
};
export const KeychainFactoryNotFound: React.FC<Props> = ({
  type,
  isLoading,
  id
}) => {
  const keychainFactory: KeychainCommon = {
    type: type,
    keychainFactoryUri: id,
    image: '',
    name: id
  };
  return (
    <section>
      <KeychainFactoryBreadcrumbs keychainFactory={keychainFactory} />
      {isLoading ? <LoadingSpinner /> : <h1>Finner ikke tilgangslisten</h1>}
    </section>
  );
};
