import { FC } from 'react';
import {
  AuditSvg,
  BrickSvg,
  FactoriesSvg,
  GodModeSvg,
  HelpSvg,
  StatsSvg,
  SubjectsSvg,
  TermsSvg
} from '../../assets/navbar';

export interface NavPath {
  name: string;
  path: string;
  accessKey?: string;
  Image?: FC;
}

export const keychainHome: NavPath = {
  name: 'Tilgangslister',
  path: '/tilgangslister',
  accessKey: 'T',
  Image: FactoriesSvg
};
export const pushKeychainHome: NavPath = {
  name: 'Booking',
  path: `${keychainHome.path}/booking`
};
export const manualGrantKeychainHome: NavPath = {
  name: 'Tilgang for noen',
  path: `${keychainHome.path}/keystudio`
};
export const oidcKeychainHome: NavPath = {
  name: 'Web login',
  path: `${keychainHome.path}/web-login`
};
export const publicKeychainHome: NavPath = {
  name: 'Tilgang for alle',
  path: `${keychainHome.path}/public`
};
export const subjectHome: NavPath = {
  name: 'Steder',
  path: '/steder',
  accessKey: 'S',
  Image: SubjectsSvg
};
export const admissionCardsHome: NavPath = {
  name: 'Adgangskort',
  path: '/adgangskort',
  accessKey: 'A',
  Image: BrickSvg
};
export const termsHome: NavPath = {
  name: 'Vilkår',
  path: '/vilkår',
  accessKey: 'V',
  Image: TermsSvg
};
export const statsHome: NavPath = {
  name: 'Statistikk',
  path: '/stats',
  Image: StatsSvg
};
export const auditHome: NavPath = {
  name: 'Besøkslogger',
  path: '/audit',
  accessKey: 'B',
  Image: AuditSvg
};
export const helpHome: NavPath = {
  name: 'Hjelp',
  path: '/hjelp',
  accessKey: 'H',
  Image: HelpSvg
};

/** Superadmin */
export const controlPanelHome: NavPath = {
  name: 'Kontroll­panel',
  path: '/kontrollpanel',
  accessKey: 'K',
  Image: GodModeSvg
};
