import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import { SelectTermList } from '../../../../components/operation/terms/edit/SelectTermList';
import { updateRequiredTerms } from '../../../../redux/actions/admissionCards';
import { getTerms } from '../../../../redux/actions/terms';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { AdmissionCard } from '../../../../redux/slice/admissionCards';
import { termsSelectors } from '../../../../redux/slice/terms';
import { historyGoBack } from '../../../../utils/history';

interface Props {
  admissionCard: AdmissionCard;
}

export const EditAdmissionCardTerms: React.FC<Props> = ({ admissionCard }) => {
  const terms = useAppSelector(termsSelectors.selectAll);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedTermIds, setSelectedTermIds] = useState<string[]>(
    admissionCard.requiredTermIdsList
  );

  useEffect(() => {
    dispatch(getTerms());
  }, [terms.length]);

  const handleSaveClick = () =>
    dispatchAndHandleResult(
      dispatch,
      updateRequiredTerms,
      {
        id: admissionCard.id,
        version: admissionCard.version,
        requiredTerms: selectedTermIds
      },
      () => {
        historyGoBack(navigate);
      }
    );

  return (
    <React.Fragment>
      <h3>Vilkår</h3>
      <p>Vilkår brukere må ha godkjent før de bruker adgangskortet.</p>
      <br />
      <ul className='terms'>
        {terms &&
          terms.map(term => (
            <SelectTermList
              key={term.id}
              term={term}
              selectedTermIds={selectedTermIds}
              setSelectedTermIds={setSelectedTermIds}
            />
          ))}
      </ul>
      <Button label='Lagre' type='primary' onClick={handleSaveClick} />
    </React.Fragment>
  );
};
