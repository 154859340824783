import React, { useId } from 'react';
import './Slider.scss';

interface Props {
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

export const Slider: React.FC<Props> = ({ checked, setChecked }) => {
  const id = useId();
  return (
    <label className='switch' htmlFor={id}>
      <input
        type='checkbox'
        id={id}
        checked={checked}
        onChange={() => {
          setChecked(!checked);
        }}
      />
      <div className='slider round'></div>
    </label>
  );
};
