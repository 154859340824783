import React from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  Breadcrumbs
} from '../../components/Navigation/breadcrumbs';

export const showcaseBreadcrumb: Breadcrumb[] = [
  { name: 'Showcase', path: '/showcase' }
];

export const Showcase: React.FC = () => {
  return (
    <section>
      <Breadcrumbs breadcrumbs={showcaseBreadcrumb} />
      <ul>
        <li>
          <Link to={'buttons'}>Buttons</Link>
        </li>
        <li>
          <Link to={'form'}>Forms</Link>
        </li>
        <li>
          <Link to={'images'}>Images</Link>
        </li>
      </ul>
    </section>
  );
};
