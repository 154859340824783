/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.keychain.oidc
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: keychain-oidc.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as keychain$oidc_pb from './keychain-oidc_pb'; // proto import: "keychain-oidc.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class OidcKeychainServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateOidcKeychainFactory = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/CreateOidcKeychainFactory',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.CreateOidcKeychainFactoryRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.CreateOidcKeychainFactoryRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createOidcKeychainFactory(
    request: keychain$oidc_pb.CreateOidcKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createOidcKeychainFactory(
    request: keychain$oidc_pb.CreateOidcKeychainFactoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createOidcKeychainFactory(
    request: keychain$oidc_pb.CreateOidcKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/CreateOidcKeychainFactory',
        request,
        metadata || {},
        this.methodDescriptorCreateOidcKeychainFactory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/CreateOidcKeychainFactory',
    request,
    metadata || {},
    this.methodDescriptorCreateOidcKeychainFactory);
  }

  methodDescriptorPushOidcKeychain = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/PushOidcKeychain',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.PushOidcKeychainRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.PushOidcKeychainRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  pushOidcKeychain(
    request: keychain$oidc_pb.PushOidcKeychainRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  pushOidcKeychain(
    request: keychain$oidc_pb.PushOidcKeychainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  pushOidcKeychain(
    request: keychain$oidc_pb.PushOidcKeychainRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/PushOidcKeychain',
        request,
        metadata || {},
        this.methodDescriptorPushOidcKeychain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/PushOidcKeychain',
    request,
    metadata || {},
    this.methodDescriptorPushOidcKeychain);
  }

  methodDescriptorCreateOidcRole = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/CreateOidcRole',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.CreateOidcRoleRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.CreateOidcRoleRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createOidcRole(
    request: keychain$oidc_pb.CreateOidcRoleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createOidcRole(
    request: keychain$oidc_pb.CreateOidcRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createOidcRole(
    request: keychain$oidc_pb.CreateOidcRoleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/CreateOidcRole',
        request,
        metadata || {},
        this.methodDescriptorCreateOidcRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/CreateOidcRole',
    request,
    metadata || {},
    this.methodDescriptorCreateOidcRole);
  }

  methodDescriptorDeleteOidcRole = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/DeleteOidcRole',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.DeleteOidcRoleRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.DeleteOidcRoleRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteOidcRole(
    request: keychain$oidc_pb.DeleteOidcRoleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteOidcRole(
    request: keychain$oidc_pb.DeleteOidcRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteOidcRole(
    request: keychain$oidc_pb.DeleteOidcRoleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/DeleteOidcRole',
        request,
        metadata || {},
        this.methodDescriptorDeleteOidcRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/DeleteOidcRole',
    request,
    metadata || {},
    this.methodDescriptorDeleteOidcRole);
  }

  methodDescriptorAddAllowedOidcRedirectUri = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/AddAllowedOidcRedirectUri',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.AddAllowedOidcRedirectUriRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.AddAllowedOidcRedirectUriRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  addAllowedOidcRedirectUri(
    request: keychain$oidc_pb.AddAllowedOidcRedirectUriRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  addAllowedOidcRedirectUri(
    request: keychain$oidc_pb.AddAllowedOidcRedirectUriRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  addAllowedOidcRedirectUri(
    request: keychain$oidc_pb.AddAllowedOidcRedirectUriRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/AddAllowedOidcRedirectUri',
        request,
        metadata || {},
        this.methodDescriptorAddAllowedOidcRedirectUri,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/AddAllowedOidcRedirectUri',
    request,
    metadata || {},
    this.methodDescriptorAddAllowedOidcRedirectUri);
  }

  methodDescriptorReplaceAllowedOidcRedirectUris = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/ReplaceAllowedOidcRedirectUris',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.ReplaceAllowedOidcRedirectUrisRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.ReplaceAllowedOidcRedirectUrisRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  replaceAllowedOidcRedirectUris(
    request: keychain$oidc_pb.ReplaceAllowedOidcRedirectUrisRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  replaceAllowedOidcRedirectUris(
    request: keychain$oidc_pb.ReplaceAllowedOidcRedirectUrisRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  replaceAllowedOidcRedirectUris(
    request: keychain$oidc_pb.ReplaceAllowedOidcRedirectUrisRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/ReplaceAllowedOidcRedirectUris',
        request,
        metadata || {},
        this.methodDescriptorReplaceAllowedOidcRedirectUris,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/ReplaceAllowedOidcRedirectUris',
    request,
    metadata || {},
    this.methodDescriptorReplaceAllowedOidcRedirectUris);
  }

  methodDescriptorReplaceAllowedOrigins = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/ReplaceAllowedOrigins',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.ReplaceAllowedOriginsRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.ReplaceAllowedOriginsRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  replaceAllowedOrigins(
    request: keychain$oidc_pb.ReplaceAllowedOriginsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  replaceAllowedOrigins(
    request: keychain$oidc_pb.ReplaceAllowedOriginsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  replaceAllowedOrigins(
    request: keychain$oidc_pb.ReplaceAllowedOriginsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/ReplaceAllowedOrigins',
        request,
        metadata || {},
        this.methodDescriptorReplaceAllowedOrigins,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/ReplaceAllowedOrigins',
    request,
    metadata || {},
    this.methodDescriptorReplaceAllowedOrigins);
  }

  methodDescriptorDeleteAllowedOidcRedirectUri = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/DeleteAllowedOidcRedirectUri',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.DeleteAllowedOidcRedirectUriRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.DeleteAllowedOidcRedirectUriRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteAllowedOidcRedirectUri(
    request: keychain$oidc_pb.DeleteAllowedOidcRedirectUriRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteAllowedOidcRedirectUri(
    request: keychain$oidc_pb.DeleteAllowedOidcRedirectUriRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteAllowedOidcRedirectUri(
    request: keychain$oidc_pb.DeleteAllowedOidcRedirectUriRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/DeleteAllowedOidcRedirectUri',
        request,
        metadata || {},
        this.methodDescriptorDeleteAllowedOidcRedirectUri,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/DeleteAllowedOidcRedirectUri',
    request,
    metadata || {},
    this.methodDescriptorDeleteAllowedOidcRedirectUri);
  }

  methodDescriptorOidcAddRoleToUser = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcAddRoleToUser',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.OidcAddRoleToUserRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.OidcAddRoleToUserRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  oidcAddRoleToUser(
    request: keychain$oidc_pb.OidcAddRoleToUserRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  oidcAddRoleToUser(
    request: keychain$oidc_pb.OidcAddRoleToUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  oidcAddRoleToUser(
    request: keychain$oidc_pb.OidcAddRoleToUserRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcAddRoleToUser',
        request,
        metadata || {},
        this.methodDescriptorOidcAddRoleToUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcAddRoleToUser',
    request,
    metadata || {},
    this.methodDescriptorOidcAddRoleToUser);
  }

  methodDescriptorOidcDeleteRoleToUser = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcDeleteRoleToUser',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.OidcDeleteRoleFromUserRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.OidcDeleteRoleFromUserRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  oidcDeleteRoleToUser(
    request: keychain$oidc_pb.OidcDeleteRoleFromUserRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  oidcDeleteRoleToUser(
    request: keychain$oidc_pb.OidcDeleteRoleFromUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  oidcDeleteRoleToUser(
    request: keychain$oidc_pb.OidcDeleteRoleFromUserRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcDeleteRoleToUser',
        request,
        metadata || {},
        this.methodDescriptorOidcDeleteRoleToUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcDeleteRoleToUser',
    request,
    metadata || {},
    this.methodDescriptorOidcDeleteRoleToUser);
  }

  methodDescriptorOidcReplaceRolesForUser = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcReplaceRolesForUser',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.OidcReplaceRolesForUserRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.OidcReplaceRolesForUserRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  oidcReplaceRolesForUser(
    request: keychain$oidc_pb.OidcReplaceRolesForUserRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  oidcReplaceRolesForUser(
    request: keychain$oidc_pb.OidcReplaceRolesForUserRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  oidcReplaceRolesForUser(
    request: keychain$oidc_pb.OidcReplaceRolesForUserRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcReplaceRolesForUser',
        request,
        metadata || {},
        this.methodDescriptorOidcReplaceRolesForUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcReplaceRolesForUser',
    request,
    metadata || {},
    this.methodDescriptorOidcReplaceRolesForUser);
  }

  methodDescriptorOidcSetUserEmail = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcSetUserEmail',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.OidcSetUserEmailRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.OidcSetUserEmailRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  oidcSetUserEmail(
    request: keychain$oidc_pb.OidcSetUserEmailRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  oidcSetUserEmail(
    request: keychain$oidc_pb.OidcSetUserEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  oidcSetUserEmail(
    request: keychain$oidc_pb.OidcSetUserEmailRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcSetUserEmail',
        request,
        metadata || {},
        this.methodDescriptorOidcSetUserEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcSetUserEmail',
    request,
    metadata || {},
    this.methodDescriptorOidcSetUserEmail);
  }

  methodDescriptorOidcListUsersWithRole = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcListUsersWithRole',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.OidcListUsersWithRoleRequest,
    keychain$oidc_pb.OidcListUsersWithRoleResponse,
    (request: keychain$oidc_pb.OidcListUsersWithRoleRequest) => {
      return request.serializeBinary();
    },
    keychain$oidc_pb.OidcListUsersWithRoleResponse.deserializeBinary
  );

  oidcListUsersWithRole(
    request: keychain$oidc_pb.OidcListUsersWithRoleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<keychain$oidc_pb.OidcListUsersWithRoleResponse>;

  oidcListUsersWithRole(
    request: keychain$oidc_pb.OidcListUsersWithRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: keychain$oidc_pb.OidcListUsersWithRoleResponse) => void): grpcWeb.ClientReadableStream<keychain$oidc_pb.OidcListUsersWithRoleResponse>;

  oidcListUsersWithRole(
    request: keychain$oidc_pb.OidcListUsersWithRoleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: keychain$oidc_pb.OidcListUsersWithRoleResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcListUsersWithRole',
        request,
        metadata || {},
        this.methodDescriptorOidcListUsersWithRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcListUsersWithRole',
    request,
    metadata || {},
    this.methodDescriptorOidcListUsersWithRole);
  }

  methodDescriptorOidcTerminateRootSession = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcTerminateRootSession',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.OidcTerminateRootSessionRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.OidcTerminateRootSessionRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  oidcTerminateRootSession(
    request: keychain$oidc_pb.OidcTerminateRootSessionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  oidcTerminateRootSession(
    request: keychain$oidc_pb.OidcTerminateRootSessionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  oidcTerminateRootSession(
    request: keychain$oidc_pb.OidcTerminateRootSessionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcTerminateRootSession',
        request,
        metadata || {},
        this.methodDescriptorOidcTerminateRootSession,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcTerminateRootSession',
    request,
    metadata || {},
    this.methodDescriptorOidcTerminateRootSession);
  }

  methodDescriptorOidcSetSessionLifespan = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcSetSessionLifespan',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.OidcSetSessionLifespanRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.OidcSetSessionLifespanRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  oidcSetSessionLifespan(
    request: keychain$oidc_pb.OidcSetSessionLifespanRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  oidcSetSessionLifespan(
    request: keychain$oidc_pb.OidcSetSessionLifespanRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  oidcSetSessionLifespan(
    request: keychain$oidc_pb.OidcSetSessionLifespanRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcSetSessionLifespan',
        request,
        metadata || {},
        this.methodDescriptorOidcSetSessionLifespan,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcSetSessionLifespan',
    request,
    metadata || {},
    this.methodDescriptorOidcSetSessionLifespan);
  }

  methodDescriptorOidcUpdateRoleDescription = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcUpdateRoleDescription',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.OidcUpdateRoleDescriptionRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.OidcUpdateRoleDescriptionRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  oidcUpdateRoleDescription(
    request: keychain$oidc_pb.OidcUpdateRoleDescriptionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  oidcUpdateRoleDescription(
    request: keychain$oidc_pb.OidcUpdateRoleDescriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  oidcUpdateRoleDescription(
    request: keychain$oidc_pb.OidcUpdateRoleDescriptionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcUpdateRoleDescription',
        request,
        metadata || {},
        this.methodDescriptorOidcUpdateRoleDescription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcUpdateRoleDescription',
    request,
    metadata || {},
    this.methodDescriptorOidcUpdateRoleDescription);
  }

  methodDescriptorOidcUpdateBiometricsRequired = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcUpdateBiometricsRequired',
    grpcWeb.MethodType.UNARY,
    keychain$oidc_pb.OidcUpdateBiometricsRequiredRequest,
    shared_pb.EmptyResponse,
    (request: keychain$oidc_pb.OidcUpdateBiometricsRequiredRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  oidcUpdateBiometricsRequired(
    request: keychain$oidc_pb.OidcUpdateBiometricsRequiredRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  oidcUpdateBiometricsRequired(
    request: keychain$oidc_pb.OidcUpdateBiometricsRequiredRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  oidcUpdateBiometricsRequired(
    request: keychain$oidc_pb.OidcUpdateBiometricsRequiredRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcUpdateBiometricsRequired',
        request,
        metadata || {},
        this.methodDescriptorOidcUpdateBiometricsRequired,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.oidc.OidcKeychainService/OidcUpdateBiometricsRequired',
    request,
    metadata || {},
    this.methodDescriptorOidcUpdateBiometricsRequired);
  }

}

