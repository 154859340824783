import { z } from 'zod';
import { DataResponse } from '../../proto/data_pb';
import { AnomalyMessage, Coordinates } from '../../proto/shared_pb';
import {
  ClearAnomalyMessage,
  CreateSubjectRequest,
  DeleteSubjectRequest,
  UpdateAnomalyMessage,
  UpdateProductionStatusRequest,
  UpdateSubjectAboutRequest,
  UpdateSubjectCoordinatesRequest,
  UpdateSubjectNameRequest,
  UpdateSubjectOwnerRoleRequest
} from '../../proto/subjects_pb';
import {
  getGrpcMetadata,
  getSubjectsClient,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import { setPopup } from '../slice/popup';
import '../slice/subjects';
import { Subject } from '../slice/subjects';
import {
  id,
  name,
  noValidationString,
  optionTypeSchema
} from '../types/zodSchemas';
import { getAppData } from './appData';
import { convertToOperation } from './operations';
import { convertToKeypedia, createText } from './util';

export const CreateSubjectSchema = z.object({
  name: name,
  id: id,
  category: optionTypeSchema,
  adminRole: optionTypeSchema
});

export type CreateSubjectSchema = z.infer<typeof CreateSubjectSchema>;
export const createSubject = createAppAsyncThunk<void, CreateSubjectSchema>(
  'subjects/createSubject',
  async ({ name, id, category, adminRole }, thunkAPI) => {
    const request = new CreateSubjectRequest()
      .setName(name)
      .setId(id)
      .setCategory(category.value)
      .setAdminRole(adminRole.value);
    try {
      await getSubjectsClient().createSubject(request, await getGrpcMetadata());
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Stedet ble opprettet!'
        })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const deleteSubject = createAppAsyncThunk<void, string>(
  'subjects/deleteSubject',
  async (subjectId, thunkAPI) => {
    try {
      await getSubjectsClient().deleteSubject(
        new DeleteSubjectRequest().setSubjectId(subjectId),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Stedet ble slettet!'
        })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

interface UpdateSubjectCooridatesProps {
  subjectId: string;
  coordinates: { lat: number; lng: number };
}

export const updateSubjectCoordinates = createAppAsyncThunk<
  void,
  UpdateSubjectCooridatesProps
>(
  'subjects/updateSubjectCoordinates',
  async ({ subjectId, coordinates }, thunkAPI) => {
    try {
      await getSubjectsClient().updateSubjectCoordinates(
        new UpdateSubjectCoordinatesRequest()
          .setId(subjectId)
          .setCoordinates(
            new Coordinates().setLat(coordinates.lat).setLon(coordinates.lng)
          ),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Posisjonen er oppdatert'
        })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const updateSubjectName = createAppAsyncThunk<
  void,
  { subjectId: string; name: string }
>('subjects/updateSubjectName', async ({ subjectId, name }, thunkAPI) => {
  try {
    await getSubjectsClient().updateSubjectName(
      new UpdateSubjectNameRequest().setId(subjectId).setName(name),
      await getGrpcMetadata()
    );
    thunkAPI.dispatch(getAppData());
    thunkAPI.dispatch(
      setPopup({
        error: false,
        message: 'Navnet er oppdatert'
      })
    );
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const updateSubjectAbout = createAppAsyncThunk<
  void,
  { subjectId: string; content: string }
>('subjects/updateSubjectAbout', async ({ subjectId, content }, thunkAPI) => {
  try {
    await getSubjectsClient().updateSubjectAbout(
      new UpdateSubjectAboutRequest()
        .setSubjectId(subjectId)
        .setAbout(createText(content)),
      await getGrpcMetadata()
    );
    thunkAPI.dispatch(getAppData());
    thunkAPI.dispatch(
      setPopup({
        error: false,
        message: 'Beskrivelse er oppdatert'
      })
    );
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const updateAnomaly = createAppAsyncThunk<
  void,
  { subjectId: string; content: string }
>('subjects/updateAnomaly', async ({ subjectId, content }, thunkAPI) => {
  try {
    await getSubjectsClient().updateAnomaly(
      new UpdateAnomalyMessage()
        .setSubjectId(subjectId)
        .setContent(createText(content)),
      await getGrpcMetadata()
    );
    thunkAPI.dispatch(getAppData());
    thunkAPI.dispatch(
      setPopup({
        error: false,
        message: 'Avviket er oppdatert'
      })
    );
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const clearAnomaly = createAppAsyncThunk<void, string>(
  'subjects/clearAnomaly',
  async (subjectId, thunkAPI) => {
    try {
      await getSubjectsClient().clearAnomaly(
        new ClearAnomalyMessage().setSubjectId(subjectId),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Avviket er fjernet'
        })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const ChangeSubjectAdminRole = z.object({
  subjectId: noValidationString,
  newAdmin: optionTypeSchema
});

export type ChangeSubjectAdminRole = z.infer<typeof ChangeSubjectAdminRole>;

export const changeSubjectAdminRole = createAppAsyncThunk<
  void,
  ChangeSubjectAdminRole
>(
  'subjects/changeSubjectAdminRole',
  async ({ subjectId, newAdmin: { value } }, thunkAPI) => {
    try {
      await getSubjectsClient().updateSubjectOwnerRole(
        new UpdateSubjectOwnerRoleRequest()
          .setSubjectId(subjectId)
          .setNewOwnerRoleId(value),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Admin-rolle er oppdatert'
        })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const updateProductionStatus = createAppAsyncThunk<
  boolean,
  { subjectId: string; inProduction: boolean }
>(
  'subjects/updateProductionStatus',
  async ({ subjectId, inProduction }, thunkAPI) => {
    try {
      await getSubjectsClient().updateProductionStatus(
        new UpdateProductionStatusRequest()
          .setId(subjectId)
          .setInProduction(inProduction),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Produksjonsstatus er oppdatert'
        })
      );
      return inProduction;
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const convertToSubject = (
  subject: DataResponse.Subject.AsObject
): Subject => {
  return {
    ...subject,
    operationsList: subject.operationsList.map(operation =>
      convertToOperation(operation)
    ),
    anomalyMessage: convertToAnomalyMessage(subject.anomalyMessage),
    about: About.parse(subject.about?.content),
    wiki: convertToKeypedia(subject.wiki)
  };
};

const AnomalyMessageObject = z.object({
  htmlContent: z.string().default(''),
  author: z.string().default(''),
  timestampMillis: z.number().default(0)
});
const convertToAnomalyMessage = (anomaly?: AnomalyMessage.AsObject) => {
  return AnomalyMessageObject.parse({
    htmlContent: anomaly?.text?.content,
    author: anomaly?.author,
    timestampMillis: anomaly?.timestamp?.epochMillis
  });
};

const About = z
  .string()
  .default('<p></p>')
  .transform(value => {
    return value.replace(/<p>\s*<\/p>/g, '');
  });
