import React from 'react';

export const TermsSvg = () => (
  <svg
    width='54'
    height='49'
    viewBox='0 0 54 49'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Group_1523' clipPath='url(#clip0_57_1080)'>
      <path
        id='Path 2927'
        d='M4.88969 9.79996C5.85684 9.79996 6.80226 9.51251 7.6064 8.97397C8.41053 8.43543 9.03725 7.66999 9.4073 6.77446C9.77735 5.87892 9.8741 4.89352 9.68533 3.94287C9.49655 2.99222 9.03071 2.11903 8.34674 1.43373C7.66277 0.748431 6.79139 0.281806 5.84279 0.0928705C4.8942 -0.0960651 3.911 0.00117546 3.01755 0.372293C2.1241 0.743411 1.36053 1.37174 0.823405 2.1778C0.286281 2.98387 -0.000266981 3.93148 1.86653e-07 4.90077C0.000358361 6.20024 0.515679 7.44636 1.43263 8.3651C2.34959 9.28384 3.5931 9.79996 4.88969 9.79996Z'
        fill='#2A2859'
      />
      <path
        id='Path 2928'
        d='M17.1126 2.45026H54.0014V7.3508H17.1126V2.45026Z'
        fill='#2A2859'
      />
      <path
        id='Path 2929'
        d='M4.88969 29.3992C5.85678 29.3992 6.80215 29.1118 7.60626 28.5733C8.41036 28.0348 9.03708 27.2695 9.40717 26.374C9.77726 25.4786 9.87409 24.4932 9.68542 23.5426C9.49675 22.592 9.03106 21.7188 8.34722 21.0335C7.66339 20.3481 6.79213 19.8814 5.84362 19.6923C4.89511 19.5032 3.91196 19.6002 3.01849 19.9711C2.12501 20.3421 1.36135 20.9702 0.824061 21.7761C0.286775 22.582 0 23.5294 0 24.4987C0 25.1422 0.126476 25.7795 0.372205 26.374C0.617935 26.9686 0.978108 27.5088 1.43216 27.9639C1.88621 28.4189 2.42524 28.7799 3.01849 29.0262C3.61173 29.2724 4.24757 29.3992 4.88969 29.3992Z'
        fill='#2A2859'
      />
      <path
        id='Path 2930'
        d='M17.1126 22.0497H54.0014V26.9503H17.1126V22.0497Z'
        fill='#2A2859'
      />
      <path
        id='Path 2931'
        d='M4.88969 49C5.85678 49 6.80215 48.7126 7.60626 48.1741C8.41036 47.6356 9.03708 46.8703 9.40717 45.9748C9.77726 45.0794 9.87409 44.094 9.68542 43.1434C9.49675 42.1928 9.03106 41.3196 8.34722 40.6343C7.66339 39.9489 6.79213 39.4822 5.84362 39.2931C4.89511 39.104 3.91196 39.201 3.01849 39.5719C2.12501 39.9429 1.36135 40.571 0.824061 41.3769C0.286775 42.1828 0 43.1302 0 44.0995C0 45.3992 0.515162 46.6456 1.43216 47.5647C2.34915 48.4837 3.59286 49 4.88969 49Z'
        fill='#2A2859'
      />
      <path
        id='Path 2932'
        d='M17.1126 41.6505H54.0014V46.5511H17.1126V41.6505Z'
        fill='#2A2859'
      />
    </g>
    <defs>
      <clipPath id='clip0_57_1080'>
        <rect width='54' height='49' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
