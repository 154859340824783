import React from 'react';
import './AppNotLoggedIn.scss';
import { LoginMethodApp } from './assets/LoginMethodApp';
import { setUseSafetycloak } from './utils/storage/webStorage';

type Props = {
  loginAndRunApp: () => void;
};

export const AppNotLoggedIn: React.FC<Props> = ({ loginAndRunApp }) => {
  const setSafetycloakLogin = () => {
    setUseSafetycloak('yes');
    loginAndRunApp();
  };

  return (
    <div className='keystudio-landing'>
      <section className='left-side'>
        <div>
          <h1>Velkommen</h1>
          <span className='login-method'>
            <button onClick={setSafetycloakLogin}>
              <LoginMethodApp /> <span>Logg inn</span>
            </button>
          </span>
          <div className='contact'>
            Trøbbel med innlogging? Behov for nye brukere? Send oss en e‑post:
            <a href='mailto:support@citykey.zendesk.com?subject=Mobilinnlogging'>
              support@citykey.zendesk.com
            </a>
          </div>
        </div>
      </section>
      <section className='right-side'>
        <div>
          <img src='/icon512.png' />
          <h1>KeyStudio</h1>
          <h2>Norges aller beste administasjonsverktøy for Oslonøkkelen.</h2>
        </div>
      </section>
    </div>
  );
};
