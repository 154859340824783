import React from 'react';
import './SubjectsContainer.scss';

interface SubjectsContainerProps {
  children: React.ReactNode;
}

export const SubjectsContainer: React.FC<SubjectsContainerProps> = ({
  children
}) => (
  <div className='subjects-container' role={'list'}>
    {children}
  </div>
);
