import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import { updateRelevantSubjects } from '../../../../redux/actions/admissionCards';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { AdmissionCard } from '../../../../redux/slice/admissionCards';
import { subjectSelectors } from '../../../../redux/slice/subjects';
import { historyGoBack } from '../../../../utils/history';
import { SelectSubjectList } from './SelectSubjectList';

interface Props {
  admissionCard: AdmissionCard;
}

export const EditAdmissionCardSubjects: React.FC<Props> = ({
  admissionCard
}) => {
  const subjects = useAppSelector(subjectSelectors.selectAll);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedSubjectIds, setSelectedSubjectIds] = useState<string[]>(
    admissionCard.relevantSubjectIdsList
  );

  const handleSaveClick = () =>
    dispatchAndHandleResult(
      dispatch,
      updateRelevantSubjects,
      {
        id: admissionCard.id,
        version: admissionCard.version,
        subjects: selectedSubjectIds
      },
      () => {
        historyGoBack(navigate);
      }
    );

  return (
    <React.Fragment>
      <h3>Tilknyttede steder</h3>
      <p>Dette adgangskortet gir adgang til de avkryssede stedene:</p>
      <br />
      <ul className='subjects'>
        {subjects &&
          subjects.map(subject => (
            <SelectSubjectList
              key={subject.id}
              subject={subject}
              selectedSubjectIds={selectedSubjectIds}
              setSelectedTermIds={setSelectedSubjectIds}
            />
          ))}
      </ul>
      <Button label='Lagre' type='primary' onClick={handleSaveClick} />
    </React.Fragment>
  );
};
