import { z } from 'zod';
import {
  CreateCategoryRequest,
  DeleteCategoryRequest,
  ListCategoriesRequest,
  UpdateCategoryDescriptionRequest,
  UpdateCategoryNameRequest
} from '../../proto/category_pb';
import {
  getCategoryClient,
  getGrpcMetadata,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import { setPopup } from '../slice/popup';
import { Category } from '../types/protoTypes';
import { description, name } from '../types/zodSchemas';
import { createText } from './util';

export const listCategories = createAppAsyncThunk<Category[], void>(
  'categories/getCategories',
  async (_, thunkAPI) => {
    try {
      const response = await getCategoryClient().listCategories(
        new ListCategoriesRequest(),
        await getGrpcMetadata()
      );
      return response.toObject().categoriesList;
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const CreateCategoryForm = z.object({
  name: name,
  about: description
});
export type CreateCategoryForm = z.infer<typeof CreateCategoryForm>;
export const createCategory = createAppAsyncThunk<
  CreateCategoryRequest.AsObject,
  CreateCategoryForm
>('categories/createCategory', async ({ name, about }, thunkAPI) => {
  try {
    const request = new CreateCategoryRequest()
      .setName(name)
      .setDescription(createText(about));
    await getCategoryClient().createCategory(request, await getGrpcMetadata());
    thunkAPI.dispatch(
      setPopup({ error: false, message: 'Kategorien er laget' })
    );
    thunkAPI.dispatch(listCategories());
    return request.toObject();
  } catch (error) {
    return handleGrpcError(error, thunkAPI);
  }
});

export const updateCategoryName = createAppAsyncThunk<
  UpdateCategoryNameRequest.AsObject,
  { id: string; name: string }
>('categories/updateCategoryName', async ({ id, name }, thunkAPI) => {
  try {
    const request = new UpdateCategoryNameRequest().setId(id).setName(name);
    await getCategoryClient().updateCategoryName(
      request,
      await getGrpcMetadata()
    );
    thunkAPI.dispatch(
      setPopup({ error: false, message: 'Kategorien er oppdatert' })
    );
    return request.toObject();
  } catch (error) {
    return handleGrpcError(error, thunkAPI);
  }
});

export const UpdateCategoryDescription = z.object({
  id: z.string(),
  description: description
});

export type UpdateCategoryDescription = z.infer<
  typeof UpdateCategoryDescription
>;

export const updateCategoryDescription = createAppAsyncThunk<
  UpdateCategoryDescriptionRequest.AsObject,
  UpdateCategoryDescription
>(
  'categories/updateCategoryDescription',
  async ({ id, description }, thunkAPI) => {
    try {
      const request = new UpdateCategoryDescriptionRequest()
        .setId(id)
        .setDescription(createText(description));
      await getCategoryClient().updateCategoryDescription(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Kategorien er oppdatert' })
      );
      return request.toObject();
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const deleteCategory = createAppAsyncThunk<string, string>(
  'categories/deleteCategory',
  async (id, thunkAPI) => {
    try {
      await getCategoryClient().deleteCategory(
        new DeleteCategoryRequest().setCategoryId(id),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Kategorien er slettet' })
      );
      return id;
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);
