interface SingularPlural {
  s: string;
  p: string;
}

const lookupTable = new Map<string, string>([
  ['lås', 'låser'],
  ['actionchain', 'actionchains'],
  ['action', 'actions'],
  ['tilgangsliste', 'tilgangslister'],
  ['OIDC tilgangsliste', 'OIDC tilgangslister'],
  ['offentlig tilgangsliste', 'offentlige tilgangslister'],
  ['adapter', 'adaptere'],
  ['adgangskort', 'adgangskort'],
  ['bruker', 'brukere'],
  ['betingelse', 'betingelser'],
  ['spann', 'spann'],
  ['vilkår', 'vilkår'],
  ['rolle', 'roller']
]);

const getSingularPlural = (ting: string): SingularPlural => {
  if (lookupTable.has(ting)) {
    return { s: ting, p: lookupTable.get(ting)! };
  }
  return { s: ting, p: ting + 'er' };
};

export const antall = (n = 0, ting: string): string => {
  const { s, p } = getSingularPlural(ting);
  switch (n) {
    case 0:
      return `Ingen ${p}`;
    case 1:
      return `${n} ${s}`;
    default:
      return `${n} ${p}`;
  }
};

export const storForbokstav = (s: string): string =>
  s.charAt(0).toUpperCase() + s.slice(1);
