import React from 'react';
import { Button } from '../Button/Button';

interface Props {
  imageLink: string;
  setShowPopup: (showPopup: boolean) => void;
}

export const Image: React.FC<Props> = ({ imageLink, setShowPopup }) => (
  <div
    className='image-container subject-action-card'
    onClick={() => setShowPopup(true)}
  >
    <img src={imageLink} alt='' />
    <Button type='edit' label='Rediger' onClick={() => setShowPopup(true)} />
  </div>
);
