import React from 'react';

type Props = {
  size?: number;
};
export const ThreeDots: React.FC<Props> = ({ size = 136 }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12181'
      data-name='Group 12181'
      transform='translate(-1718 -219)'
    >
      <g
        id='Group_12179'
        data-name='Group 12179'
        transform='translate(1390 -13)'
      >
        <circle
          id='Ellipse_691'
          data-name='Ellipse 691'
          cx='68'
          cy='68'
          r='68'
          transform='translate(328 232)'
          fill='#f9b3ab'
        />
      </g>
      <g id='Group_12180' data-name='Group 12180' transform='translate(-2)'>
        <circle
          id='Ellipse_701'
          data-name='Ellipse 701'
          cx='7'
          cy='7'
          r='7'
          transform='translate(1746 280)'
          fill='#292858'
        />
        <circle
          id='Ellipse_702'
          data-name='Ellipse 702'
          cx='7'
          cy='7'
          r='7'
          transform='translate(1816 280)'
          fill='#292858'
        />
        <circle
          id='Ellipse_703'
          data-name='Ellipse 703'
          cx='7'
          cy='7'
          r='7'
          transform='translate(1781 280)'
          fill='#292858'
        />
      </g>
    </g>
  </svg>
);
