import { logAnalyticsEvent } from '../../../analyticsConfig';

export const handleImageInput = (
  evt: React.ChangeEvent<HTMLInputElement>,
  setError: (error: string) => void,
  setImage: (image: File | null) => void,
  maxWidth = 800
) => {
  setError('');
  if (evt && evt.target && evt.target.files && evt.target.files.length !== 0) {
    const file = evt.target.files[0];
    const acceptedFileTypes = ['image/jpeg', 'image/png'];
    const sizeInKB = file.size / 1024;

    if (!acceptedFileTypes.includes(file.type)) {
      setError('Filtypen støttes ikke. Må være .png eller .jpg');
      setImage(null);
    } else {
      const reader = new FileReader();
      reader.onload = event => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          let width = img.width;
          let height = img.height;

          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }

          canvas.width = width;
          canvas.height = height;
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob(blob => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type
              });
              const newSizeInKB = resizedFile.size / 1024;
              logAnalyticsEvent('image_resized', {
                data: `image sized from ${sizeInKB.toFixed(0)}KB to ${newSizeInKB.toFixed(0)}KB`
              });
              if (newSizeInKB < 1024) {
                setImage(resizedFile);
              } else {
                setError('Filstørrelsen må være mindre enn 1MB');
                setImage(null);
              }
            }
          }, file.type);
        };
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  }
};
