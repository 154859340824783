import React from 'react';

interface ThirdPartySystemsContainerProps {
  children: React.ReactNode;
}

export const ThirdPartySystemsContainer: React.FC<
  ThirdPartySystemsContainerProps
> = ({ children }) => (
  <section className='third-party-systems'>{children}</section>
);
