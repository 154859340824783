import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { subjectHome } from '../../../../components/Navigation/navPaths';
import { DynamicTag } from '../../../../proto/shared_pb';
import { Subject } from '../../../../redux/slice/subjects';
import { antall } from '../../../../utils/language';
import { Tag } from '../../DynamicTag/DynamicTag';
import './SubjectCard.scss';

interface SubjectCardProps {
  subject: Subject;
  hasSeriousProblem: boolean;
  anomaly: boolean;
  index: number;
  img?: string;
}
export const SubjectCard: React.FC<SubjectCardProps> = ({
  subject,
  hasSeriousProblem,
  anomaly,
  index,
  img
}) => {
  const { id, name, dynamicTagsList } = subject;
  const [openTag, setOpenTag] = React.useState<DynamicTag.Type>();

  const classnames = cn('subject-card', {
    warning: hasSeriousProblem,
    anomaly: anomaly
  });

  const problemtekst = anomaly ? 'Aktiv avviksmelding' : '';

  const sorted = dynamicTagsList
    .toSorted(a => {
      if (a.typeId === DynamicTag.Type.TROUBLE) {
        return -1;
      }
      return 0;
    })
    .filter(value => {
      return value.typeId !== DynamicTag.Type.ANOMALY_MESSAGE;
    });

  return (
    <Link to={subjectHome.path + '/' + id}>
      <div data-testid='subject-card' className={classnames}>
        <div className='inner'>
          {img && (
            <div className='img-container'>
              <img src={img} alt='subject' />
            </div>
          )}
          <p className='title'>{name}</p>
          <p className='description'>
            {antall(subject.operationsList.length, 'lås')}
          </p>
          <div className='problem-section'>
            <p className='text'>{problemtekst}</p>
          </div>
          <div className='tags' style={{ zIndex: index }}>
            {sorted.map(tag => (
              <Tag
                tag={tag}
                activeTag={openTag}
                setActiveTag={setOpenTag}
                key={tag.typeId}
              />
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
};
