import React from 'react';
import { OpeningHours } from '../../../../redux/types/common';
import { formatSimpleDayRules } from '../../../../utils/keychainFactory/openingHours';
import { AlwaysOpenDaysList } from './alwaysOpenList/AlwaysOpenDaysList';
import { SimpleDayRuleList } from './simpleDayRuleList/SimpleDayRuleList';

interface Props {
  openingHours: OpeningHours;
  setOpeningHours: (openingHours: OpeningHours) => void;
}

export const ManualGrantKeychainFactoryNormalHours: React.FC<Props> = ({
  openingHours,
  setOpeningHours
}) => {
  const formattedSimpleDayRules = formatSimpleDayRules(
    openingHours.simpleDayRulesList
  );

  return (
    <div className='hours-card'>
      <h3>Normale åpningstider</h3>
      {openingHours.alwaysOpen ? (
        <AlwaysOpenDaysList />
      ) : (
        <SimpleDayRuleList
          formattedSimpleDayRules={formattedSimpleDayRules}
          openingHours={openingHours}
          setOpeningHours={setOpeningHours}
        />
      )}
    </div>
  );
};
