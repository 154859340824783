import React from 'react';
import { TableWithButtons } from '../../../../components/Table/TableWithButtons';
import { AuditLog } from '../../../../redux/actions/auditLogs';
import { formatDateFromEpochMillis } from '../../../../utils/timeConverter';

type Props = {
  auditLogs: AuditLog[];
  showResourceId?: boolean;
};
export const AuditLogsTable: React.FC<Props> = ({
  auditLogs,
  showResourceId = false
}) => {
  const headings = ['Tidspunkt', 'Utført av', 'Sammendrag', 'Type', 'Detaljer'];
  if (showResourceId) {
    headings.unshift('Ressurs ID');
  }
  const tableData = {
    headings: headings,
    rows: auditLogs.map(auditLog => {
      const cells = [
        formatDateFromEpochMillis(auditLog.timestamp),
        auditLog.actor,
        auditLog.summary,
        auditLog.eventType,
        auditLog.details
      ];
      if (showResourceId) {
        cells.unshift(auditLog.resourceId);
      }
      return { cells };
    })
  };
  return <TableWithButtons table={tableData} />;
};
