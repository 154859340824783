import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import Beta from '../../../assets/beta.png';
import { EditableValue } from '../../../components/EditableValue/EditableValue';
import { ToggleGroup } from '../../../components/ToggleGroup/ToggleButton';
import { updateProductionStatus } from '../../../redux/actions/subjects';
import { useAppDispatch } from '../../../redux/hooks';

type Props = {
  inProductionInitial: boolean;
  subjectId: string;
};
export const ProductionStatus: React.FC<Props> = ({
  inProductionInitial,
  subjectId
}) => {
  const dispatch = useAppDispatch();
  const [inProduciton, setInProduction] = useState(inProductionInitial);
  const toggleButtons = [
    {
      value: 'true',
      label: 'I produksjon',
      onClick: () => {
        setInProduction(true);
      }
    },
    {
      value: 'false',
      label: 'Beta',
      onClick: () => {
        setInProduction(false);
      }
    }
  ];

  const saveStatus = () => {
    dispatch(
      updateProductionStatus({
        subjectId: subjectId,
        inProduction: inProduciton
      })
    );
  };

  return (
    <EditableValue
      methods={useForm()}
      label='Produksjonsstatus'
      value={
        inProductionInitial ? (
          'I produksjon'
        ) : (
          <Fragment>
            <img src={Beta} alt='beta' className='beta' /> Beta
          </Fragment>
        )
      }
      mainButtonClick={saveStatus}
    >
      <ToggleGroup buttons={toggleButtons} selectedValue={inProduciton + ''} />
    </EditableValue>
  );
};
