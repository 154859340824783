import React, { useId } from 'react';
import { Link } from 'react-router-dom';
import { adminAdapterHome } from '../../../../components/Navigation/controlpanelNavPaths';
import { ActionItem } from '../../../../redux/slice/adapters';

interface Props {
  action: ActionItem;
  index: number;
  setSendFodselsnummer: (index: number) => void;
  moveUp: (index: number) => void;
  moveDown: (index: number) => void;
  deleteAction: (index: number) => void;
  last: boolean;
}
export const ActionListItem: React.FC<Props> = ({
  action,
  index,
  setSendFodselsnummer,
  moveUp,
  moveDown,
  deleteAction,
  last
}) => {
  const id = useId();
  return (
    <div key={action.actionUri} className='action-area'>
      <dl className='box action-box'>
        <dt className='label-actionchain'>AdapterId</dt>
        <Link to={`${adminAdapterHome.path}/${action.adapterId}`}>
          <dd> {action.adapterId}🔗</dd>
        </Link>
        <dt className='label-actionchain'>ThingId</dt>
        <dd> {action.thingId}</dd>
        <dt className='label-actionchain'>ActionId</dt>
        <Link
          to={`${adminAdapterHome.path}/${action.adapterId}/${action.thingId}/${action.actionId}`}
        >
          <dd>{action.actionId}🔗</dd>
        </Link>
        <label className='label-actionchain' htmlFor={id}>
          Sender fødselsnummer
        </label>
        <input
          id={id}
          type='checkbox'
          checked={action.sendFodselsnummer}
          onChange={() => {
            setSendFodselsnummer(index);
          }}
        />
      </dl>
      <button
        type='button'
        onClick={() => moveUp(index)}
        className={
          'keystudio-button keystudio-button--order' +
          (index === 0 ? ' first' : '')
        }
      >
        ⬆️
      </button>
      <button
        type='button'
        className='keystudio-button keystudio-button--delete'
        onClick={() => deleteAction(index)}
      >
        ❌
      </button>
      <button
        type='button'
        className={
          'keystudio-button keystudio-button--order' + (last ? ' last' : '')
        }
        onClick={() => {
          moveDown(index);
        }}
      >
        ⬇️
      </button>
    </div>
  );
};
