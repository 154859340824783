import { RpcError } from 'grpc-web';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GRPC_WEB_URL } from '../../constants';
import { keycloak } from '../../keycloak/keycloak-config';
import { AdmissionCardServiceClient } from '../../proto/AdmissioncardsServiceClientPb';
import { BroadcastServiceClient } from '../../proto/BroadcastServiceClientPb';
import { CategoryServiceClient } from '../../proto/CategoryServiceClientPb';
import { DataServiceClient } from '../../proto/DataServiceClientPb';
import { FlightRecordingServiceClient } from '../../proto/FlightrecordingServiceClientPb';
import { AdapterServiceClient } from '../../proto/Grpc-adapterServiceClientPb';
import { KeychainFactoryServiceClient } from '../../proto/Keychain-factoryServiceClientPb';
import { OidcKeychainServiceClient } from '../../proto/Keychain-oidcServiceClientPb';
import { KeychainServiceClient } from '../../proto/KeychainServiceClientPb';
import { OperationLogServiceClient } from '../../proto/Operation-logServiceClientPb';
import { OperationServiceClient } from '../../proto/OperationServiceClientPb';
import { ProximityVerificationDeviceServiceClient } from '../../proto/PvdServiceClientPb';
import { StatisticsServiceClient } from '../../proto/StatisticsServiceClientPb';
import { SubjectServiceClient } from '../../proto/SubjectsServiceClientPb';
import { TermServiceClient } from '../../proto/TermServiceClientPb';
import { TpsServiceClient } from '../../proto/Third-party-systemsServiceClientPb';
import { UtilsServiceClient } from '../../proto/UtilsServiceClientPb';
import { RootState } from '../../redux';
import {
  extractGrpcErrorMessage,
  showErrorPopup
} from '../../redux/actions/util';

let adapterService: AdapterServiceClient;
let admissionCardService: AdmissionCardServiceClient;
let broadcastService: BroadcastServiceClient;
let categoryService: CategoryServiceClient;
let dataService: DataServiceClient;
let flightRecodingService: FlightRecordingServiceClient;
let keychainFactoryService: KeychainFactoryServiceClient;
let keychainService: KeychainServiceClient;
let oidcKeychainService: OidcKeychainServiceClient;
let operationLogService: OperationLogServiceClient;
let operationService: OperationServiceClient;
let proximityVerificationDeviceService: ProximityVerificationDeviceServiceClient;
let statisticsService: StatisticsServiceClient;
let subjectService: SubjectServiceClient;
let termService: TermServiceClient;
let tpsService: TpsServiceClient;
let utilsService: UtilsServiceClient;

declare global {
  interface Window {
    __GRPCWEB_DEVTOOLS__: (arg0: unknown) => void;
  }
}

const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});
export const getAdmissionsClient = () => {
  if (!admissionCardService) {
    admissionCardService = new AdmissionCardServiceClient(GRPC_WEB_URL);
  }
  return admissionCardService;
};

export const getAdapterClient = () => {
  if (!adapterService) {
    adapterService = new AdapterServiceClient(GRPC_WEB_URL);
    enableDevTools([adapterService]);
  }
  return adapterService;
};

export const getBroadcastClient = () => {
  if (!broadcastService) {
    broadcastService = new BroadcastServiceClient(GRPC_WEB_URL);
    enableDevTools([broadcastService]);
  }
  return broadcastService;
};
export const getCategoryClient = () => {
  if (!categoryService) {
    categoryService = new CategoryServiceClient(GRPC_WEB_URL);
    enableDevTools([categoryService]);
  }
  return categoryService;
};

export const getDataClient = () => {
  if (!dataService) {
    dataService = new DataServiceClient(GRPC_WEB_URL);
    enableDevTools([dataService]);
  }
  return dataService;
};

export const getFlightRecordingClient = () => {
  if (!flightRecodingService) {
    flightRecodingService = new FlightRecordingServiceClient(GRPC_WEB_URL);
    enableDevTools([flightRecodingService]);
  }
  return flightRecodingService;
};

export const getKeychainFactoryClient = () => {
  if (!keychainFactoryService) {
    keychainFactoryService = new KeychainFactoryServiceClient(GRPC_WEB_URL);
    enableDevTools([keychainFactoryService]);
  }
  return keychainFactoryService;
};

export const getKeychainClient = () => {
  if (!keychainService) {
    keychainService = new KeychainServiceClient(GRPC_WEB_URL);
    enableDevTools([keychainService]);
  }
  return keychainService;
};

export const getOidcKeychainClient = () => {
  if (!oidcKeychainService) {
    oidcKeychainService = new OidcKeychainServiceClient(GRPC_WEB_URL);
    enableDevTools([oidcKeychainService]);
  }
  return oidcKeychainService;
};

export const getOperationLogClient = () => {
  if (!operationLogService) {
    operationLogService = new OperationLogServiceClient(GRPC_WEB_URL);
    enableDevTools([operationLogService]);
  }
  return operationLogService;
};

export const getOperationsClient = () => {
  if (!operationService) {
    operationService = new OperationServiceClient(GRPC_WEB_URL);
    enableDevTools([operationService]);
  }
  return operationService;
};
export const getPvdClient = () => {
  if (!proximityVerificationDeviceService) {
    proximityVerificationDeviceService =
      new ProximityVerificationDeviceServiceClient(GRPC_WEB_URL);
    enableDevTools([proximityVerificationDeviceService]);
  }
  return proximityVerificationDeviceService;
};
export const getStatisticsServiceClient = () => {
  if (!statisticsService) {
    statisticsService = new StatisticsServiceClient(GRPC_WEB_URL);
    enableDevTools([statisticsService]);
  }
  return statisticsService;
};

export const getSubjectsClient = () => {
  if (!subjectService) {
    subjectService = new SubjectServiceClient(GRPC_WEB_URL);
    enableDevTools([subjectService]);
  }
  return subjectService;
};

export const getTermClient = () => {
  if (!termService) {
    termService = new TermServiceClient(GRPC_WEB_URL);
    enableDevTools([termService]);
  }
  return termService;
};
export const getTpsClient = () => {
  if (!tpsService) {
    tpsService = new TpsServiceClient(GRPC_WEB_URL);
    enableDevTools([tpsService]);
  }
  return tpsService;
};
export const getUtilsClient = () => {
  if (!utilsService) {
    utilsService = new UtilsServiceClient(GRPC_WEB_URL);
    enableDevTools([utilsService]);
  }
  return utilsService;
};

export const getGrpcMetadata = async () => {
  await doTokenRefresh();
  return { Authorization: `Bearer ${keycloak.token}` };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isGrpcError = (error: any): error is RpcError => {
  return (
    error?.metadata?.['oslonokkelen.proto.shared.errorresponse-bin'] !==
      undefined && error?.code !== undefined
  );
};

interface ThunkApiProps<RejectValue> {
  dispatch: ThunkDispatch<RootState, unknown, AnyAction>;
  rejectWithValue: (e: string) => RejectValue;
}

export const handleGrpcError = <
  RejectValue,
  ThunkAPI extends ThunkApiProps<RejectValue>
>(
  error: unknown,
  thunkAPI: ThunkAPI
) => {
  showErrorPopup(thunkAPI.dispatch, error);
  let returnError = 'Unknown error';
  if (isGrpcError(error)) {
    returnError = extractGrpcErrorMessage(error);
  }
  return thunkAPI.rejectWithValue(returnError);
};

const doTokenRefresh = async () => {
  return new Promise(resolve => {
    keycloak
      .updateToken(30)
      .then(() => {
        resolve('Token Refresh Success');
      })
      .catch(() => {
        keycloak.login();
      });
  });
};
