import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { KeySelect } from '../../../../components/Forms/KeySelect';
import { OperationId } from '../../../../proto/shared_pb';
import { BucketOperation } from '../../../../redux/slice/buckets';

type Props = {
  operationsList: BucketOperation[];
  initialSelected?: BucketOperation[];
  rerenderNudge?: boolean;
};

type GroupedOption = {
  readonly options: readonly Option[];
  readonly label?: string;
};

type Option = {
  labelText: string;
  value: BucketOperation;
};

export const SelectOperations: React.FC<Props> = ({
  operationsList,
  initialSelected = [],
  rerenderNudge
}) => {
  const options: GroupedOption[] = useMemo(
    () => generateOptions(operationsList),
    [operationsList]
  );

  const { setValue } = useFormContext();

  useEffect(() => {
    if (rerenderNudge) {
      const selectedOperations = options.flatMap(group =>
        group.options.filter(option =>
          initialSelected.some(selectedOperation =>
            isEqual(selectedOperation, option.value)
          )
        )
      );
      setValue('selectedOperations', selectedOperations);
    }
  }, [options, initialSelected, rerenderNudge]);

  return (
    <KeySelect
      name='selectedOperations'
      label='Operasjoner'
      description='Legg alle ønskede operasjoner i spannet'
      elementProps={{
        isMulti: true,
        placeholder: 'Velg operasjoner...',
        options: options,
        closeMenuOnSelect: false,
        isOptionSelected: (option: Option, selectedValue) => {
          return selectedValue.some(selected =>
            isEqual(selected.value, option.value)
          );
        }
      }}
    />
  );
};

const isEqual = (a: BucketOperation, b: BucketOperation) => {
  switch (a.operationType) {
    case 'subjectOperation':
      return (
        a.operationType === b.operationType &&
        a.subjectId === b.subjectId &&
        a.operationId === b.operationId
      );
    case 'oidc':
      return (
        a.operationType === b.operationType &&
        a.realm === b.realm &&
        a.method === b.method
      );
    case 'admissionCard':
      return a.operationType === b.operationType && a.type === b.type;
  }
};

export const generateOptions = (
  operationsList: BucketOperation[]
): GroupedOption[] => {
  const groupedOptions: { [key: string]: Option[] } = {};

  operationsList.forEach(operation => {
    let shortLabel, type, method;
    switch (operation.operationType) {
      case 'subjectOperation':
        shortLabel = `${operation.subjectId} – ${operation.operationId}`;
        type = 'Stedoperasjon';
        break;
      case 'oidc':
        method =
          operation.method === OperationId.Oidc.Method.APPROVE
            ? 'Godkjenn'
            : 'Avslå';
        shortLabel = `${operation.realm} – ${method}`;
        type = 'Oidc';
        break;
      case 'admissionCard':
        shortLabel = operation.type;
        type = 'Adgangskort';
        break;
    }

    if (!groupedOptions[type]) {
      groupedOptions[type] = [];
    }

    groupedOptions[type].push({
      labelText: shortLabel,
      value: operation
    });
  });

  return Object.keys(groupedOptions).map(type => ({
    label: type,
    options: groupedOptions[type]
  }));
};
