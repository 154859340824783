import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogFormBox } from '../../../../components/DialogBox/DialogFormBox';
import { KeyText } from '../../../../components/Forms/KeyText';
import {
  EditOidcRole,
  editOidcRole
} from '../../../../redux/actions/keychainFactoryDetails';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { RoleDefinition } from '../../../../redux/slice/keychainFactoryDetails';

interface Props {
  role?: RoleDefinition;
  isModalOpen: boolean;
  closeModal: () => void;
}

export const EditRoleDialog: React.FC<Props> = ({
  role,
  isModalOpen,
  closeModal
}) => {
  const dispatch = useAppDispatch();

  const methods = useForm<EditOidcRole>({
    resolver: zodResolver(EditOidcRole),
    defaultValues: { name: '', description: '', uri: '' },
    values: role
  });

  return (
    <DialogFormBox
      methods={methods}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      header='Rediger rolle'
      onSubmit={data =>
        dispatchAndHandleResult(dispatch, editOidcRole, data, closeModal)
      }
    >
      <KeyText
        name='name'
        label='Rollenavn'
        description='Navnet på rollen'
        elementProps={{}}
      />
      <KeyText
        name='description'
        label='Beskrivelse'
        description='Gi en litt mer utfyllende beskrivelse på hva rollen gir tilgang til.'
        elementProps={{}}
      />
    </DialogFormBox>
  );
};
