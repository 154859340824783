export const createUrlSafeId = (id = '', maxCharacters = 30) => {
  const spaceRegex = / /g;
  const charactersRegex = /[^a-z_0-9-]+/g;
  const multipleHyphensRegex = /-+/g;
  const replaceMap: { [character: string]: string } = {
    æ: 'ae',
    ø: 'o',
    å: 'a'
  };

  id = id.toLowerCase();
  for (const char in replaceMap) {
    id = id.replace(new RegExp(char, 'g'), replaceMap[char]);
  }

  return `${id}`
    .replace(spaceRegex, '-')
    .replace(charactersRegex, '')
    .replace(multipleHyphensRegex, '-')
    .slice(0, maxCharacters);
};
