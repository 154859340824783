import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SubjectBreadcrumbs } from '../../pages/places/SubjectBreadcrumbs';
import { useSubject } from '../../pages/places/SubjectWrapper';
import { updateSubjectAbout } from '../../redux/actions/subjects';
import { dispatchAndHandleResult } from '../../redux/actions/util';
import { useAppDispatch } from '../../redux/hooks';
import { historyGoBack } from '../../utils/history';
import { TextEditor } from '../Editor/TextEditor';

export const EditAbout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { subject } = useSubject();
  const { about } = subject;
  const handleSaveClick = async (updatedText: string) =>
    dispatchAndHandleResult(
      dispatch,
      updateSubjectAbout,
      { subjectId: subject.id, content: updatedText },
      () => historyGoBack(navigate)
    );

  return (
    <div>
      <SubjectBreadcrumbs subject={subject} name='Offentlig beskrivelse' />
      <h3>
        Offentlig beskrivelse (dette blir synlig i en fremtidig versjon av
        appen)
      </h3>
      <TextEditor
        type='advanced'
        message={about}
        saveCallback={handleSaveClick}
        handleCancelClick={() => historyGoBack(navigate)}
      />
    </div>
  );
};
