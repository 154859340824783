import React from 'react';
import classNames from 'classnames';
import './FormElement.scss';

interface CheckboxProps {
  elementProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  label: string;
  description?: string;
}

export const CheckboxElement: React.FC<CheckboxProps> = ({
  elementProps,
  label,
  description
}) => {
  const className = classNames('form-element', elementProps.className);

  return (
    <div className='form-element'>
      <label className='form-element--label form-element--label-checkbox'>
        <input {...elementProps} type='checkbox' className={className} />
        {label}
      </label>
      <span className='form-element--description'>{description}</span>
    </div>
  );
};
