import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import Highcharts from 'highcharts';
import { createHighchartOptions } from '../../utils/highchartsOptions';
import { GraphData } from '../../utils/statsTypes';

interface HighchartsGraphProps {
  graphData: GraphData[];
}

export const HighchartsGraph = ({ graphData }: HighchartsGraphProps) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={createHighchartOptions(graphData)}
  />
);
