import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { CreateSection } from '../../../components/CreateSection/CreateSection';
import { KeyId } from '../../../components/Forms/KeyId';
import { KeyText } from '../../../components/Forms/KeyText';
import {
  createOperation,
  CreateOperationSchema
} from '../../../redux/actions/operations';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { subjectSelectors } from '../../../redux/slice/subjects';
import { ControlpanelSubjectBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { ActionChainSelector } from './ActionChainSelector';

interface MatchParams {
  subjectId: string;
}

export const CreateOperation = () => {
  const navigate = useNavigate();
  const { subjectId = '' } = useParams<keyof MatchParams>();
  const subject = useAppSelector(state =>
    subjectSelectors.selectById(state, subjectId)
  );

  const dispatch = useAppDispatch();

  const methods = useForm<CreateOperationSchema>({
    resolver: zodResolver(CreateOperationSchema),
    defaultValues: {
      subjectId: subjectId
    }
  });
  const { handleSubmit } = methods;

  return (
    <CreateSection aria-label='Ny operasjon'>
      {subject && (
        <React.Fragment>
          <ControlpanelSubjectBreadcrumbs
            currentPageName='Lag ny operasjon'
            parentName={subject.name}
          />
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(data =>
                dispatchAndHandleResult(dispatch, createOperation, data, () => {
                  navigate('..', { relative: 'path' });
                })
              )}
            >
              <KeyText
                label='Navn'
                name='name'
                description='Skriv navnet på låsen. Et eksempel på dette er HOVEDDØR.'
                elementProps={{
                  placeholder: 'Skriv inn navn på lås',
                  name: 'name'
                }}
              />
              <KeyId
                label='ID'
                name='operationId'
                otherField='name'
                description='ID til operasjonen'
                elementProps={{}}
              />
              <ActionChainSelector name='actionChain' />

              <Button label='Lag lås' type='submit' />
            </form>
          </FormProvider>
        </React.Fragment>
      )}
    </CreateSection>
  );
};
