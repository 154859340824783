import React from 'react';
import { Table } from '../../../../components/Table/Table';
import { Result } from '../../../../proto/shared_pb';
import { RecordsList } from '../../../../redux/slice/adapters';
import { getGrafanaTraceUrl } from '../../../../utils/grafana';
import { formatDateFromEpochMillis } from '../../../../utils/timeConverter';
import { formatTimeInMs } from './ActionDetails';

interface Props {
  recordsList: RecordsList[];
}

export const ActionLog: React.FC<Props> = ({ recordsList }) => {
  const data = recordsList.map(record => {
    return {
      cells: [
        formatDateFromEpochMillis(record.timestamp),
        getResultText(record.result),
        formatTimeInMs(record.responseTimeMs),
        record.code,
        <a
          key={record.traceId}
          href={getGrafanaTraceUrl(record.traceId)}
          target='grafana'
        >
          {record.traceId}
        </a>
      ]
    };
  });

  return (
    <React.Fragment>
      <h2>Logg over siste hendelser</h2>
      <Table
        headings={['Tidspunkt', 'Resultat', 'Responstid', 'Kode', 'Trace ID']}
        rows={data}
      />
    </React.Fragment>
  );
};

const getResultText = (result: Result) => {
  if (result === Result.SUCCESS) {
    return 'Suksess';
  } else if (result === Result.ERROR) {
    return 'Feil';
  } else if (result === Result.DENIED) {
    return 'Nektet';
  }
};
