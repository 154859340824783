import React from 'react';
import { OperationLinks } from '../../../components/operation/operationCards/OperationLinks/OperationLinks';
import { useAppSelector } from '../../../redux/hooks';
import { subjectSelectors } from '../../../redux/slice/subjects';
import { SubjectCard } from '../../places/SubjectCardsContainer/SubjectCard/SubjectCard';

interface Props {
  subjectIds: string[];
}

export const Subjects: React.FC<Props> = ({ subjectIds }) => {
  const subjects = subjectIds.map(id => {
    return useAppSelector(state => subjectSelectors.selectById(state, id));
  });
  subjects.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return (
    <React.Fragment>
      <h4>Dette adgangskortet gir adgang til:</h4>
      <OperationLinks>
        {subjects.map((subject, index) => {
          return (
            <SubjectCard
              key={subject.id}
              img={subject.imagesList[0]?.uri}
              subject={subject}
              hasSeriousProblem={false}
              anomaly={false}
              index={index}
            />
          );
        })}
      </OperationLinks>
    </React.Fragment>
  );
};
