import React, { useEffect, useState } from 'react';
import { getTerms } from '../../../../redux/actions/terms';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { Operation } from '../../../../redux/slice/operations';
import { termsSelectors } from '../../../../redux/slice/terms';
import { SaveButton } from './SaveButton';
import { SelectTermList } from './SelectTermList';

interface Props {
  subjectId: string;
  operation: Operation;
}

export const EditOperationTerms: React.FC<Props> = ({
  subjectId,
  operation
}) => {
  const terms = useAppSelector(termsSelectors.selectAll);
  const dispatch = useAppDispatch();

  const [selectedTermIds, setSelectedTermIds] = useState<string[]>(
    operation.termIds
  );

  useEffect(() => {
    const doGetTerms = async () => {
      dispatch(await getTerms());
    };
    doGetTerms();
  }, [getTerms]);

  return (
    <React.Fragment>
      <h3>Vilkår</h3>
      <p>Vilkår brukere må ha godkjent før de bruker døra.</p>
      <br />
      <ul className='terms'>
        {terms &&
          terms.map(term => (
            <SelectTermList
              key={term.id}
              term={term}
              selectedTermIds={selectedTermIds}
              setSelectedTermIds={setSelectedTermIds}
            />
          ))}
      </ul>

      <SaveButton
        subjectId={subjectId}
        operationId={operation.id}
        selectedTermIds={selectedTermIds}
      />
    </React.Fragment>
  );
};
