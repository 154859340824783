/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.keychain.factory
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: keychain-factory.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as keychain$factory_pb from './keychain-factory_pb'; // proto import: "keychain-factory.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class KeychainFactoryServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListKeychainFactoryConfigurations = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/ListKeychainFactoryConfigurations',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.ListKeychainFactoryConfigurationsRequest,
    keychain$factory_pb.KeychainFactoriesConfigurationsResponse,
    (request: keychain$factory_pb.ListKeychainFactoryConfigurationsRequest) => {
      return request.serializeBinary();
    },
    keychain$factory_pb.KeychainFactoriesConfigurationsResponse.deserializeBinary
  );

  listKeychainFactoryConfigurations(
    request: keychain$factory_pb.ListKeychainFactoryConfigurationsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<keychain$factory_pb.KeychainFactoriesConfigurationsResponse>;

  listKeychainFactoryConfigurations(
    request: keychain$factory_pb.ListKeychainFactoryConfigurationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.KeychainFactoriesConfigurationsResponse) => void): grpcWeb.ClientReadableStream<keychain$factory_pb.KeychainFactoriesConfigurationsResponse>;

  listKeychainFactoryConfigurations(
    request: keychain$factory_pb.ListKeychainFactoryConfigurationsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.KeychainFactoriesConfigurationsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/ListKeychainFactoryConfigurations',
        request,
        metadata || {},
        this.methodDescriptorListKeychainFactoryConfigurations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/ListKeychainFactoryConfigurations',
    request,
    metadata || {},
    this.methodDescriptorListKeychainFactoryConfigurations);
  }

  methodDescriptorFetchKeychainFactoryDetails = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/FetchKeychainFactoryDetails',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.FetchKeychainFactoryDetailsRequest,
    keychain$factory_pb.KeychainFactoryDetailsResponse,
    (request: keychain$factory_pb.FetchKeychainFactoryDetailsRequest) => {
      return request.serializeBinary();
    },
    keychain$factory_pb.KeychainFactoryDetailsResponse.deserializeBinary
  );

  fetchKeychainFactoryDetails(
    request: keychain$factory_pb.FetchKeychainFactoryDetailsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<keychain$factory_pb.KeychainFactoryDetailsResponse>;

  fetchKeychainFactoryDetails(
    request: keychain$factory_pb.FetchKeychainFactoryDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.KeychainFactoryDetailsResponse) => void): grpcWeb.ClientReadableStream<keychain$factory_pb.KeychainFactoryDetailsResponse>;

  fetchKeychainFactoryDetails(
    request: keychain$factory_pb.FetchKeychainFactoryDetailsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.KeychainFactoryDetailsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/FetchKeychainFactoryDetails',
        request,
        metadata || {},
        this.methodDescriptorFetchKeychainFactoryDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/FetchKeychainFactoryDetails',
    request,
    metadata || {},
    this.methodDescriptorFetchKeychainFactoryDetails);
  }

  methodDescriptorChangeKeychainFactoryOwner = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/ChangeKeychainFactoryOwner',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.ChangeKeychainFactoryOwnerRequest,
    shared_pb.EmptyResponse,
    (request: keychain$factory_pb.ChangeKeychainFactoryOwnerRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  changeKeychainFactoryOwner(
    request: keychain$factory_pb.ChangeKeychainFactoryOwnerRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  changeKeychainFactoryOwner(
    request: keychain$factory_pb.ChangeKeychainFactoryOwnerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  changeKeychainFactoryOwner(
    request: keychain$factory_pb.ChangeKeychainFactoryOwnerRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/ChangeKeychainFactoryOwner',
        request,
        metadata || {},
        this.methodDescriptorChangeKeychainFactoryOwner,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/ChangeKeychainFactoryOwner',
    request,
    metadata || {},
    this.methodDescriptorChangeKeychainFactoryOwner);
  }

  methodDescriptorAddOperationLock = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/AddOperationLock',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.AddOperationLockRequest,
    shared_pb.EmptyResponse,
    (request: keychain$factory_pb.AddOperationLockRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  addOperationLock(
    request: keychain$factory_pb.AddOperationLockRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  addOperationLock(
    request: keychain$factory_pb.AddOperationLockRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  addOperationLock(
    request: keychain$factory_pb.AddOperationLockRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/AddOperationLock',
        request,
        metadata || {},
        this.methodDescriptorAddOperationLock,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/AddOperationLock',
    request,
    metadata || {},
    this.methodDescriptorAddOperationLock);
  }

  methodDescriptorRemoveOperationLock = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/RemoveOperationLock',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.RemoveOperationLockRequest,
    shared_pb.EmptyResponse,
    (request: keychain$factory_pb.RemoveOperationLockRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  removeOperationLock(
    request: keychain$factory_pb.RemoveOperationLockRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  removeOperationLock(
    request: keychain$factory_pb.RemoveOperationLockRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  removeOperationLock(
    request: keychain$factory_pb.RemoveOperationLockRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/RemoveOperationLock',
        request,
        metadata || {},
        this.methodDescriptorRemoveOperationLock,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/RemoveOperationLock',
    request,
    metadata || {},
    this.methodDescriptorRemoveOperationLock);
  }

  methodDescriptorDeleteKeychainFactory = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/DeleteKeychainFactory',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.DeleteKeychainFactoryRequest,
    shared_pb.EmptyResponse,
    (request: keychain$factory_pb.DeleteKeychainFactoryRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteKeychainFactory(
    request: keychain$factory_pb.DeleteKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteKeychainFactory(
    request: keychain$factory_pb.DeleteKeychainFactoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteKeychainFactory(
    request: keychain$factory_pb.DeleteKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/DeleteKeychainFactory',
        request,
        metadata || {},
        this.methodDescriptorDeleteKeychainFactory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/DeleteKeychainFactory',
    request,
    metadata || {},
    this.methodDescriptorDeleteKeychainFactory);
  }

  methodDescriptorUpdateKeychainFactoryName = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/UpdateKeychainFactoryName',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.UpdateKeychainFactoryNameRequest,
    shared_pb.EmptyResponse,
    (request: keychain$factory_pb.UpdateKeychainFactoryNameRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateKeychainFactoryName(
    request: keychain$factory_pb.UpdateKeychainFactoryNameRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateKeychainFactoryName(
    request: keychain$factory_pb.UpdateKeychainFactoryNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateKeychainFactoryName(
    request: keychain$factory_pb.UpdateKeychainFactoryNameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/UpdateKeychainFactoryName',
        request,
        metadata || {},
        this.methodDescriptorUpdateKeychainFactoryName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/UpdateKeychainFactoryName',
    request,
    metadata || {},
    this.methodDescriptorUpdateKeychainFactoryName);
  }

  methodDescriptorUpdateKeychainFactoryOpeningHours = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/UpdateKeychainFactoryOpeningHours',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.UpdateKeychainFactoryOpeningHoursRequest,
    shared_pb.EmptyResponse,
    (request: keychain$factory_pb.UpdateKeychainFactoryOpeningHoursRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateKeychainFactoryOpeningHours(
    request: keychain$factory_pb.UpdateKeychainFactoryOpeningHoursRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateKeychainFactoryOpeningHours(
    request: keychain$factory_pb.UpdateKeychainFactoryOpeningHoursRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateKeychainFactoryOpeningHours(
    request: keychain$factory_pb.UpdateKeychainFactoryOpeningHoursRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/UpdateKeychainFactoryOpeningHours',
        request,
        metadata || {},
        this.methodDescriptorUpdateKeychainFactoryOpeningHours,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/UpdateKeychainFactoryOpeningHours',
    request,
    metadata || {},
    this.methodDescriptorUpdateKeychainFactoryOpeningHours);
  }

  methodDescriptorCloneKeychainFactory = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CloneKeychainFactory',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.CloneKeychainFactoryRequest,
    keychain$factory_pb.CloneKeychainFactoryResponse,
    (request: keychain$factory_pb.CloneKeychainFactoryRequest) => {
      return request.serializeBinary();
    },
    keychain$factory_pb.CloneKeychainFactoryResponse.deserializeBinary
  );

  cloneKeychainFactory(
    request: keychain$factory_pb.CloneKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<keychain$factory_pb.CloneKeychainFactoryResponse>;

  cloneKeychainFactory(
    request: keychain$factory_pb.CloneKeychainFactoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.CloneKeychainFactoryResponse) => void): grpcWeb.ClientReadableStream<keychain$factory_pb.CloneKeychainFactoryResponse>;

  cloneKeychainFactory(
    request: keychain$factory_pb.CloneKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.CloneKeychainFactoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CloneKeychainFactory',
        request,
        metadata || {},
        this.methodDescriptorCloneKeychainFactory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CloneKeychainFactory',
    request,
    metadata || {},
    this.methodDescriptorCloneKeychainFactory);
  }

  methodDescriptorCreateGroupLink = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CreateGroupLink',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.CreateGroupLinkRequest,
    keychain$factory_pb.CreateGroupLinkResponse,
    (request: keychain$factory_pb.CreateGroupLinkRequest) => {
      return request.serializeBinary();
    },
    keychain$factory_pb.CreateGroupLinkResponse.deserializeBinary
  );

  createGroupLink(
    request: keychain$factory_pb.CreateGroupLinkRequest,
    metadata?: grpcWeb.Metadata | null): Promise<keychain$factory_pb.CreateGroupLinkResponse>;

  createGroupLink(
    request: keychain$factory_pb.CreateGroupLinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.CreateGroupLinkResponse) => void): grpcWeb.ClientReadableStream<keychain$factory_pb.CreateGroupLinkResponse>;

  createGroupLink(
    request: keychain$factory_pb.CreateGroupLinkRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.CreateGroupLinkResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CreateGroupLink',
        request,
        metadata || {},
        this.methodDescriptorCreateGroupLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CreateGroupLink',
    request,
    metadata || {},
    this.methodDescriptorCreateGroupLink);
  }

  methodDescriptorListGroupLinks = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/ListGroupLinks',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.ListGroupLinksRequest,
    keychain$factory_pb.GroupLinkListResponse,
    (request: keychain$factory_pb.ListGroupLinksRequest) => {
      return request.serializeBinary();
    },
    keychain$factory_pb.GroupLinkListResponse.deserializeBinary
  );

  listGroupLinks(
    request: keychain$factory_pb.ListGroupLinksRequest,
    metadata?: grpcWeb.Metadata | null): Promise<keychain$factory_pb.GroupLinkListResponse>;

  listGroupLinks(
    request: keychain$factory_pb.ListGroupLinksRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.GroupLinkListResponse) => void): grpcWeb.ClientReadableStream<keychain$factory_pb.GroupLinkListResponse>;

  listGroupLinks(
    request: keychain$factory_pb.ListGroupLinksRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.GroupLinkListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/ListGroupLinks',
        request,
        metadata || {},
        this.methodDescriptorListGroupLinks,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/ListGroupLinks',
    request,
    metadata || {},
    this.methodDescriptorListGroupLinks);
  }

  methodDescriptorUpdateKeychainFactoryImage = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/UpdateKeychainFactoryImage',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.UpdateKeychainFactoryImageRequest,
    shared_pb.EmptyResponse,
    (request: keychain$factory_pb.UpdateKeychainFactoryImageRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateKeychainFactoryImage(
    request: keychain$factory_pb.UpdateKeychainFactoryImageRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateKeychainFactoryImage(
    request: keychain$factory_pb.UpdateKeychainFactoryImageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateKeychainFactoryImage(
    request: keychain$factory_pb.UpdateKeychainFactoryImageRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/UpdateKeychainFactoryImage',
        request,
        metadata || {},
        this.methodDescriptorUpdateKeychainFactoryImage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/UpdateKeychainFactoryImage',
    request,
    metadata || {},
    this.methodDescriptorUpdateKeychainFactoryImage);
  }

  methodDescriptorCreateManualGrantKeychainFactory = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CreateManualGrantKeychainFactory',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.CreateManualGrantKeychainFactoryRequest,
    keychain$factory_pb.CreateManualGrantKeychainFactoryResponse,
    (request: keychain$factory_pb.CreateManualGrantKeychainFactoryRequest) => {
      return request.serializeBinary();
    },
    keychain$factory_pb.CreateManualGrantKeychainFactoryResponse.deserializeBinary
  );

  createManualGrantKeychainFactory(
    request: keychain$factory_pb.CreateManualGrantKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<keychain$factory_pb.CreateManualGrantKeychainFactoryResponse>;

  createManualGrantKeychainFactory(
    request: keychain$factory_pb.CreateManualGrantKeychainFactoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.CreateManualGrantKeychainFactoryResponse) => void): grpcWeb.ClientReadableStream<keychain$factory_pb.CreateManualGrantKeychainFactoryResponse>;

  createManualGrantKeychainFactory(
    request: keychain$factory_pb.CreateManualGrantKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: keychain$factory_pb.CreateManualGrantKeychainFactoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CreateManualGrantKeychainFactory',
        request,
        metadata || {},
        this.methodDescriptorCreateManualGrantKeychainFactory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CreateManualGrantKeychainFactory',
    request,
    metadata || {},
    this.methodDescriptorCreateManualGrantKeychainFactory);
  }

  methodDescriptorInviteUserToManualGrantKeychainFactory = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/InviteUserToManualGrantKeychainFactory',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.InviteUserToManualGrantKeychainFactoryRequest,
    shared_pb.EmptyResponse,
    (request: keychain$factory_pb.InviteUserToManualGrantKeychainFactoryRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  inviteUserToManualGrantKeychainFactory(
    request: keychain$factory_pb.InviteUserToManualGrantKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  inviteUserToManualGrantKeychainFactory(
    request: keychain$factory_pb.InviteUserToManualGrantKeychainFactoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  inviteUserToManualGrantKeychainFactory(
    request: keychain$factory_pb.InviteUserToManualGrantKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/InviteUserToManualGrantKeychainFactory',
        request,
        metadata || {},
        this.methodDescriptorInviteUserToManualGrantKeychainFactory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/InviteUserToManualGrantKeychainFactory',
    request,
    metadata || {},
    this.methodDescriptorInviteUserToManualGrantKeychainFactory);
  }

  methodDescriptorCreatePublicKeychainFactory = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CreatePublicKeychainFactory',
    grpcWeb.MethodType.UNARY,
    keychain$factory_pb.CreatePublicKeychainFactoryRequest,
    shared_pb.EmptyResponse,
    (request: keychain$factory_pb.CreatePublicKeychainFactoryRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createPublicKeychainFactory(
    request: keychain$factory_pb.CreatePublicKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createPublicKeychainFactory(
    request: keychain$factory_pb.CreatePublicKeychainFactoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createPublicKeychainFactory(
    request: keychain$factory_pb.CreatePublicKeychainFactoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CreatePublicKeychainFactory',
        request,
        metadata || {},
        this.methodDescriptorCreatePublicKeychainFactory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryService/CreatePublicKeychainFactory',
    request,
    metadata || {},
    this.methodDescriptorCreatePublicKeychainFactory);
  }

}

