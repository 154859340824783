/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.pvd
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: pvd.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as pvd_pb from './pvd_pb'; // proto import: "pvd.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class ProximityVerificationDeviceServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateProximityVerificationDevice = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/CreateProximityVerificationDevice',
    grpcWeb.MethodType.UNARY,
    pvd_pb.CreateProximityVerificationDeviceRequest,
    shared_pb.EmptyResponse,
    (request: pvd_pb.CreateProximityVerificationDeviceRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createProximityVerificationDevice(
    request: pvd_pb.CreateProximityVerificationDeviceRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createProximityVerificationDevice(
    request: pvd_pb.CreateProximityVerificationDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createProximityVerificationDevice(
    request: pvd_pb.CreateProximityVerificationDeviceRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/CreateProximityVerificationDevice',
        request,
        metadata || {},
        this.methodDescriptorCreateProximityVerificationDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/CreateProximityVerificationDevice',
    request,
    metadata || {},
    this.methodDescriptorCreateProximityVerificationDevice);
  }

  methodDescriptorUpdateProximityVerificationDevice = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/UpdateProximityVerificationDevice',
    grpcWeb.MethodType.UNARY,
    pvd_pb.UpdateProximityVerificationDeviceRequest,
    shared_pb.EmptyResponse,
    (request: pvd_pb.UpdateProximityVerificationDeviceRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateProximityVerificationDevice(
    request: pvd_pb.UpdateProximityVerificationDeviceRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateProximityVerificationDevice(
    request: pvd_pb.UpdateProximityVerificationDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateProximityVerificationDevice(
    request: pvd_pb.UpdateProximityVerificationDeviceRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/UpdateProximityVerificationDevice',
        request,
        metadata || {},
        this.methodDescriptorUpdateProximityVerificationDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/UpdateProximityVerificationDevice',
    request,
    metadata || {},
    this.methodDescriptorUpdateProximityVerificationDevice);
  }

  methodDescriptorDeleteProximityVerificationDevice = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/DeleteProximityVerificationDevice',
    grpcWeb.MethodType.UNARY,
    pvd_pb.DeleteProximityVerificationDeviceRequest,
    shared_pb.EmptyResponse,
    (request: pvd_pb.DeleteProximityVerificationDeviceRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteProximityVerificationDevice(
    request: pvd_pb.DeleteProximityVerificationDeviceRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteProximityVerificationDevice(
    request: pvd_pb.DeleteProximityVerificationDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteProximityVerificationDevice(
    request: pvd_pb.DeleteProximityVerificationDeviceRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/DeleteProximityVerificationDevice',
        request,
        metadata || {},
        this.methodDescriptorDeleteProximityVerificationDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/DeleteProximityVerificationDevice',
    request,
    metadata || {},
    this.methodDescriptorDeleteProximityVerificationDevice);
  }

  methodDescriptorListProximityVerificationDevices = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/ListProximityVerificationDevices',
    grpcWeb.MethodType.UNARY,
    pvd_pb.ListProximityVerificationDevicesRequest,
    pvd_pb.ListProximityVerificationDevicesResponse,
    (request: pvd_pb.ListProximityVerificationDevicesRequest) => {
      return request.serializeBinary();
    },
    pvd_pb.ListProximityVerificationDevicesResponse.deserializeBinary
  );

  listProximityVerificationDevices(
    request: pvd_pb.ListProximityVerificationDevicesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<pvd_pb.ListProximityVerificationDevicesResponse>;

  listProximityVerificationDevices(
    request: pvd_pb.ListProximityVerificationDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: pvd_pb.ListProximityVerificationDevicesResponse) => void): grpcWeb.ClientReadableStream<pvd_pb.ListProximityVerificationDevicesResponse>;

  listProximityVerificationDevices(
    request: pvd_pb.ListProximityVerificationDevicesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: pvd_pb.ListProximityVerificationDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/ListProximityVerificationDevices',
        request,
        metadata || {},
        this.methodDescriptorListProximityVerificationDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/ListProximityVerificationDevices',
    request,
    metadata || {},
    this.methodDescriptorListProximityVerificationDevices);
  }

  methodDescriptorListProximityVerificationDeviceUsageLogs = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/ListProximityVerificationDeviceUsageLogs',
    grpcWeb.MethodType.UNARY,
    pvd_pb.ListProximityVerificationDeviceUsageLogsRequest,
    pvd_pb.ListProximityVerificationDeviceUsageLogsResponse,
    (request: pvd_pb.ListProximityVerificationDeviceUsageLogsRequest) => {
      return request.serializeBinary();
    },
    pvd_pb.ListProximityVerificationDeviceUsageLogsResponse.deserializeBinary
  );

  listProximityVerificationDeviceUsageLogs(
    request: pvd_pb.ListProximityVerificationDeviceUsageLogsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<pvd_pb.ListProximityVerificationDeviceUsageLogsResponse>;

  listProximityVerificationDeviceUsageLogs(
    request: pvd_pb.ListProximityVerificationDeviceUsageLogsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: pvd_pb.ListProximityVerificationDeviceUsageLogsResponse) => void): grpcWeb.ClientReadableStream<pvd_pb.ListProximityVerificationDeviceUsageLogsResponse>;

  listProximityVerificationDeviceUsageLogs(
    request: pvd_pb.ListProximityVerificationDeviceUsageLogsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: pvd_pb.ListProximityVerificationDeviceUsageLogsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/ListProximityVerificationDeviceUsageLogs',
        request,
        metadata || {},
        this.methodDescriptorListProximityVerificationDeviceUsageLogs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDeviceService/ListProximityVerificationDeviceUsageLogs',
    request,
    metadata || {},
    this.methodDescriptorListProximityVerificationDeviceUsageLogs);
  }

}

