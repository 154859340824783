import React from 'react';
import { Link } from 'react-router-dom';
import './SubjectActionCard.scss';

type SubjectActionCardProps = {
  icon: React.ReactElement;
  header: string;
  link: string;
  classes?: string;
};

export const SubjectActionCard: React.FC<SubjectActionCardProps> = ({
  icon,
  header,
  link,
  classes = ''
}) => {
  classes += ' subject-action-card';
  return (
    <Link to={link}>
      <div className={classes}>
        <div className='img-container'>{icon}</div>
        <p className='title'>{header}</p>
      </div>
    </Link>
  );
};
