import React from 'react';

export const Action = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='136'
    height='136'
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12164'
      data-name='Group 12164'
      transform='translate(-792 -232)'
    >
      <g id='Group_12163' data-name='Group 12163'>
        <circle
          id='Ellipse_693'
          data-name='Ellipse 693'
          cx='68'
          cy='68'
          r='68'
          transform='translate(792 232)'
          fill='#f9b3ab'
        />
        <g id='noun-action-1551086' transform='translate(832 264)'>
          <path
            id='Path_4503'
            data-name='Path 4503'
            d='M414.963,362.474a1.625,1.625,0,0,0,1.653-.062L430.57,353.4a1.617,1.617,0,0,0,0-2.715l-13.954-9.017a1.618,1.618,0,0,0-2.5,1.358v18.029a1.618,1.618,0,0,0,.844,1.419ZM417.353,346l9.358,6.048-9.358,6.044Z'
            transform='translate(-371.372 -298.892)'
            fill='#292858'
          />
          <path
            id='Path_4504'
            data-name='Path 4504'
            d='M125.717,11.55a1.611,1.611,0,0,0,.282-1.2l-1.346-8.12A1.612,1.612,0,0,0,122.8.891L73.077,8.908a1.618,1.618,0,0,0-1.334,1.856L73.093,19a1.6,1.6,0,0,0,.753,1.091V47.578a1.616,1.616,0,0,0,1.617,1.617H99.686V64.423a6.258,6.258,0,0,0,6.25,6.252h29.237a6.255,6.255,0,0,0,6.249-6.251V43.608a6.258,6.258,0,0,0-6.25-6.249H105.935a6.258,6.258,0,0,0-6.25,6.249v2.354H77.078v-24.3h47.144V33.812a1.617,1.617,0,0,0,3.234,0V20.045a1.616,1.616,0,0,0-1.617-1.617H86.575L124.662,12.2a1.613,1.613,0,0,0,1.054-.652Zm-22.8,32.059a3.019,3.019,0,0,1,3.016-3.015h29.237a3.019,3.019,0,0,1,3.016,3.015V64.424a3.02,3.02,0,0,1-3.016,3.018H105.937a3.02,3.02,0,0,1-3.016-3.018Zm6.542-32.2,4.114-5.723a1.287,1.287,0,0,0,.059-.132l5.675-.927L115.1,10.489Zm-10.148,1.66,4.114-5.725a1.291,1.291,0,0,0,.06-.132l5.675-.927-4.215,5.863Zm-10.15,1.659L93.281,9a1.089,1.089,0,0,0,.057-.129l5.679-.927L94.8,13.807Zm-10.146,1.66,4.117-5.726a1.255,1.255,0,0,0,.057-.131L88.866,9.6,84.65,15.467Zm-.3-5.124-2.947,4.1L75.2,11.839Zm40.886-1.511,2.459-3.42.48,2.938Z'
            transform='translate(-71.722 -0.869)'
            fill='#292858'
          />
          <path
            id='Path_4505'
            data-name='Path 4505'
            d='M165.719,214.714A1.618,1.618,0,0,0,164.1,213.1H141.285a1.617,1.617,0,0,0,0,3.234H164.1a1.618,1.618,0,0,0,1.618-1.617Z'
            transform='translate(-131.185 -186.601)'
            fill='#292858'
          />
          <path
            id='Path_4506'
            data-name='Path 4506'
            d='M141.288,263.2a1.617,1.617,0,0,0,0,3.234h10.145a1.617,1.617,0,1,0,0-3.234Z'
            transform='translate(-131.188 -230.448)'
            fill='#292858'
          />
        </g>
      </g>
    </g>
  </svg>
);
