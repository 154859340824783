import {DialogBoxType} from "../../../../components/DialogBox/useDialogBox";
import { z } from 'zod';
import {useAppDispatch} from "../../../../redux/hooks";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {dispatchAndHandleResult} from "../../../../redux/actions/util";
import {createBetterUptimeMonitor, getAdapterDetails} from "../../../../redux/actions/adapters";
import {KeyText} from "../../../../components/Forms/KeyText";
import React from "react";
import { name } from "../../../../redux/types/zodSchemas"


interface Props {
    DialogBox: DialogBoxType;
    actionUri: string;
    adapterId: string;
    closeModal: () => void;
}

const FormSchema = z.object({
        monitorName: name
    }
);
type FormSchema = z.infer<typeof FormSchema>;

export const CreateMonitor: React.FC<Props> = ({
    DialogBox,
    actionUri,
    adapterId,
    closeModal
}) => {
    const dispatch = useAppDispatch();
    const methods = useForm<FormSchema>({
        resolver: zodResolver(FormSchema)
    });

    const {reset} = methods;

    const onButtonClick = (data: FormSchema) =>
        dispatchAndHandleResult(
        dispatch,
            createBetterUptimeMonitor,
            {
                actionUri,
                monitorName: data.monitorName
            },
            () => {
                dispatch(getAdapterDetails(adapterId));
                closeModal();
                reset()
            }
    );
    return (
        <DialogBox
            buttonLabel='Opprett'
            methods={methods}
            onSubmit={onButtonClick}
            header='Opprett monitor'
        >
            <KeyText
                name='monitorName'
                description='Navn til ny monitor'
                label='Navn'
                elementProps={{}}/>
        </DialogBox>
    )
}