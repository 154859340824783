import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../Button/Button';
import { KeyDateTime } from '../Forms/KeyDateTime';
import { CommonHourComponent } from './CommonHourComponent';

interface Props {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
  dayIsClosed: boolean;
  updateDayIsClosed: (isOpen: boolean) => void;
  fromTime: string;
  untilTime: string;
  updateTime: (value: string, type: 'from' | 'until') => void;
  removeRule: () => void;
}
export const OverridingHourComponent: React.FC<Props> = props => {
  const { selectedDate, onDateChange, removeRule } = props;
  const methods = useForm({ defaultValues: { selectedDate } });
  const watchedDate = methods.watch('selectedDate');
  useEffect(() => {
    if (watchedDate.getTime() !== selectedDate.getTime()) {
      onDateChange(methods.getValues('selectedDate'));
    }
  }, [watchedDate]);
  return (
    <li className='day-line'>
      <div className='day'>
        <FormProvider {...methods}>
          <KeyDateTime
            type='date'
            name='selectedDate'
            inline
            elementProps={{
              dateFormat: 'dd/MM/yyyy'
            }}
          />
        </FormProvider>
      </div>
      <CommonHourComponent {...props} />

      <Button
        type='destructive'
        label='Fjern'
        size='small'
        onClick={removeRule}
      />
    </li>
  );
};
