import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button/Button';
import { store } from '../../../../redux';
import { showErrorPopup } from '../../../../redux/actions/util';
import {
  StatsDatePicker,
  StatsDatePickerProps
} from '../../../stats/StatsDatePicker/StatsDatePicker';
import { statisticsRequest } from '../utils/bulkStatistics';

export const OperationLogs: React.FC = () => {
  const methods = useForm<StatsDatePickerProps>({
    defaultValues: {
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date()
    }
  });

  const downloadZip = async (dateInterval: StatsDatePickerProps) => {
    try {
      const response = await statisticsRequest(dateInterval);
      const url = window.URL.createObjectURL(new Blob(response));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'statistics.zip');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      showErrorPopup(store.dispatch, error);
    }
  };
  return (
    <div>
      <br />
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(downloadZip, e => console.error(e))}
        >
          <StatsDatePicker />
          <Button type='submit' label='Last ned statistikk' />
        </form>
      </FormProvider>
    </div>
  );
};
