import React from 'react';

export const SearchSvg = () => (
  <svg
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.7302 20.5866L16.1765 15.0328C17.4402 13.4833 18.2 11.5063 18.2 9.3501C18.2 4.37985 14.1702 0.350098 9.19995 0.350098C4.2297 0.350098 0.199951 4.37985 0.199951 9.3501C0.199951 14.3203 4.2297 18.3501 9.19995 18.3501C11.4747 18.3501 13.5462 17.4996 15.131 16.1076L20.6697 21.6463L21.7302 20.5866ZM9.19995 16.8501C5.06445 16.8501 1.69995 13.4856 1.69995 9.3501C1.69995 5.2146 5.06445 1.8501 9.19995 1.8501C13.3355 1.8501 16.7 5.2146 16.7 9.3501C16.7 13.4863 13.3355 16.8501 9.19995 16.8501Z'
      fill='#2A2859'
    />
  </svg>
);
