import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { Endringslogg } from '../../../assets/Endringslogg';
import { SubjectActionCard } from '../../../pages/places/SubjectCardsContainer/SubjectCard/SubjectActionCard';
import { useAuditLog } from '../../../utils/auditLogs/useAuditLog';
import { OperationInfoCard } from '../../operation/operationCards/OperationInfoCard';
import { NarrowCard } from '../NarrowCard/NarrowCard';

interface Props {
  type:
    | 'subject'
    | 'admissioncards'
    | 'oidc'
    | 'manual-grant'
    | 'push'
    | 'public'
    | 'operation';
  citykeyId: string;
}

export const AuditCard: React.FC<Props> = ({ type, citykeyId }) => {
  const logs = useAuditLog(citykeyId);
  let sistEndret = 'Ingen endringshistorikk';
  if (logs.length) {
    const mostRecentDate = new Date(
      Math.max(...logs.map(log => log.timestamp))
    );
    sistEndret =
      'Sist endret: ' +
      formatDistanceToNow(mostRecentDate, { addSuffix: true });
  }
  switch (type) {
    case 'subject':
    case 'admissioncards':
      return (
        <SubjectActionCard
          link='auditlog'
          header={sistEndret}
          icon={<Endringslogg />}
        />
      );
    case 'manual-grant':
    case 'oidc':
    case 'public':
    case 'push':
      return (
        <NarrowCard
          link='auditlog'
          header='Endringslogg'
          icon={<Endringslogg />}
        >
          <h4>{sistEndret}</h4>
        </NarrowCard>
      );
    case 'operation':
      return (
        <OperationInfoCard
          link='auditlog'
          header='Endringslogg'
          text={sistEndret}
          icon={<Endringslogg />}
        />
      );
  }
};
