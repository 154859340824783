import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ActionChainPage } from '../pages/controlpanel/Actionchains/ActionChainPage';
import { ActionChains } from '../pages/controlpanel/Actionchains/chain/ActionChains';
import { NewActionChain } from '../pages/controlpanel/Actionchains/chain/NewActionChain';
import { listActionChains, listActions } from '../redux/actions/adapters';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const ActionChainRouter = () => {
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedAdminTool !== 'actionchains') {
      dispatch(listActionChains());
      dispatch(listActions());
      dispatch(setSelectedAdminTool('actionchains'));
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <section>
      <Routes>
        <Route path='create-new' element={<NewActionChain />} />
        <Route path=':chainId' element={<ActionChains />} />
        <Route path='/' element={<ActionChainPage />} />
      </Routes>
    </section>
  );
};
