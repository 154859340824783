import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogFormBox } from '../../../../components/DialogBox/DialogFormBox';
import { KeyId } from '../../../../components/Forms/KeyId';
import { KeyText } from '../../../../components/Forms/KeyText';
import {
  createOidcRole,
  CreateOidcRoleForm
} from '../../../../redux/actions/keychainFactoryDetails';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';

interface Props {
  keychainFactoryUri: string;
  isModalOpen: boolean;
  closeModal: () => void;
}
export const CreateNewRoleDialog: React.FC<Props> = ({
  keychainFactoryUri,
  isModalOpen,
  closeModal
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<CreateOidcRoleForm>({
    resolver: zodResolver(CreateOidcRoleForm),
    defaultValues: {
      keychainFactoryUri: keychainFactoryUri
    }
  });
  const { reset } = methods;

  return (
    <DialogFormBox
      methods={methods}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      header='Opprett ny rolle'
      onSubmit={data =>
        dispatchAndHandleResult(dispatch, createOidcRole, data, () => {
          closeModal();
          reset();
        })
      }
    >
      <KeyText
        name='name'
        label='Rollenavn'
        description='Navnet på rollen'
        elementProps={{}}
      />
      <KeyId
        maxCharacters={40}
        label='ID'
        otherField='name'
        name='roleId'
        description='En unik ID som brukes til å identifisere rollen'
        elementProps={{}}
      />
      <KeyText
        name='description'
        label='Beskrivelse'
        description='Gi en litt mer utfyllende beskrivelse på hva rollen gir tilgang til.'
        elementProps={{}}
      />
    </DialogFormBox>
  );
};
