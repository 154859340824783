import axios from 'axios';
import { createAppAsyncThunk } from '../hooks';

type BuildJson = {
  build: string;
};
export const getBuildVersion = createAppAsyncThunk<string>(
  'version/getBuildVersion',
  async () => {
    const { data } = await axios.get<BuildJson>('/build.json');
    return data?.build;
  }
);
