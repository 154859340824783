import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { KeychainDetailsWrapper } from '../pages/keychainFactory/KeychainDetailsWrapper';
import { KeychainFactoryNotFound } from '../pages/keychainFactory/KeychainFactoryNotFound';
import { KeychainFactoryPage } from '../pages/keychainFactory/KeychainFactoryPage';
import { AccessControl } from '../pages/keychainFactory/accessControl/AccessControl';
import { KeychainDetails } from '../pages/keychainFactory/accessControl/approvedKeychains/KeychainDetails';
import { AuditPage } from '../pages/keychainFactory/audit/AuditPage';
import { GroupLinkPage } from '../pages/keychainFactory/groupLink/GroupLinkPage';
import { LocksPage } from '../pages/keychainFactory/locks/locksPage/LocksPage';
import { OidcLifespan } from '../pages/keychainFactory/oidcKeychainFactory/setup/OidcLifespan';
import { OidcOrigins } from '../pages/keychainFactory/oidcKeychainFactory/setup/OidcOrigins';
import { OidcRedirect } from '../pages/keychainFactory/oidcKeychainFactory/setup/OidcRedirect';
import { OidcRoleDefinition } from '../pages/keychainFactory/oidcKeychainFactory/setup/OidcRoleDefinition';
import { OidcRoleDetails } from '../pages/keychainFactory/oidcKeychainFactory/setup/OidcRoleDetails';
import { OpeningHoursPage } from '../pages/keychainFactory/openingHours/OpeningHoursPage';
import { fetchListGroupLinks } from '../redux/actions/groupLinks';
import { fetchListKeychains } from '../redux/actions/keychains';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getFactoryTypeFromPathType } from '../utils/keychainFactory/getKeychainFactoryAssets';
import { useKeychainFactorySummaryOrDetails } from '../utils/keychainFactory/useKeychainFactory';

interface MatchParams {
  keychainType: string;
  id: string;
}

export const KeychainFactoryRouter: React.FC = () => {
  const { isLoading } = useAppSelector(state => state.keychainFactoryDetails);
  const { id = '', keychainType } = useParams<keyof MatchParams>();
  const type = getFactoryTypeFromPathType(keychainType);
  const fullId = id.replace('_', '/');
  const keychainFactory = useKeychainFactorySummaryOrDetails(fullId, type);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (keychainFactory?.keychainFactoryUri) {
      dispatch(fetchListKeychains(keychainFactory.keychainFactoryUri));
      dispatch(fetchListGroupLinks());
    }
  }, [dispatch, keychainFactory?.keychainFactoryUri]);
  if (!keychainFactory) {
    return (
      <Routes>
        <Route
          path='*'
          element={
            <KeychainFactoryNotFound
              isLoading={isLoading}
              type={type}
              id={fullId}
            />
          }
        />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route
        path='/'
        element={<KeychainFactoryPage keychainFactory={keychainFactory} />}
      />
      <Route
        path='/auditlog'
        element={<AuditPage keychainFactory={keychainFactory} />}
      />
      <Route
        path='*'
        element={<KeychainDetailsWrapper keychainFactory={keychainFactory} />}
      >
        <Route path='tilgangskontroll' element={<AccessControl />} />
        <Route
          path='tilgangskontroll/:profileId'
          element={<KeychainDetails />}
        />
        <Route path='låser' element={<LocksPage />} />
        <Route path='åpningstider' element={<OpeningHoursPage />} />
        <Route path='grouplink' element={<GroupLinkPage />} />
        <Route path='redirects' element={<OidcRedirect />} />
        <Route path='origins' element={<OidcOrigins />} />
        <Route path='lifespan' element={<OidcLifespan />} />
        <Route path='roller' element={<OidcRoleDefinition />} />
        <Route path='roller/:roleId' element={<OidcRoleDetails />} />
      </Route>
    </Routes>
  );
};
