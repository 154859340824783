import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import { actionsHome } from '../../../../components/Navigation/controlpanelNavPaths';
import {
  UpdateActionChain,
  updateActionChain
} from '../../../../redux/actions/adapters';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { ActionOperationsList } from '../../Adapter/details/ActionOperationsList';
import {
  ControlpanelActionChainsBreadcrumbs,
  ControlpanelBreadcrumbs
} from '../../ControlpanelBreadcrumbs';
import '../ActionChain.scss';
import { ActionList } from './ActionList';
import { AddNewActionToActionChain } from './AddNewActionToActionChain';
import { TermMenu } from './menu/ActionChainMenu';

interface MatchParams {
  chainId: string;
}

export const ActionChains: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const actionChains = useAppSelector(state => state.adapters.actionChains);
  const { chainId } = useParams<keyof MatchParams>();
  const actionChain = actionChains.find(
    actionChain => actionChain.id === chainId
  );
  const methods = useForm<UpdateActionChain>({
    resolver: zodResolver(UpdateActionChain),
    defaultValues: {
      actionList: []
    },
    values: actionChain && {
      actionChainId: actionChain.id,
      actionChainName: actionChain.name,
      expectedVersion: actionChain.version,
      actionList: actionChain.actionsList
    }
  });
  const { setValue, watch, handleSubmit } = methods;
  const actionList = watch('actionList');

  if (!actionChain) {
    return (
      <section>
        <ControlpanelBreadcrumbs currentPageName={chainId} />
        <div>Finner ikke ActionChain...</div>
      </section>
    );
  }

  const saveActionChain = (data: UpdateActionChain) =>
    dispatchAndHandleResult(dispatch, updateActionChain, data, goBack);

  const goBack = () => {
    navigate(actionsHome.path);
  };

  return (
    <section className='actionchain-details'>
      <ControlpanelActionChainsBreadcrumbs actionChain={actionChain} />
      <TermMenu actionChain={actionChain} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(saveActionChain)}>
          <div>
            <h2>
              <span className='label-actionchain'>URL:</span> {actionChain.uri}
            </h2>
            <ActionList
              actionList={actionList}
              setActionList={newList => setValue('actionList', newList)}
            />
            <AddNewActionToActionChain
              actionList={actionList}
              setActionList={newList => setValue('actionList', newList)}
            />
          </div>
          <div className='button-row'>
            <Button label='Avbryt' type='secondary' onClick={goBack} />
            <Button label='Lagre' type='submit' />
          </div>

          <ActionOperationsList
            usedByOperationsList={actionChain.usedByOperationsList}
          />
        </form>
      </FormProvider>
    </section>
  );
};
