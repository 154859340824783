import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SetURLSearchParams } from 'react-router-dom';
import { logAnalyticsEvent } from '../../../analyticsConfig';
import { KeyCombobox } from '../../../components/Forms/KeySelect';
import { DynamicTag } from '../../../proto/shared_pb';
import { useAppSelector } from '../../../redux/hooks';
import { categorySelectors } from '../../../redux/slice/categories';
import { Subject } from '../../../redux/slice/subjects';
import { Category } from '../../../redux/types/protoTypes';
import { getCategoryNameFromId } from '../../../utils/subjects/subjects';

interface CategoryOption {
  labelText: string;
  description?: string;
  value: string;
  selectedDisplayLabel: string;
  type: 'category';
}

interface TagOption {
  label: string;
  value: string;
  selectedDisplayLabel: string;
  type: 'tag';
}

interface FreetextOption {
  label: string;
  value: string;
  type: 'freetext';
  selectedDisplayLabel: string;
}

type Option = CategoryOption | FreetextOption | TagOption;

interface Props {
  subjects: Array<Subject>;
  selectedCategories: string[];
  selectedTags: string[];
  filter: string;
  setParams: SetURLSearchParams;
}

interface GroupedOption<Option> {
  readonly options: readonly Option[];
  readonly label?: string;
}
export const FilterSearch: React.FC<Props> = ({
  subjects,
  selectedCategories,
  selectedTags,
  setParams,
  filter
}) => {
  const categories = useAppSelector(categorySelectors.selectAll);

  const availableCategoryOptions: CategoryOption[] = useMemo(() => {
    const availableCategories = subjects.reduce(
      (categorySet, subject) =>
        categorySet.add(getCategoryNameFromId(subject.categoryId, categories)),
      new Set<Category>()
    );
    return Array.from(availableCategories).map(category => {
      return {
        labelText: category.name,
        description: category.description?.content.split('.')[0],
        value: category.id,
        selectedDisplayLabel: `Kategori: ${category.name}`,
        type: 'category'
      };
    });
  }, [subjects.length, categories.length]);

  const availableTagOptions: TagOption[] = useMemo(() => {
    const availableTags = subjects.reduce((tags, subject) => {
      subject.dynamicTagsList.forEach(tag => {
        tags.set(tag.typeId, tag.title);
      });
      return tags;
    }, new Map<DynamicTag.Type, string>());

    return Array.from(availableTags).map(([key, value]) => ({
      label: value,
      value: key.toString(),
      selectedDisplayLabel: `Markelapp: ${value}`,
      type: 'tag'
    }));
  }, [subjects]);

  const methods = useForm<{ search: Option[] }>({
    defaultValues: {
      search: []
    }
  });
  const search = methods.watch('search');
  const [setupComplete, setSetupComplete] = useState(false);
  useEffect(() => {
    if (!setupComplete) {
      return;
    }
    const newSearchParams = search.reduce<Record<string, Array<string>>>(
      (searchParams, value) => {
        if (value.type === 'category') {
          searchParams.category.push(value.value);
        } else if (value.type === 'tag') {
          searchParams.tag.push(value.value);
        } else {
          searchParams.filter = [value.value];
        }
        return searchParams;
      },
      {
        category: [],
        tag: []
      }
    );
    if (
      filter == newSearchParams.filter?.[0] &&
      selectedCategories.length == newSearchParams.category.length &&
      selectedTags.length == newSearchParams.tag.length
    ) {
      return;
    }
    setParams(newSearchParams, { replace: true });

    if (filter || selectedCategories.length || selectedTags.length) {
      logAnalyticsEvent('search', {
        search_term: filter,
        tags: selectedTags,
        catgeories: selectedCategories
      });
    }
  }, [search]);

  useEffect(() => {
    if (
      availableTagOptions.length == 0 ||
      availableCategoryOptions.length == 0
    ) {
      return;
    }
    if (search.length > 0) {
      return;
    }
    setSetupComplete(true);
    const categoryOptions = filterAndMapOptions(
      selectedCategories,
      availableCategoryOptions
    );
    const tagOptions = filterAndMapOptions(selectedTags, availableTagOptions);
    const freetextOption: FreetextOption[] = [];
    if (filter) {
      freetextOption.push(addFreetextOption(filter));
    }
    const newSearch = [...freetextOption, ...categoryOptions, ...tagOptions];

    methods.setValue('search', newSearch);
  }, [availableTagOptions, availableCategoryOptions]);

  const groupOptions: GroupedOption<Option>[] = useMemo(() => {
    return [
      {
        label: 'Merkelapper',
        options: availableTagOptions
      },
      {
        label: 'Kategorier',
        options: availableCategoryOptions
      }
    ];
  }, [availableCategoryOptions, availableTagOptions]);

  return (
    <div className='filter-search-container'>
      <FormProvider {...methods}>
        <KeyCombobox
          name='search'
          elementProps={{
            autoFocus: true,
            isMulti: true,
            placeholder: (
              <span>
                Skriv inn søkeord, eller velg kategori fra nedtrekkslisten
              </span>
            ),
            formatCreateLabel: (inputValue: string) => {
              return 'Fritekst: ' + inputValue;
            },
            onCreateOption: (inputValue: string) => {
              const currentSearch = methods.getValues().search;
              const newSearch: Option[] = currentSearch.filter(
                option => option.type !== 'freetext'
              );
              newSearch.push(addFreetextOption(inputValue));
              methods.setValue('search', newSearch);
            },
            createOptionPosition: 'first',
            options: groupOptions
          }}
        />
      </FormProvider>
    </div>
  );
};

const addFreetextOption = (filter: string): FreetextOption => {
  return {
    label: filter,
    selectedDisplayLabel: 'Fritekst: ' + filter,
    value: filter,
    type: 'freetext'
  };
};

const filterAndMapOptions = <T extends CategoryOption | TagOption>(
  selectedValues: string[],
  availableOptions: Array<T>
): T[] => {
  return selectedValues
    .map(value => {
      return availableOptions.find(opt => opt.value === value);
    })
    .filter((opt): opt is T => opt !== undefined);
};
