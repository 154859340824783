import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdapterDetails } from '../pages/controlpanel/Adapter/AdapterDetails';
import { AdminAdapterPage } from '../pages/controlpanel/Adapter/AdminAdapterPage';
import { CreateAdapter } from '../pages/controlpanel/Adapter/CreateAdapter';
import { ActionDetails } from '../pages/controlpanel/Adapter/details/ActionDetails';
import { listActions, listAdapters } from '../redux/actions/adapters';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const AdminAdapterRouter = () => {
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedAdminTool !== 'action') {
      dispatch(listActions());
      dispatch(listAdapters());
      dispatch(setSelectedAdminTool('action'));
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <section>
      <Routes>
        <Route path='lag-nytt' element={<CreateAdapter />} />
        <Route path=':adapterId' element={<AdapterDetails />} />
        <Route
          path=':adapterId/:thingId/:actionId'
          element={<ActionDetails />}
        />
        <Route path='/' element={<AdminAdapterPage />} />
      </Routes>
    </section>
  );
};
