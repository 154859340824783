import React from 'react';
import { AccessControllIcon2 } from '../../assets/AccessControlIcon2';
import { AccessControllIcon } from '../../assets/AccessControllIcon';
import { AgeSvg } from '../../assets/AgeSvg';
import { AlertIcon } from '../../assets/AlertIcon';
import { BookedKeychain } from '../../assets/BookedKeychain';
import { CaretSvg } from '../../assets/CaretSvg';
import {
  AccessControl,
  Action,
  Adapters,
  BlankRed,
  Building,
  Categories,
  Data,
  Door,
  GodMode,
  ManualGrantChains,
  PVD,
  Super,
  ThreeDots,
  Web
} from '../../assets/CircledIcons';
import { ClockIcon } from '../../assets/ClockIcon';
import { CloneIcon } from '../../assets/CloneIcon';
import { DeactivateIcon } from '../../assets/DeactivateIcon';
import { DescriptionSvg } from '../../assets/DescriptionSvg';
import { DotsIcon } from '../../assets/DotsIcon';
import { EditPencilIcon } from '../../assets/EditPencilIcon';
import { EmailIcon } from '../../assets/EmailIcon';
import { FabrikkSvg } from '../../assets/FabrikkSvg';
import { GpsSvg } from '../../assets/GpsSvg';
import { KartSvg } from '../../assets/KartSvg';
import { KeypediaSvg } from '../../assets/KeypediaSvg';
import { LockSvg } from '../../assets/LockSvg';
import { ManualGrantKeychain } from '../../assets/ManualGrantKeychain';
import { OperationDoor } from '../../assets/OperationDoor';
import { OperationLight } from '../../assets/OperationLight';
import { OperationUnknown } from '../../assets/OperationUnknown';
import { PublicKeychain } from '../../assets/PublicKeychain';
import { PushKeychain } from '../../assets/PushKeychain';
import { PvdSvg } from '../../assets/PvdSvg';
import { QrSvg } from '../../assets/QrSvg';
import { RedirectIcon } from '../../assets/RedirectIcon';
import { RoleIcon } from '../../assets/RoleIcon';
import { SearchSvg } from '../../assets/SearchSvg';
import { ShareIcon } from '../../assets/ShareIcon';
import { StatsSvg } from '../../assets/StatsSvg';
import { TermsSvg } from '../../assets/TermsSvg';
import { TimeIcon } from '../../assets/TimeIcon';
import { TrashCan } from '../../assets/TrashCan';
import { UnknownSvg } from '../../assets/UnknownSvg';
import { WarningCircle } from '../../assets/WarningCircle';
import { WarningCircleInactive } from '../../assets/WarningCircleInactive';
import { BrickSvg } from '../../assets/navbar';
import { Breadcrumbs } from '../../components/Navigation/breadcrumbs';
import { showcaseBreadcrumb } from './Showcase';

export const ImageComponents: React.FC = () => {
  return (
    <div>
      <Breadcrumbs
        breadcrumbs={showcaseBreadcrumb.concat({ name: 'Images' })}
      />
      <h1>Assets</h1>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <AccessControllIcon />
        <AccessControllIcon2 />
        <AgeSvg />
        <AlertIcon />
        <BookedKeychain />
        <BrickSvg />
        <CaretSvg />
        <ClockIcon />
        <CloneIcon />
        <DeactivateIcon />
        <DescriptionSvg />
        <DotsIcon />
        <EditPencilIcon />
        <EmailIcon />
        <FabrikkSvg />
        <GpsSvg />
        <ManualGrantKeychain />
        <KartSvg />
        <KeypediaSvg />
        <LockSvg />
        <OperationDoor />
        <OperationLight />
        <OperationUnknown />
        <PublicKeychain />
        <PushKeychain />
        <PvdSvg />
        <QrSvg />
        <RedirectIcon />
        <RoleIcon />
        <SearchSvg />
        <ShareIcon />
        <StatsSvg />
        <TimeIcon />
        <TermsSvg />
        <TrashCan />
        <UnknownSvg />
        <WarningCircle />
        <WarningCircleInactive />
      </div>
      <h1>CircledIcons</h1>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <AccessControl />
        <Action />
        <Adapters />
        <BlankRed />
        <Building />
        <Categories />
        <Data />
        <Door />
        <GodMode />
        <ManualGrantChains />
        <PVD />
        <Super />
        <ThreeDots />
        <Web />
      </div>
    </div>
  );
};
