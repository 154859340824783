import React from 'react';
import { KeychainFactoryDetails } from '../../redux/slice/keychainFactoryDetails';
import { KeychainFactorySummary } from '../../redux/slice/keychainFactorySummaries';
import { ManualGrantKeychainFactory } from './ManualGrantKeychainFactory';
import { OidcKeychainFactoryPage } from './oidcKeychainFactory/OidcKeychainFactoryPage';
import { PublicKeychainFactoryPage } from './publicKeychainFactory/PublicKeychainFactory';
import { PushKeychainFactoryPage } from './pushSystemKeychainFactory/PushKeychainFactoryPage';
import { UnknownKeychainFactoryPage } from './unknownKeychainFactory/UnknownKeychainFactory';

type Props = {
  keychainFactory: KeychainFactorySummary | KeychainFactoryDetails;
};
export const KeychainFactoryPage: React.FC<Props> = ({ keychainFactory }) => {
  switch (keychainFactory.type) {
    case 'manual-grant':
      return <ManualGrantKeychainFactory keychainFactory={keychainFactory} />;
    case 'push':
      return <PushKeychainFactoryPage keychainFactory={keychainFactory} />;
    case 'oidc':
      return <OidcKeychainFactoryPage keychainFactory={keychainFactory} />;
    case 'public':
      return <PublicKeychainFactoryPage keychainFactory={keychainFactory} />;
    default:
      return <UnknownKeychainFactoryPage keychainFactory={keychainFactory} />;
  }
};
