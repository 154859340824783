import React from 'react';

export const AuditSvg = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Frame'>
      <path
        id='Vector'
        d='M29.9472 12.2599L25.36 20.3922L14.2844 13.9814C14.1017 14.174 13.9236 14.3638 13.7432 14.5514C12.6299 15.7091 11.5171 16.8672 10.4047 18.0258C10.3606 18.0745 10.306 18.1127 10.245 18.1375C10.1841 18.1622 10.1184 18.1729 10.0527 18.1688C8.21294 18.1644 6.37312 18.1634 4.53329 18.1659H4.25049V30.7661H2.05298V1.23364H4.23427V15.9435H4.48327C6.02589 15.9435 7.56853 15.9458 9.11111 15.9386C9.22712 15.9402 9.33984 15.9001 9.42883 15.8256C10.3717 14.8576 11.3052 13.8803 12.2407 12.905C12.2593 12.8814 12.2764 12.8566 12.2921 12.8309L6.55041 9.50499L11.128 1.24547L29.9472 12.2599ZM23.4351 11.0301L11.9568 4.31208L9.54599 8.66713L21.073 15.3374L23.4351 11.0301ZM22.9881 16.4457L24.5395 17.3421L26.9411 13.0832L25.3435 12.148L22.9881 16.4457Z'
      />
    </g>
  </svg>
);
