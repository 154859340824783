import React from 'react';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { TPSList } from './TPSList';
import { ThirdPartySystemsContainer } from './ThirdPartySystemsContainer';

export const ThirdPartySystems = () => (
  <ThirdPartySystemsContainer>
    <ControlpanelBreadcrumbs />
    <TPSList />
  </ThirdPartySystemsContainer>
);
