import React from 'react';

export const RoleIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='94.9'
    height='69.628'
    viewBox='0 0 94.9 69.628'
  >
    <g
      id='Group_12248'
      data-name='Group 12248'
      transform='translate(-2.6 -15.179)'
    >
      <path
        id='Path_11377'
        data-name='Path 11377'
        d='M97.5,46.5c0-5.7-8.5-9.1-19-11.1L75.9,24.1A11.516,11.516,0,0,0,62,15.5l-7.1,1.6a22.665,22.665,0,0,1-9.7,0l-7.1-1.6a11.642,11.642,0,0,0-13.9,8.6L21.6,35.4c-10.6,2-19,5.4-19,11.1,0,4.9,6.3,8.1,14.8,10.2L15.7,75.2a7.464,7.464,0,0,0,1.5,5.4,7.085,7.085,0,0,0,4.9,2.8c6,.8,14.8,1.2,18.3,1.4h.4a8.225,8.225,0,0,0,6-2.5l2-2a2.606,2.606,0,0,1,3.5,0l1.9,1.9a8.668,8.668,0,0,0,6.5,2.6c4.2-.2,12.2-.6,17.6-1.3a7.435,7.435,0,0,0,6.4-8.3L83,56.7C91.2,54.6,97.5,51.4,97.5,46.5ZM78.4,75.8v.1a1.383,1.383,0,0,1-1.2,1.6c-3.8.5-10.1,1-17.1,1.3a2.268,2.268,0,0,1-1.8-.7l-1.9-2a8.72,8.72,0,0,0-6.1-2.6,7.942,7.942,0,0,0-6,2.6l-2,2a2.086,2.086,0,0,1-1.8.7c-7.2-.3-13.7-.8-17.8-1.3a1.742,1.742,0,0,1-.9-.5,1.964,1.964,0,0,1-.3-1v-.1L23.2,58A170.97,170.97,0,0,0,50,60.1,159.71,159.71,0,0,0,76.8,58ZM50,54c-24.9,0-40-4.8-41.4-7.4.7-1.3,4.7-3.1,11.5-4.6l-.2.7A3.021,3.021,0,0,0,25.8,44l4.3-18.5a5.482,5.482,0,0,1,6.6-4.1L43.8,23a28.811,28.811,0,0,0,12.4,0l7.1-1.6a5.512,5.512,0,0,1,6.6,4.1L74.3,44a2.979,2.979,0,0,0,2.9,2.3,1.7,1.7,0,0,0,.7-.1,3.128,3.128,0,0,0,2.3-3.6l-.2-.7c6.8,1.5,10.8,3.3,11.5,4.6C90,49.2,74.9,54,50,54Z'
      />
      <ellipse
        id='Ellipse_751'
        data-name='Ellipse 751'
        cx='6.4'
        cy='4.1'
        rx='6.4'
        ry='4.1'
        transform='translate(57.2 64.1)'
      />
      <ellipse
        id='Ellipse_752'
        data-name='Ellipse 752'
        cx='6.4'
        cy='4.1'
        rx='6.4'
        ry='4.1'
        transform='translate(30 64.1)'
      />
    </g>
  </svg>
);
