import React from 'react';
import {
  Breadcrumbs,
  generateKeychainBreadcrumbs
} from '../../components/Navigation/breadcrumbs';
import { Keychain } from '../../redux/slice/keychains';
import { KeychainCommon } from '../../redux/slice/operations';
import { getKeychainFactoryLink } from '../../utils/keychainFactory/getKeychainFactoryAssets';

interface Props {
  keychainFactory: KeychainCommon;
  name?: string;
  subName?: string;
}

export const KeychainFactoryBreadcrumbs: React.FC<Props> = ({
  keychainFactory,
  name,
  subName
}) => {
  const breadcrumbs = [
    ...generateKeychainBreadcrumbs(keychainFactory.type),
    {
      name: keychainFactory.name,
      path: getKeychainFactoryLink(keychainFactory)
    }
  ];
  if (name) {
    breadcrumbs.push({ name, path: subName ? '..' : undefined });
    if (subName) {
      breadcrumbs.push({ name: subName });
    }
  }
  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};

type KeychainBreadcumbsProps = {
  keychainFactory: KeychainCommon;
  keychain: Keychain;
};

export const KeychainBreadcrumbs: React.FC<KeychainBreadcumbsProps> = ({
  keychainFactory,
  keychain
}) => {
  const breadcrumbs = [
    ...generateKeychainBreadcrumbs(keychainFactory.type),
    {
      name: keychainFactory.name,
      path: getKeychainFactoryLink(keychainFactory)
    },
    {
      name: 'Tilgangsoversikt',
      path: getKeychainFactoryLink(keychainFactory) + '/tilgangskontroll'
    },
    {
      name: keychain?.personName || 'Brukerinformasjon'
    }
  ];
  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
