/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.adapter
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: grpc-adapter.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as grpc$adapter_pb from './grpc-adapter_pb'; // proto import: "grpc-adapter.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class AdapterServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateAdapter = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateAdapter',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.CreateAdapterMessage,
    shared_pb.EmptyResponse,
    (request: grpc$adapter_pb.CreateAdapterMessage) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createAdapter(
    request: grpc$adapter_pb.CreateAdapterMessage,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createAdapter(
    request: grpc$adapter_pb.CreateAdapterMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createAdapter(
    request: grpc$adapter_pb.CreateAdapterMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateAdapter',
        request,
        metadata || {},
        this.methodDescriptorCreateAdapter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateAdapter',
    request,
    metadata || {},
    this.methodDescriptorCreateAdapter);
  }

  methodDescriptorToggleAdapterSandbox = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ToggleAdapterSandbox',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.ToggleAdapterSandboxMessage,
    shared_pb.EmptyResponse,
    (request: grpc$adapter_pb.ToggleAdapterSandboxMessage) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  toggleAdapterSandbox(
    request: grpc$adapter_pb.ToggleAdapterSandboxMessage,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  toggleAdapterSandbox(
    request: grpc$adapter_pb.ToggleAdapterSandboxMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  toggleAdapterSandbox(
    request: grpc$adapter_pb.ToggleAdapterSandboxMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ToggleAdapterSandbox',
        request,
        metadata || {},
        this.methodDescriptorToggleAdapterSandbox,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ToggleAdapterSandbox',
    request,
    metadata || {},
    this.methodDescriptorToggleAdapterSandbox);
  }

  methodDescriptorCreateSelftestKey = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateSelftestKey',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.CreateSelftestKeyMessage,
    grpc$adapter_pb.CreateSelftestKeyResponse,
    (request: grpc$adapter_pb.CreateSelftestKeyMessage) => {
      return request.serializeBinary();
    },
    grpc$adapter_pb.CreateSelftestKeyResponse.deserializeBinary
  );

  createSelftestKey(
    request: grpc$adapter_pb.CreateSelftestKeyMessage,
    metadata?: grpcWeb.Metadata | null): Promise<grpc$adapter_pb.CreateSelftestKeyResponse>;

  createSelftestKey(
    request: grpc$adapter_pb.CreateSelftestKeyMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grpc$adapter_pb.CreateSelftestKeyResponse) => void): grpcWeb.ClientReadableStream<grpc$adapter_pb.CreateSelftestKeyResponse>;

  createSelftestKey(
    request: grpc$adapter_pb.CreateSelftestKeyMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grpc$adapter_pb.CreateSelftestKeyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateSelftestKey',
        request,
        metadata || {},
        this.methodDescriptorCreateSelftestKey,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateSelftestKey',
    request,
    metadata || {},
    this.methodDescriptorCreateSelftestKey);
  }

  methodDescriptorDeleteAdapter = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/DeleteAdapter',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.DeleteAdapterMessage,
    shared_pb.EmptyResponse,
    (request: grpc$adapter_pb.DeleteAdapterMessage) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteAdapter(
    request: grpc$adapter_pb.DeleteAdapterMessage,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteAdapter(
    request: grpc$adapter_pb.DeleteAdapterMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteAdapter(
    request: grpc$adapter_pb.DeleteAdapterMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/DeleteAdapter',
        request,
        metadata || {},
        this.methodDescriptorDeleteAdapter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/DeleteAdapter',
    request,
    metadata || {},
    this.methodDescriptorDeleteAdapter);
  }

  methodDescriptorListAdapters = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ListAdapters',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.ListAdaptersRequest,
    grpc$adapter_pb.AdapterListResponse,
    (request: grpc$adapter_pb.ListAdaptersRequest) => {
      return request.serializeBinary();
    },
    grpc$adapter_pb.AdapterListResponse.deserializeBinary
  );

  listAdapters(
    request: grpc$adapter_pb.ListAdaptersRequest,
    metadata?: grpcWeb.Metadata | null): Promise<grpc$adapter_pb.AdapterListResponse>;

  listAdapters(
    request: grpc$adapter_pb.ListAdaptersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grpc$adapter_pb.AdapterListResponse) => void): grpcWeb.ClientReadableStream<grpc$adapter_pb.AdapterListResponse>;

  listAdapters(
    request: grpc$adapter_pb.ListAdaptersRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grpc$adapter_pb.AdapterListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ListAdapters',
        request,
        metadata || {},
        this.methodDescriptorListAdapters,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ListAdapters',
    request,
    metadata || {},
    this.methodDescriptorListAdapters);
  }

  methodDescriptorDescribeAdapter = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/DescribeAdapter',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.DescribeAdapterRequest,
    grpc$adapter_pb.AdapterDescriptionResponse,
    (request: grpc$adapter_pb.DescribeAdapterRequest) => {
      return request.serializeBinary();
    },
    grpc$adapter_pb.AdapterDescriptionResponse.deserializeBinary
  );

  describeAdapter(
    request: grpc$adapter_pb.DescribeAdapterRequest,
    metadata?: grpcWeb.Metadata | null): Promise<grpc$adapter_pb.AdapterDescriptionResponse>;

  describeAdapter(
    request: grpc$adapter_pb.DescribeAdapterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grpc$adapter_pb.AdapterDescriptionResponse) => void): grpcWeb.ClientReadableStream<grpc$adapter_pb.AdapterDescriptionResponse>;

  describeAdapter(
    request: grpc$adapter_pb.DescribeAdapterRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grpc$adapter_pb.AdapterDescriptionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/DescribeAdapter',
        request,
        metadata || {},
        this.methodDescriptorDescribeAdapter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/DescribeAdapter',
    request,
    metadata || {},
    this.methodDescriptorDescribeAdapter);
  }

  methodDescriptorListAllActions = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ListAllActions',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.ListAllActionsRequest,
    grpc$adapter_pb.ListAllActionsResponse,
    (request: grpc$adapter_pb.ListAllActionsRequest) => {
      return request.serializeBinary();
    },
    grpc$adapter_pb.ListAllActionsResponse.deserializeBinary
  );

  listAllActions(
    request: grpc$adapter_pb.ListAllActionsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<grpc$adapter_pb.ListAllActionsResponse>;

  listAllActions(
    request: grpc$adapter_pb.ListAllActionsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grpc$adapter_pb.ListAllActionsResponse) => void): grpcWeb.ClientReadableStream<grpc$adapter_pb.ListAllActionsResponse>;

  listAllActions(
    request: grpc$adapter_pb.ListAllActionsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grpc$adapter_pb.ListAllActionsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ListAllActions',
        request,
        metadata || {},
        this.methodDescriptorListAllActions,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ListAllActions',
    request,
    metadata || {},
    this.methodDescriptorListAllActions);
  }

  methodDescriptorGenerateActionHealthApiKey = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/GenerateActionHealthApiKey',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.GenerateActionHealthApiKeyRequest,
    shared_pb.EmptyResponse,
    (request: grpc$adapter_pb.GenerateActionHealthApiKeyRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  generateActionHealthApiKey(
    request: grpc$adapter_pb.GenerateActionHealthApiKeyRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  generateActionHealthApiKey(
    request: grpc$adapter_pb.GenerateActionHealthApiKeyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  generateActionHealthApiKey(
    request: grpc$adapter_pb.GenerateActionHealthApiKeyRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/GenerateActionHealthApiKey',
        request,
        metadata || {},
        this.methodDescriptorGenerateActionHealthApiKey,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/GenerateActionHealthApiKey',
    request,
    metadata || {},
    this.methodDescriptorGenerateActionHealthApiKey);
  }

  methodDescriptorCreateBetterUptimeMonitor = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateBetterUptimeMonitor',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.CreateBetterUptimeMonitorRequest,
    shared_pb.EmptyResponse,
    (request: grpc$adapter_pb.CreateBetterUptimeMonitorRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createBetterUptimeMonitor(
    request: grpc$adapter_pb.CreateBetterUptimeMonitorRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createBetterUptimeMonitor(
    request: grpc$adapter_pb.CreateBetterUptimeMonitorRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createBetterUptimeMonitor(
    request: grpc$adapter_pb.CreateBetterUptimeMonitorRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateBetterUptimeMonitor',
        request,
        metadata || {},
        this.methodDescriptorCreateBetterUptimeMonitor,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateBetterUptimeMonitor',
    request,
    metadata || {},
    this.methodDescriptorCreateBetterUptimeMonitor);
  }

  methodDescriptorListActionChains = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ListActionChains',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.ListActionChainsRequest,
    grpc$adapter_pb.ActionChainListResponse,
    (request: grpc$adapter_pb.ListActionChainsRequest) => {
      return request.serializeBinary();
    },
    grpc$adapter_pb.ActionChainListResponse.deserializeBinary
  );

  listActionChains(
    request: grpc$adapter_pb.ListActionChainsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<grpc$adapter_pb.ActionChainListResponse>;

  listActionChains(
    request: grpc$adapter_pb.ListActionChainsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: grpc$adapter_pb.ActionChainListResponse) => void): grpcWeb.ClientReadableStream<grpc$adapter_pb.ActionChainListResponse>;

  listActionChains(
    request: grpc$adapter_pb.ListActionChainsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: grpc$adapter_pb.ActionChainListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ListActionChains',
        request,
        metadata || {},
        this.methodDescriptorListActionChains,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ListActionChains',
    request,
    metadata || {},
    this.methodDescriptorListActionChains);
  }

  methodDescriptorCreateActionChain = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateActionChain',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.CreateActionChainRequest,
    shared_pb.EmptyResponse,
    (request: grpc$adapter_pb.CreateActionChainRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createActionChain(
    request: grpc$adapter_pb.CreateActionChainRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createActionChain(
    request: grpc$adapter_pb.CreateActionChainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createActionChain(
    request: grpc$adapter_pb.CreateActionChainRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateActionChain',
        request,
        metadata || {},
        this.methodDescriptorCreateActionChain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/CreateActionChain',
    request,
    metadata || {},
    this.methodDescriptorCreateActionChain);
  }

  methodDescriptorUpdateActionChain = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/UpdateActionChain',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.UpdateActionChainRequest,
    shared_pb.EmptyResponse,
    (request: grpc$adapter_pb.UpdateActionChainRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateActionChain(
    request: grpc$adapter_pb.UpdateActionChainRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateActionChain(
    request: grpc$adapter_pb.UpdateActionChainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateActionChain(
    request: grpc$adapter_pb.UpdateActionChainRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/UpdateActionChain',
        request,
        metadata || {},
        this.methodDescriptorUpdateActionChain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/UpdateActionChain',
    request,
    metadata || {},
    this.methodDescriptorUpdateActionChain);
  }

  methodDescriptorRenameActionChain = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/RenameActionChain',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.RenameActionChainRequest,
    shared_pb.EmptyResponse,
    (request: grpc$adapter_pb.RenameActionChainRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  renameActionChain(
    request: grpc$adapter_pb.RenameActionChainRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  renameActionChain(
    request: grpc$adapter_pb.RenameActionChainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  renameActionChain(
    request: grpc$adapter_pb.RenameActionChainRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/RenameActionChain',
        request,
        metadata || {},
        this.methodDescriptorRenameActionChain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/RenameActionChain',
    request,
    metadata || {},
    this.methodDescriptorRenameActionChain);
  }

  methodDescriptorScrapeAdapterManifests = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ScrapeAdapterManifests',
    grpcWeb.MethodType.UNARY,
    grpc$adapter_pb.ScrapeAdapterManifestsRequest,
    shared_pb.EmptyResponse,
    (request: grpc$adapter_pb.ScrapeAdapterManifestsRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  scrapeAdapterManifests(
    request: grpc$adapter_pb.ScrapeAdapterManifestsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  scrapeAdapterManifests(
    request: grpc$adapter_pb.ScrapeAdapterManifestsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  scrapeAdapterManifests(
    request: grpc$adapter_pb.ScrapeAdapterManifestsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ScrapeAdapterManifests',
        request,
        metadata || {},
        this.methodDescriptorScrapeAdapterManifests,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.adapter.AdapterService/ScrapeAdapterManifests',
    request,
    metadata || {},
    this.methodDescriptorScrapeAdapterManifests);
  }

}

