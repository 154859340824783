import React from 'react';
import './GrafanaDashboardLink.scss';

interface Props {
  href: string;
  title: string;
}

export const GrafanaDashboardLink: React.FC<Props> = ({ href, title }) => {
  return (
    <a className='grafana-dashboard-link' title={title} href={href}>
      <img src='/grafana.svg' alt='Grafana' />
    </a>
  );
};
