import React from 'react';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { API_URL } from '../../../constants';
import { useAppSelector } from '../../../redux/hooks';
import { PvdDetails, pvdSelectors } from '../../../redux/slice/pvd';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';
import { formatDateFromEpochMillis } from '../../../utils/timeConverter';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const PvdList: React.FC = () => {
  const pvdList: PvdDetails[] = useAppSelector(pvdSelectors.selectAll);
  const loading = useAppSelector(state => state.pvd.devicelistLoading);
  const tableHeadings = [
    'ID',
    'Secret',
    'Tidsavvik (sekunder)',
    'Regenereringssyklus (sekunder)',
    'Antall eldre/fremtidige koder som fortsatt virker',
    'Sist endret',
    'QR'
  ];
  const data = pvdList.map(pvd => {
    return {
      cells: [
        <CopyWrapper key={pvd.id} copyValue={pvd.id} />,
        pvd.secret,
        pvd.timeDriftSeconds,
        pvd.timeStepSeconds,
        pvd.discrepancyTolerance,
        pvd.lastModifiedTimestamp ? (
          <span
            key={pvd.id}
            title={formatDateFromEpochMillis(pvd.lastModifiedTimestamp)}
          >
            {formatDateFromEpochMillis(pvd.lastModifiedTimestamp, true)}
          </span>
        ) : (
          <span key={pvd.id}>Aldri endret</span>
        ),
        <a
          key={pvd.id}
          onClick={e => {
            e.preventDefault();
            window.location.href = `${API_URL}/web/proximity/${pvd.id}/`;
          }}
          href={`${API_URL}/web/proximity/${pvd.id}/`}
        >
          🔗
        </a>
      ]
    };
  });
  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs />
      {loading && pvdList.length === 0 ? (
        <p>Laster...</p>
      ) : (
        <TableWithButtons
          ting='nærhetsenhet'
          buttons={[
            {
              label: 'Legg til ny nærhetsenhet',
              type: 'primary',
              accessKey: 'n',
              linkTo: 'lag-ny'
            }
          ]}
          table={{
            headings: tableHeadings,
            rows: data
          }}
        />
      )}
    </React.Fragment>
  );
};
