import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import { termsSelectors } from '../../../redux/slice/terms';
import { Cards } from './Cards';
import { NotFound } from './NotFound';
import './Term.scss';
import { TermBreadcrumbs } from './breadcrumbs/TermBreadcrumbs';
import { TermMenu } from './menu/TermMenu';

interface MatchParams {
  termId: string;
}

export const Term: React.FC = () => {
  const { termId = '' } = useParams<keyof MatchParams>();
  const term = useAppSelector(state =>
    termsSelectors.selectById(state, termId)
  );
  return (
    <section className='term'>
      {term ? (
        <>
          <TermBreadcrumbs term={term} />
          <TermMenu term={term} />
          <Cards term={term} />
        </>
      ) : (
        <NotFound termId={termId} />
      )}
    </section>
  );
};
