import { z } from 'zod';
import {
  KeychainFactoryType,
  LocalDateTimeInterval,
  Text
} from '../../proto/shared_pb';

export const FactoryType = z.nativeEnum(KeychainFactoryType).transform(type => {
  switch (type) {
    case KeychainFactoryType.PUBLIC:
      return 'public';
    case KeychainFactoryType.MANUAL_GRANT:
      return 'manual-grant';
    case KeychainFactoryType.PUSH:
      return 'push';
    case KeychainFactoryType.OIDC:
      return 'oidc';
    default:
      return 'unknown';
  }
});

export const TextContent = z.object({
  content: z.string(),
  type: z.nativeEnum(Text.Type)
});

export const Keypedia = z.object({
  htmlContent: z.string().default(''),
  lastUpdatedMillis: z.number().default(0),
  lastUpdatedBy: z.string().default('')
});

export const TimeIntervalsList = z.object({
  from: z.string().default(''),
  until: z.string().default('')
});

export const DaysEng = z.enum([
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
]);

export const SimpleDayRule = z.object({
  day: DaysEng,
  timeInterval: z.array(TimeIntervalsList)
});

export const DateOverrideRule = z.object({
  date: z.string(), //YYYY-MM-DD
  timeInterval: z.array(TimeIntervalsList)
});

export const ClosedDateRule = z.object({
  date: z.string() //YYYY-MM-DD
});

export const OpeningHours = z.object({
  simpleDayRulesList: z.array(SimpleDayRule),
  dateOverrideRulesList: z.array(DateOverrideRule),
  closedDateRulesList: z.array(ClosedDateRule),
  alwaysOpen: z.boolean(),
  spec: z.string()
});

export const flattenInterval = (interval?: LocalDateTimeInterval.AsObject) => {
  return {
    from: {
      date: interval?.from?.date?.dateString || '',
      time: interval?.from?.time?.timeString || ''
    },
    until: {
      date: interval?.until?.date?.dateString || '',
      time: interval?.until?.time?.timeString || ''
    }
  };
};
export type TimeInterval = ReturnType<typeof flattenInterval>;

export type ImageData = {
  uri: string;
};

export const policiesListToModel = <T extends object>(
  policiesList: T[] = []
) => {
  return policiesList.reduce((acc, obj) => {
    for (const [key, value] of Object.entries(obj)) {
      if (value !== undefined) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[key as keyof T] = value;
      }
    }
    return acc;
  }, {} as T);
};

export type FactoryType = z.infer<typeof FactoryType>;
export type TextContent = z.infer<typeof TextContent>;
export type Keypedia = z.infer<typeof Keypedia>;
export type TimeIntervalsList = z.infer<typeof TimeIntervalsList>;
export type SimpleDayRule = z.infer<typeof SimpleDayRule>;
export type DateOverrideRule = z.infer<typeof DateOverrideRule>;
export type ClosedDateRule = z.infer<typeof ClosedDateRule>;
export type OpeningHours = z.infer<typeof OpeningHours>;
export type Interval = ReturnType<typeof flattenInterval>;
