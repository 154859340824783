import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdminKeychain } from '../pages/controlpanel/KeychainFactory/AdminKeychain';
import { AdminPublicKeychainPage } from '../pages/controlpanel/PublicKeychainFactory/AdminPublicKeychainPage';
import { CreatePublicKeychainFactoryPage } from '../pages/controlpanel/PublicKeychainFactory/CreatePublicKeychainFactoryPage';
import { listKeychainFactoryConfigurations } from '../redux/actions/keychainFactorySummaries';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const AdminPublicKeychainRouter = () => {
  const dispatch = useAppDispatch();
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  useEffect(() => {
    if (selectedAdminTool !== 'publicchains') {
      dispatch(listKeychainFactoryConfigurations());
      dispatch(setSelectedAdminTool('publicchains'));
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <section>
      <Routes>
        <Route path='lag-ny' element={<CreatePublicKeychainFactoryPage />} />
        <Route path=':keychainId' element={<AdminKeychain type='public' />} />
        <Route path='/' element={<AdminPublicKeychainPage />} />
      </Routes>
    </section>
  );
};
