import React from 'react';
import { ActionMenu } from '../../../components/ActionMenu/ActionMenu';
import { ActionMenuContainer } from '../../../components/ActionMenu/ActionMenuContainer';
import { ActionMenuItemProps } from '../../../components/ActionMenu/ActionMenuItem';
import { KeychainFactoryDetails } from '../../../redux/slice/keychainFactoryDetails';
import { DeleteKeychain } from '../../keychainFactory/actions/DeleteKeychain';

type Props = {
  keychainFactory: KeychainFactoryDetails;
};
export const KeychainActionMenu: React.FC<Props> = ({ keychainFactory }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const { policiesList } = keychainFactory;
  const actions: ActionMenuItemProps[] = [];
  if (policiesList.pb_delete) {
    actions.push({
      type: 'delete',
      description: 'Slett tilgangslisten',
      onClick: () => setIsDeleteModalOpen(true)
    });
  }

  return (
    <ActionMenuContainer>
      <ActionMenu actions={actions} />
      <DeleteKeychain
        isModalOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        keychainFactory={keychainFactory}
      />
    </ActionMenuContainer>
  );
};
