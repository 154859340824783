import React from 'react';

export const BrickSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='11'
    viewBox='0 0 24 11'
  >
    <g id='Group_2065' data-name='Group 2065' transform='translate(-762 -665)'>
      <g
        id='Rectangle_1801'
        data-name='Rectangle 1801'
        transform='translate(762 665)'
        fill='none'
        stroke='#292858'
        strokeWidth='1'
      >
        <rect width='24' height='11' stroke='none' />
        <rect x='0.5' y='0.5' width='23' height='10' fill='none' />
      </g>
      <path
        id='Path_4580'
        data-name='Path 4580'
        d='M-10028.463-645.06l1.593-.6,2.658.951'
        transform='translate(10794 1314.002)'
        fill='none'
        stroke='#292858'
        strokeWidth='1'
      />
      <path
        id='Path_4582'
        data-name='Path 4582'
        d='M-10025.6-645.116l-1.071-.543-1.788.862'
        transform='translate(10807.968 1316.689)'
        fill='none'
        stroke='#292858'
        strokeWidth='1'
      />
      <path
        id='Path_4583'
        data-name='Path 4583'
        d='M-10025.6-645.116l-1.071-.543-1.788.862'
        transform='translate(10797.968 1317.672)'
        fill='none'
        stroke='#292858'
        strokeWidth='1'
      />
    </g>
  </svg>
);
