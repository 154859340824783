import { zodResolver } from '@hookform/resolvers/zod';
import { formatISO } from 'date-fns';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { EndDatePicker } from '../../../../components/DatePickers/EndDatePicker';
import { StartDatePicker } from '../../../../components/DatePickers/StartDatePicker';
import { DialogBoxType } from '../../../../components/DialogBox/useDialogBox';
import { grantAccess } from '../../../../redux/actions/keychains';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { PhoneNumberSearch } from './PhoneNumberSearch';
import { Shareable } from './Shareable';
import './grantAccess.scss';

interface Props {
  DialogBox: DialogBoxType;
  keychainFactoryUri: string;
  closeModal: () => void;
}

const FormSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  phoneNumber: z.string().trim(),
  countryCode: z.string(),
  shareable: z.string()
});
type FormSchema = z.infer<typeof FormSchema>;

export const GrantAccess: React.FC<Props> = ({
  DialogBox,
  keychainFactoryUri,
  closeModal
}) => {
  const dispatch = useAppDispatch();
  const { phoneSearchResult } = useAppSelector(state => state.access);
  const name = phoneSearchResult.name;

  const methods = useForm<FormSchema>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      startDate: new Date(),
      endDate: new Date(),
      countryCode: '+47',
      shareable: 'false'
    }
  });
  const { watch, resetField } = methods;
  const { startDate, endDate, phoneNumber } = watch();

  const onButtonClick = (data: FormSchema) =>
    dispatchAndHandleResult(
      dispatch,
      grantAccess,
      {
        ...data,
        countryCode: data.countryCode.replace('+', ''),
        keychainFactoryUri,
        startDate: formatISO(data.startDate, { representation: 'date' }),
        endDate: formatISO(data.endDate, { representation: 'date' }),
        shareable: data.shareable === 'true'
      },
      () => {
        closeModal();
        resetField('phoneNumber');
      }
    );

  return (
    <DialogBox
      buttonLabel='Send tilgang'
      methods={methods}
      onSubmit={onButtonClick}
      header='Gi tilgang'
    >
      <div className='grant-access'>
        <p>Denne personen skal ha tilgang:</p>
        <PhoneNumberSearch
          phoneNumber={phoneNumber}
          setPhoneNumber={(phoneNumber: string) => {
            methods.setValue('phoneNumber', phoneNumber);
          }}
        />
        {name && (
          <div className='grid-container'>
            <StartDatePicker
              name='startDate'
              startDate={startDate}
              endDate={endDate}
              minDate={new Date()}
            />
            <EndDatePicker
              name='endDate'
              startDate={startDate}
              endDate={endDate}
              minDate={
                startDate && new Date(startDate) > new Date()
                  ? new Date(startDate)
                  : new Date()
              }
            />
          </div>
        )}
        {name && <Shareable />}
      </div>
    </DialogBox>
  );
};
