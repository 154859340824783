import { configureStore } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
// import { ENV, REDUX_LOG_ENABLED } from '../constants';
import rootReducer from './slice';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    // if (REDUX_LOG_ENABLED && ENV !== 'prod') {
    //   return new Tuple(logger);
    // }
    return getDefaultMiddleware();
  }
});
export type RootState = ReturnType<typeof rootReducer>;
