import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { KeyId } from '../../../components/Forms/KeyId';
import {
  createOperationBucket,
  UpdateOperationBucketName
} from '../../../redux/actions/buckets';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { BucketOperation } from '../../../redux/slice/buckets';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { BucketName } from './Components/BucketName';
import { SelectOperations } from './Components/SelectOperations';

type FormSchema = UpdateOperationBucketName & {
  selectedOperations: { value: BucketOperation }[];
};

export const CreateOperationBucket: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const operationsList = useAppSelector(state => state.buckets.operationsList);

  const methods = useForm<FormSchema>({
    resolver: zodResolver(UpdateOperationBucketName),
    defaultValues: {
      selectedOperations: []
    }
  });
  const { handleSubmit, getValues } = methods;
  const handleCreateClick = (data: UpdateOperationBucketName) =>
    dispatchAndHandleResult(
      dispatch,
      createOperationBucket,
      {
        ...data,
        operationsList: getValues('selectedOperations').map(
          selectedOperations => selectedOperations.value
        )
      },
      () => {
        navigate('..', { relative: 'path' });
      }
    );

  return (
    <section>
      <ControlpanelBreadcrumbs currentPageName='Lag nytt operasjonsspann' />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleCreateClick)}>
          <BucketName name='name' />
          <KeyId
            name='id'
            otherField='name'
            description='Unik ID til operasjonsspannet'
            label='ID'
            elementProps={{}}
          />
          <SelectOperations operationsList={operationsList} />
          <Button label='Lag nytt operasjonsspann' type='submit' />
        </form>
      </FormProvider>
    </section>
  );
};
