const LOGIN_TIMESTAMP = 'keystudio-login_timestamp';
const KEYCHAIN_TYPE = 'keystudio-keychain_type';
const USE_SAFETYCLOAK = 'use-safetycloak';
const LOGOUT_PARAMS = 'logout';

export const setStartLoginTimestamp = () => {
  sessionStorage.setItem(LOGIN_TIMESTAMP, Date.now().toString());
};

export const setLoginTimestampIfNotSet = () => {
  if (!getLoginTimestamp()) {
    setStartLoginTimestamp();
  }
};
export const getLoginTimestamp = () => {
  const timestamp = sessionStorage.getItem(LOGIN_TIMESTAMP);
  if (timestamp) {
    return new Date(parseInt(timestamp));
  }
};

export const getTimeSinceLoginStarted = () => {
  const timestamp = getLoginTimestamp();
  if (timestamp) {
    return Date.now() - timestamp.getTime();
  }
  return -1;
};
export const deleteLoginTimestamp = () => {
  sessionStorage.removeItem(LOGIN_TIMESTAMP);
};

export const setKeychainType = (type: string) => {
  sessionStorage.setItem(KEYCHAIN_TYPE, type);
};
export const getKeychainType = () => {
  return sessionStorage.getItem(KEYCHAIN_TYPE) || '';
};

export const getUseSafetycloak = () => {
  return localStorage.getItem(USE_SAFETYCLOAK);
};

export const setUseSafetycloak = (value: 'yes') => {
  return localStorage.setItem(USE_SAFETYCLOAK, value);
};

export const removeUseSafetycloak = () => {
  return localStorage.removeItem(USE_SAFETYCLOAK);
};

export type loginType = 'mobile' | 'ask';

export const getLoginMethod = (): loginType => {
  logoutCheck();
  if (location.pathname === '/login') {
    location.pathname = '/';
    removeUseSafetycloak();
  }
  switch (getUseSafetycloak()) {
    case 'yes':
      document.title = 'KeyStudio - Mobilinnlogging';
      return 'mobile';
    default:
      document.title = 'KeyStudio - Logg inn';
      return 'ask';
  }
};

export const logoutCheck = () => {
  const currentUrl = new URL(location.href);
  const urlParams = new URLSearchParams(currentUrl.search);
  const logout = urlParams.get(LOGOUT_PARAMS);
  if (logout) {
    removeUseSafetycloak();
    deleteLoginTimestamp();
    urlParams.delete(LOGOUT_PARAMS);
    currentUrl.search = urlParams.toString();
    window.history.replaceState(null, '', currentUrl);
  }
};
