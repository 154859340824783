import React from 'react';
import cn from 'classnames';
import './Table.scss';

export type HeadingProps<T> = {
  element: string;
  sortKey?: keyof T;
};

type TableHeaderProps<T> = {
  headings: HeadingProps<T>[];
  sortKey: '' | keyof T;
  setSortKey: (key: keyof T) => void;
  hasRowActions: boolean;
  sortReversed: boolean;
};

export const TableHeader = <T,>({
  headings,
  sortKey,
  setSortKey,
  hasRowActions,
  sortReversed
}: TableHeaderProps<T>) => {
  return (
    <thead className='keystudio-table-thead'>
      <tr className='keystudio-table-tr'>
        {headings.map((heading, index) => (
          <th
            key={index}
            className={cn('keystudio-table-th', {
              sortable: !!heading.sortKey,
              sorted: sortKey === heading.sortKey,
              'sort-reversed': sortKey === heading.sortKey && sortReversed
            })}
            onClick={() => {
              heading.sortKey && setSortKey(heading.sortKey);
            }}
          >
            <span>{heading.element}</span>
          </th>
        ))}
        {hasRowActions && <th key='actions' className='keystudio-table-th' />}
      </tr>
    </thead>
  );
};
