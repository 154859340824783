import React from 'react';

export const Last30Days: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='40'
    viewBox='0 0 35 40'
  >
    <g
      id='Group_12332'
      data-name='Group 12332'
      transform='translate(-64 -1304)'
    >
      <g
        id='Ellipse_778'
        data-name='Ellipse 778'
        transform='translate(64 1304)'
        fill='#fff'
        stroke='#292858'
        strokeWidth='1'
      ></g>
      <g id='noun-cobweb-1383376' transform='translate(-22.228 1272.104)'>
        <path
          id='Path_11315'
          data-name='Path 11315'
          d='M119.539,51.187l-4.043.241-4.887-6.758,2.745-3.952a.305.305,0,1,0-.513-.332L110.1,44.339l-4.948-.935V38.939a.3.3,0,1,0-.6,0v4.5l-6.185,1.9-2.926-3.047a.3.3,0,0,0-.422.422L98,45.757l-1.3,8.96L92.81,55.984a.3.3,0,0,0-.181.392.281.281,0,0,0,.392.181l3.8-1.237,3.62,5.219-1.659,3.319a.288.288,0,0,0,.121.392.181.181,0,0,0,.121.03.317.317,0,0,0,.271-.151l1.629-3.2,3.651,1.569v2.353a.3.3,0,0,0,.6,0V62.591l7.3-1.629.845.905a.3.3,0,0,0,.422-.422l-.845-.905,2.655-8.508,4.012-.241a.272.272,0,0,0,.271-.332.3.3,0,0,0-.3-.271Zm-4.767.3-1.81.121-3.59-5.129.905-1.3Zm-8.206-1,.6,1.478-1.689.09Zm-1.388.935V50.161h.875Zm-.6.241-1.448-1.508h1.448Zm-.271.6-1.629.513V50.614Zm-.03.633-.784,1.539-.573-1.116Zm.3.754v1.177H104Zm.6-.483,1.75,1.87-1.75-.181Zm.362-.513,1.84-.121v2.112Zm2.263-.754-.845-2.021.483-.694,1.81,2.625Zm-1.327-2.353h-1.3V48.291l1.81.543Zm-1.9,0h-2.051l-.664-.694,2.715-.6Zm-2.474.422V53l-1.659.513.965-4.223Zm.241,3.56.815,1.6-.815,1.6-1.81-2.594Zm1.327,1.9h.905v1.87l-1.72-.241Zm1.508.03,2.383.241,1.569,1.659h-3.922v-1.9Zm2.806-.181V52.514l1.508-.09v4.5Zm1.961-3.5L107.8,48.683l1.177-1.689,3.258,4.646Zm-2.625-3.469-2.172-.664V46.028l3.319.634Zm-2.745-.664-3.228.694-.875-.905,4.1-1.388Zm-3.65,1.116-1.116,4.948-.905.3,1.207-6.064Zm-1,5.551,2.112,3.017-.573,1.116-2.534-3.831Zm2.625,3.319,1.991.271v1.3l-2.594-.392Zm2.625.332h4.5l1.207,1.3h-5.7Zm4.917-.422V52.394l2.353-.151-1.086,6.667Zm-.362-12.671-.875,1.267-3.681-.724V44.037Zm-5.159-.814v1.388l-4.555,1.539-1.146-1.177ZM98.511,46.33,99.6,47.446,98.24,54.264l-.905.3Zm-1.086,8.779.935-.3,2.806,4.254-.422.845Zm3.771,5.249.483-.965,2.9.422v2.021Zm3.982,1.6V59.876h6.275l.543.573Zm7.24-1.9-.543-.573,1.177-7.271,1.84-.121Z'
          fill='#292858'
        />
      </g>
    </g>
  </svg>
);
