import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
  EditSvg,
  MinusCircleSvg,
  PlusCircleSvg,
  SadSvg
} from '../../assets/button';
import './Button.scss';

export interface ButtonProps {
  /**
   * What type of button is this?
   */
  type?:
    | 'submit'
    | 'primary'
    | 'secondary'
    | 'destructive'
    | 'edit'
    | 'selected'
    | 'unselected'
    | 'disabled'
    | 'add'
    | 'remove';

  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'dialog';

  /**
   * Button contents
   */
  label: string;

  tooltip?: string;

  /**
   * Optional, wraps the button in a link
   */
  linkTo?: string;

  /**
   * Optional click handler
   */
  onClick?: () => void;

  /**
   * Optional classes
   */
  className?: string;
  /**
   * Optional shortcut-key
   */
  accessKey?: string;
}

export const Button = ({
  type = 'primary',
  size = 'medium',
  label,
  tooltip,
  onClick,
  className,
  accessKey,
  linkTo
}: ButtonProps) => {
  const mode = `keystudio-button--${type}`;

  const renderIconOrLabel = (type: string) => {
    if (type === 'edit') {
      return <EditSvg />;
    } else if (type === 'sad') {
      return <SadSvg />;
    } else if (type === 'add') {
      return <PlusCircleSvg />;
    } else if (type === 'remove') {
      return <MinusCircleSvg />;
    }
    return label;
  };

  const allClasses = classNames(
    'keystudio-button',
    `keystudio-button--${size}`,
    mode,
    className
  );
  const button = (
    <button
      className={allClasses}
      title={tooltip}
      onClick={() => {
        if (type !== 'disabled' && onClick) onClick();
      }}
      accessKey={accessKey}
      type={['submit', 'destructive'].includes(type) ? 'submit' : 'button'}
    >
      {renderIconOrLabel(type)}
    </button>
  );
  if (linkTo) {
    return <Link to={linkTo}>{button}</Link>;
  }
  return button;
};
