import React, { MouseEvent } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { setPopup } from '../../redux/slice/popup';
import './CopyWrapper.scss';

interface CopyWrapperProps {
  copyValue?: string;
  children?: React.ReactNode;
}

export const CopyWrapper: React.FC<CopyWrapperProps> = ({
  copyValue,
  children
}) => {
  const dispatch = useAppDispatch();

  const copy = (e: MouseEvent) => {
    e.preventDefault();

    if (copyValue) {
      navigator.clipboard.writeText(copyValue).then(() => {
        dispatch(
          setPopup({
            error: false,
            message:
              'Kopiert til utklippstavla! Pass på kildehenvisningen om du skriver master...'
          })
        );
      });
    }

    return false;
  };

  if (!children) {
    children = copyValue;
  }

  return (
    <div className='copy-wrapper' data-testid='copy-wrapper' onClick={copy}>
      {children}
      <button type='button' title='Kopier til clipboard'>
        <img src='/copy-icon.svg' alt='Kopier' />
      </button>
    </div>
  );
};
