import React, { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteAndConfirmMenu } from '../../../components/DeleteAndConfirm/DeleteAndConfirm';
import { PreContainer } from '../../../components/code/PreContainer';
import { deleteOperation } from '../../../redux/actions/operations';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { subjectSelectors } from '../../../redux/slice/subjects';
import { ControlpanelSubjectBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { LinkedActionChain } from './LinkedActionChain';
import { OperationType } from './OperationType';
import { Pvd } from './Pvd';

interface MatchParams {
  subjectId: string;
  operationId: string;
}

export const AdminOperation: React.FC = () => {
  const navigate = useNavigate();
  const { subjectId = '', operationId = '' } = useParams<keyof MatchParams>();
  const subject = useAppSelector(state =>
    subjectSelectors.selectById(state, subjectId)
  );
  const operation = subject?.operationsList.find(
    operation => operation.id === operationId
  );
  const dispatch = useAppDispatch();

  return (
    <Fragment>
      <ControlpanelSubjectBreadcrumbs
        currentPageName={operation?.name || 'Ukjent operasjon'}
        parentName={subject?.name || 'Ukjent sted'}
      />
      {subject && operation ? (
        <Fragment>
          <DeleteAndConfirmMenu
            label='Slett operasjon'
            message='Er du sikker på at du vil slette denne operasjonen?'
            deleteAction={() =>
              dispatchAndHandleResult(
                dispatch,
                deleteOperation,
                { subjectId: subject.id, operationId: operation.id },
                () => {
                  navigate('..', { relative: 'path' });
                }
              )
            }
          />
          <OperationType
            operationId={operation.id}
            subjectId={subject.id}
            operationTypeInitial={operation.operationCategory}
          />
          <LinkedActionChain
            actionChainId={operation.actionChainId}
            operationId={operation.id}
            subjectId={subject.id}
          />
          <Pvd operation={operation} subjectId={subject.id} />
          <h2>Operasjonens JSON-data</h2>
          <PreContainer obj={operation} />
        </Fragment>
      ) : (
        <div>Finner ikke operasjonen med ID: {operationId}</div>
      )}
    </Fragment>
  );
};
