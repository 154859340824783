import React from 'react';
import { ActionItem } from '../../../../redux/slice/adapters';
import { ActionListItem } from './ActionListItem';

interface Props {
  actionList: ActionItem[];
  setActionList: (list: ActionItem[]) => void;
}
export const ActionList: React.FC<Props> = ({ actionList, setActionList }) => {
  const setSendFodselsnummer = (index: number) => {
    const list = [...actionList];
    list[index] = {
      ...list[index],
      sendFodselsnummer: !list[index].sendFodselsnummer
    };
    setActionList(list);
  };

  const moveUp = (index: number) => {
    const list = [...actionList];
    const temp = list[index];
    list[index] = list[index - 1];
    list[index - 1] = temp;
    setActionList(list);
  };

  const moveDown = (index: number) => {
    const list = [...actionList];
    const temp = list[index];
    list[index] = list[index + 1];
    list[index + 1] = temp;
    setActionList(list);
  };

  const deleteAction = (index: number) => {
    const list = [...actionList];
    list.splice(index, 1);
    setActionList(list);
  };

  return (
    <div className='action-list'>
      {actionList.map((action, index) => {
        return (
          <ActionListItem
            action={action}
            index={index}
            key={action.actionUri}
            moveUp={moveUp}
            moveDown={moveDown}
            deleteAction={deleteAction}
            setSendFodselsnummer={setSendFodselsnummer}
            last={index === actionList.length - 1}
          />
        );
      })}
    </div>
  );
};
