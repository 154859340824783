import { z } from 'zod';
import {
  CreateGroupLinkRequest,
  GroupLinkListResponse,
  ListGroupLinksRequest
} from '../../proto/keychain-factory_pb';
import {
  getGrpcMetadata,
  getKeychainFactoryClient,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { dateToISO8601DateTimeInterval } from '../../utils/timeConverter';
import { createAppAsyncThunk } from '../hooks';
import { fullOperationId } from '../slice/adapters';
import { flattenInterval, Interval } from '../types/common';
import { description, noValidationString, title } from '../types/zodSchemas';
import { createFullOperationId, createText } from './util';

export type GroupLink = {
  code: string;
  publicDescription: string;
  activeInterval: Interval;
  keychainFactoryUri: string;
  operationId: string;
  subjectId: string;
  title: string;
  publicUri: string;
};

export const fetchListGroupLinks = createAppAsyncThunk<GroupLink[]>(
  'groupLinks/listGroupLinks',
  async (_, thunkAPI) => {
    try {
      const response = await getKeychainFactoryClient().listGroupLinks(
        new ListGroupLinksRequest(),
        await getGrpcMetadata()
      );
      return response.toObject().groupLinksList.map(convertToGroupLink);
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const CreateGroupLink = z.object({
  title: title,
  publicDescription: description,
  fromDate: z.date(),
  untilDate: z.date(),
  keychainFactoryUri: noValidationString,
  fullOperationId: z.object({
    value: z.object({
      operationId: z.string(),
      subjectId: z.string()
    }),
    label: z.string()
  })
});

export type CreateGroupLink = z.infer<typeof CreateGroupLink>;

export const createGroupLink = createAppAsyncThunk<GroupLink, CreateGroupLink>(
  'groupLinks/createGroupLink',
  async (groupLink, thunkAPI) => {
    const request = new CreateGroupLinkRequest()
      .setTitle(groupLink.title)
      .setPublicDescription(createText(groupLink.publicDescription))
      .setKeychainFactoryUri(groupLink.keychainFactoryUri)
      .setOperationId(createFullOperationId(groupLink.fullOperationId.value))
      .setActiveInterval(
        dateToISO8601DateTimeInterval({
          startDate: groupLink.fromDate,
          endDate: groupLink.untilDate
        })
      );
    try {
      const response = await getKeychainFactoryClient().createGroupLink(
        request,
        await getGrpcMetadata()
      );
      return convertToGroupLink({
        ...request.toObject(),
        ...response.toObject()
      });
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

const convertToGroupLink = (
  groupLink: GroupLinkListResponse.GroupLink.AsObject
): GroupLink => {
  return {
    ...groupLink,
    ...fullOperationId.parse(groupLink.operationId),
    publicDescription: groupLink.publicDescription?.content || '',
    activeInterval: flattenInterval(groupLink.activeInterval)
  };
};
