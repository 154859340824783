import React from 'react';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { CreateNewOidcKeychain } from './CreateNewOidcKeychain';

export const CreateOidcKeychainFactory = () => {
  return (
    <section>
      <ControlpanelBreadcrumbs currentPageName='Lag ny web login (oidc)' />
      <CreateNewOidcKeychain />
    </section>
  );
};
