import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { KeychainFactoriesConfigurationsResponse } from '../../proto/keychain-factory_pb';
import { changeKeychainFactoryAdminRole } from '../actions/keychainFactoryDetails';
import { listKeychainFactoryConfigurations } from '../actions/keychainFactorySummaries';
import { RootState } from '../index';

export interface PublicKeychainFactorySummary
  extends CommonKeychainFactorySummary {
  type: 'public';
  publicName: string;
}

export interface ManualGrantKeychainFactorySummary
  extends CommonKeychainFactorySummary {
  type: 'manual-grant';
  publicName: string;
}

export interface PushKeychainFactorySummary
  extends CommonKeychainFactorySummary {
  type: 'push';
  pendingRecipientCount: number;
  thirdPartySystemId: string;
  thirdPartySystemName: string;
}

export interface OidcKeychainFactorySummary
  extends CommonKeychainFactorySummary {
  type: 'oidc';
}

/**
 * Unknown type is reserved for future use, so it can be displayed without breaking frontend.
 */
export interface UnknownKeychainFactorySummary
  extends CommonKeychainFactorySummary {
  type: 'unknown';
}
interface CommonKeychainFactorySummary {
  keychainFactoryUri: string;
  version: number;
  name: string;
  ownerRole: string;
  image: string;
  linkedSubjects: LinkedSubject[];
  isDetails: false;
}

export type KeychainFactorySummary =
  | PublicKeychainFactorySummary
  | ManualGrantKeychainFactorySummary
  | PushKeychainFactorySummary
  | OidcKeychainFactorySummary
  | UnknownKeychainFactorySummary;

interface LinkedSubject {
  subjectId: string;
  subjectName: string;
}

const keychainFactoryAdapter = createEntityAdapter<
  KeychainFactorySummary,
  string
>({
  selectId: factory => factory.keychainFactoryUri,
  sortComparer: (a, b) => {
    return a.name.localeCompare(b.name);
  }
});
const keychainFactoriesSlice = createSlice({
  name: 'keychainFactorySummaries',
  initialState: keychainFactoryAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(
        listKeychainFactoryConfigurations.fulfilled,
        (state, { payload }) => {
          keychainFactoryAdapter.setAll(state, payload.map(factoryToModel));
        }
      )
      .addCase(
        changeKeychainFactoryAdminRole.fulfilled,
        (state, { payload }) => {
          keychainFactoryAdapter.updateOne(state, {
            id: payload.keychainFactoryUri,
            changes: {
              ownerRole: payload.newOwnerRole
            }
          });
        }
      );
  }
});

export const initialState = keychainFactoriesSlice.getInitialState();
export type KeychainFactoriySummariesState = typeof initialState;
export const selectKeychainFactories =
  keychainFactoryAdapter.getSelectors<RootState>(
    state => state.keychainFactorySummaries
  );
export default keychainFactoriesSlice.reducer;

const factoryToModel = (
  factory: KeychainFactoriesConfigurationsResponse.Summary.AsObject
): KeychainFactorySummary => {
  const common = {
    ...factory,
    isDetails: false,
    image: factory.image?.uri || '',
    linkedSubjects: factory.linkedSubjectsList.map(linkedSubjectToModel)
  } as const;
  if (factory.manualGrantKeychainFactory) {
    return {
      ...common,
      type: 'manual-grant',
      ...manualGrantFactoryToModel(factory.manualGrantKeychainFactory)
    };
  }
  if (factory.pushKeychainFactory) {
    return {
      ...common,
      type: 'push',
      ...pushFactoryToModel(factory.pushKeychainFactory)
    };
  }
  if (factory.oidcKeychainFactory) {
    return {
      ...common,
      type: 'oidc',
      ...factory.oidcKeychainFactory
    };
  }
  if (factory.publicKeychainFactory) {
    return {
      ...common,
      type: 'public',
      ...factory.publicKeychainFactory
    };
  }
  console.error('Unknown keychainfactory type', factory);
  return { ...common, type: 'unknown' };
};

const linkedSubjectToModel = (
  linkedSubject: KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.AsObject
): LinkedSubject => ({
  subjectId: linkedSubject.subjectId,
  subjectName: linkedSubject.subjectName
});

const manualGrantFactoryToModel = (
  factory: KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.AsObject
) => ({
  publicName: factory.publicName
});

const pushFactoryToModel = (
  factory: KeychainFactoriesConfigurationsResponse.PushKeychainFactory.AsObject
) => ({
  pendingRecipientCount: factory.pendingRecipientCount,
  thirdPartySystemId: factory.thirdPartySystemId,
  thirdPartySystemName: factory.thirdPartySystemName
});
