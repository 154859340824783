import React from 'react';
import { Link } from 'react-router-dom';
import './ControlPanelCard.scss';

interface Props {
  Image?: React.FC;
  name: string;
  path: string;
}

export const ControlPanelCard: React.FC<Props> = ({ Image, name, path }) => {
  return (
    <div className='controlpanel-card-container'>
      <Link to={path}>
        <div className='icon-container'>{Image ? <Image /> : ''}</div>
        <div className='text-container'>{name}</div>
      </Link>
    </div>
  );
};
