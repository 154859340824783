import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogBoxType } from '../../../../components/DialogBox/useDialogBox';
import { KeyText } from '../../../../components/Forms/KeyText';
import {
  SetEmailProps,
  setUserEmail
} from '../../../../redux/actions/keychains';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { Keychain } from '../../../../redux/slice/keychains';

interface Props {
  DialogBox: DialogBoxType;
  keychain?: Keychain;
  closeModal: () => void;
}

export const EditEmail: React.FC<Props> = ({
  DialogBox,
  keychain,
  closeModal
}) => {
  const methods = useForm<SetEmailProps>({
    resolver: zodResolver(SetEmailProps),
    values: {
      email: keychain?.emailAddress || '',
      keychainUri: keychain?.id || ''
    }
  });
  const dispatch = useAppDispatch();

  const onSubmit = (data: SetEmailProps) =>
    dispatchAndHandleResult(dispatch, setUserEmail, data, closeModal);

  return (
    <DialogBox
      methods={methods}
      onSubmit={onSubmit}
      buttonLabel='Oppdater'
      header={keychain?.personName}
    >
      <KeyText
        name='email'
        elementProps={{
          type: 'email'
        }}
        label='E-post'
        description='Endre e-postadressen til brukeren'
      />
    </DialogBox>
  );
};
