import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Confirm } from '../../Confirm';
import { OperationContainer } from '../../OperationContainer/OperationContainer';
import { useOperation } from '../OperationWrapper';
import { EditAgeLimit } from '../agelimit/EditAgeLimit';
import { AuditPage } from '../audit/AuditPage';
import { EditKeypediaOperation } from '../keypedia/EditKeypediaOperation';
import { EditOperationTerms } from '../terms/edit/EditOperationTerms';
import { EditAbout } from './EditAbout';
import { EditMetersToExecute } from './EditMetersToExecute';
import { EditOperationBreadcrumbs } from './EditOperationBreadcrumbs';
import { EditOpeningHours } from './openingHours/EditOpeningHours';

interface MatchParams {
  editId: string;
}

export const EditOperation: React.FC = () => {
  const [showNavigateConfirm, setShowNaviateConfirm] = useState(false);
  const navigate = useNavigate();
  const { editId = '' } = useParams<keyof MatchParams>();

  const { subject, operation } = useOperation();

  return (
    <OperationContainer>
      <Confirm
        title='Forlate siden'
        message='Du har ulagrede endringer, er du sikker på at du vil forlate siden?'
        isModalOpen={showNavigateConfirm}
        closeModal={() => setShowNaviateConfirm(false)}
        doSomething={() => {
          navigate(-1);
        }}
      />

      <EditOperationBreadcrumbs
        subject={subject}
        operation={operation}
        editId={editId}
      />

      {editId === 'about' && (
        <EditAbout
          subjectId={subject.id}
          operation={operation}
          setShowNavigateConfirm={setShowNaviateConfirm}
        />
      )}
      {editId === 'gps-radius' && (
        <EditMetersToExecute
          subjectId={subject.id}
          operation={operation}
          setShowNavigateConfirm={setShowNaviateConfirm}
        />
      )}
      {editId === 'vilkaar' && (
        <EditOperationTerms subjectId={subject.id} operation={operation} />
      )}
      {editId === 'wiki' && <EditKeypediaOperation operation={operation} />}
      {editId === 'agelimit' && (
        <EditAgeLimit
          subjectId={subject.id}
          operation={operation}
          setShowNavigateConfirm={setShowNaviateConfirm}
        />
      )}
      {editId === 'åpningstider' && (
        <EditOpeningHours
          keychainFactoryList={operation.keychainFactoriesGrantingAccessList}
        />
      )}
      {editId === 'auditlog' && <AuditPage citykeyId={operation.citykeyId} />}
    </OperationContainer>
  );
};
