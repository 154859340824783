import React from 'react';
import './OperationContainer.scss';

interface OperationContainerProps {
  children: React.ReactNode;
}

export const OperationContainer: React.FC<OperationContainerProps> = ({
  children
}) => <section className='operation'>{children}</section>;
