import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EditableValue } from '../../../components/EditableValue/EditableValue';
import {
  AdminRoleSelector,
  createRoleOption
} from '../../../components/Forms/AdminRoleSelector';
import {
  ChangeSubjectAdminRole,
  changeSubjectAdminRole
} from '../../../redux/actions/subjects';
import { useAppDispatch } from '../../../redux/hooks';
import { getKeystudioLink } from '../../../utils/citykeyId';
import { useAdminRole } from '../../../utils/roles/useAdminRole';

type Props = {
  subjectId: string;
  roleId: string;
};
export const AdminRole: React.FC<Props> = ({ subjectId, roleId }) => {
  const dispatch = useAppDispatch();
  const role = useAdminRole(roleId);
  const methods = useForm<ChangeSubjectAdminRole>({
    resolver: zodResolver(ChangeSubjectAdminRole),
    defaultValues: {
      subjectId: subjectId
    }
  });
  useEffect(() => {
    if (role) {
      methods.setValue('newAdmin', createRoleOption(role));
    }
  }, [role]);
  const saveRole = (data: ChangeSubjectAdminRole) => {
    dispatch(changeSubjectAdminRole(data));
  };

  return (
    <EditableValue
      methods={methods}
      link={getKeystudioLink(role?.uri || '')}
      label='Adminrolle'
      value={role?.name || roleId}
      mainButtonClick={saveRole}
    >
      <AdminRoleSelector
        name='newAdmin'
        description='Adminbrukere må ha denne rollen for å administrere stedet.'
      />
    </EditableValue>
  );
};
