// source: keychain.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
var keychain$shared_pb = require('./keychain-shared_pb.js');
goog.object.extend(proto, keychain$shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.DetailsCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.TypeCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainStatus', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.GrantedByCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.toObject = function(includeInstance, msg) {
  var f, obj = {
    policiesList: jspb.Message.toObjectList(msg.getPoliciesList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.deserializeBinaryFromReader);
      msg.addPolicies(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.serializeBinaryToWriter
    );
  }
};


/**
 * repeated KeychainAdminPolicy policies = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.prototype.getPoliciesList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.prototype.setPoliciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.prototype.addPolicies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.prototype.clearPoliciesList = function() {
  return this.setPoliciesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    removableRolesList: jspb.Message.toObjectList(msg.getRemovableRolesList(),
    keychain$shared_pb.OidcRoleDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new keychain$shared_pb.OidcRoleDefinition;
      reader.readMessage(value,keychain$shared_pb.OidcRoleDefinition.deserializeBinaryFromReader);
      msg.addRemovableRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemovableRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      keychain$shared_pb.OidcRoleDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated oslonokkelen.proto.shared.keychain.OidcRoleDefinition removable_roles = 1;
 * @return {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.prototype.getRemovableRolesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, keychain$shared_pb.OidcRoleDefinition, 1));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.prototype.setRemovableRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.prototype.addRemovableRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.prototype.clearRemovableRolesList = function() {
  return this.setRemovableRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableRolesList: jspb.Message.toObjectList(msg.getAvailableRolesList(),
    keychain$shared_pb.OidcRoleDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new keychain$shared_pb.OidcRoleDefinition;
      reader.readMessage(value,keychain$shared_pb.OidcRoleDefinition.deserializeBinaryFromReader);
      msg.addAvailableRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      keychain$shared_pb.OidcRoleDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated oslonokkelen.proto.shared.keychain.OidcRoleDefinition available_roles = 2;
 * @return {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.prototype.getAvailableRolesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, keychain$shared_pb.OidcRoleDefinition, 2));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.prototype.setAvailableRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.prototype.addAvailableRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.prototype.clearAvailableRolesList = function() {
  return this.setAvailableRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    availableRolesList: jspb.Message.toObjectList(msg.getAvailableRolesList(),
    keychain$shared_pb.OidcRoleDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new keychain$shared_pb.OidcRoleDefinition;
      reader.readMessage(value,keychain$shared_pb.OidcRoleDefinition.deserializeBinaryFromReader);
      msg.addAvailableRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAvailableRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      keychain$shared_pb.OidcRoleDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated oslonokkelen.proto.shared.keychain.OidcRoleDefinition available_roles = 2;
 * @return {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.prototype.getAvailableRolesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, keychain$shared_pb.OidcRoleDefinition, 2));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.prototype.setAvailableRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.prototype.addAvailableRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.prototype.clearAvailableRolesList = function() {
  return this.setAvailableRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.TypeCase = {
  TYPE_NOT_SET: 0,
  REMOVE_ROLE: 1,
  ADD_ROLE: 2,
  REPLACE_ROLES: 3,
  SET_EMAIL: 4,
  EXTEND: 5,
  REVOKE: 6,
  SET_SHAREABLE: 7
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.TypeCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.getTypeCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.TypeCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    removeRole: (f = msg.getRemoveRole()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.toObject(includeInstance, f),
    addRole: (f = msg.getAddRole()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.toObject(includeInstance, f),
    replaceRoles: (f = msg.getReplaceRoles()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.toObject(includeInstance, f),
    setEmail: (f = msg.getSetEmail()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.toObject(includeInstance, f),
    extend: (f = msg.getExtend()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.toObject(includeInstance, f),
    revoke: (f = msg.getRevoke()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.toObject(includeInstance, f),
    setShareable: (f = msg.getSetShareable()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.deserializeBinaryFromReader);
      msg.setRemoveRole(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.deserializeBinaryFromReader);
      msg.setAddRole(value);
      break;
    case 3:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.deserializeBinaryFromReader);
      msg.setReplaceRoles(value);
      break;
    case 4:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.deserializeBinaryFromReader);
      msg.setSetEmail(value);
      break;
    case 5:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.deserializeBinaryFromReader);
      msg.setExtend(value);
      break;
    case 6:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.deserializeBinaryFromReader);
      msg.setRevoke(value);
      break;
    case 7:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.deserializeBinaryFromReader);
      msg.setSetShareable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRemoveRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy.serializeBinaryToWriter
    );
  }
  f = message.getAddRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy.serializeBinaryToWriter
    );
  }
  f = message.getReplaceRoles();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy.serializeBinaryToWriter
    );
  }
  f = message.getSetEmail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy.serializeBinaryToWriter
    );
  }
  f = message.getExtend();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy.serializeBinaryToWriter
    );
  }
  f = message.getRevoke();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy.serializeBinaryToWriter
    );
  }
  f = message.getSetShareable();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy.serializeBinaryToWriter
    );
  }
};


/**
 * optional RemoveRolePolicy remove_role = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.getRemoveRole = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.RemoveRolePolicy|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.setRemoveRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.clearRemoveRole = function() {
  return this.setRemoveRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.hasRemoveRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AddRolePolicy add_role = 2;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.getAddRole = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy, 2));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.AddRolePolicy|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.setAddRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.clearAddRole = function() {
  return this.setAddRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.hasAddRole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ReplaceRolesPolicy replace_roles = 3;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.getReplaceRoles = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy, 3));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.ReplaceRolesPolicy|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.setReplaceRoles = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.clearReplaceRoles = function() {
  return this.setReplaceRoles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.hasReplaceRoles = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SetEmailPolicy set_email = 4;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.getSetEmail = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy, 4));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.SetEmailPolicy|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.setSetEmail = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.clearSetEmail = function() {
  return this.setSetEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.hasSetEmail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ExtendPolicy extend = 5;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.getExtend = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy, 5));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendPolicy|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.setExtend = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.clearExtend = function() {
  return this.setExtend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.hasExtend = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RevokePolicy revoke = 6;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.getRevoke = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy, 6));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokePolicy|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.setRevoke = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.clearRevoke = function() {
  return this.setRevoke(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.hasRevoke = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SetShareablePolicy set_shareable = 7;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.getSetShareable = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy, 7));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.SetShareablePolicy|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.setSetShareable = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.clearSetShareable = function() {
  return this.setSetShareable(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicy.prototype.hasSetShareable = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainsList: jspb.Message.toObjectList(msg.getKeychainsList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.toObject, includeInstance),
    unclaimedKeychainsList: jspb.Message.toObjectList(msg.getUnclaimedKeychainsList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.deserializeBinaryFromReader);
      msg.addKeychains(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.deserializeBinaryFromReader);
      msg.addUnclaimedKeychains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.serializeBinaryToWriter
    );
  }
  f = message.getUnclaimedKeychainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    personname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profileid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    policies: (f = msg.getPolicies()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.toObject(includeInstance, f),
    activeinterval: (f = msg.getActiveinterval()) && shared_pb.LocalDateTimeInterval.toObject(includeInstance, f),
    usageCounter: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lastUsedAtEpochSeconds: jspb.Message.getFieldWithDefault(msg, 7, 0),
    shareable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    grantedBy: (f = msg.getGrantedBy()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 5:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.deserializeBinaryFromReader);
      msg.setPolicies(value);
      break;
    case 10:
      var value = new shared_pb.LocalDateTimeInterval;
      reader.readMessage(value,shared_pb.LocalDateTimeInterval.deserializeBinaryFromReader);
      msg.setActiveinterval(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsageCounter(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastUsedAtEpochSeconds(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShareable(value);
      break;
    case 9:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.deserializeBinaryFromReader);
      msg.setGrantedBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPersonname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfileid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPolicies();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.serializeBinaryToWriter
    );
  }
  f = message.getActiveinterval();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      shared_pb.LocalDateTimeInterval.serializeBinaryToWriter
    );
  }
  f = message.getUsageCounter();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLastUsedAtEpochSeconds();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getShareable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getGrantedBy();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.GrantedByCase = {
  GRANTED_BY_NOT_SET: 0,
  USER: 1,
  ADMIN: 2
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.GrantedByCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.getGrantedByCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.GrantedByCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.toObject(includeInstance, f),
    admin: (f = msg.getAdmin()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.deserializeBinaryFromReader);
      msg.setAdmin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.serializeBinaryToWriter
    );
  }
  f = message.getAdmin();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    keychainId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKeychainId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string keychain_id = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.prototype.getKeychainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User.prototype.setKeychainId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.getUser = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.User|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.setUser = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Admin admin = 2;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.getAdmin = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin, 2));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.Admin|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.setAdmin = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.clearAdmin = function() {
  return this.setAdmin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy.prototype.hasAdmin = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional KeychainStatus status = 2;
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainStatus}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.getStatus = function() {
  return /** @type {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainStatus} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string personName = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.getPersonname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.setPersonname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profileId = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.setProfileid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional KeychainAdminPolicies policies = 5;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.getPolicies = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies, 5));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.setPolicies = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.clearPolicies = function() {
  return this.setPolicies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.hasPolicies = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional oslonokkelen.proto.shared.LocalDateTimeInterval activeInterval = 10;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.getActiveinterval = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTimeInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateTimeInterval, 10));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.setActiveinterval = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.clearActiveinterval = function() {
  return this.setActiveinterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.hasActiveinterval = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 usage_counter = 6;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.getUsageCounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.setUsageCounter = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 last_used_at_epoch_seconds = 7;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.getLastUsedAtEpochSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.setLastUsedAtEpochSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool shareable = 8;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.getShareable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.setShareable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional GrantedBy granted_by = 9;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.getGrantedBy = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy, 9));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.GrantedBy|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.setGrantedBy = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.clearGrantedBy = function() {
  return this.setGrantedBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain.prototype.hasGrantedBy = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated Keychain keychains = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.prototype.getKeychainsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.prototype.setKeychainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.prototype.addKeychains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.prototype.clearKeychainsList = function() {
  return this.setKeychainsList([]);
};


/**
 * repeated Keychain unclaimed_keychains = 2;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.prototype.getUnclaimedKeychainsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain, 2));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.prototype.setUnclaimedKeychainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.prototype.addUnclaimedKeychains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.Keychain, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ListKeychainsResponse.prototype.clearUnclaimedKeychainsList = function() {
  return this.setUnclaimedKeychainsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiredInsteadOfDelete: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExpiredInsteadOfDelete(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiredInsteadOfDelete();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string keychain_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.prototype.getKeychainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.prototype.setKeychainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool expired_instead_of_delete = 3;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.prototype.getExpiredInsteadOfDelete = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.RevokeKeychainRequest.prototype.setExpiredInsteadOfDelete = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newExpireDate: (f = msg.getNewExpireDate()) && shared_pb.LocalDate.toObject(includeInstance, f),
    approvalMessage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    shareable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainId(value);
      break;
    case 2:
      var value = new shared_pb.LocalDate;
      reader.readMessage(value,shared_pb.LocalDate.deserializeBinaryFromReader);
      msg.setNewExpireDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovalMessage(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShareable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewExpireDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.LocalDate.serializeBinaryToWriter
    );
  }
  f = message.getApprovalMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getShareable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string keychain_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.getKeychainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.setKeychainId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.LocalDate new_expire_date = 2;
 * @return {?proto.oslonokkelen.proto.shared.LocalDate}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.getNewExpireDate = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDate} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDate, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDate|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.setNewExpireDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.clearNewExpireDate = function() {
  return this.setNewExpireDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.hasNewExpireDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string approval_message = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.getApprovalMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.setApprovalMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool shareable = 4;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.getShareable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendKeychainExpireDateRequest.prototype.setShareable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string keychain_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.prototype.getKeychainUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainRequest.prototype.setKeychainUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.oneofGroups_ = [[20,21,22]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.DetailsCase = {
  DETAILS_NOT_SET: 0,
  OIDC: 20,
  MANUAL_GRANT: 21,
  PUSH: 22
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.DetailsCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.getDetailsCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.DetailsCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    policies: (f = msg.getPolicies()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.toObject(includeInstance, f),
    oidc: (f = msg.getOidc()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.toObject(includeInstance, f),
    manualGrant: (f = msg.getManualGrant()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.toObject(includeInstance, f),
    push: (f = msg.getPush()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 3:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.deserializeBinaryFromReader);
      msg.setPolicies(value);
      break;
    case 20:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.deserializeBinaryFromReader);
      msg.setOidc(value);
      break;
    case 21:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.deserializeBinaryFromReader);
      msg.setManualGrant(value);
      break;
    case 22:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.deserializeBinaryFromReader);
      msg.setPush(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPolicies();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies.serializeBinaryToWriter
    );
  }
  f = message.getOidc();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.serializeBinaryToWriter
    );
  }
  f = message.getManualGrant();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.serializeBinaryToWriter
    );
  }
  f = message.getPush();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolesList: jspb.Message.toObjectList(msg.getRolesList(),
    keychain$shared_pb.OidcRoleDefinition.toObject, includeInstance),
    sessionsList: jspb.Message.toObjectList(msg.getSessionsList(),
    keychain$shared_pb.OidcRootSession.toObject, includeInstance),
    emailAddress: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new keychain$shared_pb.OidcRoleDefinition;
      reader.readMessage(value,keychain$shared_pb.OidcRoleDefinition.deserializeBinaryFromReader);
      msg.addRoles(value);
      break;
    case 2:
      var value = new keychain$shared_pb.OidcRootSession;
      reader.readMessage(value,keychain$shared_pb.OidcRootSession.deserializeBinaryFromReader);
      msg.addSessions(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmailAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      keychain$shared_pb.OidcRoleDefinition.serializeBinaryToWriter
    );
  }
  f = message.getSessionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      keychain$shared_pb.OidcRootSession.serializeBinaryToWriter
    );
  }
  f = message.getEmailAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated oslonokkelen.proto.shared.keychain.OidcRoleDefinition roles = 1;
 * @return {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.getRolesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, keychain$shared_pb.OidcRoleDefinition, 1));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.setRolesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.addRoles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * repeated oslonokkelen.proto.shared.keychain.OidcRootSession sessions = 2;
 * @return {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRootSession>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.getSessionsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRootSession>} */ (
    jspb.Message.getRepeatedWrapperField(this, keychain$shared_pb.OidcRootSession, 2));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRootSession>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.setSessionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.keychain.OidcRootSession=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.keychain.OidcRootSession}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.addSessions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.oslonokkelen.proto.shared.keychain.OidcRootSession, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.clearSessionsList = function() {
  return this.setSessionsList([]);
};


/**
 * optional string email_address = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.getEmailAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain.prototype.setEmailAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional string keychain_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.getKeychainUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.setKeychainUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string keychain_factory_uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional KeychainAdminPolicies policies = 3;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.getPolicies = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies, 3));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainAdminPolicies|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.setPolicies = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.clearPolicies = function() {
  return this.setPolicies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.hasPolicies = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OidcKeychain oidc = 20;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.getOidc = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain, 20));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.OidcKeychain|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.setOidc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.clearOidc = function() {
  return this.setOidc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.hasOidc = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional ManualGrantKeychain manual_grant = 21;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.getManualGrant = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain, 21));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.ManualGrantKeychain|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.setManualGrant = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.clearManualGrant = function() {
  return this.setManualGrant(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.hasManualGrant = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional PushKeychain push = 22;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.getPush = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain, 22));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.PushKeychain|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.setPush = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.clearPush = function() {
  return this.setPush(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.DescribeKeychainResponse.prototype.hasPush = function() {
  return jspb.Message.getField(this, 22) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newExpireDate: (f = msg.getNewExpireDate()) && shared_pb.LocalDate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = new shared_pb.LocalDate;
      reader.readMessage(value,shared_pb.LocalDate.deserializeBinaryFromReader);
      msg.setNewExpireDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewExpireDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.LocalDate.serializeBinaryToWriter
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.LocalDate new_expire_date = 2;
 * @return {?proto.oslonokkelen.proto.shared.LocalDate}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.prototype.getNewExpireDate = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDate} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDate, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDate|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.prototype.setNewExpireDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.prototype.clearNewExpireDate = function() {
  return this.setNewExpireDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.ExtendAllKeychainsExpireDateRequest.prototype.hasNewExpireDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.KeychainStatus = {
  APPROVED: 0,
  EXPIRED: 1
};

goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.keychain);
