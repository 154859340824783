import React from 'react';

export const StatsSvg: React.FC = () => (
  <svg
    id='Group_1726'
    data-name='Group 1726'
    xmlns='http://www.w3.org/2000/svg'
    width='80'
    height='80'
    viewBox='0 0 80 80'
  >
    <path
      id='Path_3038'
      data-name='Path 3038'
      d='M71.045,18.667V0H52.379V5.333h9.588l-21.9,21.893L17.058,3.9,2.173,18.781l3.771,3.771L17.032,11.464,40.045,34.8,65.712,9.131v9.535Z'
      transform='translate(3.621)'
      fill='#2a2859'
    />
    <path
      id='Path_3039'
      data-name='Path 3039'
      d='M24.72,12H5.6V54.667H0V60H80V54.667H74.373v-40H55.253v40H49.547V25.333H30.427V54.667H24.72ZM60.587,54.667H69.04V20H60.587Zm-16.373-24v24H35.76v-24Zm-24.827,24V17.333H10.933V54.667Z'
      transform='translate(0 20)'
      fill='#2a2859'
      fillRule='evenodd'
    />
  </svg>
);
