import React, { useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import { openDB } from 'idb';
import { logAnalyticsEvent } from '../../analyticsConfig';
import p1 from '../../assets/person1.png';
import p2 from '../../assets/person2.png';
import p3 from '../../assets/person3.png';
import p4 from '../../assets/person4.png';
import p5 from '../../assets/person5.png';
import p6 from '../../assets/person6.png';
import p7 from '../../assets/person7.png';
import { handleImageInput } from '../../utils/operation/images/imageInput';
import { DialogFormBox } from '../DialogBox/DialogFormBox';
import './ChangeProfileImage.scss';

interface Props {
  setImageUrl: (url: string) => void;
  closeModal: () => void;
  username: string;
  isOpen: boolean;
}

const listOfImages = [p1, p2, p3, p4, p5, p6, p7];
const getRandomImage = () => {
  const randomIndex = Math.floor(Math.random() * listOfImages.length);
  return listOfImages[randomIndex];
};

const testVersion = 4;
export const ProfileImage: React.FC<Props> = ({
  setImageUrl,
  closeModal,
  username,
  isOpen
}) => {
  const [markedImage, setMarkedImage] = useState('');
  const [customImage, setCustomImage] = useState<File>();
  useEffect(() => {
    // Initialize IndexedDB database
    const initDB = async () => {
      try {
        const db = await openDB('KeyStudioProfile', testVersion, {
          upgrade: (db, oldVersion, newVersion) => {
            logAnalyticsEvent('profile_image', {
              data: 'migrating from ' + oldVersion + ' to ' + newVersion
            });
            switch (oldVersion) {
              case 3:
                try {
                  db.createObjectStore('profileImage');
                } catch (e) {
                  console.error('profileImage already exists');
                }
                break;
              default:
                db.createObjectStore('profileImage');
                db.createObjectStore('userUploadedImage');
                break;
            }
          }
        });
        const tempCustImage = await db.get('userUploadedImage', username);
        const storedImage =
          (await db.get('profileImage', username)) || 'random';
        if (tempCustImage) {
          setCustomImage(tempCustImage);
        }
        if (storedImage === 'custom') {
          setImageUrl(URL.createObjectURL(tempCustImage));
        } else if (storedImage === 'random') {
          setImageUrl(getRandomImage());
        } else {
          setImageUrl(storedImage);
        }
        setMarkedImage(storedImage);
      } catch (e) {
        setImageUrl(getRandomImage());
      }
    };
    if (username) {
      initDB();
    }
  }, [username]);

  useEffect(() => {
    if (isOpen) {
      logAnalyticsEvent('profile_image', {
        data: 'edit profile image was opened'
      });
    }
  }, [isOpen]);

  const storeImageUrl = async () => {
    const db = await openDB('KeyStudioProfile', testVersion);
    await db.put('profileImage', markedImage, username);
    logAnalyticsEvent('profile_image', { data: 'stored profile image' });
    closeModal();
    if (markedImage === 'custom' && customImage) {
      setImageUrl(URL.createObjectURL(customImage));
    } else if (markedImage === 'random') {
      setImageUrl(getRandomImage());
    } else if (markedImage) {
      setImageUrl(markedImage);
    } else {
      setImageUrl(getRandomImage());
    }
  };

  const storeCustomImage = async (customImage: File) => {
    const db = await openDB('KeyStudioProfile', testVersion);
    await db.put('userUploadedImage', customImage, username);
    logAnalyticsEvent('profile_image', {
      data: 'uploaded custom profile image'
    });
    setCustomImage(customImage);
    setMarkedImage('custom');
  };

  return (
    <DialogFormBox
      header='Velg profilbilde'
      onSubmit={storeImageUrl}
      closeModal={closeModal}
      isModalOpen={isOpen}
    >
      <div className='available-images'>
        {listOfImages.map(image => {
          return (
            <div
              key={image}
              className={cs('select-box', {
                selected: markedImage === image
              })}
              onClick={() => {
                setMarkedImage(image);
              }}
            >
              <img src={image} alt={'profile image' + image} />
            </div>
          );
        })}
        {customImage && (
          <div
            className={cs('select-box', { selected: markedImage === 'custom' })}
            onClick={() => {
              setMarkedImage('custom');
            }}
          >
            <img src={URL.createObjectURL(customImage)} alt='bilde' />
          </div>
        )}
        <div
          className={cs('select-box', { selected: markedImage === 'random' })}
          onClick={() => {
            setMarkedImage('random');
          }}
        >
          <span>
            Tilfeldige
            <br />
            bilder
          </span>
        </div>
        <ImageUploader storeImage={storeCustomImage} />
      </div>
      <br />
    </DialogFormBox>
  );
};

type ImageUploaderProps = {
  storeImage: (image: File) => void;
};
const ImageUploader: React.FC<ImageUploaderProps> = ({ storeImage }) => {
  const [error, setError] = useState('');
  const fileInput = useRef<HTMLInputElement>(null);

  return (
    <div
      className='select-box image-upload'
      onClick={() => {
        fileInput.current?.click();
      }}
    >
      <span>
        Last opp
        <br />
        eget bilde
      </span>
      <input
        type='file'
        id='upload'
        ref={fileInput}
        onChange={evt => {
          handleImageInput(
            evt,
            setError,
            image => {
              if (image) {
                storeImage(image);
              }
            },
            300
          );
        }}
      />
      {error !== '' && <p className='red'>{error}</p>}
    </div>
  );
};
