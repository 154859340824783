import React, { useCallback } from 'react';
import { ActionMenu } from '../../../../../components/ActionMenu/ActionMenu';
import { ActionMenuItemProps } from '../../../../../components/ActionMenu/ActionMenuItem';
import { RenameDialog } from '../../../../../components/Dialog/RenameDialog';
import { renameActionChain } from '../../../../../redux/actions/adapters';
import { useAppDispatch } from '../../../../../redux/hooks';
import { ActionChain } from '../../../../../redux/slice/adapters';

type Props = {
  actionChain: ActionChain;
};
export const TermMenu: React.FC<Props> = ({ actionChain }) => {
  const [isRenameModalOpen, setIsRenameModalOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  const renameAction = useCallback(
    (name: string) => {
      dispatch(
        renameActionChain({
          actionChainId: actionChain.id,
          version: actionChain.version,
          newChainName: name
        })
      );
    },
    [actionChain.id]
  );

  const actions: ActionMenuItemProps[] = [
    {
      type: 'edit',
      description: 'Endre navn',
      onClick: () => setIsRenameModalOpen(true)
    }
  ];

  return (
    <div className='actionmenu-container'>
      <ActionMenu actions={actions} />
      <RenameDialog
        isModalOpen={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
        initialName={actionChain.name}
        storeAction={renameAction}
        formLabel='Nytt navn på action chain'
      />
    </div>
  );
};
