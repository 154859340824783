import React from 'react';

export const TermsSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='20'
    viewBox='0 0 18 20'
  >
    <g id='Group_1524' data-name='Group 1524' transform='translate(-96 -640)'>
      <g
        id='Group_1523'
        data-name='Group 1523'
        transform='translate(98.843 643.98)'
      >
        <path
          id='Path_2927'
          data-name='Path 2927'
          d='M6.1,8.274A1.137,1.137,0,1,0,4.96,7.137,1.137,1.137,0,0,0,6.1,8.274Z'
          transform='translate(-4.96 -6)'
        />
        <path
          id='Path_2928'
          data-name='Path 2928'
          d='M11.96,7h8.579V8.137H11.96Z'
          transform='translate(-7.98 -6.431)'
        />
        <path
          id='Path_2929'
          data-name='Path 2929'
          d='M6.1,16.274A1.137,1.137,0,1,0,4.96,15.137,1.137,1.137,0,0,0,6.1,16.274Z'
          transform='translate(-4.96 -9.452)'
        />
        <path
          id='Path_2930'
          data-name='Path 2930'
          d='M11.96,15h8.579v1.137H11.96Z'
          transform='translate(-7.98 -9.883)'
        />
        <path
          id='Path_2931'
          data-name='Path 2931'
          d='M6.1,24.274A1.137,1.137,0,1,0,4.96,23.137,1.137,1.137,0,0,0,6.1,24.274Z'
          transform='translate(-4.96 -12.903)'
        />
        <path
          id='Path_2932'
          data-name='Path 2932'
          d='M11.96,23h8.579v1.137H11.96Z'
          transform='translate(-7.98 -13.335)'
        />
      </g>
      <g
        id='Path_2934'
        data-name='Path 2934'
        transform='translate(96 640)'
        fill='none'
      >
        <path d='M0,0H18V20H0Z' stroke='none' />
        <path
          d='M 1.139999389648438 1.139999389648438 L 1.139999389648438 18.86000061035156 L 16.86000061035156 18.86000061035156 L 16.86000061035156 1.139999389648438 L 1.139999389648438 1.139999389648438 M 0 0 L 18 0 L 18 20 L 0 20 L 0 0 Z'
          stroke='none'
        />
      </g>
    </g>
  </svg>
);
