import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionMenu } from '../../../components/ActionMenu/ActionMenu';
import { ActionMenuContainer } from '../../../components/ActionMenu/ActionMenuContainer';
import { ActionMenuItemProps } from '../../../components/ActionMenu/ActionMenuItem';
import { ConfirmDialogDelete } from '../../../components/DeleteAndConfirm/DeleteAndConfirm';
import { RenameDialog } from '../../../components/Dialog/RenameDialog';
import {
  deleteCategory,
  updateCategoryName
} from '../../../redux/actions/categories';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { Category } from '../../../redux/types/protoTypes';
import { EditCategoryDescriptionModal } from './EditCategoryDescriptionModal';

type Props = {
  category: Category;
};
export const AdminCategoryActionMenu: React.FC<Props> = ({ category }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isEditNameModalOpen, setIsEditNameModalOpen] = React.useState(false);
  const [isChangeDescriptionModalOpen, setIsChangeDescriptionModalOpen] =
    React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const actions: ActionMenuItemProps[] = [
    {
      type: 'edit',
      description: 'Endre kategorinavn',
      onClick: () => setIsEditNameModalOpen(true)
    },
    {
      type: 'edit',
      description: 'Endre beskrivelse',
      onClick: () => setIsChangeDescriptionModalOpen(true)
    },
    {
      type: 'delete',
      description: 'Slett kategori',
      onClick: () => setIsDeleteModalOpen(true)
    }
  ];
  return (
    <ActionMenuContainer>
      <ActionMenu actions={actions} />
      <ConfirmDialogDelete
        label='Slett kategori'
        message='Er du sikker på at du vil slette denne kategorien?'
        deleteAction={() =>
          dispatchAndHandleResult(dispatch, deleteCategory, category.id, () => {
            navigate('..', { relative: 'path' });
          })
        }
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
      />
      <RenameDialog
        closeModal={() => setIsEditNameModalOpen(false)}
        isModalOpen={isEditNameModalOpen}
        initialName={category.name}
        storeAction={newName => {
          dispatch(updateCategoryName({ id: category.id, name: newName }));
        }}
      />
      <EditCategoryDescriptionModal
        category={category}
        isModalOpen={isChangeDescriptionModalOpen}
        closeModal={() => setIsChangeDescriptionModalOpen(false)}
      />
    </ActionMenuContainer>
  );
};
