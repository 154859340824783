import React from 'react';
import { PageInfoText } from '../../../components/PageInfoText/PageInfoText';
import { TopInfoStat } from '../../../components/TopInfoStat/TopInfoStat';
import './TopInfo.scss';

interface TopInfoProps {
  numOfSubjects: number;
  totalNumber: number;
}

export const TopInfo: React.FC<TopInfoProps> = ({
  numOfSubjects,
  totalNumber
}) => (
  <div className='top-info-container-subjects'>
    <PageInfoText type='small'>
      Et sted er et lokale, et idrettsanlegg eller noe annet der Oslonøkkelen
      brukes til en eller flere ting, for eksempel til å åpne dører.
    </PageInfoText>
    <TopInfoStat
      label='Antall steder:'
      number={numOfSubjects}
      total={totalNumber}
    />
  </div>
);
