import React from 'react';
import './OperationLinks.scss';

interface OperationLinksProps {
  children: React.ReactNode;
}

export const OperationLinks: React.FC<OperationLinksProps> = ({ children }) => (
  <ul className='operation-links'>{children}</ul>
);
