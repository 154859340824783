import React, { useState } from 'react';
import { User } from '../../redux/slice/user';
import { getNameFromEmail } from '../../utils/userProfile';
import { ProfileImage } from './ProfileImage';
import './ProfileInformation.scss';

interface Props {
  user: User;
}
export const ProfileInformation: React.FC<Props> = ({ user }) => {
  const { username } = user;
  const name = getNameFromEmail(username);
  const [dialogBoxOpen, setDialogBoxOpen] = useState(false);

  const [imageUrl, setImageUrl] = useState('');

  return (
    <div className='profile-info'>
      <p
        className='circle-image'
        onClick={() => {
          setDialogBoxOpen(true);
        }}
      >
        <img src={imageUrl} />
      </p>
      <p className='username'>{name}</p>
      <ProfileImage
        setImageUrl={setImageUrl}
        username={username}
        closeModal={() => setDialogBoxOpen(false)}
        isOpen={dialogBoxOpen}
      />
    </div>
  );
};
