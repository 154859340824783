import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  addGroup,
  deleteGroup,
  deleteQuestion,
  FAQGroup,
  fetchFAQ,
  updateGroupTitle,
  updateQuestion
} from '../actions/broadcast';
import { RootState } from '../index';

const broadcastAdapter = createEntityAdapter<FAQGroup, string>({
  selectId: faq => faq.id
});

const broadcastSlice = createSlice({
  name: 'broadcast',
  initialState: broadcastAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchFAQ.fulfilled, (state, { payload }) => {
        broadcastAdapter.setAll(state, payload);
      })
      .addCase(updateGroupTitle.fulfilled, (state, { payload }) => {
        broadcastAdapter.updateOne(state, {
          id: payload.groupId,
          changes: { title: payload.title }
        });
      })
      .addCase(deleteGroup.fulfilled, (state, { payload }) => {
        broadcastAdapter.removeOne(state, payload);
      })
      .addCase(addGroup.fulfilled, (state, { payload }) => {
        broadcastAdapter.addOne(state, {
          id: payload.groupId,
          title: payload.title,
          questions: []
        });
      })
      .addCase(updateQuestion.fulfilled, (state, { payload }) => {
        broadcastAdapter.updateOne(state, {
          id: payload.groupId,
          changes: {
            questions: state.entities[payload.groupId]?.questions.map(
              question =>
                question.id === payload.question.id
                  ? payload.question
                  : question
            )
          }
        });
      })
      .addCase(deleteQuestion.fulfilled, (state, { payload }) => {
        broadcastAdapter.updateOne(state, {
          id: payload.groupId,
          changes: {
            questions: state.entities[payload.groupId]?.questions.filter(
              question => question.id !== payload.questionId
            )
          }
        });
      });
  }
});

export const initialState = broadcastSlice.getInitialState();
export default broadcastSlice.reducer;
export type BroadcastState = typeof initialState;
export const broadcastSelectors = broadcastAdapter.getSelectors<RootState>(
  state => state.broadcast
);
