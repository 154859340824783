import React from 'react';
import { GpsSvg } from '../../assets/GpsSvg';
import { OperationInfoCard } from './operationCards/OperationInfoCard';

interface Props {
  mustBeWithinMetersToExecute: number | string;
}

export const MetersToExecute: React.FC<Props> = ({
  mustBeWithinMetersToExecute
}) => (
  <OperationInfoCard
    icon={<GpsSvg />}
    header='GPS-Radius'
    link='gps-radius'
    text={
      mustBeWithinMetersToExecute
        ? mustBeWithinMetersToExecute + ' meter'
        : 'Ikke angitt'
    }
  />
);
