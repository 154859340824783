import React from 'react';
import classNames from 'classnames';
import { WarningCircle } from '../../assets/WarningCircle';
import { WarningCircleInactive } from '../../assets/WarningCircleInactive';
import { SubjectActionCard } from '../../pages/places/SubjectCardsContainer/SubjectCard/SubjectActionCard';
import { AnomalyMessage } from '../../redux/slice/subjects';
import './AnomalyCard.scss';

export interface AnomalyCardProps {
  anomaly: AnomalyMessage;
}

export const AnomalyCard: React.FC<AnomalyCardProps> = ({ anomaly }) => {
  const message = anomaly.htmlContent;
  const hasMessage = !!message;
  const classes = classNames('anomaly-card--content', {
    message: hasMessage,
    'no-message': !hasMessage
  });
  return (
    <SubjectActionCard
      icon={hasMessage ? <WarningCircle /> : <WarningCircleInactive />}
      link='avvik'
      header='Avviksmelding'
      classes={classes}
    />
  );
};
