import { createSlice } from '@reduxjs/toolkit';
import { OperationEntity, OperationType } from '../../proto/shared_pb';
import { auditLog, fetchOperationLogAudits } from '../actions/operations';
import { FactoryType, ImageData, Keypedia } from '../types/common';
import { Problem } from '../types/protoTypes';

import EntityCase = OperationEntity.EntityCase;

export interface Operation {
  actionChainId: string;
  about: string;
  closed: boolean;
  id: string;
  name: string;
  operationType: OperationType;
  termIds: string[];
  mustBeWithinMetersToExecute: number;
  ageLimit: number;
  visibleForEveryone: boolean;
  proximityDeviceId: string;
  problemsList: Array<Problem>;
  wiki: Keypedia;
  operationCategory: EntityCase;
  keychainFactoriesGrantingAccessList: KeychainCommon[];
  imagesList: Array<ImageData>;
  citykeyId: string;
}

export interface KeychainCommon {
  keychainFactoryUri: string;
  name: string;
  image: string;
  type: FactoryType;
}

export interface OperationsState {
  auditLog: AuditLog[];
  auditSearchResult?: AuditLogSearchResult[];
}

export type AuditLog = {
  email: string;
  entriesFound: number;
  from: {
    date: string;
    time: string;
  };
  until: {
    date: string;
    time: string;
  };
  operationId: string;
  subjectId: string;
  timestamp: number;
};

export type AuditLogSearchResult = {
  timestamp: number;
  fnr: string;
  name: string;
  error: boolean;
};

export const initialState: OperationsState = {
  auditLog: [],
  auditSearchResult: undefined
};

const operationsSlice = createSlice({
  name: 'operations',
  initialState,
  reducers: {
    clearAuditSearchResults: state => {
      state.auditSearchResult = undefined;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(auditLog.fulfilled, (state, { payload }) => {
        state.auditLog = payload;
      })
      .addCase(fetchOperationLogAudits.fulfilled, (state, { payload }) => {
        state.auditSearchResult = payload;
      });
  }
});
export const { clearAuditSearchResults } = operationsSlice.actions;

export default operationsSlice.reducer;
