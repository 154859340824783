import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DialogFormBox } from '../../../components/DialogBox/DialogFormBox';
import { KeyText } from '../../../components/Forms/KeyText';
import {
  CloneKeyChainFactory,
  cloneKeychainFactory
} from '../../../redux/actions/keychainFactoryDetails';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { KeychainCommon } from '../../../redux/slice/operations';
import { getKeystudioLink } from '../../../utils/citykeyId';

type Props = {
  closeModal: () => void;
  isModalOpen: boolean;
  keychainFactory: KeychainCommon;
};
export const CreateCopy: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  keychainFactory
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm<CloneKeyChainFactory>({
    resolver: zodResolver(CloneKeyChainFactory),
    defaultValues: {
      keychainFactoryUri: keychainFactory.keychainFactoryUri,
      newName: ''
    }
  });
  return (
    <DialogFormBox
      methods={methods}
      buttonLabel='Lag kopi'
      onSubmit={data =>
        dispatchAndHandleResult(
          dispatch,
          cloneKeychainFactory,
          data,
          newUrl => {
            closeModal();
            navigate(getKeystudioLink(newUrl));
          }
        )
      }
      header='Opprett kopi'
      closeModal={closeModal}
      isModalOpen={isModalOpen}
    >
      <KeyText
        name='newName'
        elementProps={{}}
        label='Navn på tilgangslisten'
        description='Navnet blir synlig i Oslonøkkelen-appen for personer på
          tilgangslisten.'
      />
      <p>
        Den nye tilgangslisten vil som standard beholde de samme låsene,
        <br /> men hvem som har tilgang og åpningstider må settes opp på nytt.
      </p>
    </DialogFormBox>
  );
};
