import React, { PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import './FormElement.scss';

export interface KeyComponentProps {
  name: string;
  'data-testid'?: string;
  label?: string;
  description?: string;
  important?: boolean;
  inline?: boolean;
}

export const KeyWrapper: React.FC<PropsWithChildren<KeyComponentProps>> = ({
  inline,
  label,
  description,
  important,
  name,
  children
}) => {
  const formElementClassNames = classNames('form-element', { inline: inline });
  const labelClasses = classNames('form-element--label', {
    important: important
  });
  const {
    formState: { errors }
  } = useFormContext();
  let error = errors[name]?.message;
  error = typeof error === 'string' ? error : undefined;
  const descriptionClasses = classNames('form-element--description', {
    important: important,
    error: error
  });
  return (
    <div className={formElementClassNames}>
      {label && (
        <label className={labelClasses} htmlFor={name}>
          {label}
          <br />
        </label>
      )}
      {children}

      {error && <span className={descriptionClasses}>{error}</span>}
      {!error && description && (
        <span className={descriptionClasses}>{description}</span>
      )}
    </div>
  );
};

type Props = {
  type: string;
  error?: string;
  elementProps: { className?: string };
};

export const generateClasses = ({
  type,
  error,
  elementProps: { className }
}: Props) => {
  const inputClassNames = classNames(
    className,
    `keystudio-form-element--${type}`,
    {
      'keystudio-form-element--error': error
    }
  );
  return { inputClassNames };
};
