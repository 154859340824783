/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.statistics
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: statistics.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as statistics_pb from './statistics_pb'; // proto import: "statistics.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class StatisticsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorReportAdminLogin = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/ReportAdminLogin',
    grpcWeb.MethodType.UNARY,
    statistics_pb.ReportAdminLoginRequest,
    shared_pb.EmptyResponse,
    (request: statistics_pb.ReportAdminLoginRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  reportAdminLogin(
    request: statistics_pb.ReportAdminLoginRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  reportAdminLogin(
    request: statistics_pb.ReportAdminLoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  reportAdminLogin(
    request: statistics_pb.ReportAdminLoginRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/ReportAdminLogin',
        request,
        metadata || {},
        this.methodDescriptorReportAdminLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/ReportAdminLogin',
    request,
    metadata || {},
    this.methodDescriptorReportAdminLogin);
  }

  methodDescriptorCreateOperationBucket = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/CreateOperationBucket',
    grpcWeb.MethodType.UNARY,
    statistics_pb.CreateOperationBucketRequest,
    shared_pb.EmptyResponse,
    (request: statistics_pb.CreateOperationBucketRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createOperationBucket(
    request: statistics_pb.CreateOperationBucketRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createOperationBucket(
    request: statistics_pb.CreateOperationBucketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createOperationBucket(
    request: statistics_pb.CreateOperationBucketRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/CreateOperationBucket',
        request,
        metadata || {},
        this.methodDescriptorCreateOperationBucket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/CreateOperationBucket',
    request,
    metadata || {},
    this.methodDescriptorCreateOperationBucket);
  }

  methodDescriptorFetchOperationBuckets = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/FetchOperationBuckets',
    grpcWeb.MethodType.UNARY,
    statistics_pb.FetchOperationBucketsRequest,
    statistics_pb.FetchOperationBucketsResponse,
    (request: statistics_pb.FetchOperationBucketsRequest) => {
      return request.serializeBinary();
    },
    statistics_pb.FetchOperationBucketsResponse.deserializeBinary
  );

  fetchOperationBuckets(
    request: statistics_pb.FetchOperationBucketsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<statistics_pb.FetchOperationBucketsResponse>;

  fetchOperationBuckets(
    request: statistics_pb.FetchOperationBucketsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: statistics_pb.FetchOperationBucketsResponse) => void): grpcWeb.ClientReadableStream<statistics_pb.FetchOperationBucketsResponse>;

  fetchOperationBuckets(
    request: statistics_pb.FetchOperationBucketsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: statistics_pb.FetchOperationBucketsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/FetchOperationBuckets',
        request,
        metadata || {},
        this.methodDescriptorFetchOperationBuckets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/FetchOperationBuckets',
    request,
    metadata || {},
    this.methodDescriptorFetchOperationBuckets);
  }

  methodDescriptorUpdateOperationBucketName = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/UpdateOperationBucketName',
    grpcWeb.MethodType.UNARY,
    statistics_pb.UpdateOperationBucketNameRequest,
    shared_pb.EmptyResponse,
    (request: statistics_pb.UpdateOperationBucketNameRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateOperationBucketName(
    request: statistics_pb.UpdateOperationBucketNameRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateOperationBucketName(
    request: statistics_pb.UpdateOperationBucketNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateOperationBucketName(
    request: statistics_pb.UpdateOperationBucketNameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/UpdateOperationBucketName',
        request,
        metadata || {},
        this.methodDescriptorUpdateOperationBucketName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/UpdateOperationBucketName',
    request,
    metadata || {},
    this.methodDescriptorUpdateOperationBucketName);
  }

  methodDescriptorUpdateOperationBucketOperations = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/UpdateOperationBucketOperations',
    grpcWeb.MethodType.UNARY,
    statistics_pb.UpdateOperationBucketOperationsRequest,
    shared_pb.EmptyResponse,
    (request: statistics_pb.UpdateOperationBucketOperationsRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateOperationBucketOperations(
    request: statistics_pb.UpdateOperationBucketOperationsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateOperationBucketOperations(
    request: statistics_pb.UpdateOperationBucketOperationsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateOperationBucketOperations(
    request: statistics_pb.UpdateOperationBucketOperationsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/UpdateOperationBucketOperations',
        request,
        metadata || {},
        this.methodDescriptorUpdateOperationBucketOperations,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/UpdateOperationBucketOperations',
    request,
    metadata || {},
    this.methodDescriptorUpdateOperationBucketOperations);
  }

  methodDescriptorDeleteOperationBucket = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/DeleteOperationBucket',
    grpcWeb.MethodType.UNARY,
    statistics_pb.DeleteOperationBucketRequest,
    shared_pb.EmptyResponse,
    (request: statistics_pb.DeleteOperationBucketRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteOperationBucket(
    request: statistics_pb.DeleteOperationBucketRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteOperationBucket(
    request: statistics_pb.DeleteOperationBucketRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteOperationBucket(
    request: statistics_pb.DeleteOperationBucketRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/DeleteOperationBucket',
        request,
        metadata || {},
        this.methodDescriptorDeleteOperationBucket,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/DeleteOperationBucket',
    request,
    metadata || {},
    this.methodDescriptorDeleteOperationBucket);
  }

  methodDescriptorGetAllOperationIds = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/GetAllOperationIds',
    grpcWeb.MethodType.UNARY,
    statistics_pb.GetAllOperationIdsRequest,
    statistics_pb.GetAllOperationIdsResponse,
    (request: statistics_pb.GetAllOperationIdsRequest) => {
      return request.serializeBinary();
    },
    statistics_pb.GetAllOperationIdsResponse.deserializeBinary
  );

  getAllOperationIds(
    request: statistics_pb.GetAllOperationIdsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<statistics_pb.GetAllOperationIdsResponse>;

  getAllOperationIds(
    request: statistics_pb.GetAllOperationIdsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: statistics_pb.GetAllOperationIdsResponse) => void): grpcWeb.ClientReadableStream<statistics_pb.GetAllOperationIdsResponse>;

  getAllOperationIds(
    request: statistics_pb.GetAllOperationIdsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: statistics_pb.GetAllOperationIdsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/GetAllOperationIds',
        request,
        metadata || {},
        this.methodDescriptorGetAllOperationIds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.statistics.StatisticsService/GetAllOperationIds',
    request,
    metadata || {},
    this.methodDescriptorGetAllOperationIds);
  }

}

