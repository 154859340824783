import React from 'react';

interface Props {
  image: File | null;
}

export const ImagePreview: React.FC<Props> = ({ image }) => (
  <div className='image'>
    {image ? (
      <img src={URL.createObjectURL(image)} alt='bilde' />
    ) : (
      <p>Du har ikke valgt et bilde.</p>
    )}
  </div>
);
