import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { WarningCircle } from '../../../assets/WarningCircle';
import { KeyStudioCard } from '../KeyStudioCard/KeyStudioCard';

interface LinkCardProps {
  /**
   * Card title
   */
  title: string;

  /**
   * Optional image uri
   */
  img?: string;

  /**
   * Card content
   */
  content: React.JSX.Element;

  /**
   * Where should the card link to?
   */
  linkUri: LinkProps['to'];

  /**
   * Where should the link open? (target on anchor)
   */
  linkTarget?: string;

  /**
   * Card size
   */
  size?: 'small' | 'small-wide' | 'medium';
  /**
   * Is the card healthy?
   */
  healthy?: boolean;
  /**
   * Display alert on card?
   */
  alert?: boolean;
  /**
   * Icon to display on card
   */
  icon?: React.JSX.Element;
}

export const LinkCard = ({
  title,
  img,
  content,
  linkUri,
  linkTarget,
  icon,
  size = 'medium',
  healthy = true,
  alert = false
}: LinkCardProps) => {
  const className = classNames(img ? 'img' : 'no-img', {
    'not-healthy': !healthy
  });

  return (
    <Link to={linkUri} target={linkTarget}>
      <KeyStudioCard className={className} size={size} isLink>
        <h4>{title}</h4>
        <span className='center-image'>{alert && <WarningCircle />}</span>
        {icon}
        {img && (
          <div className='img-container'>
            <img src={img} alt='subject' />
          </div>
        )}
        {content}
      </KeyStudioCard>
    </Link>
  );
};
