import { KeychainFactorySummary } from '../../redux/slice/keychainFactorySummaries';

export interface GroupedKeychainFactories {
  [subjectName: string]: KeychainFactorySummary[];
}

export const groupBySubject = (keyChainFactories: KeychainFactorySummary[]) =>
  keyChainFactories.reduce(
    (groupedKeychainFactories: GroupedKeychainFactories, keychainFactory) => {
      const pushToGroup = (subjectId: string) => {
        groupedKeychainFactories[subjectId] =
          groupedKeychainFactories[subjectId] || [];
        groupedKeychainFactories[subjectId].push(keychainFactory);
      };

      keychainFactory.linkedSubjects.forEach(subject => {
        pushToGroup(subject.subjectId);
      });
      if (keychainFactory.linkedSubjects.length === 0) {
        pushToGroup('Tilgangslister uten låser');
      }
      return groupedKeychainFactories;
    },
    {}
  );
