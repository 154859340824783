import React, { useEffect } from 'react';
import { KeyText } from '../../../../components/Forms/KeyText';
import { findProfileFromPhoneNumber } from '../../../../redux/actions/access';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { resetPhoneNumber } from '../../../../redux/slice/access';

interface Props {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
}

export const PhoneNumberSearch: React.FC<Props> = ({
  phoneNumber,
  setPhoneNumber
}) => {
  const dispatch = useAppDispatch();
  const { phoneSearchResult, phoneSearchError } = useAppSelector(
    state => state.access
  );
  const { name } = phoneSearchResult;

  useEffect(() => {
    const trimmedPhoneNumber = phoneNumber?.replace(/\s/g, '');
    setPhoneNumber(trimmedPhoneNumber);
    if (trimmedPhoneNumber?.length === 8) {
      dispatch(findProfileFromPhoneNumber(trimmedPhoneNumber));
    } else if (name) {
      dispatch(resetPhoneNumber());
    }
  }, [phoneNumber]);

  return (
    <React.Fragment>
      <label className='phone-label' htmlFor='telefonnr'>
        Telefonnummer
      </label>
      <div className='input-group'>
        <KeyText
          name='countryCode'
          elementProps={{
            tabIndex: 2,
            readOnly: true,
            disabled: true,
            className: 'input-prefix'
          }}
        />
        <KeyText
          name='phoneNumber'
          inline
          elementProps={{
            autoFocus: true,
            id: 'telefonnr',
            className: 'phone-input',
            type: 'tel',
            pattern: '[0-9]{8}'
          }}
        />
      </div>
      <div className='name'>{name}</div>
      <p className='warning'>
        {phoneSearchError === 'IKKE FUNNET' ? (
          <span>
            <span className='highlight-error'>
              Uff, da! Denne personen kan ikke få tilgang ennå.
            </span>
            <br />
            <br />
            <span>Kontakt personen, og be vedkommende gjøre dette:</span>
            <br />
            <ol>
              <li>
                Last ned Oslonøkkelen-appen fra{' '}
                <a href='https://apps.apple.com/no/app/oslon%C3%B8kkelen/id1392962722?fbclid=IwAR3V66-ND_MuTcKlfqtHa1FiCvSq_Urb2f-fTW9O1U0O6H1BpB4QYcrqC1o'>
                  App Store (iPhone)
                </a>{' '}
                eller{' '}
                <a href='https://play.google.com/store/apps/details?id=com.oslonokkel'>
                  Play Store (Android)
                </a>
              </li>
              <li>Logg inn i appen (ID-porten).</li>
              <li>
                Legg til telefonnummeret ditt i appen. Dette gjør du under
                «Innstillinger» inne på profilsiden din.
              </li>
            </ol>
            <br />
            Når dette er i orden, kan du gi personen tilgang.
          </span>
        ) : (
          <span className='highligh-error'>{phoneSearchError}</span>
        )}
      </p>
    </React.Fragment>
  );
};
