import React from 'react';
import { StatsSvg } from '../../../assets/StatsSvg';
import { SubjectActionCard } from '../../../pages/places/SubjectCardsContainer/SubjectCard/SubjectActionCard';
import { getShortkeychainFactoryId } from '../../../utils/keychainFactory/getKeychainFactoryAssets';
import { statsHome } from '../../Navigation/navPaths';
import { NarrowCard } from '../NarrowCard/NarrowCard';

interface Props {
  type: 'subject' | 'admissioncards' | 'oidc' | 'manual-grant' | 'push';
  id: string;
}

const getLink = (id: string, type: Props['type']) => {
  switch (type) {
    case 'subject':
      return (
        statsHome.path +
        '?' +
        new URLSearchParams({ type: 'none', subject: id }).toString()
      );
    case 'admissioncards':
      return (
        statsHome.path +
        '?' +
        new URLSearchParams({ type: 'none', cards: id }).toString()
      );
    default:
      return (
        statsHome.path +
        '?' +
        new URLSearchParams({
          type: 'none',
          keychainFactory: getShortkeychainFactoryId(id)
        }).toString()
      );
  }
};
export const StatsCard: React.FC<Props> = ({ type, id }) => {
  const link = getLink(id, type);
  switch (type) {
    case 'subject':
    case 'admissioncards':
      return (
        <SubjectActionCard
          link={link}
          header='Statistikk'
          icon={<StatsSvg />}
        ></SubjectActionCard>
      );
    case 'manual-grant':
    case 'oidc':
    case 'push':
      return (
        <NarrowCard
          link={link}
          header='Statistikk'
          icon={<StatsSvg />}
        ></NarrowCard>
      );
  }
};
