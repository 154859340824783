import React from 'react';
import { PageInfoText } from '../../../../components/PageInfoText/PageInfoText';
import { TextFilter } from '../../../../components/TextFilter/TextFilter';
import { TopInfoStat } from '../../../../components/TopInfoStat/TopInfoStat';
import './TopInfo.scss';

interface TopInfoProps {
  numOfKeychainFactories: number;
  total: number;
  search: string;
  setSearch: (search: string) => void;
}

export const TopInfo: React.FC<TopInfoProps> = ({
  numOfKeychainFactories,
  search,
  total,
  setSearch
}) => (
  <div className='top-info-container-factory'>
    <PageInfoText type='small'>
      Oslonøkkelen bruker forskjellige typer tilgangslister for å gi innbyggere
      tilgang til de dørene de skal ha. En tilgangsliste gir en større eller
      mindre gruppe tilgang til en eller flere dører i et gitt tidsrom. Noen
      tilgangslister administreres manuelt her i Keystudio, mens andre lister
      blir automatisk synkronisert fra f.eks. bookingsystemet til Origo.
    </PageInfoText>
    <TextFilter search={search} setSearch={setSearch} />
    <TopInfoStat
      label='Tilgangslister'
      number={numOfKeychainFactories}
      total={total}
    />
  </div>
);
