import React from 'react';
import { updateOperationName } from '../../redux/actions/operations';
import { useAppDispatch } from '../../redux/hooks';
import { Operation } from '../../redux/slice/operations';
import { Subject } from '../../redux/slice/subjects';
import {
  Breadcrumbs,
  generateSubjectBreadcrumbs
} from '../Navigation/breadcrumbs';

interface Props {
  subject: Subject;
  operation: Operation;
}

export const OperationBreadcrumbs: React.FC<Props> = ({
  subject,
  operation
}) => {
  const dispatch = useAppDispatch();

  const breadcrumbs = [
    ...generateSubjectBreadcrumbs(subject),
    {
      name: operation.name,
      editCallback: async (name: string) =>
        dispatch(
          updateOperationName({
            subjectId: subject.id,
            operationId: operation.id,
            name
          })
        )
    }
  ];

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
