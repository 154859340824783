/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.flightrecording
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: flightrecording.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as flightrecording_pb from './flightrecording_pb'; // proto import: "flightrecording.proto"


export class FlightRecordingServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorFetchFlightRecording = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.flightrecording.FlightRecordingService/FetchFlightRecording',
    grpcWeb.MethodType.UNARY,
    flightrecording_pb.FetchFlightRecordingRequest,
    flightrecording_pb.FetchFlightRecordingResponse,
    (request: flightrecording_pb.FetchFlightRecordingRequest) => {
      return request.serializeBinary();
    },
    flightrecording_pb.FetchFlightRecordingResponse.deserializeBinary
  );

  fetchFlightRecording(
    request: flightrecording_pb.FetchFlightRecordingRequest,
    metadata?: grpcWeb.Metadata | null): Promise<flightrecording_pb.FetchFlightRecordingResponse>;

  fetchFlightRecording(
    request: flightrecording_pb.FetchFlightRecordingRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: flightrecording_pb.FetchFlightRecordingResponse) => void): grpcWeb.ClientReadableStream<flightrecording_pb.FetchFlightRecordingResponse>;

  fetchFlightRecording(
    request: flightrecording_pb.FetchFlightRecordingRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: flightrecording_pb.FetchFlightRecordingResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.flightrecording.FlightRecordingService/FetchFlightRecording',
        request,
        metadata || {},
        this.methodDescriptorFetchFlightRecording,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.flightrecording.FlightRecordingService/FetchFlightRecording',
    request,
    metadata || {},
    this.methodDescriptorFetchFlightRecording);
  }

}

