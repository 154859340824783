// source: pvd.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.Capability', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.Result', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProximityVerificationDevice device = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.prototype.getDevice = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.CreateProximityVerificationDeviceRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional ProximityVerificationDevice device = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.prototype.getDevice = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.UpdateProximityVerificationDeviceRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.DeleteProximityVerificationDeviceRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ProximityVerificationDevice devices = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice>}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeDriftAverageSeconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    detailsList: jspb.Message.toObjectList(msg.getDetailsList(),
    proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeDriftAverageSeconds(value);
      break;
    case 3:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.deserializeBinaryFromReader);
      msg.addDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeDriftAverageSeconds();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    verifiedTimestamp: (f = msg.getVerifiedTimestamp()) && shared_pb.Timestamp.toObject(includeInstance, f),
    timeDriftSeconds: jspb.Message.getFieldWithDefault(msg, 2, 0),
    result: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails;
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setVerifiedTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeDriftSeconds(value);
      break;
    case 3:
      var value = /** @type {!proto.com.github.oslonokkelen.protobuf.admin.pvd.Result} */ (reader.readEnum());
      msg.setResult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVerifiedTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTimeDriftSeconds();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.Timestamp verified_timestamp = 1;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.prototype.getVerifiedTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.prototype.setVerifiedTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.prototype.clearVerifiedTimestamp = function() {
  return this.setVerifiedTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.prototype.hasVerifiedTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 time_drift_seconds = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.prototype.getTimeDriftSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.prototype.setTimeDriftSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Result result = 3;
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.Result}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.prototype.getResult = function() {
  return /** @type {!proto.com.github.oslonokkelen.protobuf.admin.pvd.Result} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.Result} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 time_drift_average_seconds = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.prototype.getTimeDriftAverageSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.prototype.setTimeDriftAverageSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated LogDetails details = 3;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails>}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.prototype.getDetailsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails, 3));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.prototype.setDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.prototype.addDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.LogDetails, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ListProximityVerificationDeviceUsageLogsResponse.prototype.clearDetailsList = function() {
  return this.setDetailsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secret: jspb.Message.getFieldWithDefault(msg, 2, ""),
    timeDriftSeconds: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timeStepSeconds: jspb.Message.getFieldWithDefault(msg, 4, 0),
    discrepancyTolerance: jspb.Message.getFieldWithDefault(msg, 5, 0),
    capabilitiesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    lastModified: (f = msg.getLastModified()) && shared_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice;
  return proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeDriftSeconds(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeStepSeconds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDiscrepancyTolerance(value);
      break;
    case 6:
      var values = /** @type {!Array<!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.Capability>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCapabilities(values[i]);
      }
      break;
    case 7:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastModified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTimeDriftSeconds();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTimeStepSeconds();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDiscrepancyTolerance();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCapabilitiesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      6,
      f
    );
  }
  f = message.getLastModified();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.Capability = {
  QR: 0
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string secret = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 time_drift_seconds = 3;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.getTimeDriftSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.setTimeDriftSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 time_step_seconds = 4;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.getTimeStepSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.setTimeStepSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 discrepancy_tolerance = 5;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.getDiscrepancyTolerance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.setDiscrepancyTolerance = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Capability capabilities = 6;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.Capability>}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.getCapabilitiesList = function() {
  return /** @type {!Array<!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.Capability>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.Capability>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.setCapabilitiesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.Capability} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.addCapabilities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.clearCapabilitiesList = function() {
  return this.setCapabilitiesList([]);
};


/**
 * optional oslonokkelen.proto.shared.Timestamp last_modified = 7;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.getLastModified = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 7));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.setLastModified = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.clearLastModified = function() {
  return this.setLastModified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.ProximityVerificationDevice.prototype.hasLastModified = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.pvd.Result = {
  SUCCESS: 0,
  DENIED: 1
};

goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.pvd);
