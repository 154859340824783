import React from 'react';
import { OpeningHourComponent } from '../../../../../../components/OpeningHours/OpeningHour';
import {
  OpeningHours,
  SimpleDayRule
} from '../../../../../../redux/types/common';
import { checkIfDayIsClosed } from '../../../../../../utils/keychainFactory/openingHours';
import { getDayNor } from '../../../../../../utils/operation/openingHours/openingHoursFunctions';
import {
  updateDayTimeInterval,
  updateDayIsClosed
} from './editSimpleDayRuleFunctions';

interface Props {
  openingHours: OpeningHours;
  simpleDayRule: SimpleDayRule;
  setOpeningHours: (openingHours: OpeningHours) => void;
}

export const EditSimpleDayRule: React.FC<Props> = ({
  openingHours,
  simpleDayRule,
  setOpeningHours
}) => {
  const [timeInterval] = simpleDayRule.timeInterval;
  const dayIsClosed = checkIfDayIsClosed(timeInterval);

  const handleDayTimeChange = (value: string, type: 'from' | 'until') => {
    const newTimeInterval = { ...timeInterval, [type]: value };
    updateDayTimeInterval(
      newTimeInterval,
      openingHours,
      simpleDayRule,
      timeInterval,
      setOpeningHours
    );
  };

  const handleDayIsClosedChange = (dayIsOpen: boolean) =>
    updateDayIsClosed(
      !dayIsOpen,
      openingHours,
      simpleDayRule,
      timeInterval,
      setOpeningHours
    );

  return (
    <OpeningHourComponent
      day={getDayNor(simpleDayRule.day)}
      dayIsClosed={dayIsClosed}
      updateDayIsClosed={handleDayIsClosedChange}
      fromTime={timeInterval.from}
      untilTime={timeInterval.until}
      updateTime={handleDayTimeChange}
    />
  );
};
