import { combineReducers } from 'redux';
import access from './access';
import adapters from './adapters';
import adminEditValues from './adminEditValues';
import admissionCards from './admissionCards';
import appData from './appData';
import auditLogs from './auditLogs';
import broadcast from './broadcast';
import buckets from './buckets';
import categories from './categories';
import flightrecordings from './flightrecordings';
import groupLinks from './groupLinks';
import keychainFactoryDetails from './keychainFactoryDetails';
import keychainFactorySummaries from './keychainFactorySummaries';
import keychains from './keychains';
import operations from './operations';
import popup from './popup';
import pvd from './pvd';
import roles from './roles';
import subjects from './subjects';
import terms from './terms';
import thirdPartySystems from './thirdPartySystems';
import ui from './ui';
import user from './user';

const rootReducer = combineReducers({
  access,
  adapters,
  adminEditValues,
  admissionCards,
  appData,
  auditLogs,
  buckets,
  broadcast,
  categories,
  flightrecordings,
  groupLinks,
  keychainFactoryDetails,
  keychainFactorySummaries,
  keychains,
  operations,
  popup,
  pvd,
  roles,
  subjects,
  terms,
  thirdPartySystems,
  ui,
  user
});

export default rootReducer;
