import React from 'react';

export const FAQ = () => (
  <svg
    width='137'
    height='137'
    viewBox='0 0 156 156'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='78' cy='78' r='78' fill='#F9B3AB' />
    <path
      d='M98.5365 50.8818C99.3188 54.1792 99.1161 58.3935 97.888 61.4553C96.7448 64.2733 94.8766 66.4435 92.8325 68.5885C90.8221 70.7167 88.7108 72.7523 86.7424 74.6786C83.6553 77.7069 80.3756 80.3481 77.5652 83.7801C76.6651 84.8653 75.437 86.3213 75.1678 87.7337C74.9323 88.9786 74.9407 90.4851 74.823 91.9143C74.7304 92.933 74.6547 93.9929 74.5622 95.0519C74.5285 95.4052 74.5454 95.8426 74.4697 96.0949C74.3519 96.4979 73.8304 96.8512 73.4687 97.2642C73.4014 97.3491 72.6275 98.425 72.4677 98.4418C72.291 98.4587 71.82 97.8782 71.5592 97.6587C71.1891 97.3483 70.7937 97.196 70.6844 96.8764C70.5161 96.3633 70.5666 95.1268 70.5161 94.4791C69.574 82.7194 68.8001 70.5897 67.858 58.973C70.4488 57.9468 73.0817 56.971 75.6473 55.928C76.3539 55.928 77.0689 55.928 77.7755 55.928C77.1951 64.4659 76.2445 72.6421 75.5632 81.0783C78.0362 77.2341 81.3421 74.3068 83.9581 70.4627C86.448 66.8035 89.1642 62.379 88.4836 56.1458C87.8864 50.6193 84.7404 47.0275 80.1728 45.3536C76.0342 43.831 69.7254 43.9993 66.0327 45.8751C65.5196 46.1274 64.8887 46.405 64.5522 46.7415C64.2914 47.0023 64.098 47.4902 63.854 47.8771C62.71 49.7024 61.6165 51.654 60.5482 53.5719C60.136 54.3121 59.7911 55.1869 59.0257 55.6159C58.42 55.4981 57.7639 55.4309 57.2424 55.229C56.7124 54.5476 57.1751 53.8663 57.4611 53.0083C58.6808 49.3239 59.9846 45.3704 61.1539 41.829C65.0653 39.1878 69.5993 37.3288 75.7819 37.0343C80.0298 36.8409 83.4786 37.4802 86.4816 38.5653C89.4081 39.6168 92.0923 41.1729 94.0943 43.1329C96.0374 45.0272 97.7711 47.618 98.5365 50.8818Z'
      fill='#33394E'
    />
    <path
      d='M77.868 107.627C79.0456 108.797 80.055 110.571 79.87 113.103C79.5756 117.124 75.6052 119.993 71.4246 118.983C68.8254 118.352 66.5121 116.106 66.2934 113.019C66.0495 109.645 67.7487 107.677 69.7254 106.534C70.4656 106.113 71.1975 105.81 72.2994 105.709C74.8061 105.482 76.5558 106.307 77.868 107.627Z'
      fill='#33394E'
    />
  </svg>
);
