import React, { useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../components/Button/Button';
import { KeyText } from '../../components/Forms/KeyText';
import { KeyTextarea } from '../../components/Forms/KeyTextarea';
import { showErrorPopup } from '../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setPopup } from '../../redux/slice/popup';
import { setTraceId } from '../../redux/slice/ui';
import { createTicket } from '../../utils/Zendesk/createTicket';
import { Ticket } from '../../utils/Zendesk/types';
import './Help.scss';

export const ContactForm: React.FC = () => {
  const [hasSentMessage, setHasSentMessage] = useState(false);

  const user = useAppSelector(state => state.user);
  const traceId = useAppSelector(state => state.ui.traceId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (traceId) {
      window.scrollTo(0, 1000);
    }
  }, [traceId]);

  const onSendClick = async (data: Ticket) => {
    try {
      await createTicket(data);
      dispatch(setTraceId(''));
      dispatch(setPopup({ error: false, message: 'Meldingen er sendt!' }));
      setHasSentMessage(true);
    } catch (error) {
      showErrorPopup(
        dispatch,
        error,
        'Noe gikk galt, har du tastet inn riktig e-postadresse?'
      );
    }
  };

  const methods = useForm<Ticket>({
    defaultValues: {
      subject: 'Tilbakemelding fra Oslonøkkelen KeyStudio',
      requester: {
        name: user.username || 'Ukjent bruker',
        email: user.email
      },
      tags: ['KeyStudio'],
      custom_fields: traceId
        ? [
            {
              id: 10798236873884,
              value: traceId
            }
          ]
        : []
    }
  });

  useEffect(() => {
    if (user) {
      methods.setValue('requester.email', user.email);
      methods.setValue('requester.name', user.username);
    }
  }, [user]);

  return (
    <div className='input'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSendClick)}>
          <KeyText
            name='requester.email'
            elementProps={{
              type: 'email',
              disabled: hasSentMessage
            }}
            label='E-post'
          />
          <KeyTextarea
            label='Beskjed'
            name='comment.body'
            description='Meldingen går rett til teamet vårt og vi svarer som regel med en gang
          innenfor kontortiden mandag – fredag (0900 – 1500)'
            data-testid='info-input'
            elementProps={{
              className: 'message-box',
              placeholder: 'Skriv en melding',
              disabled: hasSentMessage
            }}
          />
          <Button label='Send' type={hasSentMessage ? 'disabled' : 'submit'} />
        </form>
      </FormProvider>
    </div>
  );
};
