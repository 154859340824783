import React from 'react';

export const CloneIcon: React.FC = () => (
  <svg
    width='31'
    height='34'
    viewBox='0 0 31 34'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M8.5 11V23.5H0.5V0.5H23.5V10.5H9H8.5V11Z' stroke='#2A2859' />
    <rect x='8.5' y='10.5' width='21.9565' height='23' stroke='#2A2859' />
    <path d='M16 22H24' stroke='#2A2859' />
    <path d='M20 26L20 18' stroke='#2A2859' />
  </svg>
);
