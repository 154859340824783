import { createSlice } from '@reduxjs/toolkit';

export interface AdminEditValuesState {
  suggestedAdminRoles: string[];
}

export const initialState: AdminEditValuesState = {
  suggestedAdminRoles: [
    'admin_alna_bydelshus',
    'admin_bjerke_frivillighetenshus',
    'admin_deichman',
    'admin_frogner_bydelshus',
    'admin_frogner_seniorsenter',
    'admin_h2',
    'admin_hasle_aktivitetshus',
    'admin_haugen_skole',
    'admin_haugerud_seniorsenter',
    'admin_holmenkollen_nasjonalanlegg',
    'admin_holmlia_nærmijølokale',
    'admin_homansbyen_naermiljohus',
    'admin_kabelgata',
    'admin_kulturetaten',
    'admin_lindeberg',
    'admin_lindeberg_minigjenbruk',
    'admin_majorstuen_seniorarena',
    'admin_naermiljokontoret_rudshogda',
    'admin_nordre_aker',
    'admin_nordstrand_bydelshus',
    'admin_origo',
    'admin_ovre_fossum_gard',
    'admin_rachel_grepp',
    'admin_reg',
    'admin_slime_gard',
    'admin_slurpen',
    'admin_sondre_nordstrand_bydelshus',
    'admin_stovner',
    'admin_super',
    'admin_tveten_gard',
    'admin_verdenshuset_haugenstua',
    'admin_vestlitoppen',
    'admin_voldslokka_skole'
  ]
};

const adminEditValuesSlice = createSlice({
  name: 'adminEditValues',
  initialState,
  reducers: {}
});

export default adminEditValuesSlice.reducer;
