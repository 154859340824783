import React from 'react';
import { PreContainer } from '../../../components/code/PreContainer';
import { KeychainFactoryDetails } from '../../../redux/slice/keychainFactoryDetails';
import { KeychainFactorySummary } from '../../../redux/slice/keychainFactorySummaries';
import { KeychainFactoryBreadcrumbs } from '../KeychainFactoryBreadcrumbs';
import { KeychainFactoryContainer } from '../KeychainFactoryContainer/KeychainFactoryContainer';

type Props = {
  keychainFactory: KeychainFactorySummary | KeychainFactoryDetails;
};

export const UnknownKeychainFactoryPage: React.FC<Props> = ({
  keychainFactory
}) => {
  return (
    <KeychainFactoryContainer>
      <KeychainFactoryBreadcrumbs keychainFactory={keychainFactory} />
      <PreContainer obj={keychainFactory} />
    </KeychainFactoryContainer>
  );
};
