import React from 'react';

export const AgeSvg = () => (
  <svg
    width='80'
    height='80'
    viewBox='0 0 80 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Frame'>
      <g id='Vector'>
        <path
          d='M72.7307 46.7767V69.6543C72.7307 70.3271 73.3026 71 74.0651 71C74.8277 71 75.3996 70.4233 75.3996 69.6543V46.3923C75.3043 46.4884 75.2089 46.4884 75.1136 46.4884C74.5417 46.7767 73.9698 46.8729 73.3979 46.8729C73.2073 46.8729 73.0166 46.8729 72.7307 46.7767Z'
          fill='#2A2859'
        />
        <path
          d='M55.4783 71C57.0987 71 58.3378 69.7504 58.4331 68.1163L58.8144 49.7566H60.9114L61.1973 68.1163C61.1973 69.7504 62.6271 71 64.1522 71C65.7726 71 67.0117 69.6543 67.0117 68.0202L66.3445 35.1457L67.7742 36.5876L70.729 43.5085C71.2056 44.5659 72.1588 45.1426 73.3026 45.1426C73.6839 45.1426 74.0651 45.0465 74.3511 44.9504C75.7808 44.3736 76.3528 42.7395 75.7809 41.2977L72.6354 33.8961C72.5401 33.6078 72.2541 33.3194 72.0635 33.031L67.8695 28.7054C66.4398 27.2636 64.5334 26.4946 62.4365 26.4946H57.0987C55.2877 26.4946 53.4767 26.9752 51.9516 28.0326L44.8981 32.5504C43.659 33.3194 43.2778 34.9535 43.945 36.2992L48.3296 43.893C49.0921 45.2388 50.8078 45.7194 52.1422 44.9504C52.5235 44.7581 52.8094 44.3736 53.0954 43.9892L52.6188 68.1163C52.5235 69.6543 53.7626 71 55.4783 71ZM50.0453 35.7225L53.1907 33.7039L53.0954 40.9132L50.0453 35.7225Z'
          fill='#2A2859'
        />
        <path
          d='M53.286 18.0357C53.286 21.6884 56.2409 24.6682 59.7676 24.6682C63.3896 24.6682 66.2492 21.6884 66.2492 18.1318C64.5334 18.4202 62.4365 18.6124 59.7676 18.6124C57.0987 18.5163 54.9064 18.4202 53.286 18.0357Z'
          fill='#2A2859'
        />
        <path
          d='M59.7676 16.8822C64.8194 16.8822 68.8227 16.1132 68.8227 15.0558C68.8227 14.4791 67.5836 13.9985 65.6772 13.7101L64.6288 10.4419C64.3428 9.57674 63.5803 9 62.6271 9H56.9081C56.0502 9 55.1924 9.57674 54.9064 10.4419L53.8579 13.7101C51.9516 13.9985 50.7125 14.5752 50.7125 15.0558C50.7125 16.1132 54.8111 16.8822 59.7676 16.8822Z'
          fill='#2A2859'
        />
        <path
          d='M33.3648 35.1457C32.3163 34.2806 30.7912 34.3767 29.9334 35.4341L24.119 42.1628C23.5471 42.9318 22.594 43.3163 21.6408 43.3163H16.589C15.6358 43.3163 14.778 42.9318 14.1107 42.1628L8.29641 35.338C7.43856 34.2806 5.91349 34.1845 4.865 35.0496C3.81651 35.9147 3.7212 37.4527 4.57905 38.5101L12.395 47.738V68.1163C12.395 69.462 13.4435 70.6155 14.8733 70.6155C16.303 70.6155 17.3515 69.462 17.3515 68.1163V57.6388H20.8783V68.1163C20.8783 69.462 21.9267 70.6155 23.3565 70.6155C24.6909 70.6155 25.8347 69.5581 25.8347 68.1163V47.738L33.6507 38.5101C34.5086 37.5488 34.4133 36.0109 33.3648 35.1457Z'
          fill='#2A2859'
        />
        <path
          d='M19.1625 41.0093C22.4264 41.0093 25.0722 38.3411 25.0722 35.0496C25.0722 31.7582 22.4264 29.0899 19.1625 29.0899C15.8987 29.0899 13.2529 31.7582 13.2529 35.0496C13.2529 38.3411 15.8987 41.0093 19.1625 41.0093Z'
          fill='#2A2859'
        />
      </g>
    </g>
  </svg>
);
