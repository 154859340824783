import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { KeychainCommon } from '../../../../redux/slice/operations';
import { Alert } from '../../../Alert/Alert';
import { helpHome } from '../../../Navigation/navPaths';
import { LinkedKeychains } from '../../linnkedKeychains/LinkedKeychains';

type Props = {
  keychainFactoryList: KeychainCommon[];
};

export const EditOpeningHours: React.FC<Props> = ({ keychainFactoryList }) => {
  const publicKeychainFactories = keychainFactoryList.filter(
    keychain => keychain.type === 'public'
  );
  return (
    <Fragment>
      <Alert severity='warning'>
        Oi oi oi. Nå har vi flyttet åpningstidene til “Tilgangslistene”. Om dere
        vil endre åpningstidene må dere navigere dit. Grunnen er det at vi vil
        skape et tydeligere skille mellom de ordinære åpningstidene til stedene
        og de utvidetede tilgangstidene som settes i KeyStudio. Vi håper også at
        det vil bli enklere for dere å få oversikt over når og for hvem
        tilgangene gjelder. Om det er noe som helst trøbbel ikke nøl med å ta
        kontakt.
      </Alert>
      <LinkedKeychains keychainFactoryList={publicKeychainFactories} />
      <br />
      <div>
        Trenger du hjelp?{' '}
        <Link
          to={helpHome.path}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          Ta kontakt med support
        </Link>
      </div>
    </Fragment>
  );
};
