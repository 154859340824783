import { createSlice } from '@reduxjs/toolkit';
import { listAdmissionCardTypes } from '../actions/admissionCards';

export type AdmissionCard = {
  citykeyId: string;
  id: string;
  adminRole: string;
  publicName: string;
  version: number;
  description: string;
  relevantSubjectIdsList: Array<string>;
  requiredTermIdsList: Array<string>;
  actionChainId: string;
  published: boolean;
  hexBackgroundColor: string;
  iconUri: string;
};

export interface AdmissionCardsState {
  cards: AdmissionCard[];
}

export const initialState: AdmissionCardsState = {
  cards: []
};

const admissionCardsSlice = createSlice({
  name: 'admissionCards',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(listAdmissionCardTypes.fulfilled, (state, { payload }) => {
      state.cards = payload.map(card => {
        return {
          ...card,
          description: card.description?.content || '',
          hexBackgroundColor:
            card.hexBackgroundColor.length === 8
              ? '#' + card.hexBackgroundColor.substring(2, 8)
              : ''
        };
      });
    });
  }
});

export default admissionCardsSlice.reducer;
