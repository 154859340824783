import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SubjectBreadcrumbs } from '../../pages/places/SubjectBreadcrumbs';
import { useSubject } from '../../pages/places/SubjectWrapper';
import { clearAnomaly, updateAnomaly } from '../../redux/actions/subjects';
import { dispatchAndHandleResult } from '../../redux/actions/util';
import { useAppDispatch } from '../../redux/hooks';
import { historyGoBack } from '../../utils/history';
import { Alert } from '../Alert/Alert';
import { TextEditor } from '../Editor/TextEditor';
import { PageInfoText } from '../PageInfoText/PageInfoText';
import './AnomalyCard.scss';

export const EditAnomaly: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { subject } = useSubject();
  const message = subject.anomalyMessage.htmlContent;
  const handleSaveClick = async (updatedText: string) =>
    dispatchAndHandleResult(
      dispatch,
      updateAnomaly,
      { subjectId: subject.id, content: updatedText },
      () => historyGoBack(navigate)
    );

  const handleClearClick = () =>
    dispatchAndHandleResult(dispatch, clearAnomaly, subject.id, () => {
      historyGoBack(navigate);
    });

  return (
    <div>
      <SubjectBreadcrumbs subject={subject} name='Avviksmelding' />
      {message ? (
        <Alert>Det er en aktiv avviksmelding på dette stedet.</Alert>
      ) : (
        <PageInfoText type='medium'>
          Her kan du melde fra til brukerne om avvik på stedet deres. Brukerne
          får opp meldingen når de trykker på stedet i appen. Husk å fjerne
          meldingen når avviket er rettet.
          <br /> Eksempel på avviksmelding: «Vi har for øyeblikket en feil på
          døren. Velkommen tilbake senere.»
        </PageInfoText>
      )}
      <TextEditor
        type='simple'
        message={message}
        saveCallback={handleSaveClick}
        clearCallback={handleClearClick}
        handleCancelClick={() => historyGoBack(navigate)}
      />
    </div>
  );
};
