import { formatISO } from 'date-fns';
import {
  BulkStatisticsRequest,
  BulkStatisticsResponse
} from '../../../../proto/operation-log_pb';
import {
  getGrpcMetadata,
  getOperationLogClient
} from '../../../../utils/requests/grpcRequest';

export const statisticsRequest = async (interval: {
  startDate: Date;
  endDate: Date;
}) => {
  const request = new BulkStatisticsRequest()
    .setFrom(formatISO(interval.startDate, { representation: 'date' }))
    .setUntil(formatISO(interval.endDate, { representation: 'date' }));
  const stream = getOperationLogClient().generateBulkStatistics(
    request,
    await getGrpcMetadata()
  );
  return new Promise<Uint8Array[]>((resolve, reject) => {
    const bytes: Uint8Array[] = [];
    stream
      .on('data', (response: BulkStatisticsResponse) => {
        bytes.push(response.getZip_asU8());
      })
      .on('error', error => {
        reject(error);
      })
      .on('end', () => {
        resolve(bytes);
      })
      .on('status', status => {
        //Workaround since end event is not being sent
        if (status.code === 0) {
          resolve(bytes);
        }
      });
  });
};
