import React from 'react';
import { DialogBoxType } from '../../../../components/DialogBox/useDialogBox';
import { Keychain } from '../../../../redux/slice/keychains';
import { DeleteApprovedKeychains } from './DeleteApprovedKeychains';
import { EditApprovedKeychains } from './EditApprovedKeychains';
import { EditEmail } from './EditEmail';
import { EditRoles } from './EditRoles';

type Props = {
  keychain: Keychain;
  keychainFactoryUri: string;
  DeleteDialog: DialogBoxType;
  closeDelete: () => void;
  EditDialog: DialogBoxType;
  closeEdit: () => void;
  EmailDialog: DialogBoxType;
  closeEmail: () => void;
  RolesDialog: DialogBoxType;
  closeRoles: () => void;
};

export const KeychainDialogs: React.FC<Props> = ({
  keychain,
  keychainFactoryUri,
  DeleteDialog,
  closeDelete,
  EditDialog,
  closeEdit,
  EmailDialog,
  closeEmail,
  RolesDialog,
  closeRoles
}) => {
  return (
    <React.Fragment>
      <DeleteApprovedKeychains
        DialogBox={DeleteDialog}
        closeModal={closeDelete}
        keychainFactoryUri={keychainFactoryUri}
        keychain={keychain}
      />
      <EditApprovedKeychains
        DialogBox={EditDialog}
        closeModal={closeEdit}
        keychainFactoryUri={keychainFactoryUri}
        keychain={keychain}
      />
      <EditEmail
        DialogBox={EmailDialog}
        closeModal={closeEmail}
        keychain={keychain}
      />
      <EditRoles
        DialogBox={RolesDialog}
        closeModal={closeRoles}
        keychain={keychain}
      />
    </React.Fragment>
  );
};
