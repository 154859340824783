import React from 'react';

export const Building = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='136'
    height='136'
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12188'
      data-name='Group 12188'
      transform='translate(-326 -475)'
    >
      <g
        id='Group_12183'
        data-name='Group 12183'
        transform='translate(-234 243)'
      >
        <circle
          id='Ellipse_692'
          data-name='Ellipse 692'
          cx='68'
          cy='68'
          r='68'
          transform='translate(560 232)'
          fill='#f9b3ab'
        />
      </g>
      <g
        id='Group_12184'
        data-name='Group 12184'
        transform='translate(360.435 518.626)'
      >
        <path
          id='Path_11300'
          data-name='Path 11300'
          d='M25.244.374,8.983,16.633V49.152H41.5V16.633Z'
          transform='translate(24.103 0)'
          fill='gray'
        />
        <path
          id='Path_11301'
          data-name='Path 11301'
          d='M16.826,4.583V20.842H.565V37.1H33.087V4.583Z'
          transform='translate(0 12.05)'
          fill='#ccc'
        />
        <path
          id='Path_11302'
          data-name='Path 11302'
          d='M21.035,16.633H4.774L21.035.374H37.3Z'
          transform='translate(12.052 0)'
        />
        <path
          id='Path_11303'
          data-name='Path 11303'
          d='M.565,20.842H16.826V4.583Z'
          transform='translate(0 12.05)'
        />
        <path
          id='Path_11304'
          data-name='Path 11304'
          d='M19.275,11.317h-6.5v6.5h6.5Z'
          transform='translate(34.95 31.332)'
        />
      </g>
    </g>
  </svg>
);
