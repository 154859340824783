import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavPath } from '../navPaths';
import './MenuItem.scss';

export const MenuItem = ({ name, path, accessKey, Image }: NavPath) => (
  <NavLink to={path} accessKey={accessKey}>
    {({ isActive }) => (
      <li className={`navlink ${isActive ? 'selected' : ''}`}>
        <dt>
          {Image ? <Image /> : ''}
          <span className='navlink-text'>{name}</span>
        </dt>
      </li>
    )}
  </NavLink>
);
