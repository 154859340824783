import React from 'react';
import {
  Breadcrumbs,
  generateSubjectBreadcrumbs
} from '../../components/Navigation/breadcrumbs';
import { Subject } from '../../redux/slice/subjects';

interface Props {
  subject: Pick<Subject, 'name' | 'id'>;
  name?: string;
}

export const SubjectBreadcrumbs: React.FC<Props> = ({ subject, name }) => {
  const breadcrumbs = generateSubjectBreadcrumbs(subject);
  if (name) {
    breadcrumbs.push({ name });
  }
  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
