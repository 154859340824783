import React from 'react';
import { ActionMenu } from '../../../components/ActionMenu/ActionMenu';
import { ActionMenuItemProps } from '../../../components/ActionMenu/ActionMenuItem';
import { KeychainFactoryDetails } from '../../../redux/slice/keychainFactoryDetails';
import { NewGroupLink } from './NewGroupLink';

type Props = {
  keychainFactory: KeychainFactoryDetails;
};
export const GroupLinkMenu: React.FC<Props> = ({ keychainFactory }) => {
  const [newGroupListModalOpen, setNewGroupListModalOpen] =
    React.useState(false);
  const { policiesList } = keychainFactory;
  const actions: ActionMenuItemProps[] = [];
  if (policiesList.createGroupLink) {
    actions.push({
      type: 'clone',
      description: 'Lag ny nøkkelspredning',
      onClick: () => setNewGroupListModalOpen(true)
    });
  }

  return (
    <div className='actionmenu-container'>
      <ActionMenu actions={actions} />
      <NewGroupLink
        isModalOpen={newGroupListModalOpen}
        closeModal={() => setNewGroupListModalOpen(false)}
        keychainFactory={keychainFactory}
      />
    </div>
  );
};
