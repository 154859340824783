/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.keychain
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: keychain.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as keychain_pb from './keychain_pb'; // proto import: "keychain.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class KeychainServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListKeychains = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/ListKeychains',
    grpcWeb.MethodType.UNARY,
    keychain_pb.ListKeychainsRequest,
    keychain_pb.ListKeychainsResponse,
    (request: keychain_pb.ListKeychainsRequest) => {
      return request.serializeBinary();
    },
    keychain_pb.ListKeychainsResponse.deserializeBinary
  );

  listKeychains(
    request: keychain_pb.ListKeychainsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<keychain_pb.ListKeychainsResponse>;

  listKeychains(
    request: keychain_pb.ListKeychainsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: keychain_pb.ListKeychainsResponse) => void): grpcWeb.ClientReadableStream<keychain_pb.ListKeychainsResponse>;

  listKeychains(
    request: keychain_pb.ListKeychainsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: keychain_pb.ListKeychainsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/ListKeychains',
        request,
        metadata || {},
        this.methodDescriptorListKeychains,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/ListKeychains',
    request,
    metadata || {},
    this.methodDescriptorListKeychains);
  }

  methodDescriptorRevokeKeychain = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/RevokeKeychain',
    grpcWeb.MethodType.UNARY,
    keychain_pb.RevokeKeychainRequest,
    shared_pb.EmptyResponse,
    (request: keychain_pb.RevokeKeychainRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  revokeKeychain(
    request: keychain_pb.RevokeKeychainRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  revokeKeychain(
    request: keychain_pb.RevokeKeychainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  revokeKeychain(
    request: keychain_pb.RevokeKeychainRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/RevokeKeychain',
        request,
        metadata || {},
        this.methodDescriptorRevokeKeychain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/RevokeKeychain',
    request,
    metadata || {},
    this.methodDescriptorRevokeKeychain);
  }

  methodDescriptorExtendKeychainExpireDate = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/ExtendKeychainExpireDate',
    grpcWeb.MethodType.UNARY,
    keychain_pb.ExtendKeychainExpireDateRequest,
    shared_pb.EmptyResponse,
    (request: keychain_pb.ExtendKeychainExpireDateRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  extendKeychainExpireDate(
    request: keychain_pb.ExtendKeychainExpireDateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  extendKeychainExpireDate(
    request: keychain_pb.ExtendKeychainExpireDateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  extendKeychainExpireDate(
    request: keychain_pb.ExtendKeychainExpireDateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/ExtendKeychainExpireDate',
        request,
        metadata || {},
        this.methodDescriptorExtendKeychainExpireDate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/ExtendKeychainExpireDate',
    request,
    metadata || {},
    this.methodDescriptorExtendKeychainExpireDate);
  }

  methodDescriptorDescribeKeychain = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/DescribeKeychain',
    grpcWeb.MethodType.UNARY,
    keychain_pb.DescribeKeychainRequest,
    keychain_pb.DescribeKeychainResponse,
    (request: keychain_pb.DescribeKeychainRequest) => {
      return request.serializeBinary();
    },
    keychain_pb.DescribeKeychainResponse.deserializeBinary
  );

  describeKeychain(
    request: keychain_pb.DescribeKeychainRequest,
    metadata?: grpcWeb.Metadata | null): Promise<keychain_pb.DescribeKeychainResponse>;

  describeKeychain(
    request: keychain_pb.DescribeKeychainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: keychain_pb.DescribeKeychainResponse) => void): grpcWeb.ClientReadableStream<keychain_pb.DescribeKeychainResponse>;

  describeKeychain(
    request: keychain_pb.DescribeKeychainRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: keychain_pb.DescribeKeychainResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/DescribeKeychain',
        request,
        metadata || {},
        this.methodDescriptorDescribeKeychain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/DescribeKeychain',
    request,
    metadata || {},
    this.methodDescriptorDescribeKeychain);
  }

  methodDescriptorExtendAllKeychainsExpireDate = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/ExtendAllKeychainsExpireDate',
    grpcWeb.MethodType.UNARY,
    keychain_pb.ExtendAllKeychainsExpireDateRequest,
    shared_pb.EmptyResponse,
    (request: keychain_pb.ExtendAllKeychainsExpireDateRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  extendAllKeychainsExpireDate(
    request: keychain_pb.ExtendAllKeychainsExpireDateRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  extendAllKeychainsExpireDate(
    request: keychain_pb.ExtendAllKeychainsExpireDateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  extendAllKeychainsExpireDate(
    request: keychain_pb.ExtendAllKeychainsExpireDateRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/ExtendAllKeychainsExpireDate',
        request,
        metadata || {},
        this.methodDescriptorExtendAllKeychainsExpireDate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.keychain.KeychainService/ExtendAllKeychainsExpireDate',
    request,
    metadata || {},
    this.methodDescriptorExtendAllKeychainsExpireDate);
  }

}

