export const sortData = <T>(
  data: T[],
  sortKey: '' | keyof T,
  sortReversed: boolean
): T[] =>
  data.toSorted((a, b) => {
    if (sortKey === '') {
      return 0;
    }
    const valueA = a[sortKey];
    const valueB = b[sortKey];

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return sortReversed
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
      return sortReversed ? valueA - valueB : valueB - valueA;
    } else if (Array.isArray(valueA) && Array.isArray(valueB)) {
      return sortReversed
        ? valueA.length - valueB.length
        : valueB.length - valueA.length;
    } else if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
      return sortReversed
        ? Number(valueA) - Number(valueB)
        : Number(valueB) - Number(valueA);
    }
    return 0; // Handle other cases, or throw an error if unsupported types are found
  });
