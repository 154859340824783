import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import { updateOperationAbout } from '../../../redux/actions/operations';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { Operation } from '../../../redux/slice/operations';
import { historyGoBack } from '../../../utils/history';
import { TextEditor } from '../../Editor/TextEditor';

interface Props {
  operation: Operation;
  subjectId: string;
  setShowNavigateConfirm: (n: boolean) => void;
}

export const EditAbout: React.FC<Props> = ({
  subjectId,
  operation,
  setShowNavigateConfirm
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSaveClick = (updatedAbout: string) =>
    dispatchAndHandleResult(
      dispatch,
      updateOperationAbout,
      {
        subjectId,
        operationId: operation.id,
        about: updatedAbout
      },
      () => {
        historyGoBack(navigate);
      }
    );

  const handleCancelClick = (hasChanged: boolean) => {
    if (hasChanged) {
      setShowNavigateConfirm(true);
    } else {
      navigate(-1);
    }
  };

  return (
    <React.Fragment>
      <h3>Beskrivelse (står under mer info i appen)</h3>
      <TextEditor
        type='advanced'
        message={operation.about}
        handleCancelClick={handleCancelClick}
        saveCallback={handleSaveClick}
      />
    </React.Fragment>
  );
};
