import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import './NarrowCard.scss';

export const NarrowCard: React.FC<{
  classes?: string;
  link?: string;
  header?: string;
  icon?: React.JSX.Element;
  iconPosition?: 'top' | 'bottom';
  contentAlignment?: 'top' | 'bottom';
  children?: React.ReactNode;
}> = ({
  children,
  classes,
  link,
  header,
  icon,
  iconPosition = 'top',
  contentAlignment = 'bottom'
}) => {
  const clickable = !!link;

  const classeName = classNames(
    'narrow-card-container',
    { clickable: clickable },
    classes
  );

  const iconClasses = classNames('icon-container', iconPosition);
  const contentClasses = classNames('content-container', contentAlignment);

  const cardContainer = (
    <div className={classeName}>
      {header ? <h4 className='header'>{header}</h4> : null}
      {icon ? <div className={iconClasses}>{icon}</div> : null}
      <div className={contentClasses}>{children}</div>
    </div>
  );
  if (link) {
    return <Link to={link}>{cardContainer}</Link>;
  }
  return cardContainer;
};
