import React from 'react';
import { Provider } from 'react-redux';
import { App } from './App';
import { store as storeSingleton } from './redux';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

declare const myNonce: string

interface AppWithStoreProps {
  store: typeof storeSingleton;
}

const cache = createCache({
    key: 'keystudio-cache',
    nonce: myNonce
});

export const AppWithStore: React.FC<AppWithStoreProps> = (
  props: AppWithStoreProps
) => (
  <Provider store={props.store}>
      <CacheProvider value={cache}> {/* This is needed to have react-select add nonce to inline style elements */}
          <App />
      </CacheProvider>
  </Provider>
);
