import { z } from 'zod';
import {
  DecryptedOperationLogDataRequest,
  DecryptedOperationLogDataResponse,
  OperationLogAuditsRequest,
  OperationLogAuditsResponse
} from '../../proto/operation-log_pb';
import {
  ClearAgeLimitMessage,
  CreateOperationRequest,
  DeleteOperationRequest,
  UpdateAgeLimitMessage,
  UpdateEntityTypeRequest,
  UpdateOperationAboutRequest,
  UpdateOperationActionChainRequest,
  UpdateOperationExecuteMetersRequest,
  UpdateOperationNameRequest,
  UpdateOperationTermsRequest,
  UpdateProximityVerificationDeviceIdRequest
} from '../../proto/operation_pb';
import { Operation, OperationEntity } from '../../proto/shared_pb';
import {
  getGrpcMetadata,
  getOperationLogClient,
  getOperationsClient,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { dateToISO8601DateTimeInterval } from '../../utils/timeConverter';
import { createAppAsyncThunk } from '../hooks';
import { AuditLog, AuditLogSearchResult } from '../slice/operations';
import { Operation as OperationObject } from '../slice/operations';
import { setPopup } from '../slice/popup';
import { FactoryType, flattenInterval } from '../types/common';
import { FullOperationId } from '../types/protoTypes';
import {
  id,
  name,
  noValidationString,
  optionTypeSchema,
  stringNonEmpty
} from '../types/zodSchemas';
import { getAppData } from './appData';
import { convertToKeypedia, createFullOperationId, createText } from './util';

import EntityCase = OperationEntity.EntityCase;

export const CreateOperationSchema = z.object({
  subjectId: noValidationString,
  operationId: id,
  name: name,
  actionChain: optionTypeSchema
});

export type CreateOperationSchema = z.infer<typeof CreateOperationSchema>;

export const createOperation = createAppAsyncThunk<void, CreateOperationSchema>(
  'operations/createOperation',
  async ({ subjectId, operationId, name, actionChain }, thunkAPI) => {
    try {
      await getOperationsClient().createOperation(
        new CreateOperationRequest()
          .setSubjectId(subjectId)
          .setId(operationId)
          .setName(name)
          .setActionChainId(actionChain.value),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Låsen er opprettet' })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const deleteOperation = createAppAsyncThunk<void, FullOperationId>(
  'operations/deleteOperation',
  async ({ subjectId, operationId }, thunkAPI) => {
    try {
      await getOperationsClient().deleteOperation(
        new DeleteOperationRequest().setOperationId(
          createFullOperationId({ subjectId, operationId })
        ),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Låsen er slettet' })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const updateOperationName = createAppAsyncThunk<
  void,
  {
    subjectId: string;
    operationId: string;
    name: string;
  }
>(
  'operations/updateOperationName',
  async ({ subjectId, operationId, name }, thunkAPI) => {
    try {
      await getOperationsClient().updateOperationName(
        new UpdateOperationNameRequest()
          .setOperationId(createFullOperationId({ subjectId, operationId }))
          .setName(name),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Navnet er oppdatert' })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const updateOperationAbout = createAppAsyncThunk<
  void,
  {
    subjectId: string;
    operationId: string;
    about: string;
  }
>(
  'operations/updateOperationAbout',
  async ({ subjectId, operationId, about }, thunkAPI) => {
    try {
      await getOperationsClient().updateOperationAbout(
        new UpdateOperationAboutRequest()
          .setOperationId(createFullOperationId({ subjectId, operationId }))
          .setAbout(createText(about)),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Beskrivelsen er oppdatert' })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const updateOperationTerms = createAppAsyncThunk<
  void,
  {
    subjectId: string;
    operationId: string;
    termIds: string[];
  }
>(
  'operations/updateOperationTerms',
  async ({ subjectId, operationId, termIds }, thunkAPI) => {
    try {
      await getOperationsClient().updateOperationTerms(
        new UpdateOperationTermsRequest()
          .setOperationId(createFullOperationId({ subjectId, operationId }))
          .setTermIdsList(termIds),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Betingelsene er oppdatert' })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const UpdateOperationExecuteMeters = z.object({
  subjectId: noValidationString,
  operationId: noValidationString,
  mustBeWithinMetersToExecute: z.coerce
    .number()
    .min(
      50,
      'På grunnen av unøyaktig lokasjon fra mobiltelefoner kreves det minimum 50 meter på GPS-radiusen.'
    )
});
export type UpdateOperationExecuteMeters = z.infer<
  typeof UpdateOperationExecuteMeters
>;
export const updateOperationExecuteMeters = createAppAsyncThunk<
  void,
  UpdateOperationExecuteMeters
>(
  'operations/updateOperationExecuteMeters',
  async ({ subjectId, operationId, mustBeWithinMetersToExecute }, thunkAPI) => {
    try {
      await getOperationsClient().updateOperationExecuteMeters(
        new UpdateOperationExecuteMetersRequest()
          .setOperationId(createFullOperationId({ subjectId, operationId }))
          .setMustBeWithinMetersToExecute(mustBeWithinMetersToExecute),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'GPS radius er oppdatert' })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const SetPvdId = z.object({
  subjectId: stringNonEmpty,
  operationId: stringNonEmpty,
  deviceOption: optionTypeSchema
});
export type SetPvdId = z.infer<typeof SetPvdId>;

export const setPvdId = createAppAsyncThunk<void, SetPvdId>(
  'operations/setPvdId',
  async ({ subjectId, operationId, deviceOption: { value } }, thunkAPI) => {
    const request =
      new UpdateProximityVerificationDeviceIdRequest().setOperationId(
        createFullOperationId({ subjectId, operationId })
      );
    if (value) {
      request.setId(value);
    }
    try {
      await getOperationsClient().updateProximityVerificationDeviceId(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Nærhetsenehet er satt' })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const setAgeLimit = createAppAsyncThunk<
  void,
  {
    subjectId: string;
    operationId: string;
    ageLimit: number;
  }
>(
  'operations/setAgeLimit',
  async ({ subjectId, operationId, ageLimit }, thunkAPI) => {
    try {
      await getOperationsClient().updateAgeLimit(
        new UpdateAgeLimitMessage()
          .setOperationId(createFullOperationId({ subjectId, operationId }))
          .setAgeLimit(ageLimit),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Aldersgrensen er satt' })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const clearAgeLimit = createAppAsyncThunk<
  void,
  {
    subjectId: string;
    operationId: string;
  }
>('operations/clearAgeLimit', async ({ subjectId, operationId }, thunkAPI) => {
  const request = new ClearAgeLimitMessage();
  request.setOperationId(createFullOperationId({ subjectId, operationId }));

  try {
    await getOperationsClient().clearAgeLimit(request, await getGrpcMetadata());
    thunkAPI.dispatch(getAppData());
    thunkAPI.dispatch(
      setPopup({ error: false, message: 'Aldersgrensen er fjernet' })
    );
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const UpdateOperationType = z.object({
  subjectId: noValidationString,
  operationId: noValidationString,
  operationType: z.nativeEnum(EntityCase)
});
export type UpdateOperationType = z.infer<typeof UpdateOperationType>;
export const updateOperationType = createAppAsyncThunk<
  void,
  UpdateOperationType
>(
  'operations/updateOperationType',
  async ({ subjectId, operationId, operationType }, thunkAPI) => {
    try {
      const entity = new OperationEntity();
      if (operationType === EntityCase.DOOR) {
        entity.setDoor(new OperationEntity.Door());
      } else if (operationType === EntityCase.LIGHT) {
        entity.setLight(new OperationEntity.Light());
      }
      await getOperationsClient().updateEntityType(
        new UpdateEntityTypeRequest()
          .setOperationId(createFullOperationId({ subjectId, operationId }))
          .setEntity(entity),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Typen opperasjon er oppdatert' })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const UpdateOperationActionChain = z.object({
  subjectId: noValidationString,
  operationId: noValidationString,
  actionChain: optionTypeSchema
});

export type UpdateOperationActionChain = z.infer<
  typeof UpdateOperationActionChain
>;

export const updateOperationActionChain = createAppAsyncThunk<
  void,
  UpdateOperationActionChain
>(
  'operations/updateOperationActionChain',
  async ({ subjectId, operationId, actionChain }, thunkAPI) => {
    try {
      await getOperationsClient().updateOperationActionChain(
        new UpdateOperationActionChainRequest()
          .setOperationId(createFullOperationId({ subjectId, operationId }))
          .setActionChainId(actionChain.value),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getAppData());
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Operasjonen er nå knyttet til ny ActionChain'
        })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const auditLog = createAppAsyncThunk<Array<AuditLog>, void>(
  'operations/auditLog',
  async (_, thunkAPI) => {
    try {
      const response = await getOperationLogClient().fetchOperationLogAudits(
        new OperationLogAuditsRequest(),
        await getGrpcMetadata()
      );
      return response.toObject().auditsList.map(convertToAuditLog);
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const FetchOperationLogAudits = z.object({
  selectedSubject: optionTypeSchema,
  selectedOperation: optionTypeSchema,
  fromDate: z.date(),
  untilDate: z.date()
});
export type FetchOperationLogAudits = z.infer<typeof FetchOperationLogAudits>;

export const fetchOperationLogAudits = createAppAsyncThunk<
  AuditLogSearchResult[],
  FetchOperationLogAudits
>(
  'operations/fetchOperationLogAudits',
  async (
    { selectedSubject, selectedOperation, fromDate, untilDate },
    thunkAPI
  ) => {
    try {
      const result =
        await getOperationLogClient().fetchDecryptedOperationLogData(
          new DecryptedOperationLogDataRequest()
            .setOperationId(
              createFullOperationId({
                subjectId: selectedSubject.value,
                operationId: selectedOperation.value
              })
            )
            .setInterval(
              dateToISO8601DateTimeInterval({
                startDate: fromDate,
                endDate: untilDate
              })
            ),
          await getGrpcMetadata()
        );
      thunkAPI.dispatch(auditLog());
      return result.toObject().entriesList.map(convertToAuditLogSearchResult);
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const convertToAuditLog = (
  audit: OperationLogAuditsResponse.Audit.AsObject
): AuditLog => {
  return {
    email: audit.email,
    entriesFound: audit.entriesFound,
    ...flattenInterval(audit.interval),
    operationId: audit.operationId?.operationId || '',
    subjectId: audit.operationId?.subjectId || '',
    timestamp: audit.timestamp?.epochMillis || 0
  };
};

export const convertToAuditLogSearchResult = (
  searchResult: DecryptedOperationLogDataResponse.Entry.AsObject
): AuditLogSearchResult => {
  return {
    timestamp: searchResult.timestamp?.epochMillis || 0,
    fnr: searchResult.fnr,
    name: searchResult.name,
    error: searchResult.error
  };
};

export const convertToOperation = (
  operation: Operation.AsObject
): OperationObject => {
  return {
    ...operation,
    about: operation.about?.content || '',
    termIds: operation.termIdsList,
    wiki: convertToKeypedia(operation.wiki),
    operationCategory: findOperationType(operation.entity),
    keychainFactoriesGrantingAccessList:
      operation.keychainFactoriesGrantingAccessList.map(factory => {
        return {
          keychainFactoryUri: factory.keychainFactoryUri,
          name: factory.name,
          image: factory.image?.uri || '',
          type: FactoryType.parse(factory.type)
        };
        // return KeychainCommon.parse(factory);
      })
  };
};

export const findOperationType = (entity?: OperationEntity.AsObject) => {
  if (entity?.door) {
    return OperationEntity.EntityCase.DOOR;
  } else if (entity?.light) {
    return OperationEntity.EntityCase.LIGHT;
  }
  return OperationEntity.EntityCase.ENTITY_NOT_SET;
};
