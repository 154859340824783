import { z } from 'zod';
import { convertTextareaToArray } from '../../utils/string';

export const stringNonEmpty = z
  .string()
  .trim()
  .min(1, 'Feltet kan ikke være tomt');

export const noValidationString = z.string().default(''); // Brukt på verdier som brukeren ikke skal kunne endre, f.eks IDer.

export const id = z.string().trim().min(1, 'Du må fylle inn en ID');

export const email = z
  .string()
  .email({ message: 'Du må fylle inn en gyldig e-postadresse' });

export const title = z.string().trim().min(2, 'Gi en beskrivende tittel');

export const name = z.string().trim().min(2, 'Gi et beskrivende navn');

export const zeroOrMore = z.preprocess(
  val => Number(val),
  z.number().min(0, 'Tallet må være positivt').default(0)
);
export const positiveNumber = z.preprocess(
  val => Number(val),
  z.number().min(1, 'Må være et tall større enn 0')
);

export const description = z
  .string()
  .trim()
  .min(1, 'Beskrivelsen kan ikke være tom');

export const multiLine = z
  .string()
  .refine(value => convertTextareaToArray(value).length > 0, {
    message: 'Fyll ut minst en linje'
  });

export const optionTypeSchema = z.object(
  {
    value: z.string(),
    label: z.unknown()
  },
  {
    message: 'Velg en verdi fra listen'
  }
);
// .transform(option => option.value);
