import { AsyncThunk, isFulfilled, isRejected } from '@reduxjs/toolkit';
import { RpcError } from 'grpc-web';
import axios from 'axios';
import { Dispatch } from 'redux';
import {
  ErrorResponse,
  FullOperationId as FullOperationIdObject,
  Keypedia,
  LocalDate,
  LocalDateTime,
  LocalTime,
  Text
} from '../../proto/shared_pb';
import { isGrpcError } from '../../utils/requests/grpcRequest';
import { AppDispatch } from '../hooks';
import { RootState } from '../index';
import { setPopup } from '../slice/popup';
import { Keypedia as KeypediaObject } from '../types/common';
import { FullOperationId } from '../types/protoTypes';

export const showErrorPopup = (
  dispatch: Dispatch,
  error: unknown,
  defaultErrorMessage = 'Noe gikk galt, og vi vet ikke hva det er.'
) => {
  let traceId;
  let errormessage;
  let methodname;
  if (axios.isAxiosError(error)) {
    traceId = error.response?.headers?.['x-trace-id'] || '';
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    errormessage = (error.response?.data as any)?.humanReadableErrorMessage;
  } else if (isGrpcError(error)) {
    traceId = error.metadata['x-trace-id'];
    errormessage = extractGrpcErrorMessage(error);
    methodname = extractGrpcMethodName(error);
  }
  console.error(error);
  dispatch(
    setPopup({
      error: true,
      message: errormessage || defaultErrorMessage,
      traceId: traceId,
      methodName: methodname
    })
  );
};

export const extractGrpcErrorMessage = (error: RpcError): string => {
  const encodedErrorMessage =
    error.metadata['oslonokkelen.proto.shared.errorresponse-bin'];
  const intArray = Uint8Array.from(window.atob(encodedErrorMessage), v =>
    v.charCodeAt(0)
  );
  const message = ErrorResponse.deserializeBinary(intArray);
  return message.getHumanReadableMessage();
};

export const extractGrpcMethodName = (error: RpcError): string => {
  const encodedErrorMessage =
    error.metadata['oslonokkelen.proto.shared.errorresponse-bin'];
  const intArray = Uint8Array.from(window.atob(encodedErrorMessage), v =>
    v.charCodeAt(0)
  );
  const message = ErrorResponse.deserializeBinary(intArray);
  return message.getMethodName();
};

export const convertToKeypedia = (wiki?: Keypedia.AsObject) => {
  return KeypediaObject.parse({
    htmlContent: wiki?.text?.content,
    lastUpdatedMillis: wiki?.lastUpdated?.epochMillis,
    lastUpdatedBy: wiki?.lastUpdatedBy
  });
};

export const createText = (text: string) => {
  return new Text().setContent(text).setType(Text.Type.HTML);
};

export const createFullOperationId = ({
  operationId,
  subjectId
}: FullOperationId) => {
  return new FullOperationIdObject()
    .setOperationId(operationId)
    .setSubjectId(subjectId);
};

export type TimeDate = {
  date: string;
  time: string;
};
export const createDateTime = ({ date, time }: TimeDate) => {
  return new LocalDateTime()
    .setDate(new LocalDate().setDateString(date))
    .setTime(new LocalTime().setTimeString(time));
};

export const dispatchAndHandleResult = async <
  Returned, // The type of the return value of the thunk
  ThunkArg // The type of the argument the thunk receives
>(
  dispatch: AppDispatch,
  action: AsyncThunk<
    Returned,
    ThunkArg,
    { state: RootState; dispatch: AppDispatch; rejectValue: string }
  >,
  args: ThunkArg,
  onSuccess?: (result: Returned) => void,
  onFailure?: (error?: string) => void
) => {
  const actionResult = await dispatch(action(args));
  if (isFulfilled(actionResult)) {
    if (onSuccess) onSuccess(actionResult.payload);
  } else if (isRejected(actionResult)) {
    if (onFailure) onFailure(actionResult.payload);
  }
};
