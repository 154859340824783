import React from 'react';
import { OperationLinks } from '../../../../components/operation/operationCards/OperationLinks/OperationLinks';
import { OperationEntity } from '../../../../proto/shared_pb';
import { FullOpertaionId } from '../../../../redux/slice/adapters';
import { LockCard } from '../../../keychainFactory/locks/lockCards/LockCard';

interface Props {
  usedByOperationsList: FullOpertaionId[];
}

export const ActionOperationsList: React.FC<Props> = ({
  usedByOperationsList
}) => {
  const locks = usedByOperationsList?.map(operation => {
    const operationInfo = {
      ...operation,
      name: '',
      type: OperationEntity.EntityCase.ENTITY_NOT_SET
    };
    return (
      <OperationLinks key={operation.operationId}>
        <LockCard operation={operationInfo} />
      </OperationLinks>
    );
  });
  return (
    <React.Fragment>
      <h2>Brukes av disse operasjonene:</h2>
      <div className='cards'>{locks}</div>
    </React.Fragment>
  );
};
