import { formatISO } from 'date-fns';
import { Visitation, Visitations } from '../../pages/stats/utils/statsTypes';
import {
  StatisticsRequest,
  StatisticsResponse
} from '../../proto/operation-log_pb';
import {
  getGrpcMetadata,
  getOperationLogClient
} from '../../utils/requests/grpcRequest';
import { dateToISO8601DateTimeInterval } from '../../utils/timeConverter';
import { Subject } from '../slice/subjects';
import { createFullOperationId } from './util';

export const fetchSubjectVisitations = async (
  dateInterval: { startDate: Date; endDate: Date },
  subject: Subject
) => {
  const responseData: Visitations[] = [];

  const interval = dateToISO8601DateTimeInterval(dateInterval);

  for (let i = 0; i < subject.operationsList.length; i++) {
    try {
      const subjectId = subject.id;
      const operationId = subject.operationsList[i].id;
      const response: StatisticsResponse.AsObject = (
        await getOperationLogClient().generateStatistics(
          new StatisticsRequest().setInterval(interval).setOperationId(
            createFullOperationId({
              subjectId: subject.id,
              operationId: subject.operationsList[i].id
            })
          ),
          await getGrpcMetadata()
        )
      ).toObject();

      for (
        let d = new Date(dateInterval.startDate);
        d <= new Date(dateInterval.endDate);
        d.setDate(d.getDate() + 1)
      ) {
        const date = formatISO(d.toISOString(), { representation: 'date' });
        const dayStatistic = response.dayStatisticsList.find(
          day => day.date?.dateString === date
        );
        if (dayStatistic) {
          responseData.push({
            subjectId,
            operationId,
            day: [{ date, successCount: dayStatistic.successCount }]
          });
        } else {
          responseData.push({
            subjectId,
            operationId,
            day: [{ date, successCount: 0 }]
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  return responseData;
};

export const fetchKeychainFactoryVisitations = async (
  dateInterval: { startDate: Date; endDate: Date },
  id: string
) => {
  const interval = dateToISO8601DateTimeInterval(dateInterval);

  try {
    const response: StatisticsResponse.AsObject = (
      await getOperationLogClient().generateStatistics(
        new StatisticsRequest().setInterval(interval).setKeychainFactoryUri(id),
        await getGrpcMetadata()
      )
    ).toObject();
    return extractDataFromResponse(dateInterval, response);
  } catch (error) {
    console.error(error);
  }
};

export const fetchAdmissionCardUsages = async (
  dateInterval: { startDate: Date; endDate: Date },
  id: string
) => {
  try {
    const response: StatisticsResponse.AsObject = (
      await getOperationLogClient().generateStatistics(
        new StatisticsRequest()
          .setInterval(dateToISO8601DateTimeInterval(dateInterval))
          .setAdmissionCardType(id),
        await getGrpcMetadata()
      )
    ).toObject();

    return extractDataFromResponse(dateInterval, response);
  } catch (error) {
    console.error(error);
  }
};

const extractDataFromResponse = (
  dateInterval: { startDate: Date; endDate: Date },
  response: StatisticsResponse.AsObject
) => {
  const responseData: Visitation[] = [];

  for (
    let d = new Date(dateInterval.startDate);
    d <= new Date(dateInterval.endDate);
    d.setDate(d.getDate() + 1)
  ) {
    const date = formatISO(d.toISOString(), { representation: 'date' });
    const dayStatistic = response.dayStatisticsList.find(
      day => day.date?.dateString === date
    );
    if (dayStatistic) {
      responseData.push({ date, successCount: dayStatistic.successCount });
    } else {
      responseData.push({ date, successCount: 0 });
    }
  }

  return responseData;
};
