// source: broadcast.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchFAQRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    faq: (f = msg.getFaq()) && proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.deserializeBinaryFromReader);
      msg.setFaq(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFaq();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.serializeBinaryToWriter
    );
  }
};


/**
 * optional BroadcastFaq faq = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.prototype.getFaq = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.prototype.setFaq = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.prototype.clearFaq = function() {
  return this.setFaq(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FAQResponse.prototype.hasFaq = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddGroupRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteGroupRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateGroupTitleRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional BroadcastFaq.Group.Question question = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.prototype.getQuestion = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.AddQuestionRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    questionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuestionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string question_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.prototype.getQuestionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.DeleteQuestionRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    question: (f = msg.getQuestion()) && proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.deserializeBinaryFromReader);
      msg.setQuestion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.serializeBinaryToWriter
    );
  }
};


/**
 * optional BroadcastFaq.Group.Question question = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.prototype.getQuestion = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.prototype.setQuestion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.prototype.clearQuestion = function() {
  return this.setQuestion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateQuestionRequest.prototype.hasQuestion = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupsList: jspb.Message.toObjectList(msg.getGroupsList(),
    proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.deserializeBinaryFromReader);
      msg.addGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    body: jspb.Message.getFieldWithDefault(msg, 4, ""),
    admissionCardTypeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    relevantSubjectIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    triggerWordsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    shared_pb.Image.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdmissionCardTypeId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addRelevantSubjectIds(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTriggerWords(value);
      break;
    case 8:
      var value = new shared_pb.Image;
      reader.readMessage(value,shared_pb.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAdmissionCardTypeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRelevantSubjectIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getTriggerWordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      shared_pb.Image.serializeBinaryToWriter
    );
  }
};


/**
 * optional string group_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string body = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string admission_card_type_id = 5;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.getAdmissionCardTypeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.setAdmissionCardTypeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string relevant_subject_ids = 6;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.getRelevantSubjectIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.setRelevantSubjectIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.addRelevantSubjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.clearRelevantSubjectIdsList = function() {
  return this.setRelevantSubjectIdsList([]);
};


/**
 * repeated string trigger_words = 7;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.getTriggerWordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.setTriggerWordsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.addTriggerWords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.clearTriggerWordsList = function() {
  return this.setTriggerWordsList([]);
};


/**
 * repeated oslonokkelen.proto.shared.Image images = 8;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Image>}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.Image, 8));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Image>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Image}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.oslonokkelen.proto.shared.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Question questions = 3;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question>}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question, 3));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};


/**
 * repeated Group groups = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group>}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.prototype.getGroupsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.prototype.setGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.prototype.addGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.Group, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastFaq.prototype.clearGroupsList = function() {
  return this.setGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.FetchSupportedClientsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    supportedClients: (f = msg.getSupportedClients()) && proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.deserializeBinaryFromReader);
      msg.setSupportedClients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupportedClients();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.serializeBinaryToWriter
    );
  }
};


/**
 * optional BroadcastSupportedClients supported_clients = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.prototype.getSupportedClients = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.prototype.setSupportedClients = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.prototype.clearSupportedClients = function() {
  return this.setSupportedClients(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.SupportedClientsResponse.prototype.hasSupportedClients = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    supportedClients: (f = msg.getSupportedClients()) && proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.deserializeBinaryFromReader);
      msg.setSupportedClients(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupportedClients();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.serializeBinaryToWriter
    );
  }
};


/**
 * optional BroadcastSupportedClients supported_clients = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.prototype.getSupportedClients = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.prototype.setSupportedClients = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.prototype.clearSupportedClients = function() {
  return this.setSupportedClients(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.UpdateSupportedClientsRequest.prototype.hasSupportedClients = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.toObject = function(includeInstance, msg) {
  var f, obj = {
    android: (f = msg.getAndroid()) && proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.toObject(includeInstance, f),
    ios: (f = msg.getIos()) && proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.deserializeBinaryFromReader);
      msg.setAndroid(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.deserializeBinaryFromReader);
      msg.setIos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAndroid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.serializeBinaryToWriter
    );
  }
  f = message.getIos();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.toObject = function(includeInstance, msg) {
  var f, obj = {
    minBuildNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minSdkVersion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinBuildNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinSdkVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinBuildNumber();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMinSdkVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 min_build_number = 1;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.prototype.getMinBuildNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.prototype.setMinBuildNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 min_sdk_version = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.prototype.getMinSdkVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android.prototype.setMinSdkVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.toObject = function(includeInstance, msg) {
  var f, obj = {
    minVersion: (f = msg.getMinVersion()) && proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.toObject(includeInstance, f),
    minOs: (f = msg.getMinOs()) && proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.deserializeBinaryFromReader);
      msg.setMinVersion(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.deserializeBinaryFromReader);
      msg.setMinOs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinVersion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.serializeBinaryToWriter
    );
  }
  f = message.getMinOs();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.serializeBinaryToWriter
    );
  }
};


/**
 * optional SemVer min_version = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.prototype.getMinVersion = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.prototype.setMinVersion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.prototype.clearMinVersion = function() {
  return this.setMinVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.prototype.hasMinVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SemVer min_os = 2;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.prototype.getMinOs = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer, 2));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.prototype.setMinOs = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.prototype.clearMinOs = function() {
  return this.setMinOs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS.prototype.hasMinOs = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.toObject = function(includeInstance, msg) {
  var f, obj = {
    numbersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer;
  return proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNumbers(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumbersList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
};


/**
 * repeated int32 numbers = 1;
 * @return {!Array<number>}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.prototype.getNumbersList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.prototype.setNumbersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.prototype.addNumbers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.SemVer.prototype.clearNumbersList = function() {
  return this.setNumbersList([]);
};


/**
 * optional Android android = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.prototype.getAndroid = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.Android|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.prototype.setAndroid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.prototype.clearAndroid = function() {
  return this.setAndroid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.prototype.hasAndroid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional IOS iOS = 2;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.prototype.getIos = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS, 2));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.IOS|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.prototype.setIos = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.prototype.clearIos = function() {
  return this.setIos(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.broadcast.BroadcastSupportedClients.prototype.hasIos = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.broadcast);
