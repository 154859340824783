import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  createGroupLink,
  fetchListGroupLinks,
  GroupLink
} from '../actions/groupLinks';
import { RootState } from '../index';

const groupLinkAdapter = createEntityAdapter<GroupLink, string>({
  selectId: groupLink => groupLink.code
});

const groupLinkSlice = createSlice({
  name: 'groupLink',
  initialState: groupLinkAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchListGroupLinks.fulfilled, (state, { payload }) => {
        groupLinkAdapter.setAll(state, payload);
      })
      .addCase(createGroupLink.fulfilled, (state, { payload }) => {
        groupLinkAdapter.addOne(state, payload);
      });
  }
});

export const initialState = groupLinkSlice.getInitialState();
export type GroupLinkState = typeof initialState;

export default groupLinkSlice.reducer;
export const groupLinkSelectors = groupLinkAdapter.getSelectors<RootState>(
  state => state.groupLinks
);
