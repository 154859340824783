import React from 'react';

export const GodModeSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19.868'
    height='16.612'
    viewBox='0 0 19.868 16.612'
  >
    <g id='noun-god-3965047' transform='translate(-72.207 -57.677)'>
      <path
        id='Path_3021'
        data-name='Path 3021'
        d='M256.2,59.372l1.967,3.264a8.256,8.256,0,0,1,1.674.544l-3.138-5.23a.6.6,0,0,0-1,0l-3.138,5.209a8.254,8.254,0,0,1,1.674-.544Z'
        transform='translate(-174.061 0)'
      />
      <path
        id='Path_3022'
        data-name='Path 3022'
        d='M103.926,372.6l-2.49-4.121a7.87,7.87,0,0,1-.837.837l1.82,2.992h-16.7l1.82-2.992c-.335-.314-.607-.607-.837-.837l-2.49,4.121a.586.586,0,0,0,0,.586.563.563,0,0,0,.5.293h18.724a.563.563,0,0,0,.5-.293.644.644,0,0,0-.021-.586Z'
        transform='translate(-11.933 -299.192)'
      />
      <path
        id='Path_3023'
        data-name='Path 3023'
        d='M166.308,221.042a9.012,9.012,0,0,0,6.925-3.536.973.973,0,0,0,0-1.193,8.564,8.564,0,0,0-13.85.021.973.973,0,0,0,0,1.193A9.075,9.075,0,0,0,166.308,221.042Zm3.557-6.235a8.506,8.506,0,0,1,2.448,2.113,8.518,8.518,0,0,1-2.448,2.092,4.007,4.007,0,0,0,.586-2.113,4.483,4.483,0,0,0-.586-2.092Zm-3.557-.941a3.054,3.054,0,1,1-3.055,3.054A3.058,3.058,0,0,1,166.308,213.866Zm-3.557.941a4.007,4.007,0,0,0-.586,2.113,4.061,4.061,0,0,0,.586,2.113,8.838,8.838,0,0,1-2.448-2.113,8.505,8.505,0,0,1,2.448-2.113Z'
        transform='translate(-84.169 -149.327)'
      />
      <path
        id='Path_3024'
        data-name='Path 3024'
        d='M296.092,271.82a2.071,2.071,0,1,0,0-4.142,2.106,2.106,0,0,0-.816.167.86.86,0,0,1,.607.837.879.879,0,0,1-1.715.272,2.054,2.054,0,0,0,1.925,2.866Z'
        transform='translate(-213.953 -202.156)'
      />
    </g>
  </svg>
);
