import React from 'react';
import { KartSvg } from '../../assets/KartSvg';
import './Location.scss';
import { SubjectActionCard } from './SubjectCardsContainer/SubjectCard/SubjectActionCard';

export const LocationCard: React.FC = () => {
  return (
    <SubjectActionCard
      link='lokasjon'
      header=' Posisjonering i kart'
      icon={<KartSvg />}
      classes='location-card'
    ></SubjectActionCard>
  );
};
