import React from 'react';

export const AlertIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='48'
    height='48'
    viewBox='0 0 48 48'
  >
    <path
      id='Path_2923'
      data-name='Path 2923'
      d='M25,1A24,24,0,1,0,49,25,24,24,0,0,0,25,1ZM45.8,25A20.8,20.8,0,1,1,25,4.2,20.8,20.8,0,0,1,45.8,25ZM23.181,29.8,22.6,10.6h4.8l-.581,19.2ZM22.2,33.4h5.606V39H22.2Z'
      transform='translate(-1 -1)'
      fill='#2a2859'
    />
  </svg>
);
