import React, { PropsWithChildren } from 'react';
import './PageInfoText.scss';

interface PageInfoTextProps {
  type?: 'small' | 'medium' | 'regular';
}

export const PageInfoText: React.FC<PropsWithChildren<PageInfoTextProps>> = ({
  children,
  type = 'regular'
}) => (
  <section className={`page-info-text page-info-text--${type}`}>
    {children}
  </section>
);
