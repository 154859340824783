import { ReportAdminLoginRequest } from '../../proto/statistics_pb';
import { getGrpcMetadata, getStatisticsServiceClient } from './grpcRequest';

export const sendLoginStatistics = async (
  duration: number,
  userAgent: string,
  provider: string
) => {
  const request = new ReportAdminLoginRequest();
  request.setDurationMs(duration);
  request.setUserAgent(userAgent);
  request.setProvider(provider);

  await getStatisticsServiceClient().reportAdminLogin(
    request,
    await getGrpcMetadata()
  );
};
