import { OperationEntity } from '../../proto/shared_pb';
import { OperationInfo } from '../../redux/slice/keychainFactoryDetails';
import { Operation } from '../../redux/slice/operations';
import { Subject } from '../../redux/slice/subjects';
import { FullOperationId } from '../../redux/types/protoTypes';

import EntityCase = OperationEntity.EntityCase;

export const getSubject = (subjectId: string, subjects: Subject[]) =>
  subjects.find(subject => subject.id === subjectId);

export const getOperation = (
  fullOperationId: FullOperationId,
  subjects: Subject[]
) => {
  const subject = subjects.find(
    subject => subject.id === fullOperationId.subjectId
  );
  return subject?.operationsList.find(
    operation => operation.id === fullOperationId.operationId
  );
};

export const getOperationInfo = (
  operation: Operation,
  fullOperationId: FullOperationId
): OperationInfo => {
  return {
    operationId: fullOperationId.operationId,
    subjectId: fullOperationId.subjectId,
    type: operation?.operationCategory || EntityCase.ENTITY_NOT_SET,
    name: operation?.name
  };
};

export const getOperationName = (
  operation: { subjectId: string; operationId: string },
  subjects: Subject[]
) => {
  let operationName = '';
  subjects.forEach(subject => {
    if (subject.id === operation.subjectId) {
      subject.operationsList.forEach(op => {
        if (op.id === operation.operationId) {
          operationName = op.name;
        }
      });
    }
  });
  return operationName;
};

export const getSubjectName = (
  operation: { subjectId: string; operationId: string },
  subjects: Subject[]
) => {
  let subjectName = '';
  for (let i = 0; i < subjects.length; i++) {
    if (subjects[i].id === operation.subjectId) {
      subjectName = subjects[i].name;
      break;
    }
  }
  return subjectName;
};

export const getSubjectImage = (subjectId: string, subjects: Subject[]) => {
  const subject = subjects.find(s => s.id === subjectId);

  if (subject) {
    if (subject.imagesList.length > 0) {
      return subject.imagesList[0].uri;
    }
  }
  return null;
};
