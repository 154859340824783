import React, { useRef } from 'react';
import { handleImageInput } from '../../../utils/operation/images/imageInput';
import { Button } from '../../Button/Button';

interface Props {
  image: File | null;
  setError: (error: string) => void;
  setImage: (image: File | null) => void;
}

export const InputButtons: React.FC<Props> = ({
  image,
  setError,
  setImage
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  return (
    <>
      <input
        type='file'
        id='upload'
        ref={fileInput}
        onChange={evt => {
          handleImageInput(evt, setError, setImage);
        }}
      />
      <Button
        label='Velg bilde'
        type='primary'
        onClick={() => {
          if (fileInput.current !== null) {
            fileInput.current.click();
          }
        }}
      />
      {image && (
        <Button
          label='Fjern bilde'
          type='destructive'
          onClick={() => setImage(null)}
        />
      )}
    </>
  );
};
