import { differenceInMinutes } from 'date-fns';
import React, { useEffect } from 'react';
import { getBuildVersion } from '../../redux/actions/version';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearNewVersion } from '../../redux/slice/popup';
import './Popup.scss';

export const RefreshNotification = () => {
  const dispatch = useAppDispatch();
  const { lastCheckedForNewVersion, showNewBuildVersion, popup } =
    useAppSelector(state => state.popup);
  useEffect(() => {
    const handleFocus = () => {
      const minutes = differenceInMinutes(Date.now(), lastCheckedForNewVersion);
      if (minutes > 10) {
        dispatch(getBuildVersion());
      }
    };
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [lastCheckedForNewVersion]);

  if (showNewBuildVersion && !popup) {
    return (
      <div className='popup-container refresh'>
        <img src='/icon192prod.png' className='icon' alt='Keystudio' />
        <div className='column'>
          <span
            onClick={() => {
              window.location.reload();
            }}
          >
            <h3>Oi!!</h3>
            Keystudio har blitt oppdatert. Trykk her for å få den nyeste
            versjonen
          </span>
        </div>
        <button
          className='cross-container'
          onClick={() => dispatch(clearNewVersion())}
        >
          <div className='line' style={{ transform: 'rotate(45deg)' }} />
          <div className='line' style={{ transform: 'rotate(135deg)' }} />
        </button>
      </div>
    );
  } else {
    return null;
  }
};
