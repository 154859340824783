import { flip, shift } from '@floating-ui/react';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { KeyDateTime } from '../Forms/KeyDateTime';
import { TimePickerProps } from './DatePickerProps';
import './DatePickerStyle.scss';

export const TimePicker: React.FC<TimePickerProps> = ({
  disabled = false,
  inline = false,
  position,
  name = 'time'
}) => (
  <div className={`datepicker ${inline ? 'datepicker--inline' : ''}`}>
    <KeyDateTime
      type='time'
      name={name}
      elementProps={{
        popperPlacement: position,
        popperModifiers: [shift(), flip()],
        dateFormat: 'HH:mm',
        showTimeSelect: true,
        showTimeSelectOnly: true,
        disabled: disabled
      }}
    />
  </div>
);
