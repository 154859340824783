import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { DeleteAndConfirmMenu } from '../../../components/DeleteAndConfirm/DeleteAndConfirm';
import { Breadcrumbs } from '../../../components/Navigation/breadcrumbs';
import { thirdPartySystemHome } from '../../../components/Navigation/controlpanelNavPaths';
import { KeyStudioCard } from '../../../components/cards/KeyStudioCard/KeyStudioCard';
import { deleteThirdPartySystem } from '../../../redux/actions/thirdPartySystems';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { thirdPartySystemSelectors } from '../../../redux/slice/thirdPartySystems';
import { getKeystudioLink } from '../../../utils/citykeyId';
import { useAdminRole } from '../../../utils/roles/useAdminRole';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { AssetsTable } from './AssetsTable';
import { ThirdPartySystemsContainer } from './ThirdPartySystemsContainer';

interface MatchParams {
  thirdPartySystemId: string;
}

export const ThirdPartySystem: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { thirdPartySystemId = '' } = useParams<keyof MatchParams>();
  const thirdPartySystem = useAppSelector(state =>
    thirdPartySystemSelectors.selectById(state, thirdPartySystemId)
  );
  const role = useAdminRole(thirdPartySystem?.adminRole || '');
  if (thirdPartySystem) {
    return (
      <ThirdPartySystemsContainer>
        <ControlpanelBreadcrumbs currentPageName={thirdPartySystem.name} />
        <DeleteAndConfirmMenu
          label='Slett tredjepartssystem'
          message='Er du sikker på at du vil slette tredjepartssystemet?'
          deleteAction={() =>
            dispatchAndHandleResult(
              dispatch,
              deleteThirdPartySystem,
              thirdPartySystem.id,
              () => navigate('..')
            )
          }
        />

        <div className='cards actionmenu-ribbon'>
          <KeyStudioCard>
            <h3>Informasjon</h3>

            <p>
              {thirdPartySystem.information !== ''
                ? thirdPartySystem.information
                : 'Mangler beskrivelse'}
            </p>
          </KeyStudioCard>

          <KeyStudioCard>
            <h3>Event Callback Uri</h3>
            <p>
              {thirdPartySystem.eventCallbackUri
                ? thirdPartySystem.eventCallbackUri
                : 'Ingen'}
            </p>
          </KeyStudioCard>
          <Link to={getKeystudioLink(role?.uri)}>
            <KeyStudioCard isLink>
              <h3>Adminrolle</h3>
              <p>{role?.name || thirdPartySystem?.adminRole}</p>
            </KeyStudioCard>
          </Link>
        </div>
        <AssetsTable
          managedassetsList={thirdPartySystem.managedAssetsList}
          id={thirdPartySystemId}
          version={thirdPartySystem.version}
        />
      </ThirdPartySystemsContainer>
    );
  } else {
    if (thirdPartySystemId === 'lag-ny') {
      return null;
    } else {
      return (
        <ThirdPartySystemsContainer>
          <Breadcrumbs
            breadcrumbs={[thirdPartySystemHome, { name: thirdPartySystemId }]}
          />
          <h3>Finner ikke tredjepartssystem med id {thirdPartySystemId}. </h3>
        </ThirdPartySystemsContainer>
      );
    }
  }
};
