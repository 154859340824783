import React from 'react';
import { OpeningHours } from '../../../../redux/types/common';
import { AlwaysOpenText } from './AlwaysOpenText';
import { OverridingHoursList } from './OverridingHoursList';

interface Props {
  openingHours: OpeningHours;
  setOpeningHours: (openingHours: OpeningHours) => void;
}

export const ManualGrantKeychainFactoryOverridingHours: React.FC<Props> = ({
  openingHours,
  setOpeningHours
}) => (
  <div className='hours-card'>
    <h3>Overstyrende åpningstider</h3>
    {openingHours.alwaysOpen ? (
      <AlwaysOpenText />
    ) : (
      <OverridingHoursList
        openingHours={openingHours}
        setOpeningHours={setOpeningHours}
      />
    )}
  </div>
);
