import React from 'react';
import { signOut } from '../../utils/keycloak';
import { Button } from '../Button/Button';
import './LogoutSection.scss';

export const LogoutSection: React.FC = () => {
  return (
    <div className='logout-section'>
      <Button
        label='Logg ut'
        onClick={signOut}
        className='logout-button'
        type='primary'
      />
    </div>
  );
};
