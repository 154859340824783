import React from 'react';
import './KeychainFactoryContainer.scss';

interface KeychainFactoryContainerProps {
  children: React.ReactNode;
}

export const KeychainFactoryContainer: React.FC<
  KeychainFactoryContainerProps
> = ({ children }) => (
  <section className='keystudio-keychain-factory-container'>{children}</section>
);
