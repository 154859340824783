import React from 'react';
import { PreContainer } from '../../../components/code/PreContainer';
import { useAppSelector } from '../../../redux/hooks';
import { groupLinkSelectors } from '../../../redux/slice/groupLinks';
import { useKeychainFactoryDetails } from '../KeychainDetailsWrapper';
import { KeychainFactoryBreadcrumbs } from '../KeychainFactoryBreadcrumbs';
import { GroupLinkMenu } from './GroupLinkMenu';

export const GroupLinkPage: React.FC = () => {
  const { keychainFactory } = useKeychainFactoryDetails();
  const groupLinks = useAppSelector(groupLinkSelectors.selectAll);
  const filteredGroupLinks = groupLinks.filter(
    groupLink =>
      groupLink.keychainFactoryUri === keychainFactory.keychainFactoryUri
  );
  return (
    <section>
      <KeychainFactoryBreadcrumbs
        keychainFactory={keychainFactory}
        name='Spre nøkkel'
      />
      <GroupLinkMenu keychainFactory={keychainFactory} />
      {filteredGroupLinks.length ? (
        <React.Fragment>
          <h4>Eksisterende spredninger:</h4>
          <PreContainer obj={filteredGroupLinks} />
        </React.Fragment>
      ) : (
        <p>Ingen eksisterende spredninger</p>
      )}
    </section>
  );
};
