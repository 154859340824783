import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { z } from 'zod';
import { Term as GrpcTerm } from '../../proto/term_pb';
import {
  createTerm,
  getTerms,
  reviseTerm,
  updateTermOwner
} from '../actions/terms';
import { RootState } from '../index';
import { TextContent } from '../types/common';

export const Term = z.object({
  id: z.string(),
  title: z.string(),
  currentText: TextContent,
  currentRevisionNumber: z.number(),
  currentRevisionTimestamp: z.number(),
  type: z.nativeEnum(GrpcTerm.Type),
  archived: z.boolean(),
  ownerName: z.string(),
  adminRole: z.string()
});

export type Term = z.infer<typeof Term>;

const termsAdapter = createEntityAdapter<Term>({});

const termsSlice = createSlice({
  name: 'terms',
  initialState: termsAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTerms.fulfilled, (state, { payload }) => {
        termsAdapter.setAll(state, payload);
      })
      .addCase(createTerm.fulfilled, (state, { payload }) => {
        const term = Term.parse({
          ...payload,
          currentText: TextContent.parse(payload.text),
          currentRevisionNumber: 1,
          currentRevisionTimestamp: Date.now(),
          archived: false
        });
        termsAdapter.addOne(state, term);
      })
      .addCase(reviseTerm.fulfilled, (state, { payload }) => {
        const term = state.entities[payload.termId];
        if (term) {
          termsAdapter.updateOne(state, {
            id: payload.termId,
            changes: {
              currentText: TextContent.parse(payload.text),
              currentRevisionNumber: term.currentRevisionNumber + 1,
              currentRevisionTimestamp: Date.now(),
              title: payload.title
            }
          });
        }
      })
      .addCase(updateTermOwner.fulfilled, (state, { payload }) => {
        termsAdapter.updateOne(state, {
          id: payload.id,
          changes: {
            ownerName: payload.ownerName,
            adminRole: payload.adminRole
          }
        });
      });
  }
});

export const initialState = termsSlice.getInitialState();
export type TermsState = typeof initialState;
export default termsSlice.reducer;

export const termsSelectors = termsAdapter.getSelectors<RootState>(
  state => state.terms
);
