import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import { ENV } from '../constants';

const safetycloakConfProd: KeycloakConfig = {
  realm: 'keystudio',
  url: 'https://api.prod.citykey.oslo.systems/web/oidc/',
  clientId: 'keystudio'
};

const localSafetycloakConfig: KeycloakConfig = {
  url: `https://${process.env.REACT_APP_HOST}:10200/web/oidc`, // The port mapped to host network in dev-env
  realm: 'keystudio',
  clientId: 'oslonokkelen-admin'
};

const getKeycloakConf = () => {
  const isLocalOrTest = ENV.includes('local') || ENV.includes('test');
  return isLocalOrTest ? localSafetycloakConfig : safetycloakConfProd;
};

export const keycloak = new Keycloak(getKeycloakConf());

export const initOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  checkLoginIframe: false,
  enableLogging: true,
  pkceMethod: 'S256'
};
