import { NavigateFunction } from 'react-router-dom';

export const getPrevPathname = (pathname?: string) => {
  const currentPathname = pathname ? pathname : window.location.pathname;
  const lastSlashIndex = currentPathname.lastIndexOf('/');
  return currentPathname.substr(0, lastSlashIndex);
};

export const historyGoBack = (navigate: NavigateFunction) => {
  const prevPathname = getPrevPathname();
  navigate(prevPathname);
};
