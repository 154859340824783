import { z } from 'zod';
import {
  CreateTermRequest,
  ListTermsRequest,
  ReviseTermRequest,
  Term as GrpcTerm,
  UpdateTermOwnerRequest
} from '../../proto/term_pb';
import {
  getGrpcMetadata,
  getTermClient,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import { setPopup } from '../slice/popup';
import { Term } from '../slice/terms';
import {
  id,
  name,
  noValidationString,
  optionTypeSchema,
  stringNonEmpty,
  title
} from '../types/zodSchemas';
import { createText } from './util';

import Type = GrpcTerm.Type;

export const getTerms = createAppAsyncThunk(
  'terms/getTerms',
  async (arg, thunkAPI) => {
    try {
      const response = (
        await getTermClient().listTerms(
          new ListTermsRequest(),
          await getGrpcMetadata()
        )
      ).toObject();

      return response.termsList.map(TermToModel);
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const CreateTermSchema = z.object({
  id: id,
  title: title,
  ownerName: name,
  adminRole: optionTypeSchema,
  text: stringNonEmpty,
  type: z.object({
    value: z.nativeEnum(Type),
    label: z.string()
  })
});

export type CreateTermProps = z.infer<typeof CreateTermSchema>;

export const createTerm = createAppAsyncThunk<
  CreateTermRequest.AsObject,
  CreateTermProps
>(
  'terms/createTerm',
  async ({ id, title, ownerName, adminRole, text, type }, thunkAPI) => {
    try {
      const request = new CreateTermRequest()
        .setId(id)
        .setTitle(title)
        .setOwnerName(ownerName)
        .setAdminRole(adminRole.value)
        .setText(createText(text))
        .setType(type.value);
      await getTermClient().createTerm(request, await getGrpcMetadata());
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Betingelsen er laget!' })
      );
      thunkAPI.dispatch(getTerms());
      return request.toObject();
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

interface ReviseTermProps {
  termId: string;
  text: string;
  title: string;
  currentRevisionNumber: number;
}

export const reviseTerm = createAppAsyncThunk<
  ReviseTermRequest.AsObject,
  ReviseTermProps
>(
  'terms/reviseTerm',
  async ({ termId, text, title, currentRevisionNumber }, thunkAPI) => {
    try {
      const request = new ReviseTermRequest()
        .setTermId(termId)
        .setText(createText(text))
        .setTitle(title)
        .setCurrentRevisionNumber(currentRevisionNumber);
      await getTermClient().reviseTerm(request, await getGrpcMetadata());
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Betingelsen er oppdatert!'
        })
      );
      return request.toObject();
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const UpdateTermOwner = z.object({
  id: noValidationString,
  ownerName: stringNonEmpty,
  adminRole: optionTypeSchema
});

export type UpdateTermOwner = z.infer<typeof UpdateTermOwner>;
export const updateTermOwner = createAppAsyncThunk<
  UpdateTermOwnerRequest.AsObject,
  UpdateTermOwner
>('terms/updateTermOwner', async ({ id, ownerName, adminRole }, thunkAPI) => {
  try {
    const request = new UpdateTermOwnerRequest()
      .setId(id)
      .setOwnerName(ownerName)
      .setAdminRole(adminRole.value);
    await getTermClient().updateTermOwner(request, await getGrpcMetadata());
    thunkAPI.dispatch(
      setPopup({
        error: false,
        message: 'Informasjon om eier er oppdatert!'
      })
    );
    return request.toObject();
  } catch (error) {
    return handleGrpcError(error, thunkAPI);
  }
});

const TermToModel = (term: GrpcTerm.AsObject) => {
  return Term.parse({
    ...term,
    currentRevisionTimestamp: term.currentRevisionTimestamp?.epochMillis || 0
  });
};
