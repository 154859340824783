import React, { useEffect } from 'react';
import './Toggle.scss';

interface ToggleProps {
  allIds: string[];
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
}

export const ToggleAll = ({
  allIds,
  selectedIds,
  setSelectedIds
}: ToggleProps) => {
  const [selected, setSelected] = React.useState(false);

  useEffect(() => {
    setSelected(
      allIds.every(id => selectedIds.find(selected => selected === id))
    );
  }, [allIds.length, selectedIds.length]);

  const handleClick = () => {
    if (selected) {
      setSelectedIds([]);
      setSelected(false);
    } else {
      setSelectedIds(allIds);
      setSelected(true);
    }
  };

  const className = selected ? 'toggle selected' : 'toggle';

  return (
    <li className={className}>
      <label className='osg-txt7'>
        <input
          type='checkbox'
          checked={selected}
          onChange={handleClick}
          className='ods-form-check-input'
        />
        Velg alle
      </label>
    </li>
  );
};
