import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Breadcrumb,
  Breadcrumbs
} from '../../components/Navigation/breadcrumbs';
import {
  actionsHome,
  adminAdapterHome,
  adminCategoryHome,
  adminKeychainHome,
  adminSubjectHome,
  adminAdmissionCardsHome,
  godModeHome,
  pvdHome,
  downloadsHome,
  thirdPartySystemHome,
  adminOidcHome,
  adminPublicKeychainHome,
  adminBucketHome, faqHome
} from '../../components/Navigation/controlpanelNavPaths';
import { controlPanelHome } from '../../components/Navigation/navPaths';
import { ActionChain } from '../../redux/slice/adapters';

type Props = {
  currentPageName?: string;
};
export const ControlpanelBreadcrumbs: React.FC<Props> = ({
  currentPageName
}) => {
  const breadcrumbs: Breadcrumb[] = generateBreadcrumbs(
    decodeURI(useLocation().pathname),
    [],
    currentPageName
  );

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
interface SubjectProps extends Props {
  currentPageName: string;
  parentName: string;
  grandParentName?: string;
}
export const ControlpanelSubjectBreadcrumbs: React.FC<SubjectProps> = ({
  currentPageName,
  parentName,
  grandParentName
}) => {
  const breadcrumbs: Breadcrumb[] = [
    { name: parentName, path: '..' },
    { name: currentPageName }
  ];
  if (grandParentName) {
    breadcrumbs.unshift({ name: grandParentName, path: '../..' });
  }

  return (
    <Breadcrumbs
      breadcrumbs={generateBreadcrumbs(useLocation().pathname, breadcrumbs)}
    />
  );
};

interface AdapterProps extends Props {
  currentPageName: string;
  adapterName: string;
}

export const ControlpanelAdapterBreadcrumbs: React.FC<AdapterProps> = ({
  currentPageName,
  adapterName
}) => {
  const breadcrumbs: Breadcrumb[] = generateBreadcrumbs(
    useLocation().pathname,
    [{ name: adapterName, path: '../..' }, { name: currentPageName }]
  );

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};

type ActionChainProps = {
  actionChain: ActionChain;
};
export const ControlpanelActionChainsBreadcrumbs: React.FC<
  ActionChainProps
> = ({ actionChain }) => {
  const breadcrumbs: Breadcrumb[] = generateBreadcrumbs(
    useLocation().pathname,
    [
      {
        name: actionChain.name
      }
    ]
  );

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
const generateBreadcrumbs = (
  pathname: string,
  breadcrumbs: Breadcrumb[],
  name?: string
): Breadcrumb[] => {
  switch (pathname) {
    case '':
      return breadcrumbs;
    case actionsHome.path:
      breadcrumbs.unshift(actionsHome);
      break;
    case adminAdapterHome.path:
      breadcrumbs.unshift(adminAdapterHome);
      break;
    case faqHome.path:
      breadcrumbs.unshift(faqHome);
      break;
    case adminCategoryHome.path:
      breadcrumbs.unshift(adminCategoryHome);
      break;
    case adminKeychainHome.path:
      breadcrumbs.unshift(adminKeychainHome);
      break;
    case adminPublicKeychainHome.path:
      breadcrumbs.unshift(adminPublicKeychainHome);
      break;
    case adminSubjectHome.path:
      breadcrumbs.unshift(adminSubjectHome);
      break;
    case adminAdmissionCardsHome.path:
      breadcrumbs.unshift(adminAdmissionCardsHome);
      break;
    case controlPanelHome.path:
      breadcrumbs.unshift(controlPanelHome);
      break;
    case godModeHome.path:
      breadcrumbs.unshift(godModeHome);
      break;
    case pvdHome.path:
      breadcrumbs.unshift(pvdHome);
      break;
    case adminOidcHome.path:
      breadcrumbs.unshift(adminOidcHome);
      break;
    case downloadsHome.path:
      breadcrumbs.unshift(downloadsHome);
      break;
    case thirdPartySystemHome.path:
      breadcrumbs.unshift(thirdPartySystemHome);
      break;
    case adminBucketHome.path:
      breadcrumbs.unshift(adminBucketHome);
      break;
    default:
      if (!name) {
        //Not all paths have a separate page.
        break;
      }
      breadcrumbs.unshift({ name: name });
  }
  return generateBreadcrumbs(
    pathname.substring(0, pathname.lastIndexOf('/')),
    breadcrumbs
  );
};
