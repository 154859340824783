import React, { Fragment, useEffect } from 'react';
import { Breadcrumbs } from '../../components/Navigation/breadcrumbs';
import { auditHome } from '../../components/Navigation/navPaths';
import { PageInfoText } from '../../components/PageInfoText/PageInfoText';
import { auditLog } from '../../redux/actions/operations';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AuditForm } from './AuditForm';
import { AuditSearchResult } from './AuditSearchResult';
import { AuditTable } from './AuditTable';
import './audit.scss';

export const AuditPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const log = useAppSelector(state => state.operations.auditLog);
  const auditResult = useAppSelector(
    state => state.operations.auditSearchResult
  );
  useEffect(() => {
    if (log.length === 0) {
      dispatch(auditLog());
    }
  }, [dispatch, log.length]);

  return (
    <Fragment>
      <Breadcrumbs breadcrumbs={[auditHome]} />
      <PageInfoText type='medium'>
        Du kan få innsyn i besøkslogg ved mislighold. Loggen viser hvem som har
        brukt Oslonøkkelen på en gitt dør i et gitt tidsrom. Av personvernhensyn
        må du vurdere om du har et tjenstlig behov for denne informasjonen,
        behandle den på en sikker måte, ikke dele den med andre uten tjenstlig
        behov, og slette den når du ikke lenger har behov for den. Det blir
        logget at du har bedt om innsyn i besøksloggen for dette tidsrommet.
      </PageInfoText>
      <div className='audit-page'>
        {auditResult ? (
          <AuditSearchResult auditResult={auditResult} />
        ) : (
          <AuditForm />
        )}
        <AuditTable log={log} />
      </div>
    </Fragment>
  );
};
