import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearAgeLimit, setAgeLimit } from '../../../redux/actions/operations';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { Operation } from '../../../redux/slice/operations';
import { historyGoBack } from '../../../utils/history';
import { Button } from '../../Button/Button';
import { ToggleGroup } from '../../ToggleGroup/ToggleButton';
import './EditAgeLimit.scss';

interface Props {
  subjectId: string;
  operation: Operation;
  setShowNavigateConfirm: (showNavigateConfirm: boolean) => void;
}

export const EditAgeLimit: React.FC<Props> = ({ operation, subjectId }) => {
  const { ageLimit } = operation;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isAgeLimit, enableAgeLimit] = useState(ageLimit !== 0);
  const [ageInput, setAgeInput] = useState(ageLimit ? ageLimit + '' : '');
  const buttons = [
    {
      label: 'Ingen aldersgrense',
      value: 'false',
      onClick: () => enableAgeLimit(false)
    },
    {
      label: 'Alderspesifikk',
      value: 'true',
      onClick: () => enableAgeLimit(true)
    }
  ];

  const onSave = () => {
    const callback = () => {
      historyGoBack(navigate);
    };
    if (isAgeLimit) {
      dispatchAndHandleResult(
        dispatch,
        setAgeLimit,
        {
          subjectId,
          operationId: operation.id,
          ageLimit: parseInt(ageInput)
        },
        callback
      );
    } else {
      dispatchAndHandleResult(
        dispatch,
        clearAgeLimit,
        { subjectId, operationId: operation.id },
        callback
      );
    }
  };

  return (
    <div>
      <p className='page-info-text py-size-10 page-info-text--small'>
        Her kan du endre aldersgrensen til dette stedet. Dersom ingen
        aldersgrense er satt, blir ikke alder vurdert før åpning av dør.
      </p>
      <ToggleGroup buttons={buttons} selectedValue={isAgeLimit + ''} />
      {isAgeLimit && (
        <div className='input-group age-limit'>
          <input readOnly className='input-prefix' value='År' />
          <input
            type='number'
            placeholder='Nedre aldersgrense'
            value={ageInput}
            onChange={e => {
              setAgeInput(e.currentTarget.value);
            }}
          />
        </div>
      )}
      {!isAgeLimit && (
        <div className={'placeholder'}>Ingen aldersverifisering.</div>
      )}
      <div className='button-group'>
        <Button label='Lagre' type='primary' onClick={onSave} />
      </div>
    </div>
  );
};
