import React from 'react';
import { DescriptionSvg } from '../../assets/DescriptionSvg';
import { SubjectActionCard } from '../../pages/places/SubjectCardsContainer/SubjectCard/SubjectActionCard';
import { StickeredContent } from '../Sticker/StickeredContent';

export interface AnomalyCardProps {
  about: string;
}

export const AboutCard: React.FC<AnomalyCardProps> = ({ about }) => {
  return (
    <StickeredContent
      stickerText={about ? '' : 'Legg inn beskrivelse for stedet'}
    >
      <SubjectActionCard
        icon={<DescriptionSvg />}
        link='about'
        header={
          about ? 'Offentlig beskrivelse' : 'Mangler offentlig beskrivelse'
        }
      />
    </StickeredContent>
  );
};
