import React from 'react';
import { useAppSelector } from '../../../../../redux/hooks';
import { subjectSelectors } from '../../../../../redux/slice/subjects';
import { SubjectList } from './subjectList/SubjectList';

export const AddLocks: React.FC = () => {
  const subjects = useAppSelector(subjectSelectors.selectAll);

  return (
    <section>
      <h2>Legg til lås</h2>
      <p>Finn og velg låser som brukere skal få tilgang til</p>
      <SubjectList subjects={subjects} />
    </section>
  );
};
