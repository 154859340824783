import React, { Fragment, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EditableValue } from '../../../components/EditableValue/EditableValue';
import { ToggleGroup } from '../../../components/ToggleGroup/ToggleButton';
import { OperationEntity } from '../../../proto/shared_pb';
import { updateOperationType } from '../../../redux/actions/operations';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';

import EntityCase = OperationEntity.EntityCase;

type Props = {
  subjectId: string;
  operationId: string;
  operationTypeInitial: EntityCase;
};
export const OperationType: React.FC<Props> = ({
  subjectId,
  operationId,
  operationTypeInitial
}) => {
  const dispatch = useAppDispatch();
  const [operationType, setOperationType] = useState(operationTypeInitial);
  const toggleButtons = useMemo(() => {
    const buttons = [
      {
        value: EntityCase.DOOR.toString(),
        label: 'Dør',
        onClick: () => {
          setOperationType(EntityCase.DOOR);
        }
      },
      {
        value: EntityCase.LIGHT.toString(),
        label: 'Lys',
        onClick: () => {
          setOperationType(EntityCase.LIGHT);
        }
      }
    ];
    if (
      operationType !== EntityCase.LIGHT &&
      operationType !== EntityCase.DOOR
    ) {
      buttons.push({
        value: operationType.toString(),
        label: `Ukjent (${operationType.toString()})`,
        onClick: () => {
          setOperationType(EntityCase.ENTITY_NOT_SET);
        }
      });
    }
    return buttons;
  }, [operationType, subjectId, operationId, dispatch]);
  const saveType = () =>
    dispatchAndHandleResult(dispatch, updateOperationType, {
      subjectId: subjectId,
      operationId: operationId,
      operationType: operationType
    });

  return (
    <Fragment>
      <EditableValue
        methods={useForm()}
        label='Operasjonstype'
        value={operationTypeInitial === EntityCase.DOOR ? 'Dør' : 'Lys'}
        mainButtonClick={saveType}
      >
        <ToggleGroup
          buttons={toggleButtons}
          selectedValue={operationType.toString()}
        />
      </EditableValue>
    </Fragment>
  );
};
