import React from 'react';

export const Visible: React.FC = () => (
  <svg
    width='35'
    height='40'
    viewBox='0 0 37 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Frame' clipPath='url(#clip0_130_1091)'>
      <g id='Group'>
        <g id='Group_2'>
          <g id='Group_3'>
            <path
              id='Vector_3'
              d='M35.7798 20.6025C35.6493 20.472 34.0834 18.8735 31.6041 17.1771L33.17 14.502C33.4309 14.0779 33.2678 13.5233 32.8437 13.2949C32.4196 13.0666 31.865 13.1971 31.6367 13.6212L30.136 16.231C29.1247 15.6438 28.0155 15.0892 26.8085 14.6325L27.5262 11.8921C27.6567 11.4028 27.3631 10.9461 26.9063 10.8156C26.4496 10.6851 25.9603 10.9787 25.8298 11.4354L25.1447 14.0453C23.9703 13.719 22.7306 13.4907 21.4257 13.4254V10.8808C21.4257 10.3915 21.0342 10 20.5449 10C20.0555 10 19.664 10.3915 19.664 10.8808V13.4254C18.3917 13.4907 17.1521 13.719 15.945 14.0453L15.2599 11.4354C15.1294 10.9461 14.6401 10.6851 14.1834 10.8156C13.694 10.9461 13.433 11.4354 13.5635 11.8921L14.2812 14.6325C13.0742 15.0892 11.965 15.6438 10.9537 16.231L9.45304 13.6212C9.22468 13.1971 8.67009 13.0666 8.24599 13.2949C7.82189 13.5559 7.6914 14.0779 7.91976 14.502L9.48566 17.1771C6.94107 18.8735 5.37516 20.472 5.24467 20.6025C4.91844 20.9287 4.91844 21.4833 5.24467 21.8095C5.53828 22.1358 12.1607 29.0518 20.5122 29.0518C28.8637 29.0518 35.4862 22.1031 35.7798 21.8095C36.0734 21.4833 36.0734 20.9613 35.7798 20.6025ZM20.5122 26.7356C17.4783 26.7356 14.9989 24.2562 14.9989 21.2223C14.9989 18.1884 17.4783 15.709 20.5122 15.709C23.5462 15.709 26.0255 18.1884 26.0255 21.2223C26.0255 24.2562 23.5462 26.7356 20.5122 26.7356ZM15.2926 16.1331C14.0203 17.438 13.2373 19.2323 13.2373 21.2223C13.2373 23.1797 14.0203 24.974 15.2926 26.3115C11.443 24.9087 8.44173 22.3967 7.20205 21.2223C8.44173 20.0805 11.443 17.5359 15.2926 16.1331ZM25.7319 26.3115C27.0042 25.0066 27.7872 23.2123 27.7872 21.2223C27.7872 19.2323 27.0042 17.4707 25.7319 16.1657C29.5488 17.5685 32.5827 20.0805 33.8224 21.2549C32.5827 22.3967 29.5814 24.9087 25.7319 26.3115Z'
              fill='#292858'
            />
            <path
              id='Vector_4'
              d='M20.5123 17.8293C20.0556 17.8293 19.5989 17.9272 19.2074 18.0903C19.501 18.3187 19.6641 18.6775 19.6641 19.1016C19.6641 19.8193 19.0769 20.4066 18.3592 20.4066C17.9677 20.4066 17.6089 20.2108 17.3479 19.9498C17.1848 20.3413 17.0869 20.798 17.0869 21.2548C17.0869 23.1469 18.6202 24.6476 20.4797 24.6476C22.3718 24.6476 23.8725 23.1143 23.8725 21.2548C23.8725 19.3952 22.4045 17.8293 20.5123 17.8293Z'
              fill='#292858'
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id='clip0_130_1091'>
        <rect width='40' height='40' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
