import React from 'react';
import { useFormContext } from 'react-hook-form';
import { EndDatePicker } from '../../../components/DatePickers/EndDatePicker';
import { StartDatePicker } from '../../../components/DatePickers/StartDatePicker';
import './StatsDatePicker.scss';

export type StatsDatePickerProps = {
  startDate: Date;
  endDate: Date;
};

export const StatsDatePicker = () => {
  const { watch } = useFormContext<StatsDatePickerProps>();
  const [startDate, endDate] = watch(['startDate', 'endDate']);
  return (
    <div className='stats-datepicker-container'>
      <StartDatePicker
        name='startDate'
        startDate={startDate}
        endDate={endDate}
        inline
      />
      <EndDatePicker
        name='endDate'
        startDate={startDate}
        endDate={endDate}
        inline
      />
    </div>
  );
};
