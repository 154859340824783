import {
  Autocomplete,
  Circle,
  GoogleMap,
  Marker
} from '@react-google-maps/api';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { KeySearch } from '../../components/Forms/KeySearch';
import { osloBounds } from '../../constants';
import { Coordinates } from './Location';
import './Location.scss';

interface Props {
  mapCenterCoordinates: Coordinates;
  markerCoordinates: Coordinates;
  setMarkerCoordinates: (coordinates: Coordinates) => void;
  radii: [number, number];
}
export const MapView: React.FC<Props> = ({
  mapCenterCoordinates,
  markerCoordinates,
  setMarkerCoordinates,
  radii
}) => {
  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete>();
  const [map, setMap] = useState<google.maps.Map>();

  const handleLocationChange = (coordinates: Coordinates) => {
    setMarkerCoordinates(coordinates);
  };
  const onPlaceChanged = () => {
    if (autocomplete) {
      const coordinates = autocomplete.getPlace().geometry?.location?.toJSON();
      if (coordinates) {
        handleLocationChange(coordinates);
        map?.panTo(coordinates);
      }
    } else {
      console.error('Autocomplete not loaded');
    }
  };

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      handleLocationChange(e.latLng.toJSON());
    }
  };

  const RadiusCircles = radii.map((radius, index) => {
    if (radius <= 0 || (index === 1 && radii[0] === radii[1])) {
      return null;
    }
    return (
      <Circle
        key={index}
        center={markerCoordinates}
        onClick={onMapClick}
        options={{ radius: radius }}
      />
    );
  });

  const methods = useForm();

  return (
    <React.Fragment>
      <Autocomplete
        onPlaceChanged={onPlaceChanged}
        onLoad={ref => setAutocomplete(ref)}
        bounds={osloBounds}
      >
        <FormProvider {...methods}>
          <KeySearch
            name='search'
            label='Adresse-søk'
            description='Søk opp stedet for å plassere nålen automatisk'
            elementProps={{ id: 'autocomplete' }}
          />
        </FormProvider>
      </Autocomplete>
      <GoogleMap
        mapContainerClassName='map-container'
        center={mapCenterCoordinates}
        zoom={12}
        clickableIcons={false}
        onClick={onMapClick}
        onLoad={ref => setMap(ref)}
        options={{ streetViewControl: false }}
      >
        <Marker position={markerCoordinates} clickable={false} />
        {RadiusCircles}
      </GoogleMap>
    </React.Fragment>
  );
};
