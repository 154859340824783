import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { CreateSection } from '../../../components/CreateSection/CreateSection';
import { KeyText } from '../../../components/Forms/KeyText';
import {
  createAdapter,
  CreateAdapterForm
} from '../../../redux/actions/adapters';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const CreateAdapter = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const methods = useForm<CreateAdapterForm>({
    resolver: zodResolver(CreateAdapterForm)
  });

  const onSubmit = (data: CreateAdapterForm) =>
    dispatchAndHandleResult(dispatch, createAdapter, data, () =>
      navigate('..')
    );

  return (
    <CreateSection>
      <ControlpanelBreadcrumbs currentPageName='Lag nytt adapter' />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <KeyText
            name='adapterId'
            label='Adapter ID'
            description='Adapter ID'
            elementProps={{
              placeholder: 'Skriv inn adapter-ID'
            }}
          />

          <KeyText
            name='adapterEndpointUri'
            label='Endpoint URI'
            description='Adapter Endpoint URI'
            elementProps={{
              placeholder: 'https://example.com/api/oslonokkelen-adapter'
            }}
          />

          <Button label='Lag adapter' type='submit' />
        </form>
      </FormProvider>
    </CreateSection>
  );
};
