import React from 'react';

export const SubjectsSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.211'
    height='21.982'
    viewBox='0 0 18.211 21.982'
  >
    <path
      id='Path_2935'
      data-name='Path 2935'
      d='M11.85,0a9.105,9.105,0,0,1,6.439,15.543L11.85,21.982,5.412,15.543A9.105,9.105,0,0,1,11.85,0Zm0,20.039,5.467-5.467a7.731,7.731,0,1,0-10.933,0Zm0-8.98A2.061,2.061,0,1,0,9.79,9,2.061,2.061,0,0,0,11.85,11.06Z'
      transform='translate(-2.745)'
      fillRule='evenodd'
    />
  </svg>
);
