// source: third-party-systems.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.tps.Asset', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.AssetCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.tps.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.tps.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartySystemId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartySystemId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartySystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string third_party_system_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.prototype.getThirdPartySystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.DeleteThirdPartySystemRequest.prototype.setThirdPartySystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartySystemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAsset: (f = msg.getUpdatedAsset()) && proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartySystemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    case 3:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.deserializeBinaryFromReader);
      msg.setUpdatedAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartySystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUpdatedAsset();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.serializeBinaryToWriter
    );
  }
};


/**
 * optional string third_party_system_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.prototype.getThirdPartySystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.prototype.setThirdPartySystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 expected_version = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ManagedAsset updated_asset = 3;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.prototype.getUpdatedAsset = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset, 3));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.prototype.setUpdatedAsset = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.prototype.clearUpdatedAsset = function() {
  return this.setUpdatedAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.AddOrUpdateManagedAssetRequest.prototype.hasUpdatedAsset = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartySystemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    managedAssetId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartySystemId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setManagedAssetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartySystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getManagedAssetId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string third_party_system_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.prototype.getThirdPartySystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.prototype.setThirdPartySystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 expected_version = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string managed_asset_id = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.prototype.getManagedAssetId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.RemoveManagedAssetRequest.prototype.setManagedAssetId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset;
  return proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.tps.Asset;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Asset assets = 2;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset>}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.tps.Asset, 2));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.github.oslonokkelen.protobuf.admin.tps.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.oneofGroups_ = [[5]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.AssetCase = {
  ASSET_NOT_SET: 0,
  OPERATION_ID: 5
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.AssetCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.prototype.getAssetCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.AssetCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.tps.Asset;
  return proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 5;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 5));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.prototype.setOperationId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.Asset} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.Asset.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    information: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adminRole: jspb.Message.getFieldWithDefault(msg, 4, ""),
    eventCallbackUri: jspb.Message.getFieldWithDefault(msg, 5, ""),
    version: jspb.Message.getFieldWithDefault(msg, 6, 0),
    managedAssetsList: jspb.Message.toObjectList(msg.getManagedAssetsList(),
    proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry;
  return proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminRole(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventCallbackUri(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 7:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.deserializeBinaryFromReader);
      msg.addManagedAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInformation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdminRole();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventCallbackUri();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getManagedAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string information = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.getInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.setInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string admin_role = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.getAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.setAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string event_callback_uri = 5;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.getEventCallbackUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.setEventCallbackUri = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 version = 6;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated ManagedAsset managed_assets = 7;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset>}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.getManagedAssetsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset, 7));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.setManagedAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.addManagedAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.com.github.oslonokkelen.protobuf.admin.tps.ManagedAsset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry.prototype.clearManagedAssetsList = function() {
  return this.setManagedAssetsList([]);
};


/**
 * repeated Entry entries = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry>}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.Entry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.ListThirdPartySystemsResponse.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    information: jspb.Message.getFieldWithDefault(msg, 3, ""),
    apiKey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    eventCallbackUri: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adminRole: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInformation(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApiKey(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventCallbackUri(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInformation();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getApiKey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEventCallbackUri();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdminRole();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string information = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.getInformation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.setInformation = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string api_key = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.getApiKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.setApiKey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string event_callback_uri = 5;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.getEventCallbackUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.setEventCallbackUri = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string admin_role = 6;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.getAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.tps.CreateThirdPartySystemRequest.prototype.setAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.tps);
