import React from 'react';
import { SubjectsSvg } from '../../../assets/navbar';
import { OperationInfoCard } from '../../../components/operation/operationCards/OperationInfoCard';
import { antall } from '../../../utils/language';

interface Props {
  subjectIds: string[];
}

export const AdmissionCardSubjectCard: React.FC<Props> = ({ subjectIds }) => {
  return (
    <OperationInfoCard
      icon={<SubjectsSvg />}
      header='Tilknyttede steder'
      link='steder'
      text={antall(subjectIds.length, 'sted')}
    />
  );
};
