import React, { FC, useEffect, useState } from 'react';
import { HeadingProps } from '../../../components/Table/TableHeader';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { sortData } from '../../../components/Table/sortUtil';
import { useSort } from '../../../components/Table/useSort';
import { useAppSelector } from '../../../redux/hooks';
import {
  PublicKeychainFactorySummary,
  selectKeychainFactories
} from '../../../redux/slice/keychainFactorySummaries';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';
import { getIdFromkeychainFactoryUri } from '../../../utils/keychainFactory/getKeychainFactoryAssets';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const AdminPublicKeychainPage: FC = () => {
  const keychains = useAppSelector(selectKeychainFactories.selectAll);
  const publicChains = keychains.filter(
    (keychain): keychain is PublicKeychainFactorySummary =>
      keychain.type === 'public'
  );
  const [sortedChains, setSortedChains] = useState<
    PublicKeychainFactorySummary[]
  >([]);
  const [sortKey, sortReversed, setSortKey] =
    useSort<PublicKeychainFactorySummary>();
  const tableHeadings: HeadingProps<PublicKeychainFactorySummary>[] = [
    { element: 'Navn', sortKey: 'name' },
    { element: 'Public name', sortKey: 'publicName' },
    { element: 'ID', sortKey: 'keychainFactoryUri' },
    { element: 'Tilkoblede steder', sortKey: 'linkedSubjects' },
    { element: 'Rolle', sortKey: 'ownerRole' }
  ];

  useEffect(() => {
    setSortedChains(sortData(publicChains, sortKey, sortReversed));
  }, [sortKey, sortReversed, publicChains.length]);

  const data = sortedChains.map(chain => {
    return {
      cells: [
        chain.name,
        chain.publicName,
        <CopyWrapper
          key={chain.keychainFactoryUri}
          copyValue={chain.keychainFactoryUri}
        />,
        chain.linkedSubjects.length,
        <CopyWrapper key={chain.ownerRole} copyValue={chain.ownerRole} />
      ],
      link: getIdFromkeychainFactoryUri(chain.keychainFactoryUri)
    };
  });

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs />
      <TableWithButtons
        ting='offentlig tilgangsliste'
        buttons={[
          {
            label: 'Lag ny offentlig tilgangsliste',
            type: 'primary',
            accessKey: 'n',
            linkTo: 'lag-ny'
          }
        ]}
        table={{
          headings: tableHeadings,
          rows: data,
          sortKey,
          setSortKey,
          sortReversed
        }}
      />
    </React.Fragment>
  );
};
