import React from 'react';

export const BlankRed = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='136'
    height='136'
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12183'
      data-name='Group 12183'
      transform='translate(-560 -232)'
    >
      <circle
        id='Ellipse_692'
        data-name='Ellipse 692'
        cx='68'
        cy='68'
        r='68'
        transform='translate(560 232)'
        fill='#f9b3ab'
      />
    </g>
  </svg>
);
