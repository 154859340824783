import { EditorView } from '@codemirror/view';
import { githubDark } from '@uiw/codemirror-theme-github';
import CodeMirror from '@uiw/react-codemirror';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button/Button';
import { DeleteAndConfirm } from '../../../../components/DeleteAndConfirm/DeleteAndConfirm';
import { KeyText } from '../../../../components/Forms/KeyText';
import { KeyTextarea } from '../../../../components/Forms/KeyTextarea';
import { deleteProfile, profileLookup } from '../utils/profile';

export const ProfileLookup: React.FC = () => {
  const methods = useForm({
    defaultValues: { phoneNumber: '', fnr: '', profileId: '', reason: '' }
  });
  const { register, handleSubmit, getValues, setValue, clearErrors } = methods;
  //TODO convert to zodResolver
  const [result, setResult] = useState<string>('');

  const downloadUserProfile = async () => {
    const { phoneNumber, fnr, profileId, reason } = getValues();
    const data = await profileLookup(profileId, fnr, phoneNumber, reason);
    if (data) {
      setValue('profileId', data.profileId || '');
      setValue('fnr', data.fodselsnummer || '');
      setResult(JSON.stringify(data, null, 2));
    }
  };
  const deleteUserProfile = async (slice: 'profile' | 'device') => {
    const { profileId, fnr, reason } = getValues();
    await deleteProfile(profileId, fnr, reason, slice);
    setResult('');
    if (slice === 'device') {
      return downloadUserProfile();
    }
  };

  const phonenumberChange = () => {
    setValue('profileId', '');
    setValue('fnr', '');
    clearErrors();
    setResult('');
  };
  const profileIdChange = () => {
    setValue('phoneNumber', '');
    setValue('fnr', '');
    clearErrors();
    setResult('');
  };
  const fnrChange = () => {
    setValue('profileId', '');
    setValue('phoneNumber', '');
    clearErrors();
    setResult('');
  };

  const validate = () => {
    const { profileId, fnr, phoneNumber } = getValues();
    return (
      !!profileId ||
      !!fnr ||
      !!phoneNumber ||
      'Fyll inn enten telefonnummer, profil ID eller fødselsnummer'
    );
  };

  return (
    <div>
      <br />
      <h3>
        Fyll inn <i>enten</i> telefonnummer <i>eller</i> profil ID <i>eller</i>{' '}
        fødselsnummer.
      </h3>
      <br />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(downloadUserProfile)}>
          <KeyText
            name='phoneNumber'
            label='Telefonnummer'
            description='Telefonnummer (uten landskode)'
            elementProps={{
              ...register('phoneNumber', {
                pattern: {
                  value: /[0-9]{8}/,
                  message: 'Telefonnummeret må ha 8 siffer'
                },
                validate: validate
              }),
              autoFocus: true,
              type: 'tel',
              onChange: phonenumberChange
            }}
          />
          <KeyText
            label='Fødselsnummer'
            description='Fødselsnummer (11 siffer)'
            name='fnr'
            elementProps={{
              ...register('fnr', {
                validate: validate,
                pattern: {
                  value: /[0-9]{11}/,
                  message: 'Fødselsnummer må ha 11 siffer'
                }
              }),
              onChange: fnrChange
            }}
          />
          <KeyText
            label='Profil ID'
            name='profileId'
            elementProps={{
              ...register('profileId', { validate: validate }),
              onChange: profileIdChange
            }}
          />
          <KeyTextarea
            name='reason'
            label='Begrunnelse'
            description='Skriv ned hvorfor du trenger å hente frem profilen her'
            elementProps={{
              ...register('reason', { required: 'Du må fylle inn begrunnelse' })
            }}
          />
          <br />
          <Button type='submit' label='Vis brukerprofil' />
        </form>
        <br />
        <DeleteAndConfirm
          deleteAction={() => deleteUserProfile('device')}
          buttonType={result ? 'secondary' : 'disabled'}
          label='Logg ut av alle enheter'
          message='Alle mobilenheter blir logget ut. Ingenting tilganger slettes.'
        />
        <DeleteAndConfirm
          deleteAction={() => deleteUserProfile('profile')}
          buttonType={result ? 'destructive' : 'disabled'}
          label='Slett brukerprofil'
          message='Er du sikker på at du ønsker å slette denne brukerprofilen? Slettingen er permanent.'
        />
      </FormProvider>
      <br />
      <br />
      {result && (
        <React.Fragment>
          <CodeMirror
            value={result}
            theme={githubDark}
            readOnly={true}
            extensions={[EditorView.cspNonce.of(myNonce)]}
          />
        </React.Fragment>
      )}
    </div>
  );
};
