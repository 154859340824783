import React, { useEffect, useState } from 'react';
import { RoleDefinition } from '../../redux/slice/keychainFactoryDetails';
import { useAdminRoles } from '../../utils/roles/useAdminRole';
import { KeySelect } from './KeySelect';
import { KeyComponentProps } from './KeyWrapper';

interface Option {
  value: string;
  description?: string;
  labelText: string;
}

export const createRoleOption = (role: RoleDefinition): Option => {
  return {
    labelText: role.name,
    description: role.description,
    value: role.id
  };
};

export const emptyOption = (roleId: string): Option => {
  return {
    value: roleId,
    labelText: roleId
  };
};

export const AdminRoleSelector: React.FC<KeyComponentProps> = ({
  label = 'Adminrolle',
  description,
  name
}) => {
  const roles = useAdminRoles();
  const [options, setOptions] = useState<Option[]>([]);
  useEffect(() => {
    setOptions(roles.map(role => createRoleOption(role)));
  }, [roles]);
  return (
    <KeySelect
      name={name}
      label={label}
      description={description}
      elementProps={{
        options: options,
        placeholder: 'Velg adminrolle'
      }}
    />
  );
};
