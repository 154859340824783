import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import { KeyTextarea } from '../../../../components/Forms/KeyTextarea';
import {
  ReplaceOidcRedirects,
  replaceOidcRedirects
} from '../../../../redux/actions/keychainFactoryDetails';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { historyGoBack } from '../../../../utils/history';
import { useKeychainFactoryDetails } from '../../KeychainDetailsWrapper';
import { KeychainFactoryBreadcrumbs } from '../../KeychainFactoryBreadcrumbs';

export const OidcRedirect: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { keychainFactory } = useKeychainFactoryDetails();
  if (keychainFactory.type !== 'oidc') {
    return <p>Støtter ikke tilgangslistetype {keychainFactory.type}</p>;
  }

  const methods = useForm<ReplaceOidcRedirects>({
    resolver: zodResolver(ReplaceOidcRedirects),
    values: {
      redirectUris: keychainFactory.redirectUrisList.join('\n'),
      keychainFactoryUri: keychainFactory.keychainFactoryUri
    }
  });

  const onCreate = (data: ReplaceOidcRedirects) =>
    dispatchAndHandleResult(dispatch, replaceOidcRedirects, data, goBack);

  const goBack = () => {
    historyGoBack(navigate);
  };

  return (
    <React.Fragment>
      <KeychainFactoryBreadcrumbs
        keychainFactory={keychainFactory}
        name='Konfigurer redirects'
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onCreate)}>
          <KeyTextarea
            label='Redirect URLer'
            name='redirectUris'
            description='URL(er) som OIDC tillater redirect til etter innlogging. En URL på hver linje.'
            elementProps={{
              rows: 5
            }}
          />
          <div className='button-row'>
            <Button label='Avbryt' type='secondary' onClick={goBack} />
            <Button label='Lagre' type='submit' />
          </div>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
