import {
  createEntityAdapter,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { ListThirdPartySystemsResponse } from '../../proto/third-party-systems_pb';
import {
  createThirdPartySystem,
  deleteThirdPartySystem,
  getThirdPartySystems
} from '../actions/thirdPartySystems';
import { RootState } from '../index';

export type ThirdPartySystem = ListThirdPartySystemsResponse.Entry.AsObject;

const thirdPartyAdapter = createEntityAdapter<ThirdPartySystem>({});

const thirdPartySystemSlice = createSlice({
  name: 'thirdPartySystems',
  initialState: thirdPartyAdapter.getInitialState({
    newApiKey: '',
    isLoading: false
  }),
  reducers: {
    setNewApiKey: (state, action: PayloadAction<string>) => {
      state.newApiKey = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getThirdPartySystems.fulfilled, (state, { payload }) => {
        thirdPartyAdapter.setAll(state, payload);
        state.isLoading = false;
      })
      .addCase(getThirdPartySystems.pending, state => {
        state.isLoading = true;
      })
      .addCase(getThirdPartySystems.rejected, state => {
        state.isLoading = false;
      })
      .addCase(createThirdPartySystem.fulfilled, (state, { payload }) => {
        const tps: ThirdPartySystem = {
          ...payload,
          version: 1,
          managedAssetsList: []
        };
        thirdPartyAdapter.addOne(state, tps);
      })
      .addCase(deleteThirdPartySystem.fulfilled, (state, { payload }) => {
        thirdPartyAdapter.removeOne(state, payload);
      });
  }
});

export const initialState = thirdPartySystemSlice.getInitialState();
export type ThirdPartySystemState = typeof initialState;
export const { setNewApiKey } = thirdPartySystemSlice.actions;
export default thirdPartySystemSlice.reducer;

export const thirdPartySystemSelectors =
  thirdPartyAdapter.getSelectors<RootState>(state => state.thirdPartySystems);
