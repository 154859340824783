import React from 'react';

export const Trouble: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='40'
    viewBox='0 0 37 40'
  >
    <g
      id='Group_12330'
      data-name='Group 12330'
      transform='translate(-64 -1184)'
    >
      <g
        id='Ellipse_780'
        data-name='Ellipse 780'
        transform='translate(64 1184)'
        fill='#fff'
        stroke='#292858'
        strokeWidth='1'
      ></g>
      <g
        id='Component_33_15'
        data-name='Component 33 – 15'
        transform='translate(70 1199)'
      >
        <path
          id='Union_56'
          data-name='Union 56'
          d='M.019,5.063A4.691,4.691,0,0,1,4.233.029,4.768,4.768,0,0,1,9.306,3.247l2.958-.3.707,1.8-1.476.152L9.6,5.086,9.5,5.1A4.672,4.672,0,0,1,6.473,8.986a4.886,4.886,0,0,1-1.213.282,4.937,4.937,0,0,1-.5.026A4.719,4.719,0,0,1,.019,5.063ZM4.41,1.881a2.884,2.884,0,0,0-.667.162A2.8,2.8,0,0,0,1.915,4.886c0,.018,0,.034.005.052A2.843,2.843,0,0,0,5.115,7.414a2.815,2.815,0,0,0,2.5-3,2.832,2.832,0,0,0-2.844-2.55A2.965,2.965,0,0,0,4.41,1.881Zm19.629,5.94L22.46,6.781,20.881,5.742,19.3,4.7l-1.088-.715.643-1.82,1.511.995,1.065-1.541L22.5.079l1.579,1.039L23.011,2.659a.918.918,0,0,0,.317,1.327.875.875,0,0,0,.15.066,1.015,1.015,0,0,0,1.124-.37l1.055-1.524L27.236,3.2,25.1,6.28l1.58,1.039L25.62,8.86ZM14.51,3.965l.034-.5,1.279.089-.035.5Zm-.36-2.46L17.1.659l.346,1.2L14.5,2.71Zm-1.868.209.22-.452.688.335-.221.452Z'
          transform='translate(0 0)'
          fill='#292858'
        />
      </g>
    </g>
  </svg>
);
