import React, { PropsWithChildren } from 'react';
import './KeyStudioCard.scss';

interface KeyStudioCardProps {
  /**
   * How wide should the card be?
   */
  size?: 'small' | 'small-wide' | 'medium';

  /**
   * What should the className be?
   */
  className?: string;

  /**
   * Is the card a link?
   */
  isLink?: boolean;

  /**
   * Should the card be transparent?
   */
  transparent?: boolean;
}

export const KeyStudioCard: React.FC<PropsWithChildren<KeyStudioCardProps>> = ({
  children,
  size = 'small',
  className,
  isLink,
  transparent = false
}) => {
  const cardClassName = `keystudio-card--${size} ${
    className ? `keystudio-card--${className}` : ''
  }${isLink ? ' keystudio-card--hover' : ''}${
    transparent ? ' keystudio-card--transparent' : ''
  }`;

  return <li className={`keystudio-card ${cardClassName}`}>{children}</li>;
};
