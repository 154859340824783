import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { EditAbout } from '../components/About/EditAbout';
import { EditAnomaly } from '../components/Anomaly/EditAnomaly';
import { EditKeypediaSubject } from '../components/Keypedia/EditKeypediaSubject';
import { Operation } from '../components/operation/Operation';
import { OperationWrapper } from '../components/operation/OperationWrapper';
import { EditOperation } from '../components/operation/edit/EditOperation';
import { Location } from '../pages/places/Location';
import { Subject } from '../pages/places/Subject';
import { SubjectWrapper } from '../pages/places/SubjectWrapper';
import { Subjects } from '../pages/places/Subjects';
import { AuditPage } from '../pages/places/audit/AuditPage';
import { listCategories } from '../redux/actions/categories';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const SubjectsRouter = () => {
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedAdminTool !== 'steder') {
      dispatch(setSelectedAdminTool('steder'));
      dispatch(listCategories());
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <Routes>
      <Route path=':subjectId' element={<SubjectWrapper />}>
        <Route index element={<Subject />} />
        <Route path='about' element={<EditAbout />} />
        <Route path='avvik' element={<EditAnomaly />} />
        <Route path='lokasjon' element={<Location />} />
        <Route path='wiki' element={<EditKeypediaSubject />} />
        <Route path='auditlog' element={<AuditPage />} />
        <Route path=':operationId' element={<OperationWrapper />}>
          <Route index element={<Operation />} />
          <Route path=':editId' element={<EditOperation />} />
          <Route path=':editId/:hoursType' element={<EditOperation />} />
        </Route>
      </Route>
      <Route path='/' element={<Subjects />} />
    </Routes>
  );
};
