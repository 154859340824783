import React from 'react';
import { Breadcrumbs } from '../../components/Navigation/breadcrumbs';
import { admissionCardsHome } from '../../components/Navigation/navPaths';
import { PageInfoText } from '../../components/PageInfoText/PageInfoText';
import { AdmissionCard } from '../../redux/slice/admissionCards';
import { AdmissionCardCard } from './AdmissionCardCard';

type Props = {
  admissionCards: AdmissionCard[];
};
export const AdmissionCards: React.FC<Props> = ({ admissionCards }) => {
  const data = admissionCards.map(card => {
    return <AdmissionCardCard admissionCard={card} key={card.id} />;
  });

  return (
    <React.Fragment>
      <Breadcrumbs breadcrumbs={[admissionCardsHome]} />
      <div className='top-info-container'>
        <PageInfoText type='small'>
          Et adgangskort er et bevis som vises fram for å få tilgang til en
          tjeneste.
        </PageInfoText>
      </div>
      {admissionCards.length ? (
        <div className='admissioncard-cards' role='list'>
          {data}
        </div>
      ) : (
        <p>Ingen adgangskort</p>
      )}
    </React.Fragment>
  );
};
