import React from 'react';
import { Operation } from '../../../../../../../redux/slice/operations';
import { OperationListItem } from './OperationListItem';

interface Props {
  operations: Operation[];
  subjectId: string;
}

export const OperationList: React.FC<Props> = ({ operations, subjectId }) => {
  if (operations.length) {
    return (
      <ul>
        {operations.map(operation => (
          <OperationListItem
            key={operation.id}
            operation={operation}
            subjectId={subjectId}
          />
        ))}
      </ul>
    );
  } else {
    return <p>Dette stedet har ingen låser.</p>;
  }
};
