import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { KeyId } from '../../../components/Forms/KeyId';
import { KeyText } from '../../../components/Forms/KeyText';
import {
  createProximityVerificationDevice,
  CreatePvdDetails
} from '../../../redux/actions/pvd';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { historyGoBack } from '../../../utils/history';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const CreateNewPvd: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const methods = useForm<CreatePvdDetails>({
    resolver: zodResolver(CreatePvdDetails),
    defaultValues: {
      id: '',
      timeDriftSeconds: 0,
      timeStepSeconds: 30,
      discrepancyTolerance: 1
    }
  });
  const onSave = (data: CreatePvdDetails) =>
    dispatchAndHandleResult(
      dispatch,
      createProximityVerificationDevice,
      data,
      () => {
        historyGoBack(navigate);
      }
    );

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs currentPageName='Legg til ny nærhetsenhet' />
      <FormProvider {...methods}>
        <form
          className='keystudio-modal-content'
          onSubmit={methods.handleSubmit(onSave)}
        >
          <div>
            <KeyId
              label='ID'
              description='ID til nærhetsenheten'
              name='id'
              elementProps={{
                type: 'text'
              }}
            />
            <KeyText
              label='Tidsavvik'
              name='timeDriftSeconds'
              description='Fordi boksene med skjer ikke er tilknyttet internett har de ingen mulighet til å synkronisere den interne klokka. Her skriver du inn hvor mange sekunder klokka på enheten avviker fra den faktisk tiden (sett 0 om du er usikker)'
              elementProps={{
                type: 'number'
              }}
            />
            <KeyText
              label='Regenereringssyklus'
              description='Hvor ofte enheten genererer en ny kode. Sett 30 sekunder om du er usikker.'
              name='timeStepSeconds'
              elementProps={{
                type: 'number'
              }}
            />
            <KeyText
              label='Antall eldre/fremtidige koder som fortsatt virker'
              description='Det kan hende at noen scanner koden akkurat i det den endres. Her kan du stille inn hvor mange koder frem/bakover vi skal godta. 1 er et fint tall om du er usikker.'
              name='discrepancyTolerance'
              elementProps={{
                type: 'number'
              }}
            />
          </div>
          <div className='button-row'>
            <Button label='Avbryt' type='secondary' onClick={onCancel} />
            <Button label='Opprett' type='submit' />
          </div>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
