import React from 'react';
import { KeyStudioCard } from '../../../../components/cards/KeyStudioCard/KeyStudioCard';
import { Term } from '../../../../proto/term_pb';
import { storForbokstav } from '../../../../utils/language';

import Type = Term.Type;

interface Props {
  type: Type;
}

export const TypeCard: React.FC<Props> = ({ type }) => {
  const formatType = (type: Type) => {
    if (type === Type.VILKAAR) {
      return 'Vilkår';
    }
    const typeString = Object.keys(Type)[type];
    return storForbokstav(typeString);
  };

  return (
    <KeyStudioCard>
      <h3>Type</h3>
      <p>{formatType(type)}</p>
    </KeyStudioCard>
  );
};
