import React, { useEffect } from 'react';
import { HeadingProps } from '../../../components/Table/TableHeader';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { sortData } from '../../../components/Table/sortUtil';
import { useSort } from '../../../components/Table/useSort';
import { useAppSelector } from '../../../redux/hooks';
import { Subject, subjectSelectors } from '../../../redux/slice/subjects';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const AdminSubjectsPage: React.FC = () => {
  const subjects = useAppSelector(subjectSelectors.selectAll);
  const [sortKey, sortReversed, setSortKey] = useSort<Subject>();
  const [sortedSubjects, setSortedSubjects] = React.useState<Subject[]>([]);
  useEffect(() => {
    setSortedSubjects(sortData(subjects, sortKey, sortReversed));
  }, [sortKey, sortReversed, subjects.length]);
  const tableHeadings: HeadingProps<Subject>[] = [
    { element: 'Navn', sortKey: 'name' },
    { element: 'ID', sortKey: 'id' },
    { element: 'Adminrolle', sortKey: 'adminRole' },
    { element: 'Kategori-ID', sortKey: 'categoryId' },
    { element: 'Antall operasjoner', sortKey: 'operationsList' },
    { element: 'Synlig for alle', sortKey: 'visibleForEveryone' },
    { element: 'Har avviksmelding', sortKey: 'anomalyMessage' }
  ];
  const data = sortedSubjects.map(subject => {
    return {
      cells: [
        subject.name,
        <CopyWrapper key={subject.id} copyValue={subject.id} />,
        <CopyWrapper key={subject.adminRole} copyValue={subject.adminRole} />,
        <CopyWrapper key={subject.categoryId} copyValue={subject.categoryId} />,
        subject.operationsList.length,
        subject.visibleForEveryone ? 'JA' : 'NEI',
        subject.anomalyMessage?.timestampMillis ? 'JA' : 'NEI'
      ],
      link: `${subject.id}`
    };
  });
  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs currentPageName='Steder' />
      <TableWithButtons
        ting='sted'
        buttons={[
          {
            label: 'Lag nytt sted',
            type: 'primary',
            accessKey: 'n',
            linkTo: 'lag-ny'
          }
        ]}
        table={{
          headings: tableHeadings,
          rows: data,
          sortKey,
          setSortKey,
          sortReversed
        }}
      />
    </React.Fragment>
  );
};
