import { Placement } from '@floating-ui/utils';
import {
  flip,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react';
import React, { Fragment, useState } from 'react';
import { DotsIcon } from '../../assets/DotsIcon';
import './ActionMenu.scss';
import { ActionMenuItem, ActionMenuItemProps } from './ActionMenuItem';

interface Props {
  actions: ActionMenuItemProps[];
  placement?: Placement;
}
export const ActionMenu: React.FC<Props> = ({
  actions,
  placement = 'bottom-end'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [shift(), flip()],
    placement: placement
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss
  ]);

  if (actions.length === 0) return null;

  const actionItems = actions.map(action => {
    return (
      <ActionMenuItem key={action.description || action.type} {...action} />
    );
  });

  return (
    <Fragment>
      <button
        className='actionmenu-button'
        data-testid='actionmenu-button'
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <DotsIcon />
      </button>
      {isOpen && (
        <div
          ref={refs.setFloating}
          className='actionmenu-items'
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {actionItems}
        </div>
      )}
    </Fragment>
  );
};
