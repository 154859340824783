import React from 'react';
import { Breadcrumbs } from '../../../../components/Navigation/breadcrumbs';
import { admissionCardsHome } from '../../../../components/Navigation/navPaths';
import { AdmissionCard } from '../../../../redux/slice/admissionCards';

interface Props {
  editId?: string;
  admissionCard: AdmissionCard;
}

const getName = (editId?: string) => {
  switch (editId) {
    case 'about':
      return 'Beskrivelse';
    case 'vilkaar':
      return 'Vilkår';
    case 'steder':
      return 'Steder';
    default:
      return editId || '???';
  }
};

export const EditAdmissionCardBreadcrumbs: React.FC<Props> = ({
  editId,
  admissionCard
}) => {
  const breadcrumbs = [
    admissionCardsHome,
    {
      name: admissionCard.publicName,
      path: '..'
    },
    {
      name: getName(editId)
    }
  ];

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};
