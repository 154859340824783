import React from 'react';
import { RenderIfSuperAdmin } from '../util/RenderIfSuperAdmin';
import { MenuItem } from './NavLink/MenuItem';
import './Navbar.scss';
import {
  admissionCardsHome,
  auditHome,
  controlPanelHome,
  helpHome,
  keychainHome,
  statsHome,
  subjectHome,
  termsHome
} from './navPaths';

export const Navbar = () => {
  return (
    <nav className='navbar'>
      <ul>
        <MenuItem {...keychainHome} />
        <MenuItem {...subjectHome} />
        <MenuItem {...termsHome} />
        <MenuItem {...admissionCardsHome} />
        <MenuItem {...statsHome} />
        <MenuItem {...auditHome} />
        <MenuItem {...helpHome} />

        <RenderIfSuperAdmin>
          <MenuItem {...controlPanelHome} />
        </RenderIfSuperAdmin>
      </ul>
    </nav>
  );
};
