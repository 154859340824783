import React from 'react';

export const Data = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='136'
    height='136'
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12182'
      data-name='Group 12182'
      transform='translate(-1486 -219)'
    >
      <g
        id='Group_12177'
        data-name='Group 12177'
        transform='translate(1158 -13)'
      >
        <circle
          id='Ellipse_691'
          data-name='Ellipse 691'
          cx='68'
          cy='68'
          r='68'
          transform='translate(328 232)'
          fill='#f9b3ab'
        />
      </g>
      <text
        id='_10101001'
        data-name='10101001'
        transform='translate(1518 278)'
        fill='#2c2c2c'
        fontSize='16'
        fontFamily='SegoeUI, Segoe UI'
      >
        <tspan x='4' y='15'>
          10101001
        </tspan>
      </text>
    </g>
  </svg>
);
