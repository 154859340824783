import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { fetchFAQ } from '../redux/actions/broadcast';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';
import {AdminFAQ} from "../pages/controlpanel/FAQ/AdminFAQ";

export const AdminFAQRouter = () => {
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedAdminTool !== 'faq') {
      dispatch(setSelectedAdminTool('faq'));
      dispatch(fetchFAQ());
    }
  }, [selectedAdminTool, dispatch]);
  return (
    <section>
      <Routes>
        <Route path='/' element={<AdminFAQ />} />
      </Routes>
    </section>
  );
};
