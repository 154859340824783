import React from 'react';
import { ActionMenuItemProps } from '../../../components/ActionMenu/ActionMenuItem';
import { ConfirmDialogDelete } from '../../../components/DeleteAndConfirm/DeleteAndConfirm';
import { deleteGroup, FAQGroup } from '../../../redux/actions/broadcast';
import { useAppDispatch } from '../../../redux/hooks';
import { HelpSection } from '../../help/HelpSection/HelpSection';
import { FaqQuestions } from './FaqQuestions';
import { GroupDialog } from './GroupDialog';
import { QuestionDialog } from './QuestionDialog';

type Props = {
  faq: FAQGroup[];
};

export const FaqSection: React.FC<Props> = ({ faq }) => {
  const dispatch = useAppDispatch();
  const [isQuestionModalOpen, setIsQuestionModalOpen] = React.useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = React.useState(false);
  const [isDeleteGroupModalOpen, setIsDeleteGroupModalOpen] =
    React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState<FAQGroup>();

  const groupActions = (group: FAQGroup): ActionMenuItemProps[] => [
    {
      type: 'add',
      description: 'Legg til nytt spørsmål',
      onClick: () => {
        setSelectedGroup(group);
        setIsQuestionModalOpen(true);
      }
    },
    {
      type: 'edit',
      description: 'Rediger',
      onClick: () => {
        setSelectedGroup(group);
        setIsGroupModalOpen(true);
      }
    },
    {
      type: 'delete',
      description: 'Slett',
      onClick: () => {
        setSelectedGroup(group);
        setIsDeleteGroupModalOpen(true);
      }
    }
  ];

  return (
    <React.Fragment>
      {faq.map(group => (
        <HelpSection
          key={group.id}
          title={group.title}
          actions={groupActions(group)}
        >
          <FaqQuestions groupId={group.id} questions={group.questions} />
        </HelpSection>
      ))}
      <QuestionDialog
        isModalOpen={isQuestionModalOpen}
        closeModal={() => {
          setIsQuestionModalOpen(false);
        }}
        groupId={selectedGroup?.id || ''}
      />
      <GroupDialog
        isModalOpen={isGroupModalOpen}
        closeModal={() => setIsGroupModalOpen(false)}
        group={selectedGroup}
      />
      <ConfirmDialogDelete
        label='Slett spørsmålsgrouppe'
        message='Er du sikker på at du vil slette denne gruppen? Alle spørsmål i gruppen vil også bli slettet.'
        deleteAction={() => {
          if (!selectedGroup) return;
          dispatch(deleteGroup(selectedGroup.id));
        }}
        isModalOpen={isDeleteGroupModalOpen}
        setIsModalOpen={setIsDeleteGroupModalOpen}
      />
    </React.Fragment>
  );
};
