import { differenceInCalendarDays, format, parse, parseISO } from 'date-fns';
import {
  LocalDate,
  LocalDateTime,
  LocalDateTimeInterval,
  LocalTime
} from '../proto/shared_pb';

export const formatDateAndTime = (date: Date) => {
  return format(date, 'dd.MM.yyyy HH:mm');
};
export const formatDate = (date: Date) => {
  return format(date, 'dd.MM.yyyy');
};

export const formatInterval = (
  fromDate: string,
  toDate: string,
  fromTime?: string,
  toTime?: string
) => {
  if (!fromTime || !toTime) {
    return (
      formatDate(parse(fromDate, 'yyyy-MM-dd', new Date())) +
      ' – ' +
      formatDate(parse(toDate, 'yyyy-MM-dd', new Date()))
    );
  } else {
    return (
      formatDateAndTime(dateFromISO8601DateTime(fromDate, fromTime)) +
      ' – ' +
      formatDateAndTime(dateFromISO8601DateTime(toDate, toTime))
    );
  }
};

export const parseTime = (time: string) => {
  return parse(time, 'HH:mm', new Date());
};

export const formatTime = (date: Date) => {
  return format(date, 'HH:mm');
};

export const dateFromISO8601DateTime = (date: string, time: string) => {
  return parseISO(date + 'T' + time);
};

export const formatDayDate = (date: Date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const calculateDaysBetween = (date1: Date, date2: Date): number => {
  return differenceInCalendarDays(date1, date2);
};

export const formatDateFromEpochMillis = (epoch: number, onlyDate = false) => {
  if (onlyDate) {
    return formatDate(new Date(epoch));
  }
  return formatDateAndTime(new Date(epoch));
};

export const formatDateFromEpochSeconds = (epoch: number, onlyDate = false) => {
  return formatDateFromEpochMillis(epoch * 1000, onlyDate);
};

export const dateToISO8601DateTimeInterval = (dateInterval: {
  startDate: Date;
  endDate: Date;
}): LocalDateTimeInterval => {
  const interval = {
    from: {
      date: format(dateInterval.startDate, 'yyyy-MM-dd'),
      time: format(dateInterval.startDate, 'HH:mm')
    },
    until: {
      date: format(dateInterval.endDate, 'yyyy-MM-dd'),
      time: format(dateInterval.endDate, 'HH:mm')
    }
  };

  return new LocalDateTimeInterval()
    .setFrom(
      new LocalDateTime()
        .setDate(new LocalDate().setDateString(interval.from.date))
        .setTime(new LocalTime().setTimeString(interval.from.time))
    )
    .setUntil(
      new LocalDateTime()
        .setDate(new LocalDate().setDateString(interval.until.date))
        .setTime(new LocalTime().setTimeString(interval.until.time))
    );
};
