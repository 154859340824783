import React from 'react';
import { useNavigate } from 'react-router-dom';
import { updateKeypedia } from '../../../redux/actions/appData';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { Operation } from '../../../redux/slice/operations';
import { historyGoBack } from '../../../utils/history';
import { KeypediaEditor } from '../../Keypedia/KeypediaEditor';
import { PageInfoText } from '../../PageInfoText/PageInfoText';

interface Props {
  operation: Operation;
}

export const EditKeypediaOperation: React.FC<Props> = ({ operation }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSaveClick = async (updatedText: string) =>
    dispatchAndHandleResult(
      dispatch,
      updateKeypedia,
      {
        citykeyId: operation.citykeyId,
        content: updatedText
      },
      () => historyGoBack(navigate)
    );

  return (
    <div>
      <PageInfoText type='medium'>
        Her kan man skrive hva som helst om døra/operasjonen. Eksempelvis
        hvordan oppkoblingen foregår, serienummere, feilsøkningstriks, eller
        lignende. Informasjonen her er ikke tilgjengelig i appen.
      </PageInfoText>
      <KeypediaEditor saveCallback={handleSaveClick} wiki={operation.wiki} />
    </div>
  );
};
