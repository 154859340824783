import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  createCategory,
  deleteCategory,
  listCategories,
  updateCategoryDescription,
  updateCategoryName
} from '../actions/categories';
import { RootState } from '../index';
import { Category } from '../types/protoTypes';

const categoryAdapter = createEntityAdapter<Category>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const categorySlice = createSlice({
  name: 'categories',
  initialState: categoryAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(listCategories.fulfilled, (state, { payload }) => {
        categoryAdapter.setAll(state, payload);
      })
      .addCase(createCategory.fulfilled, (state, { payload }) => {
        categoryAdapter.addOne(state, {
          imagesList: [],
          citykeyId: '',
          adminRole: '',
          ...payload
        });
      })
      .addCase(updateCategoryName.fulfilled, (state, { payload }) => {
        categoryAdapter.updateOne(state, {
          id: payload.id,
          changes: { name: payload.name }
        });
      })
      .addCase(updateCategoryDescription.fulfilled, (state, { payload }) => {
        categoryAdapter.updateOne(state, {
          id: payload.id,
          changes: { description: payload.description }
        });
      })
      .addCase(deleteCategory.fulfilled, (state, { payload }) => {
        categoryAdapter.removeOne(state, payload);
      });
  }
});
export const initialState = categorySlice.getInitialState();
export type CategoriesState = typeof initialState;
export default categorySlice.reducer;
export const categorySelectors = categoryAdapter.getSelectors<RootState>(
  state => state.categories
);
