import React from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { categorySelectors } from '../../../redux/slice/categories';
import { SubjectsGroupedByCategories } from '../../../utils/subjects/subjects';
import { SubjectCardGroup } from './SubjectCard/SubjectCardGroup';

interface SubjectCardsContainerProps {
  categoriesWithSubjects: Array<SubjectsGroupedByCategories>;
}

export const SubjectCardsContainer: React.FC<SubjectCardsContainerProps> = ({
  categoriesWithSubjects
}) => {
  const categories = useAppSelector(categorySelectors.selectAll);

  return (
    <React.Fragment>
      {categoriesWithSubjects.map(category => {
        return (
          <SubjectCardGroup
            key={category.id}
            category={category}
            categories={categories}
          />
        );
      })}
    </React.Fragment>
  );
};
