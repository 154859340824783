import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { CreateSection } from '../../../components/CreateSection/CreateSection';
import { AdminRoleSelector } from '../../../components/Forms/AdminRoleSelector';
import { Breadcrumbs } from '../../../components/Navigation/breadcrumbs';
import { termsHome } from '../../../components/Navigation/navPaths';
import { Term } from '../../../proto/term_pb';
import {
  createTerm,
  CreateTermProps,
  CreateTermSchema
} from '../../../redux/actions/terms';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { User } from '../../../redux/slice/user';
import { historyGoBack } from '../../../utils/history';
import { createUrlSafeId } from '../../../utils/urlSafeId';
import { NormalInput } from './NormalInput';
import { TextInput } from './TextInput';
import { TypeSelector } from './TypeSelector';

import Type = Term.Type;

const getUserRole = (user: User) => {
  let role = '';
  for (let i = 0; i < user.rolesList.length; i++) {
    if (user.rolesList[i] !== 'admin_ui') {
      role = user.rolesList[i];
    }
  }
  return role;
};

const createId = (ownerName: string, title: string) => {
  return createUrlSafeId(`${ownerName}-${title}`, 30);
};

export const CreateTerm = () => {
  const user = useAppSelector(state => state.user);
  const [type, setType] = useState<Type>(Type.SAMTYKKE);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const methods = useForm<CreateTermProps>({
    resolver: zodResolver(CreateTermSchema),
    defaultValues: {
      type: { value: Type.SAMTYKKE, label: 'Samtykke' }
    }
  });

  const currentAdminRole = getUserRole(user);

  useEffect(() => {
    if (currentAdminRole !== 'admin_super') {
      methods.setValue('adminRole', {
        label: currentAdminRole,
        value: currentAdminRole
      });
    }
  }, [currentAdminRole]);

  useEffect(
    () => {
      methods.setValue(
        'id',
        createId(methods.getValues('ownerName'), methods.getValues('title'))
      );
    },
    methods.watch(['ownerName', 'title'])
  );

  const onSubmit = (data: CreateTermProps) =>
    dispatchAndHandleResult(dispatch, createTerm, data, () =>
      historyGoBack(navigate)
    );

  return (
    <React.Fragment>
      <Breadcrumbs
        breadcrumbs={[termsHome, { name: 'Lag nytt brukervilkår' }]}
      />

      <CreateSection>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <NormalInput
              title='Navn'
              name='title'
              helperText='Navnet på brukervilkåret'
              placeholder='Skriv inn navn på brukervilkåret'
            />
            <NormalInput
              title='Eier'
              name='ownerName'
              helperText='Hvem har laget dette brukervilkåret? F.eks. ren eller deichman'
              placeholder='Skriv inn navn på eier'
            />
            <NormalInput
              title='ID'
              name='id'
              helperText='Unik ID'
              placeholder='Skriv inn IDen til betingelsen'
            />
            <TextInput
              text={methods.getValues('text')}
              setText={value => methods.setValue('text', value)}
            />
            <TypeSelector selectedType={type} setSelectedType={setType} />
            {currentAdminRole === 'admin_super' && (
              <AdminRoleSelector
                name='adminRole'
                description='Hvilke admin-brukere skal ha tilgang til denne betingelsen?'
              />
            )}

            <Button label='Lag nytt vilkår' accessKey='n' type='submit' />
          </form>
        </FormProvider>
      </CreateSection>
    </React.Fragment>
  );
};
