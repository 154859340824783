import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { KeySearch } from '../Forms/KeySearch';

interface TextFilterProps {
  search: string;
  setSearch: (search: string) => void;
}

export const TextFilter: React.FC<TextFilterProps> = ({
  search,
  setSearch
}) => {
  return (
    <div className='keystudio-filter-container'>
      <FormProvider {...useForm()}>
        <KeySearch
          name='search'
          elementProps={{
            className: 'keystudio-filter',
            autoFocus: true,
            value: search,
            accessKey: 'f',
            title: 'Finn',
            onChange: evt => setSearch(evt.target.value),
            placeholder: 'Søk'
          }}
        />
      </FormProvider>
    </div>
  );
};
