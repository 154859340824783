import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  actionsHome,
  adminAdapterHome,
  adminCategoryHome,
  adminKeychainHome,
  adminSubjectHome,
  adminAdmissionCardsHome,
  getRelativePath,
  godModeHome,
  pvdHome,
  downloadsHome,
  thirdPartySystemHome,
  adminOidcHome,
  adminPublicKeychainHome,
  adminBucketHome,
  faqHome
} from '../components/Navigation/controlpanelNavPaths';
import { Controlpanel } from '../pages/controlpanel/Controlpanel';
import { Godmode } from '../pages/controlpanel/godmode/Godmode';
import { ActionChainRouter } from './ActionChainRouter';
import { AdminAdapterRouter } from './AdminAdapterRouter';
import { AdminAdmissionCardRouter } from './AdminAdmissionCardRouter';
import { AdminBucketsRouter } from './AdminBucketsRouter';
import { AdminCategoryRouter } from './AdminCategoryRouter';
import { AdminFAQRouter } from './AdminFAQRouter';
import { AdminKeychainRouter } from './AdminKeychainRouter';
import { AdminOidcRouter } from './AdminOidcRouter';
import { AdminPublicKeychainRouter } from './AdminPublicKeychainRouter';
import { AdminSubjectsRouter } from './AdminSubjectsRouter';
import { PvdRouter } from './PvdRouter';
import { SuperadminRouter } from './SuperadminRouter';
import { ThirdPartySystemsRouter } from './ThirdPartySystemsRouter';

export const ControlPanelRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Controlpanel />} />
      <Route
        path={`${getRelativePath(adminAdmissionCardsHome)}/*`}
        element={<AdminAdmissionCardRouter />}
      />
      <Route
        path={`${getRelativePath(actionsHome)}/*`}
        element={<ActionChainRouter />}
      />
      <Route path={`${getRelativePath(pvdHome)}/*`} element={<PvdRouter />} />
      <Route
        path={`${getRelativePath(thirdPartySystemHome)}/*`}
        element={<ThirdPartySystemsRouter />}
      />
      <Route
        path={`${getRelativePath(downloadsHome)}/*`}
        element={<SuperadminRouter />}
      />
      <Route path={getRelativePath(godModeHome)} element={<Godmode />} />
      <Route
        path={`${getRelativePath(adminSubjectHome)}/*`}
        element={<AdminSubjectsRouter />}
      />
      <Route
        path={`${getRelativePath(adminCategoryHome)}/*`}
        element={<AdminCategoryRouter />}
      />
      <Route
        path={`${getRelativePath(faqHome)}/*`}
        element={<AdminFAQRouter />}
      />
      <Route
        path={`${getRelativePath(adminAdapterHome)}/*`}
        element={<AdminAdapterRouter />}
      />
      <Route
        path={`${getRelativePath(adminKeychainHome)}/*`}
        element={<AdminKeychainRouter />}
      />
      <Route
        path={`${getRelativePath(adminPublicKeychainHome)}/*`}
        element={<AdminPublicKeychainRouter />}
      />
      <Route
        path={`${getRelativePath(adminOidcHome)}/*`}
        element={<AdminOidcRouter />}
      />
      <Route
        path={`${getRelativePath(adminBucketHome)}/*`}
        element={<AdminBucketsRouter />}
      />
    </Routes>
  );
};
