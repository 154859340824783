import React, { useState } from 'react';
import { useMetaTags } from 'react-metatags-hook';
import { ImageData } from '../../redux/types/common';
import { getImageLink } from '../../utils/operation/images/imageLink';
import { StickeredContent } from '../Sticker/StickeredContent';
import { Image } from './Image';
import './ImageCard.scss';
import { EditImagePopup } from './popup/EditImagePopup';

interface CommonProps {
  images: ImageData[];
}

interface SubjectProps extends CommonProps {
  subjectId: string;
}

interface OperationProps extends CommonProps {
  subjectId: string;
  operationId: string;
}

interface KeychainFactoryProps extends CommonProps {
  keychainFactoryUri: string;
}

type Props = SubjectProps | OperationProps | KeychainFactoryProps;
export const ImageCard: React.FC<Props> = ({ images, ...ids }) => {
  const [showPopup, setShowPopup] = useState(false);
  const imageLink = getImageLink(images);
  useMetaTags(
    {
      openGraph: {
        image: imageLink
      }
    },
    [imageLink]
  );
  const headingText = images.length
    ? 'Bildet som vises i appen'
    : 'Sett et eget bilde som vises i appen';
  return (
    <div className='image-card-container'>
      <h3>{headingText}</h3>
      <StickeredContent stickerText={images.length ? '' : 'Bilde mangler!'}>
        <div className='image-card subject-action-card'>
          {showPopup && (
            <EditImagePopup
              {...ids}
              isModalOpen={showPopup}
              closeModal={() => setShowPopup(false)}
            />
          )}

          <Image imageLink={imageLink} setShowPopup={setShowPopup} />
        </div>
      </StickeredContent>
    </div>
  );
};
