// source: subjects.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    preferredTextEncoding: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (reader.readEnum());
      msg.setPreferredTextEncoding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreferredTextEncoding();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.Text.Type preferred_text_encoding = 1;
 * @return {!proto.oslonokkelen.proto.shared.Text.Type}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.prototype.getPreferredTextEncoding = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Text.Type} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ListSubjectsRequest.prototype.setPreferredTextEncoding = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectsList: jspb.Message.toObjectList(msg.getSubjectsList(),
    proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.deserializeBinaryFromReader);
      msg.addSubjects(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    categoryId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adminRole: jspb.Message.getFieldWithDefault(msg, 4, ""),
    coordinates: (f = msg.getCoordinates()) && shared_pb.Coordinates.toObject(includeInstance, f),
    imagesList: jspb.Message.toObjectList(msg.getImagesList(),
    shared_pb.Image.toObject, includeInstance),
    anomalyMessage: (f = msg.getAnomalyMessage()) && shared_pb.AnomalyMessage.toObject(includeInstance, f),
    about: (f = msg.getAbout()) && shared_pb.Text.toObject(includeInstance, f),
    citykeyId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminRole(value);
      break;
    case 5:
      var value = new shared_pb.Coordinates;
      reader.readMessage(value,shared_pb.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    case 6:
      var value = new shared_pb.Image;
      reader.readMessage(value,shared_pb.Image.deserializeBinaryFromReader);
      msg.addImages(value);
      break;
    case 7:
      var value = new shared_pb.AnomalyMessage;
      reader.readMessage(value,shared_pb.AnomalyMessage.deserializeBinaryFromReader);
      msg.setAnomalyMessage(value);
      break;
    case 8:
      var value = new shared_pb.Text;
      reader.readMessage(value,shared_pb.Text.deserializeBinaryFromReader);
      msg.setAbout(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitykeyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdminRole();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      shared_pb.Coordinates.serializeBinaryToWriter
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      shared_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getAnomalyMessage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      shared_pb.AnomalyMessage.serializeBinaryToWriter
    );
  }
  f = message.getAbout();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      shared_pb.Text.serializeBinaryToWriter
    );
  }
  f = message.getCitykeyId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string category_id = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string admin_role = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.getAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.setAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional oslonokkelen.proto.shared.Coordinates coordinates = 5;
 * @return {?proto.oslonokkelen.proto.shared.Coordinates}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.getCoordinates = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Coordinates} */ (
    jspb.Message.getWrapperField(this, shared_pb.Coordinates, 5));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Coordinates|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated oslonokkelen.proto.shared.Image images = 6;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Image>}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.getImagesList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Image>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.Image, 6));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Image>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.setImagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Image=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Image}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.addImages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.oslonokkelen.proto.shared.Image, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * optional oslonokkelen.proto.shared.AnomalyMessage anomaly_message = 7;
 * @return {?proto.oslonokkelen.proto.shared.AnomalyMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.getAnomalyMessage = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.AnomalyMessage} */ (
    jspb.Message.getWrapperField(this, shared_pb.AnomalyMessage, 7));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.AnomalyMessage|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.setAnomalyMessage = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.clearAnomalyMessage = function() {
  return this.setAnomalyMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.hasAnomalyMessage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional oslonokkelen.proto.shared.Text about = 8;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.getAbout = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, shared_pb.Text, 8));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.setAbout = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.clearAbout = function() {
  return this.setAbout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.hasAbout = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string citykey_id = 9;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.getCitykeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject.prototype.setCitykeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated Subject subjects = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject>}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.prototype.getSubjectsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.prototype.setSubjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.prototype.addSubjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.Subject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.SubjectListResponse.prototype.clearSubjectsList = function() {
  return this.setSubjectsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: (f = msg.getContent()) && shared_pb.Text.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = new shared_pb.Text;
      reader.readMessage(value,shared_pb.Text.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.Text.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.Text content = 2;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.prototype.getContent = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, shared_pb.Text, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateAnomalyMessage.prototype.hasContent = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.ClearAnomalyMessage.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adminRole: jspb.Message.getFieldWithDefault(msg, 3, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdminRole();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string admin_role = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.prototype.getAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.prototype.setAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.CreateSubjectRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    coordinates: (f = msg.getCoordinates()) && shared_pb.Coordinates.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new shared_pb.Coordinates;
      reader.readMessage(value,shared_pb.Coordinates.deserializeBinaryFromReader);
      msg.setCoordinates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCoordinates();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.Coordinates.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.Coordinates coordinates = 2;
 * @return {?proto.oslonokkelen.proto.shared.Coordinates}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.prototype.getCoordinates = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Coordinates} */ (
    jspb.Message.getWrapperField(this, shared_pb.Coordinates, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Coordinates|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.prototype.setCoordinates = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.prototype.clearCoordinates = function() {
  return this.setCoordinates(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectCoordinatesRequest.prototype.hasCoordinates = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inProduction: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInProduction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInProduction();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool in_production = 2;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.prototype.getInProduction = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateProductionStatusRequest.prototype.setInProduction = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectNameRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.DeleteSubjectRequest.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectImageRequest.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newOwnerRoleId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewOwnerRoleId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewOwnerRoleId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_owner_role_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.prototype.getNewOwnerRoleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectOwnerRoleRequest.prototype.setNewOwnerRoleId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    about: (f = msg.getAbout()) && shared_pb.Text.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = new shared_pb.Text;
      reader.readMessage(value,shared_pb.Text.deserializeBinaryFromReader);
      msg.setAbout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAbout();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.Text.serializeBinaryToWriter
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.Text about = 2;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.prototype.getAbout = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, shared_pb.Text, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.prototype.setAbout = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.prototype.clearAbout = function() {
  return this.setAbout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.subject.UpdateSubjectAboutRequest.prototype.hasAbout = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.subject);
