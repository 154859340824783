/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.subject
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: subjects.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as subjects_pb from './subjects_pb'; // proto import: "subjects.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class SubjectServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorUpdateAnomaly = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateAnomaly',
    grpcWeb.MethodType.UNARY,
    subjects_pb.UpdateAnomalyMessage,
    shared_pb.EmptyResponse,
    (request: subjects_pb.UpdateAnomalyMessage) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateAnomaly(
    request: subjects_pb.UpdateAnomalyMessage,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateAnomaly(
    request: subjects_pb.UpdateAnomalyMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateAnomaly(
    request: subjects_pb.UpdateAnomalyMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateAnomaly',
        request,
        metadata || {},
        this.methodDescriptorUpdateAnomaly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateAnomaly',
    request,
    metadata || {},
    this.methodDescriptorUpdateAnomaly);
  }

  methodDescriptorUpdateProductionStatus = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateProductionStatus',
    grpcWeb.MethodType.UNARY,
    subjects_pb.UpdateProductionStatusRequest,
    shared_pb.EmptyResponse,
    (request: subjects_pb.UpdateProductionStatusRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateProductionStatus(
    request: subjects_pb.UpdateProductionStatusRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateProductionStatus(
    request: subjects_pb.UpdateProductionStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateProductionStatus(
    request: subjects_pb.UpdateProductionStatusRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateProductionStatus',
        request,
        metadata || {},
        this.methodDescriptorUpdateProductionStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateProductionStatus',
    request,
    metadata || {},
    this.methodDescriptorUpdateProductionStatus);
  }

  methodDescriptorClearAnomaly = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/ClearAnomaly',
    grpcWeb.MethodType.UNARY,
    subjects_pb.ClearAnomalyMessage,
    shared_pb.EmptyResponse,
    (request: subjects_pb.ClearAnomalyMessage) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  clearAnomaly(
    request: subjects_pb.ClearAnomalyMessage,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  clearAnomaly(
    request: subjects_pb.ClearAnomalyMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  clearAnomaly(
    request: subjects_pb.ClearAnomalyMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/ClearAnomaly',
        request,
        metadata || {},
        this.methodDescriptorClearAnomaly,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/ClearAnomaly',
    request,
    metadata || {},
    this.methodDescriptorClearAnomaly);
  }

  methodDescriptorListSubjects = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/ListSubjects',
    grpcWeb.MethodType.UNARY,
    subjects_pb.ListSubjectsRequest,
    subjects_pb.SubjectListResponse,
    (request: subjects_pb.ListSubjectsRequest) => {
      return request.serializeBinary();
    },
    subjects_pb.SubjectListResponse.deserializeBinary
  );

  listSubjects(
    request: subjects_pb.ListSubjectsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<subjects_pb.SubjectListResponse>;

  listSubjects(
    request: subjects_pb.ListSubjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: subjects_pb.SubjectListResponse) => void): grpcWeb.ClientReadableStream<subjects_pb.SubjectListResponse>;

  listSubjects(
    request: subjects_pb.ListSubjectsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: subjects_pb.SubjectListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/ListSubjects',
        request,
        metadata || {},
        this.methodDescriptorListSubjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/ListSubjects',
    request,
    metadata || {},
    this.methodDescriptorListSubjects);
  }

  methodDescriptorCreateSubject = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/CreateSubject',
    grpcWeb.MethodType.UNARY,
    subjects_pb.CreateSubjectRequest,
    shared_pb.EmptyResponse,
    (request: subjects_pb.CreateSubjectRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createSubject(
    request: subjects_pb.CreateSubjectRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createSubject(
    request: subjects_pb.CreateSubjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createSubject(
    request: subjects_pb.CreateSubjectRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/CreateSubject',
        request,
        metadata || {},
        this.methodDescriptorCreateSubject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/CreateSubject',
    request,
    metadata || {},
    this.methodDescriptorCreateSubject);
  }

  methodDescriptorUpdateSubjectCoordinates = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectCoordinates',
    grpcWeb.MethodType.UNARY,
    subjects_pb.UpdateSubjectCoordinatesRequest,
    shared_pb.EmptyResponse,
    (request: subjects_pb.UpdateSubjectCoordinatesRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateSubjectCoordinates(
    request: subjects_pb.UpdateSubjectCoordinatesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateSubjectCoordinates(
    request: subjects_pb.UpdateSubjectCoordinatesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateSubjectCoordinates(
    request: subjects_pb.UpdateSubjectCoordinatesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectCoordinates',
        request,
        metadata || {},
        this.methodDescriptorUpdateSubjectCoordinates,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectCoordinates',
    request,
    metadata || {},
    this.methodDescriptorUpdateSubjectCoordinates);
  }

  methodDescriptorUpdateSubjectName = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectName',
    grpcWeb.MethodType.UNARY,
    subjects_pb.UpdateSubjectNameRequest,
    shared_pb.EmptyResponse,
    (request: subjects_pb.UpdateSubjectNameRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateSubjectName(
    request: subjects_pb.UpdateSubjectNameRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateSubjectName(
    request: subjects_pb.UpdateSubjectNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateSubjectName(
    request: subjects_pb.UpdateSubjectNameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectName',
        request,
        metadata || {},
        this.methodDescriptorUpdateSubjectName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectName',
    request,
    metadata || {},
    this.methodDescriptorUpdateSubjectName);
  }

  methodDescriptorUpdateSubjectImage = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectImage',
    grpcWeb.MethodType.UNARY,
    subjects_pb.UpdateSubjectImageRequest,
    shared_pb.EmptyResponse,
    (request: subjects_pb.UpdateSubjectImageRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateSubjectImage(
    request: subjects_pb.UpdateSubjectImageRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateSubjectImage(
    request: subjects_pb.UpdateSubjectImageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateSubjectImage(
    request: subjects_pb.UpdateSubjectImageRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectImage',
        request,
        metadata || {},
        this.methodDescriptorUpdateSubjectImage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectImage',
    request,
    metadata || {},
    this.methodDescriptorUpdateSubjectImage);
  }

  methodDescriptorUpdateSubjectOwnerRole = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectOwnerRole',
    grpcWeb.MethodType.UNARY,
    subjects_pb.UpdateSubjectOwnerRoleRequest,
    shared_pb.EmptyResponse,
    (request: subjects_pb.UpdateSubjectOwnerRoleRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateSubjectOwnerRole(
    request: subjects_pb.UpdateSubjectOwnerRoleRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateSubjectOwnerRole(
    request: subjects_pb.UpdateSubjectOwnerRoleRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateSubjectOwnerRole(
    request: subjects_pb.UpdateSubjectOwnerRoleRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectOwnerRole',
        request,
        metadata || {},
        this.methodDescriptorUpdateSubjectOwnerRole,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectOwnerRole',
    request,
    metadata || {},
    this.methodDescriptorUpdateSubjectOwnerRole);
  }

  methodDescriptorUpdateSubjectAbout = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectAbout',
    grpcWeb.MethodType.UNARY,
    subjects_pb.UpdateSubjectAboutRequest,
    shared_pb.EmptyResponse,
    (request: subjects_pb.UpdateSubjectAboutRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateSubjectAbout(
    request: subjects_pb.UpdateSubjectAboutRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateSubjectAbout(
    request: subjects_pb.UpdateSubjectAboutRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateSubjectAbout(
    request: subjects_pb.UpdateSubjectAboutRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectAbout',
        request,
        metadata || {},
        this.methodDescriptorUpdateSubjectAbout,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/UpdateSubjectAbout',
    request,
    metadata || {},
    this.methodDescriptorUpdateSubjectAbout);
  }

  methodDescriptorDeleteSubject = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/DeleteSubject',
    grpcWeb.MethodType.UNARY,
    subjects_pb.DeleteSubjectRequest,
    shared_pb.EmptyResponse,
    (request: subjects_pb.DeleteSubjectRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteSubject(
    request: subjects_pb.DeleteSubjectRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteSubject(
    request: subjects_pb.DeleteSubjectRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteSubject(
    request: subjects_pb.DeleteSubjectRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/DeleteSubject',
        request,
        metadata || {},
        this.methodDescriptorDeleteSubject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.subject.SubjectService/DeleteSubject',
    request,
    metadata || {},
    this.methodDescriptorDeleteSubject);
  }

}

