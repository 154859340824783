import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdmissionCards } from '../pages/admissionCard/AdmissionCards';
import { AdmissionCardDetails } from '../pages/admissionCard/Details/AdmissionCardDetails';
import { AdmissionCardWrapper } from '../pages/admissionCard/Details/AdmissionCardWrapper';
import { AdmissionCardEdit } from '../pages/admissionCard/Details/Edit/AdmissionCardEdit';
import { AuditPage } from '../pages/admissionCard/Details/audit/AuditPage';
import { listAdmissionCardTypes } from '../redux/actions/admissionCards';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { AdmissionCard } from '../redux/slice/admissionCards';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const AdmissionCardRouter = () => {
  const { selectedAdminTool } = useAppSelector(state => state.ui);
  const admissionCards: AdmissionCard[] = useAppSelector(
    state => state.admissionCards.cards
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedAdminTool !== 'admissioncard') {
      dispatch(setSelectedAdminTool('admissioncard'));
      dispatch(listAdmissionCardTypes());
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <section>
      <Routes>
        <Route
          path='/'
          element={<AdmissionCards admissionCards={admissionCards} />}
        />
        <Route path=':cardId' element={<AdmissionCardWrapper />}>
          <Route index element={<AdmissionCardDetails />} />
          <Route path='auditlog' element={<AuditPage />} />
          <Route path=':editId' element={<AdmissionCardEdit />} />
        </Route>
      </Routes>
    </section>
  );
};
