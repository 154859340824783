import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getBuildVersion } from '../actions/version';

export interface Popup {
  error: boolean;
  message: string;
  traceId?: string;
  methodName?: string;
}

export type PopupState = {
  popup: Popup | null;
  latestTraceId: string;
  buildId: string;
  showNewBuildVersion: boolean;
  lastCheckedForNewVersion: number;
};

export const initialState: PopupState = {
  popup: null,
  latestTraceId: '',
  buildId: process.env.REACT_APP_BUILD_VERSION || 'ukjent',
  showNewBuildVersion: false,
  lastCheckedForNewVersion: 0
};

const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setPopup: (state, action: PayloadAction<Popup>) => {
      state.popup = action.payload;
      state.showNewBuildVersion = false;
    },
    clearPopup: state => {
      state.popup = null;
    },
    clearNewVersion: state => {
      state.showNewBuildVersion = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(getBuildVersion.fulfilled, (state, { payload }) => {
      state.showNewBuildVersion = state.buildId !== payload;
      state.lastCheckedForNewVersion = Date.now();
    });
  }
});

export const { setPopup, clearPopup, clearNewVersion } = popupSlice.actions;
export default popupSlice.reducer;
