import React, { useCallback } from 'react';
import { Button } from '../../../../../../../components/Button/Button';
import {
  addOperationLock,
  removeOperationLock
} from '../../../../../../../redux/actions/keychainFactoryDetails';
import { useAppDispatch } from '../../../../../../../redux/hooks';
import { Operation } from '../../../../../../../redux/slice/operations';
import { useKeychainFactoryDetails } from '../../../../../KeychainDetailsWrapper';

interface Props {
  operation: Operation;
  subjectId: string;
}

export const OperationListItem: React.FC<Props> = ({
  operation,
  subjectId
}) => {
  const { keychainFactory } = useKeychainFactoryDetails();
  const dispatch = useAppDispatch();
  const handleAddPress = useCallback(() => {
    dispatch(
      addOperationLock({
        keychainFactoryUri: keychainFactory.keychainFactoryUri,
        subjectId,
        operationId: operation.id
      })
    );
  }, [keychainFactory]);

  const handleRemovePress = useCallback(() => {
    dispatch(
      removeOperationLock({
        keychainFactoryUri: keychainFactory.keychainFactoryUri,
        subjectId,
        operationId: operation.id
      })
    );
  }, [keychainFactory]);

  const exists = keychainFactory.grantedOperationsList.some(value => {
    return value.operationId === operation.id && value.subjectId === subjectId;
  });

  return (
    <li>
      <h3>{operation.name}</h3>
      {exists ? (
        <Button
          label='Fjern'
          type='destructive'
          onClick={handleRemovePress}
          size='small'
        />
      ) : (
        <Button
          label='Legg til'
          type='primary'
          onClick={handleAddPress}
          size='small'
        />
      )}
    </li>
  );
};
