import { z } from 'zod';
import {
  FetchKeychainFactoryDetailsRequest,
  AddOperationLockRequest,
  RemoveOperationLockRequest,
  CreateManualGrantKeychainFactoryRequest,
  UpdateKeychainFactoryNameRequest,
  DeleteKeychainFactoryRequest,
  ChangeKeychainFactoryOwnerRequest,
  KeychainFactoryDetailsResponse,
  UpdateKeychainFactoryOpeningHoursRequest,
  CloneKeychainFactoryRequest,
  CreatePublicKeychainFactoryRequest,
} from '../../proto/keychain-factory_pb';
import {
  LocalDate,
  LocalTime,
  LocalTimeInterval,
  OpeningHours as OpeningHoursClass
} from '../../proto/shared_pb';
import { getKeystudioUri } from '../../utils/citykeyId';
import {
    getGrpcMetadata,
    getKeychainFactoryClient, getOidcKeychainClient,
    handleGrpcError
} from '../../utils/requests/grpcRequest';
import { convertTextareaToArray } from '../../utils/string';
import { createAppAsyncThunk } from '../hooks';
import { setPopup } from '../slice/popup';
import { RoleEntry } from '../slice/roles';
import { OpeningHours, TimeIntervalsList } from '../types/common';
import {
  description,
  id,
  multiLine,
  name,
  noValidationString,
  optionTypeSchema,
  positiveNumber,
  zeroOrMore
} from '../types/zodSchemas';
import { listKeychainFactoryConfigurations } from './keychainFactorySummaries';
import { createFullOperationId } from './util';
import {
    CreateOidcKeychainFactoryRequest,
    CreateOidcRoleRequest, DeleteOidcRoleRequest, OidcListUsersWithRoleRequest,
    OidcSetSessionLifespanRequest,
    OidcUpdateRoleDescriptionRequest,
    ReplaceAllowedOidcRedirectUrisRequest,
    ReplaceAllowedOriginsRequest
} from "../../proto/keychain-oidc_pb";
import {OidcRoleDefinition} from "../../proto/keychain-shared_pb";

export const fetchKeychainFactoryDetails = createAppAsyncThunk<
  KeychainFactoryDetailsResponse.AsObject,
  string
>(
  'keychainFactories/fetchKeychainFactoryDetails',
  async (keychainFactoryUri, thunkAPI) => {
    try {
      const request = new FetchKeychainFactoryDetailsRequest();
      request.setKeychainFactoryUri(keychainFactoryUri);
      const response = await getKeychainFactoryClient().fetchKeychainFactoryDetails(
        request,
        await getGrpcMetadata()
      );
      return response.toObject();
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

type OperationLockProps = {
  keychainFactoryUri: string;
  subjectId: string;
  operationId: string;
};
export const addOperationLock = createAppAsyncThunk<void, OperationLockProps>(
  'keychainFactories/addOperationLock',
  async ({ keychainFactoryUri, subjectId, operationId }, thunkAPI) => {
    try {
      const request = new AddOperationLockRequest();
      request.setKeychainFactoryUri(keychainFactoryUri);
      request.setOperationId(createFullOperationId({ subjectId, operationId }));
      await getKeychainFactoryClient().addOperationLock(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Låsen er nå knyttet til tilgangslisten'
        })
      );
      thunkAPI.dispatch(fetchKeychainFactoryDetails(keychainFactoryUri));
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const removeOperationLock = createAppAsyncThunk<
  void,
  OperationLockProps
>(
  'keychainFactories/removeOperationLock',
  async ({ subjectId, operationId, keychainFactoryUri }, thunkAPI) => {
    try {
      await getKeychainFactoryClient().removeOperationLock(
        new RemoveOperationLockRequest()
          .setKeychainFactoryUri(keychainFactoryUri)
          .setOperationId(createFullOperationId({ subjectId, operationId })),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Låsen er fjernet fra tilgangslisten'
        })
      );
      thunkAPI.dispatch(fetchKeychainFactoryDetails(keychainFactoryUri));
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const CreateManualGrantKeychainFactory = z.object({
  publicName: name,
  ownerAdminRole: optionTypeSchema
});
export type CreateManualGrantKeychainFactory = z.infer<
  typeof CreateManualGrantKeychainFactory
>;
export const createManualGrantKeychainFactory = createAppAsyncThunk<
  void,
  CreateManualGrantKeychainFactory
>(
  'keychainFactories/createManualGrantKeychainFactory',
  async ({ publicName, ownerAdminRole }, thunkAPI) => {
    try {
      const request = new CreateManualGrantKeychainFactoryRequest();
      request.setPublicName(publicName);
      request.setOwnerAdminRole(ownerAdminRole.value);
      await getKeychainFactoryClient().createManualGrantKeychainFactory(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Tilgangslisten er opprettet!'
        })
      );
      thunkAPI.dispatch(listKeychainFactoryConfigurations());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const CreateOidcKeychainFactory = z.object({
  id: id,
  publicName: name,
  adminRole: optionTypeSchema,
  lifespan: zeroOrMore,
  allowedOrigins: multiLine,
  redirectUrl: multiLine
});

export type CreateOidcKeychainFactory = z.infer<
  typeof CreateOidcKeychainFactory
>;

export const createOidcKeychainFactory = createAppAsyncThunk<
  void,
  CreateOidcKeychainFactory
>(
  'keychainFactories/createOidcKeychainFactory',
  async (
    { id, publicName, adminRole, allowedOrigins, redirectUrl, lifespan },
    thunkAPI
  ) => {
    try {
      const request = new CreateOidcKeychainFactoryRequest();
      request.setId(id);
      request.setName(publicName);
      request.setAdminRole(adminRole.value);
      request.setAllowedOriginsList(convertTextareaToArray(allowedOrigins));
      request
        .setRedirectUrisList(convertTextareaToArray(redirectUrl))
        .setSessionLifespanMinutes(lifespan);
      await getOidcKeychainClient().createOidcKeychainFactory(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Ny Weblogin er opprettet!'
        })
      );
      thunkAPI.dispatch(listKeychainFactoryConfigurations());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const CreatePublicKeychainFactoryForm = z.object({
  id: id,
  publicName: name,
  adminRole: optionTypeSchema
});
export type CreatePublicKeychainFactoryForm = z.infer<
  typeof CreatePublicKeychainFactoryForm
>;
export const createPublicKeychainFactory = createAppAsyncThunk<
  void,
  CreatePublicKeychainFactoryForm
>(
  'keychainFactories/createPublicKeychainFactory',
  async ({ id, publicName, adminRole }, thunkAPI) => {
    try {
      const request = new CreatePublicKeychainFactoryRequest();
      request.setId(id);
      request.setPublicName(publicName);
      request.setAdminRole(adminRole.value);
      await getKeychainFactoryClient().createPublicKeychainFactory(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Ny offentlig tilgagnsliste er opprettet!'
        })
      );
      thunkAPI.dispatch(listKeychainFactoryConfigurations());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const ReplaceOidcRedirects = z.object({
  keychainFactoryUri: noValidationString,
  redirectUris: multiLine
});
export type ReplaceOidcRedirects = z.infer<typeof ReplaceOidcRedirects>;
export const replaceOidcRedirects = createAppAsyncThunk<
  ReplaceAllowedOidcRedirectUrisRequest.AsObject,
  ReplaceOidcRedirects
>(
  'keychainFactories/replaceOidcRedirects',
  async ({ keychainFactoryUri, redirectUris }, thunkAPI) => {
    try {
      const request = new ReplaceAllowedOidcRedirectUrisRequest();
      request.setKeychainFactoryUri(keychainFactoryUri);
      request.setRedirectUriList(convertTextareaToArray(redirectUris));
      await getOidcKeychainClient().replaceAllowedOidcRedirectUris(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Redirect URI-er er oppdatert!'
        })
      );
      return request.toObject();
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const AllowedOrigins = z.object({
  keychainFactoryUri: noValidationString,
  allowedOrigins: multiLine
});
export type AllowedOrigins = z.infer<typeof AllowedOrigins>;

export const replaceAllowedOrigins = createAppAsyncThunk<
  ReplaceAllowedOriginsRequest.AsObject,
  AllowedOrigins
>(
  'keychainFactories/replaceAllowedOrigins',
  async ({ keychainFactoryUri, allowedOrigins }, thunkAPI) => {
    try {
      const request = new ReplaceAllowedOriginsRequest();
      request.setKeychainFactoryUri(keychainFactoryUri);
      request.setAllowedOriginsList(convertTextareaToArray(allowedOrigins));
      await getOidcKeychainClient().replaceAllowedOrigins(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Allowed origins er oppdatert!'
        })
      );
      return request.toObject();
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const SessionLifeSpan = z.object({
  keychainFactoryUri: noValidationString,
  sessionLifespanMinutes: positiveNumber
});

export type SessionLifeSpan = z.infer<typeof SessionLifeSpan>;

export const setSessionLifespan = createAppAsyncThunk<
  SessionLifeSpan,
  SessionLifeSpan
>(
  'keychainFactories/setSessionLifespan',
  async ({ keychainFactoryUri, sessionLifespanMinutes }, thunkAPI) => {
    try {
      const request = new OidcSetSessionLifespanRequest();
      request.setSessionLifespanMinutes(sessionLifespanMinutes);
      request.setFactoryUri(keychainFactoryUri);
      await getOidcKeychainClient().oidcSetSessionLifespan(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Levetiden på sesjonen er oppdatert!'
        })
      );
      return { keychainFactoryUri, sessionLifespanMinutes };
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

type UpdateFactoryName = {
  id: string;
  newName: string;
};
export const updateKeychainFactoryNameRequest = createAppAsyncThunk<
  UpdateFactoryName,
  UpdateFactoryName
>(
  'keychainFactories/updateKeychainFactoryNameRequest',
  async ({ id, newName }, thunkAPI) => {
    try {
      const request = new UpdateKeychainFactoryNameRequest();
      request.setKeychainFactoryUri(id);
      request.setNewName(newName);
      await getKeychainFactoryClient().updateKeychainFactoryName(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Navnet er oppdatert!'
        })
      );
      return { id, newName };
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const updateKeychainFactoryOpeningHoursRequest = createAppAsyncThunk<
  void,
  {
    keychainFactoryUri: string;
    openingHours: OpeningHours;
  }
>(
  'keychainFactories/updateKeychainFactoryOpeningHoursRequest',
  async ({ keychainFactoryUri, openingHours }, thunkAPI) => {
    try {
      const openingHoursObject = openingHoursModelToObject(openingHours);
      const request = new UpdateKeychainFactoryOpeningHoursRequest()
        .setKeychainFactoryUri(keychainFactoryUri)
        .setOpeningHours(openingHoursObject);
      await getKeychainFactoryClient().updateKeychainFactoryOpeningHours(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(fetchKeychainFactoryDetails(keychainFactoryUri));
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Åpningstidene er oppdatert'
        })
      );
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const CloneKeyChainFactory = z.object({
  keychainFactoryUri: noValidationString,
  newName: name
});

export type CloneKeyChainFactory = z.infer<typeof CloneKeyChainFactory>;

export const cloneKeychainFactory = createAppAsyncThunk<
  string,
  CloneKeyChainFactory
>(
  'keychainFactories/cloneKeychainFactory',
  async ({ keychainFactoryUri, newName }, thunkAPI) => {
    try {
      const request = new CloneKeychainFactoryRequest()
        .setKeychainFactoryUri(keychainFactoryUri)
        .setNewName(newName);
      const newId = await getKeychainFactoryClient().cloneKeychainFactory(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(listKeychainFactoryConfigurations());
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Opprettet kopi av tilgangslisten!'
        })
      );
      return newId.toObject().newKeychainFactoryUri;
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const deleteKeychainFactory = createAppAsyncThunk<
  void,
  {
    keychainFactoryUri: string;
  }
>(
  'keychainFactories/deleteKeychainFactoryRequest',
  async ({ keychainFactoryUri }, thunkAPI) => {
    try {
      const request = new DeleteKeychainFactoryRequest();
      request.setKeychainFactoryUri(keychainFactoryUri);
      await getKeychainFactoryClient().deleteKeychainFactory(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({
          error: false,
          message: 'Tilgangslisten er slettet!'
        })
      );
      thunkAPI.dispatch(listKeychainFactoryConfigurations());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const CreateOidcRoleForm = z.object({
  keychainFactoryUri: noValidationString,
  roleId: id,
  name: name,
  description: description
});
export type CreateOidcRoleForm = z.infer<typeof CreateOidcRoleForm>;
export const createOidcRole = createAppAsyncThunk<void, CreateOidcRoleForm>(
  'keychainFactories/createOidcRole',
  async ({ keychainFactoryUri, roleId, name, description }, thunkAPI) => {
    try {
      const request = new CreateOidcRoleRequest();
      request.setKeychainFactoryUri(keychainFactoryUri);
      const role = new OidcRoleDefinition();
      role.setId(roleId);
      role.setName(name);
      role.setDescription(description);
      request.setDefinition(role);
      await getOidcKeychainClient().createOidcRole(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(fetchKeychainFactoryDetails(keychainFactoryUri));
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const EditOidcRole = z.object({
  uri: noValidationString,
  name: name,
  description: description
});
export type EditOidcRole = z.infer<typeof EditOidcRole>;
export const editOidcRole = createAppAsyncThunk<
  OidcUpdateRoleDescriptionRequest.AsObject,
  EditOidcRole
>(
  'keychainFactories/editOidcRole',
  async ({ uri, name, description }, thunkAPI) => {
    try {
      const request = new OidcUpdateRoleDescriptionRequest();
      request.setRoleUri(uri).setName(name).setDescription(description);
      await getOidcKeychainClient().oidcUpdateRoleDescription(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(fetchKeychainFactoryDetails(getKeystudioUri(uri)));
      return request.toObject();
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const deleteOidcRole = createAppAsyncThunk<
  void,
  {
    keychainFactoryUri: string;
    roleId: string;
  }
>(
  'keychainFactories/deleteOidcRole',
  async ({ keychainFactoryUri, roleId }, thunkAPI) => {
    try {
      const request = new DeleteOidcRoleRequest();
      request.setKeychainFactoryUri(keychainFactoryUri);
      request.setId(roleId);
      await getOidcKeychainClient().deleteOidcRole(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(fetchKeychainFactoryDetails(keychainFactoryUri));
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const listUsersWithRole = createAppAsyncThunk<
  { roleUri: string; entries: RoleEntry[] },
  string
>('keychainFactories/listUsersWithRole', async (roleUri, thunkAPI) => {
  try {
    const request = new OidcListUsersWithRoleRequest().setRoleUri(roleUri);
    const response = await getOidcKeychainClient().oidcListUsersWithRole(
      request,
      await getGrpcMetadata()
    );
    return { roleUri, entries: response.toObject().entriesList };
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const UpdateKeychainFactoryAdminRole = z.object({
  keychainFactoryUri: noValidationString,
  newOwner: optionTypeSchema
});

export type UpdateKeychainFactoryAdminRole = z.infer<
  typeof UpdateKeychainFactoryAdminRole
>;

export const changeKeychainFactoryAdminRole = createAppAsyncThunk<
  ChangeKeychainFactoryOwnerRequest.AsObject,
  UpdateKeychainFactoryAdminRole
>(
  'keychainFactories/changeKeychainFactoryAdminRole',
  async ({ keychainFactoryUri, newOwner: { value } }, thunkAPI) => {
    try {
      const request = new ChangeKeychainFactoryOwnerRequest();
      request.setKeychainFactoryUri(keychainFactoryUri);
      request.setNewOwnerRole(value);
      await getKeychainFactoryClient().changeKeychainFactoryOwner(
        request,
        await getGrpcMetadata()
      );
      return request.toObject();
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const openingHoursModelToObject = (
  openingHoursModel: OpeningHours
): OpeningHoursClass => {
  const openingHours = new OpeningHoursClass();
  openingHours.setSpec(openingHoursModel.spec);
  openingHours.setAlwaysOpen(openingHoursModel.alwaysOpen);
  openingHours.setSimpleDayRulesList(
    openingHoursModel.simpleDayRulesList.map(ruleModel => {
      const rule = new OpeningHoursClass.SimpleDayRule();
      rule.setDay(ruleModel.day);
      rule.setTimeIntervalsList(
        ruleModel.timeInterval.map(timeIntervalModelToObject)
      );
      return rule;
    })
  );
  openingHours.setDateOverrideRulesList(
    openingHoursModel.dateOverrideRulesList.map(ruleModel => {
      const rule = new OpeningHoursClass.DateOverrideRule();
      rule.setDate(new LocalDate().setDateString(ruleModel.date));
      rule.setTimeIntervalsList(
        ruleModel.timeInterval.map(timeIntervalModelToObject)
      );
      return rule;
    })
  );
  openingHours.setClosedDateRulesList(
    openingHoursModel.closedDateRulesList.map(ruleModel => {
      const rule = new OpeningHoursClass.ClosedDateRule();
      rule.setDate(new LocalDate().setDateString(ruleModel.date));
      return rule;
    })
  );
  return openingHours;
};
const timeIntervalModelToObject = (
  interval: TimeIntervalsList
): LocalTimeInterval => {
  const intervalObject = new LocalTimeInterval();
  intervalObject.setFrom(new LocalTime().setTimeString(interval.from));
  intervalObject.setUntil(new LocalTime().setTimeString(interval.until));
  return intervalObject;
};
