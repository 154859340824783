import React from 'react';

export const AlwaysOpenText = () => (
  <ul className='opening-hours'>
    <p className='light' data-testid='text'>
      Åpningstiden kan ikke ha overstyrende åpningstider når den er satt til{' '}
      <i>Alltid åpent</i>.
    </p>
  </ul>
);
