import React, { useEffect } from 'react';
import { Table } from '../../../components/Table/Table';
import { getThirdPartySystems } from '../../../redux/actions/thirdPartySystems';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Permission } from '../../../redux/slice/keychainFactoryDetails';
import { subjectSelectors } from '../../../redux/slice/subjects';
import { thirdPartySystemSelectors } from '../../../redux/slice/thirdPartySystems';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';
import {
  dateFromISO8601DateTime,
  formatDateAndTime
} from '../../../utils/timeConverter';

type Props = {
  thirdPartySystemId: string;
  permissions: Permission[];
};

export const PermissionsTable: React.FC<Props> = ({
  permissions,
  thirdPartySystemId
}) => {
  const dispatch = useAppDispatch();
  const thirdPartySystem = useAppSelector(state =>
    thirdPartySystemSelectors.selectById(state, thirdPartySystemId)
  );
  const subjects = useAppSelector(subjectSelectors.selectAll);
  const loading = useAppSelector(state => state.thirdPartySystems.isLoading);
  useEffect(() => {
    if (!thirdPartySystem && !loading) {
      dispatch(getThirdPartySystems());
    }
  }, [dispatch, thirdPartySystem, loading]);

  const rows = permissions.map((permission, index) => {
    return {
      cells: [
        <CopyWrapper
          copyValue={permission.assetIdsList.join(', ')}
          key={index}
        />,
        <ul key={index}>
          {permission.assetIdsList.map(assetId => {
            const asset = thirdPartySystem?.managedAssetsList.find(
              asset => asset.id === assetId
            );
            return asset?.assetsList.map(asset => {
              const subject = subjects.find(
                subject => subject.id === asset.operationId?.subjectId
              );
              if (!subject) {
                return (
                  <li key={asset?.operationId?.operationId}>
                    {asset?.operationId?.operationId}
                  </li>
                );
              }
              return (
                <li key={asset?.operationId?.operationId}>
                  {subject.name +
                    ': ' +
                    subject.operationsList.find(
                      operation =>
                        operation.id === asset.operationId?.operationId
                    )?.name}
                </li>
              );
            });
          })}
        </ul>,
        formatDateAndTime(
          dateFromISO8601DateTime(permission.from.date, permission.from.time)
        ),
        formatDateAndTime(
          dateFromISO8601DateTime(permission.until.date, permission.until.time)
        )
      ]
    };
  });

  return (
    <React.Fragment>
      <h3>Intervaller tilgangen er gyldig innenfor:</h3>
      <Table
        headings={['Ekstern ID', 'Gir tilgang til', 'Fra', 'Til']}
        rows={rows}
      />
    </React.Fragment>
  );
};
