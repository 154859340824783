import React from 'react';
import { Link } from 'react-router-dom';
import { KeychainCommon } from '../../../redux/slice/operations';
import { getKeychainFactoryLink } from '../../../utils/keychainFactory/getKeychainFactoryAssets';

type Props = {
  keychainFactoryList: KeychainCommon[];
};
export const LinkedKeychains: React.FC<Props> = ({ keychainFactoryList }) => {
  if (!keychainFactoryList.length) {
    return null;
  }
  return (
    <section className='linked-keychains'>
      <h3>Denne operasjonen får du tilgang igjennom disse tilgangslistene:</h3>
      <ul>
        {keychainFactoryList.map(kc => {
          return (
            <li key={kc.keychainFactoryUri}>
              <Link to={getKeychainFactoryLink(kc)}>{kc.name}</Link>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
