import axios from 'axios';
import { Ticket } from './types';

export const createTicket = async (ticket: Ticket) => {
  const formattedTicket = {
    request: ticket
  };
  const result = await axios({
    url: 'https://citykey.zendesk.com/api/v2/requests.json',
    method: 'post',
    data: formattedTicket,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return result.data;
};
