import React from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TextEditor } from '../../../../components/Editor/TextEditor';
import { reviseTerm } from '../../../../redux/actions/terms';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { Term } from '../../../../redux/slice/terms';

interface Props {
  term: Term;
  setEdit: (edit: boolean) => void;
}

export const EditInfoCard: React.FC<Props> = ({ term, setEdit }) => {
  const dispatch = useAppDispatch();

  const handleSaveClick = async (updatedText: string) => {
    if (term.currentText) {
      await dispatchAndHandleResult(
        dispatch,
        reviseTerm,
        {
          termId: term.id,
          text: updatedText,
          title: term.title,
          currentRevisionNumber: term.currentRevisionNumber
        },
        () => setEdit(false)
      );
    }
  };

  return (
    <div>
      <h3>Tekst</h3>
      <TextEditor
        type='advanced'
        message={term.currentText?.content}
        handleCancelClick={() => setEdit(false)}
        saveCallback={handleSaveClick}
      />
    </div>
  );
};
