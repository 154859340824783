import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LogsAndData } from '../pages/controlpanel/LogsAndData/LogsAndData';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const SuperadminRouter = () => {
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedAdminTool !== 'LogsAndData') {
      dispatch(setSelectedAdminTool('LogsAndData'));
    }
  }, [dispatch, selectedAdminTool]);

  return (
    <Routes>
      <Route path='/' element={<LogsAndData />} />
    </Routes>
  );
};
