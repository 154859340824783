import React from 'react';
import { OperationInfo } from '../../../../../../redux/slice/keychainFactoryDetails';
import { LockListItem } from './LockListItem';
import './LocksInKeychainFactoryList.scss';

interface Props {
  keychainFactoryUri: string;
  operations: OperationInfo[];
}

export const LocksInKeychainFactoryList: React.FC<Props> = ({
  keychainFactoryUri,
  operations
}) => {
  if (operations.length) {
    return (
      <ul className='locks'>
        {operations.map(operation => (
          <LockListItem
            key={operation.subjectId + operation.operationId}
            keychainFactoryUri={keychainFactoryUri}
            operation={operation}
          />
        ))}
      </ul>
    );
  } else {
    return <p>Det er ingen låser på denne tilgangslisten ennå.</p>;
  }
};
