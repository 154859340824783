import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EditableValue } from '../../../components/EditableValue/EditableValue';
import {
  AdminRoleSelector,
  createRoleOption
} from '../../../components/Forms/AdminRoleSelector';
import {
  changeKeychainFactoryAdminRole,
  UpdateKeychainFactoryAdminRole
} from '../../../redux/actions/keychainFactoryDetails';
import { useAppDispatch } from '../../../redux/hooks';
import { getKeystudioLink } from '../../../utils/citykeyId';
import { useAdminRole } from '../../../utils/roles/useAdminRole';

type Props = {
  keychainFactoryUri: string;
  roleId: string;
};
export const AdminRole: React.FC<Props> = ({ keychainFactoryUri, roleId }) => {
  const dispatch = useAppDispatch();
  const role = useAdminRole(roleId);
  const methods = useForm<UpdateKeychainFactoryAdminRole>({
    resolver: zodResolver(UpdateKeychainFactoryAdminRole),
    defaultValues: {
      keychainFactoryUri: keychainFactoryUri
    }
  });
  useEffect(() => {
    if (role) {
      methods.setValue('newOwner', createRoleOption(role));
    }
  }, [role]);
  const saveRole = (data: UpdateKeychainFactoryAdminRole) => {
    dispatch(changeKeychainFactoryAdminRole(data));
  };

  return (
    <EditableValue
      label='Adminrolle'
      link={getKeystudioLink(role?.uri || '')}
      value={role?.name || roleId}
      mainButtonClick={saveRole}
      methods={methods}
    >
      <AdminRoleSelector
        name='newOwner'
        description='Adminbrukere må ha denne rollen for å administrere tilgangslisten.'
      />
    </EditableValue>
  );
};
