import React, { useState } from 'react';
import { ActionMenuItemProps } from '../../../components/ActionMenu/ActionMenuItem';
import { TextToggle } from '../../../components/TextToggle/TextToggle';
import './HelpSection.scss';

interface HelpSectionProps {
  title: string;
  children: React.ReactNode;
  actions?: ActionMenuItemProps[];
}

export const HelpSection: React.FC<HelpSectionProps> = ({
  title,
  children,
  actions = []
}) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className='keystudio-help-section'>
      <TextToggle
        text={title}
        toggle={toggle}
        setToggle={setToggle}
        actions={actions}
      />

      {toggle && <div className='content'>{children}</div>}
    </div>
  );
};
