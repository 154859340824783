import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { OperationId } from '../../proto/shared_pb';
import {
  deleteOperationBucket,
  fetchOperationBuckets,
  getAllOperationIds
} from '../actions/buckets';
import { RootState } from '../index';

export type Bucket = {
  id: string;
  name: string;
  operationIdsList: BucketOperation[];
};
type State = {
  operationsList: BucketOperation[];
};

export type BucketOperation =
  | SubjectOperation
  | AdmissionCardOperation
  | OidcOperation;

type SubjectOperation = {
  operationType: 'subjectOperation';
  subjectId: string;
  operationId: string;
};

type AdmissionCardOperation = {
  operationType: 'admissionCard';
  type: string;
};

type OidcOperation = {
  operationType: 'oidc';
  realm: string;
  method: OperationId.Oidc.Method;
};

const bucketsAdapter = createEntityAdapter<Bucket>({
  sortComparer: (a, b) => a.name.localeCompare(b.name)
});

const bucketSlice = createSlice({
  name: 'buckets',
  initialState: bucketsAdapter.getInitialState<State>({
    operationsList: []
  }),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchOperationBuckets.fulfilled, (state, { payload }) => {
        const buckets = payload.map(bucket => {
          return {
            ...bucket,
            operationIdsList: bucket.operationIdsList.map(
              convertOperationsToModel
            )
          };
        });
        bucketsAdapter.setAll(state, buckets);
      })
      .addCase(getAllOperationIds.fulfilled, (state, { payload }) => {
        state.operationsList = payload.map(convertOperationsToModel);
      })
      .addCase(deleteOperationBucket.fulfilled, (state, { payload }) => {
        bucketsAdapter.removeOne(state, payload.id);
      });
  }
});
export const initialState = bucketSlice.getInitialState();
export type BucketsState = typeof initialState;
export default bucketSlice.reducer;
export const bucketSelectors = bucketsAdapter.getSelectors<RootState>(
  state => state.buckets
);

const convertOperationsToModel = (operation: OperationId.AsObject) => {
  const keys = Object.keys(operation) as Array<keyof typeof operation>;
  const operationType = keys.filter(key => {
    return operation[key] !== undefined;
  })[0];

  const data = operation[operationType];
  if (!data) {
    throw new Error('Invalid operation');
  }

  return {
    operationType,
    ...data
  } as BucketOperation;
};
