import React from 'react';

export const QrSvg = () => (
  <svg
    width='76'
    height='76'
    viewBox='0 0 76 76'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Group 1014'>
      <rect
        id='Rectangle 1677'
        x='0.5'
        y='0.5'
        width='35'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1678'
        x='50.5'
        y='0.5'
        width='25'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1679'
        x='50.5'
        y='20.5'
        width='25'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1680'
        x='35.5'
        y='5.5'
        width='15'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1681'
        x='25.5'
        y='10.5'
        width='17.5'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1682'
        x='10.5'
        y='10.5'
        width='5'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1683'
        x='60.5'
        y='10.5'
        width='5'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1684'
        x='0.5'
        y='20.5'
        width='25'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1685'
        x='5.5'
        y='50.5'
        width='15'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1686'
        x='25.5'
        y='53'
        width='10'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1687'
        x='40.5'
        y='35.5'
        width='10'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1688'
        x='5.5'
        y='70.5'
        width='15'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1689'
        x='10.5'
        y='30.5'
        width='10'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1690'
        x='25.5'
        y='25.5'
        width='10'
        height='5'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1691'
        x='5.5'
        y='5.5'
        width='15'
        height='5'
        transform='rotate(90 5.5 5.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1692'
        x='25.5'
        y='5.5'
        width='15'
        height='5'
        transform='rotate(90 25.5 5.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1693'
        x='75.5'
        y='5.5'
        width='15'
        height='5'
        transform='rotate(90 75.5 5.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1694'
        x='55.5'
        y='5.5'
        width='15'
        height='5'
        transform='rotate(90 55.5 5.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1695'
        x='55.5'
        y='25.5'
        width='10'
        height='5'
        transform='rotate(90 55.5 25.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1696'
        x='70.5'
        y='25.5'
        width='20'
        height='5'
        transform='rotate(90 70.5 25.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1697'
        x='60.5'
        y='35.5'
        width='10'
        height='5'
        transform='rotate(90 60.5 35.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1698'
        x='65.5'
        y='45.5'
        width='5'
        height='5'
        transform='rotate(90 65.5 45.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1699'
        x='45.5'
        y='53'
        width='5'
        height='5'
        transform='rotate(90 45.5 53)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1719'
        x='50.5'
        y='48'
        width='5'
        height='5'
        transform='rotate(90 50.5 48)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1720'
        x='60.5'
        y='50.5'
        width='5'
        height='5'
        transform='rotate(90 60.5 50.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1700'
        x='40.5'
        y='58'
        width='5'
        height='5'
        transform='rotate(90 40.5 58)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1701'
        x='15.5'
        y='60.5'
        width='5'
        height='5'
        transform='rotate(90 15.5 60.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1702'
        x='30.5'
        y='65.5'
        width='5'
        height='5'
        transform='rotate(90 30.5 65.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1721'
        x='50.5'
        y='70.5'
        width='5'
        height='20'
        transform='rotate(90 50.5 70.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1703'
        x='75.5'
        y='45.5'
        width='10'
        height='5'
        transform='rotate(90 75.5 45.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1704'
        x='70.5'
        y='50.5'
        width='12.5'
        height='5'
        transform='rotate(90 70.5 50.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1705'
        x='75.5'
        y='63'
        width='12.5'
        height='5'
        transform='rotate(90 75.5 63)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1706'
        x='60.5'
        y='63'
        width='10'
        height='5'
        transform='rotate(90 60.5 63)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1707'
        x='65.5'
        y='63'
        width='5'
        height='5'
        transform='rotate(90 65.5 63)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1708'
        x='55.5'
        y='48'
        width='15'
        height='5'
        transform='rotate(90 55.5 48)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1709'
        x='50.5'
        y='58'
        width='12.5'
        height='5'
        transform='rotate(90 50.5 58)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1710'
        x='10.5'
        y='25.5'
        width='10'
        height='5'
        transform='rotate(90 10.5 25.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1711'
        x='25.5'
        y='35.5'
        width='15'
        height='5'
        transform='rotate(90 25.5 35.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1712'
        x='25.5'
        y='50.5'
        width='25'
        height='5'
        transform='rotate(90 25.5 50.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1713'
        x='5.5'
        y='50.5'
        width='25'
        height='5'
        transform='rotate(90 5.5 50.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1714'
        x='35.5'
        y='20.5'
        width='5'
        height='5'
        transform='rotate(90 35.5 20.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1715'
        x='35.5'
        y='30.5'
        width='22.5'
        height='5'
        transform='rotate(90 35.5 30.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1716'
        x='40.5'
        y='30.5'
        width='20'
        height='5'
        transform='rotate(90 40.5 30.5)'
        fill='#2A2859'
      />
      <rect
        id='Rectangle 1717'
        x='48'
        y='15.5'
        width='10'
        height='5'
        transform='rotate(90 48 15.5)'
        fill='#2A2859'
      />
    </g>
  </svg>
);
