import React from 'react';
import { Subject } from '../../../../../../redux/slice/subjects';
import './SubjectList.scss';
import { SubjectListItem } from './SubjectListItem';

interface Props {
  subjects: Subject[];
}

export const SubjectList: React.FC<Props> = ({ subjects }) => {
  if (subjects.length) {
    return (
      <ul className='subject-list'>
        {subjects.map(subject => (
          <SubjectListItem key={subject.id} subject={subject} />
        ))}
      </ul>
    );
  } else {
    return <p>Finner ingen steder du kan velge.</p>;
  }
};
