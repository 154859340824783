import React from 'react';

export const SadSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='47.294'
    height='46.463'
    viewBox='0 0 47.294 46.463'
  >
    <g id='Group_1608' data-name='Group 1608' transform='translate(0 0)'>
      <ellipse
        id='Ellipse_462'
        data-name='Ellipse 462'
        cx='23.647'
        cy='23.232'
        rx='23.647'
        ry='23.232'
        transform='translate(0 0)'
        fill='#ff8274'
      />
      <path
        id='Path_2904'
        data-name='Path 2904'
        d='M289.252,663.321l-2.088,1.989,2.088,1.989,2.087,1.988,2.088-1.988-2.088-1.989,2.088-1.989-2.088-1.988Z'
        transform='translate(-261.461 -650.748)'
      />
      <path
        id='Path_2905'
        data-name='Path 2905'
        d='M273.662,667.3l2.087-1.989-2.087-1.989-2.088-1.988-2.087,1.988,2.087,1.989-2.087,1.989,2.087,1.988Z'
        transform='translate(-254.221 -650.748)'
      />
      <path
        id='Path_2906'
        data-name='Path 2906'
        d='M282.011,688.182l-4.175-3.977-4.175,3.977-4.175,3.977,4.175,3.977,4.175-3.977,4.175,3.977,4.175-3.977Z'
        transform='translate(-254.221 -660.164)'
      />
    </g>
  </svg>
);
