import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogFormBox } from '../../../components/DialogBox/DialogFormBox';
import { KeyText } from '../../../components/Forms/KeyText';
import {
  addGroup,
  FAQGroup,
  updateGroupTitle,
  Group
} from '../../../redux/actions/broadcast';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  group?: FAQGroup;
}
export const GroupDialog: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  group
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<Group>({
    resolver: zodResolver(Group),
    defaultValues: {
      groupId: ''
    },
    values: group && {
      title: group.title,
      groupId: group.id
    }
  });

  return (
    <DialogFormBox
      methods={methods}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      header='FAQ: Spørsmålsgruppe'
      onSubmit={data =>
        dispatchAndHandleResult(
          dispatch,
          group ? updateGroupTitle : addGroup,
          data,
          closeModal
        )
      }
    >
      <KeyText
        name='title'
        label='Gruppeoverskrift'
        description='Tittelen på spørsmålsgruppen'
        elementProps={{}}
      />
    </DialogFormBox>
  );
};
