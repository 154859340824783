import { zodResolver } from '@hookform/resolvers/zod';
import { addYears, formatISO } from 'date-fns';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { EndDatePicker } from '../../../../components/DatePickers/EndDatePicker';
import { DialogBoxType } from '../../../../components/DialogBox/useDialogBox';
import { KeyText } from '../../../../components/Forms/KeyText';
import { OidcRoleSelector } from '../../../../components/Forms/OidcRoleSelector';
import { grantOidcAccess } from '../../../../redux/actions/keychains';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { OidcKeychainFactoryDetails } from '../../../../redux/slice/keychainFactoryDetails';
import { email, optionTypeSchema } from '../../../../redux/types/zodSchemas';
import { PhoneNumberSearch } from './PhoneNumberSearch';

interface Props {
  DialogBox: DialogBoxType;
  keychainFactory: OidcKeychainFactoryDetails;
  closeModal: () => void;
}

const FormSchema = z.object({
  endDate: z.date(),
  phoneNumber: z.string().trim(),
  countryCode: z.string(),
  email: email,
  selectedRoles: z.array(optionTypeSchema).default([])
});
type FormSchema = z.infer<typeof FormSchema>;

export const GrantOidcAccess: React.FC<Props> = ({
  DialogBox,
  keychainFactory,
  closeModal
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<FormSchema>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      endDate: addYears(new Date(), 1),
      countryCode: '+47'
    }
  });
  const { watch, reset } = methods;
  const { endDate, phoneNumber } = watch();

  const { name } = useAppSelector(state => state.access.phoneSearchResult);
  const onButtonClick = (data: FormSchema) =>
    dispatchAndHandleResult(
      dispatch,
      grantOidcAccess,
      {
        ...data,
        keychainFactoryUri: keychainFactory.keychainFactoryUri,
        countryCode: data.countryCode.replace('+', ''),
        endDate: formatISO(data.endDate, { representation: 'date' }),
        roles: data.selectedRoles.map(role => role.value),
        shareable: false
      },
      () => {
        closeModal();
        reset();
      }
    );

  return (
    <DialogBox
      buttonLabel='Send tilgang'
      methods={methods}
      onSubmit={onButtonClick}
      header='Gi tilgang'
    >
      <div className='grant-access'>
        <p>Denne personen skal ha tilgang:</p>
        <PhoneNumberSearch
          phoneNumber={phoneNumber}
          setPhoneNumber={(phoneNumber: string) => {
            methods.setValue('phoneNumber', phoneNumber);
          }}
        />
        {name && (
          <React.Fragment>
            <EndDatePicker
              name='endDate'
              startDate={new Date()}
              endDate={endDate}
              minDate={new Date()}
            />
            <KeyText
              name='email'
              elementProps={{
                type: 'email'
              }}
              label='E-post'
              description='Endre e-postadressen til brukeren'
            />
            <OidcRoleSelector
              name='selectedRoles'
              roleDefinitions={keychainFactory.roleDefinitionsList}
            />
          </React.Fragment>
        )}
      </div>
    </DialogBox>
  );
};
