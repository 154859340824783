import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogFormBox } from '../../../../components/DialogBox/DialogFormBox';
import { updateOperationBucketOperations } from '../../../../redux/actions/buckets';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { Bucket, BucketOperation } from '../../../../redux/slice/buckets';
import { SelectOperations } from './SelectOperations';

type Props = {
  bucket: Bucket;
  closeModal: () => void;
  isModalOpen: boolean;
};

type FormSchema = {
  selectedOperations: { value: BucketOperation }[];
};

export const EditOperationsListModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  bucket
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<FormSchema>();
  const operationsList = useAppSelector(state => state.buckets.operationsList);

  return (
    <DialogFormBox
      methods={methods}
      buttonLabel='Bekreft'
      onSubmit={() =>
        dispatchAndHandleResult(
          dispatch,
          updateOperationBucketOperations,
          {
            id: bucket.id,
            operationsList: methods
              .getValues('selectedOperations')
              .map(selectedOperations => selectedOperations.value)
          },
          closeModal
        )
      }
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      header='Legg til/fjern operasjoner'
    >
      <SelectOperations
        operationsList={operationsList}
        initialSelected={bucket.operationIdsList}
        rerenderNudge={isModalOpen}
      />
    </DialogFormBox>
  );
};
