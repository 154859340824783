import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogFormBox } from '../../../components/DialogBox/DialogFormBox';
import { KeyText } from '../../../components/Forms/KeyText';
import { KeyTextarea } from '../../../components/Forms/KeyTextarea';
import {
  addQuestion,
  FAQQuestion,
  QuestionPayload,
  updateQuestion
} from '../../../redux/actions/broadcast';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  groupId: string;
  question?: FAQQuestion;
}
export const QuestionDialog: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  groupId,
  question
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm<QuestionPayload>({
    values: {
      groupId: groupId,
      question: question || { question: '', answer: '', id: '' }
    },
    resolver: zodResolver(QuestionPayload)
  });
  return (
    <DialogFormBox
      methods={methods}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      header='FAQ: Spørsmål og svar'
      onSubmit={data =>
        dispatchAndHandleResult(
          dispatch,
          question ? updateQuestion : addQuestion,
          data,
          closeModal
        )
      }
    >
      <KeyText
        name='question.question'
        label='Spørsmål'
        description='Tittelen på spørsmålet'
        elementProps={{}}
      />
      <KeyTextarea
        name='question.answer'
        label='Svar'
        description='Utfyllende svar på spørsmålet'
        elementProps={{}}
      />
    </DialogFormBox>
  );
};
