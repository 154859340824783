import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { EditableValue } from '../../../components/EditableValue/EditableValue';
import { actionsHome } from '../../../components/Navigation/controlpanelNavPaths';
import {
  UpdateOperationActionChain,
  updateOperationActionChain
} from '../../../redux/actions/operations';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch } from '../../../redux/hooks';
import { ActionChainSelector } from './ActionChainSelector';

type Props = {
  actionChainId: string;
  subjectId: string;
  operationId: string;
};
export const LinkedActionChain: React.FC<Props> = ({
  actionChainId,
  subjectId,
  operationId
}) => {
  const dispatch = useAppDispatch();

  const methods = useForm<UpdateOperationActionChain>({
    resolver: zodResolver(UpdateOperationActionChain),
    defaultValues: {
      subjectId,
      operationId
    }
  });

  const saveChain = (data: UpdateOperationActionChain) => {
    return dispatchAndHandleResult(dispatch, updateOperationActionChain, data);
  };

  return (
    <EditableValue
      methods={methods}
      label='Tilknyttet ActionChain'
      link={`${actionsHome.path}/${actionChainId}`}
      value={actionChainId}
      mainButtonClick={saveChain}
    >
      <ActionChainSelector actionChainId={actionChainId} />
    </EditableValue>
  );
};
