import React from 'react';

export const LifespanSvg = () => (
  <svg
    width='100'
    height='80'
    viewBox='0 0 49 39'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M36.75 19.122V26.0059L42.1094 25.5506V27.5356H35.2188V19.122H36.75Z'
      fill='#2A2859'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M25.2656 26.0059C25.2656 19.6698 30.4078 14.5327 36.75 14.5327C43.0922 14.5327 48.2344 19.6698 48.2344 26.0059C48.2344 32.3429 43.0922 37.4791 36.75 37.4791C30.4078 37.4791 25.2656 32.3429 25.2656 26.0059ZM26.9108 26.0059C26.9108 31.4337 31.316 35.8355 36.75 35.8355C42.184 35.8355 46.5892 31.4337 46.5892 26.0059C46.5892 20.5772 42.184 16.1762 36.75 16.1762C31.316 16.1762 26.9108 20.5772 26.9108 26.0059Z'
      fill='#2A2859'
    />
    <path
      d='M26.1612 34.2348L25.0878 35.3056C23.8766 36.5172 21.7574 36.5172 20.5446 35.3056L5.81399 20.5894C3.83715 18.6145 2.74996 15.9909 2.74996 13.2022C2.74996 10.4134 3.83868 7.7899 5.81399 5.81499C5.85074 5.77827 5.88596 5.74003 5.91811 5.69873C10.0096 1.73513 16.5664 1.76878 20.6028 5.80122L21.3807 6.57681C22.1846 7.3371 23.4479 7.33863 24.2809 6.5508L25.0327 5.80122C29.066 1.76878 35.6213 1.73666 39.7128 5.6972C39.745 5.7385 39.7817 5.77827 39.8185 5.81652C41.9255 7.92146 43.0295 10.8249 42.8488 13.7835C42.8029 14.5423 43.3817 15.1955 44.1412 15.2429C44.89 15.2704 45.5545 14.7105 45.6005 13.9533C45.8256 10.262 44.475 6.64106 41.8872 3.98999C41.8504 3.94257 41.8091 3.89667 41.7677 3.85384C36.609 -1.28461 28.2269 -1.28461 23.0834 3.85384L22.817 4.12002L22.5506 3.85537C17.4071 -1.28308 9.02502 -1.28461 3.86624 3.85384C3.8249 3.89514 3.78508 3.93951 3.74987 3.9854C-1.28795 9.14221 -1.24967 17.4289 3.86471 22.5368L18.5953 37.253C19.7223 38.3789 21.2214 39 22.817 39C24.4126 39 25.9116 38.3804 27.0356 37.2545L28.1075 36.1837C28.6465 35.6452 28.648 34.7748 28.109 34.2363C27.57 33.6979 26.6956 33.6994 26.1612 34.2348Z'
      fill='#2A2859'
    />
  </svg>
);
