import React from 'react';
import { AccessControllIcon2 } from '../../../assets/AccessControlIcon2';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { AuditCard } from '../../../components/cards/AuditCard/AuditCard';
import { LinkToControlpanelCard } from '../../../components/cards/ControlpanelCards/LinkToControlpanelCard';
import { NarrowCard } from '../../../components/cards/NarrowCard/NarrowCard';
import { StatsCard } from '../../../components/cards/StatsCard/StatsCard';
import { ImageCard } from '../../../components/image/ImageCard';
import { PushKeychainFactoryDetails } from '../../../redux/slice/keychainFactoryDetails';
import { PushKeychainFactorySummary } from '../../../redux/slice/keychainFactorySummaries';
import { AdminRoleCard } from '../AdminRoleCard/AdminRoleCard';
import { KeychainFactoryBreadcrumbs } from '../KeychainFactoryBreadcrumbs';
import { KeychainFactoryContainer } from '../KeychainFactoryContainer/KeychainFactoryContainer';
import { Locks } from '../locks/Locks';
import { PermissionsTable } from './PermissionsTable';

interface Props {
  keychainFactory: PushKeychainFactoryDetails | PushKeychainFactorySummary;
}

export const PushKeychainFactoryPage: React.FC<Props> = ({
  keychainFactory
}) => {
  const { type } = keychainFactory;
  return (
    <KeychainFactoryContainer>
      <KeychainFactoryBreadcrumbs keychainFactory={keychainFactory} />
      <div className='cards'>
        <ImageCard
          images={[{ uri: keychainFactory.image }]}
          keychainFactoryUri={keychainFactory.keychainFactoryUri}
        />
        <AdminRoleCard adminRole={keychainFactory.ownerRole} />
      </div>
      <div className='cards'>
        <NarrowCard
          link='tilgangskontroll'
          header='Tilgangsoversikt'
          icon={<AccessControllIcon2 />}
        >
          {keychainFactory.isDetails && (
            <h4 className='number-of-requests-text'>
              Antall brukere: {keychainFactory.approvedKeychains}
            </h4>
          )}
        </NarrowCard>
        <NarrowCard header='Avventene tilganger'>
          Antall brukere som ikke har registrert telefonnummer i appen ennå:
          <br />
          <br />
          <center>
            <b>
              {keychainFactory.isDetails
                ? keychainFactory.pendingRecipients.length
                : undefined}
            </b>
          </center>
        </NarrowCard>
        <StatsCard type={type} id={keychainFactory.keychainFactoryUri} />
        <LinkToControlpanelCard type={type} />
        <AuditCard type={type} citykeyId={keychainFactory.keychainFactoryUri} />
      </div>
      {keychainFactory.isDetails && (
        <PermissionsTable
          thirdPartySystemId={keychainFactory.thirdPartySystemId}
          permissions={keychainFactory.permissions}
        />
      )}
      <br />
      {keychainFactory.isDetails ? (
        <Locks
          operations={keychainFactory.grantedOperationsList}
          readonly={true}
        />
      ) : (
        <LoadingSpinner />
      )}
    </KeychainFactoryContainer>
  );
};
