import React from 'react';

export const Categories = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='136'
    height='136'
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12195'
      data-name='Group 12195'
      transform='translate(-790 -474)'
    >
      <g
        id='Group_12190'
        data-name='Group 12190'
        transform='translate(-696 255)'
      >
        <g
          id='Group_12177'
          data-name='Group 12177'
          transform='translate(1158 -13)'
        >
          <circle
            id='Ellipse_691'
            data-name='Ellipse 691'
            cx='68'
            cy='68'
            r='68'
            transform='translate(328 232)'
            fill='#f9b3ab'
          />
        </g>
      </g>
      <g id='Group_12194' data-name='Group 12194' transform='translate(-1 -1)'>
        <g
          id='Ellipse_704'
          data-name='Ellipse 704'
          transform='translate(826 534)'
          fill='#292858'
          stroke='#292858'
          strokeWidth='1'
        >
          <circle cx='9' cy='9' r='9' stroke='none' />
          <circle cx='9' cy='9' r='8.5' fill='none' />
        </g>
        <g
          id='Rectangle_1916'
          data-name='Rectangle 1916'
          transform='translate(874 534)'
          fill='#292858'
          stroke='#292858'
          strokeWidth='1'
        >
          <rect width='18' height='18' stroke='none' />
          <rect x='0.5' y='0.5' width='17' height='17' fill='none' />
        </g>
        <path
          id='Polygon_15'
          data-name='Polygon 15'
          d='M9,0l9,18H0Z'
          transform='translate(850 534)'
          fill='#292858'
        />
      </g>
    </g>
  </svg>
);
