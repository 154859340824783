import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { formatISO } from 'date-fns';
import { OidcRootSession, OidcSession } from '../../proto/keychain-shared_pb';
import {
  KeychainAdminPolicy,
  KeychainStatus as StatusObject,
  ListKeychainsResponse
} from '../../proto/keychain_pb';
import {
  deleteKeychainExpireDateRequest,
  extendKeychainExpireDateRequest,
  fetchListKeychains,
  getKeychainDetails,
  replaceRoles,
  setUserEmail
} from '../actions/keychains';
import { RootState } from '../index';
import { policiesListToModel } from '../types/common';

export interface OperationBridge {
  subjectId: string;
  operationId: string;
}

export interface Keychain {
  id: string;
  status: KeychainStatus;
  personName: string;
  profileId: string;
  usageCounter: number;
  lastUsedAtEpochSeconds: number;
  policies: ReturnType<
    typeof policiesListToModel<KeychainAdminPolicy.AsObject>
  >;
  fromTime: string;
  fromDate: string;
  untilTime: string;
  untilDate: string;
  roles: Role[];
  sessions: RootSession[];
  emailAddress: string;
  shareable: boolean;
  grantedBy: GrantedBy;
}

type GrantedByUser = {
  type: 'user';
  name: string;
  keychainId: string;
};
type GrantedByAdmin = {
  type: 'admin';
  name: string;
};

type GrantedByUnknown = {
  type: 'unknown';
  name: 'Ukjent';
};

export type GrantedBy = GrantedByUser | GrantedByAdmin | GrantedByUnknown;

export type Role = {
  id: string;
  name: string;
  description: string;
  uri: string;
};

type RootSession = {
  session: SessionDetail;
  childSessions: SessionDetail[];
};

export type SessionDetail = {
  sessionId: string;
  startedAt: number;
  refreshedAt: number;
  refreshCount: number;
};

export enum KeychainStatus {
  APPROVED = 'APPROVED',
  EXPIRED = 'EXPIRED'
}

const keychainsAdapter = createEntityAdapter<Keychain>({
  sortComparer: (a, b) => a.personName.localeCompare(b.personName)
});

const keychainsSlice = createSlice({
  name: 'keychains',
  initialState: keychainsAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchListKeychains.fulfilled, (state, { payload }) => {
        keychainsAdapter.setAll(
          state,
          payload.keychainsList.map(keychainToModel)
        );
      })
      .addCase(getKeychainDetails.fulfilled, (state, { payload }) => {
        if (payload.oidc) {
          keychainsAdapter.updateOne(state, {
            id: payload.keychainUri,
            changes: {
              emailAddress: payload.oidc.emailAddress,
              roles: payload.oidc.rolesList,
              sessions: payload.oidc.sessionsList.map(rootSessionToModel),
              policies: policiesListToModel(payload.policies?.policiesList)
            }
          });
        }
      })
      .addCase(
        extendKeychainExpireDateRequest.fulfilled,
        (state, { payload }) => {
          keychainsAdapter.updateOne(state, {
            id: payload.keychainId,
            changes: {
              untilDate: payload.newExpireDate,
              untilTime: '23:59',
              status: KeychainStatus.APPROVED,
              shareable: payload.shareable
            }
          });
        }
      )
      .addCase(setUserEmail.fulfilled, (state, { payload }) => {
        keychainsAdapter.updateOne(state, {
          id: payload.keychainUri,
          changes: {
            emailAddress: payload.email
          }
        });
      })
      .addCase(replaceRoles.fulfilled, (state, { payload }) => {
        keychainsAdapter.updateOne(state, {
          id: payload.keychainUri,
          changes: {
            roles: payload.roles
          }
        });
      })
      .addCase(
        deleteKeychainExpireDateRequest.fulfilled,
        (state, { payload }) => {
          if (payload.expiredInsteadOfDelete) {
            keychainsAdapter.updateOne(state, {
              id: payload.keychainId,
              changes: {
                status: KeychainStatus.EXPIRED,
                untilDate: formatISO(new Date(), { representation: 'date' }),
                untilTime: '00:00'
              }
            });
          } else {
            keychainsAdapter.removeOne(state, payload.keychainId);
          }
        }
      );
  }
});

export const initialState = keychainsSlice.getInitialState();
export type KeychainsState = typeof initialState;
export default keychainsSlice.reducer;

export const keychainsSelectors = keychainsAdapter.getSelectors<RootState>(
  state => state.keychains
);

const keychainToModel = (
  keychain: ListKeychainsResponse.Keychain.AsObject
): Keychain => ({
  id: keychain.id,
  personName: keychain.personname,
  profileId: keychain.profileid,
  usageCounter: keychain.usageCounter,
  lastUsedAtEpochSeconds: keychain.lastUsedAtEpochSeconds,
  policies: policiesListToModel(keychain.policies?.policiesList),
  fromTime: keychain.activeinterval?.from?.time?.timeString || '',
  fromDate: keychain.activeinterval?.from?.date?.dateString || '',
  untilTime: keychain.activeinterval?.until?.time?.timeString || '',
  untilDate: keychain.activeinterval?.until?.date?.dateString || '',
  status: keychainStatusToModel(keychain.status),
  roles: [],
  sessions: [],
  emailAddress: '',
  shareable: keychain.shareable,
  grantedBy: grantedByToModel(keychain.grantedBy || {})
});

const grantedByToModel = (
  grantedBy: ListKeychainsResponse.Keychain.GrantedBy.AsObject
): GrantedBy => {
  if (grantedBy.user) {
    return {
      type: 'user',
      name: `${grantedBy.user.firstName} ${grantedBy.user.lastName}`.toLowerCase(),
      keychainId: grantedBy.user.keychainId
    };
  } else if (grantedBy.admin) {
    return {
      type: 'admin',
      name: grantedBy.admin.username
    };
  } else {
    return {
      type: 'unknown',
      name: 'Ukjent'
    };
  }
};

const keychainStatusToModel = (status: StatusObject): KeychainStatus => {
  switch (status) {
    case StatusObject.APPROVED:
      return KeychainStatus.APPROVED;
    case StatusObject.EXPIRED:
      return KeychainStatus.EXPIRED;
  }
};

const sessionToModel = (session?: OidcSession.AsObject): SessionDetail => ({
  sessionId: session?.sessionId || '',
  startedAt: session?.startedAt?.epochMillis || 0,
  refreshedAt: session?.refreshedAt?.epochMillis || 0,
  refreshCount: session?.refreshCount || 0
});

const rootSessionToModel = (
  session: OidcRootSession.AsObject
): RootSession => ({
  session: sessionToModel(session.session),
  childSessions: session.childSessionsList.map(sessionToModel)
});
