import React from 'react';

export const DescriptionSvg = () => (
  <svg
    width='48'
    height='41'
    viewBox='0 0 48 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      id='Vector'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 0H48V35.3461H29.6105L24 41L18.3877 35.3461H0V0ZM28.0815 31.6255H44.3077V3.72064H3.69231V31.6255H19.9185L24 35.7368L28.0815 31.6255Z'
      fill='#2A2859'
    />
  </svg>
);
