import React from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import { Toggle } from '../Toggle/Toggle';
import { ToggleAll } from '../Toggle/ToggleAll';

type KeychainFactoryToggleListProps = {
  selectedIds: string[];
  setSearchParams: SetURLSearchParams;
  searchParamName: string;
  displayData: {
    id: string;
    name: string;
    color?: string;
  }[];
};

export const ToggleList = ({
  setSearchParams,
  selectedIds,
  searchParamName,
  displayData
}: KeychainFactoryToggleListProps) => {
  const setSelectedIds = (ids: string[]) => {
    setSearchParams(
      searchParams => {
        searchParams.delete(searchParamName);
        ids.forEach(id => searchParams.append(searchParamName, id));
        return searchParams;
      },
      { replace: true }
    );
  };
  return (
    <div className='toggle-list-container'>
      <ul className='toggle-list'>
        {displayData.length > 1 && (
          <ToggleAll
            allIds={displayData.map(({ id }) => id)}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
        )}
        {displayData.map(({ id, name, color }) => (
          <Toggle
            key={id}
            id={id}
            name={name}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            color={color}
          />
        ))}
      </ul>
    </div>
  );
};
