import React from 'react';

export const Anomaly: React.FC = () => (
  <svg
    width='35'
    height='40'
    viewBox='5 5 44 42'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M25.7504 10L26.3511 27.9995H29.6497L30.2489 10H25.7504Z'
      fill='#2A2859'
    />
    <path
      d='M25.0004 33.9996C25.0004 34.5929 25.1763 35.1728 25.5059 35.6661C25.8355 36.1594 26.304 36.5439 26.8521 36.771C27.4001 36.9981 28.0033 37.0575 28.5851 36.9418C29.167 36.8261 29.7015 36.5405 30.1211 36.1211C30.5407 35.7016 30.8264 35.1672 30.9423 34.5853C31.0581 34.0035 30.9988 33.4003 30.7719 32.8522C30.545 32.304 30.1606 31.8355 29.6674 31.5058C29.1742 31.176 28.5943 31 28.001 30.9998C27.6052 30.993 27.212 31.066 26.8449 31.2144C26.4779 31.3627 26.1444 31.5834 25.8645 31.8633C25.5845 32.1432 25.3637 32.4766 25.2153 32.8436C25.0668 33.2106 24.9937 33.6038 25.0004 33.9996Z'
      fill='#2A2859'
    />
  </svg>
);
