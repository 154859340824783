import React from 'react';

type Props = {
  size?: number;
};
export const PublicChains: React.FC<Props> = ({ size = 136 }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 156 156'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='78' cy='78' r='78' fill='#F9B3AB' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M65 104.641C68.5627 107.373 73.0391 109 77.9005 109C82.7766 109 87.2654 107.363 90.8334 104.617L85.868 91.0088H69.935L65 104.641ZM68.1865 103.441L71.8589 93.7767H83.9421L87.6144 103.441C84.7977 105.198 81.4732 106.232 77.9005 106.232C74.3277 106.232 71.0032 105.198 68.1865 103.441Z'
      fill='#2A2859'
    />
    <path
      d='M83.0906 83.1204C83.0906 85.9484 80.7669 88.241 77.9005 88.241C75.0341 88.241 72.7104 85.9484 72.7104 83.1204C72.7104 80.2924 75.0341 77.9999 77.9005 77.9999C80.7669 77.9999 83.0906 80.2924 83.0906 83.1204Z'
      fill='#2A2859'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M80.5 73.6416C84.0627 76.3733 88.5392 78 93.4005 78C98.2766 78 102.765 76.3634 106.333 73.6167L101.368 60.0089H85.435L80.5 73.6416ZM83.6866 72.4408L87.3589 62.7768H99.4421L103.114 72.4408C100.298 74.1984 96.9733 75.2322 93.4005 75.2322C89.8277 75.2322 86.5033 74.1984 83.6866 72.4408Z'
      fill='#2A2859'
    />
    <path
      d='M98.5906 52.1205C98.5906 54.9485 96.2669 57.2411 93.4005 57.2411C90.5341 57.2411 88.2104 54.9485 88.2104 52.1205C88.2104 49.2925 90.5341 47 93.4005 47C96.2669 47 98.5906 49.2925 98.5906 52.1205Z'
      fill='#2A2859'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M96.0001 104.641C99.5628 107.373 104.039 109 108.901 109C113.777 109 118.265 107.363 121.833 104.617L116.868 91.0088H100.935L96.0001 104.641ZM99.1866 103.441L102.859 93.7767H114.942L118.614 103.441C115.798 105.198 112.473 106.232 108.901 106.232C105.328 106.232 102.003 105.198 99.1866 103.441Z'
      fill='#2A2859'
    />
    <path
      d='M114.091 83.1204C114.091 85.9484 111.767 88.241 108.901 88.241C106.034 88.241 103.71 85.9484 103.71 83.1204C103.71 80.2924 106.034 77.9999 108.901 77.9999C111.767 77.9999 114.091 80.2924 114.091 83.1204Z'
      fill='#2A2859'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 104.641C37.5627 107.373 42.0391 109 46.9005 109C51.7766 109 56.2654 107.363 59.8334 104.617L54.868 91.0088H38.935L34 104.641ZM37.1865 103.441L40.8589 93.7767H52.9421L56.6144 103.441C53.7977 105.198 50.4732 106.232 46.9005 106.232C43.3277 106.232 40.0032 105.198 37.1865 103.441Z'
      fill='#2A2859'
    />
    <path
      d='M52.0906 83.1204C52.0906 85.9484 49.7669 88.241 46.9005 88.241C44.0341 88.241 41.7104 85.9484 41.7104 83.1204C41.7104 80.2924 44.0341 77.9999 46.9005 77.9999C49.7669 77.9999 52.0906 80.2924 52.0906 83.1204Z'
      fill='#2A2859'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M49.5001 73.6416C53.0628 76.3733 57.5392 78 62.4006 78C67.2767 78 71.7655 76.3634 75.3335 73.6167L70.3681 60.0089H54.4351L49.5001 73.6416ZM52.6867 72.4408L56.359 62.7768H68.4422L72.1145 72.4408C69.2978 74.1984 65.9734 75.2322 62.4006 75.2322C58.8278 75.2322 55.5034 74.1984 52.6867 72.4408Z'
      fill='#2A2859'
    />
    <path
      d='M67.5907 52.1205C67.5907 54.9485 65.267 57.2411 62.4006 57.2411C59.5342 57.2411 57.2105 54.9485 57.2105 52.1205C57.2105 49.2925 59.5342 47 62.4006 47C65.267 47 67.5907 49.2925 67.5907 52.1205Z'
      fill='#2A2859'
    />
  </svg>
);
