import React from 'react';

export const Spread = () => (
  <svg
    width='80'
    height='80'
    viewBox='0 0 49 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M24.3283 29.0647C26.7574 29.0647 28.7266 27.0955 28.7266 24.6664C28.7266 22.2373 26.7574 20.2681 24.3283 20.2681C21.8992 20.2681 19.93 22.2373 19.93 24.6664C19.93 27.0955 21.8992 29.0647 24.3283 29.0647Z'
      fill='#292858'
    />
    <path
      d='M24.3283 5.16272C25.7539 5.16272 26.9096 4.007 26.9096 2.58136C26.9096 1.15571 25.7539 0 24.3283 0C22.9026 0 21.7469 1.15571 21.7469 2.58136C21.7469 4.007 22.9026 5.16272 24.3283 5.16272Z'
      fill='#292858'
    />
    <path
      d='M10.1334 10.3293C11.5583 10.3293 12.7134 9.1742 12.7134 7.74927C12.7134 6.32433 11.5583 5.16919 10.1334 5.16919C8.70843 5.16919 7.55329 6.32433 7.55329 7.74927C7.55329 9.1742 8.70843 10.3293 10.1334 10.3293Z'
      fill='#292858'
    />
    <path
      d='M2.58008 23.4102C4.00502 23.4102 5.16016 22.255 5.16016 20.8301C5.16016 19.4051 4.00502 18.25 2.58008 18.25C1.15514 18.25 0 19.4051 0 20.8301C0 22.255 1.15514 23.4102 2.58008 23.4102Z'
      fill='#292858'
    />
    <path
      d='M3.9143 33.472C5.14736 32.7601 6.72614 33.1826 7.43806 34.417C8.15382 35.6539 7.73128 37.2288 6.49438 37.9458C5.26004 38.6578 3.68254 38.2327 2.96934 36.9958C2.25486 35.7653 2.6774 34.1839 3.9143 33.472Z'
      fill='#292858'
    />
    <path
      d='M14.3486 44.5324C14.8364 43.1995 16.3179 42.5068 17.6559 42.9908C18.9965 43.4837 19.6867 44.9601 19.2001 46.3045C18.7123 47.6387 17.2308 48.3302 15.8928 47.8449C14.5522 47.3558 13.8607 45.8769 14.3486 44.5324Z'
      fill='#292858'
    />
    <path
      d='M29.4539 46.3035C28.9673 44.9616 29.6587 43.4839 30.9968 42.991C32.34 42.5044 33.8202 43.1984 34.3067 44.5313C34.792 45.8758 34.1057 47.3547 32.7651 47.8438C31.4219 48.3291 29.943 47.6415 29.4539 46.3035Z'
      fill='#292858'
    />
    <path
      d='M42.166 37.9471C40.9265 37.23 40.504 35.6551 41.2172 34.4182C41.933 33.1838 43.5079 32.7613 44.7474 33.4732C45.9804 34.1851 46.4017 35.7678 45.6911 36.997C44.9753 38.2352 43.3991 38.6577 42.166 37.9471Z'
      fill='#292858'
    />
    <path
      d='M46.5285 23.3732C45.1213 23.6216 43.7806 22.6843 43.5361 21.281C43.2902 19.8776 44.2275 18.5383 45.6283 18.2912C47.0304 18.0453 48.371 18.9813 48.6156 20.3859C48.8665 21.7867 47.9305 23.1274 46.5285 23.3732Z'
      fill='#292858'
    />
    <path
      d='M40.5002 9.40626C39.586 10.4998 37.9572 10.6432 36.8676 9.72637C35.769 8.80958 35.6281 7.17959 36.5449 6.08994C37.4604 4.99772 39.0891 4.8556 40.1801 5.77111C41.2774 6.6879 41.4195 8.31661 40.5002 9.40626Z'
      fill='#292858'
    />
    <path
      d='M20.6984 35.4288C20.9037 35.4973 21.1253 35.5019 21.3352 35.4422C21.5452 35.3824 21.7342 35.2609 21.8785 35.093C22.0229 34.925 22.1161 34.718 22.1466 34.4981C22.177 34.2781 22.1432 34.0549 22.0496 33.8566C21.9559 33.6583 21.8065 33.4936 21.62 33.3832C21.4336 33.2729 21.2185 33.2218 21.0017 33.2364C20.785 33.251 20.5762 33.3306 20.4016 33.4653C20.227 33.5999 20.0944 33.7836 20.0204 33.9932C19.9227 34.2786 19.9391 34.5901 20.0663 34.8592C20.1934 35.1284 20.4208 35.3333 20.6984 35.4288ZM20.124 35.9544C19.7226 35.7172 19.429 35.3293 19.3045 34.8717C19.18 34.4141 19.2342 33.9221 19.4558 33.4984C19.6926 33.0625 20.087 32.737 20.553 32.5929C21.0189 32.4487 21.5187 32.4976 21.9435 32.7289C22.3246 32.9246 22.6225 33.254 22.7837 33.6578C22.9449 34.0616 22.9588 34.5132 22.8228 34.9316C22.6869 35.3499 22.4101 35.7075 22.0421 35.9399C21.6742 36.1724 21.2393 36.2645 20.8156 36.1998L19.0764 41.1272L18.3842 40.8828L18.6309 40.1839L17.2469 39.6952L17.493 38.9965L18.1858 39.2412L18.1872 39.2373C18.1872 39.2373 18.1888 39.2378 18.1896 39.2381C18.2342 39.2551 18.2819 39.2628 18.3298 39.2606C18.3777 39.2585 18.4249 39.2466 18.4685 39.2257C18.5122 39.2047 18.5514 39.1751 18.584 39.1386C18.6165 39.1022 18.6417 39.0595 18.658 39.0132C18.6744 38.967 18.6815 38.918 18.6791 38.8691C18.6767 38.8203 18.6647 38.7726 18.6439 38.7289C18.6231 38.6852 18.5938 38.6463 18.5579 38.6145C18.5219 38.5828 18.48 38.5588 18.4346 38.5439C18.4346 38.5439 18.433 38.5434 18.4322 38.5431L18.4329 38.541L17.7401 38.2964L17.9868 37.5975L19.3707 38.0862L20.124 35.9544Z'
      fill='#292858'
    />
    <path
      d='M15.047 31.114C15.1585 31.2995 15.3237 31.447 15.5221 31.5382C15.7204 31.6293 15.943 31.6599 16.1619 31.6262C16.3808 31.5925 16.5862 31.4959 16.7524 31.3486C16.9185 31.2013 17.038 31.0098 17.0959 30.7982C17.1537 30.5866 17.1472 30.3643 17.0774 30.1593C17.0075 29.9542 16.8773 29.7755 16.7031 29.6456C16.529 29.5158 16.3186 29.4405 16.0984 29.4292C15.8782 29.418 15.658 29.4713 15.4655 29.5824C15.2057 29.7356 15.0156 29.983 14.9372 30.2701C14.8587 30.5573 14.8982 30.8608 15.047 31.114ZM14.2689 31.1396C14.1182 30.6984 14.1474 30.2129 14.3504 29.7842C14.5534 29.3556 14.9145 29.0171 15.3584 28.8394C15.8217 28.6622 16.333 28.6714 16.7807 28.8649C17.2284 29.0584 17.5763 29.4206 17.7486 29.8725C17.9108 30.269 17.9229 30.713 17.7828 31.1246C17.6427 31.5361 17.3595 31.8883 16.9842 32.1177C16.6089 32.3471 16.1661 32.4387 15.7354 32.376C15.3047 32.3134 14.9144 32.1005 14.6347 31.7758L10.1091 34.3881L9.74223 33.7523L10.3841 33.3817L9.65058 32.1105L10.2919 31.7398L10.659 32.3761L10.6626 32.3741C10.6626 32.3741 10.6635 32.3755 10.6639 32.3763C10.6868 32.4182 10.718 32.455 10.7558 32.4846C10.7936 32.5141 10.8371 32.5357 10.8839 32.5482C10.9307 32.5607 10.9798 32.5637 11.0282 32.5572C11.0766 32.5506 11.1235 32.5346 11.166 32.5101C11.2085 32.4855 11.2458 32.453 11.2757 32.4143C11.3056 32.3756 11.3276 32.3316 11.3402 32.2849C11.3528 32.2381 11.3559 32.1895 11.3492 32.142C11.3426 32.0945 11.3263 32.0491 11.3015 32.0083C11.3015 32.0083 11.3006 32.0068 11.3002 32.0061L11.3021 32.005L10.9349 31.3686L11.5767 30.998L12.3103 32.2693L14.2689 31.1396Z'
      fill='#292858'
    />
    <path
      d='M14.093 22.5548C14.0698 22.77 14.1124 22.9874 14.2154 23.1798C14.3185 23.3723 14.4774 23.5311 14.6722 23.6364C14.867 23.7417 15.0891 23.7888 15.3105 23.7718C15.5319 23.7547 15.7428 23.6743 15.9167 23.5405C16.0905 23.4068 16.2197 23.2258 16.2878 23.0202C16.356 22.8145 16.3601 22.5935 16.2997 22.3848C16.2394 22.176 16.1171 21.989 15.9484 21.847C15.7797 21.7051 15.5721 21.6146 15.3515 21.5869C15.0519 21.5521 14.751 21.6345 14.5151 21.8159C14.2791 21.9974 14.1273 22.2631 14.093 22.5548ZM13.4573 22.1053C13.6036 21.6627 13.9202 21.2933 14.3408 21.0743C14.7615 20.8553 15.2538 20.8036 15.7149 20.93C16.1912 21.0686 16.5931 21.3847 16.8331 21.8094C17.073 22.234 17.1316 22.7328 16.996 23.197C16.8858 23.611 16.6273 23.9722 16.2671 24.2156C15.9068 24.459 15.4685 24.5686 15.0308 24.5248C14.5931 24.4809 14.1848 24.2865 13.8794 23.9765C13.574 23.6664 13.3914 23.261 13.3646 22.8333L8.17994 22.1821L8.27154 21.4537L9.00692 21.5461L9.19007 20.0899L9.92506 20.1817L9.83338 20.9107L9.8375 20.9112C9.8375 20.9112 9.8373 20.9129 9.8372 20.9137C9.83008 20.961 9.83274 21.0092 9.84501 21.0555C9.85729 21.1019 9.87895 21.1454 9.9087 21.1836C9.93846 21.2218 9.97571 21.2539 10.0183 21.2779C10.0608 21.3019 10.1079 21.3175 10.1566 21.3236C10.2053 21.3297 10.2547 21.3263 10.3019 21.3135C10.3491 21.3008 10.3931 21.2789 10.4314 21.2493C10.4697 21.2196 10.5015 21.1828 10.5249 21.1409C10.5482 21.099 10.5628 21.053 10.5676 21.0054C10.5676 21.0054 10.5678 21.0037 10.5679 21.0029L10.5701 21.0032L10.6617 20.2742L11.3971 20.3665L11.214 21.8228L13.4573 22.1053Z'
      fill='#292858'
    />
    <path
      d='M17.4435 16.5423C17.2901 16.6949 17.1863 16.8906 17.1452 17.105C17.1042 17.3194 17.1277 17.5429 17.2128 17.7473C17.2979 17.9518 17.4408 18.1282 17.6236 18.2543C17.8063 18.3804 18.0208 18.4506 18.2401 18.4562C18.4594 18.4617 18.6737 18.4023 18.8561 18.2854C19.0385 18.1685 19.1808 17.9994 19.2653 17.7992C19.3497 17.599 19.3725 17.3767 19.3308 17.1603C19.2891 16.9438 19.1847 16.7427 19.0307 16.5824C18.8198 16.3667 18.5342 16.2413 18.2366 16.2338C17.939 16.2263 17.6538 16.3372 17.4435 16.5423ZM17.2325 15.7929C17.6248 15.541 18.1033 15.4533 18.5681 15.5479C19.0328 15.6425 19.4478 15.9122 19.7266 16.3007C20.0094 16.7082 20.1227 17.2068 20.0419 17.6878C19.9611 18.1688 19.6926 18.5932 19.2951 18.8686C18.9488 19.1209 18.5206 19.2389 18.0875 19.2013C17.6544 19.1636 17.2447 18.9729 16.9322 18.6633C16.6197 18.3538 16.4249 17.9457 16.3827 17.5125C16.3406 17.0793 16.4539 16.6495 16.7023 16.3002L13.0835 12.5307L13.6131 12.0224L14.1264 12.557L15.1854 11.5408L15.6987 12.0748L15.1686 12.5835L15.1715 12.5865C15.1715 12.5865 15.1703 12.5877 15.1696 12.5883C15.1344 12.6205 15.1061 12.6596 15.0864 12.7034C15.0668 12.7471 15.0562 12.7946 15.0553 12.843C15.0544 12.8914 15.0631 12.9398 15.0811 12.9852C15.099 13.0307 15.1258 13.0724 15.1598 13.1078C15.1938 13.1432 15.2343 13.1716 15.279 13.1914C15.3237 13.2112 15.3717 13.222 15.4201 13.223C15.4685 13.2241 15.5164 13.2155 15.561 13.1977C15.6055 13.1798 15.6458 13.1532 15.6794 13.1193C15.6794 13.1193 15.6807 13.1181 15.6813 13.1175L15.6828 13.1191L16.2129 12.6104L16.7262 13.145L15.6672 14.1612L17.2325 15.7929Z'
      fill='#292858'
    />
    <path
      d='M23.9498 13.9477C23.7334 13.9541 23.5239 14.0261 23.3474 14.1545C23.1708 14.2829 23.0352 14.4621 22.9576 14.6695C22.8799 14.8769 22.8637 15.1033 22.9109 15.3203C22.9581 15.5373 23.0666 15.7352 23.2229 15.8891C23.3791 16.043 23.5762 16.1462 23.7892 16.1855C24.0022 16.2249 24.2217 16.1988 24.4202 16.1104C24.6187 16.022 24.7873 15.8753 24.9048 15.6888C25.0223 15.5023 25.0836 15.2842 25.0808 15.0619C25.0743 14.7603 24.9515 14.4736 24.7395 14.2647C24.5274 14.0557 24.2434 13.9417 23.9498 13.9477ZM24.308 13.2564C24.7665 13.3408 25.1758 13.6038 25.4503 13.9906C25.7248 14.3773 25.8434 14.8579 25.7813 15.332C25.7091 15.8227 25.451 16.2641 25.0632 16.5599C24.6754 16.8558 24.1893 16.9821 23.711 16.9113C23.2857 16.8588 22.8926 16.6521 22.6022 16.3286C22.3118 16.0051 22.1431 15.5858 22.1267 15.1463C22.1102 14.7067 22.2469 14.2757 22.5123 13.9307C22.7776 13.5857 23.1542 13.3494 23.5742 13.2643L23.5097 8.03926L24.2437 8.03032L24.2529 8.77141L25.7205 8.75354L25.7301 9.49418L24.9954 9.50314L24.9955 9.50729C24.9955 9.50729 24.9938 9.50732 24.993 9.50733C24.9452 9.50675 24.8978 9.51598 24.8536 9.53449C24.8093 9.55299 24.7691 9.5804 24.7354 9.6151C24.7016 9.64981 24.675 9.6911 24.657 9.73655C24.639 9.78201 24.63 9.83071 24.6306 9.87978C24.6312 9.92886 24.6414 9.97733 24.6605 10.0223C24.6796 10.0673 24.7073 10.108 24.7419 10.1419C24.7765 10.1757 24.8173 10.2022 24.862 10.2196C24.9067 10.237 24.9543 10.2451 25.0021 10.2434C25.0021 10.2434 25.0038 10.2434 25.0046 10.2434L25.0046 10.2455L25.7393 10.2366L25.7485 10.9777L24.2809 10.9955L24.308 13.2564Z'
      fill='#292858'
    />
    <path
      d='M31.3191 16.7411C31.1503 16.6057 30.9442 16.5245 30.7265 16.5077C30.5089 16.4909 30.2895 16.5393 30.0958 16.6468C29.9022 16.7543 29.7429 16.9161 29.6381 17.1118C29.5333 17.3076 29.4875 17.5286 29.5066 17.7471C29.5257 17.9657 29.6087 18.1719 29.7453 18.3401C29.8819 18.5082 30.0659 18.6307 30.2743 18.6922C30.4827 18.7537 30.7062 18.7515 30.9166 18.6857C31.127 18.62 31.3151 18.4937 31.4572 18.3227C31.6479 18.089 31.7404 17.7911 31.7145 17.4946C31.6887 17.198 31.5465 16.927 31.3191 16.7411ZM32.0402 16.4474C32.3344 16.809 32.4753 17.2747 32.4333 17.7471C32.3914 18.2195 32.1699 18.6621 31.8151 18.9826C31.4419 19.3093 30.9591 19.4779 30.4721 19.4515C29.985 19.425 29.5332 19.2059 29.215 18.8417C28.9255 18.5259 28.7603 18.1136 28.7491 17.679C28.738 17.2444 28.8816 16.8159 29.1542 16.4707C29.4267 16.1255 29.8104 15.8861 30.2361 15.7957C30.6618 15.7052 31.1017 15.7697 31.4766 15.9773L34.8168 11.9589L35.3813 12.4282L34.9075 12.9982L36.0361 13.9366L35.5629 14.5065L34.998 14.0368L34.9954 14.04C34.9954 14.04 34.9941 14.0389 34.9934 14.0383C34.9574 14.0069 34.9154 13.9832 34.8697 13.9686C34.824 13.954 34.7756 13.9488 34.7274 13.9533C34.6792 13.9578 34.6322 13.9719 34.589 13.9948C34.5458 14.0178 34.5074 14.049 34.476 14.0868C34.4447 14.1245 34.421 14.168 34.4063 14.2146C34.3916 14.2613 34.3863 14.3102 34.3907 14.3584C34.3951 14.4066 34.409 14.4532 34.4317 14.4955C34.4544 14.5377 34.4854 14.5748 34.5229 14.6044C34.5229 14.6044 34.5242 14.6055 34.5248 14.6061L34.5234 14.6077L35.0884 15.0775L34.6146 15.6474L33.4861 14.7091L32.0402 16.4474Z'
      fill='#292858'
    />
    <path
      d='M34.3214 23.1277C34.2703 22.9174 34.1564 22.7273 33.9941 22.5813C33.8319 22.4352 33.6285 22.3398 33.4095 22.3068C33.1905 22.2739 32.9656 22.305 32.7632 22.3962C32.5607 22.4874 32.3896 22.6346 32.2715 22.8194C32.1533 23.0042 32.0933 23.2183 32.099 23.4349C32.1046 23.6514 32.1758 23.8608 32.3034 24.0366C32.431 24.2124 32.6095 24.3469 32.8163 24.4232C33.0232 24.4994 33.2493 24.5141 33.4661 24.4652C33.7598 24.3963 34.0148 24.2167 34.1752 23.9659C34.3356 23.7152 34.3882 23.4137 34.3214 23.1277ZM35.072 23.3347C35.0846 23.8008 34.9122 24.2557 34.5908 24.6044C34.2694 24.9532 33.8239 25.1689 33.3473 25.2065C32.8522 25.2377 32.3669 25.0768 31.997 24.7589C31.6272 24.4409 31.4028 23.9916 31.3728 23.5089C31.3359 23.0821 31.4565 22.6546 31.7127 22.3034C31.969 21.9522 32.3441 21.7002 32.7707 21.5929C33.1973 21.4856 33.6473 21.5299 34.0398 21.7179C34.4324 21.9059 34.7416 22.2252 34.9121 22.6185L40.01 21.4714L40.171 22.1875L39.448 22.3503L39.7699 23.7822L39.0474 23.9453L38.8862 23.2285L38.8822 23.2294C38.8822 23.2294 38.8818 23.2278 38.8816 23.2269C38.8723 23.1801 38.8534 23.1356 38.8261 23.0962C38.7988 23.0567 38.7637 23.0231 38.7227 22.9973C38.6818 22.9715 38.6359 22.954 38.5877 22.9458C38.5395 22.9376 38.49 22.939 38.4421 22.9497C38.3942 22.9605 38.3489 22.9805 38.3088 23.0085C38.2688 23.0366 38.2348 23.0721 38.2088 23.1129C38.1828 23.1538 38.1654 23.1993 38.1577 23.2466C38.1499 23.2939 38.1518 23.3422 38.1634 23.3885C38.1634 23.3885 38.1638 23.3902 38.164 23.391L38.1619 23.3915L38.3231 24.1083L37.6 24.271L37.278 22.8391L35.072 23.3347Z'
      fill='#292858'
    />
    <path
      d='M33.4451 30.1427C33.5396 29.948 33.573 29.7289 33.541 29.513C33.509 29.2971 33.4131 29.0939 33.2653 28.929C33.1174 28.764 32.9243 28.6447 32.7102 28.586C32.496 28.5273 32.2704 28.5318 32.0615 28.5989C31.8527 28.6661 31.67 28.7929 31.5364 28.9634C31.4028 29.1339 31.3243 29.3406 31.3106 29.5574C31.297 29.7743 31.3488 29.9916 31.4596 30.1822C31.5705 30.3728 31.7354 30.5281 31.9337 30.6286C32.2039 30.7626 32.5149 30.7867 32.7983 30.6956C33.0817 30.6045 33.3143 30.4056 33.4451 30.1427ZM33.8917 30.7805C33.6044 31.1477 33.1817 31.3884 32.7118 31.4525C32.2419 31.5165 31.7611 31.3989 31.3697 31.1242C30.9683 30.8329 30.6967 30.3996 30.6143 29.9189C30.5318 29.4381 30.6452 28.9489 30.9296 28.5578C31.1731 28.2053 31.5384 27.9526 31.9597 27.8452C32.381 27.7378 32.8306 27.7826 33.2278 27.9717C33.6249 28.1608 33.9435 28.4817 34.1263 28.8767C34.3091 29.2717 34.344 29.7149 34.2248 30.1265L38.8848 32.4906L38.5526 33.1452L37.8916 32.8099L37.2274 34.1187L36.5666 33.784L36.8991 33.1288L36.8954 33.127C36.8954 33.127 36.8962 33.1255 36.8966 33.1247C36.9192 33.0826 36.933 33.0363 36.9371 32.9886C36.9412 32.9408 36.9355 32.8925 36.9204 32.8465C36.9053 32.8005 36.8811 32.7577 36.8491 32.7207C36.8172 32.6837 36.7782 32.6532 36.7344 32.631C36.6906 32.6088 36.643 32.5954 36.5942 32.5914C36.5455 32.5875 36.4967 32.5932 36.4506 32.6082C36.4046 32.6231 36.3622 32.6471 36.3261 32.6786C36.2899 32.7101 36.2607 32.7485 36.2401 32.7917C36.2401 32.7917 36.2393 32.7932 36.2389 32.794L36.237 32.793L35.9045 33.4481L35.2435 33.1129L35.9077 31.8041L33.8917 30.7805Z'
      fill='#292858'
    />
    <path
      d='M28.69 35.4056C28.8951 35.3367 29.0747 35.2069 29.2063 35.0328C29.3379 34.8586 29.4157 34.6478 29.4297 34.4268C29.4438 34.2058 29.3936 33.9844 29.2854 33.7905C29.1772 33.5966 29.0159 33.4387 28.8217 33.3368C28.6274 33.2349 28.409 33.1934 28.1937 33.2176C27.9784 33.2418 27.7759 33.3306 27.6117 33.4728C27.4474 33.615 27.3287 33.8043 27.2704 34.0169C27.2121 34.2295 27.2169 34.456 27.2841 34.6679C27.3779 34.9546 27.5786 35.1933 27.8422 35.3317C28.1058 35.47 28.4107 35.4966 28.69 35.4056ZM28.5479 36.1712C28.0846 36.2236 27.6166 36.0907 27.2416 35.8004C26.8666 35.51 26.6136 35.0846 26.5354 34.6129C26.4619 34.1223 26.5807 33.625 26.8659 33.2293C27.151 32.8336 27.5795 32.5716 28.0578 32.5004C28.4799 32.4272 28.9161 32.5107 29.288 32.736C29.6599 32.9612 29.943 33.3134 30.0864 33.7293C30.2298 34.1451 30.2242 34.5973 30.0704 35.0044C29.9167 35.4116 29.625 35.7471 29.2478 35.9505L30.8269 40.9316L30.1271 41.1533L29.9031 40.4469L28.504 40.8902L28.2797 40.1842L28.9801 39.9623L28.9788 39.9584C28.9788 39.9584 28.9804 39.9578 28.9812 39.9576C29.0271 39.9443 29.0698 39.9217 29.1068 39.8911C29.1437 39.8605 29.1742 39.8226 29.1964 39.7796C29.2187 39.7366 29.2322 39.6894 29.2362 39.6407C29.2402 39.5919 29.2346 39.5427 29.2198 39.4959C29.205 39.4492 29.1812 39.4057 29.1498 39.3682C29.1185 39.3307 29.0802 39.2998 29.0372 39.2775C28.9943 39.2551 28.9475 39.2417 28.8997 39.238C28.8519 39.2343 28.804 39.2403 28.7588 39.2559C28.7588 39.2559 28.7572 39.2564 28.7563 39.2567L28.7557 39.2546L28.0553 39.4765L27.8313 38.77L29.2304 38.3267L28.5479 36.1712Z'
      fill='#292858'
    />
  </svg>
);
