/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.tps
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: third-party-systems.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as third$party$systems_pb from './third-party-systems_pb'; // proto import: "third-party-systems.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class TpsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddOrUpdateManagedAsset = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/AddOrUpdateManagedAsset',
    grpcWeb.MethodType.UNARY,
    third$party$systems_pb.AddOrUpdateManagedAssetRequest,
    shared_pb.EmptyResponse,
    (request: third$party$systems_pb.AddOrUpdateManagedAssetRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  addOrUpdateManagedAsset(
    request: third$party$systems_pb.AddOrUpdateManagedAssetRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  addOrUpdateManagedAsset(
    request: third$party$systems_pb.AddOrUpdateManagedAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  addOrUpdateManagedAsset(
    request: third$party$systems_pb.AddOrUpdateManagedAssetRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/AddOrUpdateManagedAsset',
        request,
        metadata || {},
        this.methodDescriptorAddOrUpdateManagedAsset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/AddOrUpdateManagedAsset',
    request,
    metadata || {},
    this.methodDescriptorAddOrUpdateManagedAsset);
  }

  methodDescriptorRemoveManagedAsset = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/RemoveManagedAsset',
    grpcWeb.MethodType.UNARY,
    third$party$systems_pb.RemoveManagedAssetRequest,
    shared_pb.EmptyResponse,
    (request: third$party$systems_pb.RemoveManagedAssetRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  removeManagedAsset(
    request: third$party$systems_pb.RemoveManagedAssetRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  removeManagedAsset(
    request: third$party$systems_pb.RemoveManagedAssetRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  removeManagedAsset(
    request: third$party$systems_pb.RemoveManagedAssetRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/RemoveManagedAsset',
        request,
        metadata || {},
        this.methodDescriptorRemoveManagedAsset,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/RemoveManagedAsset',
    request,
    metadata || {},
    this.methodDescriptorRemoveManagedAsset);
  }

  methodDescriptorListThirdPartySystems = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/ListThirdPartySystems',
    grpcWeb.MethodType.UNARY,
    third$party$systems_pb.ListThirdPartySystemsRequest,
    third$party$systems_pb.ListThirdPartySystemsResponse,
    (request: third$party$systems_pb.ListThirdPartySystemsRequest) => {
      return request.serializeBinary();
    },
    third$party$systems_pb.ListThirdPartySystemsResponse.deserializeBinary
  );

  listThirdPartySystems(
    request: third$party$systems_pb.ListThirdPartySystemsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<third$party$systems_pb.ListThirdPartySystemsResponse>;

  listThirdPartySystems(
    request: third$party$systems_pb.ListThirdPartySystemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: third$party$systems_pb.ListThirdPartySystemsResponse) => void): grpcWeb.ClientReadableStream<third$party$systems_pb.ListThirdPartySystemsResponse>;

  listThirdPartySystems(
    request: third$party$systems_pb.ListThirdPartySystemsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: third$party$systems_pb.ListThirdPartySystemsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/ListThirdPartySystems',
        request,
        metadata || {},
        this.methodDescriptorListThirdPartySystems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/ListThirdPartySystems',
    request,
    metadata || {},
    this.methodDescriptorListThirdPartySystems);
  }

  methodDescriptorCreateThirdPartySystem = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/CreateThirdPartySystem',
    grpcWeb.MethodType.UNARY,
    third$party$systems_pb.CreateThirdPartySystemRequest,
    shared_pb.EmptyResponse,
    (request: third$party$systems_pb.CreateThirdPartySystemRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createThirdPartySystem(
    request: third$party$systems_pb.CreateThirdPartySystemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createThirdPartySystem(
    request: third$party$systems_pb.CreateThirdPartySystemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createThirdPartySystem(
    request: third$party$systems_pb.CreateThirdPartySystemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/CreateThirdPartySystem',
        request,
        metadata || {},
        this.methodDescriptorCreateThirdPartySystem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/CreateThirdPartySystem',
    request,
    metadata || {},
    this.methodDescriptorCreateThirdPartySystem);
  }

  methodDescriptorDeleteThirdPartySystem = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/DeleteThirdPartySystem',
    grpcWeb.MethodType.UNARY,
    third$party$systems_pb.DeleteThirdPartySystemRequest,
    shared_pb.EmptyResponse,
    (request: third$party$systems_pb.DeleteThirdPartySystemRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteThirdPartySystem(
    request: third$party$systems_pb.DeleteThirdPartySystemRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteThirdPartySystem(
    request: third$party$systems_pb.DeleteThirdPartySystemRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteThirdPartySystem(
    request: third$party$systems_pb.DeleteThirdPartySystemRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/DeleteThirdPartySystem',
        request,
        metadata || {},
        this.methodDescriptorDeleteThirdPartySystem,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.tps.TpsService/DeleteThirdPartySystem',
    request,
    metadata || {},
    this.methodDescriptorDeleteThirdPartySystem);
  }

}

