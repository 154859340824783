import { GRAFANA_URL } from '../constants';

export const getGrafanaTraceUrl = (traceId: string) => {
  return `${GRAFANA_URL}/explore?orgId=1&left={"datasource":"ujo07Jj7z","queries":[{"refId":"A","datasource":{"type":"tempo","uid":"ujo07Jj7z"},"queryType":"traceql","limit":20,"query":"${traceId}"}],"range":{"from":"now-24h","to":"now"}}`;
};

export const getGrafanaAdapterUrl = (adapterId: string) => {
  return `${GRAFANA_URL}/d/bfebd154-792c-4af2-9f13-10d3cee78df7/adapter-details?orgId=1&refresh=5m&var-adapter=${adapterId}&var-result=All`;
};

export const getGrafanaActionUrl = (
  adapterId: string,
  thingId: string,
  actionId: string
) => {
  return `${GRAFANA_URL}/d/f185878c-db64-41a9-9dd8-e5bf1fb77c28/adapters-single-action-details?orgId=1&var-adapter=${adapterId}&var-thing=${thingId}&var-action=${actionId}`;
};
