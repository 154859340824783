import { z } from 'zod';
import {
  CreateThirdPartySystemRequest,
  DeleteThirdPartySystemRequest,
  ListThirdPartySystemsRequest,
  ManagedAsset,
  Asset,
  AddOrUpdateManagedAssetRequest,
  RemoveManagedAssetRequest
} from '../../proto/third-party-systems_pb';
import {
  getGrpcMetadata,
  getTpsClient,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import { setPopup } from '../slice/popup';
import { setNewApiKey } from '../slice/thirdPartySystems';
import {
  description,
  id,
  name,
  noValidationString,
  optionTypeSchema,
  stringNonEmpty
} from '../types/zodSchemas';
import { createFullOperationId } from './util';

export const getThirdPartySystems = createAppAsyncThunk(
  'thirdPartySystems/getThirdPartySystems',
  async (arg, thunkAPI) => {
    try {
      const response = (
        await getTpsClient().listThirdPartySystems(
          new ListThirdPartySystemsRequest(),
          await getGrpcMetadata()
        )
      ).toObject();

      return response.entriesList;
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const CreateThirdPartySystemProps = z.object({
  id: id,
  name: name,
  information: description,
  apiKey: stringNonEmpty,
  adminRole: optionTypeSchema,
  eventCallbackUri: noValidationString
});
export type CreateThirdPartySystemProps = z.infer<
  typeof CreateThirdPartySystemProps
>;

export const createThirdPartySystem = createAppAsyncThunk<
  CreateThirdPartySystemRequest.AsObject,
  CreateThirdPartySystemProps
>(
  'thirdPartySystems/createThirdPartySystem',
  async (
    { id, name, information, apiKey, adminRole, eventCallbackUri },
    thunkAPI
  ) => {
    try {
      const request = new CreateThirdPartySystemRequest()
        .setId(id)
        .setName(name)
        .setInformation(information)
        .setApiKey(apiKey)
        .setAdminRole(adminRole.value)
        .setEventCallbackUri(eventCallbackUri);

      await getTpsClient().createThirdPartySystem(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(setPopup({ error: false, message: 'APIet er klart!' }));
      thunkAPI.dispatch(setNewApiKey(apiKey));
      return request.toObject();
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const deleteThirdPartySystem = createAppAsyncThunk<string, string>(
  'thirdPartySystems/deleteThirdPartySystem',
  async (id, thunkAPI) => {
    try {
      const request = new DeleteThirdPartySystemRequest();
      request.setThirdPartySystemId(id);
      await getTpsClient().deleteThirdPartySystem(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'APIet er slettet!' })
      );
      return id;
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const addOrUpdateManagedAsset = createAppAsyncThunk<
  void,
  {
    id: string;
    version: number;
    updatedManagedAsset: ManagedAsset.AsObject;
  }
>(
  'thirdPartySystems/addOrUpdateManagedAsset',
  async ({ id, version, updatedManagedAsset }, thunkAPI) => {
    const request = new AddOrUpdateManagedAssetRequest();
    request.setThirdPartySystemId(id);
    request.setExpectedVersion(version);

    const managedAsset = new ManagedAsset();
    managedAsset.setId(updatedManagedAsset.id);
    updatedManagedAsset.assetsList.forEach(assetObj => {
      const asset = new Asset();
      asset.setOperationId(createFullOperationId(assetObj.operationId!));
      managedAsset.addAssets(asset);
    });

    request.setUpdatedAsset(managedAsset);
    try {
      await getTpsClient().addOrUpdateManagedAsset(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(getThirdPartySystems());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const removeManagedAsset = createAppAsyncThunk<
  void,
  {
    id: string;
    version: number;
    assetId: string;
  }
>(
  'thirdPartySystems/removeManagedAsset',
  async ({ id, version, assetId }, thunkAPI) => {
    const request = new RemoveManagedAssetRequest();
    request.setThirdPartySystemId(id);
    request.setExpectedVersion(version);
    request.setManagedAssetId(assetId);

    try {
      await getTpsClient().removeManagedAsset(request, await getGrpcMetadata());
      thunkAPI.dispatch(getThirdPartySystems());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);
