// source: keychain-factory.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
var keychain$shared_pb = require('./keychain-shared_pb.js');
goog.object.extend(proto, keychain$shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.ContactinfoCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.KeychainfactoryCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.PolicyCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.DetailsCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.repeatedFields_, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.repeatedFields_, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListGroupLinksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupLinksList: jspb.Message.toObjectList(msg.getGroupLinksList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.deserializeBinaryFromReader);
      msg.addGroupLinks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupLinksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publicDescription: (f = msg.getPublicDescription()) && shared_pb.Text.toObject(includeInstance, f),
    activeInterval: (f = msg.getActiveInterval()) && shared_pb.LocalDateTimeInterval.toObject(includeInstance, f),
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 4, ""),
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 6, ""),
    publicUri: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = new shared_pb.Text;
      reader.readMessage(value,shared_pb.Text.deserializeBinaryFromReader);
      msg.setPublicDescription(value);
      break;
    case 3:
      var value = new shared_pb.LocalDateTimeInterval;
      reader.readMessage(value,shared_pb.LocalDateTimeInterval.deserializeBinaryFromReader);
      msg.setActiveInterval(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 5:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublicDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.Text.serializeBinaryToWriter
    );
  }
  f = message.getActiveInterval();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      shared_pb.LocalDateTimeInterval.serializeBinaryToWriter
    );
  }
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPublicUri();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.Text public_description = 2;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.getPublicDescription = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, shared_pb.Text, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.setPublicDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.clearPublicDescription = function() {
  return this.setPublicDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.hasPublicDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional oslonokkelen.proto.shared.LocalDateTimeInterval active_interval = 3;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.getActiveInterval = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTimeInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateTimeInterval, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.setActiveInterval = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.clearActiveInterval = function() {
  return this.setActiveInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.hasActiveInterval = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string keychain_factory_uri = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 5;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 5));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string public_uri = 7;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.getPublicUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink.prototype.setPublicUri = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated GroupLink group_links = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.prototype.getGroupLinksList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.prototype.setGroupLinksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.prototype.addGroupLinks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.GroupLink, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.GroupLinkListResponse.prototype.clearGroupLinksList = function() {
  return this.setGroupLinksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publicDescription: (f = msg.getPublicDescription()) && shared_pb.Text.toObject(includeInstance, f),
    activeInterval: (f = msg.getActiveInterval()) && shared_pb.LocalDateTimeInterval.toObject(includeInstance, f),
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 4, ""),
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    title: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = new shared_pb.Text;
      reader.readMessage(value,shared_pb.Text.deserializeBinaryFromReader);
      msg.setPublicDescription(value);
      break;
    case 3:
      var value = new shared_pb.LocalDateTimeInterval;
      reader.readMessage(value,shared_pb.LocalDateTimeInterval.deserializeBinaryFromReader);
      msg.setActiveInterval(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 5:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublicDescription();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.Text.serializeBinaryToWriter
    );
  }
  f = message.getActiveInterval();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      shared_pb.LocalDateTimeInterval.serializeBinaryToWriter
    );
  }
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.Text public_description = 2;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.getPublicDescription = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, shared_pb.Text, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.setPublicDescription = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.clearPublicDescription = function() {
  return this.setPublicDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.hasPublicDescription = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional oslonokkelen.proto.shared.LocalDateTimeInterval active_interval = 3;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.getActiveInterval = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTimeInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateTimeInterval, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.setActiveInterval = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.clearActiveInterval = function() {
  return this.setActiveInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.hasActiveInterval = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string keychain_factory_uri = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 5;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 5));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string title = 6;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publicUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublicUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string public_uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.prototype.getPublicUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateGroupLinkResponse.prototype.setPublicUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ListKeychainFactoryConfigurationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.FetchKeychainFactoryDetailsRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ownerAdminRole: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerAdminRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOwnerAdminRole();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string public_name = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.prototype.getPublicName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.prototype.setPublicName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string owner_admin_role = 5;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.prototype.getOwnerAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryRequest.prototype.setOwnerAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 2;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.AddOperationLockRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 2;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.RemoveOperationLockRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newOwnerRole: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewOwnerRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewOwnerRole();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_owner_role = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.prototype.getNewOwnerRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.ChangeKeychainFactoryOwnerRequest.prototype.setNewOwnerRole = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.DeleteKeychainFactoryRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.prototype.getNewName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryNameRequest.prototype.setNewName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.oneofGroups_ = [[4]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.ContactinfoCase = {
  CONTACTINFO_NOT_SET: 0,
  PHONE_NUMBER: 4
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.ContactinfoCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.getContactinfoCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.ContactinfoCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    approvedFromUntil: (f = msg.getApprovedFromUntil()) && shared_pb.LocalDateInterval.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && shared_pb.PhoneNumber.toObject(includeInstance, f),
    shareable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 3:
      var value = new shared_pb.LocalDateInterval;
      reader.readMessage(value,shared_pb.LocalDateInterval.deserializeBinaryFromReader);
      msg.setApprovedFromUntil(value);
      break;
    case 4:
      var value = new shared_pb.PhoneNumber;
      reader.readMessage(value,shared_pb.PhoneNumber.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShareable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApprovedFromUntil();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      shared_pb.LocalDateInterval.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      shared_pb.PhoneNumber.serializeBinaryToWriter
    );
  }
  f = message.getShareable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.LocalDateInterval approved_from_until = 3;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.getApprovedFromUntil = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateInterval, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.setApprovedFromUntil = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.clearApprovedFromUntil = function() {
  return this.setApprovedFromUntil(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.hasApprovedFromUntil = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional oslonokkelen.proto.shared.PhoneNumber phone_number = 4;
 * @return {?proto.oslonokkelen.proto.shared.PhoneNumber}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.getPhoneNumber = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.PhoneNumber} */ (
    jspb.Message.getWrapperField(this, shared_pb.PhoneNumber, 4));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.PhoneNumber|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool shareable = 5;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.getShareable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.InviteUserToManualGrantKeychainFactoryRequest.prototype.setShareable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoriesList: jspb.Message.toObjectList(msg.getKeychainFactoriesList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.deserializeBinaryFromReader);
      msg.addKeychainFactories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.repeatedFields_ = [8,9];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.oneofGroups_ = [[20,22,13,23]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.KeychainfactoryCase = {
  KEYCHAINFACTORY_NOT_SET: 0,
  MANUAL_GRANT_KEYCHAIN_FACTORY: 20,
  PUSH_KEYCHAIN_FACTORY: 22,
  OIDC_KEYCHAIN_FACTORY: 13,
  PUBLIC_KEYCHAIN_FACTORY: 23
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.KeychainfactoryCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getKeychainfactoryCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.KeychainfactoryCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ownerRole: jspb.Message.getFieldWithDefault(msg, 7, ""),
    linkedSubjectsList: jspb.Message.toObjectList(msg.getLinkedSubjectsList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.toObject, includeInstance),
    dynamicTagsList: jspb.Message.toObjectList(msg.getDynamicTagsList(),
    shared_pb.DynamicTag.toObject, includeInstance),
    image: (f = msg.getImage()) && shared_pb.Image.toObject(includeInstance, f),
    manualGrantKeychainFactory: (f = msg.getManualGrantKeychainFactory()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.toObject(includeInstance, f),
    pushKeychainFactory: (f = msg.getPushKeychainFactory()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.toObject(includeInstance, f),
    oidcKeychainFactory: (f = msg.getOidcKeychainFactory()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.toObject(includeInstance, f),
    publicKeychainFactory: (f = msg.getPublicKeychainFactory()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerRole(value);
      break;
    case 8:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.deserializeBinaryFromReader);
      msg.addLinkedSubjects(value);
      break;
    case 9:
      var value = new shared_pb.DynamicTag;
      reader.readMessage(value,shared_pb.DynamicTag.deserializeBinaryFromReader);
      msg.addDynamicTags(value);
      break;
    case 10:
      var value = new shared_pb.Image;
      reader.readMessage(value,shared_pb.Image.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 20:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.deserializeBinaryFromReader);
      msg.setManualGrantKeychainFactory(value);
      break;
    case 22:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.deserializeBinaryFromReader);
      msg.setPushKeychainFactory(value);
      break;
    case 13:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.deserializeBinaryFromReader);
      msg.setOidcKeychainFactory(value);
      break;
    case 23:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.deserializeBinaryFromReader);
      msg.setPublicKeychainFactory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOwnerRole();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLinkedSubjectsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.serializeBinaryToWriter
    );
  }
  f = message.getDynamicTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      shared_pb.DynamicTag.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      shared_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getManualGrantKeychainFactory();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.serializeBinaryToWriter
    );
  }
  f = message.getPushKeychainFactory();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.serializeBinaryToWriter
    );
  }
  f = message.getOidcKeychainFactory();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.serializeBinaryToWriter
    );
  }
  f = message.getPublicKeychainFactory();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subjectName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubjectName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subject_name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.prototype.getSubjectName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject.prototype.setSubjectName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 version = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string owner_role = 7;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getOwnerRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setOwnerRole = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated LinkedSubject linked_subjects = 8;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getLinkedSubjectsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject, 8));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setLinkedSubjectsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.addLinkedSubjects = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.LinkedSubject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.clearLinkedSubjectsList = function() {
  return this.setLinkedSubjectsList([]);
};


/**
 * repeated oslonokkelen.proto.shared.DynamicTag dynamic_tags = 9;
 * @return {!Array<!proto.oslonokkelen.proto.shared.DynamicTag>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getDynamicTagsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.DynamicTag>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.DynamicTag, 9));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.DynamicTag>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setDynamicTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.DynamicTag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.DynamicTag}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.addDynamicTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.oslonokkelen.proto.shared.DynamicTag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.clearDynamicTagsList = function() {
  return this.setDynamicTagsList([]);
};


/**
 * optional oslonokkelen.proto.shared.Image image = 10;
 * @return {?proto.oslonokkelen.proto.shared.Image}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getImage = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Image} */ (
    jspb.Message.getWrapperField(this, shared_pb.Image, 10));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Image|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.hasImage = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional ManualGrantKeychainFactory manual_grant_keychain_factory = 20;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getManualGrantKeychainFactory = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory, 20));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setManualGrantKeychainFactory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.clearManualGrantKeychainFactory = function() {
  return this.setManualGrantKeychainFactory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.hasManualGrantKeychainFactory = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional PushKeychainFactory push_keychain_factory = 22;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getPushKeychainFactory = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory, 22));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setPushKeychainFactory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.clearPushKeychainFactory = function() {
  return this.setPushKeychainFactory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.hasPushKeychainFactory = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional OidcFactoryDescription oidc_keychain_factory = 13;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getOidcKeychainFactory = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription, 13));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setOidcKeychainFactory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.clearOidcKeychainFactory = function() {
  return this.setOidcKeychainFactory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.hasOidcKeychainFactory = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PublicKeychainFactoryDescription public_keychain_factory = 23;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.getPublicKeychainFactory = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription, 23));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.setPublicKeychainFactory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 23, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.clearPublicKeychainFactory = function() {
  return this.setPublicKeychainFactory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary.prototype.hasPublicKeychainFactory = function() {
  return jspb.Message.getField(this, 23) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string public_name = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.prototype.getPublicName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.ManualGrantKeychainFactory.prototype.setPublicName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.OidcFactoryDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingRecipientCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    thirdPartySystemId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    thirdPartySystemName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    thirdPartyId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activeInterval: (f = msg.getActiveInterval()) && shared_pb.LocalDateTimeInterval.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingRecipientCount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartySystemId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartySystemName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartyId(value);
      break;
    case 5:
      var value = new shared_pb.LocalDateTimeInterval;
      reader.readMessage(value,shared_pb.LocalDateTimeInterval.deserializeBinaryFromReader);
      msg.setActiveInterval(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingRecipientCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getThirdPartySystemId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getThirdPartySystemName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getThirdPartyId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActiveInterval();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      shared_pb.LocalDateTimeInterval.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 pending_recipient_count = 1;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.getPendingRecipientCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.setPendingRecipientCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string third_party_system_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.getThirdPartySystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.setThirdPartySystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string third_party_system_name = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.getThirdPartySystemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.setThirdPartySystemName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string third_party_id = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.getThirdPartyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.setThirdPartyId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional oslonokkelen.proto.shared.LocalDateTimeInterval active_interval = 5;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.getActiveInterval = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTimeInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateTimeInterval, 5));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.setActiveInterval = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.clearActiveInterval = function() {
  return this.setActiveInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PushKeychainFactory.prototype.hasActiveInterval = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string public_name = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.prototype.getPublicName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.PublicKeychainFactoryDescription.prototype.setPublicName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Summary keychain_factories = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.prototype.getKeychainFactoriesList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.prototype.setKeychainFactoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.prototype.addKeychainFactories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.Summary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoriesConfigurationsResponse.prototype.clearKeychainFactoriesList = function() {
  return this.setKeychainFactoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.repeatedFields_ = [10,16];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.oneofGroups_ = [[5,11,13,14]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.DetailsCase = {
  DETAILS_NOT_SET: 0,
  MANUAL_GRANT_KEYCHAIN_FACTORY: 5,
  PUSH_FACTORY_DETAILS: 11,
  OIDC: 13,
  PUBLIC: 14
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.DetailsCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getDetailsCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.DetailsCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ownerRole: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    approvedKeychains: jspb.Message.getFieldWithDefault(msg, 4, 0),
    expiredKeychains: jspb.Message.getFieldWithDefault(msg, 7, 0),
    version: jspb.Message.getFieldWithDefault(msg, 8, 0),
    grantedOperationsList: jspb.Message.toObjectList(msg.getGrantedOperationsList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.toObject, includeInstance),
    image: (f = msg.getImage()) && shared_pb.Image.toObject(includeInstance, f),
    policiesList: jspb.Message.toObjectList(msg.getPoliciesList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.toObject, includeInstance),
    manualGrantKeychainFactory: (f = msg.getManualGrantKeychainFactory()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.toObject(includeInstance, f),
    pushFactoryDetails: (f = msg.getPushFactoryDetails()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.toObject(includeInstance, f),
    oidc: (f = msg.getOidc()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.toObject(includeInstance, f),
    pb_public: (f = msg.getPublic()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerRole(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApprovedKeychains(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpiredKeychains(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 10:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.deserializeBinaryFromReader);
      msg.addGrantedOperations(value);
      break;
    case 15:
      var value = new shared_pb.Image;
      reader.readMessage(value,shared_pb.Image.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 16:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.deserializeBinaryFromReader);
      msg.addPolicies(value);
      break;
    case 5:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.deserializeBinaryFromReader);
      msg.setManualGrantKeychainFactory(value);
      break;
    case 11:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.deserializeBinaryFromReader);
      msg.setPushFactoryDetails(value);
      break;
    case 13:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.deserializeBinaryFromReader);
      msg.setOidc(value);
      break;
    case 14:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.deserializeBinaryFromReader);
      msg.setPublic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOwnerRole();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getApprovedKeychains();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getExpiredKeychains();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getGrantedOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      shared_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getPoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.serializeBinaryToWriter
    );
  }
  f = message.getManualGrantKeychainFactory();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.serializeBinaryToWriter
    );
  }
  f = message.getPushFactoryDetails();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.serializeBinaryToWriter
    );
  }
  f = message.getOidc();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.serializeBinaryToWriter
    );
  }
  f = message.getPublic();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    publicName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    openingHours: (f = msg.getOpeningHours()) && shared_pb.OpeningHours.toObject(includeInstance, f),
    openingHoursSpec: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicName(value);
      break;
    case 5:
      var value = new shared_pb.OpeningHours;
      reader.readMessage(value,shared_pb.OpeningHours.deserializeBinaryFromReader);
      msg.setOpeningHours(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpeningHoursSpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPublicName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpeningHours();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      shared_pb.OpeningHours.serializeBinaryToWriter
    );
  }
  f = message.getOpeningHoursSpec();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string public_name = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.prototype.getPublicName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.prototype.setPublicName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.OpeningHours opening_hours = 5;
 * @return {?proto.oslonokkelen.proto.shared.OpeningHours}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.prototype.getOpeningHours = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OpeningHours} */ (
    jspb.Message.getWrapperField(this, shared_pb.OpeningHours, 5));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OpeningHours|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.prototype.setOpeningHours = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.prototype.clearOpeningHours = function() {
  return this.setOpeningHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.prototype.hasOpeningHours = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string opening_hours_spec = 6;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.prototype.getOpeningHoursSpec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.prototype.setOpeningHoursSpec = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    redirectUrisList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    roleDefinitionsList: jspb.Message.toObjectList(msg.getRoleDefinitionsList(),
    keychain$shared_pb.OidcRoleDefinition.toObject, includeInstance),
    allowedOriginsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    sessionLifespanMinutes: jspb.Message.getFieldWithDefault(msg, 4, 0),
    biometricsRequired: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRedirectUris(value);
      break;
    case 2:
      var value = new keychain$shared_pb.OidcRoleDefinition;
      reader.readMessage(value,keychain$shared_pb.OidcRoleDefinition.deserializeBinaryFromReader);
      msg.addRoleDefinitions(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addAllowedOrigins(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSessionLifespanMinutes(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBiometricsRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRedirectUrisList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getRoleDefinitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      keychain$shared_pb.OidcRoleDefinition.serializeBinaryToWriter
    );
  }
  f = message.getAllowedOriginsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSessionLifespanMinutes();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBiometricsRequired();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * repeated string redirect_uris = 1;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.getRedirectUrisList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.setRedirectUrisList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.addRedirectUris = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.clearRedirectUrisList = function() {
  return this.setRedirectUrisList([]);
};


/**
 * repeated oslonokkelen.proto.shared.keychain.OidcRoleDefinition role_definitions = 2;
 * @return {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.getRoleDefinitionsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, keychain$shared_pb.OidcRoleDefinition, 2));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.setRoleDefinitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.addRoleDefinitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.oslonokkelen.proto.shared.keychain.OidcRoleDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.clearRoleDefinitionsList = function() {
  return this.setRoleDefinitionsList([]);
};


/**
 * repeated string allowed_origins = 3;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.getAllowedOriginsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.setAllowedOriginsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.addAllowedOrigins = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.clearAllowedOriginsList = function() {
  return this.setAllowedOriginsList([]);
};


/**
 * optional int32 session_lifespan_minutes = 4;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.getSessionLifespanMinutes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.setSessionLifespanMinutes = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool biometrics_required = 5;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.getBiometricsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary.prototype.setBiometricsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    openingHours: (f = msg.getOpeningHours()) && shared_pb.OpeningHours.toObject(includeInstance, f),
    openingHoursSpec: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new shared_pb.OpeningHours;
      reader.readMessage(value,shared_pb.OpeningHours.deserializeBinaryFromReader);
      msg.setOpeningHours(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpeningHoursSpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOpeningHours();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      shared_pb.OpeningHours.serializeBinaryToWriter
    );
  }
  f = message.getOpeningHoursSpec();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.OpeningHours opening_hours = 5;
 * @return {?proto.oslonokkelen.proto.shared.OpeningHours}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.prototype.getOpeningHours = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OpeningHours} */ (
    jspb.Message.getWrapperField(this, shared_pb.OpeningHours, 5));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OpeningHours|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.prototype.setOpeningHours = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.prototype.clearOpeningHours = function() {
  return this.setOpeningHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.prototype.hasOpeningHours = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string opening_hours_spec = 6;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.prototype.getOpeningHoursSpec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails.prototype.setOpeningHoursSpec = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    thirdPartySystemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    thirdPartySystemName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activeInterval: (f = msg.getActiveInterval()) && shared_pb.LocalDateTimeInterval.toObject(includeInstance, f),
    pendingRecipientsList: jspb.Message.toObjectList(msg.getPendingRecipientsList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.toObject, includeInstance),
    confirmedRecipientsList: jspb.Message.toObjectList(msg.getConfirmedRecipientsList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.toObject, includeInstance),
    permissionsList: jspb.Message.toObjectList(msg.getPermissionsList(),
    proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartySystemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThirdPartySystemName(value);
      break;
    case 3:
      var value = new shared_pb.LocalDateTimeInterval;
      reader.readMessage(value,shared_pb.LocalDateTimeInterval.deserializeBinaryFromReader);
      msg.setActiveInterval(value);
      break;
    case 4:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.deserializeBinaryFromReader);
      msg.addPendingRecipients(value);
      break;
    case 5:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.deserializeBinaryFromReader);
      msg.addConfirmedRecipients(value);
      break;
    case 6:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.deserializeBinaryFromReader);
      msg.addPermissions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThirdPartySystemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getThirdPartySystemName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActiveInterval();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      shared_pb.LocalDateTimeInterval.serializeBinaryToWriter
    );
  }
  f = message.getPendingRecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.serializeBinaryToWriter
    );
  }
  f = message.getConfirmedRecipientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.serializeBinaryToWriter
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneNumber: (f = msg.getPhoneNumber()) && shared_pb.PhoneNumber.toObject(includeInstance, f),
    receivedFromThirdPartySystemAtTimestamp: (f = msg.getReceivedFromThirdPartySystemAtTimestamp()) && shared_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.PhoneNumber;
      reader.readMessage(value,shared_pb.PhoneNumber.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 2:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReceivedFromThirdPartySystemAtTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.PhoneNumber.serializeBinaryToWriter
    );
  }
  f = message.getReceivedFromThirdPartySystemAtTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.PhoneNumber phone_number = 1;
 * @return {?proto.oslonokkelen.proto.shared.PhoneNumber}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.prototype.getPhoneNumber = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.PhoneNumber} */ (
    jspb.Message.getWrapperField(this, shared_pb.PhoneNumber, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.PhoneNumber|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional oslonokkelen.proto.shared.Timestamp received_from_third_party_system_at_timestamp = 2;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.prototype.getReceivedFromThirdPartySystemAtTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.prototype.setReceivedFromThirdPartySystemAtTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.prototype.clearReceivedFromThirdPartySystemAtTimestamp = function() {
  return this.setReceivedFromThirdPartySystemAtTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient.prototype.hasReceivedFromThirdPartySystemAtTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneNumber: (f = msg.getPhoneNumber()) && shared_pb.PhoneNumber.toObject(includeInstance, f),
    personName: (f = msg.getPersonName()) && shared_pb.PersonName.toObject(includeInstance, f),
    receivedFromThirdPartySystemAtTimestamp: (f = msg.getReceivedFromThirdPartySystemAtTimestamp()) && shared_pb.Timestamp.toObject(includeInstance, f),
    associatedWithProfileAtTimestamp: (f = msg.getAssociatedWithProfileAtTimestamp()) && shared_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.PhoneNumber;
      reader.readMessage(value,shared_pb.PhoneNumber.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 2:
      var value = new shared_pb.PersonName;
      reader.readMessage(value,shared_pb.PersonName.deserializeBinaryFromReader);
      msg.setPersonName(value);
      break;
    case 3:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReceivedFromThirdPartySystemAtTimestamp(value);
      break;
    case 4:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAssociatedWithProfileAtTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.PhoneNumber.serializeBinaryToWriter
    );
  }
  f = message.getPersonName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.PersonName.serializeBinaryToWriter
    );
  }
  f = message.getReceivedFromThirdPartySystemAtTimestamp();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAssociatedWithProfileAtTimestamp();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.PhoneNumber phone_number = 1;
 * @return {?proto.oslonokkelen.proto.shared.PhoneNumber}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.getPhoneNumber = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.PhoneNumber} */ (
    jspb.Message.getWrapperField(this, shared_pb.PhoneNumber, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.PhoneNumber|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional oslonokkelen.proto.shared.PersonName person_name = 2;
 * @return {?proto.oslonokkelen.proto.shared.PersonName}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.getPersonName = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.PersonName} */ (
    jspb.Message.getWrapperField(this, shared_pb.PersonName, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.PersonName|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.setPersonName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.clearPersonName = function() {
  return this.setPersonName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.hasPersonName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional oslonokkelen.proto.shared.Timestamp received_from_third_party_system_at_timestamp = 3;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.getReceivedFromThirdPartySystemAtTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.setReceivedFromThirdPartySystemAtTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.clearReceivedFromThirdPartySystemAtTimestamp = function() {
  return this.setReceivedFromThirdPartySystemAtTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.hasReceivedFromThirdPartySystemAtTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional oslonokkelen.proto.shared.Timestamp associated_with_profile_at_timestamp = 4;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.getAssociatedWithProfileAtTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 4));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.setAssociatedWithProfileAtTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.clearAssociatedWithProfileAtTimestamp = function() {
  return this.setAssociatedWithProfileAtTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient.prototype.hasAssociatedWithProfileAtTimestamp = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.repeatedFields_ = [1,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    interval: (f = msg.getInterval()) && shared_pb.LocalDateTimeInterval.toObject(includeInstance, f),
    subjectOperationIdsList: jspb.Message.toObjectList(msg.getSubjectOperationIdsList(),
    shared_pb.FullOperationId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAssetIds(value);
      break;
    case 2:
      var value = new shared_pb.LocalDateTimeInterval;
      reader.readMessage(value,shared_pb.LocalDateTimeInterval.deserializeBinaryFromReader);
      msg.setInterval(value);
      break;
    case 3:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.addSubjectOperationIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getInterval();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.LocalDateTimeInterval.serializeBinaryToWriter
    );
  }
  f = message.getSubjectOperationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string asset_ids = 1;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.getAssetIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.setAssetIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.addAssetIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.clearAssetIdsList = function() {
  return this.setAssetIdsList([]);
};


/**
 * optional oslonokkelen.proto.shared.LocalDateTimeInterval interval = 2;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.getInterval = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTimeInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateTimeInterval, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.setInterval = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.clearInterval = function() {
  return this.setInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated oslonokkelen.proto.shared.FullOperationId subject_operation_ids = 3;
 * @return {!Array<!proto.oslonokkelen.proto.shared.FullOperationId>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.getSubjectOperationIdsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.FullOperationId>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.FullOperationId, 3));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.FullOperationId>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.setSubjectOperationIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.FullOperationId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.addSubjectOperationIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.oslonokkelen.proto.shared.FullOperationId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission.prototype.clearSubjectOperationIdsList = function() {
  return this.setSubjectOperationIdsList([]);
};


/**
 * optional string third_party_system_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.getThirdPartySystemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.setThirdPartySystemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string third_party_system_name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.getThirdPartySystemName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.setThirdPartySystemName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional oslonokkelen.proto.shared.LocalDateTimeInterval active_interval = 3;
 * @return {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.getActiveInterval = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.LocalDateTimeInterval} */ (
    jspb.Message.getWrapperField(this, shared_pb.LocalDateTimeInterval, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.LocalDateTimeInterval|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.setActiveInterval = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.clearActiveInterval = function() {
  return this.setActiveInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.hasActiveInterval = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated PendingRecipient pending_recipients = 4;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.getPendingRecipientsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient, 4));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.setPendingRecipientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.addPendingRecipients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.PendingRecipient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.clearPendingRecipientsList = function() {
  return this.setPendingRecipientsList([]);
};


/**
 * repeated ConfirmedRecipient confirmed_recipients = 5;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.getConfirmedRecipientsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient, 5));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.setConfirmedRecipientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.addConfirmedRecipients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.ConfirmedRecipient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.clearConfirmedRecipientsList = function() {
  return this.setConfirmedRecipientsList([]);
};


/**
 * repeated Permission permissions = 6;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.getPermissionsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission, 6));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.setPermissionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.addPermissions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.Permission, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    entityType: (f = msg.getEntityType()) && shared_pb.OperationEntity.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = new shared_pb.OperationEntity;
      reader.readMessage(value,shared_pb.OperationEntity.deserializeBinaryFromReader);
      msg.setEntityType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getEntityType();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.OperationEntity.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional oslonokkelen.proto.shared.OperationEntity entity_type = 2;
 * @return {?proto.oslonokkelen.proto.shared.OperationEntity}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.getEntityType = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OperationEntity} */ (
    jspb.Message.getWrapperField(this, shared_pb.OperationEntity, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OperationEntity|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.setEntityType = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.clearEntityType = function() {
  return this.setEntityType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.hasEntityType = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string owner_role = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getOwnerRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setOwnerRole = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 approved_keychains = 4;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getApprovedKeychains = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setApprovedKeychains = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 expired_keychains = 7;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getExpiredKeychains = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setExpiredKeychains = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 version = 8;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated GrantedOperation granted_operations = 10;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getGrantedOperationsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation, 10));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setGrantedOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.addGrantedOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.GrantedOperation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.clearGrantedOperationsList = function() {
  return this.setGrantedOperationsList([]);
};


/**
 * optional oslonokkelen.proto.shared.Image image = 15;
 * @return {?proto.oslonokkelen.proto.shared.Image}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getImage = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Image} */ (
    jspb.Message.getWrapperField(this, shared_pb.Image, 15));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Image|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.hasImage = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated KeychainFactoryAdminPolicy policies = 16;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getPoliciesList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy, 16));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setPoliciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.addPolicies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.clearPoliciesList = function() {
  return this.setPoliciesList([]);
};


/**
 * optional ManualGrantKeychainFactoryDetails manual_grant_keychain_factory = 5;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getManualGrantKeychainFactory = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails, 5));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setManualGrantKeychainFactory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.clearManualGrantKeychainFactory = function() {
  return this.setManualGrantKeychainFactory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.hasManualGrantKeychainFactory = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PushFactoryDetails push_factory_details = 11;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getPushFactoryDetails = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails, 11));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PushFactoryDetails|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setPushFactoryDetails = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.clearPushFactoryDetails = function() {
  return this.setPushFactoryDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.hasPushFactoryDetails = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional OidcFactorySummary oidc = 13;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getOidc = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary, 13));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.OidcFactorySummary|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setOidc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.clearOidc = function() {
  return this.setOidc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.hasOidc = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional PublicFactoryDetails public = 14;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.getPublic = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails, 14));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.PublicFactoryDetails|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.setPublic = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.clearPublic = function() {
  return this.setPublic(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryDetailsResponse.prototype.hasPublic = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.PolicyCase = {
  POLICY_NOT_SET: 0,
  CLONE: 1,
  DELETE: 2,
  UPDATE_OPENING_HOURS: 3,
  CREATE_GROUP_LINK: 4,
  UPDATE_OIDC_ROLE: 5
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.PolicyCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.getPolicyCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.PolicyCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.toObject = function(includeInstance, msg) {
  var f, obj = {
    clone: (f = msg.getClone()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.toObject(includeInstance, f),
    pb_delete: (f = msg.getDelete()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.toObject(includeInstance, f),
    updateOpeningHours: (f = msg.getUpdateOpeningHours()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.toObject(includeInstance, f),
    createGroupLink: (f = msg.getCreateGroupLink()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.toObject(includeInstance, f),
    updateOidcRole: (f = msg.getUpdateOidcRole()) && proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.deserializeBinaryFromReader);
      msg.setClone(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.deserializeBinaryFromReader);
      msg.setDelete(value);
      break;
    case 3:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.deserializeBinaryFromReader);
      msg.setUpdateOpeningHours(value);
      break;
    case 4:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.deserializeBinaryFromReader);
      msg.setCreateGroupLink(value);
      break;
    case 5:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.deserializeBinaryFromReader);
      msg.setUpdateOidcRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClone();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.serializeBinaryToWriter
    );
  }
  f = message.getDelete();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.serializeBinaryToWriter
    );
  }
  f = message.getUpdateOpeningHours();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.serializeBinaryToWriter
    );
  }
  f = message.getCreateGroupLink();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.serializeBinaryToWriter
    );
  }
  f = message.getUpdateOidcRole();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    eligibleOperationIdsList: jspb.Message.toObjectList(msg.getEligibleOperationIdsList(),
    shared_pb.FullOperationId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.addEligibleOperationIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEligibleOperationIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
};


/**
 * repeated oslonokkelen.proto.shared.FullOperationId eligible_operation_ids = 1;
 * @return {!Array<!proto.oslonokkelen.proto.shared.FullOperationId>}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.prototype.getEligibleOperationIdsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.FullOperationId>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.FullOperationId>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.prototype.setEligibleOperationIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.FullOperationId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.prototype.addEligibleOperationIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.oslonokkelen.proto.shared.FullOperationId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink.prototype.clearEligibleOperationIdsList = function() {
  return this.setEligibleOperationIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional Clone clone = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.getClone = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Clone|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.setClone = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.clearClone = function() {
  return this.setClone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.hasClone = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Delete delete = 2;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.getDelete = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete, 2));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.Delete|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.setDelete = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.clearDelete = function() {
  return this.setDelete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.hasDelete = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UpdateOpeningHours update_opening_hours = 3;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.getUpdateOpeningHours = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours, 3));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOpeningHours|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.setUpdateOpeningHours = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.clearUpdateOpeningHours = function() {
  return this.setUpdateOpeningHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.hasUpdateOpeningHours = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CreateGroupLink create_group_link = 4;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.getCreateGroupLink = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink, 4));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.CreateGroupLink|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.setCreateGroupLink = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.clearCreateGroupLink = function() {
  return this.setCreateGroupLink(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.hasCreateGroupLink = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UpdateOidcRole update_oidc_role = 5;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.getUpdateOidcRole = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole, 5));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.UpdateOidcRole|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.setUpdateOidcRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.clearUpdateOidcRole = function() {
  return this.setUpdateOidcRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryAdminPolicy.prototype.hasUpdateOidcRole = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreateManualGrantKeychainFactoryResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.KeychainFactoryCreatedResponse.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    publicName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adminRole: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPublicName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdminRole();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string public_name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.prototype.getPublicName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.prototype.setPublicName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string admin_role = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.prototype.getAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CreatePublicKeychainFactoryRequest.prototype.setAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    openingHours: (f = msg.getOpeningHours()) && shared_pb.OpeningHours.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 3:
      var value = new shared_pb.OpeningHours;
      reader.readMessage(value,shared_pb.OpeningHours.deserializeBinaryFromReader);
      msg.setOpeningHours(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOpeningHours();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      shared_pb.OpeningHours.serializeBinaryToWriter
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.OpeningHours opening_hours = 3;
 * @return {?proto.oslonokkelen.proto.shared.OpeningHours}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.prototype.getOpeningHours = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OpeningHours} */ (
    jspb.Message.getWrapperField(this, shared_pb.OpeningHours, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OpeningHours|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.prototype.setOpeningHours = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.prototype.clearOpeningHours = function() {
  return this.setOpeningHours(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryOpeningHoursRequest.prototype.hasOpeningHours = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newName: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.prototype.getNewName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryRequest.prototype.setNewName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    newKeychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewKeychainFactoryUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string new_keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.prototype.getNewKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.CloneKeychainFactoryResponse.prototype.setNewKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imageUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImageUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string image_uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.prototype.getImageUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.keychain.factory.UpdateKeychainFactoryImageRequest.prototype.setImageUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.keychain.factory);
