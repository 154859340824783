import React from 'react';
import { Confirm } from '../../../../components/Confirm';
import { RowProps } from '../../../../components/Table/TableRow';
import { TableWithButtons } from '../../../../components/Table/TableWithButtons';
import { deleteOidcRole } from '../../../../redux/actions/keychainFactoryDetails';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { RoleDefinition } from '../../../../redux/slice/keychainFactoryDetails';
import { CopyWrapper } from '../../../../utils/id/CopyWrapper';
import { useKeychainFactoryDetails } from '../../KeychainDetailsWrapper';
import { KeychainFactoryBreadcrumbs } from '../../KeychainFactoryBreadcrumbs';
import { CreateNewRoleDialog } from './CreateNewRoleDialog';
import { EditRoleDialog } from './EditRoleDialog';

export const OidcRoleDefinition: React.FC = () => {
  const dispatch = useAppDispatch();
  const [title, setTitle] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [role, setRole] = React.useState<RoleDefinition>();
  const [deleteAction, setDeleteAction] = React.useState(() => () => {});
  const { keychainFactory } = useKeychainFactoryDetails();
  if (keychainFactory.type !== 'oidc') {
    return <p>Støtter ikke tilgangslistetype {keychainFactory.type}</p>;
  }

  const editRole = (role: RoleDefinition) => {
    return () => {
      setRole(role);
      setIsEditModalOpen(true);
    };
  };

  const deleteRole = (id: string, name: string) => {
    return () => {
      setTitle(`Slett rolle ${name}?`);
      setMessage(
        `Er du sikker på at du vil slette denne rollen: ${name} (ID: ${id})?`
      );
      setDeleteAction(() =>
        dispatchAndHandleResult(
          dispatch,
          deleteOidcRole,
          {
            keychainFactoryUri: keychainFactory.keychainFactoryUri,
            roleId: id
          },
          () => setIsDeleteModalOpen(false)
        )
      );
    };
  };

  const headings = ['ID', 'Navn', 'Beskrivelse'];
  const rolleData: RowProps[] = keychainFactory.roleDefinitionsList.map(
    role => {
      return {
        cells: [
          <CopyWrapper key={role.id} copyValue={role.id} />,
          role.name,
          role.description
        ],
        actions: [
          { type: 'edit', onClick: editRole(role) },
          { type: 'delete', onClick: deleteRole(role.id, role.name) }
        ],
        link: role.id
      };
    }
  );

  return (
    <React.Fragment>
      <KeychainFactoryBreadcrumbs
        keychainFactory={keychainFactory}
        name='Konfigurere roller'
      />
      <TableWithButtons
        ting='rolle'
        buttons={[
          {
            label: 'Opprett ny rolle',
            onClick: () => {
              setIsCreateModalOpen(true);
            },
            type: 'primary'
          }
        ]}
        table={{
          headings: headings.map(value => {
            return { element: value };
          }),
          rows: rolleData
        }}
      />
      <Confirm
        title={title}
        message={message}
        doSomething={deleteAction}
        closeModal={() => {
          setIsDeleteModalOpen(false);
        }}
        isModalOpen={isDeleteModalOpen}
      />
      <CreateNewRoleDialog
        keychainFactoryUri={keychainFactory.keychainFactoryUri}
        isModalOpen={isCreateModalOpen}
        closeModal={() => {
          setIsCreateModalOpen(false);
        }}
      />
      <EditRoleDialog
        role={role}
        closeModal={() => {
          setIsEditModalOpen(false);
        }}
        isModalOpen={isEditModalOpen}
      />
    </React.Fragment>
  );
};
