import React from 'react';

export const LockSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='62.722'
    viewBox='0 0 40 62.722'
  >
    <g
      id='noun_locked_lock_3734872'
      data-name='noun_locked lock_3734872'
      transform='translate(-23.828 1.272)'
    >
      <g
        id='Layer_20'
        data-name='Layer 20'
        transform='translate(23.828 -1.272)'
      >
        <path
          id='Path_2868'
          data-name='Path 2868'
          d='M33.8.93H25.857A11.3,11.3,0,0,0,14.57,12.216V27.4H11.187a1.359,1.359,0,0,0-1.359,1.359V49.939A13.729,13.729,0,0,0,23.541,63.652H36.114A13.729,13.729,0,0,0,49.828,49.939V28.761A1.359,1.359,0,0,0,48.468,27.4H45.086V12.216A11.3,11.3,0,0,0,33.8.93ZM17.289,12.216a8.577,8.577,0,0,1,8.568-8.568H33.8a8.577,8.577,0,0,1,8.568,8.568V27.4H17.289Zm29.82,37.723A11.006,11.006,0,0,1,36.114,60.933H23.541A11.007,11.007,0,0,1,12.546,49.939V36.253h23.63a1.359,1.359,0,0,0,0-2.719H12.546V30.12H47.109Z'
          transform='translate(-9.828 -0.93)'
        />
        <path
          id='Path_2869'
          data-name='Path 2869'
          d='M33,25.393a1.682,1.682,0,0,0-.481,1.177,1.658,1.658,0,1,0,3.317,0,1.675,1.675,0,0,0-.481-1.177,1.736,1.736,0,0,0-2.355,0Z'
          transform='translate(-2.168 7.373)'
        />
        <path
          id='Path_2870'
          data-name='Path 2870'
          d='M27.417,29.368a7.245,7.245,0,1,0,7.245,7.245,7.245,7.245,0,0,0-7.245-7.245Zm0,11.173a3.928,3.928,0,1,1,3.928-3.928,3.928,3.928,0,0,1-3.928,3.928Z'
          transform='translate(-7.417 7.304)'
        />
      </g>
    </g>
  </svg>
);
