import React, { useCallback } from 'react';
import { ActionMenu } from '../../../../components/ActionMenu/ActionMenu';
import { ActionMenuItemProps } from '../../../../components/ActionMenu/ActionMenuItem';
import { RenameDialog } from '../../../../components/Dialog/RenameDialog';
import { reviseTerm } from '../../../../redux/actions/terms';
import { useAppDispatch } from '../../../../redux/hooks';
import { Term } from '../../../../redux/slice/terms';

type Props = {
  term: Term;
};
export const TermMenu: React.FC<Props> = ({ term }) => {
  const [isRenameModalOpen, setIsRenameModalOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  const renameAction = useCallback(
    (name: string) => {
      dispatch(
        reviseTerm({
          termId: term.id,
          text: term.currentText.content,
          title: name,
          currentRevisionNumber: term.currentRevisionNumber
        })
      );
    },
    [term.id]
  );

  const actions: ActionMenuItemProps[] = [
    {
      type: 'edit',
      description: 'Endre navn på vilkår',
      onClick: () => setIsRenameModalOpen(true)
    }
  ];

  return (
    <div className='actionmenu-container'>
      <ActionMenu actions={actions} />
      <RenameDialog
        isModalOpen={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
        initialName={term.title}
        storeAction={renameAction}
        formLabel='Nytt navn på vilkåret'
      />
    </div>
  );
};
