/**
 * @fileoverview gRPC-Web generated client stub for com.github.oslonokkelen.protobuf.admin.category
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.27.2
// source: category.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as category_pb from './category_pb'; // proto import: "category.proto"
import * as shared_pb from './shared_pb'; // proto import: "shared.proto"


export class CategoryServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListCategories = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/ListCategories',
    grpcWeb.MethodType.UNARY,
    category_pb.ListCategoriesRequest,
    category_pb.ListCategoriesResponse,
    (request: category_pb.ListCategoriesRequest) => {
      return request.serializeBinary();
    },
    category_pb.ListCategoriesResponse.deserializeBinary
  );

  listCategories(
    request: category_pb.ListCategoriesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<category_pb.ListCategoriesResponse>;

  listCategories(
    request: category_pb.ListCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: category_pb.ListCategoriesResponse) => void): grpcWeb.ClientReadableStream<category_pb.ListCategoriesResponse>;

  listCategories(
    request: category_pb.ListCategoriesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: category_pb.ListCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/ListCategories',
        request,
        metadata || {},
        this.methodDescriptorListCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/ListCategories',
    request,
    metadata || {},
    this.methodDescriptorListCategories);
  }

  methodDescriptorCreateCategory = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/CreateCategory',
    grpcWeb.MethodType.UNARY,
    category_pb.CreateCategoryRequest,
    shared_pb.EmptyResponse,
    (request: category_pb.CreateCategoryRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  createCategory(
    request: category_pb.CreateCategoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  createCategory(
    request: category_pb.CreateCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  createCategory(
    request: category_pb.CreateCategoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/CreateCategory',
        request,
        metadata || {},
        this.methodDescriptorCreateCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/CreateCategory',
    request,
    metadata || {},
    this.methodDescriptorCreateCategory);
  }

  methodDescriptorUpdateCategoryDescription = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/UpdateCategoryDescription',
    grpcWeb.MethodType.UNARY,
    category_pb.UpdateCategoryDescriptionRequest,
    shared_pb.EmptyResponse,
    (request: category_pb.UpdateCategoryDescriptionRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateCategoryDescription(
    request: category_pb.UpdateCategoryDescriptionRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateCategoryDescription(
    request: category_pb.UpdateCategoryDescriptionRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateCategoryDescription(
    request: category_pb.UpdateCategoryDescriptionRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/UpdateCategoryDescription',
        request,
        metadata || {},
        this.methodDescriptorUpdateCategoryDescription,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/UpdateCategoryDescription',
    request,
    metadata || {},
    this.methodDescriptorUpdateCategoryDescription);
  }

  methodDescriptorUpdateCategoryName = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/UpdateCategoryName',
    grpcWeb.MethodType.UNARY,
    category_pb.UpdateCategoryNameRequest,
    shared_pb.EmptyResponse,
    (request: category_pb.UpdateCategoryNameRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateCategoryName(
    request: category_pb.UpdateCategoryNameRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateCategoryName(
    request: category_pb.UpdateCategoryNameRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateCategoryName(
    request: category_pb.UpdateCategoryNameRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/UpdateCategoryName',
        request,
        metadata || {},
        this.methodDescriptorUpdateCategoryName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/UpdateCategoryName',
    request,
    metadata || {},
    this.methodDescriptorUpdateCategoryName);
  }

  methodDescriptorDeleteCategory = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/DeleteCategory',
    grpcWeb.MethodType.UNARY,
    category_pb.DeleteCategoryRequest,
    shared_pb.EmptyResponse,
    (request: category_pb.DeleteCategoryRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  deleteCategory(
    request: category_pb.DeleteCategoryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  deleteCategory(
    request: category_pb.DeleteCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  deleteCategory(
    request: category_pb.DeleteCategoryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/DeleteCategory',
        request,
        metadata || {},
        this.methodDescriptorDeleteCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/DeleteCategory',
    request,
    metadata || {},
    this.methodDescriptorDeleteCategory);
  }

  methodDescriptorUpdateCategoryImage = new grpcWeb.MethodDescriptor(
    '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/UpdateCategoryImage',
    grpcWeb.MethodType.UNARY,
    category_pb.UpdateCategoryImageRequest,
    shared_pb.EmptyResponse,
    (request: category_pb.UpdateCategoryImageRequest) => {
      return request.serializeBinary();
    },
    shared_pb.EmptyResponse.deserializeBinary
  );

  updateCategoryImage(
    request: category_pb.UpdateCategoryImageRequest,
    metadata?: grpcWeb.Metadata | null): Promise<shared_pb.EmptyResponse>;

  updateCategoryImage(
    request: category_pb.UpdateCategoryImageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void): grpcWeb.ClientReadableStream<shared_pb.EmptyResponse>;

  updateCategoryImage(
    request: category_pb.UpdateCategoryImageRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: shared_pb.EmptyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/UpdateCategoryImage',
        request,
        metadata || {},
        this.methodDescriptorUpdateCategoryImage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/com.github.oslonokkelen.protobuf.admin.category.CategoryService/UpdateCategoryImage',
    request,
    metadata || {},
    this.methodDescriptorUpdateCategoryImage);
  }

}

