import React from 'react';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { CreateNewManualGrantKeychainFactory } from './CreateNewManualGrantKeychainFactory';

export const CreateKeychainFactory = () => {
  return (
    <section>
      <ControlpanelBreadcrumbs currentPageName='Lag ny tilgangsliste' />
      <CreateNewManualGrantKeychainFactory />
    </section>
  );
};
