import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { KeyValueFields } from '../../../../components/KeyValue/KeyValueFields';
import { Table } from '../../../../components/Table/Table';
import { RowProps } from '../../../../components/Table/TableRow';
import {
  getKeychainDetails,
  terminateSession
} from '../../../../redux/actions/keychains';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  Keychain,
  keychainsSelectors,
  SessionDetail
} from '../../../../redux/slice/keychains';
import { getKeystudioLink } from '../../../../utils/citykeyId';
import {
  formatDateFromEpochMillis,
  formatInterval
} from '../../../../utils/timeConverter';
import { useKeychainFactoryDetails } from '../../KeychainDetailsWrapper';
import { KeychainBreadcrumbs } from '../../KeychainFactoryBreadcrumbs';
import './KeychainDetails.scss';
import { KeychainDetailsMenu } from './KeychainDetailsMenu';
import {
  generateGratedByText,
  generateLastUsedText,
  generateStatusText,
  generateUsageCounterText
} from './keychainDataGenerator';

interface MatchParams {
  profileId: string;
}

export const KeychainDetails: React.FC = () => {
  const { keychainFactory } = useKeychainFactoryDetails();
  const { profileId = '' } = useParams<keyof MatchParams>();
  const dispatch = useAppDispatch();

  const keychainId =
    keychainFactory.keychainFactoryUri + '/keychains/' + profileId;
  const keychain: Keychain = useAppSelector(state =>
    keychainsSelectors.selectById(state, keychainId)
  );

  useEffect(() => {
    if (keychain) dispatch(getKeychainDetails(keychainId));
  }, [keychain?.status]);
  return (
    <section className='keychain-details'>
      <KeychainBreadcrumbs
        keychainFactory={keychainFactory}
        keychain={keychain}
      />
      <KeychainDetailsMenu
        keychain={keychain}
        keychainFactoryUri={keychainFactory.keychainFactoryUri}
      />
      {keychain && (
        <KeyValueFields>
          <dt>Person</dt>
          <dd className='capitalized'>{keychain.personName.toLowerCase()}</dd>
          {keychainFactory.type === 'oidc' && (
            <Fragment>
              <dt>E-post</dt>
              <dd>{keychain.emailAddress || 'Ingen e-post'}</dd>
              <dt>Roller</dt>
              <dd>
                {keychain.roles.length
                  ? keychain.roles
                      .map<React.ReactNode>(value => {
                        return (
                          <Link
                            key={value.id}
                            to={getKeystudioLink(value.uri)}
                            className='underline-link'
                          >
                            {value.id}
                          </Link>
                        );
                      })
                      .reduce((prev, curr) => [prev, ', ', curr])
                  : 'Ingen roller'}
              </dd>
            </Fragment>
          )}
          <dt>Gyldighet</dt>
          <dd>
            {formatInterval(
              keychain.fromDate,
              keychain.untilDate,
              keychain.fromTime,
              keychain.untilTime
            )}
          </dd>
          <dt>Status</dt>
          <dd>
            {generateStatusText(
              keychain.status,
              keychain.fromDate,
              keychain.untilDate
            )}
          </dd>
          <dt>Antall ganger brukt</dt>
          <dd>{generateUsageCounterText(keychain.usageCounter)}</dd>
          <dt>Sist brukt</dt>
          <dd>{generateLastUsedText(keychain.lastUsedAtEpochSeconds)}</dd>
          <dt>Tildelt av</dt>
          <dd>{generateGratedByText(keychain.grantedBy, true)}</dd>
          <dt>Delbar</dt>
          <dd>{keychain.shareable ? 'Ja' : 'Nei'}</dd>
        </KeyValueFields>
      )}
      {keychain?.sessions.length > 0 && (
        <div>
          <br />
          <h2>Aktive sesjoner</h2>
          <Table
            headings={[
              'Session type',
              'Session ID',
              'Start tidspunkt',
              'Sist oppfrisket',
              'Antall ganger oppfrisket'
            ]}
            rows={generateRowProps(
              keychain,
              keychainFactory.keychainFactoryUri,
              dispatch
            )}
          />
        </div>
      )}
    </section>
  );
};

const generateRowProps = (
  keychain: Keychain,
  factoryId: string,
  dispatch: ReturnType<typeof useAppDispatch>
): RowProps[] => {
  const formatSessionData = (session: SessionDetail, sessionType: string) => [
    sessionType,
    session.sessionId,
    formatDateFromEpochMillis(session.startedAt),
    formatDateFromEpochMillis(session.refreshedAt),
    session.refreshCount
  ];

  return keychain.sessions.flatMap(parentSession => {
    const parentRow: RowProps = {
      cells: formatSessionData(parentSession.session, 'Parent'),
      actions: [
        {
          type: 'delete',
          description: 'Terminer session',
          onClick: () => {
            dispatch(
              terminateSession({
                factoryUri: factoryId,
                sessionId: parentSession.session.sessionId,
                keychainId: keychain.id
              })
            );
          }
        }
      ]
    };
    const childRows: RowProps[] = parentSession.childSessions.map(
      childSession => ({
        cells: formatSessionData(childSession, '↳  Child')
      })
    );
    return [parentRow, ...childRows];
  });
};
