import { z } from 'zod';
import {
  AdmissionCardType,
  CreateAdmissionCardTypeRequest,
  DescribeAdmissionCardTypeRequest,
  ListAdmissionCardTypesRequest,
  UpdateAdmissionCardTypeDescriptionRequest,
  UpdateRelevantSubjectsRequest,
  UpdateRequiredTermsRequest
} from '../../proto/admissioncards_pb';
import {
  getAdmissionsClient,
  getGrpcMetadata,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import { setPopup } from '../slice/popup';
import { id, name, optionTypeSchema } from '../types/zodSchemas';
import { createText } from './util';

export const listAdmissionCardTypes = createAppAsyncThunk<
  AdmissionCardType.AsObject[]
>('admissionCards/listAdmissionCardTypes', async (_, thunkAPI) => {
  try {
    const request = new ListAdmissionCardTypesRequest();
    const response = await getAdmissionsClient().listAdmissionCardTypes(
      request,
      await getGrpcMetadata()
    );
    return response.toObject().cardsList;
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const describeAdmissionCardType = createAppAsyncThunk<
  AdmissionCardType.AsObject | undefined,
  { id: string }
>('admissionCards/describeAdmissionCardType', async ({ id }, thunkAPI) => {
  try {
    const request = new DescribeAdmissionCardTypeRequest().setId(id);
    const response = await getAdmissionsClient().describeAdmissionCardType(
      request,
      await getGrpcMetadata()
    );
    return response.toObject().description;
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

export const CreateAdmissionCardType = z.object({
  id: id,
  adminRole: optionTypeSchema,
  publicName: name,
  actionChainId: optionTypeSchema
});
export type CreateAdmissionCardType = z.infer<typeof CreateAdmissionCardType>;

export const createAdmissionCardType = createAppAsyncThunk<
  void,
  CreateAdmissionCardType
>(
  'admissionCards/createAdmissionCardType',
  async ({ id, adminRole, publicName, actionChainId }, thunkAPI) => {
    try {
      const request = new CreateAdmissionCardTypeRequest()
        .setId(id)
        .setAdminRole(adminRole.value)
        .setPublicName(publicName)
        .setActionChainId(actionChainId.value);

      await getAdmissionsClient().createAdmissionCardType(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(listAdmissionCardTypes());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const updateRequiredTerms = createAppAsyncThunk<
  void,
  { id: string; version: number; requiredTerms: string[] }
>(
  'admissionCards/updateRequiredTerms',
  async ({ id, version, requiredTerms }, thunkAPI) => {
    try {
      const request = new UpdateRequiredTermsRequest()
        .setId(id)
        .setExpectedVersion(version)
        .setTermIdList(requiredTerms);
      await getAdmissionsClient().updateRequiredTerms(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Påkrevde vilkår ble oppdatert' })
      );
      //TODO use describe and reload only one
      thunkAPI.dispatch(listAdmissionCardTypes());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const updateRelevantSubjects = createAppAsyncThunk<
  void,
  { id: string; version: number; subjects: string[] }
>(
  'admissionCards/updateRelevantSubjects',
  async ({ id, version, subjects }, thunkAPI) => {
    try {
      const request = new UpdateRelevantSubjectsRequest()
        .setId(id)
        .setExpectedVersion(version)
        .setSubjectIdList(subjects);
      await getAdmissionsClient().updateRelevantSubjects(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Tilknyttede steder ble oppdatert' })
      );
      //TODO use describe and reload only one
      await thunkAPI.dispatch(listAdmissionCardTypes());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const updateAdmissionCardDescription = createAppAsyncThunk<
  void,
  { id: string; version: number; description: string }
>(
  'admissionCards/updateAdmissionCardDescription',
  async ({ id, version, description }, thunkAPI) => {
    try {
      const request = new UpdateAdmissionCardTypeDescriptionRequest()
        .setId(id)
        .setExpectedVersion(version)
        .setDescription(createText(description));
      await getAdmissionsClient().updateAdmissionCardTypeDescription(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Beskrivelsen ble oppdatert' })
      );
      //TODO use describe and reload only one
      thunkAPI.dispatch(listAdmissionCardTypes());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);
