import React from 'react';
import { KeyInput } from './KeyInput';
import { KeyComponentProps } from './KeyWrapper';

export interface KeySearchType extends KeyComponentProps {
  elementProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}
export const KeySearch: React.FC<KeySearchType> = props => {
  return <KeyInput {...props} type='search' />;
};
