import React from 'react';
import { Button } from '../../components/Button/Button';
import { TableWithButtons } from '../../components/Table/TableWithButtons';
import { useAppDispatch } from '../../redux/hooks';
import {
  AuditLogSearchResult,
  clearAuditSearchResults
} from '../../redux/slice/operations';
import { formatDateFromEpochMillis } from '../../utils/timeConverter';
import './audit.scss';

type Props = {
  auditResult: AuditLogSearchResult[];
};

export const AuditSearchResult: React.FC<Props> = ({ auditResult }) => {
  const dispatch = useAppDispatch();
  const headings = ['Tidspunkt', 'Fødselsnummer', 'Navn', 'Fikk feilmelding?'];

  const rows = auditResult.map(row => {
    return {
      cells: [
        formatDateFromEpochMillis(row.timestamp),
        row.fnr,
        row.name,
        row.error ? 'Ja' : 'Nei'
      ]
    };
  });

  return (
    <section className='search-result'>
      <TableWithButtons
        buttons={[]}
        table={{ headings: headings, rows: rows }}
        ting='døråpning'
      />
      <br />
      <br />
      <Button
        label='Fjern / Nytt søk'
        onClick={() => dispatch(clearAuditSearchResults())}
      />
    </section>
  );
};
