import React from 'react';

export const KartSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='94'
    height='94'
    viewBox='0 0 94 94'
  >
    <g
      id='Group_12135'
      data-name='Group 12135'
      transform='translate(-609 -459)'
    >
      <circle
        id='Ellipse_695'
        data-name='Ellipse 695'
        cx='47'
        cy='47'
        r='47'
        transform='translate(609 459)'
        fill='#c7f6c9'
      />
      <g
        id='Group_12134'
        data-name='Group 12134'
        transform='translate(624.691 474.336)'
      >
        <path
          id='Path_11281'
          data-name='Path 11281'
          d='M13.582,9.445A3.945,3.945,0,1,0,17.527,5.5,3.947,3.947,0,0,0,13.582,9.445Z'
          transform='translate(12.912 4.704)'
          fill='#2a2859'
        />
        <path
          id='Path_11282'
          data-name='Path 11282'
          d='M16.744,14.361a13.7,13.7,0,1,1,25.54,6.878L61.974,35.3,51.108,48.34l-12.622,1.8L28.779,61.789.308,41.455,11.174,28.417l11.238-1.608-3.528-5.092A13.639,13.639,0,0,1,16.744,14.361ZM35.2,31.776l4.9-7.25L56.219,36.043,50.6,42.781ZM30.583,38.6l2.4-3.557,13.88,9.915-9.7,1.387L16.561,31.63l8.364-1.194Zm9.609-24.242a9.751,9.751,0,1,0-19.5,0,9.648,9.648,0,0,0,1.48,5.171l8.373,12.083L38.668,19.6a9.686,9.686,0,0,0,1.507-4.672ZM34.223,49.095,12.191,33.358,6.06,40.714,28.09,56.449Z'
          transform='translate(0 0)'
          fill='#2a2859'
          fillRule='evenodd'
        />
      </g>
    </g>
  </svg>
);
