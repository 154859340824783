import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { KeyColor } from '../../components/Forms/KeyColor';
import { KeyDateTime } from '../../components/Forms/KeyDateTime';
import { KeyId } from '../../components/Forms/KeyId';
import { KeySearch } from '../../components/Forms/KeySearch';
import { KeyCombobox, KeySelect } from '../../components/Forms/KeySelect';
import { KeyText } from '../../components/Forms/KeyText';
import { KeyTextarea } from '../../components/Forms/KeyTextarea';
import { Breadcrumbs } from '../../components/Navigation/breadcrumbs';
import { showcaseBreadcrumb } from './Showcase';

type FormValues = {
  text: string;
  textarea: string;
  id: string;
  id2: string;
  select: { value: string; label: string };
  combobox: { value: string; label: string };
  search: string;
  color: string;
  time: Date;
  date: Date;
  datetime: Date;
};

export const FormComponents: React.FC = () => {
  const commonProps = {
    label: 'Label',
    description: 'Beskrivelse',
    elementProps: { placeholder: 'Placeholder' }
  };
  const dateProps = {
    onChange: () => {},
    selected: new Date(),
    dateFormat: 'dd.MM.yyyy'
  };
  const selectItems = [
    { label: 'Oslonøkkelen', value: 'oslonokkelen' },
    { label: 'Booking', value: 'booking' },
    { label: 'Åpen by', value: 'apenby' }
  ];

  const methods = useForm<FormValues>({
    // resolver: zodResolver(formSchema),
    defaultValues: {
      text: 'Tekst',
      textarea: 'Stort tekstfelt',
      id: 'safe-ID',
      id2: 'inline',
      select: selectItems[0],
      combobox: selectItems[1],
      search: 'Søk',
      color: '#987654',
      time: new Date(),
      date: new Date(),
      datetime: new Date()
    }
  });

  const onSubmit = (data: FormValues) => {
    console.log(data);
  };

  return (
    <div>
      <Breadcrumbs
        breadcrumbs={showcaseBreadcrumb.concat({ name: 'Form components' })}
      />
      <h1>Med label og beskrivelse</h1>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit, e => {
            console.error(e);
          })}
        >
          <KeyText name='text' {...commonProps} />
          <KeyTextarea name='textarea' {...commonProps} />
          <KeyId name='id' {...commonProps} />
          <KeySelect
            name='select'
            {...commonProps}
            elementProps={{ options: selectItems }}
          />
          <KeyCombobox
            name='combobox'
            {...commonProps}
            elementProps={{ options: selectItems }}
          />
          <KeySearch name='search' {...commonProps} />
          <KeyColor name='color' {...commonProps} />
          <KeyDateTime
            type='time'
            name='time'
            {...commonProps}
            elementProps={{ ...dateProps }}
          />
          <KeyDateTime
            type='date'
            name='date'
            {...commonProps}
            elementProps={{ ...dateProps }}
          />
          <KeyDateTime
            type='datetime'
            name='datetime'
            {...commonProps}
            elementProps={{ ...dateProps, dateFormat: 'dd.MM.yyyy  HH:mm' }}
          />
          <button type='submit'>Submit</button>
        </form>
      </FormProvider>
      <h1>Uten label og beskrivelse</h1>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <KeyText name='text' elementProps={{}} />
          <KeyTextarea name='textarea' elementProps={{}} />
          <KeyId name='id2' elementProps={{}} />
          <KeySearch name='search' elementProps={{}} />
          <KeyColor name='color' elementProps={{}} />
          <KeySelect name='select' elementProps={{ options: selectItems }} />
          <KeyCombobox
            name='combobox'
            elementProps={{ options: selectItems }}
          />
          <KeyDateTime
            type='time'
            name='time'
            elementProps={{ ...dateProps }}
          />
          <KeyDateTime
            type='date'
            name='date'
            elementProps={{ ...dateProps }}
          />
          <KeyDateTime
            type='datetime'
            name='datetime'
            elementProps={{ ...dateProps, dateFormat: 'dd.MM.yyyy  HH:mm' }}
          />
          <h1>Inline time</h1>
          Før
          <KeyDateTime
            type='time'
            name='time'
            inline={true}
            {...commonProps}
            elementProps={{ ...dateProps }}
          />
          Mellom
          <KeyDateTime
            type='time'
            name='time'
            inline={true}
            elementProps={{ ...dateProps }}
          />
          Etter
        </form>
      </FormProvider>
    </div>
  );
};
