import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { PreContainer } from '../../../components/code/PreContainer';
import { useAppSelector } from '../../../redux/hooks';
import { bucketSelectors } from '../../../redux/slice/buckets';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { OperationBucketActionMenu } from './Components/OperationBucketActionMenu';

interface MatchParams {
  bucketId: string;
}
export const OperationBucket: React.FC = () => {
  const { bucketId = '' } = useParams<keyof MatchParams>();
  const bucket = useAppSelector(state =>
    bucketSelectors.selectById(state, bucketId)
  );
  if (!bucket) {
    return (
      <Fragment>
        <ControlpanelBreadcrumbs currentPageName='Ugyldig spann' />
        <div>Spann ikke funnet</div>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <ControlpanelBreadcrumbs currentPageName={bucket.name} />
      <OperationBucketActionMenu bucket={bucket} />
      <h2>Bucket JSON-data</h2>
      <PreContainer obj={bucket} />
    </Fragment>
  );
};
