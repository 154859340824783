import { ContentState, convertToRaw, EditorState } from 'draft-js';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import draftToHtml from 'draftjs-to-html';
import React, { Fragment, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Button } from '../Button/Button';
import './TextEditor.scss';

interface Props {
  type: 'simple' | 'advanced' | 'none';
  message: string | undefined;
  saveCallback: (updatedMessage: string) => void;
  clearCallback?: () => void;
  handleCancelClick: (hasChanged: boolean) => void;
  description?: string;
}
export const TextEditor: React.FC<Props> = ({
  type,
  message,
  saveCallback,
  clearCallback,
  handleCancelClick,
  description
}) => {
  const getEditorState = () => {
    let editorState;
    if (message) {
      const processedHTML = DraftPasteProcessor.processHTML(message);
      const contentState = ContentState.createFromBlockArray(processedHTML);
      editorState = EditorState.createWithContent(contentState);
    } else {
      editorState = EditorState.createEmpty();
    }
    return editorState;
  };

  const [editorState, setEditorState] = useState(getEditorState());

  const getEditorText = () =>
    draftToHtml(convertToRaw(editorState.getCurrentContent()));

  const handleSaveClick = async () => {
    const plainText = editorState.getCurrentContent().getPlainText();
    if (plainText.trim().length === 0 && clearCallback) {
      clearCallback();
    } else {
      saveCallback(getEditorText());
    }
  };

  const onCancelClick = () => {
    if (getEditorText() !== message) {
      handleCancelClick(true);
    } else {
      handleCancelClick(false);
    }
  };

  return (
    <Fragment>
      <div>
        <Editor
          toolbarClassName='editor-toolbar'
          editorClassName='wysiwyg-editor'
          wrapperClassName='wysiwyg-wrapper'
          toolbarHidden={type === 'none'}
          editorState={editorState}
          toolbar={type === 'simple' ? simpleToolbar : advancedToolbar}
          onEditorStateChange={editorState => setEditorState(editorState)}
        />
        {description && (
          <div className='form-element'>
            <span className='form-element--description'>{description}</span>
          </div>
        )}
      </div>
      <div className='button-row'>
        <Button label='Avbryt' type='secondary' onClick={onCancelClick} />
        {clearCallback && (
          <Button label='Fjern' type='destructive' onClick={clearCallback} />
        )}
        <Button label='Lagre' type='primary' onClick={handleSaveClick} />
      </div>
    </Fragment>
  );
};

export const simpleToolbar = {
  options: ['inline', 'link'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['bold']
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: 'link-popup',
    dropdownClassName: undefined,
    defaultTargetOption: '_self',
    options: ['link']
  }
};

export const advancedToolbar = {
  ...simpleToolbar,
  options: ['blockType', 'inline', 'list', 'link'],
  blockType: {
    inDropdown: true,
    options: ['Normal', 'H1', 'H2', 'H3', 'H4'],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered']
  }
};
