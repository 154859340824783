import React from 'react';
import { TermsSvg } from '../../../assets/TermsSvg';
import { antall } from '../../../utils/language';
import { OperationInfoCard } from '../operationCards/OperationInfoCard';

interface Props {
  termIds: string[];
}

export const OperationTerms: React.FC<Props> = ({ termIds }) => {
  return (
    <OperationInfoCard
      icon={<TermsSvg />}
      header='Vilkår'
      link='vilkaar'
      text={antall(termIds.length, 'vilkår')}
    />
  );
};
