import React from 'react';
import { DescriptionSvg } from '../../../assets/DescriptionSvg';
import { StickeredContent } from '../../Sticker/StickeredContent';
import { OperationInfoCard } from '../operationCards/OperationInfoCard';

interface Props {
  about?: string;
}

export const About: React.FC<Props> = ({ about }) => (
  <StickeredContent
    stickerText={about ? '' : 'Legg inn beskrivelse for operasjonen'}
  >
    <OperationInfoCard
      icon={<DescriptionSvg />}
      header='Offentlig beskrivelse'
      link='about'
      text={about ? 'Trykk for å se/endre beskrivelsen' : 'Ingen beskrivelse'}
    />
  </StickeredContent>
);
