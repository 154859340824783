import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { StatusDot } from '../../../components/Status/StatusDot';
import { Table } from '../../../components/Table/Table';
import { HealthColor } from '../../../proto/shared_pb';
import { Thing } from '../../../redux/slice/adapters';
import { getGrafanaActionUrl } from '../../../utils/grafana';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';

interface Props {
  adapterId: string;
  things: Thing[];
}
export const ActionTable: React.FC<Props> = ({ adapterId, things }) => {
  const tableHeadings = [
    'Status',
    'Thing ID',
    'Manufacturer',
    'Type',
    'Firmware',
    'Sist sett',
    'Beskrivelse',
    'Action ID',
    'Beskrivelse',
    'Grafana',
    'Link'
  ];

  const data = things.flatMap(thing =>
    thing.actionsList.map(action => {
      const status = getStatus(
        action.manifestHealth?.color,
        action.responseHealth?.color
      );

      return {
        link: `${thing.id}/${action.id}`,
        cells: [
          status,
          <CopyWrapper key={thing.id} copyValue={thing.id} />,
          thing.manufacturer,
          thing.deviceType,
          thing.firmwareVersion,
          thing.lastSeen != undefined ? (
            <span title={thing.lastSeen.explanation}>
              {formatDistanceToNow(
                thing.lastSeen.timestampEpochSeconds * 1000,
                { addSuffix: true }
              )}
            </span>
          ) : null,
          thing.description,
          <CopyWrapper key={action.id} copyValue={action.id} />,
          action.description,

          <a
            key={action.actionUri}
            onClick={e => {
              e.preventDefault();
              window.location.href = getGrafanaActionUrl(
                adapterId,
                thing.id,
                action.id
              );
            }}
            href={getGrafanaActionUrl(adapterId, thing.id, action.id)}
          >
            🔗
          </a>,
          thing.link !== '' ? (
            <a
              key={thing.link}
              onClick={e => {
                e.preventDefault();
                window.location.href = thing.link;
              }}
              href={thing.link}
            >
              🔗
            </a>
          ) : null
        ]
      };
    })
  );
  return <Table headings={tableHeadings} rows={data} />;
};

const getStatus = (color1?: number, color2?: number) => {
  if (color1 === HealthColor.RED || color2 === HealthColor.RED) {
    return <StatusDot color='red' />;
  }
  if (color1 === HealthColor.YELLOW || color2 === HealthColor.YELLOW) {
    return <StatusDot color='yellow' />;
  }
  return <StatusDot color='green' />;
};
