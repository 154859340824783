import React from 'react';

export const OperationDoor: React.FC = () => (
  <svg
    width='40'
    height='48'
    viewBox='0 0 20 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Group 12644'>
      <g id='Group 1312'>
        <path
          id='Vector'
          d='M17.2793 13.8135L15.147 13.82L15.1531 15.8204L17.2853 15.8139L17.2793 13.8135Z'
          fill='#2A2859'
        />
        <g id='Group 1311'>
          <path
            id='Vector_2'
            d='M10.983 0V2.45508H4.01657L4.06252 18.544L6.06252 18.5577L6.00291 4.45507H10.983V23.1699H6.0195L6.0625 18.5791L4.06252 18.544L4.04687 19.1553H4.05664L4 25.1699H10.983V27.6504L22.9684 24.9863V2.66407L10.983 0ZM20.9684 23.3828L12.9864 25.1555V2.49488L20.9684 4.26757V23.3828Z'
            fill='#2A2859'
          />
        </g>
      </g>
    </g>
  </svg>
);
