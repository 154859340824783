import React, { Fragment } from 'react';
import { useKeychainFactoryDetails } from '../../KeychainDetailsWrapper';
import { KeychainFactoryBreadcrumbs } from '../../KeychainFactoryBreadcrumbs';
import './LocksPage.scss';
import { AddLocks } from './addLocks/AddLocks';
import { LocksInKeychainFactory } from './locksInKeychainFactory/LocksInKeychainFactory';

export const LocksPage: React.FC = () => {
  const { keychainFactory } = useKeychainFactoryDetails();
  return (
    <Fragment>
      <KeychainFactoryBreadcrumbs
        keychainFactory={keychainFactory}
        name='Rediger låser'
      />
      <section className='locks-page'>
        <LocksInKeychainFactory
          keychainFactoryUri={keychainFactory.keychainFactoryUri}
          operations={keychainFactory.grantedOperationsList}
        />
        <AddLocks />
      </section>
    </Fragment>
  );
};
