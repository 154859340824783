// source: admissioncards.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.PresentationCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.Type', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.PresentationCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.repeatedFields_, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hexArgbColor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHexArgbColor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHexArgbColor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hex_argb_color = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.prototype.getHexArgbColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.prototype.setHexArgbColor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 expected_version = 3;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateBackgroundColorRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iconUri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconUri(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIconUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string icon_uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.prototype.getIconUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.prototype.setIconUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 expected_version = 3;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateIconRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.oneofGroups_ = [[3,4,5]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.PresentationCase = {
  PRESENTATION_NOT_SET: 0,
  QR: 3,
  RAW: 4,
  BARCODE: 5
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.PresentationCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.getPresentationCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.PresentationCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    qr: (f = msg.getQr()) && proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.toObject(includeInstance, f),
    raw: (f = msg.getRaw()) && proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.toObject(includeInstance, f),
    barcode: (f = msg.getBarcode()) && proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    case 3:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.deserializeBinaryFromReader);
      msg.setQr(value);
      break;
    case 4:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.deserializeBinaryFromReader);
      msg.setRaw(value);
      break;
    case 5:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.deserializeBinaryFromReader);
      msg.setBarcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getQr();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.serializeBinaryToWriter
    );
  }
  f = message.getRaw();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.serializeBinaryToWriter
    );
  }
  f = message.getBarcode();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 expected_version = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Qr qr = 3;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.getQr = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr, 3));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.setQr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.clearQr = function() {
  return this.setQr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.hasQr = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Raw raw = 4;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.getRaw = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw, 4));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.setRaw = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.clearRaw = function() {
  return this.setRaw(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.hasRaw = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Barcode barcode = 5;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.getBarcode = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode, 5));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.setBarcode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.clearBarcode = function() {
  return this.setBarcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdatePresentationRequest.prototype.hasBarcode = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    termIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addTermId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTermIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 expected_version = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string term_id = 3;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.prototype.getTermIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.prototype.setTermIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.prototype.addTermId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRequiredTermsRequest.prototype.clearTermIdList = function() {
  return this.setTermIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subjectIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addSubjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSubjectIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 expected_version = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string subject_id = 3;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.prototype.getSubjectIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.prototype.setSubjectIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.prototype.addSubjectId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateRelevantSubjectsRequest.prototype.clearSubjectIdList = function() {
  return this.setSubjectIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    published: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPublished();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 expected_version = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool published = 3;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.prototype.getPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ToggleAdmissionCardPublishedStatusRequest.prototype.setPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    preferredEncoding: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (reader.readEnum());
      msg.setPreferredEncoding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPreferredEncoding();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.Text.Type preferred_encoding = 2;
 * @return {!proto.oslonokkelen.proto.shared.Text.Type}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.prototype.getPreferredEncoding = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Text.Type} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardTypeRequest.prototype.setPreferredEncoding = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: (f = msg.getDescription()) && proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.serializeBinaryToWriter
    );
  }
};


/**
 * optional AdmissionCardType description = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.prototype.getDescription = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.DescribeAdmissionCardResponse.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 2, 0),
    description: (f = msg.getDescription()) && shared_pb.Text.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    case 3:
      var value = new shared_pb.Text;
      reader.readMessage(value,shared_pb.Text.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      shared_pb.Text.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 expected_version = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional oslonokkelen.proto.shared.Text description = 3;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.prototype.getDescription = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, shared_pb.Text, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.UpdateAdmissionCardTypeDescriptionRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adminRole: jspb.Message.getFieldWithDefault(msg, 2, ""),
    publicName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actionChainId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminRole(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionChainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdminRole();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublicName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActionChainId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string admin_role = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.prototype.getAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.prototype.setAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string public_name = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.prototype.getPublicName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.prototype.setPublicName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action_chain_id = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.prototype.getActionChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.CreateAdmissionCardTypeRequest.prototype.setActionChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    preferredEncoding: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (reader.readEnum());
      msg.setPreferredEncoding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreferredEncoding();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.Text.Type preferred_encoding = 1;
 * @return {!proto.oslonokkelen.proto.shared.Text.Type}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.prototype.getPreferredEncoding = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Text.Type} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesRequest.prototype.setPreferredEncoding = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdmissionCardType cards = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType>}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.ListAdmissionCardTypesResponse.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.repeatedFields_ = [6,7];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.oneofGroups_ = [[10,11,12]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.PresentationCase = {
  PRESENTATION_NOT_SET: 0,
  QR: 10,
  RAW: 11,
  BARCODE: 12
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.PresentationCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getPresentationCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.PresentationCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adminRole: jspb.Message.getFieldWithDefault(msg, 2, ""),
    publicName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    description: (f = msg.getDescription()) && shared_pb.Text.toObject(includeInstance, f),
    relevantSubjectIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    requiredTermIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    actionChainId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    published: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    qr: (f = msg.getQr()) && proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.toObject(includeInstance, f),
    raw: (f = msg.getRaw()) && proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.toObject(includeInstance, f),
    barcode: (f = msg.getBarcode()) && proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.toObject(includeInstance, f),
    iconUri: jspb.Message.getFieldWithDefault(msg, 15, ""),
    hexBackgroundColor: jspb.Message.getFieldWithDefault(msg, 16, ""),
    citykeyId: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminRole(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 5:
      var value = new shared_pb.Text;
      reader.readMessage(value,shared_pb.Text.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addRelevantSubjectIds(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addRequiredTermIds(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionChainId(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPublished(value);
      break;
    case 10:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.deserializeBinaryFromReader);
      msg.setQr(value);
      break;
    case 11:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.deserializeBinaryFromReader);
      msg.setRaw(value);
      break;
    case 12:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.deserializeBinaryFromReader);
      msg.setBarcode(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setIconUri(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setHexBackgroundColor(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCitykeyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdminRole();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPublicName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      shared_pb.Text.serializeBinaryToWriter
    );
  }
  f = message.getRelevantSubjectIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getRequiredTermIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getActionChainId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPublished();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getQr();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.serializeBinaryToWriter
    );
  }
  f = message.getRaw();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.serializeBinaryToWriter
    );
  }
  f = message.getBarcode();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.serializeBinaryToWriter
    );
  }
  f = message.getIconUri();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getHexBackgroundColor();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCitykeyId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string admin_role = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getAdminRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setAdminRole = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string public_name = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getPublicName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setPublicName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 version = 4;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional oslonokkelen.proto.shared.Text description = 5;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getDescription = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, shared_pb.Text, 5));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated string relevant_subject_ids = 6;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getRelevantSubjectIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setRelevantSubjectIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.addRelevantSubjectIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.clearRelevantSubjectIdsList = function() {
  return this.setRelevantSubjectIdsList([]);
};


/**
 * repeated string required_term_ids = 7;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getRequiredTermIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setRequiredTermIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.addRequiredTermIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.clearRequiredTermIdsList = function() {
  return this.setRequiredTermIdsList([]);
};


/**
 * optional string action_chain_id = 8;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getActionChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setActionChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool published = 9;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional Qr qr = 10;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getQr = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr, 10));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setQr = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.clearQr = function() {
  return this.setQr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.hasQr = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Raw raw = 11;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getRaw = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw, 11));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setRaw = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.clearRaw = function() {
  return this.setRaw(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.hasRaw = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional Barcode barcode = 12;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getBarcode = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode, 12));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setBarcode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.clearBarcode = function() {
  return this.setBarcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.hasBarcode = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string icon_uri = 15;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getIconUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setIconUri = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string hex_background_color = 16;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getHexBackgroundColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setHexBackgroundColor = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string citykey_id = 17;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.getCitykeyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.AdmissionCardType.prototype.setCitykeyId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Qr.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Raw.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode;
  return proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.Type = {
  CODE_39: 0
};

/**
 * optional Type type = 1;
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.Type}
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.prototype.getType = function() {
  return /** @type {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.Type} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.admissioncard.Barcode.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.admissioncard);
