import React from 'react';
import { AccessControllIcon } from '../../assets/AccessControllIcon';
import { ClockIcon } from '../../assets/ClockIcon';
import { Spread } from '../../assets/Spread';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { AuditCard } from '../../components/cards/AuditCard/AuditCard';
import { LinkToControlpanelCard } from '../../components/cards/ControlpanelCards/LinkToControlpanelCard';
import { NarrowCard } from '../../components/cards/NarrowCard/NarrowCard';
import { StatsCard } from '../../components/cards/StatsCard/StatsCard';
import { ImageCard } from '../../components/image/ImageCard';
import { RenderIfSuperAdmin } from '../../components/util/RenderIfSuperAdmin';
import { ManualGrantKeychainFactoryDetails } from '../../redux/slice/keychainFactoryDetails';
import { ManualGrantKeychainFactorySummary } from '../../redux/slice/keychainFactorySummaries';
import { AdminRoleCard } from './AdminRoleCard/AdminRoleCard';
import { KeychainFactoryBreadcrumbs } from './KeychainFactoryBreadcrumbs';
import { KeychainFactoryContainer } from './KeychainFactoryContainer/KeychainFactoryContainer';
import { KeychainFactoryMenu } from './actions/KeychainFactoryMenu';
import { Locks } from './locks/Locks';

interface Props {
  keychainFactory:
    | ManualGrantKeychainFactoryDetails
    | ManualGrantKeychainFactorySummary;
}

export const ManualGrantKeychainFactory: React.FC<Props> = ({
  keychainFactory
}) => {
  const { isDetails } = keychainFactory;
  return (
    <KeychainFactoryContainer>
      <span className='breadcrumbs-delete-button-container'>
        <KeychainFactoryBreadcrumbs keychainFactory={keychainFactory} />
      </span>
      <div className='cards actionmenu-ribbon'>
        <ImageCard
          images={[{ uri: keychainFactory.image }]}
          keychainFactoryUri={keychainFactory.keychainFactoryUri}
        />
        <AdminRoleCard adminRole={keychainFactory.ownerRole} />
        {isDetails && <KeychainFactoryMenu keychainFactory={keychainFactory} />}
      </div>
      <div className='cards'>
        <NarrowCard
          link='tilgangskontroll'
          header='Tilgangsoversikt'
          icon={<AccessControllIcon />}
        >
          {isDetails && (
            <h4 className='number-of-requests-text'>
              Antall brukere: {keychainFactory.approvedKeychains}
            </h4>
          )}
        </NarrowCard>
        <NarrowCard
          link='åpningstider'
          header='Åpningstider'
          icon={<ClockIcon />}
        >
          {isDetails && (
            <h4>
              {keychainFactory.openingHours.alwaysOpen
                ? 'Alltid åpent'
                : 'Tilpasset åpningstid'}
            </h4>
          )}
        </NarrowCard>
        <StatsCard
          type={keychainFactory.type}
          id={keychainFactory.keychainFactoryUri}
        />
        <RenderIfSuperAdmin>
          <NarrowCard
            link='grouplink'
            header='Spre nøkkel'
            icon={<Spread />}
          ></NarrowCard>
        </RenderIfSuperAdmin>
        <AuditCard
          type={keychainFactory.type}
          citykeyId={keychainFactory.keychainFactoryUri}
        />
        <LinkToControlpanelCard type='manual-grant' />
      </div>
      {isDetails ? (
        <Locks operations={keychainFactory.grantedOperationsList} />
      ) : (
        <LoadingSpinner />
      )}
    </KeychainFactoryContainer>
  );
};
