import { createSlice } from '@reduxjs/toolkit';
import { FindNameFromContactInfoResponse } from '../../proto/utils_pb';
import { findProfileFromPhoneNumber } from '../actions/access';
import { grantAccess, grantOidcAccess } from '../actions/keychains';

export interface AccessState {
  phoneSearchResult: FindNameFromContactInfoResponse.AsObject;
  phoneSearchError: string;
}

export const initialState: AccessState = {
  phoneSearchResult: { name: '', profileId: '' },
  phoneSearchError: ''
};

const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    resetPhoneNumber: () => initialState
  },
  extraReducers: builder => {
    builder
      .addCase(findProfileFromPhoneNumber.rejected, (state, { payload }) => {
        state.phoneSearchError = payload || '';
      })
      .addCase(findProfileFromPhoneNumber.fulfilled, (state, { payload }) => {
        state.phoneSearchResult = payload;
        state.phoneSearchError = initialState.phoneSearchError;
      })
      .addCase(grantOidcAccess.fulfilled, () => initialState)
      .addCase(grantAccess.fulfilled, () => initialState);
  }
});

export const { resetPhoneNumber } = accessSlice.actions;
export default accessSlice.reducer;
