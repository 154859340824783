declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

export const logAnalyticsEvent = (event: string, data?: object) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    ...data
  });
};
