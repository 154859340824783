import React from 'react';
import { AgeSvg } from '../../../assets/AgeSvg';
import { OperationInfoCard } from '../operationCards/OperationInfoCard';

interface Props {
  ageLimit: number;
}

export const AgeLimitCard: React.FC<Props> = ({ ageLimit }) => {
  return (
    <OperationInfoCard
      icon={<AgeSvg />}
      header={'Aldersgrense'}
      link='agelimit'
      text={ageLimit ? ageLimit + ' år' : 'Ingen aldersgrense'}
    />
  );
};
