import React from 'react';

type Props = {
  size?: number;
};
export const Web: React.FC<Props> = ({ size = 136 }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12227'
      data-name='Group 12227'
      transform='translate(-1488 -475)'
    >
      <g id='Group_12226' data-name='Group 12226' transform='translate(233)'>
        <g
          id='Group_12192'
          data-name='Group 12192'
          transform='translate(-231 256)'
        >
          <g
            id='Group_12177'
            data-name='Group 12177'
            transform='translate(1158 -13)'
          >
            <circle
              id='Ellipse_691'
              data-name='Ellipse 691'
              cx='68'
              cy='68'
              r='68'
              transform='translate(328 232)'
              fill='#f9b3ab'
            />
          </g>
        </g>
      </g>
      <path
        id='noun-web-5761639'
        d='M119.5,17.5a32,32,0,1,0,32,32A32.015,32.015,0,0,0,119.5,17.5Zm9.85,49.448c-3.2-.252-6.494-.382-9.85-.382s-6.645.13-9.85.382a26.889,26.889,0,0,0,2.795,5.45c2.007,2.942,4.38,4.834,7.055,4.834s5.048-1.892,7.055-4.834A26.889,26.889,0,0,0,129.35,66.948Zm-24.073.424q-3.168.369-6.193.89a27.8,27.8,0,0,0,10.168,7.012c-.111-.153-.222-.311-.33-.471a32.18,32.18,0,0,1-3.644-7.43Zm28.445,0a32.176,32.176,0,0,1-3.644,7.43c-.109.16-.22.318-.33.471a27.8,27.8,0,0,0,10.168-7.012q-3.021-.522-6.193-.89ZM91.847,51.633a27.569,27.569,0,0,0,4.3,12.828q3.821-.739,7.891-1.237a56.609,56.609,0,0,1-1.572-11.59Zm44.683,0a56.621,56.621,0,0,1-1.572,11.59q4.064.5,7.891,1.237a27.567,27.567,0,0,0,4.3-12.828Zm-29.792,0a51.777,51.777,0,0,0,1.576,11.145c3.627-.314,7.364-.478,11.185-.478s7.559.164,11.185.478a51.781,51.781,0,0,0,1.576-11.145ZM96.149,34.539a27.567,27.567,0,0,0-4.3,12.828h10.622a56.621,56.621,0,0,1,1.572-11.59q-4.064-.5-7.891-1.237Zm34.534,1.683c-3.627.314-7.364.478-11.185.478s-7.559-.164-11.185-.478a51.781,51.781,0,0,0-1.576,11.145H132.26a51.776,51.776,0,0,0-1.576-11.145Zm12.164-1.683q-3.821.739-7.891,1.237a56.61,56.61,0,0,1,1.572,11.59h10.622a27.569,27.569,0,0,0-4.3-12.828ZM119.5,21.767c-2.675,0-5.048,1.892-7.055,4.834a26.889,26.889,0,0,0-2.795,5.45c3.2.252,6.494.382,9.85.382s6.645-.13,9.85-.382a26.89,26.89,0,0,0-2.795-5.45C124.546,23.659,122.174,21.767,119.5,21.767Zm10.249,1.959c.111.153.222.311.33.471a32.181,32.181,0,0,1,3.644,7.43q3.168-.365,6.193-.89a27.8,27.8,0,0,0-10.168-7.012Zm-20.5,0a27.8,27.8,0,0,0-10.168,7.012q3.021.522,6.193.89a32.176,32.176,0,0,1,3.644-7.43c.109-.16.22-.318.33-.471Z'
        transform='translate(1436.5 493.5)'
        fill='#292858'
        fillRule='evenodd'
      />
    </g>
  </svg>
);
