import { zodResolver } from '@hookform/resolvers/zod';
import { formatDistance } from 'date-fns';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button } from '../../../../components/Button/Button';
import { EndDateTimePicker } from '../../../../components/DatePickers/EndDateTimePicker';
import { StartDateTimePicker } from '../../../../components/DatePickers/StartDateTimePicker';
import {
  FetchAuditLog,
  fetchAuditLog
} from '../../../../redux/actions/auditLogs';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { auditLogSelectors } from '../../../../redux/slice/auditLogs';
import { AuditLogsTable } from './AuitLogTable';

export const AuditLogs: React.FC = () => {
  const dispatch = useAppDispatch();
  const auditLogs = useAppSelector(auditLogSelectors.selectAll);
  const [showResults, setShowResults] = useState(false);

  const methods = useForm<FetchAuditLog>({
    resolver: zodResolver(FetchAuditLog),
    defaultValues: {
      fromDate: new Date(),
      untilDate: new Date()
    }
  });

  const { watch, handleSubmit } = methods;
  const [fromDate, untilDate] = watch(['fromDate', 'untilDate']);

  const submit = (data: FetchAuditLog) =>
    dispatchAndHandleResult(dispatch, fetchAuditLog, data, () => {
      setShowResults(true);
    });

  const varighet = formatDistance(fromDate, untilDate);

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <StartDateTimePicker
            name='fromDate'
            startDate={methods.getValues('fromDate')}
            endDate={methods.getValues('untilDate')}
            inline
          />{' '}
          -{' '}
          <EndDateTimePicker
            name='untilDate'
            startDate={methods.getValues('fromDate')}
            endDate={new Date()}
            inline
          />
          <p>
            Du søker innenfor en varighet på: <b>{varighet}</b>
          </p>
          <br />
          <Button label='Vis logger' type='submit' />
          <br />
          <br />
          {showResults && (
            <AuditLogsTable auditLogs={auditLogs} showResourceId={true} />
          )}
        </form>
      </FormProvider>
    </div>
  );
};
