import React from 'react';
import { KeyRadioGroup } from '../../../../components/Forms/KeyRadioGroup';
import { RenderIfSuperAdmin } from '../../../../components/util/RenderIfSuperAdmin';

export const Shareable: React.FC = () => {
  return (
    <RenderIfSuperAdmin>
      <KeyRadioGroup
        name='shareable'
        description='Kan denne personen dele tilgangen videre?'
        label='Kan deles?'
        options={[
          { label: 'Ja', value: 'true' },
          { label: 'Nei', value: 'false' }
        ]}
        elementProps={{}}
      />
    </RenderIfSuperAdmin>
  );
};
