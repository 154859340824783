import React from 'react';
import './LoadingSpinner.scss';

export const LoadingSpinner = () => (
  <div className='loading-spinner'>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
