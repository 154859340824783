import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Keypedia } from '../../redux/types/common';
import { historyGoBack } from '../../utils/history';
import { formatDateFromEpochMillis } from '../../utils/timeConverter';
import { TextEditor } from '../Editor/TextEditor';
import './Keypedia.scss';

interface Props {
  saveCallback: (updatedText: string) => void;
  wiki: Keypedia;
}

export const KeypediaEditor: React.FC<Props> = ({ saveCallback, wiki }) => {
  const navigate = useNavigate();
  const handleSaveClick = (updatedText: string) => {
    saveCallback(updatedText);
  };
  return (
    <div className='keypedia-page'>
      <div>
        <TextEditor
          type='advanced'
          message={wiki.htmlContent}
          saveCallback={handleSaveClick}
          handleCancelClick={() => historyGoBack(navigate)}
          description='Informasjon som ikke skal vises i appen, men som kan være relevant internt.'
        />
      </div>
      <div className='log'>
        <b>Logg</b>
        {wiki.lastUpdatedMillis ? (
          <p>
            Sist endret av <b>{wiki.lastUpdatedBy}</b> den{' '}
            <b>{formatDateFromEpochMillis(wiki.lastUpdatedMillis)}</b>
          </p>
        ) : (
          <p>Foreløpig ingen lagringer</p>
        )}
      </div>
    </div>
  );
};
