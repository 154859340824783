import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ProximityVerificationDevice } from '../../proto/pvd_pb';
import {
  createProximityVerificationDevice,
  listProximityVerificationDevice
} from '../actions/pvd';
import { RootState } from '../index';

export interface PvdDetails {
  id: string;
  secret: string;
  timeDriftSeconds: number;
  timeStepSeconds: number;
  discrepancyTolerance: number;
  capabilitiesList: ProximityVerificationDevice.Capability[];
  lastModifiedTimestamp: number;
}

const deviceListAdapter = createEntityAdapter<PvdDetails>();

const pvdSlice = createSlice({
  name: 'pvd',
  initialState: deviceListAdapter.getInitialState({
    devicelistLoading: false,
    devicelistLastRefreshed: 0
  }),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(listProximityVerificationDevice.pending, state => {
        state.devicelistLoading = true;
      })
      .addCase(listProximityVerificationDevice.fulfilled, (state, action) => {
        deviceListAdapter.setAll(state, action.payload);
        state.devicelistLoading = false;
        state.devicelistLastRefreshed = Date.now();
      })
      .addCase(listProximityVerificationDevice.rejected, state => {
        state.devicelistLoading = false;
      })
      .addCase(createProximityVerificationDevice.fulfilled, (state, action) => {
        deviceListAdapter.addOne(state, action.payload);
      });
  }
});

export const initialState = pvdSlice.getInitialState();
export type PvdState = typeof initialState;
export default pvdSlice.reducer;
export const pvdSelectors = deviceListAdapter.getSelectors<RootState>(
  state => state.pvd
);
