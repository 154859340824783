import React from 'react';
import { Link } from 'react-router-dom';
import { AdmissionCard } from '../../redux/slice/admissionCards';
import { antall } from '../../utils/language';
import { generateImageUri } from '../../utils/templating';
import './AdmissionCards.scss';

type AdmissionCardProps = {
  admissionCard: AdmissionCard;
};
export const AdmissionCardCard: React.FC<AdmissionCardProps> = ({
  admissionCard
}) => {
  const {
    id,
    publicName,
    iconUri,
    hexBackgroundColor,
    relevantSubjectIdsList,
    requiredTermIdsList
  } = admissionCard;

  return (
    <Link to={id}>
      <div
        data-testid='admissioncard-card'
        className='admissioncard-card'
        style={{ backgroundColor: hexBackgroundColor }}
      >
        <div className='inner'>
          {iconUri && (
            <div className='img-container'>
              <img src={generateImageUri(iconUri)} alt='Adgangskort ' />
            </div>
          )}
          <p className='title'>{publicName}</p>
          <p className='description'>
            {antall(relevantSubjectIdsList.length, 'sted')}
            <br />
            {antall(requiredTermIdsList.length, 'vilkår')}
          </p>
        </div>
      </div>
    </Link>
  );
};
