import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CreateOperationBucket } from '../pages/controlpanel/OperationBucket/CreateOperationBucket';
import { OperationBucket } from '../pages/controlpanel/OperationBucket/OperationBucket';
import { OperationBuckets } from '../pages/controlpanel/OperationBucket/OperationBuckets';
import {
  fetchOperationBuckets,
  getAllOperationIds
} from '../redux/actions/buckets';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const AdminBucketsRouter = () => {
  const dispatch = useAppDispatch();
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  useEffect(() => {
    if (selectedAdminTool !== 'buckets') {
      dispatch(fetchOperationBuckets());
      dispatch(getAllOperationIds());
      dispatch(setSelectedAdminTool('buckets'));
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <section>
      <Routes>
        <Route path='lag-ny' element={<CreateOperationBucket />} />
        <Route path=':bucketId' element={<OperationBucket />} />
        <Route path='/' element={<OperationBuckets />} />
      </Routes>
    </section>
  );
};
