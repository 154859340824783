import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdminKeychain } from '../pages/controlpanel/KeychainFactory/AdminKeychain';
import { AdminKeychainPage } from '../pages/controlpanel/KeychainFactory/AdminKeychainPage';
import { CreateKeychainFactory } from '../pages/controlpanel/KeychainFactory/CreateKeychainFactory';
import { listKeychainFactoryConfigurations } from '../redux/actions/keychainFactorySummaries';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const AdminKeychainRouter = () => {
  const dispatch = useAppDispatch();
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  useEffect(() => {
    if (selectedAdminTool !== 'manualgrantchains') {
      dispatch(listKeychainFactoryConfigurations());
      dispatch(setSelectedAdminTool('manualgrantchains'));
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <section>
      <Routes>
        <Route path='lag-ny' element={<CreateKeychainFactory />} />
        <Route path=':keychainId' element={<AdminKeychain type='manual-grant' />} />
        <Route path='/' element={<AdminKeychainPage />} />
      </Routes>
    </section>
  );
};
