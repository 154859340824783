import React from 'react';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { useAppSelector } from '../../../redux/hooks';
import { getGrafanaAdapterUrl } from '../../../utils/grafana';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';
import { formatDateFromEpochMillis } from '../../../utils/timeConverter';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const AdminAdapterPage: React.FC = () => {
  const adapters = useAppSelector(state => state.adapters.descriptions);

  const headings = [
    'Adapter ID',
    'URI',
    'Sandboxed',
    'Sist skrapet',
    'Grafana'
  ];

  const data = adapters.map(adapter => {
    const lastScraped = adapter.adapterScrapeSummary?.lastScrapeTimestamp;
    return {
      link: adapter.id,
      cells: [
        <CopyWrapper key={adapter.id} copyValue={adapter.id} />,
        <CopyWrapper key={adapter.uri} copyValue={adapter.uri} />,
        adapter.sandboxed ? 'Ja' : 'Nei',
        lastScraped ? formatDateFromEpochMillis(lastScraped) : '',
        <a
          key={adapter.id}
          onClick={e => {
            e.preventDefault();
            window.location.href = getGrafanaAdapterUrl(adapter.id);
          }}
          href={getGrafanaAdapterUrl(adapter.id)}
        >
          🔗
        </a>
      ]
    };
  });

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs />
      <TableWithButtons
        ting='adapter'
        buttons={[
          {
            label: 'Lag ny adapter',
            type: 'primary',
            accessKey: 'n',
            linkTo: 'lag-nytt'
          }
        ]}
        table={{
          headings: headings,
          rows: data
        }}
      />
    </React.Fragment>
  );
};
