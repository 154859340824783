import React, { useState } from 'react';
import { handleImageSave } from '../../../utils/operation/images/imageSave';
import { DialogFormBox } from '../../DialogBox/DialogFormBox';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import './EditImagePopup.scss';
import { ImagePreview } from './ImagePreview';
import { InputButtons } from './InputButtons';

interface ModalProps {
  closeModal: () => void;
  isModalOpen: boolean;
}

interface SubjectProps extends ModalProps {
  subjectId: string;
}

interface OperationProps extends SubjectProps {
  operationId: string;
}

interface KeychainFactoryProps extends ModalProps {
  keychainFactoryUri: string;
}

type Props = SubjectProps | OperationProps | KeychainFactoryProps;

export const EditImagePopup: React.FC<Props> = ({
  closeModal,
  isModalOpen,
  ...ids
}) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState<File | null>(null);
  const [error, setError] = useState('');

  return (
    <DialogFormBox
      onSubmit={() =>
        handleImageSave({
          error: error,
          image: image,
          setLoading: setLoading,
          closeModal: closeModal,
          ...ids
        })
      }
      header={'Last opp bilde for ' + displayType(ids)}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
    >
      <div className='confirm-box image'>
        {loading && <LoadingSpinner />}
        <ImagePreview image={image} />
        <InputButtons image={image} setImage={setImage} setError={setError} />
        {error !== '' && <p className='red'>{error}</p>}
      </div>
    </DialogFormBox>
  );
};

const displayType = (ids: Partial<Props>): string => {
  if ('operationId' in ids) {
    return 'operasjonen';
  } else if ('keychainFactoryUri' in ids) {
    return 'tilgangslisten';
  }
  return 'stedet';
};
