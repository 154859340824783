import React from 'react';
import { Breadcrumbs } from '../../../components/Navigation/breadcrumbs';
import { admissionCardsHome } from '../../../components/Navigation/navPaths';
import { AuditCard } from '../../../components/cards/AuditCard/AuditCard';
import { StatsCard } from '../../../components/cards/StatsCard/StatsCard';
import { OperationTerms } from '../../../components/operation/terms/OperationTerms';
import { AdminRoleCard } from '../../keychainFactory/AdminRoleCard/AdminRoleCard';
import './AdmissionCardDetails.scss';
import { AdmissionCardSubjectCard } from './AdmissionCardSubjectCard';
import { useAdmissionCard } from './AdmissionCardWrapper';
import { Subjects } from './Subjects';

export const AdmissionCardDetails: React.FC = () => {
  const { admissionCard } = useAdmissionCard();

  return (
    <section className='admissioncard-details'>
      <Breadcrumbs
        breadcrumbs={[
          admissionCardsHome,
          {
            name: admissionCard.publicName
          }
        ]}
      />

      <div className='admissioncard-infocards'>
        <OperationTerms termIds={admissionCard.requiredTermIdsList} />
        <AdmissionCardSubjectCard
          subjectIds={admissionCard.relevantSubjectIdsList}
        />
        <StatsCard type='admissioncards' id={admissionCard.id} />
        <AuditCard type='admissioncards' citykeyId={admissionCard.citykeyId} />
        <AdminRoleCard
          adminRole={admissionCard.adminRole}
          type='adgangskortet'
        />
      </div>
      <br />
      <Subjects subjectIds={admissionCard.relevantSubjectIdsList} />
    </section>
  );
};
