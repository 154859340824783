import React from 'react';
import { Button } from '../../../components/Button/Button';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { exportStats } from '../utils/exportStats';
import { getNumOfOpenings } from '../utils/helpers';
import { GraphData } from '../utils/statsTypes';
import './GraphContainer.scss';
import { HighchartsGraph } from './HighchartsGraph/HighchartsGraph';

interface GraphProps {
  graphData: GraphData[];
  loading: boolean;
}

export const GraphContainer = ({ graphData, loading }: GraphProps) => (
  <div className='graph-page-graph-container'>
    <div className='extras-container'>
      <Button
        type='primary'
        label='Last ned'
        onClick={() => exportStats(graphData)}
      />
      <p className='amount'>
        Antall åpninger: <b>{getNumOfOpenings(graphData)}</b>
      </p>
    </div>
    <HighchartsGraph graphData={graphData} />
    {loading && <LoadingSpinner />}
  </div>
);
