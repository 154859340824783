import React, { useMemo } from 'react';
import { RoleDefinition } from '../../redux/slice/keychainFactoryDetails';
import { KeySelect } from './KeySelect';
import { KeyComponentProps } from './KeyWrapper';

interface Option {
  labelText: string;
  description: string;
  value: string;
}

interface Props extends KeyComponentProps {
  roleDefinitions?: RoleDefinition[];
}
export const OidcRoleSelector: React.FC<Props> = ({
  label = 'Roller',
  description = 'Velg alle rollene som denne personene skal ha',
  name,
  roleDefinitions = []
}) => {
  const roleOptions: Option[] = useMemo(() => {
    return roleDefinitions.map(role => {
      return {
        labelText: role.name,
        description: role.description,
        value: role.id
      };
    });
  }, [roleDefinitions]);

  return (
    <KeySelect
      name={name}
      label={label}
      description={description}
      elementProps={{
        isMulti: true,
        options: roleOptions
      }}
    />
  );
};
