import React from 'react';

export const Last7Days: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='40'
    viewBox='0 0 37 40'
  >
    <g
      id='Group_12331'
      data-name='Group 12331'
      transform='translate(-63 -1248)'
    >
      <g
        id='Ellipse_779'
        data-name='Ellipse 779'
        transform='translate(64 1248)'
        fill='#fff'
        stroke='#292858'
        strokeWidth='1'
      ></g>
      <path
        id='Path_11412'
        data-name='Path 11412'
        d='M3.693,7.385H0V5.539H1.848V5.533h.006a.917.917,0,1,0,0-1.834H1.848V3.692H0V1.846H3.693V0H5.539V13.724a4.616,4.616,0,1,1-1.846,0ZM1.807,18.246a2.81,2.81,0,1,0,2.81-2.81A2.811,2.811,0,0,0,1.807,18.246Z'
        transform='translate(84.563 1283.979) rotate(141)'
        fill='#292858'
      />
    </g>
  </svg>
);
