import Highcharts, { SeriesOptionsType } from 'highcharts';
import { GraphData } from './statsTypes';

const formatGraphData = (graphData: GraphData[]) => {
  const allStats: { name: string; points: number[][]; color: string }[] = [];

  for (let i = 0; i < graphData.length; i++) {
    const stat: number[][] = [];

    graphData[i].data.map(point =>
      stat.push([point.timestamp * 1000, point.visits])
    );

    allStats.push({
      name: graphData[i].id,
      points: stat,
      color: graphData[i].color
    });
  }

  if (allStats.length === 0) {
    allStats.push({
      name: 'No data',
      points: [],
      color: '#ccc'
    });
  }

  return allStats;
};

export const createSeries: (
  allStats: {
    name: string;
    points: number[][];
    color: string;
  }[]
) => SeriesOptionsType[] = (
  allStats: {
    name: string;
    points: number[][];
    color: string;
  }[]
) => {
  return allStats.map(options => {
    return {
      type: 'area',
      name: options.name,
      data: options.points,
      color: options.color
    };
  });
};

export const createHighchartOptions: (
  graphData: GraphData[]
) => Highcharts.Options = (graphData: GraphData[]) => {
  const seriesData = createSeries(formatGraphData(graphData));

  return {
    credits: {
      enabled: false
    },
    title: undefined,
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%d %b'
      },
      title: {
        style: {
          color: '#121212'
        },
        text: 'Dato'
      },
      labels: {
        style: {
          color: '#2c2c2ca8'
        }
      }
    },
    yAxis: {
      title: {
        style: {
          color: '#121212'
        },
        text: 'Besøk'
      },
      gridLineWidth: 0,
      minorGridLineWidth: 0
    },
    series: seriesData,
    legend: {
      enabled: false
    },
    scrollbar: {
      enabled: true
    },
    chart: {
      backgroundColor: 'transparent',
      style: {
        textColor: '#ffffff'
      },
      height: 400,
      type: 'line'
    }
  };
};
