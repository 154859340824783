import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Terms } from '../pages/terms/Terms';
import { CreateTerm } from '../pages/terms/createTerm/CreateTerm';
import { Term } from '../pages/terms/term/Term';
import { getTerms } from '../redux/actions/terms';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const TermsRouter = () => {
  const dispatch = useAppDispatch();
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  useEffect(() => {
    if (selectedAdminTool !== 'terms') {
      dispatch(getTerms());
      dispatch(setSelectedAdminTool('terms'));
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <Routes>
      <Route path='' element={<Terms />} />
      <Route path='lag-ny' element={<CreateTerm />} />
      <Route path=':termId' element={<Term />} />
    </Routes>
  );
};
