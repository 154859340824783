// source: grpc-adapter.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesList: jspb.Message.toObjectList(msg.getEntriesList(),
    proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.deserializeBinaryFromReader);
      msg.addEntries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.toObject = function(includeInstance, msg) {
  var f, obj = {
    uri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adapterId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    thingId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    actionId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    thingDescription: jspb.Message.getFieldWithDefault(msg, 6, ""),
    actionDescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
    betterUptimeMonitorId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setThingId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setThingDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetterUptimeMonitorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdapterId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getThingId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActionId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getThingDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getActionDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBetterUptimeMonitorId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string adapter_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.getAdapterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.setAdapterId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string thing_id = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.getThingId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.setThingId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string action_id = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.getActionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.setActionId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string thing_description = 6;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.getThingDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.setThingDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string action_description = 7;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.getActionDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.setActionDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string better_uptime_monitor_id = 8;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.getBetterUptimeMonitorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry.prototype.setBetterUptimeMonitorId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated Entry entries = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry>}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.prototype.getEntriesList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.prototype.setEntriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.prototype.addEntries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.Entry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAllActionsResponse.prototype.clearEntriesList = function() {
  return this.setEntriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    preferredEncoding: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (reader.readEnum());
      msg.setPreferredEncoding(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPreferredEncoding();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional oslonokkelen.proto.shared.Text.Type preferred_encoding = 2;
 * @return {!proto.oslonokkelen.proto.shared.Text.Type}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.prototype.getPreferredEncoding = function() {
  return /** @type {!proto.oslonokkelen.proto.shared.Text.Type} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Text.Type} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DescribeAdapterRequest.prototype.setPreferredEncoding = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListAdaptersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ScrapeAdapterManifestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chain: (f = msg.getChain()) && proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.deserializeBinaryFromReader);
      msg.setChain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * optional ActionChainDefinition chain = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.prototype.getChain = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.prototype.setChain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.prototype.clearChain = function() {
  return this.setChain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateActionChainRequest.prototype.hasChain = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedChain: (f = msg.getUpdatedChain()) && proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.toObject(includeInstance, f),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.deserializeBinaryFromReader);
      msg.setUpdatedChain(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedChain();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.serializeBinaryToWriter
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional ActionChainDefinition updated_chain = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.prototype.getUpdatedChain = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.prototype.setUpdatedChain = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.prototype.clearUpdatedChain = function() {
  return this.setUpdatedChain(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.prototype.hasUpdatedChain = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 expected_version = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.UpdateActionChainRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    expectedVersion: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExpectedVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExpectedVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.prototype.getNewName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.prototype.setNewName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 expected_version = 3;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.prototype.getExpectedVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.RenameActionChainRequest.prototype.setExpectedVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionChainsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainsList: jspb.Message.toObjectList(msg.getChainsList(),
    proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.deserializeBinaryFromReader);
      msg.addChains(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ActionChainDefinition chains = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition>}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.prototype.getChainsList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.prototype.setChainsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.prototype.addChains = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainListResponse.prototype.clearChainsList = function() {
  return this.setChainsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    actionsList: jspb.Message.toObjectList(msg.getActionsList(),
    shared_pb.ActionDefinition.toObject, includeInstance),
    usedByOperationsList: jspb.Message.toObjectList(msg.getUsedByOperationsList(),
    shared_pb.FullOperationId.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 5:
      var value = new shared_pb.ActionDefinition;
      reader.readMessage(value,shared_pb.ActionDefinition.deserializeBinaryFromReader);
      msg.addActions(value);
      break;
    case 6:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.addUsedByOperations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      shared_pb.ActionDefinition.serializeBinaryToWriter
    );
  }
  f = message.getUsedByOperationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 version = 4;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated oslonokkelen.proto.shared.ActionDefinition actions = 5;
 * @return {!Array<!proto.oslonokkelen.proto.shared.ActionDefinition>}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.getActionsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.ActionDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.ActionDefinition, 5));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.ActionDefinition>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.setActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.ActionDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.ActionDefinition}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.addActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.oslonokkelen.proto.shared.ActionDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.clearActionsList = function() {
  return this.setActionsList([]);
};


/**
 * repeated oslonokkelen.proto.shared.FullOperationId used_by_operations = 6;
 * @return {!Array<!proto.oslonokkelen.proto.shared.FullOperationId>}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.getUsedByOperationsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.FullOperationId>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.FullOperationId, 6));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.FullOperationId>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.setUsedByOperationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.FullOperationId=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.addUsedByOperations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.oslonokkelen.proto.shared.FullOperationId, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ActionChainDefinition.prototype.clearUsedByOperationsList = function() {
  return this.setUsedByOperationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string action_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.prototype.getActionUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.prototype.setActionUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.GenerateActionHealthApiKeyRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    actionUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    monitorName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonitorName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActionUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMonitorName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string action_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.prototype.getActionUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.prototype.setActionUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string monitor_name = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.prototype.getMonitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateBetterUptimeMonitorRequest.prototype.setMonitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    adapterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adapterEndpointUri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterEndpointUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdapterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdapterEndpointUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string adapter_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.prototype.getAdapterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.prototype.setAdapterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string adapter_endpoint_uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.prototype.getAdapterEndpointUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateAdapterMessage.prototype.setAdapterEndpointUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    adapterId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sandboxed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSandboxed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdapterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSandboxed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string adapter_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.prototype.getAdapterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.prototype.setAdapterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool sandboxed = 2;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.prototype.getSandboxed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ToggleAdapterSandboxMessage.prototype.setSandboxed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    adapterId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdapterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string adapter_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.prototype.getAdapterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyMessage.prototype.setAdapterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.CreateSelftestKeyResponse.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: (f = msg.getDescription()) && proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.toObject(includeInstance, f),
    thingsList: jspb.Message.toObjectList(msg.getThingsList(),
    shared_pb.Thing.toObject, includeInstance),
    wiki: (f = msg.getWiki()) && shared_pb.Keypedia.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 2:
      var value = new shared_pb.Thing;
      reader.readMessage(value,shared_pb.Thing.deserializeBinaryFromReader);
      msg.addThings(value);
      break;
    case 3:
      var value = new shared_pb.Keypedia;
      reader.readMessage(value,shared_pb.Keypedia.deserializeBinaryFromReader);
      msg.setWiki(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.serializeBinaryToWriter
    );
  }
  f = message.getThingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      shared_pb.Thing.serializeBinaryToWriter
    );
  }
  f = message.getWiki();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      shared_pb.Keypedia.serializeBinaryToWriter
    );
  }
};


/**
 * optional AdapterDescription description = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.getDescription = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated oslonokkelen.proto.shared.Thing things = 2;
 * @return {!Array<!proto.oslonokkelen.proto.shared.Thing>}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.getThingsList = function() {
  return /** @type{!Array<!proto.oslonokkelen.proto.shared.Thing>} */ (
    jspb.Message.getRepeatedWrapperField(this, shared_pb.Thing, 2));
};


/**
 * @param {!Array<!proto.oslonokkelen.proto.shared.Thing>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.setThingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.oslonokkelen.proto.shared.Thing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.oslonokkelen.proto.shared.Thing}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.addThings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.oslonokkelen.proto.shared.Thing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.clearThingsList = function() {
  return this.setThingsList([]);
};


/**
 * optional oslonokkelen.proto.shared.Keypedia wiki = 3;
 * @return {?proto.oslonokkelen.proto.shared.Keypedia}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.getWiki = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Keypedia} */ (
    jspb.Message.getWrapperField(this, shared_pb.Keypedia, 3));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Keypedia|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.setWiki = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.clearWiki = function() {
  return this.setWiki(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescriptionResponse.prototype.hasWiki = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    adaptersList: jspb.Message.toObjectList(msg.getAdaptersList(),
    proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.deserializeBinaryFromReader);
      msg.addAdapters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdaptersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AdapterDescription adapters = 1;
 * @return {!Array<!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription>}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.prototype.getAdaptersList = function() {
  return /** @type{!Array<!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription, 1));
};


/**
 * @param {!Array<!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.prototype.setAdaptersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.prototype.addAdapters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterListResponse.prototype.clearAdaptersList = function() {
  return this.setAdaptersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uri: jspb.Message.getFieldWithDefault(msg, 2, ""),
    version: jspb.Message.getFieldWithDefault(msg, 3, 0),
    adapterScrapeSummary: (f = msg.getAdapterScrapeSummary()) && proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.toObject(includeInstance, f),
    sandboxed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    selfTestUri: jspb.Message.getFieldWithDefault(msg, 6, ""),
    scrapeUri: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVersion(value);
      break;
    case 4:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.deserializeBinaryFromReader);
      msg.setAdapterScrapeSummary(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSandboxed(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSelfTestUri(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setScrapeUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAdapterScrapeSummary();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.serializeBinaryToWriter
    );
  }
  f = message.getSandboxed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getSelfTestUri();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getScrapeUri();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 version = 3;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional AdapterScrapeSummary adapter_scrape_summary = 4;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.getAdapterScrapeSummary = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary, 4));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.setAdapterScrapeSummary = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.clearAdapterScrapeSummary = function() {
  return this.setAdapterScrapeSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.hasAdapterScrapeSummary = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool sandboxed = 5;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.getSandboxed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.setSandboxed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string self_test_uri = 6;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.getSelfTestUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.setSelfTestUri = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string scrape_uri = 7;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.getScrapeUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterDescription.prototype.setScrapeUri = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastScrapeTimestamp: (f = msg.getLastScrapeTimestamp()) && shared_pb.Timestamp.toObject(includeInstance, f),
    lastSuccessfulScrapeTimestamp: (f = msg.getLastSuccessfulScrapeTimestamp()) && shared_pb.Timestamp.toObject(includeInstance, f),
    lastScrapeDurationMs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    lastScrapeTraceId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastScrapeTimestamp(value);
      break;
    case 2:
      var value = new shared_pb.Timestamp;
      reader.readMessage(value,shared_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSuccessfulScrapeTimestamp(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLastScrapeDurationMs(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastScrapeTraceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastScrapeTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastSuccessfulScrapeTimestamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastScrapeDurationMs();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLastScrapeTraceId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.Timestamp last_scrape_timestamp = 1;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.getLastScrapeTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.setLastScrapeTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.clearLastScrapeTimestamp = function() {
  return this.setLastScrapeTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.hasLastScrapeTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional oslonokkelen.proto.shared.Timestamp last_successful_scrape_timestamp = 2;
 * @return {?proto.oslonokkelen.proto.shared.Timestamp}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.getLastSuccessfulScrapeTimestamp = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Timestamp} */ (
    jspb.Message.getWrapperField(this, shared_pb.Timestamp, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Timestamp|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.setLastSuccessfulScrapeTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.clearLastSuccessfulScrapeTimestamp = function() {
  return this.setLastSuccessfulScrapeTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.hasLastSuccessfulScrapeTimestamp = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 last_scrape_duration_ms = 3;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.getLastScrapeDurationMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.setLastScrapeDurationMs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string last_scrape_trace_id = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.getLastScrapeTraceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.AdapterScrapeSummary.prototype.setLastScrapeTraceId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    adapterId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdapterId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdapterId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string adapter_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.prototype.getAdapterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.DeleteAdapterMessage.prototype.setAdapterId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.adapter.ListActionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.adapter);
