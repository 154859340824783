import React from 'react';
import './CreateSection.scss';

interface CreateSectionProps {
  children: React.ReactNode;
}

export const CreateSection: React.FC<CreateSectionProps> = ({ children }) => (
  <section className='lag-ny'>{children}</section> //TODO Possible put FormProvider here
);
