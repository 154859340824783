import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button/Button';
import { AdminRoleSelector } from '../../../components/Forms/AdminRoleSelector';
import { KeyId } from '../../../components/Forms/KeyId';
import { KeySelect } from '../../../components/Forms/KeySelect';
import { KeyText } from '../../../components/Forms/KeyText';
import {
  CreateAdmissionCardType,
  createAdmissionCardType
} from '../../../redux/actions/admissionCards';
import { dispatchAndHandleResult } from '../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { historyGoBack } from '../../../utils/history';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

interface Option {
  labelText: string;
  value: string;
}

export const CreateAdmissionCard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const actionChains = useAppSelector(state => state.adapters.actionChains);

  const methods = useForm<CreateAdmissionCardType>({
    resolver: zodResolver(CreateAdmissionCardType)
  });

  const { handleSubmit } = methods;

  const options: Option[] = actionChains.map(actionChain => {
    return {
      labelText: actionChain.name,
      value: actionChain.id,
      description: actionChain.id
    };
  });
  const onSave = (data: CreateAdmissionCardType) =>
    dispatchAndHandleResult(dispatch, createAdmissionCardType, data, () => {
      historyGoBack(navigate);
    });

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs currentPageName='Lag ny type adganskort' />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSave)}>
          <div>
            <KeyText
              label='Navn'
              name='publicName'
              description='Navn som er synlig i appen'
              elementProps={{
                type: 'text'
              }}
            />
            <KeyId
              label='ID'
              name='id'
              otherField='publicName'
              description='Intern id, sett denne kort og beskrivende. F.eks. deichman-medlem'
              elementProps={{
                type: 'text'
              }}
            />
            <AdminRoleSelector
              description='Avgjør hvem som kan redigere administrere adgangskort og se statistikk'
              name='adminRole'
            />
            <KeySelect
              label='ActionChain'
              name='actionChainId'
              description='Hvilke action(s) som skal utføres når noen henter kortet i appen. Må peke på en chain hvor minst en av stegene returnerer en kode.'
              elementProps={{
                placeholder: 'Velg en ActionChain',
                options: options,
                isMulti: false
              }}
            />
          </div>
          <div className='button-row'>
            <Button label='Avbryt' type='secondary' onClick={onCancel} />
            <Button label='Opprett' type='submit' />
          </div>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};
