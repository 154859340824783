import React from 'react';
import { useFormContext } from 'react-hook-form';
import { generateClasses, KeyComponentProps, KeyWrapper } from './KeyWrapper';

export interface KeyTextareaType extends KeyComponentProps {
  elementProps: React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >;
}
export const KeyTextarea: React.FC<KeyTextareaType> = ({ name, ...props }) => {
  const { inputClassNames } = generateClasses({ ...props, type: 'textarea' });
  const { register } = useFormContext();
  const { elementProps, ...wrapperProps } = props;
  return (
    <KeyWrapper name={name} {...wrapperProps}>
      <textarea
        {...register(name)}
        id={name}
        rows={5}
        {...elementProps}
        className={inputClassNames}
      />
    </KeyWrapper>
  );
};
