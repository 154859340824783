import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DialogBoxType } from '../../../../components/DialogBox/useDialogBox';
import { deleteKeychainExpireDateRequest } from '../../../../redux/actions/keychains';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { Keychain, KeychainStatus } from '../../../../redux/slice/keychains';
import { getKeystudioLink } from '../../../../utils/citykeyId';

interface Props {
  keychain?: Keychain;
  keychainFactoryUri: string;
  DialogBox: DialogBoxType;
  closeModal: () => void;
}

export const DeleteApprovedKeychains: React.FC<Props> = ({
  keychain,
  DialogBox,
  closeModal,
  keychainFactoryUri
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const removeUserClick = useMemo(() => {
    if (!keychain) {
      return {
        onClick: () => {}
      };
    }
    const { status } = keychain;
    return {
      label:
        status === KeychainStatus.EXPIRED ? 'Slett bruker' : 'Fjern bruker',
      onClick: () =>
        dispatchAndHandleResult(
          dispatch,
          deleteKeychainExpireDateRequest,
          {
            keychainId: keychain.id,
            keychainFactoryUri,
            expiredInsteadOfDelete: status !== KeychainStatus.EXPIRED
          },
          () => {
            closeModal();
            if (status === KeychainStatus.EXPIRED) {
              navigate(
                getKeystudioLink(keychainFactoryUri) + '/tilgangskontroll'
              );
            }
          }
        )
    };
  }, [closeModal, dispatch, keychain, keychainFactoryUri]);

  return (
    <DialogBox
      buttonLabel={removeUserClick?.label}
      buttonType='destructive'
      onSubmit={removeUserClick.onClick}
      header={keychain?.personName}
    >
      Ønsker du å{' '}
      {keychain?.status === KeychainStatus.EXPIRED ? 'slette' : 'fjerne'}{' '}
      brukeren?
    </DialogBox>
  );
};
