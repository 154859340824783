import { useState } from 'react';

export const useSort = <T,>(
  initialSortKey: '' | keyof T = '',
  initialSortDirection = false
) => {
  const [sortKey, setSortKey] = useState<'' | keyof T>(initialSortKey);
  const [sortReversed, setSortReversed] = useState(initialSortDirection);

  const handleSortKeyChange = (key: keyof T) => {
    if (sortKey === key) {
      setSortReversed(prevDirection => !prevDirection);
    } else {
      setSortKey(key);
      setSortReversed(false);
    }
  };

  return [sortKey, sortReversed, handleSortKeyChange] as const;
};
