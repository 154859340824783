import React from 'react';

export const FactoriesSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21.19'
    height='24'
    viewBox='0 0 21.19 24'
  >
    <g id='Group_12274' data-name='Group 12274' transform='translate(0)'>
      <g
        id='Group_12264'
        data-name='Group 12264'
        transform='translate(6.242 0)'
      >
        <path
          id='Path_2943'
          data-name='Path 2943'
          d='M60.538-219.869h3.436v1.756H62.256v.005H62.25a.865.865,0,0,0-.856.872.864.864,0,0,0,.856.872h.006v.007h1.719v1.756H60.538v1.756H58.821v-12.38a4.35,4.35,0,0,1-3.387-3.643,4.377,4.377,0,0,1,3.6-5,4.309,4.309,0,0,1,4.89,3.682,4.383,4.383,0,0,1-3.387,4.96Zm1.755-9.657a2.645,2.645,0,0,0-2.614-2.672,2.645,2.645,0,0,0-2.614,2.672,2.645,2.645,0,0,0,2.614,2.672A2.645,2.645,0,0,0,62.294-229.527Z'
          transform='translate(-55.384 233.917)'
        />
      </g>
      <path
        id='Ellipse_756'
        data-name='Ellipse 756'
        d='M10.595,2a8.7,8.7,0,0,0,0,17.39,8.7,8.7,0,0,0,0-17.39m0-2A10.7,10.7,0,1,1,0,10.695,10.645,10.645,0,0,1,10.595,0Z'
        transform='translate(0 2.61)'
      />
    </g>
  </svg>
);
