import { formatDistanceToNow } from 'date-fns';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ActionMenuItemProps } from '../../../../components/ActionMenu/ActionMenuItem';
import { TableProps } from '../../../../components/Table/Table';
import { HeadingProps } from '../../../../components/Table/TableHeader';
import { sortData } from '../../../../components/Table/sortUtil';
import { useSort } from '../../../../components/Table/useSort';
import { isSuperAdmin } from '../../../../components/util/RenderIfSuperAdmin';
import { useAppSelector } from '../../../../redux/hooks';
import {
  GrantedBy,
  Keychain,
  keychainsSelectors,
  KeychainStatus
} from '../../../../redux/slice/keychains';
import { getKeystudioLink } from '../../../../utils/citykeyId';
import { storForbokstav } from '../../../../utils/language';
import {
  calculateDaysBetween,
  formatDate
} from '../../../../utils/timeConverter';

export const keychainDataGenerator = (
  setActiveKeychainId: (keychain: string) => void,
  openEditModal: () => void,
  openDeleteModal: () => void,
  openEmailModal: () => void,
  openRolesModal: () => void
): TableProps<Keychain> => {
  const keychains = useAppSelector(keychainsSelectors.selectAll);
  const [sortKey, sortReversed, setSortKey] = useSort<Keychain>();
  const [sortedKeychains, setSortedKeychains] = React.useState<Keychain[]>([]);
  useEffect(() => {
    setSortedKeychains(sortData(keychains, sortKey, sortReversed));
  }, [sortKey, sortReversed, keychains]);

  const isShareable =
    isSuperAdmin() &&
    keychains.some(keychain => keychain.policies.setShareable);

  const knownSource = keychains.some(
    keychain => keychain.grantedBy.type !== 'unknown'
  );

  const rows = sortedKeychains.map((keychain, keyIndex) => {
    const {
      status,
      fromDate,
      untilDate,
      usageCounter,
      lastUsedAtEpochSeconds
    } = keychain;
    const statusText = generateStatusText(status, fromDate, untilDate);

    let fromDateCell = <span />;
    if (fromDate) {
      fromDateCell = (
        <span
          className={classNames({
            'in-future': new Date(fromDate) > new Date()
          })}
        >
          {formatDate(new Date(fromDate))}
        </span>
      );
    }

    const actions = generateActionMenuItems(
      keychain,
      openEditModal,
      openDeleteModal,
      openEmailModal,
      openRolesModal,
      setActiveKeychainId
    );

    const cells = [
      <span className='capitalized' key={keyIndex}>
        {keychain.personName.toLowerCase()}
      </span>,
      fromDateCell,
      untilDate ? formatDate(new Date(untilDate)) : '???',
      statusText,
      generateUsageCounterText(usageCounter),
      generateLastUsedText(lastUsedAtEpochSeconds)
    ];

    if (isShareable) {
      cells.push(keychain.shareable ? 'Ja' : 'Nei');
    }

    if (knownSource) {
      cells.push(generateGratedByText(keychain.grantedBy, false));
    }

    return {
      cells: cells,
      link: keychain.profileId,
      actions: actions,
      className: keychain.grantedBy.type === 'user' ? 'user-generated' : ''
    };
  });

  const headings: HeadingProps<Keychain>[] = [
    { element: 'Navn', sortKey: 'personName' },
    { element: 'Tilgang fra', sortKey: 'fromDate' },
    { element: 'Tilgang til', sortKey: 'untilDate' },
    { element: 'Status' },
    { element: 'Antall ganger brukt', sortKey: 'usageCounter' },
    { element: 'Sist brukt', sortKey: 'lastUsedAtEpochSeconds' }
  ];

  if (isShareable) {
    headings.push({ element: 'Kan deles' });
  }
  if (knownSource) {
    headings.push({ element: 'Tilgang gitt av', sortKey: 'grantedBy' });
  }

  return {
    tableClassName: 'approved-keychains-table',
    emptyTableText: <p>Det er ingen som har tilgang.</p>,
    headings: headings,
    rows,
    sortKey,
    setSortKey,
    sortReversed
  };
};

export const generateUsageCounterText = (usageCounter: number) => {
  if (usageCounter === 0) {
    return 'Ikke brukt';
  } else if (usageCounter === 1) {
    return 'En gang';
  } else if (usageCounter < 10) {
    return `Noen få ganger`;
  } else if (usageCounter < 100) {
    return `Titalls ganger`;
  } else {
    return `Mange ganger`;
  }
};

export const generateLastUsedText = (lastUsedAtEpochSeconds: number) => {
  if (lastUsedAtEpochSeconds) {
    const text = formatDistanceToNow(lastUsedAtEpochSeconds * 1000, {
      addSuffix: true,
      includeSeconds: false
    });
    return storForbokstav(text);
  }
  return 'Aldri brukt';
};

export const generateStatusText = (
  status: KeychainStatus,
  fromDate: string,
  untilDate: string
) => {
  switch (status) {
    case KeychainStatus.APPROVED:
      if (untilDate) {
        const daysLeft = calculateDaysBetween(new Date(untilDate), new Date());
        if (new Date(fromDate) > new Date()) {
          return <span className='in-future'>Ikke aktiv ennå</span>;
        } else if (daysLeft === 0) {
          return <span className='warning-text'>Utløper i dag</span>;
        } else if (daysLeft === 1) {
          return <span className='warning-text'>Utløper i morgen</span>;
        } else if (daysLeft < 10) {
          return (
            <span className='warning-text'>Utløper om {daysLeft} dager</span>
          );
        } else {
          return 'Aktiv';
        }
      }
      break;
    case KeychainStatus.EXPIRED:
      return <span className='warning-text'>Utløpt</span>;
    default:
      return status;
  }
};

export const generateGratedByText = (
  grantedBy: GrantedBy,
  withLink: boolean
) => {
  const text = grantedBy.name;
  let content = <span>{text}</span>;
  if (!withLink && grantedBy.type === 'admin') {
    content = (
      <span>{text.replace('@', '@\u200B').replace('.', '.\u200B')}</span>
    );
  }
  if (grantedBy.type === 'user') {
    content = <span className='capitalized'>{text}</span>;
  }
  if (withLink && grantedBy.type === 'user') {
    return (
      <Link
        to={getKeystudioLink(grantedBy.keychainId)}
        className='underline-link'
      >
        {content}
      </Link>
    );
  }
  return content;
};

export const generateActionMenuItems = (
  keychain: Keychain,
  openEditModal: () => void,
  openDeleteModal: () => void,
  openEmailModal: () => void,
  openRolesModal: () => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setActiveKeychainId = (keychain: string) => {}
) => {
  const actions: ActionMenuItemProps[] = [];
  if (!keychain) return [];
  const { policies, id } = keychain;
  if (policies.extend) {
    actions.push({
      type: 'time',
      description: 'Endre tilgang',
      onClick: () => {
        setActiveKeychainId(id);
        openEditModal();
      }
    });
  }
  if (policies.replaceRoles) {
    actions.push({
      type: 'edit',
      description: 'Endre roller',
      onClick: () => {
        setActiveKeychainId(id);
        openRolesModal();
      }
    });
  }
  if (policies.setEmail) {
    actions.push({
      type: 'email',
      description: 'Endre e-postadresse',
      onClick: () => {
        setActiveKeychainId(id);
        openEmailModal();
      }
    });
  }
  if (policies.revoke) {
    actions.push({
      type: 'delete',
      onClick: () => {
        setActiveKeychainId(id);
        openDeleteModal();
      }
    });
  }
  return actions;
};
