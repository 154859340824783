import {
  AccessControl,
  Action,
  Adapters,
  Bucket,
  Building,
  Categories,
  GodMode,
  ManualGrantChains,
  PublicChains,
  PVD,
  Super,
  ThreeDots,
  Web
} from '../../assets/CircledIcons';
import { FAQ } from '../../assets/CircledIcons/FAQ';
import { controlPanelHome, NavPath } from './navPaths';

const getFullPath = (path: string): string => {
  if (path === controlPanelHome.path) return path;
  return `${controlPanelHome.path}${path}`;
};

export const getRelativePath = (navpath: NavPath): string => {
  if (navpath.path === controlPanelHome.path) return navpath.path;
  return navpath.path.replace(controlPanelHome.path, '');
};
export const adminAdmissionCardsHome: NavPath = {
  name: 'Adgangskort',
  path: getFullPath('/adgangskort'),
  Image: AccessControl
};

export const actionsHome: NavPath = {
  name: 'ActionChain',
  path: getFullPath('/actionchains'),
  Image: Action
};
export const pvdHome: NavPath = {
  name: 'Nærhets­enheter',
  path: getFullPath('/pvd'),
  Image: PVD
};
export const thirdPartySystemHome: NavPath = {
  name: 'Tredjeparts­systemer',
  path: getFullPath('/tredjepart'),
  Image: ThreeDots
};
export const faqHome: NavPath = {
  name: 'FAQ',
  path: getFullPath('/faq'),
  Image: FAQ
};
export const downloadsHome: NavPath = {
  name: 'Data og Logger',
  path: getFullPath('/LogsAndData'),
  Image: Super
};
export const adminOidcHome: NavPath = {
  name: 'Web login',
  path: getFullPath('/web-login'),
  Image: Web
};

export const adminKeychainHome: NavPath = {
  name: 'Tilganger fra KeyStudio',
  path: getFullPath('/keystudio'),
  Image: ManualGrantChains
};
export const adminPublicKeychainHome: NavPath = {
  name: 'Offentlige tilgangslister',
  path: getFullPath('/public'),
  Image: PublicChains
};
export const adminSubjectHome: NavPath = {
  name: 'Steder',
  path: getFullPath('/steder'),
  Image: Building
};
export const adminCategoryHome: NavPath = {
  name: 'Kategorier',
  path: getFullPath('/kategori'),
  Image: Categories
};
export const adminAdapterHome: NavPath = {
  name: 'Adapter',
  path: getFullPath('/adapter'),
  Image: Adapters
};
export const adminBucketHome: NavPath = {
  name: 'Operasjonsspann',
  path: getFullPath('/buckets'),
  Image: Bucket
};
export const godModeHome: NavPath = {
  name: 'Godmode',
  path: getFullPath('/godmode'),
  Image: GodMode
};
