import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  fetchKeychainFactoryDetails,
  listUsersWithRole
} from '../actions/keychainFactoryDetails';
import { RootState } from '../index';

export interface RoleDefinition {
  id: string;
  name: string;
  description: string;
  uri: string;
  entries: RoleEntry[];
}

export type RoleEntry = {
  keychainUri: string;
  fullName: string;
};

const RoleAdapter = createEntityAdapter<RoleDefinition>();

const rolesSlice = createSlice({
  name: 'roles',
  initialState: RoleAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(listUsersWithRole.fulfilled, (state, { payload }) => {
        const roleId = payload.roleUri.split('/').pop() || '';
        if (payload?.entries && roleId) {
          RoleAdapter.updateOne(state, {
            id: roleId,
            changes: {
              entries: payload.entries
            }
          });
        }
      })
      .addCase(fetchKeychainFactoryDetails.fulfilled, (state, { payload }) => {
        if (payload?.oidc?.roleDefinitionsList) {
          RoleAdapter.setAll(
            state,
            payload.oidc.roleDefinitionsList.map(role => {
              return {
                ...role,
                entries: []
              };
            })
          );
        }
      });
  }
});

export const initialState = rolesSlice.getInitialState();
export type RolesState = typeof initialState;
export default rolesSlice.reducer;
export const rolesSelectors = RoleAdapter.getSelectors<RootState>(
  state => state.roles
);
