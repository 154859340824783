import React from 'react';
import { ActionMenu } from '../ActionMenu/ActionMenu';
import { ActionMenuItemProps } from '../ActionMenu/ActionMenuItem';
import './RowAction.scss';

interface Props {
  rowActions: ActionMenuItemProps[];
}
export const RowActionMenu: React.FC<Props> = ({ rowActions }) => {
  return <ActionMenu actions={rowActions} />;
};
