import { KeychainFactorySummary } from '../../redux/slice/keychainFactorySummaries';
import { filterByType } from './filterByType';
import { getKeychainFactoryLink } from './getKeychainFactoryAssets';

export const filterKeychainFactories = (
  keychainFactories: KeychainFactorySummary[],
  search: string,
  keychainType: string
) => {
  const lowerCaseSearch = search.toLocaleLowerCase();
  const filteredByType = filterByType(keychainFactories, keychainType);
  return filteredByType.filter(factory => {
    return (
      getKeychainFactoryLink(factory).includes(lowerCaseSearch) ||
      factory.name.toLocaleLowerCase().includes(lowerCaseSearch) ||
      factory.linkedSubjects.some(subject => {
        return subject.subjectName
          .toLocaleLowerCase()
          .includes(lowerCaseSearch);
      })
    );
  });
};
