import React from 'react';
import { Term } from '../../../../redux/slice/terms';
import { CheckboxElement } from '../../../Forms/Checkbox';

interface Props {
  term: Term;
  selectedTermIds: string[];
  setSelectedTermIds: (termIds: string[]) => void;
}

export const SelectTermList: React.FC<Props> = ({
  term,
  selectedTermIds,
  setSelectedTermIds
}) => {
  const selected = selectedTermIds.includes(term.id);

  return (
    <CheckboxElement
      description={term.id}
      label={term.title}
      elementProps={{
        checked: selected,
        onChange: () => {
          selected
            ? setSelectedTermIds(selectedTermIds.filter(t => t !== term.id))
            : setSelectedTermIds([...selectedTermIds, term.id]);
        }
      }}
    />
  );
};
