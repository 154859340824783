import {
  FetchFlightRecordingRequest,
  FetchFlightRecordingResponse
} from '../../proto/flightrecording_pb';
import {
  getFlightRecordingClient,
  getGrpcMetadata,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';

export const fetchFlightRecording = createAppAsyncThunk<
  FetchFlightRecordingResponse.AsObject,
  { flightRecordingId: string }
>(
  'flightrecordings/fetchFlightRecording',
  async ({ flightRecordingId }, thunkAPI) => {
    try {
      const request = new FetchFlightRecordingRequest().setId(
        flightRecordingId
      );
      const response = await getFlightRecordingClient().fetchFlightRecording(
        request,
        await getGrpcMetadata()
      );
      return response.toObject();
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);
