import React from 'react';
import { KeychainFactorySummary } from '../../../redux/slice/keychainFactorySummaries';
import { groupBySubject } from '../../../utils/keychainFactory/groupBySubject';
import { KeychainFactoryCards } from './KeychainFactoryCards/KeychainFactoryCards';
import './KeychainFactoryCardsContainer.scss';

interface Props {
  keychainFactories: KeychainFactorySummary[];
}

export const KeychainFactoryCardsContainer: React.FC<Props> = ({
  keychainFactories
}) => {
  const groupedKeychainFactories = groupBySubject(keychainFactories);

  return (
    <div className='keychain-factory-cards-container'>
      {keychainFactories.length > 0 ? (
        <React.Fragment>
          <KeychainFactoryCards
            groupedKeychainFactories={groupedKeychainFactories}
          />
        </React.Fragment>
      ) : (
        <p>Finner ingen tilgangslister.</p>
      )}
    </div>
  );
};
