import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  KeychainFactoryAdminPolicy,
  KeychainFactoryDetailsResponse
} from '../../proto/keychain-factory_pb';
import { KeychainStatus } from '../../proto/keychain_pb';
import {
  LocalTimeInterval,
  OpeningHours as OpeningHoursClass,
  OperationEntity
} from '../../proto/shared_pb';
import {
  changeKeychainFactoryAdminRole,
  fetchKeychainFactoryDetails,
  replaceAllowedOrigins,
  replaceOidcRedirects,
  setSessionLifespan,
  updateKeychainFactoryNameRequest
} from '../actions/keychainFactoryDetails';
import { fetchListKeychains } from '../actions/keychains';
import { findOperationType } from '../actions/operations';
import { RootState } from '../index';
import {
  flattenInterval,
  OpeningHours,
  policiesListToModel,
  TimeInterval,
  TimeIntervalsList
} from '../types/common';

export type CommonKeychainFactoryDetails = {
  keychainFactoryUri: string;
  name: string;
  approvedKeychains: number;
  expiredKeychains: number;
  version: number;
  grantedOperationsList: OperationInfo[];
  ownerRole: string;
  policiesList: ReturnType<
    typeof policiesListToModel<KeychainFactoryAdminPolicy.AsObject>
  >;
  image: string;
  isDetails: true;
};
export interface PublicKeychainFactoryDetails
  extends CommonKeychainFactoryDetails {
  type: 'public';
  openingHours: OpeningHours;
  openingHoursSpec: string;
}
export interface ManualGrantKeychainFactoryDetails
  extends CommonKeychainFactoryDetails {
  type: 'manual-grant';
  publicName: string;
  openingHours: OpeningHours;
}

export interface PushKeychainFactoryDetails
  extends CommonKeychainFactoryDetails {
  type: 'push';
  thirdPartySystemId: string;
  thirdPartySystemName: string;
  activeInterval: TimeInterval;
  pendingRecipients: PendingRecipient[];
  confirmedRecipients: ConfirmedRecipient[];
  permissions: Permission[];
}

export interface Permission extends TimeInterval {
  assetIdsList: string[];
}

export interface OidcKeychainFactoryDetails
  extends CommonKeychainFactoryDetails {
  type: 'oidc';
  redirectUrisList: string[];
  roleDefinitionsList: RoleDefinition[];
  allowedOriginsList: string[];
  sessionLifespanMinutes: number;
}
export interface UnknownKeychainFactoryDetails
  extends CommonKeychainFactoryDetails {
  type: 'unknown';
}

export type RoleDefinition = {
  id: string;
  name: string;
  description: string;
  uri: string;
};

export type KeychainFactoryDetails =
  | PublicKeychainFactoryDetails
  | ManualGrantKeychainFactoryDetails
  | PushKeychainFactoryDetails
  | OidcKeychainFactoryDetails
  | UnknownKeychainFactoryDetails;

export type OperationInfo = {
  subjectId: string;
  operationId: string;
  name: string;
  type: OperationEntity.EntityCase;
};

type PhoneNumber = {
  countryCode: string;
  phoneNumber: string;
};

type PendingRecipient = {
  phoneNumber: PhoneNumber;
  receivedTimestampMillis: number;
};
type ConfirmedRecipient = {
  phoneNumber: PhoneNumber;
  personName: unknown;
  receivedTimestampMillis: number;
  associatedTimestampMillis: number;
};

const emptyOpeningHours: OpeningHours = {
  alwaysOpen: false,
  closedDateRulesList: [],
  dateOverrideRulesList: [],
  simpleDayRulesList: [],
  spec: ''
};

const keychainFactoryAdapter = createEntityAdapter<
  KeychainFactoryDetails,
  string
>({
  selectId: keychainFactory => keychainFactory.keychainFactoryUri
});

const keychainFactoriesSlice = createSlice({
  name: 'keychainFactoryDetails',
  initialState: keychainFactoryAdapter.getInitialState({
    isLoading: false
  }),
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchKeychainFactoryDetails.fulfilled, (state, { payload }) => {
        keychainFactoryAdapter.setOne(state, factoryDetailsToModel(payload));
        state.isLoading = false;
      })
      .addCase(fetchKeychainFactoryDetails.pending, state => {
        state.isLoading = true;
      })
      .addCase(fetchKeychainFactoryDetails.rejected, state => {
        state.isLoading = false;
      })
      .addCase(
        updateKeychainFactoryNameRequest.fulfilled,
        (state, { payload }) => {
          keychainFactoryAdapter.updateOne(state, {
            id: payload.id,
            changes: {
              name: payload.newName
            }
          });
        }
      )
      .addCase(
        changeKeychainFactoryAdminRole.fulfilled,
        (state, { payload }) => {
          keychainFactoryAdapter.updateOne(state, {
            id: payload.keychainFactoryUri,
            changes: {
              ownerRole: payload.newOwnerRole
            }
          });
        }
      )
      .addCase(fetchListKeychains.fulfilled, (state, { payload }) => {
        keychainFactoryAdapter.updateOne(state, {
          id: payload.uri,
          changes: {
            approvedKeychains: payload.keychainsList.filter(
              value => value.status === KeychainStatus.APPROVED
            ).length
          }
        });
      })
      .addCase(setSessionLifespan.fulfilled, (state, { payload }) => {
        keychainFactoryAdapter.updateOne(state, {
          id: payload.keychainFactoryUri,
          changes: {
            sessionLifespanMinutes: payload.sessionLifespanMinutes
          }
        });
      })
      .addCase(replaceOidcRedirects.fulfilled, (state, { payload }) => {
        keychainFactoryAdapter.updateOne(state, {
          id: payload.keychainFactoryUri,
          changes: {
            redirectUrisList: payload.redirectUriList
          }
        });
      })
      .addCase(replaceAllowedOrigins.fulfilled, (state, { payload }) => {
        keychainFactoryAdapter.updateOne(state, {
          id: payload.keychainFactoryUri,
          changes: {
            allowedOriginsList: payload.allowedOriginsList
          }
        });
      });
  }
});

export const initialState = keychainFactoriesSlice.getInitialState();
export type KeychainFactoryDetailsState = typeof initialState;
export const selectKeychainFactoryDetails =
  keychainFactoryAdapter.getSelectors<RootState>(
    state => state.keychainFactoryDetails
  );
export default keychainFactoriesSlice.reducer;

const operationSummaryToModel = (
  operation: KeychainFactoryDetailsResponse.GrantedOperation.AsObject
): OperationInfo => ({
  name: operation.name,
  subjectId: operation.operationId?.subjectId || '',
  operationId: operation.operationId?.operationId || '',
  type: findOperationType(operation.entityType)
});

const factoryDetailsToModel = (
  details: KeychainFactoryDetailsResponse.AsObject
): KeychainFactoryDetails => {
  const common = {
    keychainFactoryUri: details.keychainFactoryUri,
    name: details.name,
    approvedKeychains: details.approvedKeychains,
    expiredKeychains: details.expiredKeychains,
    version: details.version,
    ownerRole: details.ownerRole,
    isDetails: true,
    image: details.image?.uri || '',
    grantedOperationsList: details.grantedOperationsList.map(
      operationSummaryToModel
    ),
    policiesList: policiesListToModel(details.policiesList)
  } as const;
  if (details.pb_public) {
    return {
      ...common,
      type: 'public',
      openingHours:
        openingHoursToModel(details.pb_public.openingHours) ||
        emptyOpeningHours,
      openingHoursSpec: details.pb_public.openingHoursSpec || ''
    };
  }
  if (details.manualGrantKeychainFactory) {
    return {
      ...common,
      type: 'manual-grant',
      ...manualGrantFactoryDetailsToModel(details.manualGrantKeychainFactory)
    };
  }
  if (details.pushFactoryDetails) {
    return {
      ...common,
      type: 'push',
      ...pushFactoryDetailsToModel(details.pushFactoryDetails)
    };
  }
  if (details.oidc) {
    return {
      ...common,
      type: 'oidc',
      ...oidcFactoryDetailsToModel(details.oidc)
    };
  }
  return { ...common, type: 'unknown' };
};

const manualGrantFactoryDetailsToModel = (
  details: KeychainFactoryDetailsResponse.ManualGrantKeychainFactoryDetails.AsObject
) => ({
  ...details,
  openingHours: openingHoursToModel(details.openingHours) || emptyOpeningHours
});

const pushFactoryDetailsToModel = (
  details: KeychainFactoryDetailsResponse.PushFactoryDetails.AsObject
) => ({
  activeInterval: flattenInterval(details.activeInterval),
  pendingRecipients: details.pendingRecipientsList.map(recipient => ({
    phoneNumber: {
      phoneNumber: recipient.phoneNumber?.number || '',
      countryCode: recipient.phoneNumber?.countryCode || ''
    },
    receivedTimestampMillis:
      recipient.receivedFromThirdPartySystemAtTimestamp?.epochMillis || 0
  })),
  confirmedRecipients: details.confirmedRecipientsList.map(recipient => ({
    phoneNumber: {
      phoneNumber: recipient.phoneNumber?.number || '',
      countryCode: recipient.phoneNumber?.countryCode || ''
    },
    personName: recipient.personName,
    receivedTimestampMillis:
      recipient.receivedFromThirdPartySystemAtTimestamp?.epochMillis || 0,
    associatedTimestampMillis:
      recipient.associatedWithProfileAtTimestamp?.epochMillis || 0
  })),
  permissions: details.permissionsList.map(permission => ({
    assetIdsList: permission.assetIdsList,
    ...flattenInterval(permission.interval)
  })),

  thirdPartySystemId: details.thirdPartySystemId,
  thirdPartySystemName: details.thirdPartySystemName
});

const oidcFactoryDetailsToModel = (
  details: KeychainFactoryDetailsResponse.OidcFactorySummary.AsObject
) => {
  return {
    ...details,
    roleDefinitionsList: details.roleDefinitionsList.map(role => ({
      ...role
    }))
  };
};

export const openingHoursToModel = (
  openingHours?: OpeningHoursClass.AsObject
): OpeningHours | undefined => {
  if (openingHours !== undefined) {
    return OpeningHours.parse({
      alwaysOpen: openingHours.alwaysOpen,
      closedDateRulesList: openingHours.closedDateRulesList.map(rule => ({
        date: rule.date?.dateString || ''
      })),
      dateOverrideRulesList: openingHours.dateOverrideRulesList.map(rule => ({
        date: rule.date?.dateString || '',
        timeInterval: rule.timeIntervalsList.map(convertToTimeIntervals)
      })),
      simpleDayRulesList: openingHours.simpleDayRulesList.map(rule => ({
        day: rule.day,
        timeInterval: rule.timeIntervalsList.map(convertToTimeIntervals)
      })),
      spec: openingHours.spec
    });
  }
};

const convertToTimeIntervals = (timeIntervals: LocalTimeInterval.AsObject) => {
  return TimeIntervalsList.parse({
    from: timeIntervals.from?.timeString,
    until: timeIntervals.until?.timeString
  });
};
