import React, { Fragment } from 'react';
import TeamImage from '../../assets/teamet.jpg';
import { Breadcrumbs } from '../../components/Navigation/breadcrumbs';
import { helpHome } from '../../components/Navigation/navPaths';
import { ContactForm } from './ContactForm';
import './Help.scss';
import { HelpSection } from './HelpSection/HelpSection';

export const HelpPage = () => {
  return (
    <Fragment>
      <Breadcrumbs breadcrumbs={[helpHome]} />
      <section className='help'>
        <h2>Ofte stilte spørsmål</h2>

        <HelpSection title='Hva gjør man hvis noe ikke fungerer? Få hjelp til selvhjelp her.'>
          <p>
            Her er en sjekkliste du kan gå gjennom når noe er galt, for eksempel
            hvis du får beskjed fra en bruker om at Oslonøkkelen ikke fungerer
            på stedet deres.
          </p>
          <h3>1. Sjekk om noe er rødt:</h3>
          <p>
            Sjekk tilgangslistene deres: Hvis de er røde (i stedet for grønne),
            betyr det at noe er galt. Da ser du også en feilmelding som
            beskriver hva som er galt. Hvis alt er grønt, men brukere likevel
            melder om feil: Fortsett videre til du finner løsningen.
          </p>
          <h3>2. Sjekk innstillinger og app:</h3>
          <ul>
            <li>
              <p>
                Sjekk at åpningstidene dere har lagt inn for stedet her i
                KeyStudio, er korrekte. Åpningstidene er dager og tider der
                brukere kan bruke Oslonøkkelen i deres lokale.
              </p>
            </li>
            <li>
              <p>
                Åpne Oslonøkkelen-appen på telefonen din: Sjekk om døren/stedet
                faktisk er synlig der for brukerne. Hvis ikke, kan det hende
                døren (låsen) eller stedet er definert som «skjult» i appen.
                Dette kan du selv endre hvis du klikker deg inn på den aktuelle
                døren eller stedet.
              </p>
            </li>
            <li>
              <p>Sjekk selv om du kan åpne døren med appen.</p>
            </li>
          </ul>
          <h3>3. Sjekk døra:</h3>
          <ul>
            <li>
              <p>
                Sjekk om du kan åpne døren fysisk med alternativer som nøkkel
                eller adgangskort. Hvis du oppdager at det er en mekanisk feil
                på døren eller låsen, må dere kontakte låsesmed. En mekanisk
                feil kan for eksempel være en sikkerhetslås som ikke er koblet
                til Oslonøkkelen. Hvis denne er låst, går døren naturlig nok
                ikke opp med Oslonøkkelen. Det er dere som selv har ansvaret for
                at dørene fungerer som de skal.
              </p>
            </li>
            <li>
              <p>
                Trekk ut og sett inn igjen strømkabelen til Oslonøkkelen-boksen.
                Vent i 60 sekunder før du prøver å åpne døren på nytt med
                Oslonøkkelen-appen.
              </p>
            </li>
            <li>
              <p>
                Hvis du hører et klikk fra låsen når du åpner døren med appen,
                men døren likevel ikke åpner seg, kan døren være satt i
                nattstilling eller lignende. Dette er vanligvis noe vaktmester
                kan fikse. Låsesmed vil også kunne endre dette.
              </p>
            </li>
          </ul>
          <p>
            Står du fortsatt fast? Send oss gjerne en melding via
            kontaktskjemaet nedenfor. Lykke til!
          </p>
        </HelpSection>

        <HelpSection title='Om KeyStudio'>
          <h3>Hva er KeyStudio?</h3>
          <p>
            KeyStudio er Oslonøkkelens administratorløsning. Her kan du som
            tjenesteeier administrere alt som har med dine tilgangslister.
          </p>

          <h3>Hva kan jeg administrere i KeyStudio?</h3>
          <ul>
            <li>
              <p>
                Åpningstider: Bestem når steder/dører skal kunne åpnes med
                Oslonøkkelen.
              </p>
            </li>
            <li>
              <p>
                Tilgangsstyring: Gi, fjern og endre tilganger for dem som skal
                åpne stedet med Oslonøkkelen.
              </p>
            </li>
            <li>
              <p>
                Sletting av tilgangslister: Fjern hele lister med personer med
                egendfinerte tilganger.
              </p>
            </li>
            <li>
              <p>
                Informasjon om stedet: Rediger tekstene innbyggeren ser i
                Oslonøkkelen-appen.
              </p>
            </li>
            <li>
              <p>
                Bilde av stedet: Legg til og endre bildet innbyggeren ser i
                appen.
              </p>
            </li>
          </ul>

          <h3>
            Hvordan legger jeg til flere administratorbrukere for KeyStudio?
          </h3>
          <p>
            Du har allerede tilgang (siden du leser dette), men det er ofte lurt
            at flere har tilgang til KeyStudio. Send oss den nye
            administratorens navn og e-postadresse via kontaktskjemaet nedenfor.
            Da sender vi en e-post til den nye administratoren og forklarer
            hvordan man logger inn første gangen. Deretter kan personen logge
            inn og begynne å administrere.
          </p>

          <h3>Hva er en tilgangsliste?</h3>
          <p>
            En tilgangsliste er en eller flere tilganger som kan åpne ting i
            Oslonøkkelen-appen. Alle Oslonøkkelens tilganger er styrt via
            tilgangslister. En tilgangsliste kan for eksempel gi tilgang til å
            åpne en eller flere dører i et bydelslokale i bestemte tidsrom. Dere
            bestemmer hvem som skal kunne bruke hvilke tilgangslister.
          </p>

          <h3>Hvordan bestemmer jeg hva en tilgangsliste skal åpne?</h3>
          <p>
            Trykk på legg/til fjern låser inne på en tilgangsliste for å
            bestemme hva den skal gi tilgang til. Lurer du på noe mer? Send oss
            en melding via kontaktskjemaet nedenfor.
          </p>

          <h3>Hvordan lar jeg innbyggere bruke en tilgangsliste?</h3>
          <p>
            Først må du gi tilgangslisten åpningstider, hvis ikke vil det være
            stengt hele tiden. Innbyggeren(e) må også ha tilgang til
            tilgangslisten. Du kan gi, fjerne og endre tilganger under
            «Tilgangshåndtering». For å gi noen tilgang til tilgangslisten, må
            du legge inn personens mobilnummer. Personen må på forhånd ha logget
            seg inn i Oslonøkkelen-appen og lagt til mobilnummeret sitt for at
            du skal kunne gi personen tilgang. Når alt dette er i orden, vil
            stedet dukke opp under riktig kategori i Oslonøkkelen-appen til
            brukere som har tilgang.
          </p>
          <h3>Hvordan stenger jeg et sted for Oslonøkkelen-brukere?</h3>
          <p>
            Noen ganger er det nødvendig å stenge et sted for for en kortere
            eller lengre periode. Gå inn på stedet du ønsker å stenge, og trykk
            på den knappen som heter «Skjul fra appen». Da blir dette stedet
            skjult for brukerne i Oslonøkkelen-appen. Vi ønsker beskjed når dere
            stenger et sted, så kontakt oss via kontaktskjemaet nedenfor.
          </p>

          <h3>
            Kan jeg ha forskjellige åpningstider på dørene på den samme
            tilgangslisten?
          </h3>
          <p>
            Nei, det er ikke mulig. Alle dørene på en tilgangsliste må ha de
            samme åpningstidene. Men om dette er noe dere har behov for, vil vi
            gjerne høre om det. Send oss en melding via kontaktskjemaet
            nedenfor.
          </p>

          <h3>Hvordan kan jeg lage flere tilgangslister?</h3>
          <p>
            Dersom du allerede har en kan du lage en kopi ved å trykke på
            menyknappen øverst til høyre. Dersom du ikke har tilgang, send oss
            en melding i kontaktskjemaet nedenfor, så lager vi dem for dere.
          </p>

          <h3>Kan jeg endre navn på en tilgangsliste?</h3>
          <p>
            Ja, klikk deg inn på tilgangslisten, klikk på menyknappen øverst til
            høyre og velg «Endre navn». Endret navnet og klikk på
            «Lagre»-knappen til slutt.
          </p>

          <h3>I hvilken kategori havner tilgangslistene i appen?</h3>
          <p>
            Det bestemmer du selv, bare si fra til oss når vi oppretter det
            første tilgangslisten for stedet deres.
          </p>

          <h3>Kan jeg endre kategori for en tilgangsliste?</h3>
          <p>
            Nei, men vi kan gjøre det for deg. Ta kontakt med oss via
            kontaktskjemaet nedenfor.
          </p>

          <h3>
            Hva gjør jeg når jeg får varsel på sms eller e-post, eller når en
            innbygger sier at noe ikke fungerer?
          </h3>
          <p>
            Da er det flere ting du kan gjøre for å finne feilen selv. Gå
            gjennom sjekklisten øverst på siden, sannsynligvis vil du klare å
            rette feilen selv. Hvis du likevel ikke finner ut hva som er galt,
            send oss en melding via kontaktskjemaet nedenfor.
          </p>
        </HelpSection>

        <HelpSection title='Om Oslonøkkelen'>
          <p>
            Oslonøkkelen er en digital nøkkel som gir innbyggerne utvidet og
            enklere tilgang til flere av byens tjenester.
          </p>
          <p>
            Med Oslonøkkelen får du mulighet til å åpne minigjenbruksstasjoner
            utenfor betjent åpningstid, bruke appen til å identifisere deg på
            gjenbruksstasjoner, og åpne biblioteker. Flere tjenester kommer
            etter hvert.
          </p>
          <br />
          <figure>
            <img src={TeamImage} alt='Bilde av tre utviklere av Oslonøkkelen' />
            <figcaption>Noen av våre hardtarbeidende utviklere</figcaption>
          </figure>
        </HelpSection>

        <h2 className='contact-header'>Kontakt oss</h2>
        <ContactForm />
      </section>
    </Fragment>
  );
};
