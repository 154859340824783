import React from 'react';
import { LockCard } from '../../../pages/keychainFactory/locks/lockCards/LockCard';
import { useAppSelector } from '../../../redux/hooks';
import { subjectSelectors } from '../../../redux/slice/subjects';
import { FullOperationId } from '../../../redux/types/protoTypes';
import {
  getOperation,
  getOperationInfo
} from '../../../utils/operation/operation';
import { OperationLinks } from './OperationLinks/OperationLinks';

interface Props {
  fullOperationIds: FullOperationId[];
}

export const OperationCards: React.FC<Props> = ({ fullOperationIds }) => {
  const subjects = useAppSelector(subjectSelectors.selectAll);

  const publicOperations: React.JSX.Element[] = [];
  const nonPublicOperations: React.JSX.Element[] = [];
  fullOperationIds.forEach(fullOperationId => {
    const operation = getOperation(fullOperationId, subjects);
    const operationInfo = getOperationInfo(operation!, fullOperationId);
    if (operation && operation.visibleForEveryone) {
      publicOperations.push(
        <LockCard key={operation.id} operation={operationInfo} />
      );
    } else if (operation && !operation.visibleForEveryone) {
      nonPublicOperations.push(
        <LockCard key={operation.id} operation={operationInfo} />
      );
    }
  });

  return (
    <React.Fragment>
      {fullOperationIds.length > 0 ? (
        <React.Fragment>
          {publicOperations.length > 0 && (
            <React.Fragment>
              <h4>
                Ting som er tilgjengelig for alle innloggede brukere av
                Oslonøkkelen:
              </h4>
              <div className='cards' data-testid='operation-cards'>
                <OperationLinks>{publicOperations}</OperationLinks>
              </div>
            </React.Fragment>
          )}
          {nonPublicOperations.length > 0 && (
            <React.Fragment>
              <h4>
                Disse tingene får man kun tilgang til igjennom tilgangslister
              </h4>
              <div className='cards' data-testid='operation-cards'>
                <OperationLinks>{nonPublicOperations}</OperationLinks>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <p>Dette stedet har ingen låser.</p>
      )}
    </React.Fragment>
  );
};
