import React, { ReactElement, useCallback, useMemo } from 'react';
import cn from 'classnames';
import './Table.scss';
import { HeadingProps, TableHeader } from './TableHeader';
import { RowProps, TableRow } from './TableRow';

interface CommonTableProps<T> {
  headings: string[] | HeadingProps<T>[];
  rows: RowProps[] | undefined;
  tableClassName?: string;
  emptyTableText?: ReactElement;
}

export interface TableWithSortProps<T> extends CommonTableProps<T> {
  sortKey: '' | keyof T;
  setSortKey: (key: keyof T) => void;
  sortReversed: boolean;
}

export type TableProps<T> = CommonTableProps<T> | TableWithSortProps<T>;

const normalizeHeadings = <T,>(
  headings: TableProps<T>['headings']
): HeadingProps<T>[] => {
  return headings.map(heading => {
    if (typeof heading === 'string') {
      return { element: heading };
    }
    return heading;
  });
};

export const Table = <T,>({
  headings,
  rows,
  tableClassName,
  emptyTableText,
  ...sort
}: TableProps<T>) => {
  if (!rows || rows.length === 0) {
    return <div className='table'>{emptyTableText}</div>;
  }

  const normalizedHeadings = useMemo(
    () => normalizeHeadings(headings),
    [headings]
  );

  const hasRowActions = rows.some(row => row.actions);

  const {
    sortKey = '',
    setSortKey = () => {},
    sortReversed = false
  } = sort as TableWithSortProps<T>;

  const memoizedSetSortKey = useCallback(setSortKey, [setSortKey]);

  return (
    <table className={cn('keystudio-table', tableClassName)}>
      <TableHeader
        headings={normalizedHeadings}
        sortKey={sortKey}
        setSortKey={memoizedSetSortKey}
        hasRowActions={hasRowActions}
        sortReversed={sortReversed}
      />
      <tbody className='keystudio-table-tbody'>
        {rows.map((row, rowIndex) => (
          <TableRow
            key={row.link || rowIndex}
            row={row}
            rowIndex={rowIndex}
            hasRowActions={hasRowActions}
          />
        ))}
      </tbody>
    </table>
  );
};
