export const refreshCacheAndReload = () => {
  console.log('Clearing cache and hard reloading...');
  if (caches) {
    console.log(caches);
    caches.keys().then(names => {
      for (const name of names) caches.delete(name);
    });
  }
  window.location.reload();
};
