import React from 'react';
import { ActionMenu } from '../ActionMenu/ActionMenu';
import { ActionMenuContainer } from '../ActionMenu/ActionMenuContainer';
import { ActionMenuItemProps } from '../ActionMenu/ActionMenuItem';
import { Button, ButtonProps } from '../Button/Button';
import { Confirm } from '../Confirm';

interface Props {
  label: string;
  message: string;
  size?: 'small' | 'medium' | 'dialog';
  buttonType?: ButtonProps['type'];
  deleteAction: () => void;
}
export const DeleteAndConfirm: React.FC<Props> = ({
  label,
  message,
  size,
  deleteAction,
  buttonType = 'destructive'
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <React.Fragment>
      <ConfirmDialogDelete
        label={label}
        message={message}
        deleteAction={deleteAction}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Button
        label={label}
        size={size}
        type={buttonType}
        onClick={() => setIsModalOpen(true)}
      />
    </React.Fragment>
  );
};

export const DeleteAndConfirmMenu: React.FC<Props> = ({
  label,
  message,
  deleteAction
}) => {
  const actions: ActionMenuItemProps[] = [];
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  actions.push({
    type: 'delete',
    description: label,
    onClick: () => setIsDeleteModalOpen(true)
  });
  return (
    <ActionMenuContainer>
      <ActionMenu actions={actions} />
      <ConfirmDialogDelete
        label={label}
        message={message}
        deleteAction={deleteAction}
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
      />
    </ActionMenuContainer>
  );
};

type ConfirmDialogDeleteProps = {
  label: string;
  message: string;
  deleteAction: () => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ConfirmDialogDelete: React.FC<ConfirmDialogDeleteProps> = ({
  label,
  message,
  deleteAction,
  isModalOpen,
  setIsModalOpen
}) => {
  const doSomething = () => {
    deleteAction();
    setIsModalOpen(false);
  };

  return (
    <Confirm
      title={label}
      message={message}
      doSomething={doSomething}
      closeModal={() => setIsModalOpen(false)}
      isModalOpen={isModalOpen}
    />
  );
};
