import React, { Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SetURLSearchParams } from 'react-router-dom';
import { Button } from '../../../../components/Button/Button';
import { KeyText } from '../../../../components/Forms/KeyText';
import { KeyValueFields } from '../../../../components/KeyValue/KeyValueFields';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';
import { PreContainer } from '../../../../components/code/PreContainer';
import { fetchFlightRecording } from '../../../../redux/actions/flightrecordings';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { formatDateFromEpochSeconds } from '../../../../utils/timeConverter';

type Props = {
  id: string;
  setSearchParams: SetURLSearchParams;
};

type FormProps = {
  flightRecordingId: string;
};

export const FlightRecordings: React.FC<Props> = ({ id, setSearchParams }) => {
  const dispatch = useAppDispatch();
  const {
    metadata: { summary, os, buildNumber, receivedEpochSeconds },
    json,
    hasData,
    isLoading
  } = useAppSelector(state => state.flightrecordings);
  const methods = useForm<FormProps>({
    defaultValues: { flightRecordingId: id }
  });

  const onSubmit = (data: FormProps) =>
    dispatchAndHandleResult(dispatch, fetchFlightRecording, data, () => {
      setSearchParams(data);
    });

  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <KeyText
            label='FlightRecordingId'
            name='flightRecordingId'
            elementProps={{}}
          />
          <br />
          <Button label='Vis logger' type='submit' />
          <br />
        </form>
      </FormProvider>
      <br />
      {isLoading && <LoadingSpinner />}
      {hasData && (
        <Fragment>
          <h2>Summary</h2>
          <PreContainer obj={{ summary: summary }} />
          <br />
          <KeyValueFields>
            <dt>OS</dt>
            <dd>{os}</dd>
            <dt>Build number</dt>
            <dd>{buildNumber}</dd>
            <dt>Received</dt>
            <dd>
              {receivedEpochSeconds
                ? `${formatDateFromEpochSeconds(receivedEpochSeconds)}`
                : '?'}
            </dd>
          </KeyValueFields>
          <br />
          <h2>JSON-dump:</h2>
          <PreContainer obj={{ ...json }} />
        </Fragment>
      )}
    </div>
  );
};
