import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoadingSpinner } from '../components/LoadingSpinner/LoadingSpinner';
import { CreateTPS } from '../pages/controlpanel/ThirdPartySystems/CreateTPS';
import { ThirdPartySystem } from '../pages/controlpanel/ThirdPartySystems/ThirdPartySystem';
import { ThirdPartySystems } from '../pages/controlpanel/ThirdPartySystems/ThirdPartySystems';
import { store } from '../redux';
import { getThirdPartySystems } from '../redux/actions/thirdPartySystems';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { thirdPartySystemSelectors } from '../redux/slice/thirdPartySystems';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const ThirdPartySystemsRouter = () => {
  const loading = useAppSelector(state => state.thirdPartySystems.isLoading);
  const thirdPartySystems = useAppSelector(thirdPartySystemSelectors.selectAll);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedAdminTool('api'));
    if (thirdPartySystems.length === 0 && !loading) {
      store.dispatch(getThirdPartySystems());
    }
  }, [dispatch, thirdPartySystems.length, loading]);

  return (
    <Routes>
      <Route
        path='/'
        element={loading ? <LoadingSpinner /> : <ThirdPartySystems />}
      />
      <Route path='lag-ny' element={<CreateTPS />} />
      <Route path=':thirdPartySystemId' element={<ThirdPartySystem />} />
    </Routes>
  );
};
