// source: operation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    ageLimit: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAgeLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getAgeLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 age_limit = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.prototype.getAgeLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateAgeLimitMessage.prototype.setAgeLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    entity: (f = msg.getEntity()) && shared_pb.OperationEntity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = new shared_pb.OperationEntity;
      reader.readMessage(value,shared_pb.OperationEntity.deserializeBinaryFromReader);
      msg.setEntity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getEntity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.OperationEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional oslonokkelen.proto.shared.OperationEntity entity = 2;
 * @return {?proto.oslonokkelen.proto.shared.OperationEntity}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.prototype.getEntity = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OperationEntity} */ (
    jspb.Message.getWrapperField(this, shared_pb.OperationEntity, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OperationEntity|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.prototype.setEntity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.prototype.clearEntity = function() {
  return this.setEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateEntityTypeRequest.prototype.hasEntity = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateProximityVerificationDeviceIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.ClearAgeLimitMessage.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    actionChainId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    id: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionChainId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActionChainId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string action_chain_id = 9;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.prototype.getActionChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.prototype.setActionChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string id = 8;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.CreateOperationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationNameRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    about: (f = msg.getAbout()) && shared_pb.Text.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = new shared_pb.Text;
      reader.readMessage(value,shared_pb.Text.deserializeBinaryFromReader);
      msg.setAbout(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getAbout();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      shared_pb.Text.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional oslonokkelen.proto.shared.Text about = 2;
 * @return {?proto.oslonokkelen.proto.shared.Text}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.prototype.getAbout = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.Text} */ (
    jspb.Message.getWrapperField(this, shared_pb.Text, 2));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.Text|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.prototype.setAbout = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.prototype.clearAbout = function() {
  return this.setAbout(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationAboutRequest.prototype.hasAbout = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    termIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addTermIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getTermIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string term_ids = 2;
 * @return {!Array<string>}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.prototype.getTermIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.prototype.setTermIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.prototype.addTermIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationTermsRequest.prototype.clearTermIdsList = function() {
  return this.setTermIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    mustBeWithinMetersToExecute: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMustBeWithinMetersToExecute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getMustBeWithinMetersToExecute();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 must_be_within_meters_to_execute = 2;
 * @return {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.prototype.getMustBeWithinMetersToExecute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationExecuteMetersRequest.prototype.setMustBeWithinMetersToExecute = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    actionChainId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActionChainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getActionChainId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string action_chain_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.prototype.getActionChainId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationActionChainRequest.prototype.setActionChainId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    uri: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getUri();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string uri = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.prototype.getUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationImageRequest.prototype.setUri = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f),
    biometricsRequired: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBiometricsRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
  f = message.getBiometricsRequired();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool biometrics_required = 2;
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.prototype.getBiometricsRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.UpdateOperationBiometricsRequirementRequest.prototype.setBiometricsRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.operation.DeleteOperationRequest.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.operation);
