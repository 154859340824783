import React, { Fragment } from 'react';
import {
  manualGrantKeychainHome,
  keychainHome,
  oidcKeychainHome,
  publicKeychainHome,
  pushKeychainHome
} from '../../../../components/Navigation/navPaths';
import {
  ToggleButtonProps,
  ToggleGroup
} from '../../../../components/ToggleGroup/ToggleButton';
import { KeychainFactorySummary } from '../../../../redux/slice/keychainFactorySummaries';
import { setKeychainType } from '../../../../utils/storage/webStorage';

interface Props {
  keychainType: string;
  keychainFactories: KeychainFactorySummary[];
  searchParams: URLSearchParams;
}
export const FilterButtons: React.FC<Props> = ({
  keychainType,
  keychainFactories,
  searchParams
}) => {
  const buttons: ToggleButtonProps[] = [];

  const availableTypes: Set<string> = new Set(
    keychainFactories.map(keychainFactory => keychainFactory.type)
  );
  const searchParamsString = searchParams ? '?' + searchParams.toString() : '';

  if (availableTypes.has('public')) {
    buttons.push({
      value: 'public',
      onClick: () => {
        setKeychainType(publicKeychainHome.path.split('/')[2]);
      },
      tooltip:
        'Tilganger som er tilgjenelig for alle som er logget inn i appen',
      label: publicKeychainHome.name,
      linkTo: publicKeychainHome.path + searchParamsString
    });
  }
  if (availableTypes.has('manual-grant')) {
    buttons.push({
      value: 'manual-grant',
      onClick: () => {
        setKeychainType(manualGrantKeychainHome.path.split('/')[2]);
      },
      tooltip: 'Tilganger som er manuelt administrert i Keystudio',
      label: manualGrantKeychainHome.name,
      linkTo: manualGrantKeychainHome.path + searchParamsString
    });
  }
  if (availableTypes.has('push')) {
    buttons.push({
      value: 'push',
      onClick: () => {
        setKeychainType(pushKeychainHome.path.split('/')[2]);
      },
      tooltip:
        'Tilganger som blir automatisk synkronisert fra en tredjepart. f.eks Origo Booking',
      label: pushKeychainHome.name,
      linkTo: pushKeychainHome.path + searchParamsString
    });
  }
  if (availableTypes.has('oidc')) {
    buttons.push({
      value: 'oidc',
      onClick: () => {
        setKeychainType(oidcKeychainHome.path.split('/')[2]);
      },
      tooltip: 'Tilganger som lar brukere logge inn på nettsider',
      label: oidcKeychainHome.name,
      linkTo: oidcKeychainHome.path + searchParamsString
    });
  }
  if (availableTypes.has('unknown')) {
    buttons.push({
      value: 'unknown',
      tooltip: 'Tilganger som ikke er definert i Keystudio',
      label: 'Ukjent',
      linkTo: keychainHome.path + '/unknown/' + searchParamsString
    });
  }

  if (keychainType && !availableTypes.has(keychainType)) {
    buttons.push({
      value: keychainType,
      label: keychainType
    });
  }
  if (buttons.length <= 1) {
    return null;
  } else {
    buttons.unshift({
      value: 'all',
      onClick: () => {
        setKeychainType('');
      },
      label: 'Vis alle typer',
      linkTo: keychainHome.path + searchParamsString
    });
  }

  const currentValue = keychainType ? keychainType : 'all';
  return (
    <Fragment>
      <h4>Filtrering på type</h4>
      <ToggleGroup buttons={buttons} selectedValue={currentValue} />
    </Fragment>
  );
};
