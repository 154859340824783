import React from 'react';
import { actionsHome } from '../../../components/Navigation/controlpanelNavPaths';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { useAppSelector } from '../../../redux/hooks';
import { AdmissionCard } from '../../../redux/slice/admissionCards';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';
import { generateImageUri } from '../../../utils/templating';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';

export const AdmissionCardsTable: React.FC = () => {
  const admissionCards: AdmissionCard[] = useAppSelector(
    state => state.admissionCards.cards
  );
  const tableHeadings = [
    'ID',
    'Navn',
    'Beskrivelse',
    'ActionChain',
    'Adminrolle',
    'Publisert',
    'Tilhørende steder',
    'Tilhørende vilkår',
    'Ikon',
    'Farge'
  ];
  const data = admissionCards.map(card => {
    return {
      cells: [
        <CopyWrapper key={card.id} copyValue={card.id} />,
        card.publicName,
        card.description,
        <CopyWrapper key={card.actionChainId} copyValue={card.actionChainId} />,
        <CopyWrapper key={card.adminRole} copyValue={card.adminRole} />,
        card.published ? 'Ja' : 'Nei',
        card.relevantSubjectIdsList.join(', '),
        card.requiredTermIdsList.join(', '),
        card.iconUri ? (
          <img
            src={generateImageUri(card.iconUri)}
            alt={card.publicName + ' ikon'}
            style={{ width: '50px' }}
          />
        ) : (
          ''
        ),
        card.hexBackgroundColor ? (
          <span
            key={card.hexBackgroundColor}
            style={{
              backgroundColor: card.hexBackgroundColor
            }}
          >
            {card.hexBackgroundColor}
          </span>
        ) : (
          ''
        )
      ],
      link: actionsHome.path + '/' + card.actionChainId
    };
  });

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs />
      <TableWithButtons
        ting='adgangskort'
        buttons={[
          {
            label: 'Lag ny type adgangskort',
            type: 'primary',
            accessKey: 'n',
            linkTo: 'lag-ny'
          }
        ]}
        table={{ headings: tableHeadings, rows: data }}
      ></TableWithButtons>
    </React.Fragment>
  );
};
