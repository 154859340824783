import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { Breadcrumbs } from '../../components/Navigation/breadcrumbs';
import { statsHome } from '../../components/Navigation/navPaths';
import { ToggleGroup } from '../../components/ToggleGroup/ToggleButton';
import { listAdmissionCardTypes } from '../../redux/actions/admissionCards';
import { listKeychainFactoryConfigurations } from '../../redux/actions/keychainFactorySummaries';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { AdmissionCard } from '../../redux/slice/admissionCards';
import { selectKeychainFactories } from '../../redux/slice/keychainFactorySummaries';
import { Subject, subjectSelectors } from '../../redux/slice/subjects';
import { getShortkeychainFactoryId } from '../../utils/keychainFactory/getKeychainFactoryAssets';
import { GraphContainer } from './GraphContainer/GraphContainer';
import {
  StatsDatePicker,
  StatsDatePickerProps
} from './StatsDatePicker/StatsDatePicker';
import './StatsPage.scss';
import { ToggleList } from './ToggleList/ToggleList';
import {
  createGraphData,
  fetchNewStats,
  getNewStatsToFetch
} from './utils/helpers';
import { GraphData } from './utils/statsTypes';

export const StatsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const subjects: Subject[] = useAppSelector(subjectSelectors.selectAll);
  const subjectSearchParamName = 'subject';
  const selectedSubjects = searchParams.getAll(subjectSearchParamName);
  const admissionCardSearchParamName = 'cards';
  const selectedAdmissioncards = searchParams.getAll(
    admissionCardSearchParamName
  );
  const keychainSearchParamName = 'keychainFactory';
  const selectedKeychainFactories =
    searchParams.getAll(keychainSearchParamName) || [];

  const [loading, setLoading] = useState(false);
  const [dataForGraph, setDataForGraph] = useState<GraphData[]>([]);
  const methods = useForm<StatsDatePickerProps>({
    defaultValues: {
      startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      endDate: new Date()
    }
  });
  const dateInterval: StatsDatePickerProps = methods.getValues();
  const keychainFactories = useAppSelector(selectKeychainFactories.selectAll);
  const admissionCards: AdmissionCard[] = useAppSelector(
    state => state.admissionCards.cards
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getAllVisitations = async () => {
      const selectedKeychainFactoriesIds = selectedKeychainFactories.map(
        id => 'citykey://factories/' + id
      );

      const newStatsToFetch = getNewStatsToFetch(
        dataForGraph,
        selectedSubjects,
        selectedKeychainFactoriesIds,
        selectedAdmissioncards,
        dateInterval
      );

      const newStats = await fetchNewStats(
        newStatsToFetch,
        subjects,
        dateInterval,
        setLoading
      );

      const graphData = createGraphData(
        dataForGraph,
        selectedSubjects,
        selectedKeychainFactoriesIds,
        selectedAdmissioncards,
        newStats
      );

      setDataForGraph(graphData);
    };
    if (subjects.length) {
      getAllVisitations();
    }
  }, [
    subjects.length,
    selectedSubjects.length,
    selectedKeychainFactories.length,
    selectedAdmissioncards.length,
    dateInterval.endDate.getTime() - dateInterval.startDate.getTime()
  ]);

  const toggleGroupData = [
    {
      label: 'Steder',
      value: 'subjects',
      onClick: () => {
        setSearchParams(
          searchParams => {
            searchParams.set('type', 'subjects');
            return searchParams;
          },
          { replace: true }
        );
      }
    },
    {
      label: 'Tilgangslister',
      value: 'keychainFactories',
      onClick: () => {
        setSearchParams(
          searchParams => {
            searchParams.set('type', 'keychainFactories');
            return searchParams;
          },
          { replace: true }
        );
      }
    },
    {
      label: 'Adgangskort',
      value: 'admissioncards',
      onClick: () => {
        setSearchParams(
          searchParams => {
            searchParams.set('type', 'admissioncards');
            return searchParams;
          },
          { replace: true }
        );
      }
    }
  ];

  useEffect(() => {
    if (keychainFactories.length === 0) {
      dispatch(listKeychainFactoryConfigurations());
    }
    if (admissionCards.length === 0) {
      dispatch(listAdmissionCardTypes());
    }
  }, [dispatch, keychainFactories.length, admissionCards.length]);

  const selectedType = searchParams.get('type') || 'subjects';

  return (
    <section className='stats-page'>
      <Breadcrumbs breadcrumbs={[statsHome]} />
      <ToggleGroup buttons={toggleGroupData} selectedValue={selectedType} />
      {selectedType === 'keychainFactories' && (
        <ToggleList
          selectedIds={selectedKeychainFactories}
          setSearchParams={setSearchParams}
          searchParamName={keychainSearchParamName}
          displayData={keychainFactories.map(factory => {
            return {
              id: getShortkeychainFactoryId(factory.keychainFactoryUri),
              name: factory.name,
              color: dataForGraph.find(s => s.id === factory.keychainFactoryUri)
                ?.color
            };
          })}
        />
      )}
      {selectedType === 'subjects' && (
        <ToggleList
          selectedIds={selectedSubjects}
          setSearchParams={setSearchParams}
          searchParamName={subjectSearchParamName}
          displayData={subjects.map(subject => {
            return {
              id: subject.id,
              name: subject.name,
              color: dataForGraph.find(s => s.id === subject.id)?.color
            };
          })}
        />
      )}
      {selectedType === 'admissioncards' && (
        <ToggleList
          selectedIds={selectedAdmissioncards}
          setSearchParams={setSearchParams}
          searchParamName={admissionCardSearchParamName}
          displayData={admissionCards.map(card => {
            return {
              id: card.id,
              name: card.publicName,
              color: dataForGraph.find(s => s.id === card.id)?.color
            };
          })}
        />
      )}
      <FormProvider {...methods}>
        <StatsDatePicker />
      </FormProvider>
      <GraphContainer graphData={dataForGraph} loading={loading} />
    </section>
  );
};
