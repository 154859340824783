import React, { useCallback } from 'react';
import { ActionMenu } from '../../../components/ActionMenu/ActionMenu';
import { ActionMenuItemProps } from '../../../components/ActionMenu/ActionMenuItem';
import { RenameDialog } from '../../../components/Dialog/RenameDialog';
import { updateKeychainFactoryNameRequest } from '../../../redux/actions/keychainFactoryDetails';
import { useAppDispatch } from '../../../redux/hooks';
import { ManualGrantKeychainFactoryDetails } from '../../../redux/slice/keychainFactoryDetails';
import { CreateCopy } from './CreateCopy';
import { DeleteKeychain } from './DeleteKeychain';

type Props = {
  keychainFactory: ManualGrantKeychainFactoryDetails;
};
export const KeychainFactoryMenu: React.FC<Props> = ({ keychainFactory }) => {
  const [isRenameModalOpen, setIsRenameModalOpen] = React.useState(false);
  const [isCopyModalOpen, setIsCopyModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const { policiesList } = keychainFactory;
  const dispatch = useAppDispatch();

  const renameAction = useCallback(
    (name: string) => {
      dispatch(
        updateKeychainFactoryNameRequest({
          id: keychainFactory.keychainFactoryUri,
          newName: name
        })
      );
    },
    [keychainFactory.keychainFactoryUri]
  );

  const actions: ActionMenuItemProps[] = [
    {
      type: 'edit',
      description: 'Endre navn',
      onClick: () => setIsRenameModalOpen(true)
    }
  ];
  if (policiesList.clone) {
    actions.push({
      type: 'clone',
      description: 'Lag kopi av tilgangslisten',
      onClick: () => setIsCopyModalOpen(true)
    });
  }
  if (policiesList.pb_delete) {
    actions.push({
      type: 'delete',
      description: 'Slett tilgangslisten',
      onClick: () => setIsDeleteModalOpen(true)
    });
  }

  return (
    <div className='actionmenu-container'>
      <ActionMenu actions={actions} />
      <CreateCopy
        isModalOpen={isCopyModalOpen}
        closeModal={() => setIsCopyModalOpen(false)}
        keychainFactory={keychainFactory}
      />
      <DeleteKeychain
        isModalOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        keychainFactory={keychainFactory}
      />
      <RenameDialog
        isModalOpen={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
        storeAction={renameAction}
        initialName={keychainFactory.name}
        formLabel='Navn på tilgangslisten'
        description='Navnet blir synlig i Oslonøkkelen-appen for personer på
          tilgangslisten.'
      />
    </div>
  );
};
