import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '../../../components/Button/Button';
import { CreateSection } from '../../../components/CreateSection/CreateSection';
import { AdminRoleSelector } from '../../../components/Forms/AdminRoleSelector';
import { KeyId } from '../../../components/Forms/KeyId';
import { KeyText } from '../../../components/Forms/KeyText';
import { KeyTextarea } from '../../../components/Forms/KeyTextarea';
import {
  createThirdPartySystem,
  CreateThirdPartySystemProps
} from '../../../redux/actions/thirdPartySystems';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { KeyPopup } from './KeyPopup';
import { ThirdPartySystemsContainer } from './ThirdPartySystemsContainer';

export const CreateTPS = () => {
  const newApiKey = useAppSelector(state => state.thirdPartySystems.newApiKey);
  const [popup, setPopup] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (newApiKey) {
      setPopup(true);
    }
  }, [newApiKey]);

  const methods = useForm<CreateThirdPartySystemProps>({
    resolver: zodResolver(CreateThirdPartySystemProps),
    defaultValues: {
      apiKey: uuidv4()
    }
  });
  const { handleSubmit, watch } = methods;
  const id = watch('id', '');

  return (
    <ThirdPartySystemsContainer>
      {popup && <KeyPopup apiKey={newApiKey} id={id} setPopup={setPopup} />}
      <ControlpanelBreadcrumbs currentPageName='Sett opp nytt tredjepartssystem' />

      <CreateSection>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(data => {
              dispatch(createThirdPartySystem(data));
            })}
          >
            <KeyText
              description='Navnet på tredjepartssystemet'
              label='Navn'
              name='name'
              elementProps={{
                placeholder: 'Skriv inn navnet på tredjepartssystemet'
              }}
            />

            <KeyId
              description='Unik ID til tredjepartssystemet'
              label='ID'
              name='id'
              otherField='name'
              elementProps={{
                placeholder: 'Skriv inn ønsket ID til tredjepartssystemet'
              }}
            />

            <AdminRoleSelector
              description='Hvilken keycloak oslonokkelen-admin client rolle må admin ha for å
              kunne administrere tilgangslisten?'
              label='Adminrolle'
              name='adminRole'
            />

            <KeyTextarea
              label='Informasjon'
              description='Skriv en beskrivelse av tredjepartssystemet'
              name='information'
              elementProps={{
                placeholder: 'Skriv en beskrivelse av tredjepartssystemet'
              }}
            />

            <KeyText
              name='eventCallbackUri'
              label='Event Callback Uri'
              description='Endpoint Oslonøkkelen bruker for å sende events til når noe
            interessant skjer. Kan være blank.'
              elementProps={{
                placeholder: 'Event Callback Uri'
              }}
            />

            <Button label='Opprett tredjepartssytem' type='submit' />
          </form>
        </FormProvider>
      </CreateSection>
    </ThirdPartySystemsContainer>
  );
};
