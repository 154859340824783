import React from 'react';
import { useAppSelector } from '../../../redux/hooks';
import { broadcastSelectors } from '../../../redux/slice/broadcast';
import { ControlpanelBreadcrumbs } from '../ControlpanelBreadcrumbs';
import { AdminFAQMenu } from './AdminFAQMenu';
import { FaqSection } from './FaqSection';

export const AdminFAQ: React.FC = () => {
  const faq = useAppSelector(broadcastSelectors.selectAll);

  return (
    <React.Fragment>
      <ControlpanelBreadcrumbs />
      <AdminFAQMenu />
      <FaqSection faq={faq} />
    </React.Fragment>
  );
};
