import React from 'react';
import { TableWithButtons } from '../../../components/Table/TableWithButtons';
import { OperationType } from '../../../proto/shared_pb';
import { Operation } from '../../../redux/slice/operations';
import { CopyWrapper } from '../../../utils/id/CopyWrapper';

interface Props {
  operations: Array<Operation>;
}
export const OperationsTable: React.FC<Props> = ({ operations }) => {
  const tableHeadings = [
    'Navn',
    'ID',
    'Operasjonstype',
    'Stengt',
    'Antall vilkår',
    'Radius',
    'Aldersgrense',
    'Synlig for alle',
    'Nærhetsenhet',
    'ActionChain'
  ];
  const data = operations.map(operation => {
    return {
      cells: [
        operation.name,
        <CopyWrapper key={operation.id} copyValue={operation.id} />,
        getOperationType(operation.operationType),
        operation.closed ? 'JA' : 'NEI',
        operation.termIds.length,
        operation.mustBeWithinMetersToExecute,
        operation.ageLimit,
        operation.visibleForEveryone ? 'JA' : 'NEI',
        operation.proximityDeviceId || 'Ikke tilknyttet',
        operation.actionChainId
      ],
      link: `${operation.id}`
    };
  });

  return (
    <div>
      <TableWithButtons
        ting='operasjon'
        buttons={[
          {
            label: 'Lag ny operasjon',
            type: 'primary',
            accessKey: 'n',
            linkTo: 'lag-ny'
          }
        ]}
        table={{ headings: tableHeadings, rows: data }}
      />
    </div>
  );
};

const getOperationType = (operationType: OperationType): string => {
  return Object.keys(OperationType)[
    Object.values(OperationType).indexOf(operationType)
  ];
};
