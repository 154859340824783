import { z } from 'zod';
import {
  ActionChainDefinition,
  AdapterDescriptionResponse,
  CreateActionChainRequest,
  CreateAdapterMessage,
  DescribeAdapterRequest,
  ListActionChainsRequest,
  ListAdaptersRequest,
  ListAllActionsRequest,
  UpdateActionChainRequest,
  AdapterDescription as GrpcAdapterDescription,
  RenameActionChainRequest, CreateBetterUptimeMonitorRequest
} from '../../proto/grpc-adapter_pb';
import { FullActionId, ActionDefinition } from '../../proto/shared_pb';
import {
  getAdapterClient,
  getGrpcMetadata,
  handleGrpcError
} from '../../utils/requests/grpcRequest';
import { createAppAsyncThunk } from '../hooks';
import {
  ActionChain,
  ActionItem,
  AdapterDescription,
  AdapterDetails,
  fullOperationId,
  setActionChains,
  setActionsList,
  setAdapterDescriptions,
  setAdapterDetails,
  Thing
} from '../slice/adapters';
import { setPopup } from '../slice/popup';
import { id, name, noValidationString } from '../types/zodSchemas';

const actionItem = z.object({
  adapterId: z.string(),
  thingId: z.string(),
  actionId: z.string(),
  actionUri: z.string(),
  sendFodselsnummer: z.boolean()
});

export const CreateAdapterForm = z.object({
  adapterId: id,
  adapterEndpointUri: z.string().url({
    message: 'Ugyldig URL'
  })
});
export type CreateAdapterForm = z.infer<typeof CreateAdapterForm>;

export const createAdapter = createAppAsyncThunk<void, CreateAdapterForm>(
  'adapters/createAdapter',
  async ({ adapterId, adapterEndpointUri }, thunkAPI) => {
    try {
      await getAdapterClient().createAdapter(
        new CreateAdapterMessage()
          .setAdapterId(adapterId)
          .setAdapterEndpointUri(adapterEndpointUri),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Adapteret er laget!' })
      );
      await thunkAPI.dispatch(listAdapters());
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const listActions = createAppAsyncThunk(
  'adapters/listActions',
  async (_, thunkAPI) => {
    try {
      const request = new ListAllActionsRequest();
      const response = await getAdapterClient().listAllActions(
        request,
        await getGrpcMetadata()
      );

      thunkAPI.dispatch(setActionsList(response.toObject().entriesList));
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const listActionChains = createAppAsyncThunk(
  'adapters/listActionChains',
  async (_, thunkAPI) => {
    try {
      const request = new ListActionChainsRequest();
      const response = await getAdapterClient().listActionChains(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setActionChains(convertToActionChains(response.toObject().chainsList))
      );
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const listAdapters = createAppAsyncThunk(
  'adapters/listAdapters',
  async (_, thunkAPI) => {
    try {
      const request = new ListAdaptersRequest();
      const response = await getAdapterClient().listAdapters(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setAdapterDescriptions(
          response.toObject().adaptersList.map(convertToAdapterDescriptions)
        )
      );
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const getAdapterDetails = createAppAsyncThunk(
  'adapters/getAdapterDetails',
  async (adapterId: string, thunkAPI) => {
    try {
      const response = await getAdapterClient().describeAdapter(
        new DescribeAdapterRequest().setId(adapterId),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setAdapterDetails(toAdapterDetails(response.toObject()))
      );
    } catch (error) {
      return handleGrpcError(error, thunkAPI);
    }
  }
);

export const UpdateActionChain = z.object({
  actionChainId: noValidationString,
  actionChainName: noValidationString,
  actionList: z.array(actionItem),
  expectedVersion: z.number()
});

export type UpdateActionChain = z.infer<typeof UpdateActionChain>;

export const updateActionChain = createAppAsyncThunk<void, UpdateActionChain>(
  'adapters/updateActionChain',
  async (
    { actionChainId, actionChainName, actionList, expectedVersion },
    thunkAPI
  ) => {
    try {
      const actionChainDefinition = new ActionChainDefinition()
        .setId(actionChainId)
        .setName(actionChainName)
        .setActionsList(generateActionList(actionList));

      const request = new UpdateActionChainRequest()
        .setUpdatedChain(actionChainDefinition)
        .setExpectedVersion(expectedVersion);
      await getAdapterClient().updateActionChain(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(listActionChains());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const CreateActionChain = z.object({
  id: id,
  name: name,
  actionList: z.array(actionItem)
});

export type CreateActionChain = z.infer<typeof CreateActionChain>;

export const createActionChain = createAppAsyncThunk<void, CreateActionChain>(
  'adapters/createActionChain',
  async ({ id, name, actionList }, thunkAPI) => {
    try {
      const request = new CreateActionChainRequest();
      const actionChainDefinition: ActionChainDefinition =
        new ActionChainDefinition();
      actionChainDefinition.setId(id);
      actionChainDefinition.setName(name);
      actionChainDefinition.setActionsList(generateActionList(actionList));

      request.setChain(actionChainDefinition);
      await getAdapterClient().createActionChain(
        request,
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(listActionChains());
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);

export const renameActionChain = createAppAsyncThunk<
  { id: string; name: string },
  {
    actionChainId: string;
    newChainName: string;
    version: number;
  }
>(
  'adapters/renameActionChain',
  async ({ actionChainId, newChainName, version }, thunkAPI) => {
    try {
      await getAdapterClient().renameActionChain(
        new RenameActionChainRequest()
          .setId(actionChainId)
          .setNewName(newChainName)
          .setExpectedVersion(version),
        await getGrpcMetadata()
      );
      thunkAPI.dispatch(
        setPopup({ error: false, message: 'Navnet er oppdatert' })
      );
      return { id: actionChainId, name: newChainName };
    } catch (e) {
      return handleGrpcError(e, thunkAPI);
    }
  }
);


export const createBetterUptimeMonitor = createAppAsyncThunk<
    void,
    {
      actionUri: string
      monitorName: string
    }
>('adapters/createBetterUptimeMonitor', async ({ actionUri, monitorName }, thunkAPI) => {
  try {
    const request = new CreateBetterUptimeMonitorRequest();
    request.setActionUri(actionUri);
    request.setMonitorName(monitorName)
    await getAdapterClient().createBetterUptimeMonitor(
        request,
        await getGrpcMetadata()
    );
    thunkAPI.dispatch(setPopup({ error: false, message: 'Betteruptime monitor opprettet' }));
  } catch (e) {
    return handleGrpcError(e, thunkAPI);
  }
});

const generateActionList = (actionList: ActionItem[]): ActionDefinition[] => {
  const list: ActionDefinition[] = [];
  actionList.forEach(action => {
    const actionDefinition: ActionDefinition = new ActionDefinition();
    actionDefinition.setSendFodselsnummer(action.sendFodselsnummer);
    const actionId = new FullActionId();
    actionId.setThingId(action.thingId);
    actionId.setActionId(action.actionId);
    actionId.setAdapterId(action.adapterId);
    actionDefinition.setFullActionId(actionId);
    list.push(actionDefinition);
  });
  return list;
};

const toAdapterDetails = (
  adapter: AdapterDescriptionResponse.AsObject
): AdapterDetails => {
  return {
    thingsList: convertToThingsList(adapter.thingsList),
    description: convertToAdapterDescriptions(adapter.description!)
  };
};

const convertToActionChains = (
  actionChains: ActionChainDefinition.AsObject[]
): ActionChain[] => {
  return actionChains.map(chain => {
    return {
      ...chain,
      usedByOperationList: chain.usedByOperationsList.map(operation => {
        return fullOperationId.parse({
          operationId: operation.operationId,
          subjectId: operation.subjectId
        });
      }),
      actionsList: chain.actionsList.map(action => {
        return {
          adapterId: action.fullActionId?.adapterId || '',
          thingId: action.fullActionId?.thingId || '',
          actionId: action.fullActionId?.actionId || '',
          actionUri: action.actionUri,
          sendFodselsnummer: action.sendFodselsnummer
        };
      })
    };
  });
};

const convertToAdapterDescriptions = (
  adapter: GrpcAdapterDescription.AsObject
): AdapterDescription => {
  return {
    ...adapter,
    adapterScrapeSummary: adapter.adapterScrapeSummary
      ? {
          lastScrapeTimestamp:
            adapter.adapterScrapeSummary?.lastScrapeTimestamp?.epochMillis || 0,
          lastSuccessfulScrapeTimestamp:
            adapter.adapterScrapeSummary?.lastSuccessfulScrapeTimestamp
              ?.epochMillis || 0,
          lastScrapeDurationMs:
            adapter.adapterScrapeSummary?.lastScrapeDurationMs || 0,
          lastScrapeTraceId:
            adapter.adapterScrapeSummary?.lastScrapeTraceId || ''
        }
      : undefined
  };
};

const convertToThingsList = (
  thingslist: AdapterDescriptionResponse.AsObject['thingsList']
): Thing[] => {
  return thingslist.map(thing => {
    return {
      ...thing,
      actionsList: thing.actionsList.map(action => {
        return {
          ...action,
          recordsList: action.recordsList.map(record => {
            return {
              ...record,
              timestamp: record.timestamp?.epochMillis || 0
            };
          })
        };
      })
    };
  });
};
