import React from 'react';

interface Props {
  width: number;
  minMs: number;
  avgMs: number;
  maxMs: number;
}

export const goodThresholdMs = 1000;
export const okayThresholdMs = 2000;
export const worstThresholdMs = 5000;

export const classNameBasedOnResponseTime = (responseTime: number): string => {
  if (responseTime < goodThresholdMs) {
    return 'good';
  } else if (responseTime < okayThresholdMs) {
    return 'okay';
  } else {
    return 'worst';
  }
};

export const ResponseTimeGraph: React.FC<Props> = ({
  width,
  minMs,
  avgMs,
  maxMs
}) => {
  const pxPerMs = width / worstThresholdMs;
  const goodResponseTimeWidth = goodThresholdMs * pxPerMs;
  const okayResponseTimeWidth = okayThresholdMs * pxPerMs;
  const badResponseTimeWidth =
    (worstThresholdMs - goodThresholdMs - okayThresholdMs) * pxPerMs;

  const minResponseTimeOffset = minMs * pxPerMs;
  const avgResponseTimeOffset = avgMs * pxPerMs;
  const maxResponseTimeOffset = maxMs * pxPerMs;

  return (
    <React.Fragment>
      <div style={{ width: `${width}px` }} className={'response-time-graph'}>
        <div className={'response-time-graph-background'}>
          <div
            className={'response-time-graph-section good'}
            style={{ width: goodResponseTimeWidth }}
          ></div>

          <div
            className={'response-time-graph-section okay'}
            style={{ width: okayResponseTimeWidth }}
          ></div>

          <div
            className={'response-time-graph-section worst'}
            style={{ width: badResponseTimeWidth }}
          ></div>
        </div>

        <div
          className={'response-time-marker'}
          style={{
            left: minResponseTimeOffset + 2
          }}
        ></div>
        <div
          className={'response-time-marker'}
          style={{
            left: avgResponseTimeOffset + 2
          }}
        ></div>
        <div
          className={'response-time-marker'}
          style={{
            left: maxResponseTimeOffset + 2
          }}
        ></div>
      </div>
    </React.Fragment>
  );
};
