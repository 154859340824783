import React from 'react';
import { useForm } from 'react-hook-form';
import { DialogBoxType } from '../../../../components/DialogBox/useDialogBox';
import { OidcRoleSelector } from '../../../../components/Forms/OidcRoleSelector';
import { replaceRoles } from '../../../../redux/actions/keychains';
import { dispatchAndHandleResult } from '../../../../redux/actions/util';
import { useAppDispatch } from '../../../../redux/hooks';
import { Keychain, Role } from '../../../../redux/slice/keychains';

interface Props {
  DialogBox: DialogBoxType;
  keychain?: Keychain;
  closeModal: () => void;
}

export const EditRoles: React.FC<Props> = ({
  DialogBox,
  keychain,
  closeModal
}) => {
  const dispatch = useAppDispatch();
  const methods = useForm({
    values: {
      selectedRoles:
        keychain?.roles.map(role => {
          return {
            labelText: role.name,
            value: role.id
          };
        }) || [],
      keychainUri: keychain?.id || '',
      allRoles: keychain?.policies.replaceRoles?.availableRolesList || []
    }
  });

  return (
    <DialogBox
      methods={methods}
      onSubmit={data =>
        dispatchAndHandleResult(
          dispatch,
          replaceRoles,
          {
            keychainUri: data.keychainUri,
            roles: data.selectedRoles.map(role =>
              matchRole(role.value, data.allRoles)
            )
          },
          closeModal
        )
      }
      buttonLabel='Oppdater'
      header={keychain?.personName}
    >
      <OidcRoleSelector
        name='selectedRoles'
        roleDefinitions={keychain?.policies.replaceRoles?.availableRolesList}
      />
    </DialogBox>
  );
};

const matchRole = (roleId: string, roles: Role[]): Role => {
  const role = roles.find(role => role.id === roleId);
  if (!role) {
    throw new Error('Role defintion not found: ' + roleId);
  }
  return role;
};
