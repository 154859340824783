import React from 'react';
import { CheckboxElement } from '../../../../components/Forms/Checkbox';
import { Subject } from '../../../../redux/slice/subjects';

interface Props {
  subject: Subject;
  selectedSubjectIds: string[];
  setSelectedTermIds: (termIds: string[]) => void;
}

export const SelectSubjectList: React.FC<Props> = ({
  subject,
  selectedSubjectIds,
  setSelectedTermIds
}) => {
  const selected = selectedSubjectIds.includes(subject.id);

  return (
    <CheckboxElement
      description={subject.id}
      label={subject.name}
      elementProps={{
        checked: selected,
        onChange: () => {
          selected
            ? setSelectedTermIds(
                selectedSubjectIds.filter(t => t !== subject.id)
              )
            : setSelectedTermIds([...selectedSubjectIds, subject.id]);
        }
      }}
    />
  );
};
