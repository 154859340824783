// source: utils.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var shared_pb = require('./shared_pb.js');
goog.object.extend(proto, shared_pb);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.PointerCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.ContactinfoCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.TypeCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.FromCase', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse', null, global);
goog.exportSymbol('proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.TranslationCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.oneofGroups_);
};
goog.inherits(proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.displayName = 'proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.FromCase = {
  FROM_NOT_SET: 0,
  STRUCTURED: 1,
  SPEC: 2
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.FromCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.getFromCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.FromCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    structured: (f = msg.getStructured()) && shared_pb.OpeningHours.toObject(includeInstance, f),
    spec: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.OpeningHours;
      reader.readMessage(value,shared_pb.OpeningHours.deserializeBinaryFromReader);
      msg.setStructured(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructured();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.OpeningHours.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.OpeningHours structured = 1;
 * @return {?proto.oslonokkelen.proto.shared.OpeningHours}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.getStructured = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OpeningHours} */ (
    jspb.Message.getWrapperField(this, shared_pb.OpeningHours, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OpeningHours|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.setStructured = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.clearStructured = function() {
  return this.setStructured(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.hasStructured = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string spec = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.getSpec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.setSpec = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.clearSpec = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecRequest.prototype.hasSpec = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.ContactinfoCase = {
  CONTACTINFO_NOT_SET: 0,
  PHONE_NUMBER: 1
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.ContactinfoCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.prototype.getContactinfoCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.ContactinfoCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneNumber: (f = msg.getPhoneNumber()) && shared_pb.PhoneNumber.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.PhoneNumber;
      reader.readMessage(value,shared_pb.PhoneNumber.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.PhoneNumber.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.PhoneNumber phone_number = 1;
 * @return {?proto.oslonokkelen.proto.shared.PhoneNumber}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.prototype.getPhoneNumber = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.PhoneNumber} */ (
    jspb.Message.getWrapperField(this, shared_pb.PhoneNumber, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.PhoneNumber|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoRequest.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.TypeCase = {
  TYPE_NOT_SET: 0,
  SUBJECT: 1,
  CATEGORY: 2,
  OPERATION: 3,
  KEYCHAIN_FACTORY: 4
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.TypeCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.getTypeCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.TypeCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    subject: (f = msg.getSubject()) && proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.toObject(includeInstance, f),
    category: (f = msg.getCategory()) && proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.toObject(includeInstance, f),
    keychainFactory: (f = msg.getKeychainFactory()) && proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.deserializeBinaryFromReader);
      msg.setSubject(value);
      break;
    case 2:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.deserializeBinaryFromReader);
      msg.setCategory(value);
      break;
    case 3:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 4:
      var value = new proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage;
      reader.readMessage(value,proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.deserializeBinaryFromReader);
      msg.setKeychainFactory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.serializeBinaryToWriter
    );
  }
  f = message.getCategory();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.serializeBinaryToWriter
    );
  }
  f = message.getKeychainFactory();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string subject_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.prototype.getSubjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage.prototype.setSubjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    categoryId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategoryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCategoryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string category_id = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.prototype.getCategoryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage.prototype.setCategoryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    operationId: (f = msg.getOperationId()) && shared_pb.FullOperationId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.FullOperationId;
      reader.readMessage(value,shared_pb.FullOperationId.deserializeBinaryFromReader);
      msg.setOperationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOperationId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.FullOperationId.serializeBinaryToWriter
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.FullOperationId operation_id = 1;
 * @return {?proto.oslonokkelen.proto.shared.FullOperationId}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.prototype.getOperationId = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.FullOperationId} */ (
    jspb.Message.getWrapperField(this, shared_pb.FullOperationId, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.FullOperationId|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.prototype.setOperationId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.prototype.clearOperationId = function() {
  return this.setOperationId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage.prototype.hasOperationId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    keychainFactoryUri: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeychainFactoryUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeychainFactoryUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string keychain_factory_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.prototype.getKeychainFactoryUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage.prototype.setKeychainFactoryUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SubjectImage subject = 1;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.getSubject = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage, 1));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.SubjectImage|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.setSubject = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.clearSubject = function() {
  return this.setSubject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.hasSubject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CategoryImage category = 2;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.getCategory = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage, 2));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.CategoryImage|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.setCategory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.clearCategory = function() {
  return this.setCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.hasCategory = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional OperationImage operation = 3;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.getOperation = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage, 3));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.OperationImage|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.setOperation = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional KeychainFactoryImage keychain_factory = 4;
 * @return {?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.getKeychainFactory = function() {
  return /** @type{?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage} */ (
    jspb.Message.getWrapperField(this, proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage, 4));
};


/**
 * @param {?proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.KeychainFactoryImage|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.setKeychainFactory = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.clearKeychainFactory = function() {
  return this.setKeychainFactory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlRequest.prototype.hasKeychainFactory = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.TranslationCase = {
  TRANSLATION_NOT_SET: 0,
  STRUCTURED: 1,
  SPEC: 2
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.TranslationCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.getTranslationCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.TranslationCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    structured: (f = msg.getStructured()) && shared_pb.OpeningHours.toObject(includeInstance, f),
    spec: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new shared_pb.OpeningHours;
      reader.readMessage(value,shared_pb.OpeningHours.deserializeBinaryFromReader);
      msg.setStructured(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSpec(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStructured();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      shared_pb.OpeningHours.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional oslonokkelen.proto.shared.OpeningHours structured = 1;
 * @return {?proto.oslonokkelen.proto.shared.OpeningHours}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.getStructured = function() {
  return /** @type{?proto.oslonokkelen.proto.shared.OpeningHours} */ (
    jspb.Message.getWrapperField(this, shared_pb.OpeningHours, 1));
};


/**
 * @param {?proto.oslonokkelen.proto.shared.OpeningHours|undefined} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse} returns this
*/
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.setStructured = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.clearStructured = function() {
  return this.setStructured(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.hasStructured = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string spec = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.getSpec = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.setSpec = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.clearSpec = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.TranslateOpeningHourSpecResponse.prototype.hasSpec = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profileId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfileId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profile_id = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.prototype.getProfileId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.FindNameFromContactInfoResponse.prototype.setProfileId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    presignedUri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contentType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cacheControl: jspb.Message.getFieldWithDefault(msg, 3, ""),
    publicUri: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPresignedUri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCacheControl(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPublicUri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPresignedUri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContentType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCacheControl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPublicUri();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string presigned_uri = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.prototype.getPresignedUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.prototype.setPresignedUri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content_type = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.prototype.getContentType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.prototype.setContentType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cache_control = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.prototype.getCacheControl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.prototype.setCacheControl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string public_uri = 4;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.prototype.getPublicUri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.ImagePresignedUrlResponse.prototype.setPublicUri = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.PointerCase = {
  POINTER_NOT_SET: 0,
  PROFILEID: 1,
  FODSELSNUMMER: 2
};

/**
 * @return {proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.PointerCase}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.getPointerCase = function() {
  return /** @type {proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.PointerCase} */(jspb.Message.computeOneofCase(this, proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    profileid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fodselsnummer: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest;
  return proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFodselsnummer(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string profileId = 1;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.getProfileid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.setProfileid = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.clearProfileid = function() {
  return jspb.Message.setOneofField(this, 1, proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.hasProfileid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string fodselsnummer = 2;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.getFodselsnummer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.setFodselsnummer = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.clearFodselsnummer = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.hasFodselsnummer = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string reason = 3;
 * @return {string}
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest} returns this
 */
proto.com.github.oslonokkelen.protobuf.admin.utils.DeleteProfileDataRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.com.github.oslonokkelen.protobuf.admin.utils);
