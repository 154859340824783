import { Subject } from '../../redux/slice/subjects';
import { Category } from '../../redux/types/protoTypes';

export interface SubjectsGroupedByCategories {
  id: string;
  subjects: Array<Subject>;
}

export const getCategoryNameFromId = (
  categoryId: string,
  categories: Array<Category>
): Category => {
  const category = categories.find(category => category.id === categoryId);

  return (
    category || {
      id: categoryId,
      citykeyId: '',
      name: categoryId,
      imagesList: [],
      adminRole: ''
    }
  );
};

export const getCategoriesWithSubjects = (subjects: Array<Subject>) => {
  const categoriesWithSubjects: Array<SubjectsGroupedByCategories> = [];

  subjects.forEach(subject => {
    if (
      categoriesWithSubjects.find(sortedSubject => {
        return sortedSubject.id === subject.categoryId;
      })
    ) {
      for (let i = 0; i < categoriesWithSubjects.length; i++) {
        if (categoriesWithSubjects[i].id === subject.categoryId) {
          categoriesWithSubjects[i].subjects = [
            ...categoriesWithSubjects[i].subjects,
            subject
          ];
        }
      }
    } else {
      categoriesWithSubjects.push({
        id: subject.categoryId,
        subjects: [...[], subject]
      });
    }
  });

  categoriesWithSubjects.sort((categoryA, categoryB) =>
    categoryA.id > categoryB.id ? 1 : -1
  );

  return categoriesWithSubjects;
};

export const getFilteredSubjects = (
  subjects: Array<Subject>,
  filteredCategories: Array<string>,
  filteredTags: Array<string>,
  freetextFilter: string
) => {
  return subjects
    .filter(subject => {
      return (
        subject.name
          .toLocaleLowerCase()
          .includes(freetextFilter.toLocaleLowerCase()) ||
        subject.id
          .toLocaleLowerCase()
          .includes(freetextFilter.toLocaleLowerCase())
      );
    })
    .filter(subject => {
      if (
        filteredCategories.length > 0 &&
        !filteredCategories.includes(subject.categoryId)
      ) {
        return false;
      }
      if (
        filteredTags.length > 0 &&
        !filteredTags.every(tag =>
          subject.dynamicTagsList.some(
            dynamicTag => dynamicTag.typeId.toString() === tag
          )
        )
      ) {
        return false;
      }
      return true;
    });
};
