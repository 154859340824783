import React from 'react';
import { KeypediaSvg } from '../../../assets/KeypediaSvg';
import { Keypedia } from '../../../redux/types/common';
import { formatDateFromEpochMillis } from '../../../utils/timeConverter';
import { getNameFromEmail } from '../../../utils/userProfile';
import '../../Keypedia/Keypedia.scss';
import { OperationInfoCard } from '../operationCards/OperationInfoCard';

export const KeypediaOperationCard: React.FC<Keypedia> = ({
  lastUpdatedBy,
  lastUpdatedMillis
}) => {
  let content = 'Ingen notater foreløpig';
  if (lastUpdatedMillis) {
    const user = getNameFromEmail(lastUpdatedBy);
    content = `Sist oppdatert: ${formatDateFromEpochMillis(
      lastUpdatedMillis
    )} av ${user}`;
  }
  return (
    <OperationInfoCard
      icon={<KeypediaSvg />}
      header='Interninfo og -notater'
      link='wiki'
      text={content}
    />
  );
};
