import React from 'react';
import { useKeychainFactoryDetails } from '../KeychainDetailsWrapper';
import { KeychainFactoryBreadcrumbs } from '../KeychainFactoryBreadcrumbs';
import './AccessControl.scss';
import { ApprovedKeychains } from './approvedKeychains/ApprovedKeychains';

export const AccessControl: React.FC = () => {
  const { keychainFactory } = useKeychainFactoryDetails();
  if (
    keychainFactory?.type === 'manual-grant' ||
    keychainFactory?.type === 'push' ||
    keychainFactory?.type === 'oidc'
  ) {
    return (
      <section>
        <KeychainFactoryBreadcrumbs
          keychainFactory={keychainFactory}
          name='Tilgangsoversikt'
        />
        <ApprovedKeychains keychainFactory={keychainFactory} />
      </section>
    );
  } else {
    return (
      <section className='access-control'>
        <KeychainFactoryBreadcrumbs
          keychainFactory={keychainFactory}
          name='Gi tilgang'
        />
        <br />
        <div>
          Ingen støtte for å gi tilganger med en slik tilgangsliste (
          {keychainFactory.type})
        </div>
      </section>
    );
  }
};
