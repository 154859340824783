import React from 'react';
import './EditOpeningHoursContainer.scss';

interface EditOpeningHoursContainerProps {
  children: React.ReactNode;
}

export const EditOpeningHoursContainer: React.FC<
  EditOpeningHoursContainerProps
> = ({ children }) => (
  <section className='edit-openinghours'>{children}</section>
);
