import { offset, useFloating } from '@floating-ui/react';
import React, { Fragment, PropsWithChildren } from 'react';
import './Sticker.scss';

type Props = {
  stickerText: string;
};
export const StickeredContent: React.FC<PropsWithChildren<Props>> = ({
  children,
  stickerText
}) => {
  if (!stickerText) return <Fragment>{children}</Fragment>;

  const { refs, floatingStyles } = useFloating({
    middleware: [
      offset({
        mainAxis: -125,
        crossAxis: -30
      })
    ],
    placement: 'right'
  });
  return (
    <Fragment>
      <div ref={refs.setReference}>{children}</div>
      <div
        className='circled-sticker'
        ref={refs.setFloating}
        style={floatingStyles}
      >
        <div className='header'>Obs!!</div>
        <div className='text'>{stickerText}</div>
      </div>
    </Fragment>
  );
};
