import React from 'react';
import './ConfirmCard.scss';

interface ConfirmCardProps {
  children: React.ReactNode;
}

export const ConfirmCard: React.FC<ConfirmCardProps> = ({ children }) => (
  <div className='confirm-container'>{children}</div>
);
