import React from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { subjectSelectors } from '../../../../redux/slice/subjects';
import { GroupedKeychainFactories } from '../../../../utils/keychainFactory/groupBySubject';
import { CardGroup } from '../groupedCards/cardGroup/CardGroup';

interface KeychainFactoryCardsProps {
  groupedKeychainFactories: GroupedKeychainFactories;
}

export const KeychainFactoryCards: React.FC<KeychainFactoryCardsProps> = ({
  groupedKeychainFactories
}) => {
  const subjects = useAppSelector(subjectSelectors.selectAll);
  const groupedCards = [];

  for (const key in groupedKeychainFactories) {
    const subjectName =
      subjects.find(subject => subject.id === key)?.name || key;

    groupedCards.push(
      <ul className='card-group-list' key={key}>
        <CardGroup
          subjectName={subjectName}
          keychainFactories={groupedKeychainFactories[key]}
        />
      </ul>
    );
  }

  if (groupedCards.length) {
    return <React.Fragment>{groupedCards}</React.Fragment>;
  } else {
    return null;
  }
};
