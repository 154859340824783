import React from 'react';

export const Beta: React.FC = () => (
  <svg
    width='35'
    height='40'
    viewBox='0 0 45 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Group 12639'>
      <path
        id='&#206;&#178;'
        d='M22.7121 20.4268H25.0341C26.6594 20.4268 27.983 20.7736 29.0046 21.4672C30.0341 22.1608 30.7887 23.0414 31.2686 24.109C31.7562 25.1766 32 26.2715 32 27.3937C32 28.7496 31.6865 29.9731 31.0596 31.0641C30.4327 32.1473 29.5697 33.0084 28.4706 33.6474C27.3715 34.2787 26.1176 34.5943 24.709 34.5943C23.8498 34.5943 22.9985 34.4345 22.1548 34.115C21.3189 33.7877 20.5759 33.1682 19.9257 32.2564L19.7399 32.3499V27.7677C19.7399 28.5314 19.9257 29.2367 20.2972 29.8835C20.6765 30.5303 21.2105 31.0485 21.8994 31.4382C22.596 31.8278 23.4241 32.0226 24.3839 32.0226C25.3901 32.0226 26.257 31.8083 26.9845 31.3797C27.7121 30.9511 28.2732 30.3861 28.668 29.6848C29.0627 28.9834 29.2601 28.2197 29.2601 27.3937C29.2601 26.6144 29.082 25.878 28.726 25.1844C28.3777 24.483 27.8824 23.9142 27.2399 23.4778C26.6053 23.0336 25.8545 22.8115 24.9876 22.8115H22.7121V20.4268ZM24.0588 10C25.1656 10 26.1447 10.1792 26.9961 10.5377C27.8553 10.8962 28.5789 11.3793 29.1672 11.9872C29.7632 12.5872 30.2121 13.2613 30.5139 14.0094C30.8235 14.7575 30.9783 15.5251 30.9783 16.3122C30.9783 17.2941 30.7306 18.2721 30.2353 19.2462C29.7399 20.2125 28.9814 21.0191 27.9598 21.6659C26.9381 22.3049 25.6378 22.6244 24.0588 22.6244H22.7121V20.2398H24.0124C24.9102 20.2398 25.6765 20.0528 26.3111 19.6787C26.9536 19.3047 27.4412 18.8176 27.774 18.2176C28.1146 17.6175 28.2848 16.9824 28.2848 16.3122C28.2848 15.2368 27.8746 14.3445 27.0542 13.6354C26.2415 12.9262 25.243 12.5716 24.0588 12.5716C23.2694 12.5716 22.5457 12.7353 21.8878 13.0626C21.2376 13.3899 20.7152 13.8458 20.3204 14.4302C19.9334 15.0147 19.7399 15.6888 19.7399 16.4525V41H17V16.4525C17 15.2212 17.3019 14.1185 17.9056 13.1444C18.517 12.1703 19.3529 11.4027 20.4133 10.8416C21.4814 10.2805 22.6966 10 24.0588 10Z'
        fill='#2A2859'
      />
    </g>
  </svg>
);
