import React from 'react';

export const PublicKeychain = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='24'
      cy='24'
      r='23'
      fill='#F8F0DD'
      stroke='#2A2859'
      strokeWidth='2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.3529 32.9691C19.7726 34.0576 21.5564 34.7059 23.4935 34.7059C25.4366 34.7059 27.2253 34.0537 28.6471 32.9592L26.6685 27.5367H20.3194L18.3529 32.9691ZM19.6227 32.4906L21.0861 28.6397H25.901L27.3644 32.4906C26.242 33.191 24.9172 33.6029 23.4935 33.6029C22.0699 33.6029 20.7451 33.191 19.6227 32.4906Z'
      fill='#2A2859'
    />
    <path
      d='M25.5617 24.3933C25.5617 25.5203 24.6358 26.4338 23.4935 26.4338C22.3513 26.4338 21.4254 25.5203 21.4254 24.3933C21.4254 23.2664 22.3513 22.3529 23.4935 22.3529C24.6358 22.3529 25.5617 23.2664 25.5617 24.3933Z'
      fill='#2A2859'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.5294 20.6162C25.9491 21.7047 27.7329 22.3529 29.67 22.3529C31.6131 22.3529 33.4018 21.7008 34.8236 20.6063L32.845 15.1838H26.4959L24.5294 20.6162ZM25.7992 20.1377L27.2626 16.2868H32.0775L33.5409 20.1377C32.4185 20.8381 31.0937 21.25 29.67 21.25C28.2464 21.25 26.9216 20.8381 25.7992 20.1377Z'
      fill='#2A2859'
    />
    <path
      d='M31.7382 12.0404C31.7382 13.1673 30.8123 14.0809 29.67 14.0809C28.5278 14.0809 27.6019 13.1673 27.6019 12.0404C27.6019 10.9135 28.5278 10 29.67 10C30.8123 10 31.7382 10.9135 31.7382 12.0404Z'
      fill='#2A2859'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M30.7059 32.9691C32.1256 34.0576 33.9093 34.7059 35.8465 34.7059C37.7895 34.7059 39.5783 34.0537 41 32.9592L39.0214 27.5367H32.6724L30.7059 32.9691ZM31.9757 32.4906L33.439 28.6397H38.254L39.7173 32.4906C38.5949 33.191 37.2702 33.6029 35.8465 33.6029C34.4228 33.6029 33.0981 33.191 31.9757 32.4906Z'
      fill='#2A2859'
    />
    <path
      d='M37.9147 24.3933C37.9147 25.5203 36.9887 26.4338 35.8465 26.4338C34.7043 26.4338 33.7783 25.5203 33.7783 24.3933C33.7783 23.2664 34.7043 22.3529 35.8465 22.3529C36.9887 22.3529 37.9147 23.2664 37.9147 24.3933Z'
      fill='#2A2859'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6 32.9691C7.41968 34.0576 9.20344 34.7059 11.1406 34.7059C13.0836 34.7059 14.8724 34.0537 16.2941 32.9592L14.3155 27.5367H7.96649L6 32.9691ZM7.26978 32.4906L8.73315 28.6397H13.5481L15.0114 32.4906C13.889 33.191 12.5643 33.6029 11.1406 33.6029C9.71692 33.6029 8.39218 33.191 7.26978 32.4906Z'
      fill='#2A2859'
    />
    <path
      d='M13.2088 24.3933C13.2088 25.5203 12.2828 26.4338 11.1406 26.4338C9.99839 26.4338 9.07244 25.5203 9.07244 24.3933C9.07244 23.2664 9.99839 22.3529 11.1406 22.3529C12.2828 22.3529 13.2088 23.2664 13.2088 24.3933Z'
      fill='#2A2859'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.1765 20.6162C13.5962 21.7047 15.3799 22.3529 17.3171 22.3529C19.2601 22.3529 21.0489 21.7008 22.4706 20.6063L20.492 15.1838H14.143L12.1765 20.6162ZM13.4463 20.1377L14.9096 16.2868H19.7246L21.1879 20.1377C20.0655 20.8381 18.7408 21.25 17.3171 21.25C15.8934 21.25 14.5687 20.8381 13.4463 20.1377Z'
      fill='#2A2859'
    />
    <path
      d='M19.3853 12.0404C19.3853 13.1673 18.4593 14.0809 17.3171 14.0809C16.1749 14.0809 15.2489 13.1673 15.2489 12.0404C15.2489 10.9135 16.1749 10 17.3171 10C18.4593 10 19.3853 10.9135 19.3853 12.0404Z'
      fill='#2A2859'
    />
  </svg>
);
