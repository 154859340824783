import {
  manualGrantKeychainHome,
  oidcKeychainHome,
  publicKeychainHome,
  pushKeychainHome
} from '../../components/Navigation/navPaths';
import { KeychainFactorySummary } from '../../redux/slice/keychainFactorySummaries';
import { FactoryType } from '../../redux/types/common';

export const filterByType = (
  keyChainFactories: KeychainFactorySummary[],
  type: string | undefined
) => {
  if (!type) {
    return keyChainFactories;
  }
  type = urlToDomainMap[type] || type;
  return keyChainFactories.filter(value => value.type === type);
};

export const urlToDomainMap: {
  [type: string]: FactoryType;
} = {
  [manualGrantKeychainHome.path.split('/')[2]]: 'manual-grant',
  [pushKeychainHome.path.split('/')[2]]: 'push',
  [oidcKeychainHome.path.split('/')[2]]: 'oidc',
  [publicKeychainHome.path.split('/')[2]]: 'public',
  unknown: 'unknown'
};
