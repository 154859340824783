import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AdminKeychain } from '../pages/controlpanel/KeychainFactory/AdminKeychain';
import { AdminOidcPage } from '../pages/controlpanel/OidcFactory/AdminOidcPage';
import { CreateOidcKeychainFactory } from '../pages/controlpanel/OidcFactory/CreateOidcKeychainFactory';
import { listKeychainFactoryConfigurations } from '../redux/actions/keychainFactorySummaries';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedAdminTool } from '../redux/slice/ui';

export const AdminOidcRouter = () => {
  const dispatch = useAppDispatch();
  const selectedAdminTool = useAppSelector(state => state.ui.selectedAdminTool);
  useEffect(() => {
    if (selectedAdminTool !== 'oidc') {
      dispatch(listKeychainFactoryConfigurations());
      dispatch(setSelectedAdminTool('oidc'));
    }
  }, [selectedAdminTool, dispatch]);

  return (
    <section>
      <Routes>
        <Route path='lag-ny' element={<CreateOidcKeychainFactory />} />
        <Route path='/:keychainId' element={<AdminKeychain type='oidc' />} />
        <Route path='/' element={<AdminOidcPage />} />
      </Routes>
    </section>
  );
};
