import React from 'react';
import { formatTimeInMs } from './ActionDetails';
import { classNameBasedOnResponseTime } from './ResponseTimeGraph';

interface Props {
  label: string;
  responseTime?: number;
}

export const ResponseTimeCrumb: React.FC<Props> = ({ label, responseTime }) => {
  if (responseTime === undefined) {
    return <React.Fragment />;
  } else {
    const colorClassName = classNameBasedOnResponseTime(responseTime);

    return (
      <React.Fragment>
        <span className={`inline-crumb ${colorClassName}`}>
          {label}: {formatTimeInMs(responseTime)}
        </span>
      </React.Fragment>
    );
  }
};
