import React from 'react';

export const PVD = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='136'
    height='136'
    viewBox='0 0 136 136'
  >
    <g
      id='Group_12165'
      data-name='Group 12165'
      transform='translate(-1024 -231)'
    >
      <circle
        id='Ellipse_694'
        data-name='Ellipse 694'
        cx='68'
        cy='68'
        r='68'
        transform='translate(1024 231)'
        fill='#f9b3ab'
      />
      <g id='noun-miss-76657' transform='translate(1064 269.188)'>
        <path
          id='Path_11260'
          data-name='Path 11260'
          d='M154.4,91.676c-.648-2.512-3.08-5.592-8.916-5.592a11.3,11.3,0,0,0-8.51,3.4c-1.378,1.215-2.432,2.107-4.62,2.107h-3v3.242h3c2.188,0,3.161.892,4.62,2.107a11.3,11.3,0,0,0,8.51,3.4c5.916,0,8.267-3.08,8.916-5.592l10.374,6.079V85.678Zm-8.915,5.511a8.421,8.421,0,0,1-6.4-2.594c-.486-.405-1.054-.892-1.621-1.3.568-.405,1.135-.892,1.621-1.3a8.424,8.424,0,0,1,6.4-2.594c5.673,0,5.916,3.566,5.916,3.89,0,.163,0,3.891-5.916,3.891Zm16.048-1.945-3.4-1.945,3.4-1.945Z'
          transform='translate(-126.358 -81.707)'
          fill='#292858'
        />
        <path
          id='Path_11261'
          data-name='Path 11261'
          d='M402.08,127.12h4.863v3.242H402.08Z'
          transform='translate(-359.611 -117.151)'
          fill='#292858'
        />
        <path
          id='Path_11262'
          data-name='Path 11262'
          d='M472.08,127.12h5.268v3.242H472.08Z'
          transform='translate(-419.48 -117.151)'
          fill='#292858'
        />
        <path
          id='Path_11263'
          data-name='Path 11263'
          d='M544.88,127.12h4.863v3.242H544.88Z'
          transform='translate(-481.743 -117.151)'
          fill='#292858'
        />
        <path
          id='Path_11264'
          data-name='Path 11264'
          d='M223.078,259.116a21.478,21.478,0,1,1,21.478-21.478A21.515,21.515,0,0,1,223.078,259.116Zm0-38.9A17.425,17.425,0,1,0,240.5,237.638,17.4,17.4,0,0,0,223.078,220.213Z'
          transform='translate(-188.146 -193.304)'
          fill='#292858'
        />
        <path
          id='Path_11265'
          data-name='Path 11265'
          d='M272.411,300.1a13.13,13.13,0,1,1,13.13-13.13A13.179,13.179,0,0,1,272.411,300.1Zm0-22.207a9.077,9.077,0,1,0,9.077,9.077A9.063,9.063,0,0,0,272.411,277.891Z'
          transform='translate(-237.479 -242.635)'
          fill='#292858'
        />
        <path
          id='Path_11266'
          data-name='Path 11266'
          d='M332.491,343.489a3.566,3.566,0,1,1-3.566-3.566,3.566,3.566,0,0,1,3.566,3.566'
          transform='translate(-293.993 -299.155)'
          fill='#292858'
        />
        <path
          id='Path_11267'
          data-name='Path 11267'
          d='M127.725,167.25l4.068,4.069-1.147,1.146-4.068-4.069Z'
          transform='translate(-123.982 -151.473)'
          fill='#292858'
        />
        <path
          id='Path_11268'
          data-name='Path 11268'
          d='M131.116,73.2l1.146,1.146-4.069,4.069-1.146-1.146Z'
          transform='translate(-124.383 -71.034)'
          fill='#292858'
        />
        <path
          id='Path_11269'
          data-name='Path 11269'
          d='M108.64,182.56h1.621v5.511H108.64Z'
          transform='translate(-108.64 -164.567)'
          fill='#292858'
        />
        <path
          id='Path_11270'
          data-name='Path 11270'
          d='M108.64,58.238h1.621v5.511H108.64Z'
          transform='translate(-108.64 -58.238)'
          fill='#292858'
        />
      </g>
    </g>
  </svg>
);
