import React from 'react';

export const UnknownSvg = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='46'
    height='46'
    viewBox='0 0 46 46'
  >
    <g
      id='Group_12086'
      data-name='Group 12086'
      transform='translate(-962 -299)'
    >
      <g
        id='Group_12085'
        data-name='Group 12085'
        transform='translate(392 -97.185)'
      >
        <g
          id='Group_12084'
          data-name='Group 12084'
          transform='translate(570 396.185)'
        >
          <g id='Group_1925' data-name='Group 1925' transform='translate(0 0)'>
            <g id='Group_1845' data-name='Group 1845'>
              <g id='Group_1843' data-name='Group 1843'>
                <g
                  id='Ellipse_535'
                  data-name='Ellipse 535'
                  fill='#f8f0dd'
                  stroke='#292858'
                  strokeWidth='3'
                >
                  <circle cx='23' cy='23' r='23' stroke='none' />
                  <circle cx='23' cy='23' r='21.5' fill='none' />
                </g>
              </g>
            </g>
          </g>
        </g>
        <text
          id='_'
          data-name='?'
          transform='translate(588 404.185)'
          fontSize='22'
          fontFamily='OsloSans-Regular, OsloSans'
        >
          <tspan x='0' y='23'>
            ?
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
