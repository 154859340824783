import React from 'react';
import classNames from 'classnames';
import { Problem } from '../../proto/shared_pb';
import { useAppSelector } from '../../redux/hooks';
import { subjectSelectors } from '../../redux/slice/subjects';
import './OperationStatus.scss';

import HowBad = Problem.HowBad;

interface Props {
  subjectId: string;
  operationId: string;
  type: 'dot' | 'line';
}

export const OperationStatus: React.FC<Props> = ({
  subjectId,
  operationId,
  type
}) => {
  const subjects = useAppSelector(subjectSelectors.selectAll);
  const operation = subjects
    .find(subject => {
      return subject.id === subjectId;
    })
    ?.operationsList.find(operation => {
      return operation.id === operationId;
    });

  if (!operation) {
    return null;
  }

  const highestProblemLevel = operation.problemsList.reduce((acc, problem) => {
    return Math.max(acc, problem.howBad);
  }, HowBad.NOTICE);

  const className = classNames(
    `operation-status-${type} operation-status--${
      highestProblemLevel === HowBad.NOTICE
        ? 'green'
        : highestProblemLevel === HowBad.WARNING
          ? 'yellow'
          : 'red'
    }`
  );

  return <span data-testid={'dot-span'} role='img' className={className} />;
};
