import React from 'react';
import { AuditLogsTable } from '../../../pages/controlpanel/LogsAndData/pages/AuitLogTable';
import { useAuditLog } from '../../../utils/auditLogs/useAuditLog';

type Props = {
  citykeyId: string;
};
export const AuditPage: React.FC<Props> = ({ citykeyId }) => {
  const auditLogs = useAuditLog(citykeyId);
  return <AuditLogsTable auditLogs={auditLogs} />;
};
